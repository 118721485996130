import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment-timezone';

import ApolloProviderWrapper from './hoc/ApolloProviderWrapper';
import App from './App';

import 'typeface-roboto';
import TabSyncService from './services/tabSyncService';

import './i18n';

moment.tz.setDefault('UTC');

TabSyncService.initialize();

const Root = () => {
  return (
    <BrowserRouter>
      <ApolloProviderWrapper>
        <Suspense fallback="Loading...">
          <App />
        </Suspense>
      </ApolloProviderWrapper>
    </BrowserRouter>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
