import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import UndoIcon from '@material-ui/icons/Undo';
import { useHistory } from 'react-router-dom';
import NotificationService from '../../services/notificationService';

export interface ButtonWithBackIconProps {
  children: string;
  location?: string;
  disabled?: boolean;
}

const ButtonWithBackIcon = (props: ButtonWithBackIconProps): JSX.Element => {
  const useStyles = makeStyles((theme: Theme) => ({
    open: {
      width: '100%',
      marginTop: -2,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    icon: {
      marginRight: 5,
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  return (
    <Button
      data-cy="goBackBtn"
      className={classes.open}
      variant="contained"
      onClick={() => {
        NotificationService.closeAll();
        if (props.location) {
          history.push(props.location);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (history as any).goBack();
        }
      }}
      disabled={props.disabled}
    >
      <UndoIcon fontSize="small" className={classes.icon} /> {props.children}
    </Button>
  );
};

export default ButtonWithBackIcon;
