import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
}));

interface ToolsContainerProps {
  children?: JSX.Element | JSX.Element[] | string;
}

const ToolsContainer = (props: ToolsContainerProps): JSX.Element => {
  const classes = useStyles();

  return <div className={classes.root}>{props.children}</div>;
};

export default ToolsContainer;
