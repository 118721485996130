import React, { useState } from 'react';
import { makeStyles, Tooltip, Grow } from '@material-ui/core';
import HotelIcon from '@material-ui/icons/Hotel';
import EventService from '../../services/eventService';
import PositionLabelingService from '../../services/positionLabelingService';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    margin: theme.spacing(0, 0.4),
  },
  tooltip: {
    maxWidth: 300,
  },
}));

const PositionEventsStatus = (): JSX.Element => {
  const classes = useStyles();
  const [show, setShow] = useState(PositionLabelingService.getEventReceived());

  React.useEffect(() => {
    const cbId = EventService.subscribe(
      'PositionEventReceived',
      (data: boolean) => setShow(data),
    );

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, []);

  return (
    <Grow in={show} mountOnEnter unmountOnExit>
      <div className={classes.root}>
        <Tooltip
          title="There are Position events for this scoring"
          classes={{ tooltip: classes.tooltip }}
          style={{ cursor: 'pointer' }}
          arrow
        >
          <HotelIcon fontSize="small" />
        </Tooltip>
      </div>
    </Grow>
  );
};

export default PositionEventsStatus;
