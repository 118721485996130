import gql from 'graphql-tag';
import { RecordingId } from '../recording';

export type ExportId = string;

export interface RequestExportMutationParameters {
  recordingId: RecordingId;
}

export interface RequestExportMutationResult {
  recordingExport: ExportId;
}

export const REQUEST_EXPORT_MUTATION = gql`
  mutation RequestExportRecording($recordingId: String!) {
    recordingExport(recordingId: $recordingId)
  }
`;
