import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import XRange from 'highcharts/modules/xrange';
import _ from 'underscore';
import React from 'react';
import {
  useTheme,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  fade,
} from '@material-ui/core';
import Logger from '../../../utils/logger';
import { RecordingPartData } from '../../StudyOverview/OverviewParts';
import { OverviewGraphDefinition } from './overviewChartDefinitions';
import ScoringService from '../../../services/scoringService';
import EventService from '../../../services/eventService';
import { SignalEventDetailData } from '../../../interfaces/signal-event-detail-props';
import eventChartTools from '../SignalChart/eventChartTools';
import { MarkerGroups } from '../SignalChart/markerConstants';
import chartTools from '../SignalChart/chartTools';
import studyTools from '../../StudyOverview/studyTools';
import { MarkerType } from '../../../interfaces/markers';
import SleepStageService from '../../../services/sleepStageService';

XRange(Highcharts);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    yAxisLabels: {
      position: 'absolute',
      top: 2,
      bottom: 1,
      display: 'flex',
      flexDirection: 'column',
      right: '100%',
      marginRight: theme.spacing(0.5),
      textAlign: 'right',
      justifyContent: 'space-between',
    },
    label: {
      color: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.54,
      ),
      fontSize: 9,
      lineHeight: '9px',
    },
  }),
);

interface OverviewHypnogramProps {
  graphDefinition: OverviewGraphDefinition;
  partData: RecordingPartData;
  chartWidth?: number;
}

const OverviewHypnogram = (props: OverviewHypnogramProps): JSX.Element => {
  Logger.log(
    '[OverviewHypnogram] Created new instance for:',
    props.graphDefinition.type,
  );
  const classes = useStyles();
  const theme = useTheme();

  const redraw = () => {
    const chart = studyTools.retrieveChart(
      props.partData.partId,
      props.graphDefinition.type,
    );
    if (chart && chartTools.isSerieAvailable(chart)) {
      Logger.debug(
        '[OverviewHypnogram][redraw][%s] Redrawing',
        props.graphDefinition.type,
      );
      const time = Date.now();

      Logger.debug(
        '[OverviewHypnogram][redraw][%s] chartWidth',
        props.graphDefinition.type,
        chart.chartWidth,
      );
      if (chart.chartWidth > 1) {
        chart.redraw(false);
      } else {
        Logger.debug(
          '[OverviewHypnogram][redraw][%s] Skipping!',
          props.graphDefinition.type,
        );
      }

      Logger.debug(
        '[OverviewHypnogram][redraw][%s]  Redrawing (%d) points took (ms):',
        props.graphDefinition.type,
        chartTools.getDataFromChart(chart).length,
        Date.now() - time,
      );
    }
  };

  const getYFromSleepStage = (sleepStage: MarkerType): number => {
    let y = 0;

    switch (sleepStage) {
      case 'sleep-wake':
        y = 1;
        break;
      case 'sleep-rem':
        y = 0;
        break;
      case 'sleep-nrem':
        y = -1;
        break;
      case 'sleep-n1':
        y = -1;
        break;
      case 'sleep-n2':
        y = -1;
        break;
      case 'sleep-n3':
        y = -1;
        break;
      default: // Do nothing
    }

    return y;
  };

  const areSameEventType = (a: MarkerType, b: MarkerType): boolean =>
    (a === 'sleep-wake' && b === 'sleep-wake') ||
    (a === 'sleep-rem' && b === 'sleep-rem') ||
    (SleepStageService.isNREMStage(a) && SleepStageService.isNREMStage(b));

  const processEvents = _.throttle(() => {
    Logger.log('[OverviewHypnogram] processSamples');
    const chart = studyTools.retrieveChart(
      props.partData.partId,
      props.graphDefinition.type,
    );

    if (chart) {
      const partStartTime = new Date(props.partData.startTime).valueOf();
      const partEndTime = new Date(props.partData.endTime).valueOf();

      const data: Highcharts.XrangePointOptionsObject[] = [];
      const events = ScoringService.retrieveMarkersByType(
        MarkerGroups.getEventTypes(['Sleep Stage']),
      )
        .sort((a, b) => a.start - b.end)
        .map((event) => ({ ...event }));
      Logger.log('[OverviewHypnogram] events', events);

      // Adding placeholders to prevent Highcharts issue
      [-1, 0, 1].forEach((y) =>
        data.push({
          x: partStartTime,
          x2: partStartTime,
          y,
          color: 'transparent',
        }),
      );

      if (events.length > 0) {
        let pendingStage: SignalEventDetailData = events[0];
        events.forEach((event) => {
          if (
            eventChartTools.isMarkerWithinTimePeriod(
              event,
              partStartTime,
              partEndTime,
            )
          ) {
            const sameType = areSameEventType(pendingStage.type, event.type);
            if (!sameType) {
              data.push({
                x: pendingStage.start,
                x2: pendingStage.end,
                y: getYFromSleepStage(pendingStage.type),
                color: SleepStageService.isNREMStage(pendingStage.type)
                  ? eventChartTools.getColorByEventType('sleep-nrem')
                  : eventChartTools.getColorByEventType(pendingStage.type),
              });
              pendingStage = event;
            } else if (sameType) {
              pendingStage.end = event.end;
            }
          }
        });

        data.push({
          x: pendingStage.start,
          x2: pendingStage.end,
          y: getYFromSleepStage(pendingStage.type),
          color: eventChartTools.getColorByEventType(pendingStage.type),
        });
      }

      Logger.log('[OverviewHypnogram] data', data);
      chartTools.setData(chart, data, { redraw: false });
      redraw();
    }
  }, 250);

  const handleEventChange = (marker: SignalEventDetailData) => {
    if (MarkerGroups.getEventTypes(['Sleep Stage']).includes(marker.type)) {
      const partStartTime = new Date(props.partData.startTime).valueOf();
      const partEndTime = new Date(props.partData.endTime).valueOf();
      if (
        eventChartTools.isMarkerWithinTimePeriod(
          marker,
          partStartTime,
          partEndTime,
        )
      ) {
        processEvents();
      }
    }
  };

  React.useEffect(() => {
    const cbId = EventService.subscribe(
      'ScoringChanged',
      (marker: SignalEventDetailData) => {
        // Logger.debug('[OverviewHypnogram] ScoringChanged', marker);
        handleEventChange(marker);
      },
    );

    const chart = studyTools.retrieveChart(
      props.partData.partId,
      props.graphDefinition.type,
    );
    if (chart) {
      chart.setSize(props.chartWidth, undefined);
    }

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, [props.chartWidth]);

  const min = new Date(props.partData.startTime).valueOf();
  const max = new Date(props.partData.endTime).valueOf();

  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'xrange',
      width: 1,
      height: props.graphDefinition.height,
      marginLeft: 0,
      marginTop: 0,
      spacingTop: 0,
      spacingRight: 20,
      spacingBottom: 0,
      spacingLeft: 0,
      plotBorderWidth: 0,
      panning: {
        enabled: false,
      },
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      animation: false,
      events: {
        load() {
          Logger.log('[OverviewChart] load');
          studyTools.registerChart(
            props.partData.partId,
            props.graphDefinition.type,
            this,
          );
          processEvents();
        },
      },
    },
    loading: {
      style: {
        background: theme.colors.chart.loading.background,
        color: theme.colors.chart.loading.color,
      },
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      visible: false,
      crosshair: false,
      gridLineWidth: 0,
      lineWidth: 0,
      showFirstLabel: true,
      showLastLabel: true,
      ordinal: false,
      min,
      max,
    },
    yAxis: [
      {
        startOnTick: true,
        endOnTick: true,
        tickPixelInterval: 100,
        alignTicks: false,
        lineWidth: 0,
        gridLineWidth: 0,
        top: -1,
        showFirstLabel: true,
        showLastLabel: true,
        opposite: false,
        labels: {
          enabled: false,
        },
        height: 42,
        categories: ['Wake', 'REM', 'NREM'],
      },
    ],
    series: [
      {
        name: 'Sleep Stages',
        type: 'xrange',
        borderColor: 'gray',
        pointWidth: 10,
        data: [],
        minPointLength: 1,
        borderRadius: 1,
        dataLabels: {
          enabled: true,
        },
        turboThreshold: 0,
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
  };

  return (
    <div
      data-cy={`OverviewGraph-${props.graphDefinition.type}`}
      className={classes.container}
    >
      <div className={classes.yAxisLabels}>
        <Typography variant="caption" className={classes.label}>
          Wake
        </Typography>
        <Typography variant="caption" className={classes.label}>
          REM
        </Typography>
        <Typography variant="caption" className={classes.label}>
          NREM
        </Typography>
      </div>

      <HighchartsReact
        highcharts={Highcharts}
        constructorType="stockChart"
        allowChartUpdate={false}
        options={chartOptions}
      />
    </div>
  );
};

export default OverviewHypnogram;
