import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import AppBar from '../components/AppBar/AppBar';
import PageStatus from '../interfaces/page-status';
import { WithTheme } from './withTheme';

const withNavigationBars = <P extends Record<string, unknown>>(
  Content: React.ComponentType<P>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const useStyles = makeStyles(() => ({
    rootStyle: {
      display: 'flex',
      height: '100%',
    },
    contentStyle: {
      marginTop: 40,
      width: '100%',
    },
  }));

  const Section = (props: WithTheme<P>) => {
    const classes = useStyles();
    const [pageStatus, setPageStatus] = useState<PageStatus>({
      currentPage: 'Recordings',
      recordingId: '',
    });

    const pageStatusState = {
      pageStatus: {
        status: pageStatus,
        setStatus: setPageStatus,
      },
    };

    const newProps = {
      ...props,
      ...pageStatusState,
    };

    return (
      <div className={classes.rootStyle}>
        <AppBar
          pageStatus={pageStatusState.pageStatus}
          toggleTheme={props.toggleTheme}
        />
        <div className={classes.contentStyle}>
          <Content {...(newProps as P)} />
        </div>
      </div>
    );
  };
  return Section;
};
export default withNavigationBars;
