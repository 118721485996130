import gql from 'graphql-tag';
import { RecordingId } from './recording';

export type AnalysisPeriodTypes = 'analysis-start' | 'analysis-stop';

export interface AnalysisPeriod {
  beginning: string;
  end: string;
}

export interface GetAnalysisPeriodsParameters {
  recordingId: RecordingId;
  scoringId: string;
}

export interface GetAnalysisPeriodsResult {
  analysisPeriods: AnalysisPeriod[];
}

export const GET_ANALYSIS_PERIODS = gql`
  query AnalysisPeriods($recordingId: String!, $scoringId: String!) {
    analysisPeriods(recordingId: $recordingId, scoringId: $scoringId) {
      beginning
      end
    }
  }
`;

export interface AnalysisPeriodsMutationParameters {
  recordingId: RecordingId;
  scoringId: string;
  periods: AnalysisPeriod[];
}

export const ANALYSIS_PERIODS_MUTATION = gql`
  mutation AnalysisPeriods(
    $recordingId: String!
    $scoringId: String!
    $periods: [AnalysisPeriodInput]!
  ) {
    setAnalysisPeriods(
      recordingId: $recordingId
      scoringId: $scoringId
      periods: $periods
    )
  }
`;
