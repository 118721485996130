import chartTools from '../components/Chart/SignalChart/chartTools';
import Logger from '../utils/logger';
import sheetTools, {
  HighlightedPlotbandId,
} from '../components/SignalSheet/sheetTools';
import chartRangeTools from '../components/SignalSheet/chartRangeTools';
import chartEvents from '../components/SignalSheet/chartEvents';
import signalEventDetailService from '../components/SignalEventDetail/signalEventDetailService';
import queryManager from './queryManager';
import ScoringRenderingService from './scoringRenderingService';
import {
  DataLabelData,
  DataLabelPosition,
  DataLabelUpdate,
  LABEL_FONT_SIZE,
} from '../components/Chart/SignalChart/DataLabelContainer';
import { SignalType } from '../interfaces/sheet-definition';
import SleepStageService from './sleepStageService';
import EventService from './eventService';
import { ExtendedPlotBand } from '../components/Chart/SignalChart/interfaces/chart-tools';

export type UpdateDataLabelsFunction = (
  newDataLabelUpdate: DataLabelUpdate,
) => void;
export type SetIsLoadingFunction = (isLoading: boolean) => void;

interface ConditionalRenderingOptions {
  redrawAll?: boolean;
}

interface SignalRenderingServiceInterface {
  enableSheetIntegrityCheck: () => void;
  disableSheetIntegrityCheck: () => void;
  setRenderInProgress: (status: boolean) => void;
  isRenderInProgress: () => boolean;
  waitForRenderFinished: (opts?: {
    extraWaitAfter?: number;
  }) => Promise<boolean>;
  waitForNoUserInteraction: () => Promise<boolean>;
  redrawEpochChart: () => Promise<boolean>;
  reloadAllCharts: (consecutiveMoves?: number) => Promise<boolean>;
  fireSetExtremes: (chart: Highcharts.Chart) => Promise<Highcharts.Chart>;
  redraw: (chart: Highcharts.Chart) => Promise<Highcharts.Chart>;
  addDataIfNeeded: (chart: Highcharts.Chart) => Promise<Highcharts.Chart>;
  addDataToCurrentExtremes: (
    chart: Highcharts.Chart,
  ) => Promise<Highcharts.Chart>;
  addDataToCurrentAndNextExtremes: (
    chart: Highcharts.Chart,
  ) => Promise<Highcharts.Chart>;
  addDataQueries: (chart: Highcharts.Chart) => Promise<Highcharts.Chart>;
  showLoadingIfNeeded: (chart: Highcharts.Chart) => Promise<Highcharts.Chart>;
  removeHighlightedEpochs: (
    chart: Highcharts.Chart,
  ) => Promise<Highcharts.Chart>;
  processCoreRendering: (chart: Highcharts.Chart) => Promise<Highcharts.Chart>;
  onAfterUserMoving: (
    options?: ConditionalRenderingOptions,
  ) => Promise<boolean>;
  redrawDataLabels: (chart: Highcharts.Chart) => Promise<Highcharts.Chart>;
  autoScaleIfNeeded: (chart: Highcharts.Chart) => Promise<Highcharts.Chart>;
  registerUpdateDataLabelsFunction: (
    signalType: SignalType,
    fn: UpdateDataLabelsFunction,
  ) => void;
  registerSetIsLoadingFunction: (
    signalType: SignalType,
    fn: SetIsLoadingFunction,
  ) => void;
  calculateDataLabelPosition: (
    label: number[],
    extremeMin: number,
    extremeMax: number,
    axisMin: number | null,
    axisMax: number | null,
    height?: number,
  ) => DataLabelPosition;
}

let integrityCheckIntervalId: NodeJS.Timeout;
let renderingInProgress = false;

const updateDataLabelMap = new Map<SignalType, UpdateDataLabelsFunction>();
const isLoadingMap = new Map<SignalType, SetIsLoadingFunction>();

const SignalRenderingService: SignalRenderingServiceInterface = {
  enableSheetIntegrityCheck: () => {
    SignalRenderingService.disableSheetIntegrityCheck();
    integrityCheckIntervalId = setInterval(() => {
      if (!chartEvents.isUserMoving()) {
        if (!SignalRenderingService.isRenderInProgress()) {
          if (!ScoringRenderingService.isUserInteracting()) {
            chartTools
              .getCharts({
                excludeEpochChart: true,
                excludeNavigator: true,
                withAvailableSeries: true,
                onlyVisible: true,
              })
              .forEach((chart) => {
                const signalType = chartTools.getSignalType(chart);
                const chartData = chart.series[0].data;
                const lastAddedSamples =
                  chartTools.getCustomData(chart, 'lastAddedSamples') ?? 0;
                const isLoading = chartTools.isLoading(chart);

                // Logger.warn('[setSheetIntegrityCheck][%s] chartData', signalType, chartData);

                if (
                  isLoading ||
                  chartTools.missingDataForCurrentExtremes(chart) ||
                  (lastAddedSamples > 0 && chartData.length === 0)
                ) {
                  Logger.debug(
                    '[setSheetIntegrityCheck][%s] Rerendering',
                    signalType,
                  );
                  SignalRenderingService.processCoreRendering(chart).then(
                    SignalRenderingService.addDataQueries,
                  );
                }
              });
          }
        }
      }
    }, 1000);
  },
  disableSheetIntegrityCheck: () => clearInterval(integrityCheckIntervalId),

  setRenderInProgress: (status: boolean) => {
    renderingInProgress = status;
  },
  isRenderInProgress: () => renderingInProgress,
  redrawEpochChart: () => {
    return new Promise((resolve) => {
      Logger.debug('[reRenderEpochChart] Entering rerender');
      const time = Date.now();
      const EpochChart = chartTools.getEpochChart();
      if (EpochChart) {
        if (chartTools.isSerieAvailable(EpochChart)) {
          EpochChart.series[0].update({ type: 'line' });
        }
      }
      Logger.debug(
        '[reRenderEpochChart] Rerender took (ms):',
        Date.now() - time,
      );
      resolve(true);
    });
  },
  reloadAllCharts: async (consecutiveMoves?: number) => {
    signalEventDetailService.popup.close();
    EventService.dispatch('Scoring.CancelActiveMarker');

    const signalChartList = chartTools.getCharts({
      excludeEpochChart: true,
      excludeNavigator: true,
      withAvailableSeries: true,
      onlyVisible: true,
    });

    const promises: Promise<unknown>[] = [];
    Logger.log('[reloadAllCharts] Init drawing phase');

    const epochChart = chartTools.getEpochChart();
    if (epochChart) {
      promises.push(
        SignalRenderingService.fireSetExtremes(epochChart)
          .then(SignalRenderingService.removeHighlightedEpochs)
          .then(SignalRenderingService.redrawEpochChart)
          .then(SleepStageService.updateStageColors),
      );
    }

    signalChartList.forEach((chart: Highcharts.Chart) => {
      promises.push(SignalRenderingService.processCoreRendering(chart));
    });

    await Promise.all(promises).then(() => {
      Logger.log('[reloadAllCharts] Init dataQuerying phase');
      signalChartList.forEach((chart: Highcharts.Chart) => {
        promises.push(SignalRenderingService.addDataQueries(chart));
      });
    });

    await Promise.all(promises).then(() => {
      Logger.log('[reloadAllCharts] consecutiveMoves', consecutiveMoves);
      if (!!consecutiveMoves && consecutiveMoves > 1) {
        Logger.log('[reloadAllCharts] Preparing data for consecutive moves');
        signalChartList.forEach((chart: Highcharts.Chart) => {
          promises.push(
            SignalRenderingService.addDataToCurrentAndNextExtremes(chart).then(
              SignalRenderingService.autoScaleIfNeeded,
            ),
          );
        });
      }
    });

    if (!chartEvents.isUserMoving()) {
      Logger.log('[reloadAllCharts] User has clicked. Rendering immediately');
      SignalRenderingService.waitForRenderFinished().then(() => {
        SignalRenderingService.onAfterUserMoving({ redrawAll: true });
      });
    }

    await Promise.all(promises)
      /* .then(queryManager.executeQueries) // TODO: Make sure we want this here */
      .then(() => {
        Logger.log(
          '[reloadAllCharts] Waiting animation frame to end the busy state',
        );
        requestAnimationFrame(() => {
          Logger.log(
            '[reloadAllCharts] All promises done. Ending the busy state',
          );
          queryManager.executeQueries();
          SignalRenderingService.setRenderInProgress(false);
        });
      });

    Logger.log('[reloadAllCharts] event done');
    return true;
  },
  processCoreRendering: (chart: Highcharts.Chart) =>
    new Promise((resolve) =>
      SignalRenderingService.fireSetExtremes(chart)
        .then(SignalRenderingService.addDataIfNeeded)
        .then(SignalRenderingService.redraw)
        .then(SignalRenderingService.removeHighlightedEpochs)
        .then(SignalRenderingService.showLoadingIfNeeded)
        .then(SignalRenderingService.redrawDataLabels)
        .then(() => resolve(chart)),
    ),
  waitForRenderFinished: (opts?: { extraWaitAfter?: number }) => {
    return new Promise((resolve) => {
      const checkIfStillInProgress = () => {
        const intervalId = setInterval(() => {
          if (!SignalRenderingService.isRenderInProgress()) {
            clearInterval(intervalId);
            setTimeout(() => resolve(true), opts?.extraWaitAfter || 0);
          }
        }, 50);
      };

      if (SignalRenderingService.isRenderInProgress()) {
        checkIfStillInProgress();
      } else {
        resolve(true);
      }
    });
  },
  waitForNoUserInteraction: () => {
    return new Promise((resolve) => {
      const checkIfThereIsUserInteraction = () => {
        const intervalId = setInterval(() => {
          if (!ScoringRenderingService.isUserInteracting()) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 50);
      };

      if (ScoringRenderingService.isUserInteracting()) {
        checkIfThereIsUserInteraction();
      } else {
        resolve(true);
      }
    });
  },

  fireSetExtremes: (chart: Highcharts.Chart) =>
    new Promise((resolve) => {
      const signalType = chartTools.getSignalType(chart) || 'EpochChart';
      Logger.debug('[RenderingService][fireSetExtremes][%s] init', signalType);

      const currentEpochExtremes = chartRangeTools.getCurrentExtremes();
      const time = Date.now();
      chart.xAxis[0].setExtremes(
        currentEpochExtremes.min,
        currentEpochExtremes.max,
        false,
        false,
      );
      Logger.debug(
        '[fireSetExtremes][%s] setExtremes done. Took:',
        signalType,
        Date.now() - time,
      );

      Logger.debug('[fireSetExtremes][%s] Exiting', signalType);
      resolve(chart);
    }),

  redraw: (chart: Highcharts.Chart) =>
    new Promise((resolve) => {
      if (chartTools.isSerieAvailable(chart)) {
        const signalType = chartTools.getSignalType(chart);
        if (signalType && !sheetTools.otherChartsInFullSize(signalType)) {
          Logger.debug('[redraw][%s] Redrawing', signalType);
          const time = Date.now();
          chart.redraw(false);
          Logger.debug(
            '[redraw][%s] Redrawing %d points took (ms):',
            signalType,
            chartTools.getDataFromChart(chart).length,
            Date.now() - time,
          );
        } else {
          Logger.debug(
            '[redraw][%s] There are other charts in fullsize. Skipping.',
            signalType,
          );
        }
      }
      resolve(chart);
    }),
  addDataIfNeeded: (chart: Highcharts.Chart) =>
    new Promise((resolve) => {
      const signalType = chartTools.getSignalType(chart);
      const signalInfo = chartTools.getSignalInfo(signalType);

      // Logger.log('[addDataIfNeeded][%s] Init', signalInfo?.name);
      /* Logger.log(
        '[addDataIfNeeded] -> missingDataForCurrentExtremes?',
        chartTools.missingDataForCurrentExtremes(chart)
      ); */

      if (
        !!signalInfo &&
        (chartTools.missingDataForCurrentExtremes(chart) ||
          chartTools.dataStoredForDifferentZoomRange(chart))
      ) {
        Logger.log(
          '[addDataIfNeeded][%s] Detected missing data in view. Adding.',
          signalInfo.name,
        );
        SignalRenderingService.addDataToCurrentExtremes(chart)
          .then(SignalRenderingService.autoScaleIfNeeded)
          .then(resolve);
      } else {
        resolve(chart);
      }
    }),
  addDataToCurrentExtremes: (chart: Highcharts.Chart) =>
    new Promise((resolve) => {
      const signalType = chartTools.getSignalType(chart);
      const signalInfo = chartTools.getSignalInfo(signalType);
      if (signalInfo) {
        // Logger.log('[addDataToCurrentExtremes] Entering:', signalInfo);
        const currentEpochExtremes =
          chartRangeTools.getCurrentExtremesInEpoch();

        chartTools.addDataToChart(chart, signalInfo, {
          first: currentEpochExtremes.first,
          last: currentEpochExtremes.last,
        });

        // Logger.log('[addDataToCurrentExtremes] Done:', signalInfo);
      }
      resolve(chart);
    }),
  addDataToCurrentAndNextExtremes: (chart: Highcharts.Chart) =>
    new Promise((resolve) => {
      const signalType = chartTools.getSignalType(chart);
      const signalInfo = chartTools.getSignalInfo(signalType);

      if (signalInfo) {
        Logger.log('[addDataToNextExtremes] Entering:', signalInfo);
        const currentEpochExtremes =
          chartRangeTools.getCurrentExtremesInEpoch();
        const epochDiff =
          currentEpochExtremes.last - currentEpochExtremes.first;

        chartTools.addDataToChart(chart, signalInfo, {
          first: currentEpochExtremes.first,
          last: currentEpochExtremes.last + epochDiff,
        });
      }

      Logger.log('[addDataToNextExtremes] Done:', signalInfo);
      resolve(chart);
    }),
  redrawDataLabels: (chart: Highcharts.Chart) =>
    new Promise((resolve) => {
      // Logger.log('[redrawDataLabels] init.');

      const signalType = chartTools.getSignalType(chart);
      if (!!signalType && sheetTools.isLabeledChart(signalType)) {
        // Logger.log('[redrawDataLabels] redrawDataLabels.');

        if (chartTools.isSerieAvailable(chart)) {
          // Logger.debug('[redrawDataLabels][%s] Redrawing dataLabels', signalType);
          const data = chartTools.getDataFromChart(chart);

          // const time = Date.now();

          const currentExtremes = chartRangeTools.getCurrentExtremes();
          const signalInfo = chartTools.getSignalInfo(signalType);
          if (signalInfo) {
            const updateDataLabels = updateDataLabelMap.get(signalInfo.type);
            const signalHeight = signalInfo.screenPosition?.height || 0;
            const signalWidth = ScoringRenderingService.getScoringCanvasWidth();

            const labelSafeMarginPx = 5;
            const maximumAllowedLabels =
              signalWidth / (LABEL_FONT_SIZE + labelSafeMarginPx);
            const totalNonTrendingAllowedLabels =
              maximumAllowedLabels < 30 ? maximumAllowedLabels : 30;
            const totalTrendingAllowedLabels =
              maximumAllowedLabels < 150 ? maximumAllowedLabels : 150;

            if (updateDataLabels) {
              const dataLabels: DataLabelData[] = [];

              // Init overlappingDetection
              if (signalHeight > 0) {
                let lastOneWasFirst: boolean;
                let lastShownTime: number;
                let lastShownPeakTime: number;
                let currentTrend: 'down' | 'up';
                const currentRange = sheetTools.getZoomRange();
                const safeDistance =
                  currentRange / totalNonTrendingAllowedLabels;
                const minimumDistance =
                  currentRange / totalTrendingAllowedLabels;

                const isTimestampInside = (timestamp?: number): boolean =>
                  !!timestamp &&
                  currentExtremes.min <= timestamp &&
                  timestamp <= currentExtremes.max;

                data.forEach((label, index) => {
                  const currentIndex = index;

                  const isInside =
                    !!data[currentIndex] &&
                    isTimestampInside(data[currentIndex][0]);

                  if (isInside) {
                    const previousIndex = currentIndex - 1;
                    const nextIndex = currentIndex + 1;

                    const thisPos = label[0];

                    const previousLabel = data[previousIndex]
                      ? data[previousIndex][1]
                      : undefined;
                    const thisLabel = label[1];
                    const nextLabel = data[nextIndex]
                      ? data[nextIndex][1]
                      : undefined;

                    const nextOneTime = data[nextIndex]
                      ? data[nextIndex][0]
                      : undefined;

                    const isFirst =
                      (currentIndex === 0 &&
                        data[currentIndex] &&
                        isTimestampInside(thisPos)) ||
                      (data[previousIndex] &&
                        !isTimestampInside(data[previousIndex][0]));

                    const isLast =
                      data[currentIndex] &&
                      isTimestampInside(thisPos) &&
                      data[nextIndex] &&
                      !isTimestampInside(data[nextIndex][0]);

                    const tooClose = thisPos - lastShownTime <= safeDistance;
                    const tooCloseMinimumDistance =
                      thisPos - lastShownTime <= minimumDistance;
                    const tooCloseToNextOne =
                      nextOneTime !== undefined
                        ? nextOneTime - thisPos <= safeDistance
                        : false;
                    const tooCloseToLastPeak =
                      thisPos - lastShownPeakTime <= safeDistance;

                    // Trend change detection
                    let trendChange = false;
                    if (!!currentTrend && nextLabel !== undefined) {
                      if (currentTrend === 'down' && nextLabel > thisLabel) {
                        trendChange = true;
                      } else if (
                        currentTrend === 'up' &&
                        nextLabel < thisLabel
                      ) {
                        trendChange = true;
                      }
                    }

                    // Peak detection
                    const isPeak =
                      thisLabel === chart.series[0].dataMax ||
                      thisLabel === chart.series[0].dataMin;
                    const nextOneIsPeakDifferentThanCurrent =
                      (nextLabel === chart.series[0].dataMax ||
                        nextLabel === chart.series[0].dataMin) &&
                      thisLabel !== nextLabel;

                    const isPeakAndFarEnoughFromOthers =
                      isInside &&
                      isPeak &&
                      (!nextOneIsPeakDifferentThanCurrent ||
                        (nextOneIsPeakDifferentThanCurrent &&
                          !tooCloseToNextOne)) &&
                      !tooCloseToLastPeak;

                    // Distance detection
                    const isFarEnough =
                      isInside &&
                      !isPeak &&
                      !tooClose &&
                      !nextOneIsPeakDifferentThanCurrent;

                    const isTrendChangeAndThereIsMinimumDistance =
                      trendChange &&
                      !tooCloseMinimumDistance &&
                      !lastOneWasFirst;

                    const shouldShowLabel =
                      isFirst ||
                      isLast ||
                      isPeakAndFarEnoughFromOthers ||
                      isFarEnough ||
                      isTrendChangeAndThereIsMinimumDistance;

                    if (previousLabel !== undefined) {
                      if (previousLabel < thisLabel) {
                        currentTrend = 'up';
                      } else if (previousLabel > thisLabel) {
                        currentTrend = 'down';
                      }
                    }

                    if (shouldShowLabel) {
                      lastOneWasFirst = isFirst;
                      lastShownTime = thisPos;

                      if (isPeak) {
                        lastShownPeakTime = thisPos;
                      }
                    }

                    // Enabling any of this affects HEAVILY the performance!
                    // Logger.debug('[OverlappingDetection][%s] /////////////////////', signalType);
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] maximumAllowedLabels',
                    //   signalType,
                    //   maximumAllowedLabels
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] totalNonTrendingAllowedLabels',
                    //   signalType,
                    //   totalNonTrendingAllowedLabels
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] totalTrendingAllowedLabels',
                    //   signalType,
                    //   totalTrendingAllowedLabels
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] signalHeight',
                    //   signalType,
                    //   signalHeight
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] currentRange',
                    //   signalType,
                    //   currentRange
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] safeDistance',
                    //   signalType,
                    //   safeDistance
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] minimumDistance',
                    //   signalType,
                    //   minimumDistance
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] currentIndex',
                    //   signalType,
                    //   currentIndex
                    // );
                    // Logger.debug('[OverlappingDetection][%s] isInside', signalType, isInside);
                    // Logger.debug('[OverlappingDetection][%s] thisPos', signalType, thisPos);
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] previousIndex',
                    //   signalType,
                    //   previousIndex
                    // );
                    // Logger.debug('[OverlappingDetection][%s] nextIndex', signalType, nextIndex);
                    // Logger.debug('[OverlappingDetection][%s] thisLabel', signalType, thisLabel);
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] previousLabel',
                    //   signalType,
                    //   previousLabel
                    // );
                    // Logger.debug('[OverlappingDetection][%s] nextLabel', signalType, nextLabel);
                    // Logger.debug('[OverlappingDetection][%s] isFirst', signalType, isFirst);
                    // Logger.debug('[OverlappingDetection][%s] isLast', signalType, isLast);
                    // Logger.debug('[OverlappingDetection][%s] tooClose', signalType, tooClose);
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] tooCloseMinimumDistance',
                    //   signalType,
                    //   tooCloseMinimumDistance
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] tooCloseToNextOne',
                    //   signalType,
                    //   tooCloseToNextOne
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] tooCloseToLastPeak',
                    //   signalType,
                    //   tooCloseToLastPeak
                    // );
                    // Logger.debug('[OverlappingDetection][%s] trendChange',signalType,trendChange);
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] currentTrend',
                    //   signalType,
                    //   currentTrend
                    // );
                    // Logger.debug('[OverlappingDetection][%s] isPeak', signalType, isPeak);
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] nextOneIsPeak',
                    //   signalType,
                    //   nextOneIsPeakDifferentThanCurrent
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] isPeakAndFarEnoughFromOthers',
                    //   signalType,
                    //   isPeakAndFarEnoughFromOthers
                    // );
                    // Logger.debug('[OverlappingDetection][%s] isFarEnough',signalType,isFarEnough);
                    // Logger.debug(
                    //   '[OverlappingDetection] isTrendChangeAndThereIsMinimumDistance',
                    //   signalType,
                    //   isTrendChangeAndThereIsMinimumDistance
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] lastOneWasFirst',
                    //   signalType,
                    //   lastOneWasFirst
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] lastShownTime',
                    //   signalType,
                    //   lastShownTime
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] lastShownPeakTime',
                    //   signalType,
                    //   lastShownPeakTime
                    // );
                    // Logger.debug(
                    //   '[OverlappingDetection][%s] shouldShowLabel',
                    //   signalType,
                    //   shouldShowLabel
                    // );
                    // Logger.debug('[OverlappingDetection][%s] /////////////////////', signalType);

                    if (shouldShowLabel) {
                      dataLabels.push({
                        timestamp: label[0],
                        value: label[1],
                        position:
                          SignalRenderingService.calculateDataLabelPosition(
                            label,
                            currentExtremes.min,
                            currentExtremes.max,
                            chart.yAxis[0].min,
                            chart.yAxis[0].max,
                            signalInfo.screenPosition?.height,
                          ),
                      });
                    }
                  }
                });
              }

              /* Logger.debug(
                '[redrawDataLabels][%s] Prepared dataLabels. Took (ms):',
                signalType,
                Date.now() - time
              ); */

              const updatingTime = Date.now();
              // Logger.debug('[redrawDataLabels][%s] Updating dataLabels...', signalType);
              updateDataLabels({
                containerSize: {
                  height: signalHeight,
                  width: ScoringRenderingService.getScoringCanvasWidth(),
                },
                extremes: currentExtremes,
                labels: dataLabels,
              });
              Logger.debug(
                '[redrawDataLabels][%s] Updated dataLabels. Took (in ms):',
                signalType,
                Date.now() - updatingTime,
              );
            }

            /* Logger.debug(
              '[redrawDataLabels][%s] Total dataLabels took (ms):',
              signalType,
              Date.now() - time
            ); */
          }

          // Logger.log('[redrawDataLabels] redrawDataLabels done');
        }
      }

      resolve(chart);
    }),
  addDataQueries: (chart: Highcharts.Chart) =>
    new Promise((resolve) => {
      const signalType = chartTools.getSignalType(chart);
      const signalInfo = chartTools.getSignalInfo(signalType);
      if (signalInfo) {
        Logger.log('[addDataQueries] event for series: %s', signalInfo.name);

        Logger.log(
          '[addDataQueries][%s] Checking if there is something visible on screen.',
          signalInfo.name,
        );
        const currentExtremes = chartRangeTools.getCurrentExtremes();
        const currentEpochExtremes =
          chartRangeTools.getCurrentExtremesInEpoch();
        const isQueryNeededForExtremes = chartTools.dataHasGaps(
          signalInfo,
          currentEpochExtremes,
        );
        if (isQueryNeededForExtremes) {
          Logger.warn(
            '[addDataQueries][%s] Urgent query needed!',
            signalInfo.name,
          );
          chartTools.requestDataForRange(
            signalInfo,
            {
              epochs: currentEpochExtremes.last - currentEpochExtremes.first,
              fromTime: currentExtremes.min,
              toTime: currentExtremes.max,
              fromEpoch: currentEpochExtremes.first,
              toEpoch: currentEpochExtremes.last,
            },
            chart,
          );
        }

        const currentDataRange = chartRangeTools.getCurrentDataRange();
        if (currentDataRange) {
          Logger.log(
            '[addDataQueries][%s] Checking if there are gaps',
            signalInfo.name,
          );
          const isQueryNeededForDataRange = chartTools.dataHasGaps(signalInfo, {
            first: currentDataRange.fromEpoch,
            last: currentDataRange.toEpoch,
          });
          if (isQueryNeededForDataRange) {
            Logger.log(
              '[addDataQueries][%s] Query is needed!',
              signalInfo.name,
            );
            chartTools.requestDataForRange(signalInfo, currentDataRange, chart);
          }
        }
      }

      Logger.log('[addDataQueries] event done.');

      resolve(chart);
    }),
  showLoadingIfNeeded: (chart: Highcharts.Chart) =>
    new Promise((resolve) => {
      const signalType = chartTools.getSignalType(chart);
      const signalInfo = chartTools.getSignalInfo(signalType);

      if (signalInfo) {
        // Logger.log('[showLoadingIfNeeded][%s] init', signalInfo.name);
        const currentEpochExtremes =
          chartRangeTools.getCurrentExtremesInEpoch();
        const shouldShowLoading = chartTools.dataHasGaps(
          signalInfo,
          currentEpochExtremes,
        );
        const setIsLoading = isLoadingMap.get(signalInfo.type);

        if (shouldShowLoading) {
          chartTools.showLoading(chart);
          if (setIsLoading) setIsLoading(true);
        } else {
          chartTools.hideLoading(chart);
          if (setIsLoading) setIsLoading(false);
        }

        // Logger.log('[showLoadingIfNeeded][%s] end', signalInfo.name);
      }

      resolve(chart);
    }),
  onAfterUserMoving: () => {
    return new Promise((resolve) => {
      if (!chartEvents.isUserMoving()) {
        Logger.log('[onAfterUserMoving] Entering');
        const promises: Promise<unknown>[] = [];
        chartTools
          .getCharts({
            excludeEpochChart: true,
            excludeNavigator: true,
            withAvailableSeries: true,
            onlyVisible: true,
          })
          .forEach((chart) => {
            promises.push(
              SignalRenderingService.addDataToCurrentAndNextExtremes(
                chart,
              ).then(SignalRenderingService.autoScaleIfNeeded),
            );
          });

        Promise.all(promises)
          .then(() =>
            sheetTools.selectBestEpochForCurrentExtremes({ forceRedraw: true }),
          )
          .then(() => {
            Logger.debug(
              '[onAfterUserMoving] Waiting animation frame to end the busy state',
            );
            requestAnimationFrame(() => {
              resolve(true);
            });
          });
      } else {
        resolve(true);
      }
    });
  },
  removeHighlightedEpochs: (chart: Highcharts.Chart) => {
    return new Promise((resolve) => {
      const plotBands = chartTools.getPlotBands(chart);
      plotBands
        .find(
          (plotBand) =>
            (plotBand as ExtendedPlotBand).id === HighlightedPlotbandId,
        )
        ?.destroy();
      resolve(chart);
    });
  },
  registerUpdateDataLabelsFunction: (
    signalType: SignalType,
    fn: UpdateDataLabelsFunction,
  ) => {
    updateDataLabelMap.set(signalType, fn);
  },
  registerSetIsLoadingFunction: (
    signalType: SignalType,
    fn: SetIsLoadingFunction,
  ) => {
    isLoadingMap.set(signalType, fn);
  },
  calculateDataLabelPosition: (
    label: number[],
    extremeMin: number,
    extremeMax: number,
    axisMin: number | null,
    axisMax: number | null,
    height?: number,
  ) => {
    const timestamp = label[0];
    const diffWithExtremeMax = extremeMax - extremeMin;
    const diffWithLabel = timestamp - extremeMin;
    const containerWidth = ScoringRenderingService.getScoringCanvasWidth();
    const labelHeight = LABEL_FONT_SIZE;

    let left = (diffWithLabel * containerWidth) / diffWithExtremeMax;
    let bottom = 0;
    if (axisMax !== null && axisMin !== null && height !== undefined) {
      const value = label[1];
      const diffWithAxisMax = axisMax - axisMin;
      const diffWithLabelValue = value - axisMin;
      bottom = (diffWithLabelValue * height) / diffWithAxisMax;

      if (bottom + labelHeight + 1 > height) {
        bottom -= labelHeight + 5;
      }

      if (left + labelHeight + 1 > containerWidth) {
        left -= labelHeight / 2;
      }
    }

    const dataLabelPosition: DataLabelPosition = {
      left,
      bottom,
      originalExtremeMin: extremeMin,
      originalExtremeMax: extremeMax,
    };

    // Logger.debug('[eventDetailDataToCanvasEvent] ----');
    // Logger.debug('[eventDetailDataToCanvasEvent] scoringCanvasPosition', scoringCanvasPosition);
    // Logger.debug('[eventDetailDataToCanvasEvent] distanceFromTop', distanceFromTop);
    // Logger.debug('[eventDetailDataToCanvasEvent] left', left);
    // Logger.debug('[eventDetailDataToCanvasEvent] height', height);
    // Logger.debug('[eventDetailDataToCanvasEvent] width', width);
    // Logger.debug('[eventDetailDataToCanvasEvent] containerWidth', containerWidth);
    // Logger.debug('[eventDetailDataToCanvasEvent] ----');

    return dataLabelPosition;
  },
  autoScaleIfNeeded: (chart: Highcharts.Chart) => {
    return new Promise((resolve) => {
      const signalType = chartTools.getSignalType(chart);
      const signalInfo = chartTools.getSignalInfo(signalType);
      if (signalType && signalInfo?.yAxisAutoScaleOnFirstRender) {
        const wasAutoScaled = chartTools.getCustomData(
          chart,
          'yAxisAutoScaled',
        );

        if (!wasAutoScaled) {
          chartTools.scaleToFit(signalType);
        }
      }

      resolve(chart);
    });
  },
};

export default SignalRenderingService;
