import React from 'react';
import { useSnackbar, SnackbarKey, SnackbarAction } from 'notistack';
import {
  NotificationAPIBuilder,
  EnqueueSnackbar,
  NotificationOptions,
  NotificationServiceInterface,
  CloseSnackbar,
  NotificationKeyNotNull,
  NotificationServiceAPI,
  NotificationServiceAction,
} from '../interfaces/notification-service';
import ActionOrDismiss from '../components/ActionOrDismiss/ActionOrDismiss';

const actions: Map<NotificationServiceAction, SnackbarAction> = new Map();
const notificationAPIBuilder: NotificationAPIBuilder = (
  enqueueSnackbar: EnqueueSnackbar,
  closeSnackbar: CloseSnackbar,
) => ({
  send: (message: string, opts?: NotificationOptions) => {
    const options = opts || {};

    return enqueueSnackbar(message, {
      about: options.uniqueId,
      variant: options.variant,
      persist: options.persist,
      action: options.action
        ? actions.get(options.action)
        : options.customAction,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      content: options.content,
    });
  },
  close: (key: NotificationKeyNotNull) => closeSnackbar(key),
  closeAll: () => closeSnackbar(),
});

let notificationAPI: NotificationServiceAPI;

const NotificationService: NotificationServiceInterface = {
  useNotifications: () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    notificationAPI = notificationAPIBuilder(enqueueSnackbar, closeSnackbar);

    const dismissAction = (key: SnackbarKey) => (
      <ActionOrDismiss
        dismissText="Dismiss"
        dismissOnClick={() => NotificationService.close(key)}
      />
    );
    actions.set('dismiss', dismissAction);
  },
  send: (message: string, options?: NotificationOptions) =>
    notificationAPI.send(message, options),
  close: (key: NotificationKeyNotNull) => notificationAPI.close(key),
  closeAll: () => notificationAPI.closeAll(),
};

export default NotificationService;
