import React from 'react';

import { Paper, makeStyles, Typography, fade } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ReportCommentData } from '../../queries/report';
import themeProvider from '../../utils/theme';
import ReportComment from './ReportComment';
import ReportAddComment from './ReportAddComment';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    margin: theme.spacing(0, 1),
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    '@media print': {
      boxShadow: 'none',
      padding: 0,
      background: `${
        themeProvider.getType('LIGHT').palette.background.paper
      } !important`,
      color: theme.palette.getContrastText(
        themeProvider.getType('LIGHT').palette.background.paper,
      ),
      marginTop: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  error: {
    margin: theme.spacing(2),
  },
  noComments: {
    fontStyle: 'italic',
    fontSize: 14,
    textAlign: 'left',
    color: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.54,
    ),
  },
}));

interface ReportCommentListProps {
  loading: boolean;
  error?: string;
  comments?: ReportCommentData[];
  submittedComments?: ReportCommentData[];
  handleSubmitComment: (message: string) => Promise<boolean>;
}

function ReportCommentList(props: ReportCommentListProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {!props.error && (
        <Paper data-cy="ReportCommentsPaper" className={classes.root}>
          <div>
            <Typography variant="h6" className={classes.title}>
              Comments
              {props.comments && (
                <ReportAddComment handleSubmit={props.handleSubmitComment} />
              )}
            </Typography>
            <div>
              {!props.loading ? (
                <>
                  {props.comments?.map((comment) => (
                    <ReportComment
                      key={JSON.stringify(comment)}
                      comment={comment}
                    />
                  ))}
                  {props.submittedComments?.map((comment) => (
                    <ReportComment
                      key={JSON.stringify(comment)}
                      comment={comment}
                      scrollIntoView={
                        Date.now() - comment.date.valueOf() < 1000
                      }
                    />
                  ))}
                  {!props.error &&
                    (!props.comments || props.comments?.length === 0) &&
                    (!props.submittedComments ||
                      props.submittedComments?.length === 0) && (
                      <>
                        <Typography
                          align="center"
                          className={classes.noComments}
                        >
                          No comments yet.
                        </Typography>
                      </>
                    )}
                </>
              ) : (
                <Skeleton variant="text" animation="wave" width={400} />
              )}
            </div>
          </div>
        </Paper>
      )}
    </>
  );
}

export default ReportCommentList;
