import React from 'react';

import FlexContainer from '../Shared/FlexContainer';
import { RecordingPartData } from './OverviewParts';
import OverviewPartOpenSignalSheet from './OverviewPartOpenSignalSheet';
import OverviewPartSubmitPart from './OverviewPartSubmitPart';

interface OverviewPartActionsProps {
  data: RecordingPartData;
}

const OverviewPartActions = (props: OverviewPartActionsProps): JSX.Element => {
  return (
    <FlexContainer
      alignRight
      column
      data-cy={`Actions-Part${props.data.partId}`}
    >
      <OverviewPartOpenSignalSheet
        recordingPart={props.data}
        sheetId="RespiratorySheet"
      />
      <OverviewPartSubmitPart recordingPart={props.data} />
    </FlexContainer>
  );
};

export default OverviewPartActions;
