import React from 'react';
import { darken, makeStyles, Paper, Typography } from '@material-ui/core';
import themeProvider, { darkenOrLighten } from '../../utils/theme';

interface KeyboardKeyProps {
  children: string;
}

const useStyles = makeStyles((theme) => ({
  key: {
    backgroundColor: darkenOrLighten(
      theme.id,
      theme.palette.background.paper,
      0.2,
      0.2,
    ),
    color: theme.palette.getContrastText(theme.palette.background.paper),
    margin: theme.spacing(0.5, 0.25),
    padding: theme.spacing(0.5),
    height: 24,
    minWidth: 24,
    textAlign: 'center',
    '@media print': {
      backgroundColor: darken(
        themeProvider.getType('LIGHT').palette.background.paper,
        0.2,
      ),
      color: theme.palette.getContrastText(
        themeProvider.getType('LIGHT').palette.background.paper,
      ),
    },
  },
  text: {
    fontSize: 11,
  },
}));

const KeyboardKey = (props: KeyboardKeyProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.key} component="span">
        <Typography className={classes.text}>{props.children}</Typography>
      </Paper>
    </>
  );
};

export default KeyboardKey;
