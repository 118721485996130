import {
  FormControl,
  FormGroup,
  makeStyles,
  Toolbar,
  createStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import ButtonWithBackIcon from './ButtonWithBackIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1.5, 2, 0.5, 2),
    },
    actions: {
      padding: 0,
      margin: theme.spacing(1, 2),
      alignItems: 'flex-end',
    },
    control: {
      margin: theme.spacing(0, 1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  }),
);

export default function GoBackToolbar(): JSX.Element {
  const classes = useStyles();

  return (
    <Toolbar className={classes.actions}>
      <FormControl component="fieldset" className={classes.control}>
        <FormGroup>
          <ButtonWithBackIcon>Go back</ButtonWithBackIcon>
        </FormGroup>
      </FormControl>
    </Toolbar>
  );
}
