import SheetDefinition, {
  SheetId,
  SheetPath,
  SignalDefinition,
} from '../../interfaces/sheet-definition';
import respiratory from './definitions/sheets/Respiratory';
import UserAttributesService from '../../services/userAttributesService';
import {
  SignalOrderUserPreference,
  SignalOrderUserPreferenceSheet,
} from '../../interfaces/signal-ordering';
import studyTools from '../StudyOverview/studyTools';

export type Definitions = Map<SheetId, SheetDefinition>;

const definitions: SheetDefinition[] = [];
definitions.push(respiratory);

interface SheetDefinitionsInterface {
  get: (type: SheetId, customId?: number) => SheetDefinition | undefined;
  getAll: () => SheetDefinition[];
  getTypeByPath: (path: SheetPath) => SheetId | undefined;
  sortSignalsByUserAttributes: (
    sheetDefinition: SheetDefinition,
  ) => SheetDefinition;
  saveSignalOrder: (sheetName: string, signals: SignalDefinition[]) => void;
  restoreDefaultSignalOrder: (sheetName: string) => SignalDefinition[];
}

const SheetDefinitions: SheetDefinitionsInterface = {
  getAll: () =>
    definitions.map((def) => SheetDefinitions.sortSignalsByUserAttributes(def)),
  get: (type: SheetId, customId?: number) => {
    if (type === 'CustomSheet' && customId) {
      const signals = studyTools.getSignalDefinitionForCustomSheet(customId);
      if (signals) {
        return {
          name: 'Custom',
          path: 'custom-sheet',
          signals,
          type: 'CustomSheet',
        };
      }
    } else {
      const rawDefinition = definitions.find((def) => def.type === type);
      if (rawDefinition) {
        const sortedDefinition =
          SheetDefinitions.sortSignalsByUserAttributes(rawDefinition);

        const definition: SheetDefinition = { ...sortedDefinition };
        const signals = studyTools.automaticAlternativeSignalSelector([
          ...definition.signals,
        ]);

        definition.signals = [...signals];
        return definition;
      }
    }
    return undefined;
  },
  getTypeByPath: (path: SheetPath) => {
    if (path === 'custom-sheet') return 'CustomSheet';
    return definitions.find((def) => def.path === path)?.type;
  },
  sortSignalsByUserAttributes: (
    sheetDefinition: SheetDefinition,
  ): SheetDefinition => {
    const newSheetDefinition = { ...sheetDefinition };
    const storedUserAttributes =
      UserAttributesService.getPreference<SignalOrderUserPreference>(
        'SignalOrder',
      );
    if (storedUserAttributes) {
      const signalsOrder = storedUserAttributes.find(
        (def) => def.name === newSheetDefinition.name,
      );
      if (signalsOrder) {
        const selectedSignalOrder: SignalDefinition[] = [];
        signalsOrder.signals
          .sort((a, b) => a.order - b.order)
          .forEach(({ name }) => {
            const signal = newSheetDefinition.signals.find(
              (s) => s.name === name,
            );
            if (signal) selectedSignalOrder.push(signal);
          });
        newSheetDefinition.signals = [...selectedSignalOrder];
        newSheetDefinition.customOrder = true;
      }
    }
    return newSheetDefinition;
  },
  saveSignalOrder: (sheetName: string, signals: SignalDefinition[]) => {
    const arrayToSave: SignalOrderUserPreferenceSheet = {
      name: sheetName,
      signals: signals.map((definition, index) => ({
        name: definition.name,
        order: index,
      })),
    };
    const currentSignalOrdering =
      UserAttributesService.getPreference<SignalOrderUserPreference>(
        'SignalOrder',
      ) || [];
    const filteredSignals = currentSignalOrdering.filter(
      (sheet) => sheet.name !== sheetName,
    );
    filteredSignals.push(arrayToSave);

    UserAttributesService.setPreference('SignalOrder', filteredSignals);
  },
  restoreDefaultSignalOrder: (sheetName: string) => {
    const currentSignalOrdering =
      UserAttributesService.getPreference<SignalOrderUserPreference>(
        'SignalOrder',
      ) || [];
    const filteredSignals = currentSignalOrdering.filter(
      (sheet) => sheet.name !== sheetName,
    );
    UserAttributesService.setPreference('SignalOrder', filteredSignals);
    const originalSignalOrder: SignalDefinition[] =
      definitions.find((def) => def.name === sheetName)?.signals || [];
    return [...originalSignalOrder];
  },
};

export default SheetDefinitions;
