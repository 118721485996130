import _ from 'underscore';
import { RecordingId } from '../queries/recording';
import {
  AnalysisPeriod,
  GetAnalysisPeriodsParameters,
  GetAnalysisPeriodsResult,
  AnalysisPeriodsMutationParameters,
} from '../queries/analysisPeriods';
import queryManager from './queryManager';
import Logger from '../utils/logger';
import ScoringService from './scoringService';
import eventChartTools from '../components/Chart/SignalChart/eventChartTools';
import { MarkerOverrideOnChange } from '../interfaces/markers';
import { SignalEventDetailData } from '../interfaces/signal-event-detail-props';
import { UpdateEventParams } from '../interfaces/scoring-service';
import NotificationService from './notificationService';
import { NotificationKey } from '../interfaces/notification-service';
import Analytics from './analytics';
import chartRangeTools from '../components/SignalSheet/chartRangeTools';
import { ScoringInsights } from '../queries/scoringInsights';

export interface PeriodOverlayPosition {
  left: number;
  width: number;
}
export interface PeriodOverlay {
  start: number;
  end: number;
  position: PeriodOverlayPosition;
  partId?: number;
  analysisPeriod?: CombinedAnalysisPeriod;
}

export type CombinedAnalysisPeriod = AnalysisPeriod & {
  startMarker: SignalEventDetailData;
  endMarker: SignalEventDetailData;
};

interface AnalysisPeriodsServiceInterface {
  getPeriods: (
    recordingId: RecordingId,
    scoringId: string,
  ) => Promise<AnalysisPeriod[]>;
  setPeriods: (
    recordingId: RecordingId,
    scoringId: string,
    periods: AnalysisPeriod[],
  ) => void;
  setPeriodsLater: (
    recordingId: RecordingId,
    scoringId: string,
    periods: AnalysisPeriod[],
  ) => void;
  clearPeriods: () => void;
  processPeriods: (periods: AnalysisPeriod[]) => AnalysisPeriod[];
  retrievePeriodEvents: () => SignalEventDetailData[];
  retrievePeriodEventsForCurrentPart: () => SignalEventDetailData[];
  isNewChange: (analysisPeriods: AnalysisPeriod[]) => boolean;
  storeLastPeriodsAsHash: (analysisPeriods: AnalysisPeriod[]) => void;
  scoringInsightsToPeriods: (
    scoringInsights: ScoringInsights,
  ) => AnalysisPeriod[];
  convertEventsToPeriods: (
    periodEvents: SignalEventDetailData[],
  ) => CombinedAnalysisPeriod[] | undefined;
  preparePeriodsForDisplay: (
    analysisPeriods: CombinedAnalysisPeriod[],
    edgeStart: string,
    edgeEnd: string,
    containerWidth: number,
  ) => PeriodOverlay[];
  onPeriodChange: MarkerOverrideOnChange;
}

let notificationKey: NotificationKey;
const analysisPeriodHistoryHash: string[] = [];

const AnalysisPeriodsService: AnalysisPeriodsServiceInterface = {
  getPeriods: (recordingId: RecordingId, scoringId: string) => {
    Logger.log('[AnalysisPeriodsService][getPeriods] Initializing');

    return new Promise((resolve, reject) => {
      queryManager
        .query<GetAnalysisPeriodsResult>('AnalysisPeriods', {
          recordingId,
          scoringId,
        } as GetAnalysisPeriodsParameters)
        .then((result) => {
          return AnalysisPeriodsService.processPeriods(result.analysisPeriods);
        })
        .then((periods: AnalysisPeriod[]) => resolve(periods))
        .catch((error) => reject(error));
    });
  },
  setPeriods: (
    recordingId: RecordingId,
    scoringId: string,
    periods: AnalysisPeriod[],
  ) => {
    Logger.log('[AnalysisPeriodsService][setPeriods] Initializing');
    AnalysisPeriodsService.storeLastPeriodsAsHash(periods);

    ScoringService.addMutation('analysisPeriods', {
      recordingId,
      scoringId,
      periods,
    } as AnalysisPeriodsMutationParameters);
  },
  setPeriodsLater: _.debounce(
    (...args) => AnalysisPeriodsService.setPeriods(...args),
    1000,
  ),
  clearPeriods: () => {
    const periodEvents = AnalysisPeriodsService.retrievePeriodEvents();
    const bulkId = _.uniqueId('UndoPeriodClear_');
    periodEvents.forEach((period) => {
      ScoringService.removeEvent(period.id, {
        send: false,
        updating: true,
        withUndoBulkId: bulkId,
      });
      ScoringService.forceMarkerDelete(period.id, { force: true });
    });
  },
  processPeriods: (periods: AnalysisPeriod[]) => {
    Logger.debug('[AnalysisPeriodsService][processPeriods] periods:', periods);
    AnalysisPeriodsService.storeLastPeriodsAsHash(periods);
    AnalysisPeriodsService.clearPeriods();
    const bulkId = _.uniqueId('UndoPeriod_');
    periods.forEach((period) => {
      const startEvent = eventChartTools.createSignalEventDetailData(
        'analysis-start',
        new Date(period.beginning).valueOf(),
        new Date(period.beginning).valueOf(),
      );
      ScoringService.storeEvent(startEvent, {
        send: false,
        local: true,
        updating: false,
        withUndoBulkId: bulkId,
      });

      const stopEvent = eventChartTools.createSignalEventDetailData(
        'analysis-stop',
        new Date(period.end).valueOf(),
        new Date(period.end).valueOf(),
      );
      ScoringService.storeEvent(stopEvent, {
        send: false,
        local: true,
        updating: false,
        withUndoBulkId: bulkId,
      });
    });

    return periods;
  },
  isNewChange: (analysisPeriods: AnalysisPeriod[]) => {
    /*  Logger.debug(
      '[AnalysisPeriodsService][isNewChange] lastAnalysisPeriodsHash',
      analysisPeriodHistoryHash,
    );
    Logger.debug(
      '[AnalysisPeriodsService][isNewChange] analysisPeriods',
      JSON.stringify(analysisPeriods),
    ); */
    const isNewChange = !analysisPeriodHistoryHash.some(
      (h) => h === JSON.stringify(analysisPeriods),
    );
    /*  Logger.debug(
      '[AnalysisPeriodsService][isNewChange] isNewChange',
      isNewChange,
    ); */
    return isNewChange;
  },
  storeLastPeriodsAsHash: (analysisPeriods: AnalysisPeriod[]) => {
    if (AnalysisPeriodsService.isNewChange(analysisPeriods)) {
      const periodsToStore: AnalysisPeriod[] = analysisPeriods.map(
        (period) => ({
          beginning: period.beginning,
          end: period.end,
        }),
      );
      analysisPeriodHistoryHash.push(JSON.stringify(periodsToStore));
    }
  },
  scoringInsightsToPeriods: (scoringInsights: ScoringInsights) => {
    const analysisPeriods: AnalysisPeriod[] = [];
    scoringInsights.parts?.forEach((part) => {
      const isSingleAnalysisPeriod = part.analysisPeriods?.length === 1;
      const period = isSingleAnalysisPeriod
        ? part.analysisPeriods?.[0]
        : undefined;

      if (period?.beginning && period.end) {
        analysisPeriods.push({
          beginning: period?.beginning,
          end: period?.end,
        });
      }
    });

    return analysisPeriods;
  },
  retrievePeriodEvents: () =>
    ScoringService.retrieveMarkersByType(['analysis-start', 'analysis-stop']),
  retrievePeriodEventsForCurrentPart: () =>
    ScoringService.retrieveMarkersByType([
      'analysis-start',
      'analysis-stop',
    ]).filter((event) => chartRangeTools.isTimestampWithinPart(event.start)),
  onPeriodChange: (
    recordingId: RecordingId,
    scoringId: string,
    newEvent: SignalEventDetailData,
    originalParams: UpdateEventParams,
  ) => {
    return new Promise(() => {
      Logger.log('[AnalysisPeriodsService][onPeriodChange] newEvent', newEvent);
      const periodEvents = AnalysisPeriodsService.retrievePeriodEvents();
      const oldEventIndex = periodEvents.findIndex(
        (event) => event.id === newEvent.id,
      );
      if (oldEventIndex === -1) {
        Logger.error(
          '[AnalysisPeriodsService][onPeriodChange] Updated event not found!',
        );
      } else {
        periodEvents[oldEventIndex] = newEvent;
      }

      const newAnalysisPeriods =
        AnalysisPeriodsService.convertEventsToPeriods(periodEvents);
      const hasFailed = newAnalysisPeriods === undefined;

      Logger.log(
        '[AnalysisPeriodsService][onPeriodChange] newAnalysisPeriods',
        newAnalysisPeriods,
      );

      if (notificationKey) NotificationService.close(notificationKey);
      if (hasFailed) {
        notificationKey = NotificationService.send(
          'The selected Analysis Periods are not valid.',
          {
            variant: 'error',
            action: 'dismiss',
          },
        );
        AnalysisPeriodsService.clearPeriods();
      } else if (newAnalysisPeriods) {
        const setPeriodsFn = originalParams.fromUndo
          ? AnalysisPeriodsService.setPeriodsLater
          : AnalysisPeriodsService.setPeriods;

        setPeriodsFn(
          recordingId,
          scoringId,
          newAnalysisPeriods.map((period) => ({
            beginning: period.beginning,
            end: period.end,
          })),
        );
        ScoringService.dispatchEvents(newEvent);

        Analytics.track.scoringChange({
          type: newEvent.type,
          group: 'Analysis Period',
          recordingId,
          action: 'Move',
          markerId: newEvent.id,
          movementDelta: originalParams.diff,
        });
      }

      ScoringService.updateEvent(newEvent, {
        send: false,
        local: true,
        operation: originalParams.operation,
        diff: originalParams.diff,
        ignoreOverrideFn: true,
        fromUndo: originalParams.fromUndo,
      });
    });
  },
  convertEventsToPeriods: (periodEvents: SignalEventDetailData[]) => {
    const newAnalysisPeriods: CombinedAnalysisPeriod[] = [];
    let currentBeginning: number | undefined;
    let startMarker: SignalEventDetailData | undefined;
    let hasFailed = false;

    const sortedPeriods = periodEvents.sort((a, b) => a.start - b.start);
    Logger.log(
      '[AnalysisPeriodsService][convertEventsToPeriods] Finding periods:',
      sortedPeriods,
    );
    sortedPeriods.forEach((periodEvent) => {
      if (!currentBeginning) {
        if (periodEvent.type === 'analysis-start') {
          currentBeginning = periodEvent.start;
          startMarker = periodEvent;
        } else if (periodEvent.type === 'analysis-stop') {
          Logger.error(
            '[AnalysisPeriodsService][convertEventsToPeriods]' +
              ' Found analysis-stop before analysis-start',
          );
          hasFailed = true;
        }
      } else if (currentBeginning) {
        if (periodEvent.type === 'analysis-start') {
          Logger.error(
            '[AnalysisPeriodsService][convertEventsToPeriods] Found two consecutive analysis-start',
          );
          hasFailed = true;
        } else if (periodEvent.type === 'analysis-stop' && startMarker) {
          newAnalysisPeriods.push({
            beginning: new Date(currentBeginning).toISOString(),
            end: new Date(periodEvent.start).toISOString(),
            startMarker,
            endMarker: periodEvent,
          });
          currentBeginning = undefined;
        }
      }
    });

    if (currentBeginning) {
      Logger.error(
        '[AnalysisPeriodsService][convertEventsToPeriods] Missing analysis-stop',
      );
      hasFailed = true;
    }

    if (!hasFailed) {
      return newAnalysisPeriods;
    }
    return undefined;
  },
  preparePeriodsForDisplay: (
    analysisPeriods: CombinedAnalysisPeriod[],
    edgeStart: string,
    edgeEnd: string,
    containerWidth: number,
  ) => {
    const overlayParts: PeriodOverlay[] = [];

    Logger.log('preparePeriodsForDisplay analysisPeriods', analysisPeriods);
    Logger.log('preparePeriodsForDisplay edgeStart', edgeStart);
    Logger.log('preparePeriodsForDisplay edgeEnd', edgeEnd);
    Logger.log('preparePeriodsForDisplay containerWidth', containerWidth);

    if (analysisPeriods && analysisPeriods.length > 0) {
      const edgeStartTime = new Date(edgeStart).valueOf();
      const edgeEndTime = new Date(edgeEnd).valueOf();

      analysisPeriods.forEach((period, index) => {
        const periodStart = new Date(period.beginning).valueOf();
        const periodEnd = new Date(period.end).valueOf();

        if (index === 0) {
          if (edgeStartTime < periodStart) {
            overlayParts.push({
              start: edgeStartTime,
              end: periodStart,
              position: eventChartTools.calculateOverlayPosition(
                edgeStartTime,
                edgeEndTime,
                edgeStartTime,
                periodStart,
                containerWidth,
              ),
            });
          }
        } else {
          const previousPeriodEnd = new Date(
            analysisPeriods[index - 1].end,
          ).valueOf();
          if (previousPeriodEnd < periodStart) {
            overlayParts.push({
              start: previousPeriodEnd,
              end: periodStart,
              position: eventChartTools.calculateOverlayPosition(
                edgeStartTime,
                edgeEndTime,
                previousPeriodEnd,
                periodStart,
                containerWidth,
              ),
            });
          }
        }
        overlayParts.push({
          start: periodStart,
          end: periodEnd,
          partId: index + 1,
          analysisPeriod: period,
          position: eventChartTools.calculateOverlayPosition(
            edgeStartTime,
            edgeEndTime,
            periodStart,
            periodEnd,
            containerWidth,
          ),
        });

        if (index === analysisPeriods.length - 1 && periodEnd < edgeEndTime) {
          overlayParts.push({
            start: periodEnd,
            end: edgeEndTime,
            position: eventChartTools.calculateOverlayPosition(
              edgeStartTime,
              edgeEndTime,
              periodEnd,
              edgeEndTime,
              containerWidth,
            ),
          });
        }
      });
    }

    return overlayParts;
  },
};

export default AnalysisPeriodsService;
