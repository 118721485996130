import {
  makeStyles,
  Popper,
  Paper,
  Typography,
  Grow,
  IconButton,
  ClickAwayListener,
  Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';

import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import ChangeEventTypeIcon from '@material-ui/icons/SwapVert';
import AlarmIcon from '@material-ui/icons/Alarm';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';

import { createStyles, fade, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import eventChartTools from '../Chart/SignalChart/eventChartTools';
import signalEventDetailService from './signalEventDetailService';
import SignalEventDetailPopupProps, {
  SignalEventDetailData,
} from '../../interfaces/signal-event-detail-props';
import EventService from '../../services/eventService';
import ScoringRenderingService from '../../services/scoringRenderingService';
import Logger from '../../utils/logger';
import { ZIndex } from '../../utils/theme';
import chartRangeTools from '../SignalSheet/chartRangeTools';
import { KeyCode } from '../SignalSheet/interfaces/keyboard-manager';
import KeyboardManager from '../SignalSheet/keyboardManager';
import ScoringService from '../../services/scoringService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 250,
      maxWidth: 300,
      backgroundColor: fade(theme.colors.modal.backgroundColor, 0.75),
      backdropFilter: 'blur(2px)',
      color: theme.colors.modal.color,
    },
    popper: {
      zIndex: ZIndex.EventPopup,
      willChange: 'auto !important',
    },
    name: {
      width: 145,
      paddingLeft: theme.spacing(1),
      fontWeight: 'bold',
    },
    detailList: {
      padding: theme.spacing(1, 2, 1, 2),
    },
    colorBar: {
      color: 'white',
      padding: theme.spacing(1),
      borderRadius: '4px 4px 0 0',
      boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 3px 0px',
    },
    actions: {
      position: 'absolute',
      top: 4,
      right: 4,
    },
    smallText: {
      fontSize: 9,
    },
    button: {
      padding: 5,
      height: 30,
      color: 'white',
    },
    detail: {
      display: 'grid',
      gridTemplateColumns: '30px auto',
    },
    detailIcon: {
      padding: theme.spacing(0.5),
    },
    detailText: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0.5),
    },
    caption: {
      color: fade(theme.colors.modal.color, 0.54),
    },
    hide: {
      display: 'none',
    },
  }),
);

const SignalEventDetailPopup = (
  props: SignalEventDetailPopupProps,
): JSX.Element => {
  const classes = useStyles();
  const API = signalEventDetailService;

  const { eventData } = props;

  const [hideTooltips, setHideTooltips] = React.useState(false);
  const [isReadOnlyScoring, setIsReadOnlyScoring] = React.useState(
    ScoringService.isReadOnly(),
  );

  useEffect(() => {
    const cbIds = [
      EventService.subscribe('KeyboardShortcut.DeleteEvent', () => {
        if (!ScoringService.isReadOnly()) {
          API.event.delete();
        }
      }),
      EventService.subscribe('WindowResize', API.popup.close),
      EventService.subscribe('Keyboard.KeyPressed', (event: KeyboardEvent) =>
        KeyboardManager.isEventThisKeyCode(event, KeyCode.Escape)
          ? API.popup.close()
          : null,
      ),
      EventService.subscribe(
        'ScoringChanged',
        (marker: SignalEventDetailData) => {
          if (API.popup.isOpenAndSameMarkerId(marker.id)) {
            if (
              marker.start === eventData?.start &&
              marker.end === eventData.end
            ) {
              API.refreshData();
            } else {
              API.popup.close();
            }
          }
        },
      ),
      EventService.subscribe<boolean>('ScoringReadOnly', (isReadOnly) =>
        setIsReadOnlyScoring(isReadOnly),
      ),
    ];
    return () => {
      EventService.unsubscribe(cbIds);
    };
  }, [props.eventData]);

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={(e) => {
        Logger.debug('[SignalEventDetailPopup] onClickAway', e);

        const closeClassExceptions = ['.scoring-shortcut'];
        const isCloseException = closeClassExceptions.some((exception) =>
          (e.target as Element).matches(exception),
        );

        if (!isCloseException) {
          API.popup.close();
        }
      }}
    >
      <Popper
        open={props.isOpen}
        anchorEl={props.anchorEl}
        disablePortal={false}
        placement="left-start"
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: true,
          },
          offset: {
            enabled: true,
            offset: '0,10',
          },
          preventOverflow: {
            enabled: true,
            boundariesElement:
              ScoringRenderingService.getScoringCanvasContainer()?.current,
          },
        }}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            timeout={{
              enter: 250,
              exit: 100,
            }}
            style={{
              transformOrigin:
                placement === 'left-start' ? 'center right' : 'center left',
            }}
          >
            <Paper
              className={classes.root}
              elevation={3}
              onMouseLeave={() => {
                setTimeout(() => setHideTooltips(false), 100);
              }}
              onMouseEnter={() => {
                setTimeout(() => setHideTooltips(false), 100);
              }}
            >
              {eventData ? (
                <>
                  <div
                    data-cy="EventDetailPopup"
                    className={classes.colorBar}
                    style={{
                      background: eventChartTools.getColorByEventType(
                        eventData.type,
                      ),
                    }}
                  >
                    <Typography
                      data-cy="EventDetailTitle"
                      className={classes.name}
                    >
                      {eventData.name}
                    </Typography>
                    <div className={classes.actions}>
                      <Tooltip
                        PopperProps={{ hidden: hideTooltips }}
                        title="Delete event"
                        placement="top"
                        arrow
                      >
                        <IconButton
                          data-cy="DeleteEventBtn"
                          className={clsx(classes.button, {
                            [classes.hide]: isReadOnlyScoring,
                          })}
                          onClick={API.event.delete}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        PopperProps={{ hidden: hideTooltips }}
                        title="Switch event type"
                        placement="top"
                        arrow
                      >
                        <IconButton
                          data-cy="SwitchTypeBtn"
                          className={clsx(classes.button, {
                            [classes.hide]: isReadOnlyScoring,
                          })}
                          onClick={() => {
                            setHideTooltips(true);
                            API.event.switchType();
                          }}
                        >
                          <ChangeEventTypeIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        PopperProps={{ hidden: hideTooltips }}
                        title="Close"
                        placement="top"
                        arrow
                      >
                        <IconButton
                          data-cy="CloseEventPopupBtn"
                          className={classes.button}
                          onClick={API.popup.close}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>

                  <div className={classes.detailList}>
                    <div className={classes.detail}>
                      <div className={classes.detailIcon}>
                        <Tooltip
                          PopperProps={{ hidden: hideTooltips }}
                          title="Start time"
                          placement="left"
                          arrow
                        >
                          <AlarmIcon fontSize="small" />
                        </Tooltip>
                      </div>
                      <div className={classes.detailText}>
                        <Typography
                          data-cy="EventDetailStartTime"
                          variant="body2"
                        >
                          {moment(eventData.start).format(
                            'YYYY-MM-DD HH:mm:ss',
                          )}
                        </Typography>
                        <Typography
                          data-cy="EventDetailDuration"
                          variant="caption"
                          className={classes.caption}
                        >
                          {chartRangeTools.calculateDuration(
                            eventData.start,
                            eventData.end,
                            {
                              short: false,
                            },
                          )}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.detail}>
                      <div className={classes.detailIcon}>
                        <Tooltip
                          PopperProps={{ hidden: hideTooltips }}
                          title="Epoch number"
                          placement="left"
                          arrow
                        >
                          <LocationOnIcon fontSize="small" />
                        </Tooltip>
                      </div>
                      <div className={classes.detailText}>
                        <Typography
                          data-cy="EventDetailEpochRange"
                          variant="body2"
                        >
                          {eventChartTools.getEpochRange(eventData)}
                        </Typography>
                      </div>
                    </div>

                    {eventData.extras.hasDrop && eventData.signalInfo
                      ? [
                          eventChartTools.calculatePeakToPeak(
                            eventData.start,
                            eventData.end,
                            eventData.signalInfo,
                          ),
                        ].map((drop) =>
                          drop > 0 ? (
                            <div key={drop} className={classes.detail}>
                              <div className={classes.detailIcon}>
                                <Tooltip
                                  PopperProps={{ hidden: hideTooltips }}
                                  title="Peak to Peak"
                                  placement="left"
                                  arrow
                                >
                                  <TrendingDownIcon fontSize="small" />
                                </Tooltip>
                              </div>
                              <div className={classes.detailText}>
                                <Typography variant="body2">
                                  {drop.toFixed(0)}%
                                </Typography>
                              </div>
                            </div>
                          ) : (
                            ''
                          ),
                        )
                      : ''}
                    <div className={classes.detail}>
                      <div className={classes.detailIcon}>
                        <Tooltip
                          PopperProps={{ hidden: hideTooltips }}
                          title="Scorer"
                          placement="left"
                          arrow
                        >
                          <PersonIcon fontSize="small" />
                        </Tooltip>
                      </div>
                      <div className={classes.detailText}>
                        <Typography data-cy="EventDetailScorer" variant="body2">
                          {eventData.isAutomatic ? 'Automatic' : 'Manual'}
                        </Typography>
                        <Typography
                          variant="caption"
                          className={classes.caption}
                        >
                          {eventData.scoredFrom}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </Paper>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default SignalEventDetailPopup;
