import React from 'react';
import { Paper, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Recording } from '../../queries/recording';
import { Report } from '../../queries/report';
import themeProvider from '../../utils/theme';
import OverviewPartDetail from '../StudyOverview/OverviewPartDetail';
import studyTools from '../StudyOverview/studyTools';
import reportTools from './reportTools';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    margin: theme.spacing(0, 1),
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    '@media print': {
      boxShadow: 'none',
      padding: 0,
      marginTop: theme.spacing(3),
      background: `${
        themeProvider.getType('LIGHT').palette.background.paper
      } !important`,
      color: theme.palette.getContrastText(
        themeProvider.getType('LIGHT').palette.background.paper,
      ),
    },
  },
  container: {
    // margin: 0,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

interface ReportPartGraphsProps {
  loading: boolean;
  recording?: Recording;
  report?: Report;
}

function ReportPartGraphs(props: ReportPartGraphsProps): JSX.Element {
  const classes = useStyles();

  React.useEffect(() => {
    if (props.report && props.recording) {
      studyTools.requestStudyGraphData([
        reportTools.insightsPartToRecordingPartData(
          props.recording,
          props.report,
        ),
      ]);
    }
  }, [props.report]);

  return (
    <Paper className={classes.root}>
      <div>
        <Typography variant="h6" className={classes.title}>
          Overview Graphs
        </Typography>
        <div className={classes.container}>
          {!props.loading ? (
            props.report &&
            props.recording && (
              <OverviewPartDetail
                data={reportTools.insightsPartToRecordingPartData(
                  props.recording,
                  props.report,
                )}
                forReports
              />
            )
          ) : (
            <Skeleton variant="text" animation="wave" width={400} />
          )}
        </div>
      </div>
    </Paper>
  );
}

export default ReportPartGraphs;
