import React from 'react';
import Draggable from 'react-draggable';
import clsx from 'clsx';

import { createStyles, fade, makeStyles, Typography } from '@material-ui/core';

import ScoringService from '../../../services/scoringService';
import { darkenOrLighten, ZIndex } from '../../../utils/theme';
import eventChartTools from '../SignalChart/eventChartTools';
import { ScoringCanvasEvent } from './ScoringCanvas';

const useStyles = makeStyles((theme) => {
  const eventBorder = darkenOrLighten(
    theme.id,
    theme.palette.background.paper,
    0.1,
    0.1,
  );

  return createStyles({
    xEvent: {
      position: 'absolute',
      zIndex: ZIndex.Baseline,
      top: 0,
      bottom: 0,
      textAlign: 'center',
      cursor: 'grab',
      borderLeft: `1px dashed ${fade(theme.colors.chart.line.default, 0.5)}`,
      display: 'grid',
      justifyContent: 'center',
      alignContent: 'center',
      gridTemplateColumns: 'max-content',
      pointerEvents: 'none',
    },
    textContainer: {
      transform: 'rotate(-90deg)',
      padding: theme.spacing(0.5),
      opacity: 1,
      background: fade(theme.palette.background.paper, 0.5),
      marginLeft: -5,
      borderRadius: 4,
      border: `1px solid ${eventBorder}`,
      pointerEvents: 'initial',
    },
    text: {
      color: theme.colors.chart.line.default,
    },
    readOnly: {
      pointerEvents: 'none',
    },
  });
});

interface XChartEventProps {
  event: ScoringCanvasEvent;
}

const XChartEvent = (props: XChartEventProps): JSX.Element => {
  const classes = useStyles();
  const { event } = props;

  return (
    <Draggable
      axis="x"
      bounds="parent"
      onStart={() => eventChartTools.onMarkerDragStart(event)}
      onStop={(e, position) =>
        eventChartTools.onMarkerDragStop(event, position)
      }
      disabled={ScoringService.isReadOnly()}
    >
      <div
        data-cy={event.name}
        className={classes.xEvent}
        style={{
          left: event.position.left,
          width: event.position.width,
          top: event.position.top,
          height: event.position.height,
        }}
      >
        <div
          className={clsx(classes.textContainer, {
            [classes.readOnly]: ScoringService.isReadOnly(),
          })}
        >
          <Typography className={classes.text} variant="caption">
            {event.name}
          </Typography>
        </div>
      </div>
    </Draggable>
  );
};

export default XChartEvent;
