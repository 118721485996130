import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { fade, Theme, Typography } from '@material-ui/core';
import themeProvider from '../../utils/theme';

interface AttributeListItemProps {
  field: string;
  children?: string | number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '60px 1fr',
  },
  field: {
    fontWeight: 400,
    color: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.54,
    ),
    '@media print': {
      color: fade(
        theme.palette.getContrastText(
          themeProvider.getType('LIGHT').palette.background.paper,
        ),
        0.54,
      ),
    },
  },
  value: {
    paddingLeft: theme.spacing(1),
  },
  missing: {
    fontStyle: 'italic',
  },
}));

function AttributeListItem(props: AttributeListItemProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="caption" className={classes.field}>
        {props.field}
      </Typography>
      <Typography
        variant="body2"
        className={clsx(classes.value, {
          [classes.missing]: props.children === undefined,
        })}
      >
        {props.children ? props.children : '-'}
      </Typography>
    </div>
  );
}

export default AttributeListItem;
