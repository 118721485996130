import axios from 'axios';
import UploadManager, {
  UploadQueue,
  OnPartUploadCallback,
  UploadQueueItem,
} from '../interfaces/upload-manager';
import Logger from '../../../utils/logger';
import { AttachedFile, UploadStatusSetter } from '../interfaces/uploader-tools';
import uploaderTools from './uploaderTools';
import { UrlMultipartInfo } from '../../../queries/uploadRecording/startFileUpload';

const UPLOAD_SLOTS = 5;

const uploadQueue: UploadQueue = [];
let activeUploads = 0;

let onPartUpload: OnPartUploadCallback = () => null;
let setUploadStatus: UploadStatusSetter = () => null;

let startTime = 0;
let transferredSize = 0;
let currentSpeed = 0;

const uploadManager: UploadManager = {
  getActiveUploads: () => activeUploads,
  getMaximumUploads: () => UPLOAD_SLOTS,
  getUploadQueueLength: () => uploadQueue.length,
  initialize: (
    newOnPartUpload: OnPartUploadCallback,
    newSetUploadStatus: UploadStatusSetter,
  ) => {
    onPartUpload = newOnPartUpload;
    setUploadStatus = newSetUploadStatus;
    startTime = Date.now();
    transferredSize = 0;
    const uploadStatus = uploadManager.getUploadStatus();
    newSetUploadStatus({ ...uploadStatus });
  },
  addUpload: (attachment: AttachedFile, parts: UrlMultipartInfo[]) => {
    return new Promise((resolve) => {
      Logger.debug(
        '[UploadRecording][addUpload] Adding new upload:',
        attachment,
      );

      parts.forEach((part) => {
        uploadManager
          .getFileBlob(attachment.file, part.byteOffset, part.byteCount)
          .then((blob) => {
            uploadQueue.push({
              filename: attachment.file.name,
              blob,
              type: attachment.file.type,
              url: part.url,
            });

            uploadManager.processNextUpload();
          });
      });

      resolve(true);
    });
  },
  processNextUpload: () => {
    Logger.log('[processNextUpload] Entering function...');

    if (activeUploads < UPLOAD_SLOTS) {
      if (uploadQueue.length > 0) {
        activeUploads += 1;
        uploadManager.logQueueStatus();

        const uploadItem: UploadQueueItem = uploadQueue
          .reverse()
          .pop() as UploadQueueItem;
        uploadManager
          .uploadPart(
            uploadItem.filename,
            uploadItem.blob,
            uploadItem.type,
            uploadItem.url,
          )
          .then(() => {
            activeUploads -= 1;
            transferredSize += uploadItem.blob.size;
            const uploadStatus = uploadManager.getUploadStatus();
            setUploadStatus(uploadStatus);
            uploadManager.processNextUpload();
          })
          .then(() => onPartUpload(uploadItem.filename))
          .catch(() => {
            Logger.log('[processNextUpload] Upload failed! Retrying...');
            activeUploads -= 1;
            uploadQueue.push(uploadItem);
            uploadManager.processNextUpload();
          });
        uploadManager.processNextUpload();
      } else {
        Logger.log('[processNextUpload] The upload items queue is empty!');
      }
    } else {
      Logger.log('[processNextUpload] No upload slots available!');
    }
  },
  logQueueStatus: () => {
    Logger.debug('[UploadRecording] ----- Queue Status -------');
    Logger.debug(
      '[UploadRecording] Upload slots: %d/%d',
      activeUploads,
      UPLOAD_SLOTS,
    );
    Logger.debug('[UploadRecording] Items in the queue:', uploadQueue.length);
    Logger.debug('[UploadRecording] Transferred size:', transferredSize);
    Logger.debug('[UploadRecording] Elapsed time:', Date.now() - startTime);
    Logger.debug(
      '[UploadRecording] Current speed: %s/s',
      uploaderTools.convertBytesSizeToText(uploadManager.getCurrentSpeed()),
    );
    Logger.debug('[UploadRecording] --------------------------');
  },
  getFileBlob: (file: File, byteOffset: number, byteCount?: number) => {
    Logger.log('[UploadRecording][getFileBlob] Entering function.');

    return new Promise((resolve) => {
      const blob = file.slice(
        byteOffset,
        byteCount ? byteOffset + byteCount : undefined,
      );
      Logger.log('[UploadRecording][getFileBlob] Done!');
      Logger.log(
        '[UploadRecording][getFileBlob] ---> Blob size:',
        uploaderTools.convertBytesSizeToText(blob.size),
      );

      resolve(blob);
    });
  },
  uploadPart: (filename: string, blob: Blob, type: string, url: string) => {
    Logger.log('[UploadRecording][uploadPart] Entering function.');
    let time = 0;

    return new Promise((resolve, reject) => {
      Logger.log('[UploadRecording][uploadPart] Uploading part');
      Logger.debug('[UploadRecording][uploadPart] ---> filename:', filename);
      Logger.debug('[UploadRecording][uploadPart] ---> url:', url);
      time = Date.now();

      return axios
        .put(url, blob, {
          headers: { 'Content-Type': type, 'Access-Control-Allow-Origin': '*' },
        })
        .then(() => {
          Logger.log(
            '[UploadRecording][uploadPart] Uploading done! Took (ms):',
            Date.now() - time,
          );

          resolve(true);
        })
        .catch((error) => {
          Logger.error('[UploadRecording][uploadPart] Failed!', error);
          Logger.error(
            '[UploadRecording][uploadPart] Response:',
            error.response,
          );
          reject(error);
        });
    });
  },
  getCurrentSpeed: () => {
    if (activeUploads > 0) {
      currentSpeed = transferredSize / (uploadManager.getElapsedTime() / 1000);
    }
    return Number.isNaN(currentSpeed) ? 0 : currentSpeed;
  },
  getElapsedTime: () => Date.now() - startTime,
  getUploadStatus: () => ({
    elapsedTime: uploadManager.getElapsedTime(),
    speed: uploadManager.getCurrentSpeed(),
    transferredSize,
    status: 'Uploading...',
  }),
};

export default uploadManager;
