import React from 'react';
import {
  makeStyles,
  Drawer,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  fade,
  Button,
} from '@material-ui/core';
import {
  VariableSizeGrid as Grid,
  areEqual,
  GridChildComponentProps,
} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import AlarmIcon from '@material-ui/icons/Alarm';

import RefreshIcon from '@material-ui/icons/Refresh';
import ScoringService from '../../services/scoringService';
import eventChartTools from '../Chart/SignalChart/eventChartTools';
import { SignalEventDetailData } from '../../interfaces/signal-event-detail-props';
import EventService from '../../services/eventService';
import chartRangeTools from '../SignalSheet/chartRangeTools';
import sheetTools from '../SignalSheet/sheetTools';
import { MarkerGroup, MarkerType } from '../../interfaces/markers';
import {
  MarkerGroups,
  MarkerDefinitions,
} from '../Chart/SignalChart/markerConstants';
import SignalDefinitions from '../SignalSheet/definitions/SignalDefinitions';
import { SignalType } from '../../interfaces/sheet-definition';
import { generateStripedGradient } from '../../utils/theme';

export interface EventViewerProps {
  setEventViewerOpened: React.Dispatch<React.SetStateAction<boolean>>;
  eventViewerOpened: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: fade(theme.palette.background.paper, 0.7),
    backdropFilter: 'blur(2px)',
  },
  container: {
    position: 'relative',
    width: 350,
    height: '100%',
    padding: theme.spacing(2),
    paddingBottom: 0,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  refresh: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
  markerContainer: {
    position: 'relative',
    width: '100%',
    // margin: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    borderRadius: 5,
    textTransform: 'initial',
    justifyContent: 'initial',
    alignItems: 'initial',
    color: '#fff',
    minWidth: 5,
    boxShadow: 'none',
    border: `1px solid ${fade('#000', 0.1)}`,
    '&:hover': {
      '&:after': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        content: '""',
        borderRadius: 5,
        backgroundColor: fade('#fff', 0.1),
      },
    },
  },
  markerContent: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gridTemplateRows: '1fr min-content',
    cursor: 'pointer',
    textAlign: 'left',
  },
  noMarkers: {
    margin: theme.spacing(2, 1),
    paddingRight: 18,
    textAlign: 'center',
    fontStyle: 'italic',
  },
  name: {
    fontSize: '13px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  duration: {
    fontSize: '11px',
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
  },
  icon: { fontSize: '12px', marginRight: 4, marginBottom: -2 },
  epoch: {
    fontWeight: 'bold',
  },
  signal: {
    fontStyle: 'italic',
    fontSize: 10,
  },
  list: {
    marginTop: theme.spacing(2),
    flex: '1',
    width: 335,
  },
  markerCount: {
    width: '100%',
  },
}));

const selectStyles = makeStyles((theme) => ({
  filter: {
    width: '100%',
    marginTop: theme.spacing(3),
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.23,
      ),
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: fade(
          theme.palette.getContrastText(theme.palette.background.paper),
          0.23,
        ),
      },
    },
  },
  text: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
  label: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    '&.Mui-focused': {
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
  },
  menu: {
    backgroundColor: theme.palette.secondary.light,
  },
  menuItem: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, 0.35),
    },
  },
  markerAmount: {
    marginLeft: theme.spacing(1),
  },
}));

type EventViewerFilterTypes = MarkerGroup | 'No filter';

const EventViewer = (props: EventViewerProps): JSX.Element => {
  const classes = useStyles();
  const selectClasses = selectStyles();

  const [totalMarkers, setTotalMarkers] = React.useState(0);
  const [filteredMarkers, setFilteredMarkers] = React.useState<
    SignalEventDetailData[]
  >([]);
  const [scoringChanged, setScoringChanges] = React.useState(false);
  const [filterGroup, setFilterGroup] =
    React.useState<EventViewerFilterTypes>('No filter');

  type MarkersPerGroup = Map<EventViewerFilterTypes, number>;
  const [markersPerGroup, setMarkersPerGroup] = React.useState<MarkersPerGroup>(
    new Map(),
  );

  const eventListRef = React.useRef<HTMLDivElement>(null);

  const filterMarkers = (
    allMarkers: SignalEventDetailData[],
    filter: EventViewerFilterTypes,
  ): [SignalEventDetailData[], MarkersPerGroup, number] => {
    const newMarkersPerGroup: MarkersPerGroup = new Map<
      EventViewerFilterTypes,
      number
    >();
    let newTotalMarkers = 0;

    const filterMarkerTypes: MarkerType[] = [];
    if (filter !== 'No filter') {
      const types = MarkerGroups.getEventTypes([filter]);
      filterMarkerTypes.push(...types);
    }

    const newFilteredMarkers: SignalEventDetailData[] = [];

    allMarkers.forEach((marker) => {
      if (!eventChartTools.isSleepStageMarker(marker)) {
        if (
          filterMarkerTypes.length === 0 ||
          filterMarkerTypes.includes(marker.type)
        ) {
          newFilteredMarkers.push(marker);
        }

        const markerGroup =
          MarkerDefinitions.get(marker.type)?.markerGroup || 'Unknown';
        const currentMarkersForThisGroup =
          newMarkersPerGroup.get(markerGroup) || 0;
        newMarkersPerGroup.set(markerGroup, currentMarkersForThisGroup + 1);
        newTotalMarkers += 1;
      }
    });

    return [newFilteredMarkers, newMarkersPerGroup, newTotalMarkers];
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterGroup(event.target.value as EventViewerFilterTypes);
    ScoringService.getAllMarkersForCurrentPart().then((allMarkers) => {
      const [newFilteredMarkers] = filterMarkers(
        allMarkers,
        event.target.value as EventViewerFilterTypes,
      );

      setFilteredMarkers(newFilteredMarkers);
    });
  };

  const getAllMarkers = () => {
    ScoringService.getAllMarkersForCurrentPart()
      .then((allMarkers) => {
        const [newFilteredMarkers, newMarkersPerGroup, newTotalMarkers] =
          filterMarkers(allMarkers, filterGroup);
        setMarkersPerGroup(newMarkersPerGroup);
        setFilteredMarkers(newFilteredMarkers);
        setTotalMarkers(newTotalMarkers);
      })
      .then(() => setScoringChanges(false));
  };

  const selectMarker = (marker: SignalEventDetailData) => {
    const epoch = chartRangeTools.convertToEpoch(marker.start);

    sheetTools.selectEpoch(epoch, { forceRedraw: true, toMarkerId: marker.id });
  };

  const Cell = React.memo(function cell({
    data,
    rowIndex,
    style,
  }: GridChildComponentProps) {
    const eventList = data as SignalEventDetailData[];
    const marker =
      eventList.length > rowIndex ? eventList[rowIndex] : undefined;

    return (
      <>
        {marker && (
          <div
            key={marker.id}
            style={{
              paddingRight: 18,
              ...style,
            }}
          >
            <Button
              data-cy={`EventViewerMarker-${marker.type}`}
              className={classes.markerContainer}
              style={{
                background: MarkerDefinitions.get(marker.type)?.extras
                  ?.stripedBackground
                  ? generateStripedGradient(
                      eventChartTools.getColorByEventType(marker.type),
                      0.55,
                      0.7,
                    )
                  : eventChartTools.getColorByEventType(marker.type),
                display: eventChartTools.isSleepStageMarker(marker)
                  ? 'none'
                  : 'grid',
              }}
              onClick={() => selectMarker(marker)}
            >
              <div className={classes.markerContent}>
                <Typography className={classes.name}>{marker.name}</Typography>
                <div style={{ display: 'none' }}>{marker.id}</div>
                <div style={{ display: 'none' }}>{marker.type}</div>
                <div style={{ display: 'none' }}>{marker.scoredFrom}</div>
                <Typography
                  variant="caption"
                  className={classes.epoch}
                  align="right"
                >
                  {eventChartTools.getEpochRange(marker)}
                </Typography>
                <Typography className={classes.duration}>
                  {marker.end !== marker.start ? (
                    <>
                      <AlarmIcon fontSize="small" className={classes.icon} />
                      {chartRangeTools.calculateDuration(
                        marker.start,
                        marker.end,
                        {
                          short: true,
                        },
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  className={classes.signal}
                  align="right"
                >
                  {SignalDefinitions.get(marker.scoredFrom as SignalType)
                    ?.name || 'Analysis'}
                </Typography>
              </div>
            </Button>
          </div>
        )}
      </>
    );
  },
  areEqual);

  React.useEffect(() => {
    const callbackId = EventService.subscribe('ScoringChanged', () => {
      if (props.eventViewerOpened) {
        setScoringChanges(true);
      }
    });

    if (props.eventViewerOpened) {
      getAllMarkers();
    }

    return () => {
      EventService.unsubscribe(callbackId);
    };
  }, [props.eventViewerOpened]);

  return (
    <Drawer
      anchor="right"
      open={props.eventViewerOpened}
      closeAfterTransition
      onClose={() => {
        props.setEventViewerOpened(false);
      }}
      classes={{ paper: classes.root }}
      BackdropProps={{ invisible: true }}
    >
      <div
        data-cy="EventViewerDrawer"
        className={classes.container}
        ref={eventListRef}
      >
        <Typography align="center" variant="h5">
          Event Viewer
        </Typography>
        <Typography
          align="center"
          variant="caption"
          component="div"
          className={classes.markerCount}
        >
          {totalMarkers} events
        </Typography>
        {scoringChanged && (
          <IconButton onClick={getAllMarkers} className={classes.refresh}>
            <RefreshIcon fontSize="small" />
          </IconButton>
        )}

        {props.eventViewerOpened && totalMarkers > 0 ? (
          <FormControl variant="outlined" className={selectClasses.filter}>
            <InputLabel className={selectClasses.label}>
              Filter by category
            </InputLabel>
            <Select
              data-cy="EventViewerFilterDropDown"
              value={filterGroup}
              onChange={handleChange}
              label="Filter by category"
              classes={{
                root: selectClasses.text,
                icon: selectClasses.menuItem,
              }}
              MenuProps={{
                classes: {
                  paper: selectClasses.menu,
                },
              }}
            >
              <MenuItem value="No filter" className={selectClasses.menuItem}>
                <em>No filter</em>
                <Typography
                  variant="caption"
                  className={selectClasses.markerAmount}
                >
                  ({totalMarkers})
                </Typography>
              </MenuItem>
              {MarkerGroups.getGroupNames()
                .sort((a, b) => a.localeCompare(b))
                .flatMap((group, index) =>
                  group !== 'Sleep Stage'
                    ? [
                        <MenuItem
                          data-cy="EventViewerCategoryLi"
                          key={index}
                          value={group}
                          className={selectClasses.menuItem}
                        >
                          {group}
                          <Typography
                            variant="caption"
                            className={selectClasses.markerAmount}
                          >
                            ({markersPerGroup.get(group) || 0})
                          </Typography>
                        </MenuItem>,
                      ]
                    : [],
                )}
            </Select>
          </FormControl>
        ) : (
          ''
        )}

        <div className={classes.list}>
          {totalMarkers > 0 &&
            (filteredMarkers.length > 0 ? (
              <AutoSizer>
                {({ height, width }) => (
                  <Grid
                    columnCount={1}
                    columnWidth={() => width}
                    height={height}
                    rowCount={filteredMarkers.length}
                    rowHeight={() => 48}
                    width={width}
                    itemData={filteredMarkers}
                    style={{ overflowX: 'hidden' }}
                  >
                    {Cell}
                  </Grid>
                )}
              </AutoSizer>
            ) : (
              <Typography className={classes.noMarkers} align="center">
                No events for the selected type.
              </Typography>
            ))}

          {totalMarkers === 0 && (
            <Typography className={classes.noMarkers} align="center">
              No events to show.
            </Typography>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default EventViewer;
