import React from 'react';

import { Typography, makeStyles } from '@material-ui/core';
import cssClasses from './authenticationError.module.scss';
import FeatureToggle from '../Shared/FeatureToggle';
import Analytics from '../../services/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10%',
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
}));

interface AuthenticationErrorProps {
  message?: string;
}

const AuthenticationError = (props: AuthenticationErrorProps): JSX.Element => {
  const classes = useStyles();

  React.useEffect(() => {
    Analytics.track.page('AuthenticationError');
    Analytics.track.event('ERROR_UNAUTHORIZED');
  }, []);

  return (
    <div className={classes.root}>
      <div className={cssClasses.image} />
      <Typography variant="h5">Access not authorized</Typography>
      <Typography>
        Make sure you have permissions to enter here and the URL is correct
      </Typography>
      <FeatureToggle enabledFor={['development', 'staging']}>
        <>
          {props.message && (
            <Typography variant="caption">
              {props.message.toString()}
            </Typography>
          )}
        </>
      </FeatureToggle>
    </div>
  );
};

export default AuthenticationError;
