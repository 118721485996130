import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import recordingListTools from './recordingListTools';

const RecordingListLoading = (): JSX.Element => {
  return (
    <>
      {[...Array(recordingListTools.getPageSize())].map((e, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={index} height={60} />
      ))}
    </>
  );
};

export default RecordingListLoading;
