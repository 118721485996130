import React from 'react';

import {
  Button,
  makeStyles,
  useTheme,
  ClickAwayListener,
  Popper,
  Grow,
  Paper,
  MenuItem,
  ListItemIcon,
  fade,
  Typography,
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import SettingsBrightnessIcon from '@material-ui/icons/SettingsBrightness';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { ZIndex } from '../../utils/theme';
import KeyboardShortcutsModal from './KeyboardShortcutsModal';
import Version from '../Shared/Version';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    minWidth: 20,
  },
  icon: {},
  menuContainer: {
    zIndex: ZIndex.Popup,
    willChange: 'auto !important',
  },
  menu: {
    backgroundColor: fade(theme.colors.modal.backgroundColor, 0.5),
    color: theme.colors.modal.color,
    padding: theme.spacing(0.5, 0),
    backdropFilter: 'blur(2px)',
  },
  menuIcon: {
    color: theme.colors.modal.color,
    marginRight: theme.spacing(1.5),
    minWidth: 0,
  },
  version: {
    textAlign: 'center',
  },
}));

interface AppBarOptionsProps {
  toggleTheme: () => void;
}

const AppBarOptions = (props: AppBarOptionsProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const [lastClosed, setLastClosed] = React.useState(0);
  const [enableClickAway, setEnableClickAway] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);

  const [keyboardModalOpen, setKeyboardModalOpen] = React.useState(false);
  const handleKeyboardModalOpen = () => {
    setLastClosed(Date.now());
    setOpen(false);
    setKeyboardModalOpen(true);
  };

  return (
    <>
      <Button
        data-cy="dotMenu"
        ref={anchorRef}
        className={classes.button}
        onClick={() =>
          setOpen((wasOpen) => {
            const wasRecentlyClosed = Date.now() - lastClosed < 250;
            if (wasOpen || wasRecentlyClosed) {
              setLastClosed(Date.now());
              return false;
            }
            return true;
          })
        }
      >
        <MoreVertIcon className={classes.icon} />
      </Button>

      <ClickAwayListener
        mouseEvent="onMouseUp"
        touchEvent="onTouchStart"
        onClickAway={() => {
          if (enableClickAway) {
            setEnableClickAway(false);
            setLastClosed(Date.now());
            setOpen(false);
          }
        }}
      >
        <Popper
          anchorEl={anchorRef.current}
          open={isOpen}
          placement="bottom-end"
          className={classes.menuContainer}
          modifiers={{
            offset: {
              enabled: true,
              offset: `0,${theme.spacing(1)}`,
            },
          }}
          transition
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              timeout={{
                enter: 250,
                exit: 100,
              }}
              style={{
                transformOrigin: 'middle up',
              }}
              onEntered={() => {
                setEnableClickAway(true);
              }}
            >
              <Paper className={classes.menu} elevation={3}>
                <MenuItem
                  data-cy="SwitchToDarkModeLi"
                  onClick={props.toggleTheme}
                >
                  <ListItemIcon className={classes.menuIcon}>
                    <SettingsBrightnessIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="body2">
                    {theme.id === 'LIGHT'
                      ? 'Switch to dark mode'
                      : 'Switch to light mode'}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleKeyboardModalOpen}>
                  <ListItemIcon className={classes.menuIcon}>
                    <KeyboardIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="body2">Keyboard shortcuts</Typography>
                </MenuItem>
                <MenuItem
                  data-cy="HelpContentBtn"
                  onClick={() => window.open('/help', '_blank')}
                >
                  <ListItemIcon className={classes.menuIcon}>
                    <HelpOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="body2">Help content</Typography>
                </MenuItem>
                <Version />
              </Paper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>

      <KeyboardShortcutsModal
        open={keyboardModalOpen}
        handleClose={() => setKeyboardModalOpen(false)}
      />
    </>
  );
};

export default AppBarOptions;
