import {
  SignalDefinition,
  SignalType,
} from '../../../interfaces/sheet-definition';
import { MarkerGroups } from '../../Chart/SignalChart/markerConstants';

const SignalDefinitions = new Map<SignalType, SignalDefinition>();

SignalDefinitions.set('Activity-Gravity', {
  name: 'Activity',
  shortName: 'Activity',
  type: 'Activity-Gravity',
  yMin: 0,
  yMax: 1,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Activity', 'Artifact']) },
  ],
});

SignalDefinitions.set('Snore.Envelope-Audio.dB', {
  name: 'Audio Volume',
  shortName: 'Audio',
  type: 'Snore.Envelope-Audio.dB',
  yMin: 40,
  yMax: 90,
  chartSize: 'smaller',
  markerSubscription: [
    {
      markerTypes: MarkerGroups.getEventTypes([
        'Snore',
        'Snore Breath',
        'Artifact',
      ]),
    },
  ],
});
SignalDefinitions.set('SpO2.Averaged-Probe', {
  name: 'SpO2',
  shortName: 'SpO2',
  type: 'SpO2.Averaged-Probe',
  labels: true,
  yMin: 80,
  yMax: 103,
  chartSize: 'normal',
  showGaps: true,
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Oxygen', 'Artifact']) },
  ],
});
SignalDefinitions.set('Resp.Flow-Cannula.Nasal', {
  name: 'Cannula Flow',
  shortName: 'Cannula',
  type: 'Resp.Flow-Cannula.Nasal',
  yAxisAutoScaleOnFirstRender: true,
  respiratory: true,
  chartSize: 'larger',
  markerSubscription: [
    {
      markerTypes: MarkerGroups.getEventTypes([
        'Apnea',
        'Hypopnea',
        'Artifact',
      ]),
    },
  ],
  alternativeSignals: [
    'Resp.Pressure-Raw.Mask',
    'Resp.FlowTemp-Thermistor.NasalOral',
    'Resp.FlowTemp-Thermocouple.NasalOral',
    'Resp.Flow-RIP',
  ],
  groupName: 'Flow Signal',
});
SignalDefinitions.set('Resp.Movement-Inductive.Thorax', {
  name: 'Thorax',
  shortName: 'Thorax',
  type: 'Resp.Movement-Inductive.Thorax',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'smaller',
  respiratory: true,
  markerSubscription: [
    {
      markerTypes: MarkerGroups.getEventTypes([
        'Apnea',
        'Hypopnea',
        'Artifact',
      ]),
    },
  ],
});
SignalDefinitions.set('Resp.Movement-Inductive.Abdomen', {
  name: 'Abdomen',
  shortName: 'Abdomen',
  type: 'Resp.Movement-Inductive.Abdomen',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'smaller',
  respiratory: true,
  markerSubscription: [
    {
      markerTypes: MarkerGroups.getEventTypes([
        'Apnea',
        'Hypopnea',
        'Artifact',
      ]),
    },
  ],
});
SignalDefinitions.set('Resp.FlowCal-RIP', {
  name: 'cRIP',
  shortName: 'cRIP',
  type: 'Resp.FlowCal-RIP',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'normal',
  respiratory: true,
  markerSubscription: [
    {
      markerTypes: MarkerGroups.getEventTypes([
        'Apnea',
        'Hypopnea',
        'Artifact',
      ]),
    },
  ],
});
SignalDefinitions.set('Pulse.Averaged-Probe', {
  name: 'Pulse',
  shortName: 'Pulse',
  type: 'Pulse.Averaged-Probe',
  yMin: 40,
  yMax: 110,
  showGaps: true,
  heart: true,
  labels: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Arrhythmia', 'Artifact']) },
  ],
});
SignalDefinitions.set('HeartRate-ECG', {
  name: 'Heart Rate ECG',
  shortName: 'ECG',
  type: 'HeartRate-ECG',
  yMin: 40,
  yMax: 110,
  showGaps: true,
  heart: true,
  labels: true,
  chartSize: 'smaller',
  yAxisAutoScaleOnFirstRender: true,
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Arrhythmia', 'Artifact']) },
  ],
  alternativeSignals: ['Pulse.Averaged-Probe'],
  groupName: 'Heart Signal',
});
SignalDefinitions.set('EMG.Tibialis-Leg.Left', {
  name: 'Left Leg',
  shortName: 'Leg L',
  type: 'EMG.Tibialis-Leg.Left',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Artifact']) },
  ],
});

SignalDefinitions.set('EMG.Tibialis-Leg.Right', {
  name: 'Right Leg',
  shortName: 'Leg R',
  type: 'EMG.Tibialis-Leg.Right',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Artifact']) },
  ],
});

SignalDefinitions.set('EMG.Tibialis-Leg', {
  name: 'Both Legs',
  shortName: 'Legs',
  type: 'EMG.Tibialis-Leg',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Artifact']) },
  ],
});

SignalDefinitions.set('Resp.Pressure-Raw.Mask', {
  name: 'Mask Flow',
  shortName: 'Mask',
  type: 'Resp.Pressure-Raw.Mask',
  yAxisAutoScaleOnFirstRender: true,
  respiratory: true,
  chartSize: 'larger',
  markerSubscription: [
    {
      markerTypes: MarkerGroups.getEventTypes([
        'Apnea',
        'Hypopnea',
        'Artifact',
      ]),
    },
  ],
  alternativeSignals: [
    'Resp.FlowTemp-Thermistor.NasalOral',
    'Resp.FlowTemp-Thermocouple.NasalOral',
    'Resp.Flow-Cannula.Nasal',
    'Resp.Flow-RIP',
  ],
  groupName: 'Flow Signal',
});

SignalDefinitions.set('Resp.Flow-RIP', {
  name: 'RIP Flow',
  shortName: 'RIP',
  type: 'Resp.Flow-RIP',
  yAxisAutoScaleOnFirstRender: true,
  respiratory: true,
  chartSize: 'larger',
  markerSubscription: [
    {
      markerTypes: MarkerGroups.getEventTypes([
        'Apnea',
        'Hypopnea',
        'Artifact',
      ]),
    },
  ],
  alternativeSignals: [
    'Resp.FlowTemp-Thermistor.NasalOral',
    'Resp.FlowTemp-Thermocouple.NasalOral',
    'Resp.Flow-Cannula.Nasal',
    'Resp.Pressure-Raw.Mask',
  ],
  groupName: 'Flow Signal',
});

SignalDefinitions.set('ECG', {
  name: 'ECG',
  shortName: 'ECG',
  type: 'ECG',
  yAxisAutoScaleOnFirstRender: true,
  heart: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Arrhythmia', 'Artifact']) },
  ],
});

SignalDefinitions.set('EKG', {
  name: 'EKG',
  shortName: 'EKG',
  type: 'EKG',
  yAxisAutoScaleOnFirstRender: true,
  heart: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Arrhythmia', 'Artifact']) },
  ],
});

SignalDefinitions.set('Resp.FlowTemp-Thermocouple.NasalOral', {
  name: 'Thermocouple',
  shortName: 'Thermocouple',
  type: 'Resp.FlowTemp-Thermocouple.NasalOral',
  yAxisAutoScaleOnFirstRender: true,
  respiratory: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Artifact']) },
  ],
  alternativeSignals: [
    'Resp.Flow-Cannula.Nasal',
    'Resp.Pressure-Raw.Mask',
    'Resp.FlowTemp-Thermistor.NasalOral',
    'Resp.Flow-RIP',
  ],
  groupName: 'Flow Signal',
});

SignalDefinitions.set('EMG.Masseter', {
  name: 'Bruxism',
  shortName: 'Bruxism',
  type: 'EMG.Masseter',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Artifact']) },
  ],
});

SignalDefinitions.set('EMG.Masseter-Left', {
  name: 'Bruxism Left',
  shortName: 'Bruxism L',
  type: 'EMG.Masseter-Left',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Artifact']) },
  ],
});

SignalDefinitions.set('EMG.Masseter-Right', {
  name: 'Bruxism Right',
  shortName: 'Bruxism R',
  type: 'EMG.Masseter-Right',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Artifact']) },
  ],
});

SignalDefinitions.set('Resp.FlowTemp-Thermistor.NasalOral', {
  name: 'Thermistor',
  shortName: 'Thermistor',
  type: 'Resp.FlowTemp-Thermistor.NasalOral',
  yAxisAutoScaleOnFirstRender: true,
  respiratory: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Artifact']) },
  ],
  alternativeSignals: [
    'Resp.FlowTemp-Thermocouple.NasalOral',
    'Resp.Flow-Cannula.Nasal',
    'Resp.Pressure-Raw.Mask',
    'Resp.Flow-RIP',
  ],
  groupName: 'Flow Signal',
});

SignalDefinitions.set('EEG', {
  name: 'EEG',
  shortName: 'EEG',
  type: 'EEG',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Artifact']) },
  ],
});

SignalDefinitions.set('EOG', {
  name: 'EOG',
  shortName: 'EOG',
  type: 'EOG',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Artifact']) },
  ],
});

SignalDefinitions.set('EOG-Right', {
  name: 'EOG Right',
  shortName: 'EOG R',
  type: 'EOG-Right',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Artifact']) },
  ],
});
SignalDefinitions.set('EOG-Left', {
  name: 'EOG Left',
  shortName: 'EOG L',
  type: 'EOG-Left',
  yAxisAutoScaleOnFirstRender: true,
  chartSize: 'smaller',
  markerSubscription: [
    { markerTypes: MarkerGroups.getEventTypes(['Artifact']) },
  ],
});

export const SignalDefinitionTools = {
  getBySignalTypes: (types: SignalType[]): SignalDefinition[] =>
    types.flatMap((type) => {
      const def = SignalDefinitions.get(type);
      return def ? [def] : [];
    }),
};

export default SignalDefinitions;
