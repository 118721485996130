import {
  FormControl,
  Typography,
  MenuItem,
  Button,
  makeStyles,
  Theme,
  fade,
} from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';

import React from 'react';
import { AvailableLanguages, LanguageSupportedLocale } from '../../i18n';
import ToolbarMenu from '../Shared/SheetToolbarMenu';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    position: 'relative',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    backgroundColor: theme.palette.background.paper,
    borderColor: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.23,
    ),
    '@media print': {
      display: 'none',
    },
  },
}));

interface ReportLanguageSelectorProps {
  locale: LanguageSupportedLocale;
  onChangeReportLanguage: (newLocale: LanguageSupportedLocale) => void;
}

const ReportLanguageSelector = (
  props: ReportLanguageSelectorProps,
): JSX.Element => {
  const classes = useStyles();

  const menuRef = React.useRef<HTMLButtonElement>(null);
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);

  const onChangeValue = (newLocale: LanguageSupportedLocale) => {
    props.onChangeReportLanguage(newLocale);
    setIsOpenMenu(false);
  };

  return (
    <>
      <FormControl variant="outlined">
        <Button
          ref={menuRef}
          variant="outlined"
          className={classes.button}
          startIcon={<LanguageIcon />}
          onClick={() => setIsOpenMenu(true)}
          data-cy="ReportLanguageSelector"
        >
          {
            AvailableLanguages.find((lang) => lang.locale === props.locale)
              ?.name
          }
        </Button>
        <ToolbarMenu
          isOpen={isOpenMenu}
          setOpen={setIsOpenMenu}
          anchorRef={menuRef}
        >
          {AvailableLanguages.map((lang) => (
            <MenuItem
              key={lang.locale}
              onClick={() => onChangeValue(lang.locale)}
            >
              <Typography>{lang.name}</Typography>
            </MenuItem>
          ))}
        </ToolbarMenu>
      </FormControl>
    </>
  );
};

export default ReportLanguageSelector;
