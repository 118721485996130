import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { useTheme, makeStyles } from '@material-ui/core';
import getEpochChartOptions from './getEpochChartOptions';
import { EpochChartProps } from './interfaces/epoch-chart-props';
import chartRangeTools from '../../SignalSheet/chartRangeTools';
import Logger from '../../../utils/logger';
import PassiveEvents from '../../../utils/PassiveEvents';
import { OnLoadChartFunction } from '../../../interfaces/chart-props';
import SleepStageService from '../../../services/sleepStageService';

PassiveEvents(Highcharts);

const useStyles = makeStyles(() => ({
  hide: {
    display: 'none',
  },
}));

const EpochChart = (props: EpochChartProps): JSX.Element => {
  Logger.log('[EpochChart] Init');
  const [visible, setVisible] = React.useState(false);
  const [options, setOptions] =
    React.useState<Highcharts.Options | undefined>();

  const theme = useTheme();
  const classes = useStyles();

  const onEpochChartLoad: OnLoadChartFunction = (chart: Highcharts.Chart) => {
    Logger.log('[onEpochChartLoad] Initializing data');
    const { startTime, endTime } = props.recording;
    Logger.log(
      '[onEpochChartLoad] Total recording time is %s-%s',
      startTime,
      endTime,
    );
    const startTimeUTC = new Date(startTime).getTime();
    const endTimeUTC = new Date(endTime).getTime();
    Logger.log(
      '[onEpochChartLoad] Total recording time in UTC is %d-%d',
      startTimeUTC,
      endTimeUTC,
    );

    const adjustedEdges = chartRangeTools.adjustRangeToEpoch(
      startTimeUTC,
      endTimeUTC,
    );
    Logger.log(
      '[onEpochChartLoad] Adjusted edges are %d-%d',
      adjustedEdges.min,
      adjustedEdges.max,
    );
    if (chart) {
      let done = false;
      const epochList = [];
      for (let i = 0; !done; i++) {
        const epochTime = adjustedEdges.min + 30000 * i;

        epochList.push([epochTime + 1, 1]);

        if (adjustedEdges.min + 30000 * i >= adjustedEdges.max) {
          done = true;
        }
      }
      Logger.log('[onEpochChartLoad] Final epoch list', epochList);

      chart.series[0].setData(epochList);
      SleepStageService.updateStageColors();

      setVisible(true);
    }
  };

  React.useEffect(() => {
    Logger.log('[EpochChart] useEffect', props.recording);
    const chartOptions = getEpochChartOptions(theme, onEpochChartLoad);
    setOptions(chartOptions);

    return () => {
      Logger.log('[EpochChart] useEffect cleanup', props.recording);
    };
  }, [props.recording]);

  return (
    <div
      data-cy="EpochChart"
      className={visible ? '' : classes.hide}
      style={{ width: '100%' }}
    >
      {options && (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="stockChart"
          allowChartUpdate={false}
          options={options}
        />
      )}
    </div>
  );
};

export default React.memo(EpochChart, () => true);
