import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import KeyboardShortcutsHelp from './KeyboardShortcutsHelp';

interface KeyboardShortcutsModalProps {
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '80%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    height: '50%',
    color: theme.colors.modal.color,
    display: 'flex',
  },
}));

const KeyboardShortcutsModal = (
  props: KeyboardShortcutsModalProps,
): JSX.Element => {
  const classes = useStyles();
  const smallScreen = useMediaQuery('(max-width:1000px)');

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
          className: classes.root,
        }}
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={props.handleClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogContentText className={clsx(classes.dialogContent)}>
            <KeyboardShortcutsHelp smallScreen={smallScreen} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default KeyboardShortcutsModal;
