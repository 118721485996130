import React from 'react';
import { makeStyles, Typography, fade, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';
import { Scoring } from '../../queries/scoring';
import themeProvider from '../../utils/theme';
import { generateTLocale, LanguageSupportedLocale } from '../../i18n';

const useStyles = makeStyles((theme) => ({
  caption: {
    color: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.54,
    ),
    '@media print': {
      color: fade(
        theme.palette.getContrastText(
          themeProvider.getType('LIGHT').palette.background.paper,
        ),
        0.54,
      ),
    },
  },
}));

interface ReportHeaderInfoProps {
  scoring?: Scoring;
  locale: LanguageSupportedLocale;
}

function ReportHeaderInfo(props: ReportHeaderInfoProps): JSX.Element {
  const classes = useStyles();

  const { t } = useTranslation();
  const tLocale = generateTLocale(t, props.locale);

  return (
    <>
      <div>
        {props.scoring ? (
          <>
            <Grid container spacing={3}>
              <Grid item data-cy="ReportHeaderInfo-RecordingId">
                <Typography variant="caption" className={classes.caption}>
                  {tLocale('Recording ID')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {props.scoring.recordingId}
                </Typography>
              </Grid>
              <Grid item data-cy="ReportHeaderInfo-ScoringId">
                <Typography variant="caption" className={classes.caption}>
                  {tLocale('Scoring ID')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {props.scoring.scoringId}
                </Typography>
              </Grid>
              <Grid item data-cy="ReportHeaderInfo-ScoringVersion">
                <Typography variant="caption" className={classes.caption}>
                  {tLocale('Scoring Version')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {props.scoring.version}
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <Skeleton variant="text" animation="wave" width={400} />
        )}
      </div>
    </>
  );
}

export default ReportHeaderInfo;
