import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { OpenRecordingButtonProps } from './interfaces/recording-toolbar';

const useStyles = makeStyles((theme: Theme) => ({
  open: {
    width: '100%',
    marginTop: -2,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  icon: {
    marginLeft: 5,
  },
}));

const OpenUploadRecordingButton = (
  props: OpenRecordingButtonProps,
): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      className={classes.open}
      variant="contained"
      onClick={props.openUploadRecording}
      data-cy="UploadRecordingBtn"
    >
      Upload Recording{' '}
      <CloudUploadIcon fontSize="small" className={classes.icon} />
    </Button>
  );
};

export default OpenUploadRecordingButton;
