import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Grid,
  Button,
  Typography,
  Tooltip,
  fade,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import { SignalDefinition } from '../../../interfaces/sheet-definition';
import Logger from '../../../utils/logger';
import { darkenOrLighten } from '../../../utils/theme';
import studyTools from '../../StudyOverview/studyTools';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
    },
    paper: {
      height: 430,
      overflow: 'auto',
      background: darkenOrLighten(
        theme.id,
        theme.palette.background.paper,
        0.02,
        0.1,
      ),
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
    button: {
      margin: theme.spacing(0.5, 0),
      color: theme.palette.getContrastText(theme.palette.background.paper),
      borderColor: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.54,
      ),
      '&.Mui-disabled': {
        color: fade(
          theme.palette.getContrastText(theme.palette.background.paper),
          0.12,
        ),
        borderColor: fade(
          theme.palette.getContrastText(theme.palette.background.paper),
          0.05,
        ),
      },
    },
    tooltip: {
      maxWidth: 300,
    },
    errorIcon: {
      color: theme.colors.statuses.error,
      marginTop: theme.spacing(-2.5),
    },
    caption: {
      color: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.54,
      ),
    },
    checkbox: {
      color: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.54,
      ),
      '&.Mui-checked': {
        color:
          theme.id === 'LIGHT'
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
      },
    },
  }),
);

function not(a: SignalDefinition[], b: SignalDefinition[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: SignalDefinition[], b: SignalDefinition[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

interface SignalsTransferListProps {
  handleUpdate: (signals: SignalDefinition[]) => void;
  handleError: (error: boolean) => void;
}

const SignalsTransferList = (props: SignalsTransferListProps): JSX.Element => {
  const classes = useStyles();

  const MAX_CHANNELS = 8;

  const [checked, setChecked] = React.useState<SignalDefinition[]>([]);
  const [left, setLeft] = React.useState<SignalDefinition[]>(
    studyTools.getAvailableSignals(),
  );
  const [right, setRight] = React.useState<SignalDefinition[]>([]);
  const [error, setError] = React.useState(false);

  Logger.log(
    '[ChannelsTransferList] signals',
    studyTools.getAvailableSignals(),
  );

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const validate = (
    newRight: SignalDefinition[],
    newLeftChecked: SignalDefinition[],
  ) => {
    const isError = newRight.length + newLeftChecked.length > MAX_CHANNELS;
    setError(isError);
    props.handleError(isError);
  };

  const handleToggle = (value: SignalDefinition) => () => {
    const currentIndex = checked.findIndex((s) => s.type === value.type);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    validate(right, intersection(newChecked, left));
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(leftChecked);
    const newLeft = not(left, leftChecked);
    const newChecked = not(checked, leftChecked);
    setLeft(newLeft);
    setRight(newRight);
    setChecked(newChecked);

    props.handleUpdate(newRight);
    validate(newRight, intersection(newChecked, newLeft));
  };

  const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked);
    const newRight = not(right, rightChecked);
    const newChecked = not(checked, rightChecked);

    setLeft(newLeft);
    setRight(newRight);
    setChecked(newChecked);

    props.handleUpdate(newRight);
    validate(newRight, intersection(newChecked, newLeft));
  };

  const customList = (items: SignalDefinition[]) => (
    <Paper className={classes.paper} elevation={3}>
      <List dense component="div" role="list">
        {items.map((signal) => {
          return (
            <ListItem
              data-cy={`CustomSignal-${signal.name}`}
              key={signal.type}
              role="listitem"
              button
              onClick={handleToggle(signal)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.some((s) => s.type === signal.type)}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                  className={classes.checkbox}
                />
              </ListItemIcon>
              <ListItemText
                primary={signal.name}
                secondary={signal.type}
                classes={{ secondary: classes.caption }}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={5}>
        <Typography
          data-cy="NumberOfAvailableCustomSignals"
          align="center"
          variant="body2"
          gutterBottom
        >
          Available Signals ({left.length}/
          {studyTools.getAvailableSignals().length})
        </Typography>
        {customList(left)}
      </Grid>
      <Grid item xs>
        <Grid container direction="column" alignItems="center">
          {error && (
            <Tooltip
              title={`The number of selected signals exceeds the limit by ${
                right.length + leftChecked.length - MAX_CHANNELS
              }`}
              classes={{ tooltip: classes.tooltip }}
              style={{ cursor: 'pointer' }}
              arrow
            >
              <WarningIcon fontSize="small" className={classes.errorIcon} />
            </Tooltip>
          )}
          <Button
            data-cy="AddCustomSignal"
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || error}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            data-cy="RemoveCustomSignal"
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Typography
          data-cy="NumberOfSelectedCustomSignals"
          align="center"
          variant="body2"
          gutterBottom
        >
          Custom Sheet ({right.length}/{MAX_CHANNELS})
        </Typography>
        {customList(right)}
      </Grid>
    </Grid>
  );
};

export default SignalsTransferList;
