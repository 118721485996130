import {
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  Toolbar,
  Theme,
  MenuItem,
  fade,
  Paper,
  Typography,
} from '@material-ui/core';

import clsx from 'clsx';

import React from 'react';
import { SheetToolbarContext } from '../../context/sheet-toolbar-context';
import {
  SheetToolbarPageFlip,
  SheetToolbarZoomRange,
} from '../../interfaces/sheet-toolbar-props';
import NavigatorChart from '../Chart/NavigatorChart/NavigatorChart';
import sheetTools from '../SignalSheet/sheetTools';
import SheetToolbarModeSwitcher from './SheetToolbarModeSwitcher';
import { Recording } from '../../queries/recording';
import chartRangeTools from '../SignalSheet/chartRangeTools';
import Logger from '../../utils/logger';
import StatusBar from '../StatusBar/StatusBar';
import EpochToolbar from './EpochToolbar';
import ToolsGrid from './ToolsGrid';
import ToolbarMenu from '../Shared/SheetToolbarMenu';
import EventService from '../../services/eventService';
import FeatureToggle from '../Shared/FeatureToggle';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.5, 2, 0.5, 2),
    transition: '0.1s all linear',
    overflow: 'hidden',
    height: 95,
    opacity: 1,
  },
  minimized: {
    height: 0,
    opacity: 0,
    padding: theme.spacing(0.9, 2, 0.9, 2),
    '& $legend': { height: 0, marginBottom: 0 },
  },
  minimal: {
    height: 40,
    padding: theme.spacing(0.9, 2, 0.9, 2),
    '& $legend': { height: 0, marginBottom: 0 },
  },
  toolbar: {
    padding: 0,
    alignItems: 'normal',
    // TODO: Enable when Safari supports it
    // gap: theme.spacing(1) + 'px',
  },
  control: {
    margin: theme.spacing(0, 0.5),
    '&:first-child': {
      marginLeft: 0, // TODO: Replace with gap
    },
  },
  legend: {
    color: theme.palette.getContrastText(theme.palette.background.default),
    marginBottom: 3,
    opacity: 0.6,
    height: 20,
    overflow: 'hidden',
    transition: '0.1s all linear',
  },
  relative: {
    position: 'relative',
  },
  statusBarContainer: {
    transition: '0.1s all linear',
    opacity: 1,
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
}));

const useGroupedButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    cursor: 'pointer',
    transition: '0.1s all linear',
    backgroundColor: theme.palette.background.paper,
    color: theme.colors.text.lighter,
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2),' +
      '0px 1px 1px 0px rgba(0,0,0,0.14),' +
      '0px 2px 1px -1px rgba(0,0,0,0.12)',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    minWidth: 80,

    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, 0.35),
    },
  },
  minimal: { height: 28 },
  menuOption: {
    color: theme.colors.text.lighter,
  },
}));

interface SheetToolbarProps {
  recording: Recording;
}

const SheetToolbar = (props: SheetToolbarProps): JSX.Element => {
  Logger.log('[SheetToolbar] init');
  const classes = useStyles();
  const buttonGroupClasses = useGroupedButtonStyles();
  const sheetToolbarContext = React.useContext(SheetToolbarContext);

  const zoomAnchorRef = React.useRef<HTMLDivElement>(null);
  const [zoomMenuOpen, setZoomMenuOpen] = React.useState(false);

  const pageFlipAnchorRef = React.useRef<HTMLDivElement>(null);
  const [pageFlipMenuOpen, setPageFlipMenuOpen] = React.useState(false);

  const setPageFlip = (pageFlip: SheetToolbarPageFlip) => {
    sheetToolbarContext.setPageFlip(pageFlip);
    sheetTools.setPageFlip(pageFlip);
    setPageFlipMenuOpen(false);
  };

  const setZoomRange = (newZoom: SheetToolbarZoomRange) => {
    sheetToolbarContext.setZoomRange(newZoom);
    setTimeout(() => sheetTools.setZoomRange(newZoom), 10); // Smooths the transition

    if (newZoom === '30secs') setPageFlip('Epoch');
    setZoomMenuOpen(false);
  };

  const setSingleClickScoring = (isSingleClick: boolean) => {
    sheetToolbarContext.setSingleClickScoring(isSingleClick);
    sheetTools.setSingleClickScoring(isSingleClick);

    sheetToolbarContext.setInvalidDataMode(false);
    sheetTools.setInvalidDataMode(false);
  };

  const setInvalidDataMode = (invalidDataMode: boolean) => {
    sheetToolbarContext.setInvalidDataMode(invalidDataMode);
    sheetTools.setInvalidDataMode(invalidDataMode);

    sheetToolbarContext.setSingleClickScoring(false);
    sheetTools.setSingleClickScoring(false);
  };

  const setAnnotationMode = (annotationModeEnabled: boolean) => {
    sheetToolbarContext.setAnnotationMode(annotationModeEnabled);
    sheetTools.setAnnotationMode(annotationModeEnabled);
  };

  const changeToNextAvailableZoom = (type: 'zoomIn' | 'zoomOut') => {
    const options = chartRangeTools
      .getZoomRangeOptions()
      .sort((a, b) => a.ms - b.ms)
      .filter((option) => chartRangeTools.isZoomRangeAvailable(option.value));

    const currentZoomIndex = options.findIndex(
      (option) => option.value === sheetToolbarContext.zoomRange,
    );

    if (currentZoomIndex > -1) {
      let nextIndex = -1;

      if (type === 'zoomIn') {
        nextIndex = currentZoomIndex - 1;
      } else if (type === 'zoomOut') {
        nextIndex = currentZoomIndex + 1;
      }

      const newOption = options[nextIndex];
      if (newOption) {
        setZoomRange(newOption.value);
      }
    }
  };

  React.useEffect(() => {
    Logger.log('[SheetToolbar] useEffect');
    const maximumZoomRange = chartRangeTools.getMaximumZoomRangeForPart();
    Logger.log('[SheetToolbar] maximumZoomRange:', maximumZoomRange);
    if (maximumZoomRange) {
      const currentZoomRangeinMs = chartRangeTools.getMsByZoomRange(
        sheetToolbarContext.zoomRange,
      );
      if (currentZoomRangeinMs > maximumZoomRange.ms) {
        setZoomRange(maximumZoomRange.value);
        setPageFlip('Epoch');
      }
    }

    const cbId = [
      EventService.subscribe('KeyboardShortcut.ZoomOut', () => {
        changeToNextAvailableZoom('zoomOut');
      }),
      EventService.subscribe('KeyboardShortcut.ZoomIn', () => {
        changeToNextAvailableZoom('zoomIn');
      }),
    ];

    return () => {
      EventService.unsubscribe(cbId);
    };
  });

  return (
    <>
      <Grid
        container
        spacing={1}
        className={clsx(classes.root, {
          [classes.minimized]: sheetToolbarContext.mode === 'Hidden',
          [classes.minimal]: sheetToolbarContext.mode === 'Minimal',
        })}
      >
        <Grid item xs={6}>
          <Toolbar className={classes.toolbar}>
            <FormControl component="fieldset" className={classes.control}>
              <FormHelperText component="legend" className={classes.legend}>
                Zoom
              </FormHelperText>
              <FormControl variant="outlined">
                <Paper
                  ref={zoomAnchorRef}
                  data-cy="zoomRange"
                  className={clsx(buttonGroupClasses.root, {
                    [buttonGroupClasses.minimal]:
                      sheetToolbarContext.mode === 'Minimal',
                  })}
                  onClick={() => setZoomMenuOpen(true)}
                >
                  {[
                    chartRangeTools.getZoomRangeOptionByValue(
                      sheetToolbarContext.zoomRange,
                    ),
                  ].flatMap((option) => {
                    if (option)
                      return (
                        <Typography key={option.value}>
                          {option.valueText} {option.unitText}
                        </Typography>
                      );
                    return undefined;
                  })}
                </Paper>
                <ToolbarMenu
                  isOpen={zoomMenuOpen}
                  setOpen={setZoomMenuOpen}
                  anchorRef={zoomAnchorRef}
                >
                  {chartRangeTools
                    .getZoomRangeOptions()
                    .filter((option) =>
                      chartRangeTools.isZoomRangeAvailable(option.value),
                    )
                    .map((option) => (
                      <MenuItem
                        data-cy={option.value}
                        key={option.value}
                        className={buttonGroupClasses.menuOption}
                        onClick={() => setZoomRange(option.value)}
                      >
                        <Typography>
                          {option.valueText} {option.unitText}
                        </Typography>
                      </MenuItem>
                    ))}
                </ToolbarMenu>
              </FormControl>
            </FormControl>

            <FormControl component="fieldset" className={classes.control}>
              <FormHelperText component="legend" className={classes.legend}>
                Page flip
              </FormHelperText>
              <FormControl variant="outlined">
                <Paper
                  ref={pageFlipAnchorRef}
                  data-cy="pageFlip"
                  className={clsx(buttonGroupClasses.root, {
                    [buttonGroupClasses.minimal]:
                      sheetToolbarContext.mode === 'Minimal',
                  })}
                  onClick={() => setPageFlipMenuOpen(true)}
                >
                  <Typography>{sheetToolbarContext.pageFlip}</Typography>
                </Paper>
                <ToolbarMenu
                  isOpen={pageFlipMenuOpen}
                  setOpen={setPageFlipMenuOpen}
                  anchorRef={pageFlipAnchorRef}
                >
                  <MenuItem
                    data-cy="pageFlipEpoch"
                    className={buttonGroupClasses.menuOption}
                    onClick={() => setPageFlip('Epoch')}
                  >
                    Epoch
                  </MenuItem>
                  <MenuItem
                    data-cy="pageFlipHalf"
                    className={buttonGroupClasses.menuOption}
                    disabled={
                      sheetToolbarContext.zoomRange === '30secs' ||
                      sheetToolbarContext.zoomRange === '1min'
                    }
                    onClick={() => setPageFlip('Half')}
                  >
                    Half
                  </MenuItem>
                  <MenuItem
                    data-cy="pageFlipFull"
                    className={buttonGroupClasses.menuOption}
                    disabled={sheetToolbarContext.zoomRange === '30secs'}
                    onClick={() => setPageFlip('Full')}
                  >
                    Full
                  </MenuItem>
                </ToolbarMenu>
              </FormControl>
            </FormControl>

            <EpochToolbar
              classes={{
                control: classes.control,
                legend: classes.legend,
              }}
              toolbarMode={sheetToolbarContext.mode}
            />

            <FormControl component="fieldset" className={classes.control}>
              <FormHelperText component="legend" className={classes.legend}>
                Tools
              </FormHelperText>
              <FormControl variant="outlined">
                <ToolsGrid
                  isSingleClickScoring={sheetToolbarContext.singleClickScoring}
                  setSingleClickScoring={setSingleClickScoring}
                  invalidDataMode={sheetToolbarContext.invalidDataMode}
                  setInvalidDataMode={setInvalidDataMode}
                  annotationModeEnabled={
                    sheetToolbarContext.annotationModeEnabled
                  }
                  setAnnotationMode={setAnnotationMode}
                  toolbarMode={sheetToolbarContext.mode}
                />
              </FormControl>
            </FormControl>
          </Toolbar>
        </Grid>
        <Grid item xs={6} className={classes.relative}>
          <NavigatorChart
            toolbarMode={sheetToolbarContext.mode}
            recording={props.recording}
          />
          <div
            className={clsx(classes.statusBarContainer, {
              [classes.hidden]: sheetToolbarContext.mode !== 'Full',
            })}
          >
            <StatusBar />
          </div>
        </Grid>
      </Grid>
      <FeatureToggle enabledFor="development">
        <SheetToolbarModeSwitcher
          setToolbarMode={sheetToolbarContext.setMode}
          toolbarMode={sheetToolbarContext.mode}
        />
      </FeatureToggle>
    </>
  );
};

export default SheetToolbar;
