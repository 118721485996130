import { PopperProps } from '@material-ui/core/Popper';
import {
  SignalEventDetailService,
  UpdateOpenContext,
  UpdateEventDataContext,
  UpdateAnchorElContext,
  MarkerId,
} from '../../interfaces/signal-event-detail-props';
import Logger from '../../utils/logger';
import eventChartTools from '../Chart/SignalChart/eventChartTools';
import ScoringService from '../../services/scoringService';
import { ScoringCanvasEvent } from '../Chart/ScoringCanvas/ScoringCanvas';
import ScoringRenderingService from '../../services/scoringRenderingService';
import chartRangeTools from '../SignalSheet/chartRangeTools';
import EventService from '../../services/eventService';

let updateOpenContext: UpdateOpenContext = () => {
  /* To be replaced on initialize */
};
let updateEventDataContext: UpdateEventDataContext = () => {
  /* To be replaced on initialize */
};
let updateAnchorElContext: UpdateAnchorElContext = () => {
  /* To be replaced on initialize */
};

let selectedEventDetailData: ScoringCanvasEvent | undefined;
let isOpen = false;
let wasRecentlyOpened = false;
let openedTime = 0;
let closeTimeout: NodeJS.Timeout;
let openTimeout: NodeJS.Timeout;

const signalEventDetailService: SignalEventDetailService = {
  initialize: (
    updateOpen: UpdateOpenContext,
    updateEventData: UpdateEventDataContext,
    updateAnchorEl: UpdateAnchorElContext,
  ) => {
    Logger.log('[SignalEventDetailService] initialize');
    updateOpenContext = updateOpen;
    updateEventDataContext = updateEventData;
    updateAnchorElContext = updateAnchorEl;
    selectedEventDetailData = undefined;
  },
  setData: (data?: ScoringCanvasEvent) => {
    Logger.log('[SignalEventDetailService/setData] initialize', data);
    selectedEventDetailData = data;
    updateEventDataContext(data);
  },
  refreshData: () => {
    if (selectedEventDetailData) {
      const latestEventData = ScoringService.findEventById(
        selectedEventDetailData.id,
      );
      if (latestEventData) {
        const extremes = chartRangeTools.getCurrentExtremes();
        const scoringCanvasEvent =
          ScoringRenderingService.eventDetailDataToCanvasEvent(
            latestEventData,
            extremes.min,
            extremes.max,
            Date.now(),
            selectedEventDetailData.signalInfo?.type,
            selectedEventDetailData.signalInfo?.screenPosition?.distanceFromTop,
            selectedEventDetailData.signalInfo?.screenPosition?.height,
          );
        signalEventDetailService.setData(scoringCanvasEvent);
      }
    }
  },
  popup: {
    open: () => {
      Logger.warn('[SignalEventDetailService/open] init');
      isOpen = true;
      EventService.dispatch('Scoring.ActiveMarker', selectedEventDetailData);
      clearTimeout(openTimeout);
      wasRecentlyOpened = true;
      openedTime = Date.now();
      clearTimeout(closeTimeout);
      updateOpenContext(true);
    },
    setAnchorEl: (anchorEl: PopperProps['anchorEl']) => {
      Logger.log('[SignalEventDetailService/setAnchorEl] anchorEl', anchorEl);
      updateAnchorElContext(anchorEl);
    },
    close: () => {
      clearTimeout(closeTimeout);
      closeTimeout = setTimeout(() => {
        Logger.log('[SignalEventDetailService/close] init');
        if (Date.now() - openedTime > 100) {
          Logger.log(
            '[SignalEventDetailService/close] Setting isOpen -> false',
          );
          isOpen = false;
          updateOpenContext(false);
        }
      }, 100);
      clearTimeout(openTimeout);
      openTimeout = setTimeout(() => {
        Logger.log(
          '[SignalEventDetailService/close] Setting wasRecentlyOpened -> false',
        );
        wasRecentlyOpened = false;
      }, 250);
    },
    isOpenAndSameMarkerId: (markerId: MarkerId) => {
      const isOpenAndSameMarkerId =
        isOpen && selectedEventDetailData?.id === markerId;
      /* Logger.debug(
        '[SignalEventDetailService/isOpenAndSameMarkerId] isOpenAndSameMarkerId',
        isOpenAndSameMarkerId
      ); */
      return isOpenAndSameMarkerId;
    },
    isOpen: () => {
      Logger.debug('[SignalEventDetailService/isOpen] isOpen:', isOpen);
      return isOpen || wasRecentlyOpened;
    },
  },
  event: {
    delete: () => {
      if (selectedEventDetailData && isOpen) {
        ScoringService.removeEvent(selectedEventDetailData.id, { send: true });
      }
    },
    switchType: () => {
      Logger.log('[SignalEventDetailService/switchType]');
      if (selectedEventDetailData && selectedEventDetailData.signalInfo) {
        eventChartTools.switchEventType(selectedEventDetailData, 'Popup');
      }
    },
  },
};

export default signalEventDetailService;
