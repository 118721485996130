import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import XRange from 'highcharts/modules/xrange';
import _ from 'underscore';
import React from 'react';
import {
  useTheme,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  fade,
} from '@material-ui/core';
import Logger from '../../../utils/logger';
import { RecordingPartData } from '../../StudyOverview/OverviewParts';
import chartTools from '../SignalChart/chartTools';
import {
  GraphDataEvent,
  OverviewGraphDefinition,
} from './overviewChartDefinitions';
import ScoringService from '../../../services/scoringService';
import EventService from '../../../services/eventService';
import { SignalEventDetailData } from '../../../interfaces/signal-event-detail-props';
import eventChartTools from '../SignalChart/eventChartTools';
import studyTools from '../../StudyOverview/studyTools';

XRange(Highcharts);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    yAxisLabels: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      right: '100%',
      marginRight: theme.spacing(0.5),
      textAlign: 'right',
      justifyContent: 'space-between',
    },
    label: {
      color: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.54,
      ),
      fontSize: 9,
    },
  }),
);

interface OverviewChartProps {
  graphDefinition: OverviewGraphDefinition;
  partData: RecordingPartData;
  chartWidth?: number;
}

const OverviewChart = (props: OverviewChartProps): JSX.Element => {
  Logger.log(
    '[OverviewChart] Created new instance for:',
    props.graphDefinition.type,
  );
  const classes = useStyles();
  const theme = useTheme();
  const [dataMin, setDataMin] = React.useState<number>();
  const [dataMax, setDataMax] = React.useState<number>();

  const redraw = _.throttle(() => {
    const chart = studyTools.retrieveChart(
      props.partData.partId,
      props.graphDefinition.type,
    );
    if (chart && chartTools.isSerieAvailable(chart)) {
      Logger.debug('[redraw][%s] Redrawing', props.graphDefinition.type);
      const time = Date.now();

      Logger.debug(
        '[redraw][%s] chartWidth',
        props.graphDefinition.type,
        chart.chartWidth,
      );
      if (chart.chartWidth > 1) {
        chart.redraw(false);
      } else {
        Logger.debug('[redraw][%s] Skipping!', props.graphDefinition.type);
      }

      if (chartTools.isSerieAvailable(chart, { serieIndex: 1 })) {
        Logger.debug(
          '[redraw][%s] Redrawing (%d, %d) points took (ms):',
          props.graphDefinition.type,
          chartTools.getDataFromChart(chart).length,
          chart.series[1].data.length,
          Date.now() - time,
        );
      }
    }
  }, 250);

  const processSamples = _.throttle(() => {
    Logger.log(
      '[OverviewChart][%s] processSamples',
      props.graphDefinition.type,
    );
    const chart = studyTools.retrieveChart(
      props.partData.partId,
      props.graphDefinition.type,
    );

    if (chart && props.graphDefinition.signalType) {
      const graphSamples = studyTools.getGraphData(
        props.partData.partId,
        props.graphDefinition.signalType,
      );
      Logger.debug('[OverviewChart] graphSamples', graphSamples);

      const partStartTime = new Date(props.partData.startTime).valueOf();
      const partEndTime = new Date(props.partData.endTime).valueOf();
      const artifactsAndEvents = ScoringService.retrieveMarkersByType([
        'signal-artifact',
        ...(props.graphDefinition.displayEvents || []),
      ]).filter(
        (event) =>
          event.scoredFrom === props.graphDefinition.signalType &&
          eventChartTools.isMarkerWithinTimePeriod(
            event,
            partStartTime,
            partEndTime,
          ),
      );

      if (chart.series && chart.series[1]) {
        const data: Highcharts.XrangePointOptionsObject[] = [];
        artifactsAndEvents.forEach((marker) => {
          const color = eventChartTools.getColorByEventType(marker.type);
          data.push({
            x: marker.start,
            x2: marker.end,
            y: 0,
            color: marker.type === 'signal-artifact' ? fade(color, 0.3) : color,
          });
        });

        if (chartTools.isSerieAvailable(chart, { serieIndex: 1 })) {
          chart.series[1].setData(data, false);
        }
      }

      const shouldFilter =
        artifactsAndEvents.length > 0 ||
        props.graphDefinition.filterMax !== undefined;

      const filteredSamples = shouldFilter
        ? graphSamples.filter((sample) => {
            const outsideBoundary =
              !!props.graphDefinition.filterMax &&
              sample[1] > props.graphDefinition.filterMax;

            const withinArtifact =
              !outsideBoundary &&
              artifactsAndEvents.some(
                (marker) =>
                  marker.type === 'signal-artifact' &&
                  sample[0] >= marker.start &&
                  sample[0] <= marker.end,
              );

            /* if (withinArtifact) {
            Logger.debug('[OverviewChart][processSamples] -----');
            Logger.debug('[OverviewChart][processSamples] Filtering sample!', sample);
            Logger.debug('[OverviewChart][processSamples] previous sample', newSamples[index - 1]);
            Logger.debug('[OverviewChart][processSamples] next sample', newSamples[index + 1]);
            Logger.debug('[OverviewChart][processSamples] -----');
          } */

            return !outsideBoundary && !withinArtifact;
          })
        : graphSamples;

      // Logger.debug('[OverviewChart] filteredSamples', filteredSamples);
      // Logger.debug('[OverviewChart] artifactsAndEvents', artifactsAndEvents);
      if (chartTools.getDataFromChart(chart).length === 0) {
        chartTools.setData(chart, filteredSamples, { redraw: false });
      }

      redraw();

      const series = chart?.series;
      setDataMin(series ? series[0].dataMin : undefined);
      setDataMax(series ? series[0].dataMax : undefined);
    }
  }, 250);

  React.useEffect(() => {
    const cbId = [
      EventService.subscribe(
        'Overview.GraphDataReceived',
        (info: GraphDataEvent) => {
          if (
            info.partId === props.partData.partId &&
            info.signalType === props.graphDefinition.signalType
          ) {
            Logger.debug(
              '[OverviewChart][GraphDataReceived] processSamples',
              info,
            );
            processSamples();
          }
        },
      ),
      EventService.subscribe(
        'ScoringChanged',
        (marker: SignalEventDetailData) => {
          const relevantMarkers = [
            'signal-artifact',
            ...(props.graphDefinition.displayEvents || []),
          ];

          if (
            relevantMarkers.includes(marker.type) &&
            marker.scoredFrom === props.graphDefinition.signalType
          ) {
            const partStartTime = new Date(props.partData.startTime).valueOf();
            const partEndTime = new Date(props.partData.endTime).valueOf();
            if (
              eventChartTools.isMarkerWithinTimePeriod(
                marker,
                partStartTime,
                partEndTime,
              )
            ) {
              processSamples();
            }
          }
        },
      ),
    ];

    const chart = studyTools.retrieveChart(
      props.partData.partId,
      props.graphDefinition.type,
    );
    if (chart) {
      chart.setSize(props.chartWidth, undefined);
    }

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, [props.chartWidth]);

  const min = new Date(props.partData.startTime).valueOf();
  const max = new Date(props.partData.endTime).valueOf();

  const chartOptions: Highcharts.Options = {
    chart: {
      width: 1,
      height: props.graphDefinition.height,
      marginLeft: 0,
      marginTop: 0,
      spacingTop: 0,
      spacingRight: 20,
      spacingBottom: 0,
      spacingLeft: 0,
      plotBorderWidth: 0,
      panning: {
        enabled: false,
      },
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      animation: false,
      events: {
        load() {
          Logger.log('[OverviewChart] load');
          studyTools.registerChart(
            props.partData.partId,
            props.graphDefinition.type,
            this,
          );
          processSamples();
        },
      },
    },
    loading: {
      style: {
        background: theme.colors.chart.loading.background,
        color: theme.colors.chart.loading.color,
      },
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      visible: false,
      crosshair: false,
      gridLineWidth: 0,
      lineWidth: 0,
      showFirstLabel: true,
      showLastLabel: true,
      ordinal: false,
      min,
      max,
    },
    yAxis: [
      {
        min: props.graphDefinition.yMin,
        max: props.graphDefinition.yMax,
        startOnTick: true,
        endOnTick: true,
        tickPixelInterval: 100,
        alignTicks: false,
        lineWidth: 0,
        gridLineWidth: 0,
        showFirstLabel: true,
        showLastLabel: true,
        opposite: false,
        labels: {
          enabled: false,
        },
      },
      {
        visible: false,
        startOnTick: true,
        endOnTick: true,
        tickPixelInterval: 100,
        alignTicks: false,
        lineWidth: 0,
        gridLineWidth: 0,
        showFirstLabel: true,
        showLastLabel: true,
        opposite: false,
        labels: {
          enabled: false,
        },
        categories: ['Artifact'],
      },
    ],
    series: [
      {
        name: props.graphDefinition.type,
        type: 'line',
        data: [],
        enableMouseTracking: false,
        dataGrouping: { enabled: false },
        color: theme.colors.chart.line.default,
        lineWidth: 1,
        yAxis: 0,
        gapSize: 60000,
        gapUnit: 'value',
        marker: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
      },
      {
        type: 'xrange',
        borderColor: 'gray',
        pointWidth: 50,
        enableMouseTracking: false,
        minPointLength: 1,
        yAxis: 1,
        data: [],
        opacity: 0.7,
        allowPointSelect: false,
        animation: false,
        turboThreshold: 0,
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      xrange: {
        grouping: false,
        pointPadding: 0,
        groupPadding: 0,
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
          select: {
            enabled: false,
          },
        },
      },
    },
  };

  return (
    <div
      data-cy={`OverviewGraph-${props.graphDefinition.type}`}
      className={classes.container}
    >
      {props.graphDefinition.yAxisLabels && (
        <div className={classes.yAxisLabels}>
          <Typography variant="caption" className={classes.label}>
            {props.graphDefinition.yMax || dataMax}
          </Typography>
          <Typography variant="caption" className={classes.label}>
            {props.graphDefinition.yMin || dataMin}
          </Typography>
        </div>
      )}
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="stockChart"
        allowChartUpdate={false}
        options={chartOptions}
      />
    </div>
  );
};

export default OverviewChart;
