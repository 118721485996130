import React from 'react';
import {
  Paper,
  Typography,
  LinearProgress,
  makeStyles,
} from '@material-ui/core';
import { Flipper, Front, Back } from 'react-flipper';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckIcon from '@material-ui/icons/Check';
import { darkenOrLighten } from '../../utils/theme';
import uploaderTools from './utils/uploaderTools';
import { AttachedFile } from './interfaces/uploader-tools';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  file: {
    background: darkenOrLighten(
      theme.id,
      theme.palette.background.paper,
      0.05,
      0.1,
    ),
    color: theme.palette.getContrastText(
      darkenOrLighten(theme.id, theme.palette.background.paper, 0.05, 0.1),
    ),
    margin: theme.spacing(0.5),
    alignItems: 'center',
    height: 35,
    position: 'relative',
    display: 'grid',
    gridTemplateAreas: "'icon filename filename' 'icon size type'",
    gridTemplateColumns: '40px auto',
    '& .flipper-container, & .tile': {
      maxHeight: 20,
      height: 20,
      width: 20,
      padding: 0,
    },
  },
  iconContainer: {
    gridArea: 'icon',
  },
  icon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.getContrastText(
      darkenOrLighten(theme.id, theme.palette.background.paper, 0.05, 0.1),
    ),
  },
  iconSuccess: {
    marginLeft: theme.spacing(1),
    color: theme.colors.statuses.success,
  },
  name: {
    gridArea: 'filename',
    textAlign: 'left',
  },
  size: {
    gridArea: 'size',
    textAlign: 'left',
    flex: 1,
    color: theme.colors.text.lighter,
  },
  type: {
    gridArea: 'type',
  },
  progressBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 2,
    borderRadius: '0px 0 5px 5px',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.colors.statuses.success,
    },
    '& .MuiLinearProgress-dashedColorPrimary': {
      opacity: 0.8,
      backgroundColor: theme.colors.statuses.success,
    },
  },
}));

export interface FileItemProps {
  key: string | number | undefined;
  attachment: AttachedFile;
  condensed?: boolean;
}

function FileItem(props: FileItemProps): JSX.Element {
  const classes = useStyles();

  return (
    <Paper className={classes.file}>
      <div className={classes.iconContainer}>
        <Flipper
          isFlipped={props.attachment.uploadComplete}
          orientation="horizontal"
        >
          <Front>
            <DescriptionIcon
              fontSize="small"
              className={classes.icon}
              data-cy="attachedFileTypeIcon"
            />
          </Front>
          <Back>
            <CheckIcon
              fontSize="small"
              className={classes.iconSuccess}
              data-cy="attachedFileSuccessIcon"
            />
          </Back>
        </Flipper>
      </div>

      <Typography
        variant={props.condensed ? 'caption' : undefined}
        className={classes.name}
        noWrap
        data-cy="attachedFileName"
      >
        {props.attachment.file.name}
      </Typography>
      <Typography
        variant="caption"
        className={classes.size}
        noWrap
        data-cy="attachedFileSizes"
      >
        {uploaderTools.convertBytesSizeToText(props.attachment.file.size)}
        {props.attachment.uploading
          ? ` - ${props.attachment.progress.toFixed(2)}%`
          : /* + '(' +
            props.attachment.remainingParts +
            '/' +
            props.attachment.totalParts +
            ')' */
            ''}
      </Typography>
      {!props.condensed && (
        <Typography variant="caption" className={classes.type}>
          {props.attachment.definition.text}
        </Typography>
      )}
      {props.attachment.uploading ? (
        <LinearProgress
          variant={props.attachment.progress > 0 ? 'determinate' : 'query'}
          className={classes.progressBar}
          value={props.attachment.progress}
          valueBuffer={props.attachment.progress}
          data-cy="attachmentProgressBar"
        />
      ) : (
        ''
      )}
    </Paper>
  );
}

export default FileItem;
