import _ from 'underscore';
import Logger from '../utils/logger';
import chartTools from '../components/Chart/SignalChart/chartTools';
import eventChartTools from '../components/Chart/SignalChart/eventChartTools';
import ScoringService from './scoringService';
import { SignalEventDetailData } from '../interfaces/signal-event-detail-props';
import EventService from './eventService';

let subscriptionId: string;

let lastUsedPlotbandId: string | undefined;
let positionEventsReceived = false;

export const PositionPlotbandIdPrefix = 'Position_';

interface PlotBandOptions {
  options: Highcharts.XAxisPlotBandsOptions;
}

interface PositionLabelingServiceInterface {
  initialize: () => void;
  processPlotBands: () => void;
  filterByPositionEvents: (
    markers: SignalEventDetailData[],
  ) => Promise<SignalEventDetailData[]>;
  splitPlotBands: (
    plotBandList: Highcharts.XAxisPlotBandsOptions[],
  ) => Promise<Highcharts.XAxisPlotBandsOptions[]>;
  convertToPlotBands: (
    events: SignalEventDetailData[],
  ) => Promise<Highcharts.XAxisPlotBandsOptions[]>;
  getPlotbandId: () => string;
  getLastUsedPlotbandId: () => string | undefined;
  autofitPlotBands: () => Promise<boolean>;
  setEventReceived: (areReceived: boolean) => void;
  getEventReceived: () => boolean;
  labelFormatter: Highcharts.DataLabelsFormatterCallbackFunction;
}

const PositionLabelingService: PositionLabelingServiceInterface = {
  initialize: () => {
    Logger.log('[PositionLabelingService][initialize] Init');

    PositionLabelingService.setEventReceived(false);
    if (subscriptionId) EventService.unsubscribe(subscriptionId);
    EventService.subscribe(
      'ScoringChanged',
      (marker: SignalEventDetailData) => {
        if (eventChartTools.isPositionMarker(marker)) {
          // Logger.log('[PositionLabelingService][initialize] calling processPlotBands');

          PositionLabelingService.processPlotBands();
        }
      },
    );
  },
  processPlotBands: _.debounce(() => {
    Logger.log('[PositionLabelingService][processPlotBands] Init');

    const chart = chartTools.getChartFromRegistry('Activity-Gravity');
    if (chart) {
      ScoringService.getAllMarkersForCurrentPart()
        .then(PositionLabelingService.filterByPositionEvents)
        .then(PositionLabelingService.convertToPlotBands)
        .then(PositionLabelingService.splitPlotBands)
        .then((plotBands) => chartTools.addPlotBandsToChart(chart, plotBands))
        .then((plotBandsAdded) =>
          PositionLabelingService.setEventReceived(plotBandsAdded),
        );
    }
  }, 200),
  filterByPositionEvents: (
    markers: SignalEventDetailData[],
  ): Promise<SignalEventDetailData[]> => {
    return new Promise((resolve) => {
      Logger.log('[PositionLabelingService][filterByPositionEvents] Init');
      const positionEvents = markers.filter((marker) =>
        eventChartTools.isPositionMarker(marker),
      );
      resolve(positionEvents);
    });
  },
  splitPlotBands: (
    plotBandList: Highcharts.XAxisPlotBandsOptions[],
  ): Promise<Highcharts.XAxisPlotBandsOptions[]> => {
    return new Promise((resolve) => {
      Logger.log('[PositionLabelingService][splitPlotBands] Init');
      const time = Date.now();
      const newPlotBandList = plotBandList
        .map((originalPlotBand) => {
          if (
            originalPlotBand.to &&
            originalPlotBand.from &&
            originalPlotBand.to - originalPlotBand.from > 30000
          ) {
            const splitPlotBandList = [];
            for (
              let i = originalPlotBand.from;
              i + 30000 <= originalPlotBand.to;
              i += 30000
            ) {
              const newPlotBand = { ...originalPlotBand };
              newPlotBand.from = i;
              newPlotBand.to = i + 30000;
              splitPlotBandList.push(newPlotBand);
            }
            const lastIndex = splitPlotBandList.length - 1;
            splitPlotBandList[lastIndex].to = originalPlotBand.to;
            return splitPlotBandList;
          }
          return originalPlotBand;
        })
        .flat();

      Logger.log(
        '[PositionLabelingService][convertToPlotBands] Done! %d -> %d. Took (ms):',
        plotBandList.length,
        newPlotBandList.length,
        Date.now() - time,
      );
      resolve(newPlotBandList);
    });
  },
  convertToPlotBands: (
    events: SignalEventDetailData[],
  ): Promise<Highcharts.XAxisPlotBandsOptions[]> => {
    return new Promise((resolve) => {
      Logger.log('[PositionLabelingService][convertToPlotBands] Init');
      const time = Date.now();
      const guid = PositionLabelingService.getPlotbandId();

      const plotBandList: Highcharts.XAxisPlotBandsOptions[] = events.map(
        (position) => ({
          id: guid, // They all get the same ID so we can remove them all at once
          from: new Date(position.start).valueOf(),
          to: new Date(position.end).valueOf(),
          color: 'transparent',
          label: {
            text: eventChartTools.getShortNameByEventType(position.type)
              ? eventChartTools.getShortNameByEventType(position.type).charAt(0)
              : '?',
            verticalAlign: 'middle',
            style: {
              color: '#999999',
              fontSize: '30',
            },
          },
        }),
      );

      // Logger.log('[convertToPlotBands] Final plotBand list', plotBandList);
      Logger.log(
        '[PositionLabelingService][convertToPlotBands] Took (ms):',
        Date.now() - time,
      );

      resolve(plotBandList);
    });
  },

  getPlotbandId: () => {
    const newId = _.uniqueId(PositionPlotbandIdPrefix + Date.now().toString());
    lastUsedPlotbandId = newId;
    return newId;
  },
  getLastUsedPlotbandId: () => lastUsedPlotbandId,
  autofitPlotBands: () => {
    return new Promise((resolve) => {
      Logger.log('[autoFitPlotBands] Starting');
      if (lastUsedPlotbandId !== undefined) {
        const time = Date.now();
        const chart = chartTools.getChartFromRegistry('Activity-Gravity');
        if (chart) {
          const plotBands = chartTools.getPlotBands(chart);
          if (plotBands.length > 0) {
            let anyPositionPlotband = false;
            const plotBandsOptions: Highcharts.XAxisPlotBandsOptions[] = [];

            plotBands.forEach((plotBand) => {
              const { options } = plotBand as unknown as PlotBandOptions;
              if (options.id === lastUsedPlotbandId) {
                if (!anyPositionPlotband) {
                  anyPositionPlotband = true;
                }

                plotBandsOptions.push(
                  (plotBand as unknown as PlotBandOptions).options,
                );
              }
            });

            if (anyPositionPlotband) {
              // All position plotbands have the same ID
              // We remove them all at once
              chart.xAxis[0].removePlotBand(lastUsedPlotbandId);

              const newId = PositionLabelingService.getPlotbandId();
              const newPlotBands = plotBandsOptions.map((plotBand) => ({
                ...plotBand,
                id: newId,
              }));

              // Logger.log('[autoFitPlotBands] newPlotBands', newPlotBands);
              chartTools.addPlotBandsToChart(chart, newPlotBands);
            }
          }
        }
        Logger.log('[autoFitPlotBands] Done! Took (ms):', Date.now() - time);
      } else {
        Logger.log('[autoFitPlotBands] Skipping because there are none.');
      }

      resolve(true);
    });
  },
  setEventReceived: (areReceived: boolean) => {
    positionEventsReceived = areReceived;
    EventService.dispatch('PositionEventReceived', areReceived);
  },
  getEventReceived: () => positionEventsReceived,
  labelFormatter: function labelFormatter() {
    return this.point.name.charAt(0);
  } as Highcharts.DataLabelsFormatterCallbackFunction,
};

export default PositionLabelingService;
