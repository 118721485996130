import React from 'react';
import clsx from 'clsx';
import { Button, Grow, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  WithPageStatus,
  PageId,
  SignalSheetTab,
} from '../../../interfaces/page-status';
import signalEventDetailService from '../../SignalEventDetail/signalEventDetailService';
import studyTools from '../../StudyOverview/studyTools';
import Logger from '../../../utils/logger';
import SubmittedPartIcon from './SubmittedPartIcon';
import sheetTools from '../../SignalSheet/sheetTools';
import EventService from '../../../services/eventService';
import ScoringService from '../../../services/scoringService';

interface NavigationBarButtonProps {
  direction: string;
  pageId: PageId;
  children: string;
  signalSheetTab?: SignalSheetTab;
  onlyForSubmittedScoring?: boolean;
}

const useStyles = makeStyles((theme) => ({
  tab: {
    display: 'initial',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    paddingBottom: 7,
    margin: '2px 5px 0 5px',
    borderRadius: '4px 4px 0 0',
    border: `1px solid ${theme.colors.navBar.border}`,
    borderBottom: 'none',
    boxShadow: 'inset 0px -2px 4px -4px black',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.getContrastText(theme.palette.secondary.main),
    },
    textAlign: 'center',
    lineHeight: '12px',
    height: 38,
    maxWidth: 100,
  },
  selected: {
    height: 40,
    marginTop: 4,
    paddingBottom: 9,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
  },
}));

export default function NavigationBarButton(
  props: WithPageStatus<NavigationBarButtonProps>,
): JSX.Element {
  const [hasSubmittedPart, setHasSubmittedPart] = React.useState(
    ScoringService.getSubmittedPartId() !== undefined,
  );

  const classes = useStyles();
  const { pageStatus } = props;
  const isCurrentPage =
    pageStatus.status.currentPage === props.pageId &&
    (!pageStatus.status.sheetInfo ||
      pageStatus.status.sheetInfo.partId === props.signalSheetTab?.partId) &&
    pageStatus.status.sheetInfo?.customId === props.signalSheetTab?.customId;
  const history = useHistory();

  Logger.log('[NavigationBarButton] props', props);
  Logger.log('[NavigationBarButton] isCurrentPage', isCurrentPage);

  React.useEffect(() => {
    Logger.log('[NavigationBarButton] useEffect');
    setHasSubmittedPart(ScoringService.getSubmittedPartId() !== undefined);
    const cbId = EventService.subscribe('ScoringSubmittedPart', (part) =>
      setHasSubmittedPart(part !== undefined),
    );

    return () => {
      setHasSubmittedPart(false);
      EventService.unsubscribe(cbId);
    };
  }, []);

  return (
    <Grow
      appear={props.signalSheetTab?.animate}
      mountOnEnter
      unmountOnExit
      in={props.onlyForSubmittedScoring ? hasSubmittedPart : true}
      onEntered={() => {
        if (props.signalSheetTab) {
          studyTools.disableSignalSheetTabAnimation(props.signalSheetTab);
        }
      }}
      timeout={500}
    >
      <Button
        data-cy={`${props.pageId}Tab`}
        className={clsx(classes.tab, { [classes.selected]: isCurrentPage })}
        color="inherit"
        onClick={() => {
          if (!isCurrentPage) {
            if (props.signalSheetTab) {
              EventService.dispatch('Sheet.Switching');
            }

            signalEventDetailService.popup.close();
            pageStatus.setStatus((status) => ({
              ...status,
              currentPage: props.pageId,
              sheetInfo: props.signalSheetTab
                ? { partId: props.signalSheetTab.partId }
                : undefined,
            }));

            const url = [
              '/',
              props.direction,
              '?id=',
              pageStatus.status.recordingId,
              '&scoringId=',
              sheetTools.getScoringId(),
            ];
            if (props.signalSheetTab) {
              url.push(...['&part=', props.signalSheetTab.partId.toString()]);

              if (props.signalSheetTab.customId) {
                url.push(
                  ...['&customId=', props.signalSheetTab.customId.toString()],
                );
              }
            }
            history.push(url.join(''));
          }
        }}
      >
        {props.children}
        {props.signalSheetTab && (
          <Typography component="div" variant="caption">
            <SubmittedPartIcon partId={props.signalSheetTab.partId} />
            Part {props.signalSheetTab.partId}
          </Typography>
        )}
      </Button>
    </Grow>
  );
}
