import { fade, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    color: fade(theme.colors.modal.color, 0.54),
  },

  dotFlashing: {
    position: 'relative',
    width: 5,
    height: 5,
    borderRadius: 5,
    backgroundColor: fade(theme.colors.modal.color, 0.54),
    animation: '$dotFlashing .5s infinite linear alternate',
    animationDelay: '.25s',
    '&:before': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: -8,
      width: 5,
      height: 5,
      borderRadius: 5,
      backgroundColor: fade(theme.colors.modal.color, 0.54),
      animation: '$dotFlashing .5s infinite alternate',
      animationDelay: '0s',
    },
    '&:after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: 8,
      width: 5,
      height: 5,
      borderRadius: 5,
      backgroundColor: fade(theme.colors.modal.color, 0.54),
      animation: '$dotFlashing .5s infinite alternate',
      animationDelay: '.5s',
    },
  },

  '@keyframes dotFlashing': {
    '0%': {
      backgroundColor: fade(theme.colors.modal.color, 0.54),
    },
    '50': {},
    '100%': {
      backgroundColor: fade(theme.colors.modal.color, 0.24),
    },
  },
}));

function ThreeLoadingDots(): JSX.Element {
  const classes = useStyles();

  return <div className={classes.dotFlashing} />;
}

export default ThreeLoadingDots;
