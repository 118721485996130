import { Button, fade, makeStyles, Paper, Typography } from '@material-ui/core';
import _ from 'underscore';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Recording } from '../../queries/recording';
import Logger from '../../utils/logger';
import chartEvents from '../SignalSheet/chartEvents';
import OverviewNavigatorOverlay from './OverviewNavigatorOverlay';
import chartTools from '../Chart/SignalChart/chartTools';
import HelpTooltip from '../Shared/HelpTooltip';
import OverviewSleepWidget from './OverviewSleepWidget';
import { ScoringInsights } from '../../queries/scoringInsights';
import SleepStageService from '../../services/sleepStageService';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: 'auto 197px',
    gap: theme.spacing(1),
  },
  paper: {
    position: 'relative',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    width: '100%',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  widget: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    alignItems: 'center',
    position: 'relative',
  },
  container: {
    width: '100%',
    position: 'relative',
  },
  widthControl: {
    width: '100%',
  },
  clear: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),

    color: theme.palette.getContrastText(theme.palette.background.paper),
    borderColor: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.23,
    ),
    fontSize: 12,
    textTransform: 'initial',
    textAlign: 'center',
    lineHeight: '12px',
    margin: theme.spacing(0.5, 1),
    maxWidth: 125,
    whiteSpace: 'normal',
    fontWeight: 400,

    '&:hover': {
      backgroundColor: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.05,
      ),
    },
  },
}));

interface OverviewNavigatorProps {
  loading: boolean;
  recording?: Recording;
  scoringInsights?: ScoringInsights;
  highlightPartId?: number;
  setHighlightPartId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const OverviewNavigator = (props: OverviewNavigatorProps): JSX.Element => {
  const classes = useStyles();

  const widthControlRef = React.useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = React.useState(0);
  const [chartOptions] = React.useState<Highcharts.Options>({
    chart: {
      margin: 0,
      spacingTop: 0,
      animation: false,
      spacingBottom: 0,
      height: 50,
      style: { borderRadius: 4, pointerEvents: 'none' },
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      events: {
        load() {
          Logger.log('[OverviewNavigator] load');
          chartEvents.onLoad('NavigatorChart', this, { isSignalChart: false });
          chartTools.redrawNavigator();
        },
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    accessibility: {
      keyboardNavigation: {
        enabled: true,
      },
    },
    plotOptions: {
      series: {
        showInNavigator: true,
        marker: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    navigator: {
      height: 50,
      adaptToUpdatedData: true,
      outlineColor: 'transparent',
      maskFill: 'transparent',
      handles: {
        enabled: false,
      },
      series: {
        fillOpacity: 1,
        lineWidth: 0,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, '#d32f2f'], // start
            [0.8, '#f57c00'], // middle
            [1, '#0288d1'], // end
          ],
        },
        enableMouseTracking: false,
      },
      xAxis: {
        gridLineWidth: 0,
      },
      yAxis: {
        softMax: 10,
        softMin: 0,
      },
    },
    xAxis: {
      visible: false,
      events: {
        setExtremes: (e) => {
          Logger.log('[getNavigatorChartOptions] e', e);
          Logger.debug('[getNavigatorChartOptions] Diff is:', e.max - e.min);
          chartTools.redrawNavigator();
        },
      },
      maxRange: 10 * 60 * 1000, // 10 mins
      minRange: 30000, // 30 secs
      ordinal: true,
    },
    yAxis: {
      height: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    series: [
      {
        data: [],
        type: 'line',
      },
    ],
  });

  const onResize = _.throttle(() => {
    Logger.log('onResize');

    if (widthControlRef && widthControlRef.current) {
      const SLEEP_WIDGET_WIDTH =
        props.recording?.deviceInfo?.type &&
        SleepStageService.getDeviceAvailability().includes(
          props.recording?.deviceInfo?.type,
        )
          ? 209
          : 0;
      const EXTRA_MARGINS = 16;
      setContainerWidth(
        widthControlRef.current.clientWidth -
          SLEEP_WIDGET_WIDTH -
          EXTRA_MARGINS,
      );
    }

    const chart = chartTools.getChartFromRegistry('NavigatorChart');
    if (chart) {
      chart.reflow();
    }
  }, 250);

  React.useEffect(() => {
    window.addEventListener('resize', onResize);

    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [props.recording, props.loading]);

  return (
    <>
      <div className={classes.widthControl} ref={widthControlRef} />
      <div className={classes.root}>
        <Paper
          className={classes.paper}
          style={{ width: containerWidth || '100%' }}
        >
          <Typography variant="h6" gutterBottom>
            Event Severity Overview
            <HelpTooltip text="Calculated severity of events per epoch" />
            {props.highlightPartId && (
              <Button
                className={classes.clear}
                variant="outlined"
                onClick={() => props.setHighlightPartId(undefined)}
              >
                Clear Selection
              </Button>
            )}
          </Typography>
          {props.recording ? (
            <div data-cy="EventSeverityOverview" className={classes.container}>
              <HighchartsReact
                highcharts={Highcharts}
                constructorType="stockChart"
                allowChartUpdate={false}
                options={chartOptions}
              />

              <OverviewNavigatorOverlay
                recording={props.recording}
                highlightPartId={props.highlightPartId}
                setHighlightPartId={props.setHighlightPartId}
                containerWidth={containerWidth - 32}
              />
            </div>
          ) : (
            <Skeleton variant="text" animation="wave" width={400} />
          )}
        </Paper>
        {props.recording &&
          props.recording?.deviceInfo?.type &&
          SleepStageService.getDeviceAvailability().includes(
            props.recording?.deviceInfo?.type,
          ) && (
            <Paper className={classes.widget}>
              <OverviewSleepWidget
                recording={props.recording}
                scoringInsights={props.scoringInsights}
                highlightPartId={props.highlightPartId}
              />
            </Paper>
          )}
      </div>
    </>
  );
};

export default OverviewNavigator;
