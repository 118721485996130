import gql from 'graphql-tag';

export interface RecordingFileInput {
  name: string;
  size: number;
}

export type ImportId = string;

export interface RequestImportMutationParameters {
  files: RecordingFileInput[];
}

export interface RequestImportMutationResult {
  recordingImport: ImportId;
}

export const REQUEST_IMPORT_MUTATION = gql`
  mutation RequestImportRecording($files: [RecordingFileInput!]!) {
    recordingImport(files: $files)
  }
`;
