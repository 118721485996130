import {
  Zoom,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import React, { MouseEventHandler } from 'react';
import { SnackbarKey } from 'notistack';
import longRunningOperationService, {
  LongRunningOperation,
  LongRunningOperationResult,
} from '../../services/longRunningOperationService';
import ActionOrDismiss from '../ActionOrDismiss/ActionOrDismiss';
import queryManager from '../../services/queryManager';
import { RequestExportMutationResult } from '../../queries/exportRecording/requestExport';
import {
  ExportId,
  GetExportStatusQueryResult,
} from '../../queries/exportRecording/getExportStatus';
import Analytics from '../../services/analytics';
import { RecordingId } from '../../queries/recording';
import NotificationService from '../../services/notificationService';
import Logger from '../../utils/logger';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.getContrastText(theme.palette.background.paper),
      marginLeft: 5,
      padding: 0,
      minWidth: 35,
      marginBottom: 4,
      '&:hover': {
        backgroundColor: 'transparent',
        opacity: 0.8,
      },
    },
    actions: {
      color: theme.palette.primary.contrastText,
    },
    failed: {
      color: theme.colors.statuses.error,
    },
    menuItemText: {
      paddingLeft: 10,
      textTransform: 'initial',
    },
  }),
);

interface ExportRecordingProps {
  recordingId: RecordingId;
  isMenuItem?: boolean;
  closeMenu?: () => void;
}

const ExportRecording = (props: ExportRecordingProps): JSX.Element => {
  const classes = useStyles();

  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [visible, setVisible] = React.useState(true);

  const handleButtonClick: MouseEventHandler = (e) => {
    e.preventDefault();
    if (!loading && !success) {
      setSuccess(false);
      setFailed(false);
      setLoading(true);

      if (props.isMenuItem && props.closeMenu) props.closeMenu();

      Analytics.track.event('EXPORT_REQUEST', {
        recordingId: props.recordingId,
      });

      queryManager
        .mutate<RequestExportMutationResult>('requestExport', {
          recordingId: props.recordingId,
        })
        .then((data) => {
          const exportId = data.recordingExport as ExportId;

          longRunningOperationService.addOperation({
            type: 'Export',
            message: 'Exporting recording. Please wait...',
            fireImmediately: true,
            wakeUpIn: 1000,
            task: () =>
              new Promise<LongRunningOperationResult>((resolve, reject) => {
                queryManager
                  .query<GetExportStatusQueryResult>('ExportStatus', {
                    exportId,
                  })
                  .then((result) => {
                    if (result.recordingExport.status === 'Exported') {
                      resolve({
                        finished: true,
                        data: result.recordingExport.url,
                      });
                    } else if (result.recordingExport.status === 'Failed') {
                      reject(result.recordingExport.status);
                    } else if (result.recordingExport.status === 'Requested') {
                      resolve({ finished: false });
                    }
                  })
                  .catch((error) => {
                    reject(error);
                  });
              }),
            successAction: (url: string, lro: LongRunningOperation) => {
              Analytics.track.event('EXPORT_SUCCESS', {
                recordingId: props.recordingId,
                exportId,
                completedTime: lro.getElapsedTime(),
              });

              const downloadOrDismissActions = (key: SnackbarKey) => (
                <ActionOrDismiss
                  actionText="Download"
                  actionOnClick={() => {
                    window.open(url);
                    NotificationService.close(key);
                  }}
                  dismissText="Dismiss"
                  dismissOnClick={() => NotificationService.close(key)}
                />
              );

              NotificationService.send('The recording has been exported', {
                variant: 'success',
                persist: true,
                customAction: downloadOrDismissActions,
              });
            },
            errorAction: (error: unknown, lro: LongRunningOperation) => {
              Analytics.track.event('EXPORT_ERROR', {
                recordingId: props.recordingId,
                exportId,
                completedTime: lro.getElapsedTime(),
              });

              const dismissAction = (key: SnackbarKey) => (
                <ActionOrDismiss
                  dismissText="Dismiss"
                  dismissOnClick={() => NotificationService.close(key)}
                />
              );

              setSuccess(false);
              setFailed(false);
              setLoading(false);
              setVisible(true);
              setDone(false);

              NotificationService.closeAll();
              NotificationService.send(
                'Export operation has failed. Please, try again or contact support.',
                {
                  variant: 'error',
                  persist: true,
                  customAction: dismissAction,
                },
              );
            },
          });

          setFailed(false);
          setLoading(false);
          setSuccess(true);
          setTimeout(() => setDone(true), 1000);
          setTimeout(() => setFailed(false), 5000);
        })
        .catch((error: unknown) => {
          setFailed(true);
          setLoading(false);
          setSuccess(false);
          setTimeout(() => setFailed(false), 2000);
          Logger.error('[ExportRecording] error:', error);
        });
    }
  };

  return (
    <>
      {visible ? (
        <Zoom in={!done || props.isMenuItem} timeout={{ enter: 0, exit: 200 }}>
          <Button onClick={handleButtonClick} className={classes.button}>
            {((): JSX.Element => {
              if (success) return <CheckIcon />;
              if (failed) return <ClearIcon className={classes.failed} />;
              if (loading) return <CircularProgress size={20} />;
              return (
                <Tooltip title="Export recording" arrow>
                  <CloudDownloadIcon />
                </Tooltip>
              );
            })()}

            {props.isMenuItem && (
              <Typography className={classes.menuItemText}>
                Export recording
              </Typography>
            )}
          </Button>
        </Zoom>
      ) : (
        ''
      )}
    </>
  );
};

export default ExportRecording;
