import React from 'react';
import clsx from 'clsx';

import { Button, makeStyles, Theme, fade } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: 'transparent',
    color: theme.palette.secondary.contrastText,
    borderColor: fade(theme.palette.secondary.contrastText, 0.2),
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.contrastText, 0.1),
    },
  },
}));

interface OutlinedButtonProps {
  className: string;
  children: string;
  onClick: (...args: unknown[]) => void;
}

const OutlinedButton = (props: OutlinedButtonProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      data-cy={props.children}
      className={clsx(classes.button, props.className)}
      variant="outlined"
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export default OutlinedButton;
