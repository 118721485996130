import { ApolloClient, ApolloError, DocumentNode } from '@apollo/client';
import { GET_SIGNAL } from '../queries/signal';
import Logger from '../utils/logger';
import {
  PendingQueries,
  QueriesQueue,
  QueryFunction,
  QueryFunctionCallback,
  QueryManager,
  QueryParameters,
  QueryType,
  QueryObject,
  SubscriptionEvent,
  MutationType,
  SubscriptionDataCallback,
  SubscriptionErrorCallback,
  MutationParams,
  QueryParams,
  SubscriptionParams,
} from '../interfaces/query-manager';
import {
  SCORING_CHANGED_SUBSCRIPTION,
  MARKER_CHANGED_MUTATION,
} from '../queries/subscriptions/scoringChanged';

import { REQUEST_IMPORT_MUTATION } from '../queries/uploadRecording/requestImport';
import { GET_IMPORT_STATUS_QUERY } from '../queries/uploadRecording/getImportStatus';
import { START_FILE_UPLOAD_MUTATION } from '../queries/uploadRecording/startFileUpload';
import { COMPLETE_UPLOAD_MUTATION } from '../queries/uploadRecording/completeUpload';
import { REQUEST_EXPORT_MUTATION } from '../queries/exportRecording/requestExport';
import { GET_EXPORT_STATUS_QUERY } from '../queries/exportRecording/getExportStatus';
import { GET_RECORDING } from '../queries/recording';
import { GET_RECORDINGS } from '../queries/recordings';
import { ChartRange } from '../components/SignalSheet/interfaces/chart-range';
import chartRangeTools from '../components/SignalSheet/chartRangeTools';
import { GET_SCORING } from '../queries/scoring';
import {
  GET_ANALYSIS_PERIODS,
  ANALYSIS_PERIODS_MUTATION,
} from '../queries/analysisPeriods';
import {
  GET_SCORING_INSIGHTS,
  SUBMIT_SCORING_PART_MUTATION,
} from '../queries/scoringInsights';
import { GET_ACCESS_TOKEN } from '../queries/accessToken';
import AuthService from './authService';
import { GET_IS_AUTHENTICATED } from '../queries/isAuthenticated';
import { GET_GRAPH_DATA } from '../queries/graphData';
// eslint-disable-next-line max-len
import { SCORING_INSIGHTS_UPDATED_SUBSCRIPTION } from '../queries/subscriptions/scoringInsightsUpdated';
import {
  ADD_REPORT_COMMENT,
  GET_REPORT,
  GET_REPORT_COMMENTS,
} from '../queries/report';

let GQLClient: ApolloClient<unknown>;

let queriesQueue: QueriesQueue = {};
let pendingQueries: PendingQueries = {};

const queryManager: QueryManager = {
  initializeQueryManager: (client: ApolloClient<unknown>) => {
    GQLClient = client;
    queriesQueue = {};
    pendingQueries = {};
  },
  addSignalQuery: (
    requestRange: ChartRange,
    params: QueryParameters,
    callback: QueryFunctionCallback,
    retries?: number,
  ) => {
    Logger.log(
      '[addQuery][%s] Requesting query addition: %s - %s',
      params.signalType,
      params.beginning,
      params.end,
    );
    Logger.debug(
      '[addQuery][%s] params:',
      params.signalType,
      JSON.stringify(params),
    );

    if (!queriesQueue[params.signalType]) {
      Logger.log(
        '[addQuery][%s] Creating queries queue entry for signal',
        params.signalType,
      );
      queriesQueue[params.signalType] = [];
    }

    const signalQueries = queriesQueue[params.signalType];
    const signalPending = queryManager.isSignalPending(params.signalType);

    const queryObject: QueryObject = queryManager.createQueryObject(
      Date.now(),
      requestRange,
      params,
      callback,
      retries,
    );

    const isQueryInQueue = signalQueries.some(
      (query) => query.id === queryObject.id,
    );
    const isQueryPending =
      queryManager.getPendingQueryForSignal(params.signalType)?.id ===
      queryObject.id;

    if (!isQueryInQueue && !isQueryPending) {
      if (
        !queryManager.isRequestRangeWithinPendingQueryForSignal(
          params.signalType,
          requestRange,
        )
      ) {
        signalQueries.push(queryObject);
        queriesQueue[params.signalType] = signalQueries;
        Logger.log(
          '[addQuery][%s] Queue length is: ',
          params.signalType,
          signalQueries,
        );
      } else {
        Logger.log(
          '[addQuery][%s] Requested range is already being requested. Skipping.',
          params.signalType,
        );
      }
    } else {
      Logger.log(
        '[addQuery][%s] Query is already in the queue. Skipping.',
        params.signalType,
      );
    }

    if (signalQueries.length > 0 || signalPending) {
      Logger.log(
        '[addQuery][%s] No queries on the queue. Executing.',
        params.signalType,
      );

      setTimeout(() => {
        queryManager.executeNextQueryForSignal(params.signalType);
      }, 0);
    }
    Logger.log('[addQuery][%s] Finished.', params.signalType);
  },
  createQueryObject: (
    timestamp: number,
    requestRange: ChartRange,
    params: QueryParameters,
    callback: QueryFunctionCallback,
    retries?: number,
  ) => {
    const newRetries = retries || 0;

    const queryFunction: QueryFunction = async () => {
      const time = Date.now();
      Logger.log(
        '[addQuery][%s] Querying signal: %s - %s',
        params.signalType,
        params.beginning,
        params.end,
      );

      GQLClient.query({
        query: GET_SIGNAL,
        variables: params,
        fetchPolicy: 'no-cache',
      })
        .then(({ data }) => {
          Logger.log(
            '[addQuery][%s] We got the data (%s). Took (ms):',
            params.signalType,
            Date.now() - time,
          );
          Logger.log('[addQuery][%s] Data: ', params.signalType, data);

          queryManager.removePendingQueryForSignal(params.signalType);
          callback(data.signal);
          queryManager.executeNextQueryForSignal(params.signalType);
        })
        .catch((error: ApolloError) => {
          Logger.error(
            '[addQuery][%s] Got error. Took %d ms. Error:',
            params.signalType,
            Date.now() - time,
            error,
          );

          Logger.error(
            '[addQuery][%s] Adding the query to the queue again (Retried %d times)',
            params.signalType,
            newRetries + 1,
          );
          queryManager.removePendingQueryForSignal(params.signalType);

          // TODO Add a better retry system
          setTimeout(
            () =>
              queryManager.addSignalQuery(
                requestRange,
                params,
                callback,
                newRetries + 1,
              ),
            queryManager.calculateRetryTimeout(newRetries),
          );

          AuthService.checkIfErrorIsAuthRelated(error);
        });
    };

    return {
      id: JSON.stringify(params),
      timestamp,
      function: queryFunction,
      requestRange,
      retries: newRetries,
    };
  },
  executeQueries: () => {
    return new Promise((resolve, reject) => {
      try {
        Logger.log('[executeQueries] Extracting query functions');
        if (queriesQueue) {
          const signals = Object.keys(queriesQueue);

          signals.forEach((signal) => {
            queryManager.executeNextQueryForSignal(signal);
          });
        }
      } catch (err) {
        reject(err);
      }

      resolve(true);
    });
  },
  executeNextQueryForSignal: (signal: string) => {
    Logger.log(
      '[executeNextQueryForSignal][%s] Checking if there are more queries',
      signal,
    );
    if (!queryManager.isSignalPending(signal)) {
      let signalQueries: QueryObject[] = queriesQueue[signal];
      if (signalQueries) {
        const signalTotalFunctions = signalQueries.length;

        if (signalTotalFunctions === 0) {
          Logger.log('[executeNextQueryForSignal][%s] No more queries', signal);
        }

        Logger.log(
          '[executeNextQueryForSignal][%s] Original signalQueries:',
          signal,
          signalQueries,
        );

        let nextQueryIndex: number;
        let nextQuery: QueryObject | undefined;
        const byTimestamp = queryManager.recentQueryComparator;
        signalQueries = signalQueries.sort(byTimestamp);

        // Starting block to get the next highest priority query:
        // 1º: Queries within the current extremes
        // 2ª: Queries within the current data range
        // 3ª: Most recent query
        nextQueryIndex = signalQueries.findIndex((query) =>
          chartRangeTools.isEpochRangeWithinCurrentExtremes({
            first: query.requestRange.fromEpoch,
            last: query.requestRange.toEpoch,
          }),
        );
        if (nextQueryIndex > -1) {
          Logger.warn(
            '[executeNextQueryForSignal][%s] Found query within current extremes!',
            signal,
          );
        }

        if (nextQueryIndex === -1) {
          nextQueryIndex = signalQueries.findIndex((query) =>
            chartRangeTools.isTimestampRangeWithinCurrentDataRange(
              query.requestRange.fromTime,
              query.requestRange.toTime,
            ),
          );
          if (nextQueryIndex > -1) {
            Logger.log(
              '[executeNextQueryForSignal][%s] Found query within current data range',
              signal,
            );
          }
        }

        if (nextQueryIndex > -1) {
          [nextQuery] = signalQueries.splice(nextQueryIndex, 1);
        } else {
          nextQuery = signalQueries.pop();

          if (nextQuery) {
            Logger.log(
              '[executeNextQueryForSignal][%s] No urgent queries. Retrieved the most recent one.',
              signal,
            );
          }
        }

        Logger.log(
          '[executeNextQueryForSignal][%s] Selected query:',
          signal,
          nextQuery,
        );
        if (nextQuery) {
          queryManager.setPendingQueryForSignal(signal, nextQuery);
          nextQuery.function();
        }
      } else {
        Logger.log(
          '[executeNextQueryForSignal][%s] Queue no longer exists. User probably switched tabs.',
          signal,
        );
      }
    } else {
      Logger.log(
        '[executeNextQueryForSignal][%s] Skipping execution because of pending query.',
        signal,
      );
    }
  },
  recentQueryComparator: (a: QueryObject, b: QueryObject) => {
    return a.timestamp - b.timestamp;
  },
  setPendingQueryForSignal: (signalName: string, query: QueryObject) => {
    pendingQueries[signalName] = query;
  },
  getPendingQueryForSignal: (signalName: string) => pendingQueries[signalName],
  removePendingQueryForSignal: (signalName: string) =>
    delete pendingQueries[signalName],
  isSignalPending: (signalName: string) => !!pendingQueries[signalName],
  isRequestRangeWithinPendingQueryForSignal: (
    signalName: string,
    requestRange: ChartRange,
  ) => {
    const pendingQuery = queryManager.getPendingQueryForSignal(signalName);
    const signalQueries = queriesQueue[signalName];
    const totalPendingQueries = [...signalQueries];
    if (pendingQuery) totalPendingQueries.push(pendingQuery);

    const isWithin = totalPendingQueries.some(
      (query) =>
        requestRange.fromEpoch >= query.requestRange.fromEpoch &&
        requestRange.toEpoch <= query.requestRange.toEpoch,
    );

    return isWithin;
  },
  subscribe: <T>(
    event: SubscriptionEvent,
    params: SubscriptionParams,
    onData: SubscriptionDataCallback<T>,
    onError: SubscriptionErrorCallback,
  ) => {
    Logger.log('[queryManager/subscribe] subscribe');
    if (!GQLClient)
      Logger.error('[queryManager/subscribe] Apollo Client not initialized!');

    let GQLQuery: DocumentNode;
    switch (event) {
      case 'scoringChanged':
        GQLQuery = SCORING_CHANGED_SUBSCRIPTION;
        break;
      case 'scoringInsightsUpdated':
        GQLQuery = SCORING_INSIGHTS_UPDATED_SUBSCRIPTION;
        break;
      default:
        throw new Error('event not defined');
    }

    return GQLClient.subscribe({
      query: GQLQuery,
      variables: params,
    }).subscribe({
      next: onData,
      error: onError,
    });
  },

  mutate: (type: MutationType, params: MutationParams) => {
    Logger.log('[queryManager/mutate] Mutate');
    if (!GQLClient)
      Logger.error('[queryManager/subscribe] Apollo Client not initialized!');

    let GQLQuery: DocumentNode;
    switch (type) {
      case 'marker':
        GQLQuery = MARKER_CHANGED_MUTATION;
        break;
      case 'requestImport':
        GQLQuery = REQUEST_IMPORT_MUTATION;
        break;
      case 'requestExport':
        GQLQuery = REQUEST_EXPORT_MUTATION;
        break;
      case 'nextFile':
        GQLQuery = START_FILE_UPLOAD_MUTATION;
        break;
      case 'completeUpload':
        GQLQuery = COMPLETE_UPLOAD_MUTATION;
        break;
      case 'analysisPeriods':
        GQLQuery = ANALYSIS_PERIODS_MUTATION;
        break;
      case 'submitScoringPart':
        GQLQuery = SUBMIT_SCORING_PART_MUTATION;
        break;
      case 'addReportComment':
        GQLQuery = ADD_REPORT_COMMENT;
        break;
      default:
        Logger.warn('[queryManager/mutate] QueryType %s not found!', type);
    }

    return new Promise((resolve, reject) => {
      GQLClient.mutate({
        mutation: GQLQuery,
        variables: params,
      })
        .then(({ data }) => {
          Logger.log('[queryManager/mutate] data');
          resolve(data);
        })
        .catch((error) => {
          Logger.error('[queryManager/mutate] error');
          AuthService.checkIfErrorIsAuthRelated(error);

          reject(error);
        });
    });
  },
  query: (type: QueryType, params?: QueryParams) => {
    Logger.log('[queryManager/query] Query', type);
    if (!GQLClient)
      Logger.error('[queryManager/subscribe] Apollo Client not initialized!');

    let GQLQuery: DocumentNode;
    switch (type) {
      case 'ImportStatus':
        GQLQuery = GET_IMPORT_STATUS_QUERY;
        break;
      case 'ExportStatus':
        GQLQuery = GET_EXPORT_STATUS_QUERY;
        break;
      case 'Recording':
        GQLQuery = GET_RECORDING;
        break;
      case 'RecordingList':
        GQLQuery = GET_RECORDINGS;
        break;
      case 'Scoring':
        GQLQuery = GET_SCORING;
        break;
      case 'ScoringInsights':
        GQLQuery = GET_SCORING_INSIGHTS;
        break;
      case 'AnalysisPeriods':
        GQLQuery = GET_ANALYSIS_PERIODS;
        break;
      case 'AccessToken':
        GQLQuery = GET_ACCESS_TOKEN;
        break;
      case 'IsAuthenticated':
        GQLQuery = GET_IS_AUTHENTICATED;
        break;
      case 'GraphData':
        GQLQuery = GET_GRAPH_DATA;
        break;
      case 'Report':
        GQLQuery = GET_REPORT;
        break;
      case 'ReportComments':
        GQLQuery = GET_REPORT_COMMENTS;
        break;
      default:
        Logger.warn('[queryManager/query] QueryType %s not found!', type);
    }

    return new Promise((resolve, reject) => {
      AuthService.requestAccessTokenIfNeeded({ query: type }).then(() =>
        GQLClient.query({
          query: GQLQuery,
          variables: params || {},
          fetchPolicy: 'no-cache',
        })
          .then(({ data }) => {
            Logger.log('[queryManager/query][%s] data', type);
            resolve(data);
          })
          .catch((error: ApolloError) => {
            Logger.error('[queryManager/query][%s] error', type);
            AuthService.checkIfErrorIsAuthRelated(error);

            reject(error);
          }),
      );
    });
  },
  calculateRetryTimeout(retries: number) {
    const newRetry = 1000 * retries;
    const maxRetry = 10000; // 10 secs

    return newRetry > maxRetry ? maxRetry : newRetry;
  },
};

export default queryManager;
