import React, { useEffect } from 'react';

import { Paper, makeStyles } from '@material-ui/core';
import withNavigationBars from '../../hoc/withNavigationBars';

import withTheme from '../../hoc/withTheme';
import Analytics from '../../services/analytics';
import GoBackToolbar from '../../components/Shared/GoBackToolbar';
import SignalOrderingTitle from '../../components/SignalOrdering/SignalOrderingTitle';
import SignalOrderingWorkspace from '../../components/SignalOrdering/SignalOrderingWorkspace';
import NotificationService from '../../services/notificationService';
import AppTitle from '../../components/Shared/AppTitle';

const useStyles = makeStyles((theme) => ({
  sheet: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    margin: theme.spacing(2, 2),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    gridGap: theme.spacing(2),
  },
}));

const SignalOrdering = () => {
  NotificationService.useNotifications();
  const classes = useStyles();

  useEffect(() => {
    Analytics.track.page('Signal Ordering');
  }, []);

  return (
    <>
      <AppTitle title="Signal Ordering" />
      <GoBackToolbar />
      <Paper className={classes.sheet}>
        <SignalOrderingTitle />
        <SignalOrderingWorkspace />
      </Paper>
    </>
  );
};

export default withTheme(
  withNavigationBars(React.memo(SignalOrdering, () => true)),
);
