import gql from 'graphql-tag';

export interface GetIsAuthenticatedQueryResult {
  isAuthenticated: boolean;
}

export const GET_IS_AUTHENTICATED = gql`
  query IsAuthenticated {
    isAuthenticated
  }
`;
