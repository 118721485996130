import React from 'react';

import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';

import { Button, fade, makeStyles, Paper, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import { useReactToPrint } from 'react-to-print';
import withTheme, { WithTheme } from '../../hoc/withTheme';
import { WithRouterProps } from '../../interfaces/with-router-props';

import HelpContentPath from '../../assets/help/help-manual.md';
import HelpContentRegulatoryPath from '../../assets/help/help-regulatory.md';
import NotificationService from '../../services/notificationService';
import Analytics from '../../services/analytics';
import PrintButton from '../../components/Shared/PrintButton';
import UDI from './UDI';
import KeyboardShortcutsHelp from '../../components/AppBarOptions/KeyboardShortcutsHelp';
import Logger from '../../utils/logger';
import ToolsContainer from '../../components/Shared/ToolsContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.variables.pageMaxWidth,
    margin: '0 auto',
    marginTop: theme.spacing(1),
  },
  sheet: {
    position: 'relative',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  button: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.getContrastText(theme.palette.background.paper),
    backgroundColor: theme.palette.background.paper,
    borderColor: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.23,
    ),
  },
  print: {},
  help: {
    '& h1:first-child': {
      marginTop: 0,
    },
    '& img[alt="CE"]': {
      width: 100,
    },
    '& a': {
      color: theme.colors.text.link,

      '&:visited': {
        color: theme.colors.text.link,
      },
    },
  },
}));

const Help = (props: WithTheme<WithRouterProps<unknown>>) => {
  NotificationService.useNotifications();

  const classes = useStyles();
  const [manual, setManual] = React.useState<string>();
  const [regulatory, setRegulatory] = React.useState<string>();

  const componentRef = React.useRef<HTMLDivElement>(null);

  const print = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: classes.print,
    documentTitle: 'Noxturnal Web Manual',
  });

  const handlePrint = () => {
    Analytics.track.event('HELP_PRINT');

    if (print) {
      print();
    }
  };

  const onSubmitRequest = () => {
    Analytics.track.event('HELP_SUBMIT_REQUEST');
  };

  React.useEffect(() => {
    Analytics.track.page('Help');
    Logger.debug('[Help] With props', props);

    fetch(HelpContentPath)
      .then((response) => response.text())
      .then((text) => {
        setManual(text);
      })
      .then(() => fetch(HelpContentRegulatoryPath))
      .then((response) => response.text())
      .then((text) => {
        setRegulatory(text);
      });
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.sheet}>
        {manual && regulatory && (
          <ToolsContainer>
            <PrintButton handlePrint={handlePrint} />
          </ToolsContainer>
        )}
        {manual && regulatory && (
          <div data-cy="HelpManual" ref={componentRef}>
            <ReactMarkdown
              className={classes.help}
              plugins={[breaks, gfm]}
              linkTarget="_blank"
              skipHtml={false}
            >
              {manual}
            </ReactMarkdown>

            <Typography variant="h6" component="h2" gutterBottom>
              Signal Sheet - Keyboard Shortcuts
            </Typography>
            <KeyboardShortcutsHelp />

            <ReactMarkdown
              className={classes.help}
              plugins={[breaks, gfm]}
              skipHtml={false}
            >
              {regulatory}
            </ReactMarkdown>
          </div>
        )}
      </Paper>
      {manual && regulatory && (
        <Paper className={classes.sheet}>
          <Typography variant="h6">Still have questions?</Typography>
          <Button
            data-cy="SubmitRequestBtn"
            variant="outlined"
            className={classes.button}
            startIcon={<SendIcon />}
            onClick={onSubmitRequest}
            target="_blank"
            href="https://support.noxmedical.com/hc/en-us/requests/new"
          >
            Submit a request
          </Button>
        </Paper>
      )}
      <UDI />
    </div>
  );
};

export default withTheme(Help);
