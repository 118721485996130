import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    textAlign: 'center',
  },
  title: {},
  instructions: {
    color: theme.colors.text.lighter,
    marginBottom: theme.spacing(2),
  },
}));

const SignalOrderingTitle = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h3" className={classes.title}>
          Signal Ordering
        </Typography>
        <Typography className={classes.instructions} variant="body2">
          Here you can customize the order of the signals for each Signal Sheet
        </Typography>
      </div>
    </>
  );
};

export default SignalOrderingTitle;
