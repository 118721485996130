// Key codes: https://keycode.info/
export enum KeyCode {
  Backspace = 'BACKSPACE',
  Alt = 'ALT',
  Enter = 'ENTER',
  Shift = 'SHIFT',
  Control = 'CONTROL',
  Escape = 'ESCAPE',
  Home = 'HOME',
  End = 'END',
  PageUp = 'PAGEUP',
  PageDown = 'PAGEDOWN',
  F9 = 'F9',
  Num0 = '0',
  Num1 = '1',
  Num2 = '2',
  Num3 = '3',
  Num5 = '5',
  ArrowLeft = 'ARROWLEFT',
  ArrowRight = 'ARROWRIGHT',
  Delete = 'DELETE',
  KeyA = 'A',
  KeyB = 'B',
  KeyC = 'C',
  KeyD = 'D',
  KeyH = 'H',
  KeyK = 'K',
  KeyN = 'N',
  KeyM = 'M',
  KeyO = 'O',
  KeyS = 'S',
  KeyT = 'T',
  KeyX = 'X',
  KeyY = 'Y',
  KeyZ = 'Z',
  KeyPlus = '+',
  KeyMinus = '-',
  Meta = 'META',
}

export type KeyboardShortcutId =
  | 'KeyboardShortcut.DeleteEvent'
  | 'KeyboardShortcut.UndoAction'
  | 'KeyboardShortcut.RedoAction'
  | 'KeyboardShortcut.MoveForward'
  | 'KeyboardShortcut.MoveForwardFullPage'
  | 'KeyboardShortcut.MoveBack'
  | 'KeyboardShortcut.MoveBackFullPage'
  | 'KeyboardShortcut.JumpToBeginning'
  | 'KeyboardShortcut.JumpToEnd'
  | 'KeyboardShortcut.ZoomIn'
  | 'KeyboardShortcut.ZoomOut'
  | 'KeyboardShortcut.ScaleToFitAll'
  | 'KeyboardShortcut.ScaleToFit'
  | 'KeyboardShortcut.SelectNextEpoch'
  | 'KeyboardShortcut.SelectPreviousEpoch'
  | 'KeyboardShortcut.ScoreStageWake'
  | 'KeyboardShortcut.ScoreStageREM'
  | 'KeyboardShortcut.ScoreStageN1'
  | 'KeyboardShortcut.ScoreStageN2'
  | 'KeyboardShortcut.ScoreStageN3';

export type KeyboardShortcutCategoryId =
  | 'Tools'
  | 'Navigation'
  | 'Scoring'
  | 'Signal';
export interface KeyboardShortcutCategory {
  id: KeyboardShortcutCategoryId;
  name: string;
}

export type KeyboardShortcutKey = KeyCode[];

export interface KeyboardShortcut {
  id: KeyboardShortcutId;
  category: KeyboardShortcutCategoryId;
  name: string;
  condition?: string;
  shortcutKeys: KeyboardShortcutKey[];
}

export interface KeyboardManagerInterface {
  init: () => void;
  destroy: () => void;
  handleKeyPress: (event: KeyboardEvent) => void;
  handleKeyReleased: (event: KeyboardEvent) => void;
  triggerShortcuts: (event: KeyboardEvent) => void;
  getShortcutCategories: () => KeyboardShortcutCategory[];
  getShortcutsByCategory: (
    category: KeyboardShortcutCategoryId,
  ) => KeyboardShortcut[];
  isEventThisKeyCode: (event: KeyboardEvent, keyCode: string) => boolean;
  shortcutToText: (shortcutId: KeyboardShortcutId) => string;
  keyToText: (keyCode: string) => string;
}
