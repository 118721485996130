import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  DialogTitle,
  DialogActions,
  Button,
  fade,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import SignalsTransferList from './SignalsTransferList';
import { SignalDefinition } from '../../../interfaces/sheet-definition';
import TabSyncService from '../../../services/tabSyncService';
import { SignalSheetTab } from '../../../interfaces/page-status';
import studyTools from '../../StudyOverview/studyTools';
import Analytics from '../../../services/analytics';
import sheetTools from '../../SignalSheet/sheetTools';
import EventService from '../../../services/eventService';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogTitle: {
    color: theme.colors.modal.color,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    height: '50%',
    color: theme.colors.modal.color,
    display: 'flex',
  },
  errorText: {
    textAlign: 'right',
    marginTop: theme.spacing(-1.5),
    padding: theme.spacing(2),
  },
  dialogButton: {
    color: theme.colors.text.link,
    '&:hover': {
      backgroundColor: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.05,
      ),
    },
  },
}));

interface CreateCustomSignalSheetProps {
  open: boolean;
  handleClose: () => void;
}

const CreateCustomSignalSheetModal = (
  props: CreateCustomSignalSheetProps,
): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const [selected, setSelected] = React.useState<SignalDefinition[]>([]);
  const [error, setError] = React.useState(false);

  const handleUpdate = (signals: SignalDefinition[]) => {
    setSelected(signals);
  };

  const handleError = (isError: boolean) => {
    setError(() => isError);
  };

  const handleCreate = () => {
    EventService.dispatch('Sheet.Switching');
    const customSheet = TabSyncService.addCustomSheet(selected);
    const partId = studyTools.getSelectedPart() ?? 1;

    const newTab: SignalSheetTab = {
      partId,
      sheetId: 'CustomSheet',
      animate: true,
      customId: customSheet.id,
    };

    studyTools.pageStatus.update((oldPageStatus) => {
      studyTools.addSignalSheetTab(newTab);
      return {
        ...oldPageStatus,
        signalSheetTabs: studyTools.getSignalSheetTabs(),
      };
    });

    Analytics.track.event('CUSTOM_SHEET_CREATE', {
      signalTypes: selected.map((s) => s.type),
    });
    history.push(
      [
        '/recording',
        '/',
        'custom-sheet',
        '?id=',
        sheetTools.getRecordingId(),
        '&part=',
        partId,
        '&scoringId=',
        sheetTools.getScoringId(),
        '&customId=',
        customSheet.id,
      ].join(''),
    );
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={clsx(classes.dialogTitle)}>
          Create Custom Signal Sheet
        </DialogTitle>
        <DialogContent data-cy="CreateCustomSheetDialog">
          <IconButton
            className={classes.closeButton}
            onClick={props.handleClose}
          >
            <CloseIcon />
          </IconButton>

          <DialogContentText className={clsx(classes.dialogContent)}>
            <SignalsTransferList
              handleUpdate={handleUpdate}
              handleError={handleError}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-cy="CreateCustomSheetBtn"
            color="primary"
            disabled={selected.length === 0 || error}
            onClick={handleCreate}
            className={classes.dialogButton}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateCustomSignalSheetModal;
