import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import XRange from 'highcharts/modules/xrange';
import _ from 'underscore';
import React from 'react';
import {
  useTheme,
  makeStyles,
  createStyles,
  Theme,
  fade,
} from '@material-ui/core';
import Logger from '../../../utils/logger';
import { RecordingPartData } from '../../StudyOverview/OverviewParts';
import { OverviewGraphDefinition } from './overviewChartDefinitions';
import ScoringService from '../../../services/scoringService';
import EventService from '../../../services/eventService';
import { SignalEventDetailData } from '../../../interfaces/signal-event-detail-props';
import eventChartTools from '../SignalChart/eventChartTools';
import { MarkerGroups } from '../SignalChart/markerConstants';
import chartTools from '../SignalChart/chartTools';
import studyTools from '../../StudyOverview/studyTools';
import PositionLabelingService from '../../../services/positionLabelingService';

XRange(Highcharts);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    yAxisLabels: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      right: '100%',
      marginRight: theme.spacing(0.5),
      textAlign: 'right',
      justifyContent: 'space-between',
    },
    label: {
      color: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.54,
      ),
      fontSize: 9,
    },
  }),
);

interface OverviewPositionChartProps {
  graphDefinition: OverviewGraphDefinition;
  partData: RecordingPartData;
  chartWidth?: number;
}

const OverviewPositionChart = (
  props: OverviewPositionChartProps,
): JSX.Element => {
  Logger.log(
    '[OverviewPositionChart] Created new instance for:',
    props.graphDefinition.type,
  );
  const classes = useStyles();
  const theme = useTheme();

  const redraw = _.throttle(() => {
    const chart = studyTools.retrieveChart(
      props.partData.partId,
      props.graphDefinition.type,
    );
    if (chart && chartTools.isSerieAvailable(chart)) {
      Logger.debug(
        '[OverviewPositionChart][redraw][%s] Redrawing',
        props.graphDefinition.type,
      );
      const time = Date.now();

      Logger.debug(
        '[OverviewPositionChart][redraw][%s] chartWidth',
        props.graphDefinition.type,
        chart.chartWidth,
      );
      if (chart.chartWidth > 1) {
        chart.redraw(false);
      } else {
        Logger.debug(
          '[OverviewPositionChart][redraw][%s] Skipping!',
          props.graphDefinition.type,
        );
      }

      Logger.debug(
        '[OverviewPositionChart][redraw][%s]  Redrawing (%d) points took (ms):',
        props.graphDefinition.type,
        chartTools.getDataFromChart(chart).length,
        Date.now() - time,
      );
    }
  }, 250);

  const processEvents = _.throttle(() => {
    Logger.log('[OverviewPositionChart] processSamples');
    const chart = studyTools.retrieveChart(
      props.partData.partId,
      props.graphDefinition.type,
    );
    if (chart) {
      const partStartTime = new Date(props.partData.startTime).valueOf();
      const partEndTime = new Date(props.partData.endTime).valueOf();

      const data: Highcharts.XrangePointOptionsObject[] = [];
      const events = ScoringService.retrieveMarkersByType(
        MarkerGroups.getEventTypes(['Position']),
      );
      Logger.log('[OverviewPositionChart] events', events);

      events.forEach((event) => {
        if (
          eventChartTools.isMarkerWithinTimePeriod(
            event,
            partStartTime,
            partEndTime,
          )
        ) {
          data.push({
            x: event.start,
            x2: event.end,
            y: 0,
            name: eventChartTools.getShortNameByEventType(event.type),
            color: eventChartTools.getColorByEventType(event.type),
          });
        }
      });
      Logger.log('[OverviewPositionChart] data', data);
      chartTools.setData(chart, data, { redraw: false });
      redraw();
    }
  }, 250);

  const handleEventChange = (marker: SignalEventDetailData) => {
    if (MarkerGroups.getEventTypes(['Position']).includes(marker.type)) {
      const partStartTime = new Date(props.partData.startTime).valueOf();
      const partEndTime = new Date(props.partData.endTime).valueOf();
      if (
        eventChartTools.isMarkerWithinTimePeriod(
          marker,
          partStartTime,
          partEndTime,
        )
      ) {
        processEvents();
      }
    }
  };

  React.useEffect(() => {
    const cbId = EventService.subscribe(
      'ScoringChanged',
      (marker: SignalEventDetailData) => {
        // Logger.debug('[OverviewPositionChart] ScoringChanged', marker);
        handleEventChange(marker);
      },
    );

    const chart = studyTools.retrieveChart(
      props.partData.partId,
      props.graphDefinition.type,
    );
    if (chart) {
      chart.setSize(props.chartWidth, undefined);
    }

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, [props.chartWidth]);

  const min = new Date(props.partData.startTime).valueOf();
  const max = new Date(props.partData.endTime).valueOf();

  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'xrange',
      width: 1,
      height: props.graphDefinition.height,
      marginLeft: 0,
      marginTop: 0,
      spacingTop: 0,
      spacingRight: 20,
      spacingBottom: 0,
      panning: {
        enabled: false,
      },
      spacingLeft: 0,
      plotBorderWidth: 0,
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      animation: false,
      events: {
        load() {
          Logger.log('[OverviewChart] load');
          studyTools.registerChart(
            props.partData.partId,
            props.graphDefinition.type,
            this,
          );
          processEvents();
        },
      },
    },
    loading: {
      style: {
        background: theme.colors.chart.loading.background,
        color: theme.colors.chart.loading.color,
      },
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      visible: false,
      crosshair: false,
      gridLineWidth: 0,
      lineWidth: 0,
      showFirstLabel: true,
      showLastLabel: true,
      ordinal: false,
      min,
      max,
    },
    yAxis: {
      startOnTick: true,
      endOnTick: true,
      tickPixelInterval: 100,
      alignTicks: false,
      lineWidth: 0,
      gridLineWidth: 0,
      showFirstLabel: true,
      showLastLabel: true,
      opposite: false,
      labels: {
        enabled: false,
      },
      categories: ['Position'],
    },
    series: [
      {
        name: 'Position',
        type: 'xrange',
        borderColor: 'gray',
        borderRadius: 1,
        pointWidth: 5,
        data: [],
        minPointLength: 1,
        dataLabels: {
          enabled: true,
          useHTML: true,

          style: {
            textOutline: 'none',
            fontSize: '9px',
            color: fade(
              theme.palette.getContrastText(theme.palette.background.paper),
              0.54,
            ),
            fontWeight: '400',
          },
          y: 8,
          formatter: PositionLabelingService.labelFormatter,
        },
        turboThreshold: 0,
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
  };

  return (
    <div
      data-cy={`OverviewGraph-${props.graphDefinition.type}`}
      className={classes.container}
    >
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="stockChart"
        allowChartUpdate={false}
        options={chartOptions}
      />
    </div>
  );
};

export default OverviewPositionChart;
