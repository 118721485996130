import React from 'react';
import moment from 'moment';
import _ from 'underscore';
import { ScoringInsightsUpdatedResult } from '../../queries/subscriptions/scoringInsightsUpdated';
import EventService from '../../services/eventService';
import Logger from '../../utils/logger';

import FlexContainer from '../Shared/FlexContainer';
import SleepParameterDefinitions from '../SignalSheet/definitions/SleepParameterDefinitions';
import { RecordingPartData } from './OverviewParts';
import OverviewPartStat from './OverviewPartStat';
import studyTools from './studyTools';
import { GraphDataEvent } from '../Chart/OverviewChart/overviewChartDefinitions';
import AnalysisPeriodsService from '../../services/analysisPeriodsService';
import ScoringService from '../../services/scoringService';
import eventChartTools from '../Chart/SignalChart/eventChartTools';
import { OverviewPartMenuPos } from './OverviewPartDetailSecondaryMenu';
import SleepStageService from '../../services/sleepStageService';
import chartRangeTools from '../SignalSheet/chartRangeTools';
import { SleepParameterData } from '../../interfaces/sleep-parameter';

const OverviewPartStats = (props: {
  data: RecordingPartData;
  handleMenuOpen: (pos: OverviewPartMenuPos) => void;
}): JSX.Element => {
  const [ahi, setAHI] = React.useState<SleepParameterData>({
    value: props.data.stats.AHI,
    updatedOn: Date.now(),
  });
  const [odi, setODI] = React.useState<SleepParameterData>({
    value: props.data.stats.ODI,
    updatedOn: Date.now(),
  });
  const [sleepEfficiency, setSleepEfficiency] =
    React.useState<SleepParameterData>({
      value: props.data.stats.sleepEfficiency,
      updatedOn: Date.now(),
    });
  const [totalSleptTime, setTotalSleptTime] =
    React.useState<SleepParameterData>({
      value: props.data.stats.totalSleptTime,
      updatedOn: Date.now(),
    });

  const [minPulse, setMinPulse] = React.useState<SleepParameterData>();
  const [maxPulse, setMaxPulse] = React.useState<SleepParameterData>();
  // const [averagePulse, setAveragePulse] = React.useState<SleepParameterData>();

  const [minSpO2, setMinSpO2] = React.useState<SleepParameterData>();
  // const [averageSpO2, setAverageSpO2] = React.useState<SleepParameterData>();

  const calculatePulseStats = _.throttle(
    (analysisStart: number, analysisEnd: number) => {
      Logger.log('[OverviewPartStats][Graph] Init');
      Logger.log(
        '[OverviewPartStats][Graph] props.data.partId',
        props.data.partId,
      );
      let newMinPulse: number | undefined;
      let newMinPulseTimestamp: number | undefined;
      let newMaxPulse: number | undefined;
      let newMaxPulseTimestamp: number | undefined;
      let newAveragePulse: number | undefined;
      let validPulseSamples = 0;
      const graphSamples = studyTools.getGraphData(
        props.data.partId,
        'Pulse.Averaged-Probe',
      );
      const excludingEvents = ScoringService.retrieveMarkersByType([
        'signal-artifact',
        'position-upright',
        'signal-invalid',
      ]).filter(
        (event) =>
          (event.type === 'position-upright' ||
            event.type === 'signal-invalid' ||
            event.scoredFrom === 'Pulse.Averaged-Probe') &&
          eventChartTools.isMarkerWithinTimePeriod(
            event,
            analysisStart,
            analysisEnd,
          ),
      );
      // Logger.log('[OverviewPartStats][Graph] graphSamples', graphSamples);

      Logger.log('[OverviewPartStats][Graph] analysisStart', analysisStart);
      Logger.log('[OverviewPartStats][Graph] analysisEnd', analysisEnd);
      graphSamples.forEach((sample) => {
        if (sample[0] >= analysisStart && sample[0] <= analysisEnd) {
          const sleepStage = SleepStageService.getStageForEpoch(
            chartRangeTools.convertToEpoch(sample[0]),
          );
          const isSleeping =
            SleepStageService.getTotalSleepStages() === 0 ||
            (sleepStage && sleepStage.type !== 'sleep-wake');

          const withinArtifact = excludingEvents.some(
            (marker) => sample[0] >= marker.start && sample[0] <= marker.end,
          );

          if (
            sample[1] < 300 &&
            sample[1] > 0 &&
            !withinArtifact &&
            isSleeping
          ) {
            if (newMinPulse === undefined || newMinPulse > sample[1]) {
              [newMinPulseTimestamp, newMinPulse] = sample;
            }

            if (newMaxPulse === undefined || newMaxPulse < sample[1]) {
              [newMaxPulseTimestamp, newMaxPulse] = sample;
            }

            newAveragePulse = newAveragePulse
              ? newAveragePulse + sample[1]
              : sample[1];
            validPulseSamples += 1;
          }
        }
      });
      if (newAveragePulse) newAveragePulse /= validPulseSamples;
      Logger.log('[OverviewPartStats][Graph] newMinPulse', newMinPulse);
      Logger.log('[OverviewPartStats][Graph] newMaxPulse', newMinPulse);
      setMinPulse({
        value: newMinPulse,
        timestamp: newMinPulseTimestamp,
        updatedOn: Date.now(),
      });
      setMaxPulse({
        value: newMaxPulse,
        timestamp: newMaxPulseTimestamp,
        updatedOn: Date.now(),
      });
      // setAveragePulse({ value: newAveragePulse, updatedOn: Date.now() });
    },
    250,
    { leading: false },
  );

  const calculateOxygenStats = _.throttle(
    (analysisStart: number, analysisEnd: number) => {
      Logger.log('[OverviewPartStats][Graph] Init');
      Logger.log(
        '[OverviewPartStats][Graph] props.data.partId',
        props.data.partId,
      );
      let newMinSpO2: number | undefined;
      let newMinSpO2Timestamp: number | undefined;
      let newAverageSpO2: number | undefined;
      let validSpO2Samples = 0;
      const graphSamples = studyTools.getGraphData(
        props.data.partId,
        'SpO2.Averaged-Probe',
      );
      const excludingEvents = ScoringService.retrieveMarkersByType([
        'signal-artifact',
        'position-upright',
        'signal-invalid',
      ]).filter(
        (event) =>
          (event.type === 'position-upright' ||
            event.type === 'signal-invalid' ||
            event.scoredFrom === 'SpO2.Averaged-Probe') &&
          eventChartTools.isMarkerWithinTimePeriod(
            event,
            analysisStart,
            analysisEnd,
          ),
      );
      // Logger.log('[OverviewPartStats][Graph] graphSamples', graphSamples);

      Logger.log('[OverviewPartStats][Graph] analysisStart', analysisStart);
      Logger.log('[OverviewPartStats][Graph] analysisEnd', analysisEnd);
      graphSamples.forEach((sample) => {
        if (sample[0] >= analysisStart && sample[0] <= analysisEnd) {
          const sleepStage = SleepStageService.getStageForEpoch(
            chartRangeTools.convertToEpoch(sample[0]),
          );
          const isSleeping =
            SleepStageService.getTotalSleepStages() === 0 ||
            (sleepStage && sleepStage.type !== 'sleep-wake');
          const withinArtifact = excludingEvents.some(
            (marker) => sample[0] >= marker.start && sample[0] <= marker.end,
          );

          if (
            sample[1] < 120 &&
            sample[1] > 0 &&
            !withinArtifact &&
            isSleeping
          ) {
            if (newMinSpO2 === undefined || newMinSpO2 > sample[1]) {
              [newMinSpO2Timestamp, newMinSpO2] = sample;
            }

            newAverageSpO2 = newAverageSpO2
              ? newAverageSpO2 + sample[1]
              : sample[1];
            validSpO2Samples += 1;
          }
        }
      });
      if (newAverageSpO2) newAverageSpO2 /= validSpO2Samples;
      Logger.log('[OverviewPartStats][Graph] newMinSpO2', newMinSpO2);
      Logger.log('[OverviewPartStats][Graph] newAverageSpO2', newAverageSpO2);
      setMinSpO2({
        value: newMinSpO2,
        timestamp: newMinSpO2Timestamp,
        updatedOn: Date.now(),
      });
      // setAverageSpO2({ value: newAverageSpO2, updatedOn: Date.now() });
    },
    250,
    { leading: false },
  );

  const processSignalParameters = _.debounce(() => {
    Logger.log('[OverviewPartStats][Graph] processSignalParameters');

    const analysisPeriods = AnalysisPeriodsService.retrievePeriodEvents();
    const partPeriods = analysisPeriods.filter(
      (period) =>
        period.start >= new Date(props.data.startTime).valueOf() &&
        period.start <= new Date(props.data.endTime).valueOf(),
    );

    const firstPeriod = _.first(partPeriods);
    const lastPeriod = _.last(partPeriods);
    if (firstPeriod && lastPeriod) {
      calculatePulseStats(firstPeriod.start, lastPeriod.end);
      calculateOxygenStats(firstPeriod.start, lastPeriod.end);
    }
  }, 250);

  React.useEffect(() => {
    const cbId = [
      EventService.subscribe(
        'ScoringInsightsUpdated',
        (data: ScoringInsightsUpdatedResult) => {
          const scoringInsights = data.scoringInsightsUpdated;
          scoringInsights.parts?.forEach((part) => {
            if (
              part.beginning === props.data.startTime &&
              part.end === props.data.endTime
            ) {
              const isSingleAnalysisPeriod = part.analysisPeriods?.length === 1;
              const statsPeriod = isSingleAnalysisPeriod
                ? part.analysisPeriods?.[0]
                : undefined;
              if (statsPeriod) {
                // Logger.log('[OverviewPartStats] statsPeriod', statsPeriod);
                Logger.log(
                  '[OverviewPartStats] statsPeriod.kpis',
                  statsPeriod.kpis,
                );
                /* Logger.log(
                '[OverviewPartStats] statsPeriod.sleepDurations',
                statsPeriod.sleepDurations
              ); */
                if (
                  ahi !== statsPeriod.kpis.AHI &&
                  statsPeriod.kpis.AHI !== null
                ) {
                  setAHI({
                    value: statsPeriod.kpis.AHI,
                    updatedOn: Date.now(),
                  });
                }

                if (
                  odi !== statsPeriod.kpis.ODI &&
                  statsPeriod.kpis.ODI !== null
                ) {
                  setODI({
                    value: statsPeriod.kpis.ODI,
                    updatedOn: Date.now(),
                  });
                }

                if (
                  sleepEfficiency !== statsPeriod.kpis.sleepEfficiency &&
                  statsPeriod.kpis.sleepEfficiency !== null
                ) {
                  setSleepEfficiency({
                    value: statsPeriod.kpis.sleepEfficiency,
                    updatedOn: Date.now(),
                  });
                }

                if (statsPeriod.sleepDurations) {
                  const totalSleep =
                    statsPeriod.sleepDurations.totalSecondsInNREM +
                    statsPeriod.sleepDurations.totalSecondsInREM;
                  if (totalSleptTime !== totalSleep && totalSleep !== null) {
                    setTotalSleptTime({
                      value: totalSleep,
                      updatedOn: Date.now(),
                    });
                  }
                }

                const analysisStart = new Date(statsPeriod.beginning).valueOf();
                const analysisEnd = new Date(statsPeriod.end).valueOf();
                calculatePulseStats(analysisStart, analysisEnd);
                calculateOxygenStats(analysisStart, analysisEnd);
              }
            }
          });
        },
      ),
      EventService.subscribe(
        'Overview.GraphDataReceived',
        (info: GraphDataEvent) => {
          if (info.partId === props.data.partId) {
            Logger.debug(
              '[OverviewChart][GraphDataReceived] processSamples',
              info,
            );
            processSignalParameters();
          }
        },
      ),
    ];

    processSignalParameters();

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, []);

  return (
    <FlexContainer data-cy={`Stats-Part${props.data.partId}`}>
      <OverviewPartStat
        data={{ value: new Date(props.data.startTime).valueOf() }}
        paramDefinition={SleepParameterDefinitions.get('PartStartDate')}
      />
      <OverviewPartStat
        data={{ value: moment(props.data.endTime).diff(props.data.startTime) }}
        paramDefinition={SleepParameterDefinitions.get('TotalHours')}
      />
      <OverviewPartStat
        data={totalSleptTime}
        paramDefinition={SleepParameterDefinitions.get('TotalTimeInSleep')}
        recordingPart={props.data}
      />
      <OverviewPartStat
        data={sleepEfficiency}
        paramDefinition={SleepParameterDefinitions.get('SleepEfficiency')}
        recordingPart={props.data}
      />
      <OverviewPartStat
        data={ahi}
        paramDefinition={SleepParameterDefinitions.get('AHI')}
        recordingPart={props.data}
      />
      <OverviewPartStat
        data={odi}
        paramDefinition={SleepParameterDefinitions.get('ODI')}
        recordingPart={props.data}
      />
      <OverviewPartStat
        data={minPulse}
        paramDefinition={SleepParameterDefinitions.get('Pulse-Min')}
        handleMenuOpen={props.handleMenuOpen}
        recordingPart={props.data}
      />
      <OverviewPartStat
        data={maxPulse}
        paramDefinition={SleepParameterDefinitions.get('Pulse-Max')}
        handleMenuOpen={props.handleMenuOpen}
        recordingPart={props.data}
      />
      {/* <OverviewPartStat
        data={averagePulse}
        paramDefinition={SleepParameterDefinitions.get('Pulse-Average')}
        recordingPart={props.data}
      /> */}
      <OverviewPartStat
        data={minSpO2}
        paramDefinition={SleepParameterDefinitions.get('SpO2-Min')}
        handleMenuOpen={props.handleMenuOpen}
        recordingPart={props.data}
      />
      {/* <OverviewPartStat
        data={averageSpO2}
        paramDefinition={SleepParameterDefinitions.get('SpO2-Average')}
        recordingPart={props.data}
      /> */}
    </FlexContainer>
  );
};

export default OverviewPartStats;
