import _ from 'underscore';
import { DraggableData } from 'react-draggable';
import { EventChartTools } from './interfaces/event-chart-tools';
import Logger from '../../../utils/logger';
import { ScoringMarkerDiff } from '../../../queries/subscriptions/scoringChanged';
import chartTools from './chartTools';
import {
  SignalDefinition,
  SignalType,
} from '../../../interfaces/sheet-definition';
import ScoringService from '../../../services/scoringService';
import { MarkerDefinitions, IgnoredMarkers } from './markerConstants';
import {
  SignalEventDetailData,
  MarkerChangeId,
} from '../../../interfaces/signal-event-detail-props';
import chartRangeTools from '../../SignalSheet/chartRangeTools';
import { MarkerType } from '../../../interfaces/markers';
import UserAttributesService from '../../../services/userAttributesService';
import ScoringRenderingService from '../../../services/scoringRenderingService';
import { ScoringCanvasEvent } from '../ScoringCanvas/ScoringCanvas';
import signalEventDetailService from '../../SignalEventDetail/signalEventDetailService';
import { MoveModes, ResizeModes } from '../ScoringCanvas/ScoringEvent';
import EventService from '../../../services/eventService';
import Analytics from '../../../services/analytics';
import sheetTools from '../../SignalSheet/sheetTools';

let eventIntensity: number[] = [];
let hasChangedEventIntensity = true;

const defaultMarkerTypeBySignal = new Map<SignalType, MarkerType>();
const defaultDurationBySignal = new Map<SignalType, number>();

const eventChartTools: EventChartTools = {
  setDefaultMarkerTypeBySignalType: (
    signalType: SignalType,
    markerType: MarkerType,
  ) => defaultMarkerTypeBySignal.set(signalType, markerType),
  getDefaultMarkerTypeBySignalType: (signalType: SignalType) => {
    Logger.log('[getDefaultNameBySignalType] signalType', signalType);
    const defaultMarkerType = defaultMarkerTypeBySignal.get(signalType);

    if (!defaultMarkerType) {
      const signalInfo = chartTools.getSignalInfo(signalType);
      Logger.log('[getDefaultNameBySignalType] signalInfo', signalInfo);
      if (
        signalInfo?.markerSubscription &&
        signalInfo.markerSubscription.length > -1
      ) {
        return signalInfo.markerSubscription[0].markerTypes[0];
      }
      return 'web-custom';
    }
    return defaultMarkerType;
  },
  getDefaultMarkerDurationBySignalType: (signalType: SignalType) => {
    const defaultDuration = defaultDurationBySignal.get(signalType) || 20000;
    return defaultDuration;
  },
  setDefaultMarkerDurationBySignalType: (
    signalType: SignalType,
    duration: number,
  ) => defaultDurationBySignal.set(signalType, duration),
  removeTimestampPrecision: (timestamp: number) =>
    parseInt(timestamp.toFixed(0), 10),
  getNextType: (signalType: SignalType, eventType: MarkerType) => {
    Logger.debug(
      '[getNextNameBySignalName] signalType: %s. eventName: %s',
      signalType,
      eventType,
    );
    let type: MarkerType = 'web-custom';
    const signalInfo = chartTools.getSignalInfo(signalType);
    if (
      signalInfo?.markerSubscription &&
      signalInfo.markerSubscription.length > -1
    ) {
      const eventsForType = signalInfo.markerSubscription
        .map((sub) => sub.markerTypes)
        .flat();
      const index = eventsForType.indexOf(eventType);
      Logger.debug('[getNextNameBySignalName] index:', index);
      Logger.debug('[getNextNameBySignalName] eventsForType:', eventsForType);
      if (index > -1) {
        if (index < eventsForType.length - 1) {
          type = eventsForType[index + 1];
        } else {
          [type] = eventsForType;
        }
      }
    }
    return type;
  },
  switchEventType: (
    marker: ScoringCanvasEvent,
    origin: 'Popup' | 'Shortcut',
    toEventType?: MarkerType,
  ) => {
    Logger.log('[switchEventType] init', marker);

    const eventData: ScoringCanvasEvent = { ...marker };
    if (marker.signalInfo) {
      const fromEventType = marker.type;
      const newEventType =
        toEventType ||
        eventChartTools.getNextType(marker.signalInfo?.type, marker.type);

      if (newEventType !== eventData.type) {
        eventData.type = newEventType;
        eventData.name = eventChartTools.getNameByEventType(eventData.type);

        Analytics.track.event('SCORING_TYPE_SWITCH', {
          origin,
          recordingId: sheetTools.getRecordingId(),
          markerId: marker.id,
          fromType: fromEventType,
          toEventType: newEventType,
        });
        ScoringService.updateEvent(eventData, {
          send: true,
          local: true,
          operation: 'SwitchType',
        });
        eventData.extras.hasDrop = eventChartTools.shouldHaveDrop(
          eventData.start,
          eventData.end,
          eventData.type,
          eventData.scoredFrom as SignalType,
        );
      } else {
        Logger.log(
          '[switchEventType] Skipping because the event type is the same:',
          newEventType,
        );
      }
    }

    return eventData;
  },
  getColorByEventType: (eventType: MarkerType) =>
    MarkerDefinitions.get(eventType)?.color || 'rgba(0,150,136,0.7)',
  getMarkerDefinition: (eventType: MarkerType) =>
    MarkerDefinitions.get(eventType),
  getOnChangeFn: (eventType: MarkerType) =>
    eventChartTools.getMarkerDefinition(eventType)?.onChange,
  getNameByEventType: (eventType: MarkerType) =>
    MarkerDefinitions.get(eventType)?.name || eventType,
  getShortNameByEventType: (eventType: MarkerType) =>
    MarkerDefinitions.get(eventType)?.shortName ||
    MarkerDefinitions.get(eventType)?.name ||
    eventType,
  getEventSeverityIndex: (eventType: MarkerType) =>
    MarkerDefinitions.get(eventType)?.severity || 0,
  generateId: () =>
    `manual-${UserAttributesService.getUserId()}-${Date.now().toString()}${-+_.uniqueId()}`,
  markerToSignalEventDetailData: (marker: ScoringMarkerDiff) => {
    const start = new Date(marker.startTime as string).valueOf();

    return {
      id: marker.markerId,
      name: eventChartTools.getNameByEventType(marker.eventType as MarkerType),
      type: marker.eventType as MarkerType,
      start,
      end: marker.endTime ? new Date(marker.endTime).valueOf() : start,
      isAutomatic: marker.isAutomatic || false,
      signalInfo: chartTools.getSignalInfo(marker.signal as SignalType),
      scoredFrom: (marker.signal as string) || undefined,
      modifiedDate: Date.now(),
      deleted: marker.deleted,
      history: [],
    };
  },
  createSignalEventDetailData: (
    type: MarkerType,
    start: number,
    end: number,
    signalType?: SignalType,
  ) => ({
    id: eventChartTools.generateId(),
    name: eventChartTools.getNameByEventType(type),
    type,
    start: eventChartTools.removeTimestampPrecision(start),
    end: eventChartTools.removeTimestampPrecision(end),
    isAutomatic: false,
    signalInfo: signalType ? chartTools.getSignalInfo(signalType) : undefined,
    scoredFrom: signalType,
    creationDate: Date.now(),
    modifiedDate: Date.now(),
    history: [],
  }),
  toMarkerChangeId: (marker: SignalEventDetailData) =>
    [
      marker.id,
      marker.start,
      marker.end,
      marker.type,
      !!marker.deleted,
      marker.scoredFrom || '',
    ].join('|'),
  toScoringMarker: (marker: SignalEventDetailData) => ({
    markerId: marker.id,
    startTime: new Date(marker.start).toISOString(),
    endTime: new Date(marker.end).toISOString(),
    eventType: marker.type,
    signal: marker.scoredFrom,
  }),
  markerHasChanged: (
    oldMarker: SignalEventDetailData,
    newMarker: SignalEventDetailData,
  ) =>
    oldMarker.name !== newMarker.name ||
    oldMarker.start !== newMarker.start ||
    oldMarker.end !== newMarker.end ||
    oldMarker.type !== newMarker.type ||
    oldMarker.scoredFrom !== newMarker.scoredFrom ||
    oldMarker.deleted !== newMarker.deleted,

  /**
   * isExpectedMarkerChangeId
   * Only checks the first item in the History array.
   * If we receive something else:
   *  * We got changes from another user
   *  * Ordering of events is unreliable
   * In any case, we should clear the History.
   */
  isExpectedMarkerChangeId: (
    marker: SignalEventDetailData,
    markerChangeId: MarkerChangeId,
  ) => {
    const isExpected =
      !!marker.history[0] && marker.history[0] === markerChangeId;

    // Logger.debug('[isExpectedMarkerChangeId] -----');
    // Logger.debug('[isExpectedMarkerChangeId] marker.history[0]:', marker.history[0]);
    // Logger.debug('[isExpectedMarkerChangeId]    markerChangeId:', markerChangeId);
    // Logger.debug('[isExpectedMarkerChangeId]        isExpected:', isExpected);
    // Logger.debug('[isExpectedMarkerChangeId] marker.history', marker.history);
    // Logger.debug('[isExpectedMarkerChangeId] -----');

    return isExpected;
  },
  processMarkerChange: (markerChange: ScoringMarkerDiff) => {
    const existingEventWithSameId = ScoringService.findEventById(
      markerChange.markerId,
    );
    const markerType = markerChange.eventType
      ? (markerChange.eventType as MarkerType)
      : existingEventWithSameId?.type;

    if (!!markerType && IgnoredMarkers.includes(markerType)) {
      Logger.debug(
        '[processMarkerChange] Ignoring %s marker.',
        markerChange.eventType,
        markerChange,
      );
    } else {
      // Logger.debug('[processMarkerChange] Received marker change:', markerChange);

      const eventDetails =
        eventChartTools.markerToSignalEventDetailData(markerChange);

      if (!existingEventWithSameId && !markerChange.deleted) {
        ScoringService.storeEvent(eventDetails, { send: false });
      } else if (existingEventWithSameId) {
        const markerChangeId = eventChartTools.toMarkerChangeId(eventDetails);
        if (
          eventChartTools.isExpectedMarkerChangeId(
            existingEventWithSameId,
            markerChangeId,
          )
        ) {
          Logger.log('[processMarkerChange] Skipping. Marker change was ours.');
          ScoringService.removeOlderMarkerChangeId(markerChange.markerId);
        } else {
          if (existingEventWithSameId.history.length > 0) {
            Logger.warn(
              '[processMarkerChange] Got unexpected markerChangeId (%s) for marker:',
              markerChangeId,
              existingEventWithSameId,
            );
          }
          ScoringService.clearMarkerHistory(markerChange.markerId);

          if (markerChange.deleted) {
            ScoringService.removeEvent(markerChange.markerId, { send: false });
          } else if (
            eventChartTools.markerHasChanged(
              existingEventWithSameId,
              eventDetails,
            )
          ) {
            ScoringService.updateEvent(eventDetails, {
              send: false,
              operation: 'ServerUpdate',
            });
          } else {
            Logger.log(
              '[processMarkerChange] Skipping. Marker has not changed',
            );
          }
        }
      }
    }
  },
  isSleepStageMarker: (marker: SignalEventDetailData) => {
    return MarkerDefinitions.get(marker.type)?.markerGroup === 'Sleep Stage';
  },
  isPositionMarker: (marker: SignalEventDetailData) =>
    MarkerDefinitions.get(marker.type)?.markerGroup === 'Position',
  isMarkerWithinCurrentExtremes: (marker: SignalEventDetailData) => {
    const firstEpoch = chartRangeTools.convertToEpoch(marker.start);
    const lastEpoch = chartRangeTools.convertToEpoch(marker.end - 1); // [start, end)

    return chartRangeTools.isEpochRangeWithinCurrentExtremes({
      first: firstEpoch,
      last: lastEpoch,
    });
  },
  isMarkerWithinTimePeriod: (
    marker: SignalEventDetailData,
    periodMin: number,
    periodMax: number,
  ) =>
    (marker.start >= periodMin && marker.start <= periodMax) ||
    (marker.end >= periodMin && marker.end <= periodMax) ||
    (marker.start <= periodMin && marker.end >= periodMax),

  areMarkersOverlapping: (
    a: SignalEventDetailData,
    b: SignalEventDetailData,
  ) => {
    const areOverlapping = a.start <= b.end && b.start <= a.end;
    return areOverlapping;
  },
  initializeEventIntensity: () => {
    Logger.log(
      '[initializeEventIntensity] Generating Event Intensity Chart data',
    );
    const time = Date.now();

    const finalEpoch = chartRangeTools.getLastEpoch();
    const startTime = chartRangeTools.getRecordingStartTime();
    const endTime = chartRangeTools.getRecordingEndTime();

    eventIntensity = [];
    hasChangedEventIntensity = true;

    eventIntensity[startTime] = 1;
    for (let i = 0; i <= finalEpoch; i++) {
      const timestamp = chartRangeTools.convertToTimestamp(i);
      eventIntensity[timestamp] = 1;
      if (i !== finalEpoch) {
        eventIntensity[timestamp + 15000] = 1;
      }
    }
    eventIntensity[endTime] = 1;

    EventService.dispatch('EventIntensityChanged');

    Logger.log(
      '[initializeEventIntensity] Done! %d timestamps. Took (ms) :',
      eventIntensity.length,
      Date.now() - time,
    );
  },
  incrementEventIntensity: (epoch: number, eventType: MarkerType) => {
    if (epoch >= 0) {
      const time = chartRangeTools.convertToTimestamp(epoch);
      const severityIndex = eventChartTools.getEventSeverityIndex(eventType);

      if (!eventIntensity[time]) eventIntensity[time] = 1;
      eventIntensity[time] += severityIndex;

      if (!eventIntensity[time + 15000]) eventIntensity[time + 15000] = 1;
      eventIntensity[time + 15000] += severityIndex;

      if (severityIndex !== 0) {
        hasChangedEventIntensity = true;
        EventService.dispatch('EventIntensityChanged');
      }
    }
  },
  decrementEventIntensity: (epoch: number, eventType: MarkerType) => {
    if (epoch >= 0) {
      const time = chartRangeTools.convertToTimestamp(epoch);
      const severityIndex = eventChartTools.getEventSeverityIndex(eventType);

      eventIntensity[time] -= severityIndex;
      if (eventIntensity[time] < 1) eventIntensity[time] = 1;

      eventIntensity[time + 15000] -= severityIndex;
      if (eventIntensity[time + 15000] < 1) eventIntensity[time + 15000] = 1;

      if (severityIndex !== 0) {
        hasChangedEventIntensity = true;
        EventService.dispatch('EventIntensityChanged');
      }
    }
  },
  hasChangedEventIntensity: () => hasChangedEventIntensity,
  generateEventIntensityChartData: () => {
    Logger.log(
      '[generateEventIntensityChartData] Generating Event Intensity Chart data',
    );
    Logger.debug(
      '[generateEventIntensityChartData] eventIntensity:',
      eventIntensity,
    );
    const time = Date.now();
    const data = Object.keys(eventIntensity)
      .sort()
      .map((timestamp) => [
        parseInt(timestamp, 10),
        eventIntensity[parseInt(timestamp, 10)],
      ]);
    Logger.log(
      '[generateEventIntensityChartData] Generated %d timestamps. Took (ms):',
      data.length,
      Date.now() - time,
    );
    hasChangedEventIntensity = false;

    return data;
  },
  getEventIntensityDataForCurrentPart: () => {
    const eventIntensityForPart: number[] = [];

    Object.keys(eventIntensity).forEach((timestamp) => {
      const currentTime = parseInt(timestamp, 10);

      if (chartRangeTools.isTimestampWithinPart(currentTime)) {
        eventIntensityForPart[currentTime] = eventIntensity[currentTime];
      }
    });

    return eventIntensityForPart;
  },
  getEventIntensityMaxEpochForCurrentPart: () => {
    Logger.log('[getEventIntensityMaxEpoch] init');
    const eventIntensityForCurrentPart =
      eventChartTools.getEventIntensityDataForCurrentPart();
    Logger.log(
      '[getEventIntensityMaxEpoch] eventIntensity',
      eventIntensityForCurrentPart,
    );
    let maxEpochs: number[] = [];

    const intensityCount =
      chartTools.areasOfInterest.getEventsCountByIntensity();
    Logger.log('[getEventIntensityMaxEpoch] intensityCount', intensityCount);

    const interestThreshold =
      chartTools.areasOfInterest.findInterestThresholdIndex(intensityCount, 5);
    Logger.log(
      '[getEventIntensityMaxEpoch] interestThreshold',
      interestThreshold,
    );

    if (interestThreshold > 5) {
      maxEpochs = chartTools.areasOfInterest.getEpochsByInterestThreshold(
        eventIntensityForCurrentPart,
        interestThreshold,
        5,
        { fillUpToLimit: false },
      );
    }

    Logger.log('[getEventIntensityMaxEpoch] maxEpochs', maxEpochs);
    return maxEpochs;
  },
  onMarkerDragStart: (marker: ScoringCanvasEvent) => {
    ScoringRenderingService.setEventBeingDraggedId(marker.id);
  },
  onMarkerDragStop: (
    marker: ScoringCanvasEvent,
    position: DraggableData,
    anchorEl?: React.RefObject<HTMLButtonElement>,
  ) => {
    Logger.log('[onEventDragStop] event:', marker);
    Logger.log('[onEventDragStop] position:', position);

    const timeDiff = eventChartTools.getMoveTimeDiff(marker, position.x);
    Logger.log('[onEventDragStop] timeDiff:', timeDiff);
    Logger.warn(
      '[onEventDragStop] dragDuration:',
      ScoringRenderingService.getEventBeingDraggedDuration(),
    );

    if (
      timeDiff !== 0 &&
      ScoringRenderingService.getEventBeingDraggedDuration() > 100
    ) {
      const updatedEvent: SignalEventDetailData = {
        ...marker,
        start: marker.start + timeDiff,
        end: marker.end + timeDiff,
      };
      Logger.log('[onEventDragStop] updatedEvent:', updatedEvent);

      ScoringService.updateEvent(updatedEvent, {
        send: true,
        local: true,
        operation: 'Move',
        diff: timeDiff,
      });
    } else {
      Logger.warn('[onEventDragStop] It was a click');
      // eslint-disable-next-line no-param-reassign
      position.node.style.transform = 'translate(0px, 0px)';

      if (anchorEl && anchorEl.current) {
        eventChartTools.onClick(marker, anchorEl.current);
      }
    }

    ScoringRenderingService.cancelEventBeingDragged();
  },
  onClick: (marker: ScoringCanvasEvent, anchorEl: HTMLButtonElement) => {
    Logger.log('[onClick] event:', marker);
    signalEventDetailService.setData(marker);
    const boundingRect = anchorEl.getBoundingClientRect();
    Logger.debug('[onClick] boundingRect:', boundingRect);

    signalEventDetailService.popup.setAnchorEl({
      clientWidth: boundingRect.width,
      clientHeight: boundingRect.height,
      getBoundingClientRect: () => boundingRect,
    });
    signalEventDetailService.popup.open();
  },
  onMarkerResize: (
    marker: ScoringCanvasEvent,
    position: DraggableData,
    mode: ResizeModes,
    anchorEl?: React.RefObject<HTMLButtonElement>,
  ) => {
    Logger.log('[onMarkerResize] event:', marker);
    Logger.log('[onMarkerResize] position:', position);

    const timeDiff = eventChartTools.getMoveTimeDiff(marker, position.x);
    Logger.log('[onMarkerResize] timeDiff:', timeDiff);
    Logger.warn(
      '[onMarkerResize] dragDuration:',
      ScoringRenderingService.getEventBeingResizedDuration(),
    );

    if (
      timeDiff !== 0 &&
      ScoringRenderingService.getEventBeingResizedDuration() > 100
    ) {
      const updatedEvent: SignalEventDetailData = {
        ...marker,
        start: mode === 'left' ? marker.start + timeDiff : marker.start,
        end: mode === 'right' ? marker.end + timeDiff : marker.end,
      };
      Logger.log('[onMarkerResize] updatedEvent:', updatedEvent);

      ScoringService.updateEvent(updatedEvent, {
        send: true,
        local: true,
        operation: 'Move',
        diff: timeDiff,
      });
    } else if (ScoringRenderingService.wasResizeCancelled()) {
      ScoringRenderingService.setResizeWasCancelled(false);
    } else {
      Logger.warn('[onMarkerResize] It was a click');
      if (anchorEl && anchorEl.current) {
        eventChartTools.onClick(marker, anchorEl.current);
      }
    }

    ScoringRenderingService.cancelEventBeingResized();
  },
  getEpochRange: (marker: SignalEventDetailData) => {
    const startEpoch = chartRangeTools.convertToEpoch(marker.start);
    const text: string[] = [`#${startEpoch + 1}`];

    const endEpoch = chartRangeTools.convertToEpoch(marker.end);

    if (endEpoch !== startEpoch) {
      text.push('-');
      text.push(`#${endEpoch + 1}`);
    }

    return text.join(' ');
  },
  calculateDrop: (start: number, end: number, signalInfo: SignalDefinition) => {
    // Logger.debug('[calculateDrop] init');
    // Logger.debug('[calculateDrop] start', start);
    // Logger.debug('[calculateDrop] end', end);
    // Logger.debug('[calculateDrop] signalInfo', signalInfo);
    let drop = 0;

    const valuesBetween = chartTools.getData(signalInfo, {
      first: chartRangeTools.convertToEpoch(start),
      last: chartRangeTools.convertToEpoch(end) + 1,
    });

    if (valuesBetween.length) {
      let firstValue: number | undefined;
      let lastValue: number | undefined;

      valuesBetween.forEach(([timestamp, value]) => {
        if (timestamp <= start) firstValue = value;
        if (end >= timestamp) lastValue = value;
      });

      if (firstValue && lastValue) {
        const diff = lastValue - firstValue;
        if (diff < 0) {
          drop = diff * -1;
        }
      }

      // Logger.debug('[calculateDrop] firstValue', firstValue);
      // Logger.debug('[calculateDrop] lastValue ', lastValue);
      // Logger.debug('[calculateDrop] drop      ', drop);
      // Logger.debug('[calculateDrop] valuesBetween', valuesBetween);
    }

    return drop;
  },
  calculatePeakToPeak: (
    start: number,
    end: number,
    signalInfo: SignalDefinition,
  ) => {
    // Logger.debug('[calculatePeakToPeak] init');
    // Logger.debug('[calculatePeakToPeak] start', start);
    // Logger.debug('[calculatePeakToPeak] end', end);
    // Logger.debug('[calculatePeakToPeak] signalInfo', signalInfo);
    let peakToPeak = 0;

    const valuesBetween = chartTools.getData(signalInfo, {
      first: chartRangeTools.convertToEpoch(start),
      last: chartRangeTools.convertToEpoch(end) + 1,
    });

    if (valuesBetween.length) {
      let lowestValue: number | undefined;
      let highestValue: number | undefined;

      valuesBetween.forEach(([timestamp, value]) => {
        if (timestamp >= start && timestamp <= end) {
          if (lowestValue === undefined || lowestValue > value)
            lowestValue = value;
          if (highestValue === undefined || highestValue < value)
            highestValue = value;
        }
      });

      if (lowestValue && highestValue) {
        const diff = highestValue - lowestValue;
        if (diff) {
          peakToPeak = diff;
        }
      }

      // Logger.debug('[calculatePeakToPeak] lowestValue', lowestValue);
      // Logger.debug('[calculatePeakToPeak] highestValue ', highestValue);
      // Logger.debug('[calculatePeakToPeak] peakToPeak ', peakToPeak);
      // Logger.debug('[calculatePeakToPeak] valuesBetween', valuesBetween);
    }

    return peakToPeak;
  },
  calculateDropOnMoveOrResize: _.throttle(
    (
      originalMarker: ScoringCanvasEvent,
      signalInfo: SignalDefinition | undefined,
      newXPosition: number,
      mode: ResizeModes | 'move',
      callback: (drop: number) => void,
    ) => {
      Logger.debug('[calculateDropOnResize] init');
      if (signalInfo) {
        let drop = 0;
        const { start, end } = eventChartTools.getTimesAfterResizeOrMove(
          originalMarker,
          newXPosition,
          mode,
        );

        if (
          eventChartTools.shouldHaveDrop(
            start,
            end,
            originalMarker.type,
            signalInfo.type,
          )
        ) {
          if (signalInfo) {
            drop = eventChartTools.calculatePeakToPeak(start, end, signalInfo);
          }
        }

        callback(drop);
      }
    },
    250,
    { leading: false },
  ),
  getMoveTimeDiff: (marker: ScoringCanvasEvent, newXPosition: number) => {
    const newTimestamp = ScoringRenderingService.getTimestampFromLeftAttribute(
      marker,
      marker.position.left + newXPosition,
    );
    let timeDiff = newTimestamp - marker.start;
    timeDiff = eventChartTools.removeTimestampPrecision(timeDiff);
    return timeDiff;
  },
  getTimesAfterResizeOrMove: (
    marker: ScoringCanvasEvent,
    newXPosition: number,
    mode: MoveModes,
  ) => {
    const timeDiff = eventChartTools.getMoveTimeDiff(marker, newXPosition);

    const newStart =
      mode === 'left' || mode === 'move'
        ? marker.start + timeDiff
        : marker.start;
    const newEnd =
      mode === 'right' || mode === 'move' ? marker.end + timeDiff : marker.end;

    return {
      start: newStart,
      end: newEnd,
    };
  },
  shouldHaveDrop: (
    start: number,
    end: number,
    markerType: MarkerType,
    signalType?: SignalType,
  ) => {
    let hasDrop = MarkerDefinitions.get(markerType)?.extras?.hasDrop || false;

    if (hasDrop && signalType) {
      const signalInfo = chartTools.getSignalInfo(signalType);
      if (signalInfo) {
        const dataHasGaps = chartTools.dataHasGaps(signalInfo, {
          first: chartRangeTools.convertToEpoch(start),
          last: chartRangeTools.convertToEpoch(end) + 1,
        });

        hasDrop = !dataHasGaps;
      } else {
        hasDrop = false;
      }
    } else {
      hasDrop = false;
    }
    return hasDrop;
  },
  isCrossChart: (markerType: MarkerType) =>
    MarkerDefinitions.get(markerType)?.isCrossChart || false,
  calculateOverlayPosition: (
    markerStart: number,
    markerEnd: number,
    periodStart: number,
    periodEnd: number,
    containerWidth: number,
  ) => {
    const diffWithExtremeMax = markerEnd - markerStart;
    const diffWithEventStart = periodStart - markerStart;
    const diffWithEventEnd = periodEnd - markerStart;

    const left = (diffWithEventStart * containerWidth) / diffWithExtremeMax;
    const width =
      (diffWithEventEnd * containerWidth) / diffWithExtremeMax - left;

    return {
      left,
      width,
    };
  },
};

export default eventChartTools;
