import _ from 'underscore';
import EventService from './eventService';
import Analytics from './analytics';
import sheetTools from '../components/SignalSheet/sheetTools';
import SignalRenderingService from './signalRenderingService';
import Logger from '../utils/logger';
import { AnalyticsKey } from '../interfaces/analytics';

export type UndoOrigin = 'KeyboardShortcut' | 'Toolbar';
export interface UndoServiceInterface {
  initialize: () => void;
  getUndoHistory: () => UndoRecipe[];
  getRedoHistory: () => UndoRecipe[];
  addUndoRecipe: (recipe: UndoRecipeBasic) => UndoRecipe;
  generateUndoId: () => string;
  undo: (opts: { origin: UndoOrigin }) => void;
  redo: (opts: { origin: UndoOrigin }) => void;
  processQueue: () => void;
}

export interface UndoOperation {
  run: () => void;
}

interface QueuedOperation {
  type: 'undo' | 'redo';
  origin: UndoOrigin;
  recipe: UndoRecipe;
}

export interface UndoRecipeBasic {
  type: 'ScoreEvent' | 'DeleteEvent' | 'UpdateEvent';
  targetEpoch?: number;
  bulkId?: string;
  undo: UndoOperation;
  redo: UndoOperation;
}

export type UndoRecipe = UndoRecipeBasic & {
  id: string;
  createdAt: number;
};

let undoRecipesStack: UndoRecipe[] = [];
let redoRecipesStack: UndoRecipe[] = [];

const pendingOperationsQueue: QueuedOperation[] = [];

let eventCbIds: string[] = [];

const UndoService: UndoServiceInterface = {
  initialize: () => {
    undoRecipesStack.splice(0, undoRecipesStack.length);
    redoRecipesStack.splice(0, redoRecipesStack.length);
    EventService.unsubscribe(eventCbIds);

    eventCbIds = [
      EventService.subscribe('KeyboardShortcut.UndoAction', () =>
        UndoService.undo({ origin: 'KeyboardShortcut' }),
      ),
      EventService.subscribe('KeyboardShortcut.RedoAction', () =>
        UndoService.redo({ origin: 'KeyboardShortcut' }),
      ),
    ];
  },
  getUndoHistory: () => undoRecipesStack,
  getRedoHistory: () => redoRecipesStack,
  generateUndoId: () => _.uniqueId('Undo_'),
  addUndoRecipe: (recipe: UndoRecipeBasic) => {
    Logger.log('[UndoService] addUndoRecipe', recipe);
    const recipeId = UndoService.generateUndoId();

    const undoRecipe: UndoRecipe = {
      ...recipe,
      id: recipeId,
      bulkId: recipe.bulkId || recipeId,
      createdAt: Date.now(),
    };
    undoRecipesStack.push(undoRecipe);
    redoRecipesStack.splice(0, redoRecipesStack.length);

    return undoRecipe;
  },
  undo: (opts) => {
    const recipe = undoRecipesStack.pop();
    if (recipe) {
      const { bulkId } = recipe;
      const recipesForBulkId = [
        recipe,
        ...undoRecipesStack.filter((r) => r.bulkId === bulkId),
      ];

      recipesForBulkId.forEach((nextRecipe) => {
        pendingOperationsQueue.push({
          origin: opts.origin,
          type: 'undo',
          recipe: nextRecipe,
        });
        redoRecipesStack.push(nextRecipe);
      });

      undoRecipesStack = undoRecipesStack.filter((r) => r.bulkId !== bulkId);
      UndoService.processQueue();
    }
  },
  redo: (opts) => {
    const recipe = redoRecipesStack.pop();
    if (recipe) {
      const { bulkId } = recipe;
      const recipesForBulkId = [
        recipe,
        ...redoRecipesStack.filter((r) => r.bulkId === bulkId),
      ];

      recipesForBulkId.forEach((nextRecipe) => {
        pendingOperationsQueue.push({
          origin: opts.origin,
          type: 'redo',
          recipe: nextRecipe,
        });
        undoRecipesStack.push(nextRecipe);
      });

      redoRecipesStack = redoRecipesStack.filter((r) => r.bulkId !== bulkId);
      UndoService.processQueue();
    }
  },
  processQueue: () => {
    Logger.debug(
      '[UndoService][processQueue] Queue:',
      pendingOperationsQueue.length,
    );

    const operation = pendingOperationsQueue.pop();
    if (operation) {
      const { recipe, type, origin } = operation;

      if (recipe.targetEpoch !== undefined) {
        sheetTools.selectEpochIfOutsideExtremes(recipe.targetEpoch);
      }

      SignalRenderingService.waitForRenderFinished({
        extraWaitAfter: 50,
      }).then(() => {
        if (type === 'undo') {
          recipe.undo.run();
        } else if (type === 'redo') {
          recipe.redo.run();
        }

        const event: AnalyticsKey =
          type === 'undo' ? 'ACTION_UNDO' : 'ACTION_REDO';
        EventService.dispatch('Scoring.CancelActiveMarker');
        Analytics.track.event(event, { origin });

        Logger.debug(
          '[UndoService][processQueue] Requesting to process the next one',
        );
        UndoService.processQueue();
      });
    } else {
      Logger.debug('[UndoService][processQueue] No more items in the queue.');
    }
  },
};

export default UndoService;
