import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {
  makeStyles,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Paper,
} from '@material-ui/core';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {
  OnRestoreDefault,
  OnSaveSignalOrdering,
  OnSortEnd,
} from '../../interfaces/signal-ordering';
import { darkenOrLighten } from '../../utils/theme';
import OutlinedButton from '../Shared/OutlinedButton';
import Analytics from '../../services/analytics';
import SheetDefinition, {
  SignalDefinition,
} from '../../interfaces/sheet-definition';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginTop: theme.spacing(1),
    flexDirection: 'column',
    display: 'flex',
    maxWidth: 400,
  },
  item: {
    padding: theme.spacing(0.5),
  },
  dragIcon: {
    color: darkenOrLighten(
      theme.id,
      theme.colors.modal.backgroundColor,
      0.4,
      0.4,
    ),
  },
  paper: {
    display: 'flex',
    width: '100%',
    paddingLeft: theme.spacing(1),
    background: darkenOrLighten(
      theme.id,
      theme.palette.secondary.main,
      -0.5,
      0,
    ),
    color: theme.palette.secondary.contrastText,
    alignItems: 'center',
    cursor: 'grab',

    '& .MuiListItemText-secondary': {
      color: theme.colors.modal.color,
      opacity: 0.54,
    },
  },
  restoreButton: {
    justifySelf: 'center',
    alignSelf: 'center',
  },
}));

interface SignalOrderingListProps {
  definition: SheetDefinition;
  onSaveHandler: OnSaveSignalOrdering;
  onRestoreDefault: OnRestoreDefault;
}

const SignalOrderingList = (props: SignalOrderingListProps): JSX.Element => {
  const classes = useStyles();

  const [isCustomOrder, setIsCustomOrder] = React.useState(
    props.definition.customOrder || false,
  );
  const [signals, setSignals] = React.useState([...props.definition.signals]);

  const onSortEnd: OnSortEnd = ({ oldIndex, newIndex }) => {
    document.body.style.cursor = 'default';
    setIsCustomOrder(true);
    setSignals((prev) => {
      const newOrder = arrayMove(prev, oldIndex, newIndex);
      props.onSaveHandler(props.definition.name, newOrder);

      const signalType = newOrder[newIndex].type;
      Analytics.track.signalReordering({
        sheetId: props.definition.type,
        signalType,
        newPosition: newIndex + 1,
        previousPosition: oldIndex + 1,
        selectedOrder: newOrder.map((signal) => signal.type),
      });

      return newOrder;
    });
  };

  const onRestoreDefault = () => {
    const defaultOrder = props.onRestoreDefault(props.definition.name);
    setSignals([...defaultOrder]);
    setIsCustomOrder(false);
  };

  const SortableItem = SortableElement(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ value }: { value: SignalDefinition }) => (
      <ListItem className={classes.item}>
        <Paper
          data-cy={`sortSignal-${value.name}`}
          elevation={2}
          className={classes.paper}
        >
          <ListItemIcon className={classes.dragIcon}>
            <DragIndicatorIcon />
          </ListItemIcon>
          <ListItemText
            primary={value.groupName ? value.groupName : value.name}
            secondary={
              <>
                <div>{value.type}</div>
                {value.alternativeSignals &&
                  value.alternativeSignals.length > 0 &&
                  value.alternativeSignals.map((alt) => (
                    <div key={alt}>{alt}</div>
                  ))}
              </>
            }
          />
        </Paper>
      </ListItem>
    ),
  );

  // eslint-disable-next-line react/no-unused-prop-types
  const SortableContainerDense = SortableContainer(
    (containerProps: { children: JSX.Element | JSX.Element[] }) => {
      return <List dense>{containerProps.children}</List>;
    },
  );

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h5" align="center">
          {props.definition.name}
        </Typography>
        <div
          data-cy="SignalOrderingList"
          onMouseDown={() =>
            document
              .querySelectorAll('.MuiListItem-root .MuiPaper-root')
              .forEach((el) => {
                // eslint-disable-next-line no-param-reassign
                (el as HTMLElement).style.cursor = 'grabbing';
              })
          }
          onMouseUp={() =>
            document
              .querySelectorAll('.MuiListItem-root .MuiPaper-root')
              .forEach((el) => {
                // eslint-disable-next-line no-param-reassign
                (el as HTMLElement).style.cursor = 'grab';
              })
          }
        >
          <SortableContainerDense onSortEnd={onSortEnd}>
            {signals.map((signal, index) => (
              <SortableItem
                key={`item-${signal.name}`}
                index={index}
                value={signal}
              />
            ))}
          </SortableContainerDense>
        </div>

        {isCustomOrder && (
          <OutlinedButton
            className={classes.restoreButton}
            onClick={onRestoreDefault}
          >
            Restore default
          </OutlinedButton>
        )}
      </div>
    </>
  );
};

export default SignalOrderingList;
