import React from 'react';
import clsx from 'clsx';

import { makeStyles, Typography } from '@material-ui/core';
import { ReportData } from '../../queries/report';

const useStyles = makeStyles((theme) => ({
  parameter: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    alignItems: 'center',
    padding: theme.spacing(0, 2.5),
    height: 20,
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  onlyForPrinting: {
    display: 'none',
    padding: theme.spacing(0),
    '@media print': {
      display: 'grid',
    },
  },
}));

interface ReportParameterItemProps {
  parameter: ReportData;
  style?: React.CSSProperties;
  onlyForPrinting?: boolean;
}

function ReportParameterItem(props: ReportParameterItemProps): JSX.Element {
  const classes = useStyles();

  return (
    <div
      style={props.style}
      className={clsx(classes.parameter, {
        [classes.onlyForPrinting]: props.onlyForPrinting,
      })}
      data-cy="ReportParameterItem"
    >
      <Typography variant="body1" className={classes.name}>
        {props.parameter.name}:
      </Typography>
      <Typography variant="body2">
        {props.parameter.value !== undefined &&
        props.parameter.value !== '' &&
        props.parameter.value !== 'NaN' ? (
          <>
            {props.parameter.value}
            {props.parameter.unit}
          </>
        ) : (
          '-'
        )}
      </Typography>
    </div>
  );
}

export default ReportParameterItem;
