import React from 'react';

import { Typography } from '@material-ui/core';

const RecordingListEmpty = (): JSX.Element => {
  return (
    <>
      <Typography>There are no available recordings</Typography>
    </>
  );
};

export default RecordingListEmpty;
