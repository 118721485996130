import _ from 'underscore';
import Logger from '../utils/logger';
import { NotificationKey } from '../interfaces/notification-service';
import NotificationService from './notificationService';

type LongRunningOperationType = 'Import' | 'Export';
type LongRunningOperationStatus = 'Created' | 'Running' | 'Finished' | 'Failed';
export interface LongRunningOperationResult {
  finished: boolean;
  data?: unknown;
}
type LongRunningOperationTask = (
  lro: LongRunningOperation,
) => Promise<LongRunningOperationResult>;
type LongRunningOperationFn = () => void;
type LongRunningOperationSuccessAction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  lro: LongRunningOperation,
) => void;
type LongRunningOperationErrorAction = (
  error: unknown,
  lro: LongRunningOperation,
) => void;

export interface LongRunningOperation {
  id: string;
  type: LongRunningOperationType;
  addedTime: number;
  finishedTime?: number;
  wakeUpIn: number;
  execCount: number;
  operation: LongRunningOperationFn;
  status: LongRunningOperationStatus;
  notificationKey: NotificationKey;
  seen: boolean;
  successAction?: LongRunningOperationSuccessAction;
  errorAction?: LongRunningOperationErrorAction;
  error?: unknown;
  data?: unknown;
  getElapsedTime: () => number;
}

interface AddOperationAttributes {
  type: LongRunningOperationType;
  task: LongRunningOperationTask;
  message: string;
  wakeUpIn: number;
  fireImmediately: boolean;
  successAction?: LongRunningOperationSuccessAction;
  errorAction?: LongRunningOperationErrorAction;
}

interface LongRunningOperationService {
  initialize: (setOperations: SetOperationsContext) => void;
  addOperation: (operation: AddOperationAttributes) => void;
  updateOperations: () => void;
  createFunction: (
    id: string,
    promise: LongRunningOperationTask,
  ) => LongRunningOperationFn;
  markAllAsSeen: () => void;
}

type SetOperationsContext = (operation: LongRunningOperation[]) => void;

let setOperationsContext: SetOperationsContext = () => {
  /* To be replaced on initialize */
};

const operations: LongRunningOperation[] = [];

const longRunningOperationService: LongRunningOperationService = {
  initialize: (setOperations: SetOperationsContext) => {
    Logger.log('[longRunningOperationService] Initialize');
    setOperationsContext = setOperations;

    longRunningOperationService.updateOperations();
  },
  addOperation: (operation: AddOperationAttributes) => {
    const id = _.uniqueId();
    const fn = longRunningOperationService.createFunction(id, operation.task);
    const key = NotificationService.send(operation.message);
    const operationData: LongRunningOperation = {
      id,
      type: operation.type,
      status: 'Created',
      addedTime: Date.now(),
      operation: fn,
      wakeUpIn: operation.wakeUpIn,
      execCount: 0,
      notificationKey: key,
      seen: false,
      successAction: operation.successAction,
      errorAction: operation.errorAction,
      getElapsedTime: () =>
        operationData.finishedTime
          ? operationData.finishedTime - operationData.addedTime
          : 0,
    };
    operations.push(operationData);
    Logger.log('[longRunningOperationService] Adding operation', operation);
    longRunningOperationService.updateOperations();

    if (operation.fireImmediately) {
      fn();
    } else {
      setTimeout(() => fn, operation.wakeUpIn);
    }
  },
  updateOperations: () => setOperationsContext([...operations]),
  markAllAsSeen: () => {
    operations.forEach((lro) => {
      // eslint-disable-next-line no-param-reassign
      lro.seen = true;
    });
    setOperationsContext([...operations]);
  },
  createFunction: (id: string, promise: LongRunningOperationTask) => {
    const fn = () => {
      const lro = operations.find((op) => op.id === id);
      if (lro) {
        Logger.debug('[LongRunningOperation][%s] Running', id);
        lro.execCount += 1;
        lro.status = 'Running';
        lro.seen = false;
        longRunningOperationService.updateOperations();

        promise(lro)
          .then((result: LongRunningOperationResult) => {
            Logger.debug('[LongRunningOperation][%s] then', id);
            if (result.finished) {
              lro.status = 'Finished';
              lro.finishedTime = Date.now();
              if (lro.successAction) {
                if (lro.notificationKey)
                  NotificationService.close(lro.notificationKey);
                lro.data = result.data;
                lro.successAction(result.data, lro);
              }
            } else {
              setTimeout(fn, lro.wakeUpIn);
            }
            lro.seen = false;
            longRunningOperationService.updateOperations();
          })
          .catch((error) => {
            Logger.debug('[LongRunningOperation][%s] catch', id);
            lro.status = 'Failed';
            lro.finishedTime = Date.now();
            lro.error = error;
            if (lro.errorAction) {
              if (lro.notificationKey)
                NotificationService.close(lro.notificationKey);
              lro.errorAction(error, lro);
            }
            lro.seen = false;
            longRunningOperationService.updateOperations();
          });
      } else {
        Logger.error(
          '[LongRunningOperation][%s] Fatal error. Operation id is missing',
          id,
        );
      }
    };

    return fn;
  },
};

export default longRunningOperationService;
