import React from 'react';
import { Paper, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Recording } from '../../queries/recording';
import OverviewRecordingInfo from '../StudyOverview/OverviewRecordingInfo';
import { Scoring } from '../../queries/scoring';
import ReportHeaderInfo from './ReportHeaderInfo';
import themeProvider from '../../utils/theme';
import ReportParameterLoading from './ReportParameterLoading';
import { generateTLocale, LanguageSupportedLocale } from '../../i18n';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    margin: theme.spacing(0, 1),
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    '@media print': {
      boxShadow: 'none',
      padding: 0,
      background: `${
        themeProvider.getType('LIGHT').palette.background.paper
      } !important`,
      color: theme.palette.getContrastText(
        themeProvider.getType('LIGHT').palette.background.paper,
      ),
    },
  },
  container: {
    position: 'relative',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

interface ReportHeaderProps {
  loading: boolean;
  recording?: Recording;
  scoring?: Scoring;
  locale: LanguageSupportedLocale;
}

const ReportHeader = (props: ReportHeaderProps): JSX.Element => {
  const classes = useStyles();

  const { t } = useTranslation();
  const tLocale = generateTLocale(t, props.locale);

  return (
    <Paper className={classes.root}>
      <div>
        <Typography variant="h6" className={classes.title}>
          {tLocale('Study Report')}
        </Typography>

        <div className={classes.container}>
          <ReportHeaderInfo scoring={props.scoring} locale={props.locale} />
          <OverviewRecordingInfo
            recording={props.recording}
            locale={props.locale}
          />
        </div>

        <ReportParameterLoading loading={props.loading} />
      </div>
    </Paper>
  );
};

export default ReportHeader;
