import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { SnackbarKey } from 'notistack';
import { useHistory } from 'react-router-dom';
import Logger from '../../utils/logger';

import { AttachedFile } from './interfaces/uploader-tools';
import uploaderTools from './utils/uploaderTools';
import { UploadStatus } from './interfaces/upload-manager';
import NotificationService from '../../services/notificationService';
import { ImportStatus } from '../../queries/uploadRecording/getImportStatus';
import TimeElapsedTimer from './TimeElapsedTimer';
import Analytics from '../../services/analytics';
import uploadManager from './utils/uploaderManager';
import longRunningOperationService, {
  LongRunningOperationResult,
  LongRunningOperation,
} from '../../services/longRunningOperationService';
import ActionOrDismiss from '../ActionOrDismiss/ActionOrDismiss';
import openRecording from '../../utils/openRecording';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
  },
  upload: {
    width: '100%',
    maxWidth: 500,
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  icon: {
    marginLeft: 5,
  },
  status: {
    marginTop: theme.spacing(1),
  },
  buttonProgress: {
    color: theme.colors.statuses.success,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -4,
    marginLeft: -12,
  },
  actions: {
    color: theme.palette.primary.contrastText,
  },
}));

interface FileUploaderProps {
  setAttachedFiles: (attachedFiles: AttachedFile[]) => void;
  setUploadInProgress: (uploadInProgress: boolean) => void;
  setReadyToSubmit: (readyToSubmit: boolean) => void;
  setUploadStatus: (uploadStatus: UploadStatus | undefined) => void;
  attachedFiles: AttachedFile[];
  readyToSubmit: boolean;
  uploadInProgress: boolean;
  uploadStatus: UploadStatus | undefined;
}

const FileUploader = (props: FileUploaderProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const totalSize = uploaderTools.calculateTotalSizeInBytes(
    props.attachedFiles,
  );

  const onTabClosingHandler = (ev: BeforeUnloadEvent) => {
    ev.preventDefault();
    // eslint-disable-next-line no-param-reassign
    ev.returnValue =
      'The recording upload is still in progress. Are you sure you want to cancel it?';
    return ev;
  };

  React.useEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', onTabClosingHandler);
    };
  }, []);

  const onClickHandler = () => {
    Analytics.track.event('UPLOAD_CLICK', {
      totalFiles: props.attachedFiles.length,
      totalSize,
    });
    NotificationService.closeAll();
    props.setUploadInProgress(true);
    props.setReadyToSubmit(false);
    NotificationService.send('Uploading in progress...', {
      persist: true,
    });
    window.addEventListener('beforeunload', onTabClosingHandler);
    const importAPI = uploaderTools.import;
    importAPI
      .start(props.attachedFiles, props.setAttachedFiles, props.setUploadStatus)
      .then((importId) =>
        importAPI.processFiles(
          importId,
          props.attachedFiles,
          props.setAttachedFiles,
          props.setUploadStatus,
        ),
      )
      .then((importId) => {
        Analytics.track.event('INTEGRITY_CHECK');
        NotificationService.closeAll();
        NotificationService.send('Performing a file integrity check...', {
          persist: true,
        });
        return importId;
      })
      .then((importId) =>
        importAPI.calculateHashes(
          importId,
          props.attachedFiles,
          props.setUploadStatus,
        ),
      )
      .then((importId) =>
        importAPI.completeUpload(importId, props.attachedFiles),
      )
      .then((importId) => {
        const uploadStatus = uploadManager.getUploadStatus();
        uploadStatus.status = 'Upload completed';
        props.setUploadStatus({ ...uploadStatus });

        Analytics.track.event('UPLOAD_SUCCESSFUL', {
          importId,
          speed: uploadStatus.speed,
          elapsedTime: uploadStatus.elapsedTime,
          transferredSize: uploadStatus.transferredSize,
        });
        NotificationService.closeAll();

        longRunningOperationService.addOperation({
          type: 'Import',
          wakeUpIn: 1000,
          fireImmediately: true,
          message: 'Upload successful. Importing...',
          task: (/* lro */) =>
            new Promise<LongRunningOperationResult>((resolve, reject) => {
              uploaderTools.import
                .getImportStatus(importId)
                .then((importStatus: ImportStatus) => {
                  if (importStatus.status === 'Completed') {
                    resolve({ finished: true, data: importStatus });
                  } else if (importStatus.status === 'Failed') {
                    reject(importStatus.status);
                  } else {
                    resolve({ finished: false });
                  }
                })
                .catch((error) => {
                  Analytics.track.event('IMPORT_ERROR', {
                    importId,
                  });
                  reject(error);
                });
            }),
          successAction: (
            importStatus: ImportStatus,
            lro: LongRunningOperation,
          ) => {
            const openOrDismissActions = (key: SnackbarKey) => (
              <ActionOrDismiss
                actionText="Open"
                actionOnClick={() => {
                  NotificationService.close(key);
                  if (importStatus.recordingId) {
                    openRecording(importStatus.recordingId, history);
                  }
                }}
                dismissText="Dismiss"
                dismissOnClick={() => NotificationService.close(key)}
              />
            );

            NotificationService.closeAll();
            NotificationService.send('Recording has been imported!', {
              uniqueId: 'recImportedNotification',
              variant: 'success',
              persist: true,
              customAction: openOrDismissActions,
            });

            Analytics.track.event('IMPORT_SUCCESS', {
              importId,
              recordingId: importStatus.recordingId,
              completedTime: lro.getElapsedTime(),
            });
          },
          errorAction: (error: unknown, lro: LongRunningOperation) => {
            Logger.error('[FileUploader] Upload error!', error);

            const dismissAction = (key: SnackbarKey) => (
              <ActionOrDismiss
                dismissText="Dismiss"
                dismissOnClick={() => NotificationService.close(key)}
              />
            );

            NotificationService.closeAll();
            NotificationService.send(
              'Import operation has failed. Please, try again or contact support.',
              { variant: 'error', persist: true, customAction: dismissAction },
            );

            Analytics.track.event('IMPORT_ERROR', {
              importId,
              completedTime: lro.getElapsedTime(),
            });
          },
        });

        return importId;
      })
      .catch((error) => {
        Logger.error('[FileUploader] Upload error!', error);

        const uploadStatus = uploadManager.getUploadStatus();
        uploadStatus.status = 'Upload failed';
        props.setUploadStatus({ ...uploadStatus });

        Analytics.track.event('UPLOAD_ERROR', {
          elapsedTime: uploadStatus.elapsedTime,
          error,
        });

        const dismissAction = (key: SnackbarKey) => (
          <ActionOrDismiss
            dismissText="Dismiss"
            dismissOnClick={() => NotificationService.close(key)}
          />
        );

        NotificationService.closeAll();
        NotificationService.send(
          'Upload has failed. Please, try again or contact support.',
          {
            variant: 'error',
            persist: true,
            customAction: dismissAction,
          },
        );
      })
      .finally(() => {
        props.setReadyToSubmit(true);
        props.setUploadInProgress(false);
        window.removeEventListener('beforeunload', onTabClosingHandler);
      });
  };

  return (
    <>
      <div data-cy="totalFilesToUpload">
        Total files to upload: {props.attachedFiles.length}
      </div>
      <div>Total size: {uploaderTools.convertBytesSizeToText(totalSize)}</div>
      {props.uploadStatus && (
        <div className={classes.status}>
          {props.uploadStatus.transferredSize >= 0 && (
            <div>
              Transferred size:{' '}
              {uploaderTools.convertBytesSizeToText(
                props.uploadStatus.transferredSize,
              )}{' '}
              (
              {`${uploaderTools
                .calculateTotalUploadProgress(
                  totalSize,
                  props.uploadStatus.transferredSize,
                )
                .toFixed(0)}%`}
              )
            </div>
          )}
          {props.uploadStatus.speed >= 0 && (
            <div>
              Upload speed:{' '}
              {uploaderTools.convertBytesSizeToText(props.uploadStatus.speed)}/s
            </div>
          )}
          {props.uploadStatus.elapsedTime >= 0 && (
            <div>
              Elapsed time:{' '}
              <TimeElapsedTimer
                stop={
                  props.uploadStatus.status === 'Upload failed' ||
                  props.uploadStatus.status === 'Upload completed'
                }
              />
            </div>
          )}
          {props.uploadStatus.status && (
            <div>Upload Status: {props.uploadStatus.status}</div>
          )}
        </div>
      )}
      <div className={classes.wrapper}>
        <Button
          className={classes.upload}
          variant="contained"
          color="primary"
          disabled={
            !props.readyToSubmit ||
            (props.uploadStatus &&
              props.uploadStatus.status === 'Upload completed')
          }
          onClick={onClickHandler}
          data-cy="uploadFilesBtn"
        >
          Upload <CloudUploadIcon fontSize="small" className={classes.icon} />
        </Button>
        {props.uploadInProgress && (
          <CircularProgress
            size={24}
            className={classes.buttonProgress}
            data-cy="circularProgressIcon"
          />
        )}
      </div>
    </>
  );
};

export default FileUploader;
