import _ from 'underscore';
import CryptoJS from 'crypto-js';
import UploaderTools, {
  AttachedFile,
  AttachedFilesSetter,
  UploadStatusSetter,
  DataTypeCounter,
} from '../interfaces/uploader-tools';
import Logger from '../../../utils/logger';
import queryManager from '../../../services/queryManager';
import uploadManager from './uploaderManager';
import {
  RecordingFileInput,
  ImportId,
  RequestImportMutationResult,
} from '../../../queries/uploadRecording/requestImport';
import {
  NextFileInfo,
  StartFileUploadMutationResult,
  StartFileUploadMutationParameters,
} from '../../../queries/uploadRecording/startFileUpload';
import { GetImportStatusQueryResult } from '../../../queries/uploadRecording/getImportStatus';
import {
  CompleteUploadMutationParameters,
  UploadedFileInput,
} from '../../../queries/uploadRecording/completeUpload';

const RAW_DATA_EXT = '.NIF';
const EXPLODED_DATA_EXT = '.NDF';

const uploaderTools: UploaderTools = {
  generateFileDefinitions: () => [
    {
      type: 'DeviceConfig',
      text: 'Device Config',
      filename: 'DEVICE.INI',
      visible: true,
      multiple: false,
      requiredFor: 'Raw',
      attachFor: 'Raw',
    },
    {
      type: 'RecordingConfig',
      text: 'Recording Config',
      filename: 'SETUP.INI',
      visible: true,
      multiple: false,
      requiredFor: 'Both',
      attachFor: 'Both',
    },
    {
      type: 'Data',
      text: 'Data Files',
      filename: [RAW_DATA_EXT, EXPLODED_DATA_EXT],
      visible: true,
      multiple: true,
      requiredFor: 'Both',
      attachFor: 'Both',
    },
    {
      type: 'Audio',
      text: 'Audio Files',
      filename: '.NAF',
      visible: true,
      multiple: true,
      attachFor: 'Raw',
    },
    {
      type: 'Scoring',
      text: 'Scoring File',
      filename: '.NDB',
      visible: true,
      multiple: true,
      attachFor: 'Exploded',
    },
    {
      type: 'Log',
      text: 'Log Files',
      filename: 'LOG.TXT',
      visible: true,
      multiple: true,
      attachFor: 'Raw',
    },
    {
      type: 'BioCalibration',
      text: 'Bio Calibration',
      filename: 'BIOCAL.GZ',
      visible: true,
      multiple: false,
      attachFor: 'Raw',
    },
    {
      type: 'Template',
      text: 'Recording Template',
      filename: 'RECTEMP.GZ',
      visible: true,
      multiple: false,
      attachFor: 'Raw',
    },
    {
      type: 'Patient',
      text: 'Patient',
      filename: '.NPF',
      visible: true,
      multiple: false,
      attachFor: 'Raw',
    },
    {
      type: 'Sensors',
      text: 'Sensors',
      filename: 'SENSORS.GZ',
      visible: true,
      multiple: false,
      attachFor: 'Raw',
    },
    {
      type: 'Events',
      text: 'Events',
      filename: '.NEF',
      visible: true,
      multiple: false,
      attachFor: 'Exploded',
    },
  ],
  calculateTotalSizeInBytes: (attachedFiles: AttachedFile[]) => {
    let totalSize = 0;
    attachedFiles.forEach((attachment) => {
      totalSize += attachment.file.size;
    });
    return totalSize;
  },
  getUploadType: (fileList: FileList | null) => {
    Logger.log('[getUploadType] Initializing');
    const dataFileCounter: DataTypeCounter = {};

    const dataFileExtensions = uploaderTools
      .generateFileDefinitions()
      .find((fileDef) => fileDef.type === 'Data')?.filename;

    if (fileList && dataFileExtensions && Array.isArray(dataFileExtensions)) {
      dataFileExtensions.forEach((ext) => {
        dataFileCounter[ext] = 0;
        let found = false;

        for (let i = 0; i < fileList.length && !found; i++) {
          const file = fileList.item(i);
          if (file) {
            found =
              found || file.name.toLowerCase().indexOf(ext.toLowerCase()) > -1;
          }
        }

        if (found) dataFileCounter[ext] += 1;
      });
    }

    Logger.log('[numberOfDataFileTypes] dataFileCounter:', dataFileCounter);
    if (dataFileCounter[RAW_DATA_EXT] && dataFileCounter[EXPLODED_DATA_EXT]) {
      return 'Both';
    }
    if (dataFileCounter[RAW_DATA_EXT]) {
      return 'Raw';
    }
    if (dataFileCounter[EXPLODED_DATA_EXT]) {
      return 'Exploded';
    }
    return 'Unknown';
  },
  filenameContains: (filename: string, fileList: string | string[]) => {
    return Array.isArray(fileList)
      ? fileList.some(
          (name) => filename.toLowerCase().indexOf(name.toLowerCase()) > -1,
        )
      : filename.toLowerCase().indexOf(fileList.toLowerCase()) > -1;
  },
  convertBytesSizeToText: (bytesSize: number) => {
    const sizeInMbs = bytesSize / 1024 / 1024;
    if (sizeInMbs < 1) {
      return `${(bytesSize / 1024).toFixed(2)}KB`;
    }
    return `${sizeInMbs.toFixed(2)}MB`;
  },
  convertAttachedFilesToImportFileInfoList: (attachedFiles: AttachedFile[]) => {
    Logger.log(
      '[UploadRecording][convertAttachedFilesToImportFileInfoList] Entering function.',
    );

    const importFilesInfo: RecordingFileInput[] = attachedFiles.map(
      (attachment) => ({
        name: attachment.file.name,
        size: attachment.file.size,
      }),
    );
    return importFilesInfo;
  },
  convertAttachedFilesToFilesUploadedSummaryList: (
    attachedFiles: AttachedFile[],
  ) => {
    Logger.log(
      '[UploadRecording][convertAttachedFilesToFilesUploadedSummaryList] Entering function.',
    );

    const filesSummary: UploadedFileInput[] = attachedFiles.map(
      (attachment) => {
        Logger.log('[UploadRecording][filesSummary] name:', attachment.file);
        Logger.log(
          '[UploadRecording][filesSummary] ---> progress:',
          attachment.progress,
        );
        Logger.log(
          '[UploadRecording][filesSummary] ---> size:',
          attachment.file.size,
        );
        Logger.log(
          '[UploadRecording][filesSummary] ---> hash:',
          attachment.hash,
        );
        return {
          name: attachment.file.name,
          hash: attachment.hash || 'hash',
        };
      },
    );

    Logger.log(
      '[UploadRecording][convertAttachedFilesToFilesUploadedSummaryList] filesSummary',
      filesSummary,
    );
    return filesSummary;
  },
  calculateFileHash: (attachment: AttachedFile) => {
    return new Promise((resolve) => {
      Logger.log(
        '[UploadRecording][calculateFileHash] Calculating hash for:',
        attachment.file.name,
      );
      const time = Date.now();

      const md5 = CryptoJS.algo.MD5.create();
      const getHash = () => {
        Logger.log('[UploadRecording][calculateFileHash] ---> getHash ');
        const hash = md5.finalize().toString(CryptoJS.enc.Base64);

        Logger.log('[UploadRecording][calculateFileHash] -----> hash: ', hash);
        Logger.log(
          '[UploadRecording][calculateFileHash] ---> Took(ms): ',
          Date.now() - time,
        );

        resolve(hash);
      };

      let byteOffset = 0;
      const BYTE_COUNT = 5 * 1024 * 1024; // 5MB
      const processNext = () => {
        Logger.log('[UploadRecording][calculateFileHash] ---> processNext');
        const isLastPart = byteOffset + BYTE_COUNT >= attachment.file.size;

        uploadManager
          .getFileBlob(
            attachment.file,
            byteOffset,
            !isLastPart ? BYTE_COUNT : undefined,
          )
          .then(uploaderTools.getWordArray)
          .then((wordArray) => {
            Logger.log(
              '[UploadRecording][calculateFileHash] -----> byteOffset',
              byteOffset,
            );
            Logger.log(
              '[UploadRecording][calculateFileHash] -----> isLastPart: ',
              isLastPart,
            );
            md5.update(wordArray);
          })
          .then(() => {
            if (!isLastPart) {
              byteOffset += BYTE_COUNT;
              processNext();
            } else {
              getHash();
            }
          });
      };

      processNext();
    });
  },
  calculateFileUploadProgress: (totalParts: number, remainingParts: number) =>
    100 - (100 * remainingParts) / totalParts,
  calculateTotalUploadProgress: (totalSize: number, uploadedSize: number) =>
    (100 * uploadedSize) / totalSize,
  getAttachment: (name: string, attachedFiles: AttachedFile[]) =>
    attachedFiles.find(
      (attachment) => attachment.file.name === name,
    ) as AttachedFile,
  resetAttachedFilesStatus: (
    attachedFiles: AttachedFile[],
    setAttachedFiles: AttachedFilesSetter,
  ) => {
    attachedFiles.forEach((file) => {
      const newFile = file;
      newFile.remainingParts = 0;
      newFile.totalParts = 0;
      newFile.progress = 0;
      newFile.uploading = false;
      newFile.uploadComplete = false;
      newFile.hash = undefined;
      // eslint-disable-next-line no-param-reassign
      file = newFile;
    });
    setAttachedFiles([...attachedFiles]);

    return attachedFiles;
  },
  getNextFileToUpload: (attachedFiles: AttachedFile[]) =>
    attachedFiles
      .sort((a, b) => {
        if (a.definition.requiredFor === b.definition.requiredFor) return 0;
        if (a.definition.requiredFor) return -1;
        return 1;
      })
      .find(
        (file) => file.uploading === false && file.uploadComplete === false,
      ),

  getWordArray: (blob: Blob) => {
    Logger.log('[UploadRecording][getWordArray] Entering function.');

    const time = Date.now();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onloadend = () => {
        const wordArray = CryptoJS.lib.WordArray.create(
          reader.result as unknown as number[],
        );

        Logger.log('[UploadRecording][getWordArray] Done!');
        Logger.log(
          '[UploadRecording][getWordArray] ---> Size (Bytes):',
          blob.size,
        );
        Logger.log(
          '[UploadRecording][getWordArray] ---> Time (ms):',
          Date.now() - time,
        );

        resolve(wordArray);
      };
    });
  },
  import: {
    start: (
      attachedFiles: AttachedFile[],
      setAttachedFiles: AttachedFilesSetter,
    ) => {
      Logger.log('[UploadRecording][startUpload] Entering function.');

      return new Promise((resolve, reject) => {
        const importFilesInfo =
          uploaderTools.convertAttachedFilesToImportFileInfoList(attachedFiles);

        uploaderTools.resetAttachedFilesStatus(attachedFiles, setAttachedFiles);

        const importAPI = uploaderTools.import;
        importAPI
          .requestImportId(importFilesInfo)
          .then((importId: ImportId) => resolve(importId))
          .catch((error) => reject(error));
      });
    },
    requestImportId: (files: RecordingFileInput[]) => {
      Logger.log(
        '[UploadRecording][requestImportRecording] Entering function.',
      );

      return new Promise((resolve, reject) => {
        queryManager
          .mutate<RequestImportMutationResult>('requestImport', {
            files,
          })
          .then((data) => resolve(data.recordingImport as ImportId))
          .catch((error: unknown) => reject(error));
      });
    },
    processFiles: (
      importId: ImportId,
      attachedFiles: AttachedFile[],
      setAttachedFiles: AttachedFilesSetter,
      setUploadStatus: UploadStatusSetter,
    ) => {
      Logger.log('[UploadRecording][processFiles] Entering function.');
      Logger.log(
        '[UploadRecording][processFiles] Processing importId:',
        importId,
      );

      return new Promise((resolve) => {
        const askForMoreFileParts = _.throttle(() => {
          Logger.debug(
            '[UploadRecording][askForMoreFileParts] Status of the queue:',
            uploadManager.getUploadQueueLength(),
          );
          if (
            uploadManager.getUploadQueueLength() <=
            uploadManager.getMaximumUploads()
          ) {
            const nextFile = uploaderTools.getNextFileToUpload(attachedFiles);
            if (nextFile) {
              nextFile.uploading = true;
              setAttachedFiles([...attachedFiles]);

              uploaderTools.import
                .requestNextFileUpload(importId, nextFile.file.name)
                .then((nextFileInfo: NextFileInfo) => {
                  if (nextFileInfo.parts.length > 0) {
                    nextFile.totalParts = nextFileInfo.parts.length;
                    nextFile.remainingParts = nextFileInfo.parts.length;
                    nextFile.progress =
                      uploaderTools.calculateFileUploadProgress(
                        nextFile.totalParts,
                        nextFile.remainingParts,
                      );
                    setAttachedFiles([...attachedFiles]);

                    uploadManager
                      .addUpload(nextFile, nextFileInfo.parts)
                      .then(askForMoreFileParts);
                  }
                })
                .catch(() => {
                  nextFile.uploading = false;
                  setAttachedFiles([...attachedFiles]);
                });
            } else if (
              uploadManager.getActiveUploads() === 0 &&
              attachedFiles.findIndex((file) => file.progress < 100) === -1
            ) {
              Logger.debug('[UploadRecording][askForMoreFiles] No more files.');
              resolve(importId);
            } else {
              Logger.debug(
                '[UploadRecording][askForMoreFileParts] No more files to upload. Waiting...',
              );
            }
          }
        }, 100);

        const onPartUpload = (filename: string) => {
          const attachment = uploaderTools.getAttachment(
            filename,
            attachedFiles,
          );
          attachment.remainingParts -= 1;
          attachment.progress = uploaderTools.calculateFileUploadProgress(
            attachment.totalParts,
            attachment.remainingParts,
          );
          if (attachment.remainingParts === 0) {
            attachment.uploadComplete = true;
            attachment.uploading = false;
          }
          setAttachedFiles([...attachedFiles]);
          Logger.debug(
            '[UploadRecording][onPartUpload] ---> filename:',
            filename,
          );
          Logger.debug(
            '[UploadRecording][onPartUpload] ---> remainingParts:',
            attachment.remainingParts,
          );
          Logger.debug(
            '[UploadRecording][onPartUpload] ---> totalParts:',
            attachment.totalParts,
          );
          askForMoreFileParts();
        };

        uploadManager.initialize(onPartUpload, setUploadStatus);
        askForMoreFileParts();
      });
    },
    requestNextFileUpload: (importId: ImportId, fileName: string) => {
      Logger.log('[UploadRecording][requestNextFileUpload] Entering function.');

      return new Promise((resolve, reject) => {
        const params: StartFileUploadMutationParameters = {
          importId,
          fileName,
        };

        queryManager
          .mutate<StartFileUploadMutationResult>('nextFile', params)
          .then((data) => resolve(data.startFileUpload as NextFileInfo))
          .catch((error: unknown) => reject(error));
      });
    },
    completeUpload: (importId: ImportId, attachedFiles: AttachedFile[]) => {
      Logger.log('[UploadRecording][completeUpload] Entering function.');

      return new Promise((resolve, reject) => {
        const params: CompleteUploadMutationParameters = {
          importId,
          files:
            uploaderTools.convertAttachedFilesToFilesUploadedSummaryList(
              attachedFiles,
            ),
        };

        queryManager
          .mutate('completeUpload', params)
          .then(() => resolve(importId))
          .catch((error: unknown) => reject(error));
      });
    },
    calculateHashes: (
      importId: ImportId,
      attachedFiles: AttachedFile[],
      setUploadStatus: UploadStatusSetter,
    ) => {
      Logger.log('[UploadRecording][calculateHashes] Entering function.');
      const time = Date.now();

      const uploadStatus = uploadManager.getUploadStatus();
      uploadStatus.status = 'Integrity check...';
      setUploadStatus({ ...uploadStatus });

      return new Promise((resolve) => {
        attachedFiles.forEach((attachment) => {
          uploaderTools
            .calculateFileHash(attachment)
            .then((hash) => {
              // eslint-disable-next-line no-param-reassign
              attachment.hash = hash;
            })
            .then(() => {
              if (attachedFiles.filter((file) => !file.hash).length === 0) {
                Logger.log(
                  '[UploadRecording][calculateHashes] Calculating hashes took (ms):',
                  Date.now() - time,
                );
                resolve(importId);
              }
            });
        });
      });
    },
    getImportStatus: (importId: ImportId) => {
      Logger.log('[UploadRecording][getImportStatus] Entering function.');

      return new Promise((resolve, reject) => {
        queryManager
          .query<GetImportStatusQueryResult>('ImportStatus', {
            importId,
          })
          .then((data) => resolve(data.recordingImport))
          .catch((error: unknown) => reject(error));
      });
    },
  },
};

export default uploaderTools;
