import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import Logger from '../../../utils/logger';
import { ChartOptions } from '../SignalChart/getChartOptions';
import { SignalEventDetailData } from '../../../interfaces/signal-event-detail-props';
import XChartEvent from './XChartEvent';
import ScoringRenderingService, {
  UpdateScoringCanvasEvents,
} from '../../../services/scoringRenderingService';
import ScoringEvent from './ScoringEvent';
import EventService from '../../../services/eventService';
import SignalRenderingService from '../../../services/signalRenderingService';
import eventChartTools from '../SignalChart/eventChartTools';
import ScoringService from '../../../services/scoringService';
import ScoringAnnotations from './ScoringAnnotations';
import ScoringShortcuts from './ScoringShortcuts';
import SelectedEpochHighlight from './SelectedEpochHighlight';

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      top: theme.spacing(1) + 30,
      bottom: theme.spacing(1),
      left: theme.spacing(1) + ChartOptions.signalNameWidth,
      right: theme.spacing(1),
      overflow: 'hidden',
    },
  });
});

export interface ScoringCanvasEventPosition {
  position: {
    blink: boolean;
    fadeIn: boolean;
    visible: boolean;
    isCrossChart: boolean;
    originalExtremesMin: number;
    originalExtremesMax: number;
    top: number;
    left: number;
    width: number;
    height: number | string;
    visibleWidth: number;
    outsideLeftSide: number;
    outsideRightSide: number;
  };
}

export interface ScoringCanvasEventExtras {
  extras: {
    hasDrop: boolean;
    stripedBackground?: boolean;
  };
}

export type ScoringCanvasEvent = SignalEventDetailData &
  ScoringCanvasEventPosition &
  ScoringCanvasEventExtras;

const ScoringCanvas = (): JSX.Element => {
  const classes = useStyles();
  const [events, setEvents] = useState<ScoringCanvasEvent[]>([]);
  const [annotationMode, setAnnotationMode] = useState(false);

  const containerRef = React.useRef<HTMLDivElement>(null);
  ScoringRenderingService.setScoringCanvasContainer(containerRef);

  const updateScoringCanvasEvents: UpdateScoringCanvasEvents = (
    newScoringCanvasEvents: ScoringCanvasEvent[],
  ) => {
    // Logger.debug('[ScoringCanvas] Drawing events:', newScoringCanvasEvents);
    setEvents(newScoringCanvasEvents);
  };

  React.useEffect(() => {
    ScoringRenderingService.setScoringCanvasContainer(containerRef);
    ScoringRenderingService.registerUpdateScoringCanvasEventsFunction(
      updateScoringCanvasEvents,
    );

    const cbId = [
      EventService.subscribe(
        'ScoringChanged',
        (marker: SignalEventDetailData) => {
          const isMarkerWithin =
            eventChartTools.isMarkerWithinCurrentExtremes(marker);
          const isMarkerRelevant =
            ScoringService.getMarkerTypesToFetch().includes(marker.type);

          if (isMarkerWithin && isMarkerRelevant) {
            SignalRenderingService.waitForNoUserInteraction().then(() => {
              Logger.log(
                '[ScoringCanvas] calling redrawScoringCanvas. marker:',
                marker,
              );
              ScoringRenderingService.requestRedraw();
            });
          }
        },
      ),
      EventService.subscribe('WindowResize', () =>
        ScoringRenderingService.requestRedraw(),
      ),
      EventService.subscribe('AnnotationMode', (enabled: boolean) =>
        setAnnotationMode(enabled),
      ),
    ];

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, []);

  const generateKeyId = (event: ScoringCanvasEvent): string =>
    [
      event.id,
      event.modifiedDate,
      event.position.left,
      event.position.width,
      event.position.top,
      event.position.blink,
      event.extras.hasDrop,
    ].join('|');

  return (
    <div ref={containerRef} className={classes.root}>
      <ScoringAnnotations enabled={annotationMode} />
      <SelectedEpochHighlight />

      {events.map((event) =>
        event.position.isCrossChart ? (
          <XChartEvent key={generateKeyId(event)} event={event} />
        ) : (
          <ScoringEvent key={generateKeyId(event)} event={event} />
        ),
      )}

      <ScoringShortcuts />
    </div>
  );
};

export default ScoringCanvas;
