import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { darkenOrLighten } from '../../utils/theme';

const useStyles = makeStyles((theme) => ({
  placeholder: {
    position: 'relative',
    border: '2px dotted #9e9e9e',
    background: darkenOrLighten(
      theme.id,
      theme.palette.background.paper,
      0.1,
      0.1,
    ),
    color: theme.palette.getContrastText(
      darkenOrLighten(theme.id, theme.palette.background.paper, 0.1, 0.1),
    ),
    margin: theme.spacing(0.5),
    borderRadius: 5,
    opacity: 0.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholderName: {
    textAlign: 'left',
    paddingLeft: theme.spacing(1),
    flex: 1,
  },
  example: {
    paddingRight: theme.spacing(1),
  },
  multiple: {
    '&::after': {
      width: 'calc(100% + 2px)',
      border: '2px dotted #9e9e9e',
      borderLeft: 'none',
      borderTop: 'none',
      height: 'calc(100% + 2px)',
      content: '""',
      position: 'absolute',
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      borderRadius: 5,
    },
  },
}));

export interface FilePlaceholderProps {
  isMultiple: boolean;
  text: string;
  filename: string | string[];
}

function FilePlaceholder(props: FilePlaceholderProps): JSX.Element {
  const classes = useStyles();

  return (
    <div
      className={
        props.isMultiple
          ? `${classes.placeholder} ${classes.multiple}`
          : classes.placeholder
      }
    >
      <Typography variant="caption" className={classes.placeholderName} noWrap>
        {props.text}
      </Typography>
      <Typography className={classes.example} variant="caption" noWrap>
        Example:{' '}
        {Array.isArray(props.filename)
          ? props.filename.join(', ')
          : props.filename}
      </Typography>
    </div>
  );
}

export default FilePlaceholder;
