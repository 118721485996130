import {
  MarkerType,
  MarkerTypeDefinition,
  MarkerGroup,
  Severity,
} from '../../../interfaces/markers';
import AnalysisPeriodsService from '../../../services/analysisPeriodsService';
import { KeyCode } from '../../SignalSheet/interfaces/keyboard-manager';

export const MarkerDefinitions = new Map<MarkerType, MarkerTypeDefinition>();
MarkerDefinitions.set('hypopnea', {
  type: 'hypopnea',
  name: 'Hypopnea',
  shortName: 'Hyp',
  color: 'rgba(32,167,226,0.7)',
  markerGroup: 'Hypopnea',
  shortcutKey: KeyCode.KeyH,
  severity: Severity.Medium,
});
MarkerDefinitions.set('snorebreath', {
  type: 'snorebreath',
  name: 'Single Snore',
  shortName: 'S. Snore',
  color: 'rgba(49,85,115,0.7)',
  markerGroup: 'Snore Breath',
  shortcutKey: KeyCode.KeyN,
  severity: Severity.None,
});
MarkerDefinitions.set('snore-train', {
  type: 'snore-train',
  name: 'Snore Train',
  shortName: 'ST',
  color: 'rgba(102,156,202,0.7)',
  markerGroup: 'Snore',
  shortcutKey: KeyCode.KeyS,
  severity: Severity.Low,
});
MarkerDefinitions.set('activity-movement', {
  type: 'activity-movement',
  name: 'Movement',
  shortName: 'Mov',
  color: 'rgba(87,191,149,0.7)',
  markerGroup: 'Activity',
  shortcutKey: KeyCode.KeyK,
  severity: Severity.None,
});
MarkerDefinitions.set('pulsewave-drop', {
  type: 'pulsewave-drop',
  name: 'PWA Drop',
  shortName: 'PD',
  color: 'rgba(118,255,3,0.7)',
  markerGroup: 'Other',
  severity: Severity.None,
});
MarkerDefinitions.set('web-custom', {
  type: 'web-custom',
  name: 'Custom',
  shortName: 'Custom',
  color: 'rgba(96,125,139,0.7)',
  markerGroup: 'Other',
  severity: Severity.None,
});
MarkerDefinitions.set('apnea', {
  type: 'apnea',
  name: 'Apnea',
  shortName: 'Apn',
  color: 'rgba(95,102,148,0.7)',
  markerGroup: 'Apnea',
  shortcutKey: KeyCode.KeyA,
  severity: Severity.None,
});
MarkerDefinitions.set('apnea-central', {
  type: 'apnea-central',
  name: 'A. Central',
  shortName: 'CA',
  color: 'rgba(169,181,255,0.7)',
  markerGroup: 'Apnea',
  shortcutKey: KeyCode.KeyC,
  severity: Severity.High,
});
MarkerDefinitions.set('apnea-mixed', {
  type: 'apnea-mixed',
  name: 'A. Mixed',
  shortName: 'MA',
  color: 'rgba(91,106,202,0.7)',
  markerGroup: 'Apnea',
  shortcutKey: KeyCode.KeyM,
  severity: Severity.Low,
});
MarkerDefinitions.set('apnea-obstructive', {
  type: 'apnea-obstructive',
  name: 'A. Obstructive',
  shortName: 'OA',
  color: 'rgba(228,203,143,0.7)',
  markerGroup: 'Apnea',
  shortcutKey: KeyCode.KeyO,
  severity: Severity.High,
});
MarkerDefinitions.set('arrhythmia-bradycardia', {
  type: 'arrhythmia-bradycardia',
  name: 'Bradycardia',
  shortName: 'Brady',
  color: 'rgba(204,151,170,0.7)',
  markerGroup: 'Arrhythmia',
  shortcutKey: KeyCode.KeyB,
  severity: Severity.High,
});
MarkerDefinitions.set('arrhythmia-tachycardia', {
  type: 'arrhythmia-tachycardia',
  name: 'Tachycardia',
  shortName: 'Tachy',
  color: 'rgba(125,27,62,0.7)',
  markerGroup: 'Arrhythmia',
  shortcutKey: KeyCode.KeyT,
  severity: Severity.Medium,
});
MarkerDefinitions.set('oxygensaturation-drop', {
  type: 'oxygensaturation-drop',
  name: 'Desaturation',
  shortName: 'Desat',
  color: 'rgba(34,173,184,0.7)',
  markerGroup: 'Oxygen',
  extras: {
    hasDrop: true,
  },
  shortcutKey: KeyCode.KeyD,
  severity: Severity.Medium,
});
MarkerDefinitions.set('sleep-wake', {
  type: 'sleep-wake',
  name: 'Wake',
  shortName: 'W',
  color: 'rgba(255,215,107,0.7)',
  markerGroup: 'Sleep Stage',
  severity: Severity.None,
});
MarkerDefinitions.set('sleep-rem', {
  type: 'sleep-rem',
  name: 'REM',
  shortName: 'R',
  color: 'rgba(233,110,128,0.7)',
  markerGroup: 'Sleep Stage',
  severity: Severity.None,
});
MarkerDefinitions.set('sleep-nrem', {
  type: 'sleep-nrem',
  name: 'NREM',
  shortName: 'NR',
  color: 'rgba(52,176,231,0.7)',
  markerGroup: 'Sleep Stage',
  severity: Severity.None,
});
MarkerDefinitions.set('sleep-n1', {
  type: 'sleep-n1',
  name: 'N1',
  shortName: 'N1',
  color: 'rgba(96,198,232,1.0)',
  markerGroup: 'Sleep Stage',
  severity: Severity.None,
});
MarkerDefinitions.set('sleep-n2', {
  type: 'sleep-n2',
  name: 'N2',
  shortName: 'N2',
  color: 'rgba(0,168,214,1.0)',
  markerGroup: 'Sleep Stage',
  severity: Severity.None,
});
MarkerDefinitions.set('sleep-n3', {
  type: 'sleep-n3',
  name: 'N3',
  shortName: 'N3',
  color: 'rgba(117,141,200,1.0)',
  markerGroup: 'Sleep Stage',
  severity: Severity.None,
});
MarkerDefinitions.set('signal-artifact', {
  type: 'signal-artifact',
  name: 'Signal Artifact',
  shortName: 'Artifact',
  color: 'rgba(158,158,158,0.7)',
  markerGroup: 'Artifact',
  shortcutKey: KeyCode.KeyX,
  severity: Severity.None,
});
MarkerDefinitions.set('analysis-start', {
  type: 'analysis-start',
  name: 'Analysis Start',
  shortName: 'A. Start',
  color: 'rgba(255,147,118,0.7)',
  markerGroup: 'Analysis Period',
  onChange: AnalysisPeriodsService.onPeriodChange,
  severity: Severity.None,
  isCrossChart: true,
  dispatchEventOnChange: ['Scoring.AnalysisPeriodChanged'],
});
MarkerDefinitions.set('analysis-stop', {
  type: 'analysis-stop',
  name: 'Analysis Stop',
  shortName: 'A. Stop',
  color: 'rgba(255,147,118,0.7)',
  markerGroup: 'Analysis Period',
  onChange: AnalysisPeriodsService.onPeriodChange,
  severity: Severity.None,
  isCrossChart: true,
  dispatchEventOnChange: ['Scoring.AnalysisPeriodChanged'],
});
MarkerDefinitions.set('position-left', {
  type: 'position-left',
  name: 'Position Left',
  shortName: 'L',
  color: 'rgba(1,101,191,0.7)',
  markerGroup: 'Position',
  severity: Severity.None,
});
MarkerDefinitions.set('position-right', {
  type: 'position-right',
  name: 'Position Right',
  shortName: 'R',
  color: 'rgba(110,184,252,0.7)',
  markerGroup: 'Position',
  severity: Severity.None,
});
MarkerDefinitions.set('position-supine', {
  type: 'position-supine',
  name: 'Position Supine',
  shortName: 'S',
  color: 'rgba(98,28,235,0.7)',
  markerGroup: 'Position',
  severity: Severity.None,
});
MarkerDefinitions.set('position-prone', {
  type: 'position-prone',
  name: 'Position Prone',
  shortName: 'P',
  color: 'rgba(172,131,225,0.7)',
  markerGroup: 'Position',
  severity: Severity.None,
});
MarkerDefinitions.set('position-upright', {
  type: 'position-upright',
  name: 'Position Upright',
  shortName: 'U',
  color: 'rgba(46,31,78,0.7)',
  markerGroup: 'Position',
  severity: Severity.None,
});
MarkerDefinitions.set('position-unknown', {
  type: 'position-unknown',
  name: 'Position Unknown',
  shortName: '?',
  color: 'rgba(117,124,136,0.7)',
  markerGroup: 'Position',
  severity: Severity.None,
});
MarkerDefinitions.set('signal-invalid', {
  type: 'signal-invalid',
  name: 'Invalid Data',
  shortName: 'Invalid',
  color: 'rgba(158,158,158,0.7)',
  markerGroup: 'Other',
  severity: Severity.None,
  extras: {
    stripedBackground: true,
  },
  dispatchEventOnChange: ['Scoring.ExclusionZoneChanged'],
});

export const ExtraOverlappingRules = new Map<MarkerGroup, MarkerGroup[]>();
ExtraOverlappingRules.set('Apnea', ['Hypopnea']);
ExtraOverlappingRules.set('Hypopnea', ['Apnea']);

export const DisableOverlappingRules = new Set<MarkerGroup>();
DisableOverlappingRules.add('Sleep Stage');

export const MarkerGroups = {
  getEventTypes: (markerGroups: MarkerGroup[]): MarkerType[] => [
    ...Array.from(MarkerDefinitions.values())
      .filter((marker) => markerGroups.includes(marker.markerGroup))
      .map((marker) => marker.type),
  ],
  getGroupNames: (): MarkerGroup[] => {
    const markerGroupNames: MarkerGroup[] = Array.from(
      MarkerDefinitions.values(),
    ).map((marker) => marker.markerGroup);

    const uniqueNames = new Set(markerGroupNames);

    return Array.from(uniqueNames.values());
  },
};

export const IgnoredMarkers: MarkerType[] = [
  'flow-diminished',
  'breath-normal',
  'breath-limitation',
  'breathing-paradoxical',
  'limbmovement-periodictwitch',
  'limbmovement-twitch',
  'plm',
  'pulsewave-drop',
];

export const MarkerDefinitionTools = {
  getFromMarkerTypes: (markerTypes: MarkerType[]): MarkerTypeDefinition[] =>
    markerTypes.flatMap((type) => {
      const marker = MarkerDefinitions.get(type);
      return marker ? [marker] : [];
    }),
};
