import gql from 'graphql-tag';
import { RecordingId } from './recording';

export interface GetAccessTokenQueryParameters {
  recordingId: RecordingId;
  scoringId: string;
}

export interface GetAccessTokenQueryResult {
  accessToken: {
    accessToken: string;
  };
}

export const GET_ACCESS_TOKEN = gql`
  query AccessToken($recordingId: String!, $scoringId: String!) {
    accessToken(recordingId: $recordingId, scoringId: $scoringId) {
      accessToken
    }
  }
`;
