import Highcharts from 'highcharts/highstock';
import HighchartsDraggablePoints from 'highcharts/modules/draggable-points';
import React from 'react';
import _ from 'underscore';
import { fade } from '@material-ui/core/styles';
import { SheetTools, ChartHeights } from './interfaces/sheet-tools';
import Logger from '../../utils/logger';
import {
  SheetToolbarPageFlip,
  SheetToolbarZoomRange,
  SheetToolbarIntenseMode,
} from '../../interfaces/sheet-toolbar-props';
import { ChartExtremes } from './interfaces/chart-range-tools';
import chartRangeTools from './chartRangeTools';
import chartTools from '../Chart/SignalChart/chartTools';
import PassiveEvents from '../../utils/PassiveEvents';
import SheetDefinition, {
  SignalDefinition,
  ChartSize,
  SignalScreenPosition,
  SignalType,
} from '../../interfaces/sheet-definition';
import { EpochChartOptions } from '../Chart/EpochChart/getEpochChartOptions';
import Analytics from '../../services/analytics';
import EventService from '../../services/eventService';
import SignalRenderingService from '../../services/signalRenderingService';
import PositionLabelingService from '../../services/positionLabelingService';
import { ChartName } from '../../interfaces/chart-props';
import { MarkerId } from '../../interfaces/signal-event-detail-props';
import ScoringRenderingService from '../../services/scoringRenderingService';
import ScoringService from '../../services/scoringService';
import UndoService from '../../services/undoService';
import TabSyncService from '../../services/tabSyncService';
import AuthService from '../../services/authService';
import { HighchartsWithCustom } from '../Chart/SignalChart/interfaces/chart-tools';

PassiveEvents(Highcharts);
HighchartsDraggablePoints(Highcharts);

let sheetContainer = React.createRef<HTMLDivElement>();
let labeledCharts: ChartName[] = [];
let zoomRange = 60000 * 2;
let pageFlip: SheetToolbarPageFlip = 'Epoch';
let isSingleClickScoring = false;
let invalidDataMode = false;
let isAnnotationMode = false;
let intenseMode: SheetToolbarIntenseMode = false;
let recordingId = '';
let scoringId = '';
let selectedEpoch = -1;
let activeSignals: SignalDefinition[] = [];
let fullSizeCharts: SignalType[] = [];

export const HighlightedPlotbandId = 'selectedEpoch';

const sheetTools: SheetTools = {
  initializeSheetTools: (sheetDefinition?: SheetDefinition) => {
    Logger.debug(
      '[sheetDefinition] Initializing with sheetDefinition:',
      sheetDefinition,
    );
    activeSignals = [];
    labeledCharts = [];
    fullSizeCharts = [];
    selectedEpoch = -1;

    UndoService.initialize();

    if (sheetDefinition) {
      TabSyncService.setSheetId(sheetDefinition.type);
      sheetTools.setActiveSignals(sheetDefinition.signals);
      ScoringService.processActiveSignalSubscriptions();
    }
  },
  addLabeledChart: (chartName: ChartName) => {
    Logger.debug(
      '[addLabeledChart][%s] Adding to the labeled chart list',
      chartName,
    );
    labeledCharts.push(chartName);
  },
  isLabeledChart: (chartName: ChartName) => {
    return labeledCharts.indexOf(chartName) > -1;
  },
  setSheetContainer: (container: React.RefObject<HTMLDivElement>) => {
    sheetContainer = container;
  },
  getActiveSignals: () => activeSignals,
  setActiveSignals: (signals: SignalDefinition[]) => {
    activeSignals = signals;
    signals.forEach((signal) => {
      const newSignal = signal;
      delete newSignal.screenPosition;

      if (signal.labels) {
        sheetTools.addLabeledChart(signal.type);
      }
    });
  },
  getSheetContainerHeight: () => {
    // Logger.log('[getSheetContainerHeight]', sheetContainer.current);
    const clientHeight = sheetContainer?.current?.clientHeight;
    return clientHeight || 0;
  },
  getSheetContainerWidth: () => {
    // Logger.log('[getSheetContainerWidth]', sheetContainer.current);
    const clientWidth = sheetContainer?.current?.clientWidth;
    return clientWidth ? clientWidth - 16 : 0;
  },
  getBresenhamWidth: () => {
    let width = 1200;
    if (sheetContainer.current) {
      width = sheetContainer?.current?.clientWidth;
      width = width || 0;
    }

    return width < 1200 ? width : 1200;
  },
  setRecordingId: (id: string) => {
    if (recordingId !== id) {
      recordingId = id;
      AuthService.clearAccessToken();
    }
  },
  getRecordingId: () => recordingId,
  setScoringId: (newScoringId?: string) => {
    if (scoringId !== newScoringId) {
      scoringId = newScoringId || '';
      AuthService.clearAccessToken();
    }
  },
  getScoringId: () => scoringId,
  autoFitAll: () => {
    Logger.log('[autoFitAll] autoFit');
    sheetTools.autoFitCharts();
    sheetTools.autoFitPlotBands();
    EventService.dispatch('WindowResize');
  },
  calculateAllChartHeights: (params?: { applySize?: boolean }) => {
    const chartHeights: ChartHeights = new Map<SignalType, number>();

    let availableSlots = 0;
    const wereFullSizeCharts = fullSizeCharts.length;
    fullSizeCharts = [];
    sheetTools.getActiveSignals().forEach((signal: SignalDefinition) => {
      const chartSize: ChartSize = signal.chartSize || 'normal';

      Logger.log(
        '[autoFitCharts][%s] Detected chartSize: ',
        signal.name,
        chartSize,
      );
      switch (chartSize) {
        case 'larger':
          availableSlots += 3;
          break;
        case 'normal':
          availableSlots += 2;
          break;
        case 'smaller':
          availableSlots += 1;
          break;
        case 'full':
          availableSlots += 1;
          fullSizeCharts.push(signal.type);
          break;
        default: // Do nothing
      }
    });

    const clientWidth = sheetTools.getSheetContainerWidth();
    const clientHeight = sheetTools.getSheetContainerHeight();
    const HEADER_MARGIN = 15;
    const availableSpaceForSignals =
      clientHeight - EpochChartOptions.height - HEADER_MARGIN;
    const unitSize = availableSpaceForSignals / availableSlots;

    let accumulatedDistanceFromTop = 20;

    sheetTools.getActiveSignals().forEach((signal: SignalDefinition) => {
      const chartSize: ChartSize = signal.chartSize || 'normal';

      Logger.log(
        '[autoFitCharts][%s] Detected chartSize: ',
        signal.name,
        chartSize,
      );
      let chartHeight = 0;

      if (chartSize === 'full') {
        chartHeight = availableSpaceForSignals / fullSizeCharts.length;
      } else if (fullSizeCharts.length > 0) {
        chartHeight = -1;
      } else {
        switch (chartSize) {
          case 'larger':
            chartHeight = unitSize * 3;
            break;
          case 'normal':
            chartHeight = unitSize * 2;
            break;
          case 'smaller':
            chartHeight = unitSize * 1;
            break;
          default: // Do nothing
        }
      }

      chartHeights.set(signal.type, chartHeight);
      if (params?.applySize) {
        const chart = chartTools.getChartFromRegistry(signal.type);
        if (chart) {
          const screenPosition: SignalScreenPosition = {
            height: chartHeight,
            distanceFromTop: accumulatedDistanceFromTop,
          };
          accumulatedDistanceFromTop += chartHeight;
          // eslint-disable-next-line no-param-reassign
          signal.screenPosition = screenPosition;

          chartTools.setSize(chart, clientWidth, chartHeight);
        }
      }
    });

    if (params?.applySize) {
      if (wereFullSizeCharts && fullSizeCharts.length === 0) {
        const currentExtremes = chartRangeTools.getCurrentExtremes();
        chartRangeTools.calculateTicks(
          currentExtremes.min,
          currentExtremes.max,
        );
        SignalRenderingService.reloadAllCharts();
      }
    }

    Logger.log('[calculateAllChartHeights] chartHeights:', chartHeights);
    return chartHeights;
  },
  getHeightForSignal: (signalType: SignalType) => {
    const signalInfo = chartTools.getSignalInfo(signalType);
    let height = signalInfo?.screenPosition?.height;

    if (height === undefined) {
      const calculatedHeight = sheetTools
        .calculateAllChartHeights({ applySize: false })
        .get(signalType);
      height = calculatedHeight ?? -1;
    } else {
      return height ?? -1;
    }

    Logger.log('[getHeightForSignal][%s] height:', signalType, height);
    return height;
  },
  getDistanceFromTopForSignal: (signalType: SignalType) => {
    const signalInfo = chartTools.getSignalInfo(signalType);
    return signalInfo?.screenPosition?.distanceFromTop;
  },
  otherChartsInFullSize: (signalType: SignalType) =>
    fullSizeCharts.length > 0 && !fullSizeCharts.includes(signalType),
  autoFitCharts: () => {
    sheetTools.calculateAllChartHeights({ applySize: true });
  },
  autoFitPlotBands: () => {
    Logger.log('[autoFitPlotBands] Starting');
    PositionLabelingService.autofitPlotBands();
  },
  overrideHighchartsReset: () => {
    Highcharts.Pointer.prototype.reset = function reset() {
      return undefined;
    };
  },

  /**
   * Overrides the native Highcharts hideOverlappingLabels method.
   * This removes the need to call getBBox from SVG, which is very
   * bad for performance.
   */
  overrideHighchartsHideOverlappingLabels: () => {
    const H = Highcharts.Chart.prototype as HighchartsWithCustom;
    // eslint-disable-next-line no-underscore-dangle
    if (!H._hideOverlappingLabels)
      // eslint-disable-next-line no-underscore-dangle
      H._hideOverlappingLabels = H.hideOverlappingLabels;
    H.hideOverlappingLabels.hideOverlappingLabels = () => {
      // NOOP
    };
  },
  restoreHighchartsHideOverlappingLabels: () => {
    const H = Highcharts.Chart.prototype as HighchartsWithCustom;
    // eslint-disable-next-line no-underscore-dangle
    H.hideOverlappingLabels = H._hideOverlappingLabels;
  },
  overrideHighchartsMethods: () => {
    /*
      Use this function very carefully.
      Some things could become unavailable on Highcharts update.
    */
  },
  setZoomRange: (newZoomRange: SheetToolbarZoomRange) => {
    Logger.log('[setZoomRange] Setting newZomRange: ', newZoomRange);

    zoomRange = chartRangeTools.getMsByZoomRange(newZoomRange);

    const currentExtremes = chartRangeTools.getCurrentExtremes();
    const rawMax = chartRangeTools.adjustToCurrentZoomRange(
      currentExtremes.min,
      currentExtremes.max,
    );
    const { min, max } = chartRangeTools.adjustToPartEdges(
      currentExtremes.min,
      rawMax,
    );
    sheetTools.setExtremes({ min, max }, { redraw: true });
    // sheetTools.selectBestEpochForCurrentExtremes();

    Analytics.track.navigation({ action: 'zoom', selection: newZoomRange });
  },
  setPageFlip: (newPageFlip: SheetToolbarPageFlip) => {
    Logger.log('[setPageFlip] Setting newPageFlip: ', newPageFlip);
    pageFlip = newPageFlip;

    Analytics.track.navigation({ action: 'page_flip', selection: newPageFlip });
  },
  getZoomRange: () => zoomRange,
  getPageFlip: () => pageFlip,
  setIntenseMode: (newIntenseMode: SheetToolbarIntenseMode) => {
    Logger.log('[setIntenseMode] Setting newIntenseMode: ', newIntenseMode);
    intenseMode = newIntenseMode;

    if (newIntenseMode) setTimeout(chartTools.preloadMissingEpochs, 0);
  },
  setSingleClickScoring: (is: boolean) => {
    isSingleClickScoring = is;
  },
  isSingleClickScoring: () => isSingleClickScoring,
  setInvalidDataMode: (is: boolean) => {
    invalidDataMode = is;
  },
  invalidDataMode: () => invalidDataMode,
  setAnnotationMode: (is: boolean) => {
    EventService.dispatch('AnnotationMode', is);
    isAnnotationMode = is;
  },
  isAnnotationModeEnabled: () => isAnnotationMode,
  downloadSheetData: () => {
    setTimeout(chartTools.preloadMissingEpochs, 0);
  },
  getIntenseMode: () => intenseMode,
  setExtremes: (extremes: ChartExtremes, options: { redraw: boolean }) => {
    Logger.log('[setExtremes] Setting new extremes:', extremes);
    const navigatorChart = chartTools.getNavigatorChart();

    if (navigatorChart) {
      const rawMax = chartRangeTools.adjustToCurrentZoomRange(
        extremes.min,
        extremes.max,
      );
      const { min, max } = chartRangeTools.adjustToPartEdges(
        extremes.min,
        rawMax,
      );
      navigatorChart.xAxis[0].setExtremes(min, max, options.redraw, false);
    }
  },
  getSelectedEpoch: () => selectedEpoch,
  setSelectedEpoch: (epoch: number) => {
    selectedEpoch = epoch;
    EventService.dispatch('CurrentEpochChanged');
  },
  selectEpoch: (
    epoch: number,
    opts?: { forceRedraw?: boolean; toMarkerId?: MarkerId },
  ) => {
    Logger.warn('[selectEpoch] epoch:', epoch);
    Logger.warn('[selectEpoch] opts:', opts);
    const firstEpoch = chartRangeTools.getFirstPartEpoch();
    const lastEpoch = chartRangeTools.getLastPartEpoch();

    const isEpochWithinPart = epoch >= firstEpoch && epoch <= lastEpoch;
    if (isEpochWithinPart) {
      if (opts?.toMarkerId) {
        Logger.warn('[selectEpoch] toMarkerId:', opts?.toMarkerId);
        ScoringRenderingService.setEventJumpingTo(opts.toMarkerId);
      }

      if (selectedEpoch !== epoch || opts?.forceRedraw) {
        selectedEpoch = epoch;

        EventService.dispatch('CurrentEpochChanged');

        const currentExtremesInEpoch =
          chartRangeTools.getCurrentExtremesInEpoch();
        const moreThan1Epoch =
          currentExtremesInEpoch.last - currentExtremesInEpoch.first > 1;
        const fromTime = chartRangeTools.convertToTimestamp(epoch);

        const isEpochAboveCurrentExtremes =
          selectedEpoch > currentExtremesInEpoch.last - 1;
        const isBelowCurrentExtremesOrTheFirst =
          selectedEpoch <= currentExtremesInEpoch.first;

        Logger.log(
          '[selectEpoch] isEpochAboveCurrentExtremes',
          isEpochAboveCurrentExtremes,
        );
        Logger.log(
          '[selectEpoch] isBelowCurrentExtremesOrTheFirst',
          isBelowCurrentExtremesOrTheFirst,
        );

        if (
          isEpochAboveCurrentExtremes ||
          isBelowCurrentExtremesOrTheFirst ||
          opts?.toMarkerId
        ) {
          Logger.log(
            '[selectEpoch] Selecting epoch outside current extremes. currentExtremes:',
            currentExtremesInEpoch,
          );
          const newFirstEpoch = moreThan1Epoch ? fromTime - 30000 : fromTime;
          const rawMax = chartRangeTools.adjustToCurrentZoomRange(
            newFirstEpoch,
            newFirstEpoch + 30000,
          );
          const { min, max } = chartRangeTools.adjustToPartEdges(
            newFirstEpoch,
            rawMax,
          );

          sheetTools.setExtremes({ min, max }, { redraw: true });
        }
      } else {
        if (opts?.toMarkerId) {
          ScoringRenderingService.requestRedraw();
        }
        Logger.warn('[selectEpoch] Tried to select the same epoch:', epoch);
      }
    } else {
      Logger.warn(
        '[selectEpoch] Tried to select an epoch out of boundaries:',
        epoch,
      );
    }
  },
  selectEpochIfOutsideExtremes: (epoch: number) => {
    if (
      !chartRangeTools.isEpochRangeWithinCurrentExtremes({
        first: epoch,
        last: epoch,
      })
    ) {
      sheetTools.selectEpoch(epoch);
    }
  },
  selectBestEpochForCurrentExtremes: (opts: { forceRedraw: boolean }) => {
    const currentExtremes = chartRangeTools.getCurrentExtremesInEpoch();
    const moreThan1Epoch = currentExtremes.last - currentExtremes.first > 1;
    const bestEpoch = moreThan1Epoch
      ? currentExtremes.first + 1
      : currentExtremes.first;
    sheetTools.selectEpoch(bestEpoch, opts);
  },
  animateScoredEpoch: (epoch: number, color: string) => {
    Logger.log('[selectAnimateScoredEpoch] Animating:', epoch);
    const currentExtremes = chartRangeTools.getCurrentExtremesInEpoch();
    const moreThan2Epochs = currentExtremes.last - currentExtremes.first > 2;
    const fromTime = chartRangeTools.convertToTimestamp(selectedEpoch);
    const toTime = fromTime + 30000;

    const plotBandId = `animatedEpoch${_.uniqueId()}`;

    const plotbandInitialColor = fade(color, 0.9);
    const newAnimatedPlotband = {
      id: plotBandId,
      from: fromTime,
      to: toTime,
      color: plotbandInitialColor,
    };

    chartTools
      .getCharts({ excludeNavigator: true, withAvailableSeries: true })
      .forEach((chart: Highcharts.Chart) => {
        const isEpochChart = chartTools.getChartName(chart) === 'EpochChart';
        if (moreThan2Epochs || isEpochChart) {
          const plotBand = chart.xAxis[0].addPlotBand(newAnimatedPlotband);

          if (plotBand) {
            chartTools.fadePlotband(
              plotBand?.svgElement,
              {
                color: plotbandInitialColor,
              },
              () => plotBand?.destroy(),
            );
          }
        }
      });
  },
  selectNextEpoch: () =>
    sheetTools.selectEpoch(sheetTools.getSelectedEpoch() + 1),
  selectPreviousEpoch: () =>
    sheetTools.selectEpoch(sheetTools.getSelectedEpoch() - 1),
};

export default sheetTools;
