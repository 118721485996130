import React from 'react';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { fade, lighten, makeStyles, Tooltip } from '@material-ui/core';
import _ from 'underscore';
import { useFeatures } from '@paralleldrive/react-feature-toggles';
import Logger from '../../utils/logger';
import sheetTools from '../SignalSheet/sheetTools';
import chartTools from '../Chart/SignalChart/chartTools';
import {
  BufferedEpochInfo,
  BufferedStatusData,
  TimelineEvent,
  TimelineEventPosition,
} from './navigator-timeline';
import EventService from '../../services/eventService';
import AnalysisPeriodsService from '../../services/analysisPeriodsService';
import { SignalEventDetailData } from '../../interfaces/signal-event-detail-props';
import chartRangeTools from '../SignalSheet/chartRangeTools';
import eventChartTools from '../Chart/SignalChart/eventChartTools';
import { MarkerGroups } from '../Chart/SignalChart/markerConstants';
import Analytics from '../../services/analytics';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    bottom: 3,
    left: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
  buffer: {
    position: 'relative',
    width: '100%',
    height: 3,
    background: fade(theme.colors.chart.axisLabel, 0.3),
  },
  epoch: {
    position: 'absolute',
    height: 3,
    transition: 'opacity 1s',
    cursor: 'pointer',
  },
  epochFullyLoaded: {
    background: lighten(theme.colors.chart.axisLabel, 0.3),
  },
  timelineEvent: {
    position: 'absolute',
    bottom: -3,
    width: 10,
    marginLeft: -5,
    height: 10,
    border: '2px solid white',
    borderColor: theme.palette.background.paper,
    borderRadius: 20,
    cursor: 'pointer',
  },
  analysisPeriod: {
    backgroundColor: theme.colors.statuses.success,
  },
  interestingEpoch: {
    backgroundColor: lighten(theme.colors.chart.line.default, 0.4),
  },
  tooltip: { textAlign: 'center' },
  tooltipEpoch: { maxWidth: 60 },
  tooltipTimeline: { maxWidth: 85 },
}));

export default function NavigatorTimeline(): JSX.Element {
  const classes = useStyles();
  const features = useFeatures();
  const isDevelopment = features.includes('development');

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [epochBufferedData, setEpochBufferedData] =
    React.useState<BufferedStatusData>(new Map());
  const [timelineEvents, setTimelineEvents] = React.useState<TimelineEvent[]>(
    [],
  );
  const [width, setWidth] = React.useState<number>(0);
  const dataLengthRef = React.useRef<number>(0);

  const recalculateWidth = () => {
    const dataLength = dataLengthRef.current;
    if (containerRef && containerRef.current) {
      if (dataLength) {
        Logger.log(
          '[NavigatorTimeline] Container width: ',
          containerRef.current.clientWidth,
        );
        Logger.log('[NavigatorTimeline] dataLength: ', dataLength);
        const newWidth: number = containerRef.current.clientWidth / dataLength;
        setWidth(Number.parseFloat(newWidth.toFixed(3)));
        Logger.log('[NavigatorTimeline] epochWidth: ', newWidth);
      } else {
        Logger.log('[NavigatorTimeline] Data length is 0. Skipping.');
      }
    }
  };

  const updateBufferedStatus = (newData: BufferedStatusData) => {
    setEpochBufferedData(newData);
    dataLengthRef.current = newData.size;
    recalculateWidth();
  };

  const calculateLeftForIndex = (index: number) => {
    const left = width * index;
    return left;
  };

  const calculateTimelineEventPosition = (
    start: number,
  ): TimelineEventPosition => {
    const partStart = chartRangeTools.getPartStartTime();
    const partEnd = chartRangeTools.getPartEndTime() + 30000;

    const diffWithExtremeMax = partEnd - partStart;
    const diffWithEventStart = start - partStart;
    const containerWidth = containerRef?.current?.clientWidth || 0;
    const maxLeft = containerWidth - 2;

    let left = (diffWithEventStart * containerWidth) / diffWithExtremeMax;

    if (left < 2) {
      left = 2;
    } else if (left > maxLeft) {
      left = maxLeft;
    }

    return {
      left,
    };
  };

  const calculateWidth = () => {
    if (width < 1) {
      return 1;
    }
    return width;
  };

  const isEpochFullyLoaded = (signalsWithData: number): boolean => {
    const activeSignals = sheetTools.getActiveSignals().length;
    if (isDevelopment && signalsWithData >= activeSignals) {
      return true;
    }
    return false;
  };

  const redrawTimelineEvents = _.debounce(() => {
    const periodEvents =
      AnalysisPeriodsService.retrievePeriodEventsForCurrentPart();
    const maxEpochs = eventChartTools.getEventIntensityMaxEpochForCurrentPart();
    Logger.log('[redrawTimelineEvents] maxEpochs', maxEpochs);

    const newTimelineEvents: TimelineEvent[] = [];

    if (maxEpochs.length <= 5) {
      newTimelineEvents.push(
        ...maxEpochs.map((epoch) => {
          const timelineEvent: TimelineEvent = {
            id: `MaxIntensity-${epoch}`,
            timelineEventType: 'MaxIntensity',
            timestamp: chartRangeTools.convertToTimestamp(epoch),
          };

          return timelineEvent;
        }),
      );
    }

    const timelinePeriodEvents: TimelineEvent[] = periodEvents.map((event) => ({
      id: event.id,
      markerType: event.type,
      timestamp: event.start,
      timelineEventType: 'Marker',
    }));

    newTimelineEvents.push(...timelinePeriodEvents);
    setTimelineEvents(newTimelineEvents);
  }, 250);

  const getTimelineEventTooltipText = (
    timelineEvent: TimelineEvent,
  ): string => {
    let title = '';
    if (timelineEvent.markerType) {
      title += eventChartTools.getNameByEventType(timelineEvent.markerType);
    } else {
      title += `High severity (#${
        chartRangeTools.convertToEpoch(timelineEvent.timestamp) + 1
      })`;
    }

    title += `\n${moment(timelineEvent.timestamp).format('HH:mm:ss')}`;

    return title;
  };

  React.useEffect(() => {
    chartTools.bufferedStatus.registerStatusUpdateFunction(
      updateBufferedStatus,
    );

    window.addEventListener('resize', recalculateWidth);

    const cbId = [
      EventService.subscribe(
        ['ScoringChanged'],
        (marker: SignalEventDetailData) => {
          if (
            MarkerGroups.getEventTypes(['Analysis Period']).includes(
              marker.type,
            )
          ) {
            redrawTimelineEvents();
          }
        },
      ),
      EventService.subscribe(['EventIntensityChanged'], () =>
        redrawTimelineEvents(),
      ),
    ];
    redrawTimelineEvents();

    return () => {
      window.removeEventListener('resize', recalculateWidth);
      EventService.unsubscribe(cbId);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={classes.container}
      data-cy="NavigatorTimeline-Container"
    >
      <div data-cy="EventIntensityChartBuffer" className={classes.buffer}>
        {Array.from(epochBufferedData.values()).map(
          (epoch: BufferedEpochInfo, index: number) => {
            return (
              <Tooltip
                key={JSON.stringify(epoch)}
                classes={{
                  tooltip: clsx(classes.tooltip, classes.tooltipEpoch),
                }}
                TransitionProps={{ timeout: 0 }}
                title={`#${epoch.epoch + 1}\n${moment(
                  chartRangeTools.convertToTimestamp(epoch.epoch),
                ).format('HH:mm:ss')}`}
                arrow
              >
                <div
                  className={clsx(classes.epoch, {
                    [classes.epochFullyLoaded]: isEpochFullyLoaded(
                      epoch.bufferedSignals,
                    ),
                  })}
                  style={{
                    width: calculateWidth(),
                    left: calculateLeftForIndex(index),
                  }}
                  data-cy={calculateLeftForIndex(index).toFixed(0)}
                  data-epoch={epoch.epoch}
                  data-signals={epoch.bufferedSignals}
                  onClick={() => {
                    sheetTools.selectEpoch(epoch.epoch);

                    Analytics.track.event('TIMELINE_CLICK', {
                      epoch: epoch.epoch + 1,
                      type: 'Epoch',
                    });
                  }}
                />
              </Tooltip>
            );
          },
        )}
      </div>

      {timelineEvents.map((event) => (
        <Tooltip
          key={event.id}
          classes={{ tooltip: clsx(classes.tooltip, classes.tooltipTimeline) }}
          title={getTimelineEventTooltipText(event)}
          TransitionProps={{ timeout: 0 }}
          arrow
        >
          <div
            className={clsx(classes.timelineEvent, {
              [classes.analysisPeriod]: event.timelineEventType === 'Marker',
              [classes.interestingEpoch]: event.timelineEventType !== 'Marker',
            })}
            style={calculateTimelineEventPosition(event.timestamp)}
            onClick={() => {
              const epoch = chartRangeTools.convertToEpoch(event.timestamp);
              sheetTools.selectEpoch(epoch, {
                forceRedraw: true,
                toMarkerId: event.id,
              });

              Analytics.track.event('TIMELINE_CLICK', {
                epoch: epoch + 1,
                type: event.timelineEventType,
              });
            }}
            data-cy={`NavigatorTimeline-Event${event.timelineEventType}`}
          />
        </Tooltip>
      ))}
    </div>
  );
}
