import React from 'react';
import MUIAppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';

import RecordingNavigationBar from '../RecordingNavigationBar/RecordingNavigationBar';
import { WithTheme } from '../../hoc/withTheme';
import LongRunningOperations from '../LongRunningOperations/LongRunningOperations';
import { WithPageStatus } from '../../interfaces/page-status';
import AppBarOptions from '../AppBarOptions/AppBarOptions';
import Separator from '../Shared/Separator';

import { ReactComponent as NoxturnalWebLogo } from '../../assets/images/noxturnal-web-logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    height: 40,
    minHeight: 40,
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.background.paper,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    boxShadow:
      '0px 0px 1px -1px rgba(0,0,0,0.2),' +
      '0px 0px 2px 0px rgba(0,0,0,0.14),' +
      '0px 1px 1px 0px rgba(0,0,0,0.12)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  button: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    height: 36,
    marginLeft: 3,
    minWidth: 50,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
}));

export default function AppBar(
  props: WithTheme<WithPageStatus<unknown>>,
): JSX.Element {
  const classes = useStyles();

  return (
    <MUIAppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <NoxturnalWebLogo width={140} />

        <Separator />
        {props.pageStatus.status.recordingId && (
          <>
            <RecordingNavigationBar pageStatus={props.pageStatus} />
          </>
        )}
        <LongRunningOperations />
        <AppBarOptions toggleTheme={props.toggleTheme} />
      </Toolbar>
    </MUIAppBar>
  );
}
