import _ from 'underscore';
import TabSyncLocalStorageInterface, {
  CustomSheet,
  CustomSheetsLocalStorageKey,
  LocalStorageExtendedAttributes,
  LocalStorageInterface,
  LocalStorageKey,
  LocalStorageValue,
  MutationQueue,
  TabSyncLocalStorageKey,
  TabSyncServiceInterface,
  TabSyncSheetExtremesInfo,
} from '../interfaces/tab-sync-service';
import Logger from '../utils/logger';
import { ChartExtremes } from '../components/SignalSheet/interfaces/chart-range-tools';
import EventService from './eventService';
import sheetTools from '../components/SignalSheet/sheetTools';
import studyTools from '../components/StudyOverview/studyTools';
import { SheetId, SignalDefinition } from '../interfaces/sheet-definition';
import { ScoringMutation } from './scoringService';
import UserAttributesService from './userAttributesService';

let sheetId: string;
const MAX_LIFE = 3 * 30 * 24 * 60 * 60 * 1000; // 3 months
const CURRENT_VERSION = 1;

let cleanUpDone = false;

const getKeyWithUserId = (key: LocalStorageKey) =>
  `${key}.${UserAttributesService.getUserId()}`;

const getFullKey = (key: LocalStorageKey) =>
  `${getKeyWithUserId(
    key,
  )}.${sheetTools.getRecordingId()}.${sheetTools.getScoringId()}.part${
    studyTools.getSelectedPart() ?? 1
  }`;

const LocalStorageHandler: LocalStorageInterface = {
  cleanup: () => {
    if (!cleanUpDone) {
      LocalStorageHandler.processOldKeys();
      const keys = Object.keys(localStorage).filter((key) => {
        const relevantKeys: LocalStorageKey[] = [
          'customSheet',
          'sheetExtremes',
        ];
        return relevantKeys.some(
          (s) => key.includes(s) && !key.includes(sheetTools.getRecordingId()),
        );
      });

      keys.forEach((key) => {
        const value = localStorage.getItem(key);
        if (value) {
          const extendedAttributes = JSON.parse(
            value,
          ) as LocalStorageExtendedAttributes;

          if (
            extendedAttributes.d &&
            Date.now() - extendedAttributes.d > MAX_LIFE
          ) {
            LocalStorageHandler.removeItem(key);
          }
        }
      });

      cleanUpDone = true;
    }
  },
  processOldKeys: () => {
    if (!cleanUpDone) {
      const keys = Object.keys(localStorage).filter((key) => {
        const relevantKeys: LocalStorageKey[] = [
          'customSheet',
          'sheetExtremes',
          'mutationQueue',
        ];
        return relevantKeys.some((s) => key.includes(s));
      });

      keys.forEach((key) => {
        const value = localStorage.getItem(key);
        if (value) {
          const extendedAttributes = JSON.parse(
            value,
          ) as LocalStorageExtendedAttributes;

          if (!extendedAttributes.v) {
            LocalStorageHandler.removeItem(key);
          }
        }
      });

      cleanUpDone = true;
    }
  },
  setItem: (index: string, value: LocalStorageValue) => {
    const extendedAttributes: LocalStorageExtendedAttributes = {
      i: value,
      v: CURRENT_VERSION,
      d: Date.now(),
    };
    localStorage.setItem(index, JSON.stringify(extendedAttributes));
  },
  getItem: (index: string) => {
    LocalStorageHandler.cleanup();
    const value = localStorage.getItem(index);
    try {
      if (value) {
        const parsedValue = JSON.parse(value);
        const extendedAttributes =
          parsedValue as LocalStorageExtendedAttributes;
        if (extendedAttributes.i && extendedAttributes.v === CURRENT_VERSION) {
          return extendedAttributes.i;
        }
        return JSON.parse(value);
      }
    } catch {
      return value;
    }

    return null;
  },
  removeItem: (index: string) => localStorage.removeItem(index),
};

const TabSyncLocalStorage: TabSyncLocalStorageInterface = {
  setItem: (key: TabSyncLocalStorageKey, value: TabSyncSheetExtremesInfo) => {
    Logger.log(
      '[TabSyncService] LocalStorageHandler.setItem(%s, %s)',
      getFullKey(key),
      JSON.stringify(value),
    );
    LocalStorageHandler.setItem(getFullKey(key), value);
  },
  getItem: (key: TabSyncLocalStorageKey) => {
    const value = LocalStorageHandler.getItem<TabSyncSheetExtremesInfo>(
      getFullKey(key),
    );
    if (value) {
      return value;
    }
    return undefined;
  },
};

const TabSyncService: TabSyncServiceInterface = {
  initialize: () => {
    Logger.debug('[TabSyncService] Initializing');
    window.addEventListener('storage', (e) => {
      if (!!e.key && e.key === getFullKey('sheetExtremes')) {
        if (e.oldValue && e.newValue) {
          const oldExtremes: TabSyncSheetExtremesInfo = JSON.parse(
            e.oldValue,
          ).i;
          const newExtremes: TabSyncSheetExtremesInfo = JSON.parse(
            e.newValue,
          ).i;
          if (
            oldExtremes.min !== newExtremes.min ||
            oldExtremes.max !== newExtremes.max
          ) {
            Logger.log('[TabSyncService] Received new extremes:', newExtremes);
            EventService.dispatch('TabSyncExtremes', newExtremes);
          }
        }
      }
    });
  },
  isCurrentExtremesInfoStored: () => {
    return !!TabSyncLocalStorage.getItem('sheetExtremes');
  },
  isCurrentExtremesInfoValid: () => {
    const info = TabSyncLocalStorage.getItem('sheetExtremes');
    return !!info && info.min > -1 && info.max > -1;
  },
  getSheetExtremesInfo: () => {
    return TabSyncLocalStorage.getItem('sheetExtremes');
  },
  setSheetId: (currentSheetId: SheetId) => {
    Logger.debug(
      '[TabSyncService/setSheetId] Setting sheetId:',
      currentSheetId,
    );

    sheetId = [currentSheetId, Date.now(), _.random(0, 1000)].join('.');
  },
  setCurrentExtremes: (extremes: ChartExtremes) => {
    const sheetExtremesInfo = TabSyncService.getSheetExtremesInfo();
    if (sheetExtremesInfo && sheetId) {
      if (sheetExtremesInfo.leaderSheet === sheetId) {
        TabSyncLocalStorage.setItem('sheetExtremes', {
          ...sheetExtremesInfo,
          ...extremes,
        });
      } else {
        Logger.debug(
          '[TabSyncService/setCurrentExtremes] Skipping because sheet %s is not leader (%s)',
          sheetId,
          sheetExtremesInfo.leaderSheet,
        );
      }
    } else {
      Logger.debug(
        '[TabSyncService/setCurrentExtremes] Skipping because there are no extremes info',
        sheetId,
      );
    }
  },
  setCurrentSheetAsLeader: () => {
    const recordingId = sheetTools.getRecordingId();
    if (sheetId) {
      if (TabSyncService.isCurrentExtremesInfoStored()) {
        const currentExtremesInfo = TabSyncService.getSheetExtremesInfo();
        if (currentExtremesInfo) {
          TabSyncLocalStorage.setItem('sheetExtremes', {
            ...currentExtremesInfo,
            recordingId,
            leaderSheet: sheetId,
          });
        }
      } else {
        TabSyncLocalStorage.setItem('sheetExtremes', {
          min: -1,
          max: -1,
          recordingId,
          leaderSheet: sheetId,
        });
      }
    }
  },
  updateSavedMutationQueue: (mutationQueue: ScoringMutation[]) => {
    if (mutationQueue.length > 0) {
      const newMutationQueue: MutationQueue = {
        recordingId: sheetTools.getRecordingId(),
        scoringId: sheetTools.getScoringId(),
        queue: mutationQueue,
      };
      LocalStorageHandler.setItem(
        getFullKey('mutationQueue'),
        newMutationQueue,
      );
    } else {
      LocalStorageHandler.removeItem(getFullKey('mutationQueue'));
    }
  },
  getSavedMutationQueue: () => {
    const savedData = LocalStorageHandler.getItem<MutationQueue>(
      getFullKey('mutationQueue'),
    );
    if (savedData && savedData !== null) {
      return savedData.queue;
    }

    return [];
  },
  getOtherMutationQueue: (opts?: { andDelete?: boolean }) => {
    LocalStorageHandler.cleanup();
    const keys = Object.keys(localStorage).filter((key) => {
      const relevantKeys: LocalStorageKey[] = ['mutationQueue'];
      return relevantKeys.some(
        (s) =>
          key.includes(getKeyWithUserId(s)) &&
          key !== getFullKey('mutationQueue'),
      );
    });

    const mutationQueue = LocalStorageHandler.getItem<MutationQueue>(keys[0]);
    if (mutationQueue) {
      if (opts?.andDelete) {
        LocalStorageHandler.removeItem(keys[0]);
      } else {
        EventService.dispatch(
          'Scoring.OtherMutationQueueWaiting',
          mutationQueue,
        );
      }
    }
  },
  discardOtherMutationQueue: () =>
    TabSyncService.getOtherMutationQueue({ andDelete: true }),
  addCustomSheet: (signals: SignalDefinition[]) => {
    const sheets = TabSyncService.getCustomSheets();
    const customSheet: CustomSheet = {
      id: Date.now(),
      signalTypes: signals.map((s) => s.type),
    };

    sheets.push(customSheet);

    LocalStorageHandler.setItem(
      [
        'customSheet' as CustomSheetsLocalStorageKey,
        sheetTools.getRecordingId(),
      ].join('.'),
      sheets,
    );

    return customSheet;
  },
  getCustomSheets: () => {
    const savedData = LocalStorageHandler.getItem<CustomSheet[]>(
      [
        'customSheet' as CustomSheetsLocalStorageKey,
        sheetTools.getRecordingId(),
      ].join('.'),
    );
    if (savedData) {
      const list = savedData;
      return Array.isArray(list) ? list : [];
    }

    return [];
  },
};

export default TabSyncService;
