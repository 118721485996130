import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Chart } from 'highcharts/highstock';
import React from 'react';
import { useTheme, makeStyles, createStyles, Theme } from '@material-ui/core';
import Logger from '../../../utils/logger';
import { RecordingPartData } from '../../StudyOverview/OverviewParts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      borderTop: `1px solid ${theme.colors.chart.grid}`,
    },
  }),
);

interface OverviewChartXAxisProps {
  partData: RecordingPartData;
  chartWidth?: number;
}

const OverviewChartXAxis = (props: OverviewChartXAxisProps): JSX.Element => {
  Logger.log('[OverviewChartXAxis] Created new instance.');
  const classes = useStyles();
  const theme = useTheme();
  const [chart, setChart] = React.useState<Chart>();

  React.useEffect(() => {
    if (chart) {
      chart.setSize(props.chartWidth, undefined);
    }
  }, [props.chartWidth]);

  const min = new Date(props.partData.startTime).valueOf();
  const max = new Date(props.partData.endTime).valueOf();

  const chartOptions: Highcharts.Options = {
    chart: {
      width: 1,
      height: 20,
      marginLeft: 0,
      marginTop: -1,
      spacingTop: 0,
      spacingRight: 20,
      spacingBottom: 0,
      spacingLeft: 0,
      plotBorderWidth: 0,
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      animation: false,
      events: {
        load() {
          Logger.log('[OverviewChartXAxis] load', this);
          setChart(() => this);
        },
      },
    },
    loading: {
      style: {
        background: theme.colors.chart.loading.background,
        color: theme.colors.chart.loading.color,
      },
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      crosshair: false,
      gridLineWidth: 1,
      gridLineColor: theme.colors.chart.grid,
      lineWidth: 0.3,
      tickColor: theme.colors.chart.grid,
      lineColor: theme.colors.chart.grid,
      showFirstLabel: true,
      showLastLabel: true,
      endOnTick: false,
      ordinal: false,
      labels: {
        enabled: true,
        format: '{value:%H:%M:%S}',
      },
      min,
      max,
    },
    yAxis: {
      startOnTick: true,
      endOnTick: true,
      tickPixelInterval: 100,
      alignTicks: false,
      lineWidth: 0,
      // lineColor: theme.colors.chart.grid,
      gridLineWidth: 0,
      showFirstLabel: true,
      showLastLabel: true,
      opposite: false,
      labels: {
        enabled: false,
      },
    },
    series: [
      {
        type: 'line',
        data: [],
        dataGrouping: { enabled: false },
        color: theme.colors.chart.line.default,
        lineWidth: 1,
        // TODO: This needs to be lower for most cases except SpO2 and Pulse
        gapSize: 30000,
        gapUnit: 'value',
        marker: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
  };

  return (
    <div data-cy="OverviewChartXAxis" className={classes.container}>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="stockChart"
        allowChartUpdate={false}
        options={chartOptions}
      />
    </div>
  );
};

export default OverviewChartXAxis;
