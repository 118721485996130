import {
  fade,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import LayersIcon from '@material-ui/icons/Layers';

import React from 'react';
import { useHistory } from 'react-router-dom';
import { SheetId } from '../../interfaces/sheet-definition';
import EventService from '../../services/eventService';
import ScoringService from '../../services/scoringService';
import Logger from '../../utils/logger';
import { ZIndex } from '../../utils/theme';
import eventChartTools from '../Chart/SignalChart/eventChartTools';
import chartRangeTools from '../SignalSheet/chartRangeTools';
import SheetDefinitions from '../SignalSheet/sheetDefinitions';
import sheetTools from '../SignalSheet/sheetTools';
import { OpenSignalSheetOrigin } from './OverviewPartOpenSignalSheet';
import studyTools from './studyTools';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    willChange: 'auto !important',
  },
  menu: {
    zIndex: ZIndex.Popup,
    backgroundColor: fade(theme.colors.modal.backgroundColor, 0.8),
    color: theme.colors.modal.color,
    backdropFilter: 'blur(2px)',
  },
  header: {
    margin: theme.spacing(2),
  },
  item: {
    fontSize: '0.875rem',
  },
  menuIcon: {
    color: theme.colors.modal.color,
    marginRight: theme.spacing(1.5),
    minWidth: 0,
  },
}));

export interface OverviewPartMenuPos {
  mouseX: null | number;
  mouseY: null | number;
  timestampFrom: null | number;
  timestampTo: null | number;
  partId: null | number;
  origin: null | OpenSignalSheetOrigin;
  existingAreas?: null | string[];
}

export interface OverviewPartDetailSecondaryMenuProps {
  position: OverviewPartMenuPos;
  handleClose: () => void;
}

const OverviewPartDetailSecondaryMenu = (
  props: OverviewPartDetailSecondaryMenuProps,
): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const openSignalSheets = (type: SheetId, customId?: number) => {
    const sheetDefinition = SheetDefinitions.get(type, customId);

    if (sheetDefinition && props.position.partId && props.position.origin) {
      studyTools.openSignalSheets({
        recordingId: sheetTools.getRecordingId(),
        scoringId: sheetTools.getScoringId(),
        partId: props.position.partId,
        origin: props.position.origin,
        history,
        sheetDefinition,
        epoch: props.position.timestampFrom
          ? chartRangeTools.convertToEpoch(props.position.timestampFrom) + 1
          : undefined,
        customId,
      });
    }
  };

  const handleClose = () => {
    EventService.dispatch('Overview.HighlightTimestamp');
    props.handleClose();
  };

  const clearOverlappingExclusionZones = (opts?: { undoId?: string }) => {
    if (props.position.existingAreas) {
      Logger.log('[OverviewPartAnalysisPeriod] clearExclusionZone');
      Logger.log(
        '[OverviewPartAnalysisPeriod] existingAreas',
        props.position.existingAreas,
      );

      let undoId = opts?.undoId;
      props.position.existingAreas.forEach((markerId) => {
        const result = ScoringService.removeEvent(markerId, {
          send: true,
          withUndoBulkId: undoId,
        });
        if (!undoId && result) undoId = result.undoRecipe?.bulkId;
      });
    }

    setTimeout(() => handleClose(), 50);
  };

  const addExclusionZone = () => {
    if (props.position.timestampFrom && props.position.timestampTo) {
      Logger.log('[OverviewPartAnalysisPeriod] addExclusionZone');

      const invalidSignalEvent = eventChartTools.createSignalEventDetailData(
        'signal-invalid',
        props.position.timestampFrom,
        props.position.timestampTo,
      );

      Logger.log(
        '[OverviewPartAnalysisPeriod] invalidSignalEvent',
        invalidSignalEvent,
      );

      const result = ScoringService.storeEvent(invalidSignalEvent, {
        send: true,
        local: true,
        updating: false,
      });

      if (result) {
        clearOverlappingExclusionZones({ undoId: result.undoRecipe?.id });
      }
    }
  };

  React.useEffect(() => {
    if (props.position.mouseY && props.position.timestampFrom) {
      EventService.dispatch(
        'Overview.HighlightTimestamp',
        props.position.timestampFrom,
      );
    }
  });

  return (
    <Menu
      keepMounted
      className={classes.menuContainer}
      classes={{ paper: classes.menu }}
      open={props.position.mouseY !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        props.position.mouseY !== null && props.position.mouseX !== null
          ? { top: props.position.mouseY, left: props.position.mouseX }
          : undefined
      }
      onClick={(e) => e.preventDefault()}
    >
      {props.position.timestampFrom && props.position.timestampTo && (
        <div data-cy="OverviewExclusionZoneMenu">
          <Typography variant="caption" className={classes.header}>
            From Epoch{' '}
            <b>
              #
              {chartRangeTools.convertToEpoch(props.position.timestampFrom) + 1}
            </b>{' '}
            to{' '}
            <b>
              #{chartRangeTools.convertToEpoch(props.position.timestampTo) + 1}
            </b>
            :
          </Typography>
          <MenuItem onClick={() => addExclusionZone()} className={classes.item}>
            <ListItemIcon className={classes.menuIcon}>
              <LayersClearIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">Mark invalid data</Typography>
          </MenuItem>
          {props.position.existingAreas &&
            props.position.existingAreas.length > 0 && (
              <MenuItem
                onClick={() => clearOverlappingExclusionZones()}
                className={classes.item}
              >
                <ListItemIcon className={classes.menuIcon}>
                  <LayersIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="body2">Clear invalid data</Typography>
              </MenuItem>
            )}
        </div>
      )}

      {!props.position.timestampTo && (
        <div data-cy="JumpToEpochOverviewMenu">
          {props.position.timestampFrom !== null && (
            <Typography variant="caption" className={classes.header}>
              Epoch{' '}
              <b>
                #
                {chartRangeTools.convertToEpoch(props.position.timestampFrom) +
                  1}
              </b>
              :
            </Typography>
          )}
          <MenuItem
            onClick={() => openSignalSheets('RespiratorySheet')}
            className={classes.item}
          >
            <ListItemIcon className={classes.menuIcon}>
              <OpenInNewIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">Open Respiratory</Typography>
          </MenuItem>
          {studyTools
            .getSignalSheetTabs()
            .filter(
              (tab) => tab.customId && tab.partId === props.position.partId,
            )
            .map((tab) => {
              const sheetDefinition = SheetDefinitions.get(
                tab.sheetId,
                tab.customId,
              );

              return (
                <MenuItem
                  key={tab.sheetId + tab.customId}
                  onClick={() => openSignalSheets(tab.sheetId, tab.customId)}
                  className={classes.item}
                >
                  Open {sheetDefinition?.name}
                </MenuItem>
              );
            })}
        </div>
      )}
    </Menu>
  );
};

export default OverviewPartDetailSecondaryMenu;
