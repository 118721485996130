import gql from 'graphql-tag';
import { RecordingId } from './recording';

export interface ScoringInsights {
  recordingId: RecordingId;
  scoringId: string;
  parts?: ScoringInsightsPart[];
}

export interface ScoringInsightsPart {
  beginning: string;
  end: string;
  isSubmitted: boolean;
  analysisPeriods?: ScoringInsightsPeriod[];
  signals: ScoringInsightsSignalInfo[];
}

export interface ScoringInsightsSignalInfo {
  signalType: string;
}

export interface SleepDurations {
  totalSecondsInWake: number;
  totalSecondsInREM: number;
  totalSecondsInNREM: number;
}

export interface ScoringInsightsPeriod {
  beginning: string;
  end: string;
  kpis: {
    AHI?: number;
    ODI?: number;
    sleepEfficiency?: number;
  };
  sleepDurations?: SleepDurations;
}

export interface GetScoringInsightsQueryParameters {
  recordingId: RecordingId;
  scoringId: string;
}

export interface GetScoringInsightsQueryResult {
  scoringInsights: ScoringInsights;
}

export const GET_SCORING_INSIGHTS = gql`
  query ScoringInsights($recordingId: String!, $scoringId: String!) {
    scoringInsights(recordingId: $recordingId, scoringId: $scoringId) {
      recordingId
      scoringId
      parts {
        beginning
        end
        isSubmitted
        analysisPeriods {
          beginning
          end
          kpis {
            AHI
            ODI
            sleepEfficiency
          }
          sleepDurations {
            totalSecondsInWake
            totalSecondsInREM
            totalSecondsInNREM
          }
        }
        signals {
          signalType
        }
      }
    }
  }
`;

export interface SubmitScoringPartMutationParameters {
  part: {
    recordingId: RecordingId;
    scoringId: string;
    beginning: string;
    end: string;
  };
}

export const SUBMIT_SCORING_PART_MUTATION = gql`
  mutation SubmitScoringPart($part: ScoringPart!) {
    submitScoringPart(part: $part)
  }
`;
