import React from 'react';
import { useApolloClient } from '@apollo/client';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import AuthenticationError from '../components/AuthenticationError/AuthenticationError';
import AuthService, { AuthErrorCode } from '../services/authService';
import queryManager from '../services/queryManager';
import Logger from '../utils/logger';
import NetworkError from '../components/NetworkError/NetworkError';

const PrivateRoute = ({ component, ...rest }: RouteProps): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    AuthService.isAuthenticated(),
  );
  const [authError, setAuthError] = React.useState<string | undefined>();
  const [networkError, setNetworkError] = React.useState<string | undefined>();

  const client = useApolloClient();

  React.useEffect(() => {
    if (!AuthService.isAuthenticated()) {
      queryManager.initializeQueryManager(client);
      AuthService.ensureAuthenticated()
        .then(() => {
          const authenticated = AuthService.isAuthenticated();
          Logger.log(
            '[PrivateRoute] Handshake done. Authenticated?',
            authenticated,
          );
          setIsAuthenticated(authenticated);
        })
        .catch((error) => {
          Logger.error('[PrivateRoute] error:', error);
          if (error === AuthErrorCode.NETWORK_ERROR) {
            setNetworkError(error);
          } else if (error !== AuthErrorCode.UNAUTHENTICATED) {
            setAuthError(error);
          }
        });
    }
  });

  if (!component) {
    throw Error('component is undefined');
  }

  const Component = component;
  const render = (
    props: RouteComponentProps<{ [index: string]: string }>,
  ): React.ReactNode => {
    Logger.log('[PrivateRoute] isAuthenticated', isAuthenticated);

    return (
      <>
        {!!Component && isAuthenticated && <Component {...props} />}
        {authError ? <AuthenticationError message={authError} /> : <span />}
        {networkError ? <NetworkError message={networkError} /> : <span />}
      </>
    );
  };

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;
