import {
  createMuiTheme,
  Theme,
  darken,
  lighten,
  fade,
} from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export type ThemeType = 'LIGHT' | 'DARK';
export type ThemeStorage = { [key in ThemeType]: ThemeOptions };
export interface ThemeProvider {
  getType: (themeType: ThemeType) => Theme;
}

export const darkenOrLighten = (
  themeType: ThemeType,
  color: string,
  lightCoefficient: number,
  darkCoefficient: number,
): string => {
  let func = darken;
  let lightCoeff = lightCoefficient;
  let darkCoeff = darkCoefficient;

  if (themeType === 'LIGHT') {
    if (lightCoefficient > 0) {
      func = darken;
    } else {
      func = lighten;
      lightCoeff *= -1;
    }
  } else if (darkCoefficient > 0) {
    func = lighten;
  } else {
    func = darken;
    darkCoeff *= -1;
  }

  const coefficient = themeType === 'LIGHT' ? lightCoeff : darkCoeff;
  return func(color, coefficient);
};

export const generateStripedGradient = (
  baseColor: string,
  lightOpacity: number,
  darkOpacity: number,
): string => {
  const gradient = `repeating-linear-gradient(45deg,${fade(
    baseColor,
    darkOpacity,
  )},${fade(baseColor, darkOpacity)} 5px,${fade(
    baseColor,
    lightOpacity,
  )} 5px,${fade(baseColor, lightOpacity)} 10px)`;
  return gradient;
};

declare module '@material-ui/core/styles/createMuiTheme' {
  // eslint-disable-next-line no-shadow
  interface Theme {
    id: ThemeType;
    variables: {
      pageMaxWidth: number;
    };
    colors: {
      stats: {
        ok: string;
        mild: string;
        moderate: string;
        severe: string;
        unknown: string;
      };
      statuses: {
        success: string;
        error: string;
        neutral: string;
      };
      chart: {
        line: {
          default: string;
          respiratory: string;
          heart: string;
        };
        grid: string;
        label: string;
        axisLabel: string;
        loading: {
          background: string;
          color: string;
        };
      };
      navBar: {
        border: string;
      };
      modal: {
        backgroundColor: string;
        color: string;
      };
      sleep: {
        rem: string;
        wake: string;
        nrem: string;
      };
      text: {
        lighter: string;
        link: string;
      };
    };
  }
  // allow configuration using `createMuiTheme`
  // eslint-disable-next-line no-shadow
  interface ThemeOptions {
    id?: ThemeType;
    variables?: {
      pageMaxWidth?: number;
    };
    colors?: {
      stats?: {
        ok?: string;
        mild?: string;
        moderate?: string;
        severe?: string;
        unknown?: string;
      };
      statuses?: {
        success?: string;
        error?: string;
        neutral?: string;
      };
      chart?: {
        line?: {
          default?: string;
          respiratory?: string;
          heart?: string;
        };
        grid?: string;
        label?: string;
        axisLabel?: string;
        loading?: {
          background?: string;
          color?: string;
        };
      };
      navBar?: {
        border?: string;
      };
      modal?: {
        backgroundColor?: string;
        color?: string;
      };
      text?: {
        lighter?: string;
        link?: string;
      };
    };
  }
}

export const ZIndex = {
  Baseline: 1,
  SignalName: 2,
  AboveBaseline: 2,
  Scoring: 3,
  EventPopup: 4,
  Popup: 2000,
};

const themeCreator: ThemeStorage = {
  LIGHT: {
    id: 'LIGHT',
    variables: {
      pageMaxWidth: 1170,
    },
    palette: {
      primary: {
        light: lighten('#5FA764', 0.1),
        main: '#5FA764',
        dark: darken('#5FA764', 0.1),
        contrastText: '#fff',
      },
      secondary: {
        light: '#ffffff',
        main: '#e0e0e0',
        dark: '#d5d5d5',
        contrastText: '#000000',
      },
      background: {
        default: '#FAFAFA',
        paper: '#FFF',
      },
    },
    colors: {
      statuses: {
        success: '#5FA764',
        error: '#ef5350',
        neutral: '#29b6f6',
      },
      stats: {
        ok: '#5FA764',
        mild: '#ffd76b',
        moderate: '#ff9800',
        severe: '#ef5350',
        unknown: '#e0e0e0',
      },
      chart: {
        line: {
          default: '#686473',
          respiratory: '#1565C0',
          heart: '#B00020',
        },
        grid: '#CCD6EB',
        label: '#333',
        axisLabel: '#666',
        loading: {
          background: darken('#ffffff', 0.1),
          color: '#212121',
        },
      },
      navBar: {
        border: '#dbdbdb',
      },
      modal: {
        backgroundColor: '#FFF',
        color: '#212121',
      },
      text: {
        lighter: '#808080',
        link: '#5FA764',
      },
    },
    overrides: {
      MuiSnackbarContent: {
        root: {
          '&[class*="root"]': {
            top: 500,
          },
        },
      },
    },
  },
  DARK: {
    id: 'DARK',
    variables: {
      pageMaxWidth: 1170,
    },
    palette: {
      primary: {
        light: lighten('#333333', 0.1),
        main: lighten('#333333', 0.15),
        dark: lighten('#333333', 0.2),
        contrastText: '#E1E1E1',
      },
      secondary: {
        light: lighten('#333333', 0.1),
        main: lighten('#333333', 0.15),
        dark: lighten('#333333', 0.2),
        contrastText: '#E1E1E1',
      },
      background: {
        default: '#121212',
        paper: '#333333',
      },
    },
    colors: {
      statuses: {
        success: '#66BB6A',
        error: '#ef5350',
        neutral: '#29b6f6',
      },
      stats: {
        ok: '#5FA764',
        mild: '#ffd76b',
        moderate: '#ff9800',
        severe: '#ef5350',
        unknown: '#5F5F5F',
      },
      chart: {
        line: {
          default: '#cfcfcf',
          respiratory: '#4BB2F9',
          heart: '#CF6679',
        },
        grid: '#5F5F5F',
        label: '#757575',
        axisLabel: '#666',
        loading: {
          background: lighten('#333333', 0.1),
          color: '#E1E1E1',
        },
      },
      navBar: {
        border: 'transparent',
      },
      modal: {
        backgroundColor: lighten('#333333', 0.15),
        color: '#E1E1E1',
      },
      text: {
        lighter: '#808080',
        link: '#5FA764',
      },
    },
  },
};

const themeProvider: ThemeProvider = {
  getType: (themeType: ThemeType) => createMuiTheme(themeCreator[themeType]),
};

export default themeProvider;
