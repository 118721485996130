import gql from 'graphql-tag';

export type ExportId = string;

export interface GetExportStatusQueryParameters {
  exportId: ExportId;
}

export interface ExportStatus {
  status: 'Requested' | 'Exported' | 'Failed';
  id: ExportId;
  url?: string;
}

export interface GetExportStatusQueryResult {
  recordingExport: ExportStatus;
}

export const GET_EXPORT_STATUS_QUERY = gql`
  query GetExportStatus($exportId: String!) {
    recordingExport(exportId: $exportId) {
      id
      status
      url
    }
  }
`;
