import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import Logger from '../../utils/logger';
import themeProvider from '../../utils/theme';
import OverviewChart from '../Chart/OverviewChart/OverviewChart';
import {
  calculateOverviewChartsSize,
  overviewChartDefinitions,
  OverviewGraphDefinition,
} from '../Chart/OverviewChart/overviewChartDefinitions';
import OverviewChartName from '../Chart/OverviewChart/OverviewChartName';
import OverviewChartXAxis from '../Chart/OverviewChart/OverviewChartXAxis';
import OverviewPartAnalysisPeriod from './OverviewPartAnalysisPeriod';
import { OverviewPartMenuPos } from './OverviewPartDetailSecondaryMenu';
import { RecordingPartData } from './OverviewParts';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    position: 'relative',
    background: `${theme.palette.background.paper} !important`,
    '@media print': {
      boxShadow: 'none',
      background: `${
        themeProvider.getType('LIGHT').palette.background.paper
      } !important`,
      color: theme.palette.getContrastText(
        themeProvider.getType('LIGHT').palette.background.paper,
      ),
    },
  },
  narrow: {
    width: '98%',
    cursor: 'initial',
  },
  graphArea: {
    display: 'grid',
    gridTemplateColumns: '140px auto',
    alignItems: 'center',
    '&:last-child': {
      paddingBottom: theme.spacing(2),
      borderBottom: 'none',
    },
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    opacity: 1,
    transition: '0.1s all linear',
  },
  hidden: {
    opacity: 0,
  },
}));

const OverviewPartDetail = (props: {
  data: RecordingPartData;
  forReports?: boolean;
  handleMenuOpen?: (pos: OverviewPartMenuPos) => void;
  setHighlightPartId?: React.Dispatch<React.SetStateAction<number | undefined>>;
}): JSX.Element => {
  const classes = useStyles();

  const overviewPartDetailHeight =
    calculateOverviewChartsSize({ forDeviceType: props.data.deviceType }) + 40;

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = React.useState(false);
  const [definitions, setDefinitions] = React.useState<
    OverviewGraphDefinition[]
  >([]);

  const [chartWidth, setChartWidth] = React.useState<number | undefined>();
  const [chartVisible, setChartVisible] = React.useState(false);

  const onResize = () => {
    Logger.log('[OverviewPartDetail] onResize', chartWidth);
    const clientWidth = containerRef?.current?.clientWidth;
    const newWidth = clientWidth ? clientWidth - 120 : 0;
    Logger.log('[OverviewPartDetail] newWidth', newWidth);
    setChartWidth(newWidth);
    setChartVisible(true);
  };

  React.useEffect(() => {
    Logger.log('[OverviewPartDetail] useEffect');
    Logger.log(
      '[OverviewPartDetail] props.data.deviceType',
      props.data.deviceType,
    );
    setDefinitions(
      Array.from(overviewChartDefinitions.values()).filter(
        (def) =>
          !def.getDeviceAvailability ||
          (props.data.deviceType &&
            def.getDeviceAvailability().includes(props.data.deviceType)),
      ),
    );
    setLoaded(true);

    setTimeout(() => onResize(), 500);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <>
      {loaded && (
        <div
          ref={containerRef}
          className={clsx(classes.root, { [classes.narrow]: props.forReports })}
          style={{ height: overviewPartDetailHeight }}
          onMouseOver={() => {
            if (props.setHighlightPartId) {
              props.setHighlightPartId(props.data.partId);
            }
          }}
        >
          {definitions.map((definition) => {
            return (
              <div key={definition.type} className={classes.graphArea}>
                <OverviewChartName
                  graphDefinition={definition}
                  availableSignals={props.data.availableSignals}
                />
                <div
                  className={clsx(classes.container, {
                    [classes.hidden]: !chartVisible,
                  })}
                >
                  {!definition.customComponent ? (
                    definition.isSignalGraph && (
                      <OverviewChart
                        graphDefinition={definition}
                        partData={props.data}
                        chartWidth={chartWidth}
                      />
                    )
                  ) : (
                    <definition.customComponent
                      graphDefinition={definition}
                      partData={props.data}
                      chartWidth={chartWidth}
                    />
                  )}
                </div>
              </div>
            );
          })}

          <div
            className={clsx(classes.graphArea, {
              [classes.hidden]: !chartVisible,
            })}
          >
            <div />
            <OverviewChartXAxis partData={props.data} chartWidth={chartWidth} />
          </div>
          <OverviewPartAnalysisPeriod
            part={props.data}
            handleMenuOpen={props.handleMenuOpen}
          />
        </div>
      )}
    </>
  );
};

export default OverviewPartDetail;
