/* eslint-disable */
////////////////////////////
// Temporal fix because of this issue:
//   https://github.com/highcharts/highcharts/issues/11353
////////////////////////////

const PassiveEvents = function(H: any) {
  H.addEvent = function<T>(el: any, type: string, fn: any, options: any): Function {
    // Logger.debug('[PassiveEvents] Adding event');
    // Logger.debug('[PassiveEvents] --> el:', el);
    // Logger.debug('[PassiveEvents] --> type:', type);
    // Logger.debug('[PassiveEvents] --> fn:', fn);
    // Logger.debug('[PassiveEvents] --> options:', options);
    /* eslint-enable valid-jsdoc */
    let events,
      addEventListener = (el as any).addEventListener || H.addEventListenerPolyfill;
    // If we're setting events directly on the constructor, use a separate
    // collection, `protoEvents` to distinguish it from the item events in
    // `hcEvents`.
    if (typeof el === 'function' && el.prototype) {
      events = el.prototype.protoEvents = el.prototype.protoEvents || {};
    } else {
      events = (el as any).hcEvents = (el as any).hcEvents || {};
    }
    // Allow click events added to points, otherwise they will be prevented by
    // the TouchPointer.pinch function after a pinch zoom operation (#7091).
    if (H.Point && el instanceof H.Point && (el as any).series && (el as any).series.chart) {
      (el as any).series.chart.runTrackerClick = true;
    }
    // Handle DOM events
    if (addEventListener) {
      addEventListener.call(el, type, fn, { passive: true, capture: false });
    }
    if (!events[type]) {
      events[type] = [];
    }
    const eventObject = {
      fn,
      order: !!options && typeof options.order === 'number' ? options.order : Infinity,
    };
    events[type].push(eventObject);
    // Order the calls
    events[type].sort(function(a: any, b: any): number {
      return a.order - b.order;
    });
    // Return a function that can be called to remove this event.
    return function(): void {
      H.removeEvent(el, type, fn);
    };
  };
};

export default PassiveEvents;
