import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import cssClasses from '../SignalSheet/SignalSheet.module.scss';
import SignalViewerLoading from './SignalViewerLoading';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      overflow: 'hidden',
      overscrollBehavior: 'none',
    },
  },
  signal: {
    flex: 1,
    margin: theme.spacing(1, 0),
    borderRadius: 5,
  },
  toolbar: {
    padding: theme.spacing(1.5, 2, 0.5, 2),
  },
  signals: {
    padding: theme.spacing(1.5, 2, 0.5, 2),
    height: '100%',
  },
}));

const SignalSheetLoading = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <main className={cssClasses.content}>
        <div className={classes.toolbar}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Skeleton animation="wave" height={70} width="70%" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton animation="wave" height={70} />
            </Grid>
          </Grid>
        </div>
        <SignalViewerLoading signalsTotal={8} />
      </main>
    </>
  );
};

export default SignalSheetLoading;
