import React from 'react';

interface FlexContainerProps {
  alignRight?: boolean;
  column?: boolean;
  children: JSX.Element | JSX.Element[];
  styles?: React.CSSProperties;
  'data-cy'?: string;
}
const FlexContainer = (props: FlexContainerProps): JSX.Element => (
  <div
    style={{
      display: 'flex',
      flexDirection: props.column ? 'column' : 'initial',
      justifyContent: props.alignRight ? 'flex-end' : 'initial',
      alignItems: props.column && props.alignRight ? 'flex-end' : 'initial',
      height: '100%',
      ...props.styles,
    }}
    data-cy={props['data-cy']}
  >
    {props.children}
  </div>
);
export default FlexContainer;
