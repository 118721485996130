import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import * as _ from 'underscore';
import SheetToolbar from '../SheetToolbar/SheetToolbar';
import TouchNavigation from '../TouchNavigation/TouchNavigation';
import { SignalSheetProps } from './interfaces/signal-sheet';
import sheetTools from './sheetTools';
import cssClasses from './SignalSheet.module.scss';
import Logger from '../../utils/logger';
import EventService from '../../services/eventService';
import TabSyncService from '../../services/tabSyncService';
import chartEvents from './chartEvents';
import Analytics from '../../services/analytics';
import SignalEventDetail from '../SignalEventDetail/SignalEventDetail';
import SignalViewer from './SignalViewer';
import SleepStageService from '../../services/sleepStageService';
import ScoringCanvas from '../Chart/ScoringCanvas/ScoringCanvas';
import PositionLabelingService from '../../services/positionLabelingService';
import SignalRenderingService from '../../services/signalRenderingService';
import chartRangeTools from './chartRangeTools';
import { TabSyncSheetExtremesInfo } from '../../interfaces/tab-sync-service';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      overflow: 'hidden',
      overscrollBehavior: 'none',
    },
  },
  sheet: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 2),
    overflow: 'hidden',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    '&:focus': {
      outlineWidth: 0,
    },
  },
}));

const SignalSheet = (props: SignalSheetProps): JSX.Element => {
  Logger.log('[SignalSheet]');
  const containerRef = React.createRef<HTMLDivElement>();

  const classes = useStyles();

  const signals = props.sheetDefinition?.signals;

  const isTouchDevice = 'ontouchstart' in document.documentElement;

  sheetTools.initializeSheetTools(props.sheetDefinition);
  sheetTools.setSheetContainer(containerRef);

  sheetTools.overrideHighchartsReset();
  sheetTools.overrideHighchartsHideOverlappingLabels();
  sheetTools.overrideHighchartsMethods();

  PositionLabelingService.processPlotBands();

  const onWheel = _.throttle(chartEvents.moveScroll, 150);
  const onFocus = TabSyncService.setCurrentSheetAsLeader;
  const autoFit = _.debounce(sheetTools.autoFitAll, 250);

  useEffect(() => {
    Logger.log('[SignalSheet] useEffect');
    Logger.log('[SignalSheet] sheetDefinition:', props.sheetDefinition);

    const container = containerRef.current;

    Logger.log('[SignalSheet] sheetId:', props.sheetDefinition.type);
    Analytics.track.page('Signal Sheet', props.sheetDefinition.type);

    sheetTools.setSheetContainer(containerRef);

    container?.addEventListener('wheel', onWheel);
    document.addEventListener(
      'touchstart',
      chartEvents.handleTouchStart,
      false,
    );
    document.addEventListener('touchmove', chartEvents.handleTouchMove, false);
    window.addEventListener('resize', autoFit);
    window.addEventListener('focus', onFocus);

    const callbackIds = [
      EventService.subscribe<TabSyncSheetExtremesInfo>(
        'TabSyncExtremes',
        chartEvents.onTabSyncExtremes,
      ),
      EventService.subscribe('KeyboardShortcut.MoveBack', chartEvents.moveLeft),
      EventService.subscribe(
        'KeyboardShortcut.MoveForward',
        chartEvents.moveRight,
      ),
      EventService.subscribe(
        'KeyboardShortcut.MoveBackFullPage',
        chartEvents.moveLeftFullPage,
      ),
      EventService.subscribe(
        'KeyboardShortcut.MoveForwardFullPage',
        chartEvents.moveRightFullPage,
      ),
      EventService.subscribe('KeyboardShortcut.JumpToBeginning', () =>
        sheetTools.selectEpoch(chartRangeTools.getFirstPartEpoch()),
      ),
      EventService.subscribe('KeyboardShortcut.JumpToEnd', () =>
        sheetTools.selectEpoch(chartRangeTools.getLastPartEpoch()),
      ),
      EventService.subscribe(
        'KeyboardShortcut.SelectPreviousEpoch',
        sheetTools.selectPreviousEpoch,
      ),
      EventService.subscribe(
        'KeyboardShortcut.SelectNextEpoch',
        sheetTools.selectNextEpoch,
      ),
      EventService.subscribe('Keyboard.KeyReleased', () =>
        chartEvents.setMovingStatus(false),
      ),
      ...SleepStageService.registerKeyBindings(),
    ];

    onFocus();
    autoFit();

    SignalRenderingService.enableSheetIntegrityCheck();

    return () => {
      Logger.log('[SignalSheet] cleanup');

      container?.removeEventListener('wheel', onWheel);
      document.removeEventListener(
        'touchstart',
        chartEvents.handleTouchStart,
        false,
      );
      document.removeEventListener(
        'touchmove',
        chartEvents.handleTouchMove,
        false,
      );
      window.removeEventListener('resize', autoFit);
      window.removeEventListener('focus', onFocus);

      EventService.unsubscribe(callbackIds);
      SignalRenderingService.disableSheetIntegrityCheck();
      sheetTools.restoreHighchartsHideOverlappingLabels();
      Logger.log('[SignalSheet] cleanup done');
    };
  });

  return (
    <>
      <main className={cssClasses.content}>
        <SignalEventDetail />
        <SheetToolbar recording={props.recording} />

        <Paper
          data-cy="SignalSheetPaper"
          ref={containerRef}
          className={classes.sheet}
          key={props.sheetDefinition.type}
        >
          {signals && <ScoringCanvas />}
          {signals && (
            <SignalViewer signals={signals} recording={props.recording} />
          )}
          {isTouchDevice ? (
            <TouchNavigation
              moveLeft={chartEvents.moveLeft}
              moveRight={chartEvents.moveRight}
              setMovingStatus={chartEvents.setMovingStatus}
            />
          ) : null}
        </Paper>
      </main>
    </>
  );
};

export default React.memo(SignalSheet, () => true);
