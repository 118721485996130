import React from 'react';
import { makeStyles, Drawer, Typography, Paper } from '@material-ui/core';

import _ from 'underscore';
import EventService from '../../services/eventService';
import UndoService, { UndoRecipe } from '../../services/undoService';
import Logger from '../../utils/logger';

export interface EventViewerProps {
  setEventViewerOpened: React.Dispatch<React.SetStateAction<boolean>>;
  eventViewerOpened: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: 350,
    padding: theme.spacing(2),
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  item: {
    backgroundColor: theme.colors.modal.backgroundColor,
    color: theme.colors.modal.color,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

interface UndoHistoryProps {
  undoHistoryOpened: boolean;
  setUndoHistoryOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const UndoHistory = (props: UndoHistoryProps): JSX.Element => {
  const classes = useStyles();

  const [undoOperations, setUndoOperations] = React.useState<UndoRecipe[]>([]);
  const [redoOperations, setRedoOperations] = React.useState<UndoRecipe[]>([]);

  const refresh = _.throttle(
    () => {
      Logger.log('[UndoHistory] refreshing');
      setUndoOperations([...UndoService.getUndoHistory()]);
      setRedoOperations([...UndoService.getRedoHistory()]);
    },
    100,
    { leading: true },
  );

  React.useEffect(() => {
    if (props.undoHistoryOpened) {
      const callbackId = EventService.subscribe(
        ['KeyboardShortcut.UndoAction', 'KeyboardShortcut.RedoAction'],
        () => refresh(),
      );

      refresh();

      return () => {
        EventService.unsubscribe(callbackId);
      };
    }

    return undefined;
  }, [props.undoHistoryOpened]);

  return (
    <Drawer
      anchor="right"
      open={props.undoHistoryOpened}
      closeAfterTransition
      onClose={() => {
        props.setUndoHistoryOpened(false);
      }}
    >
      <div className={classes.container}>
        <Typography align="center" variant="h5">
          Undo History
        </Typography>
        <Typography align="center" variant="caption" component="div">
          This feature is only intended for development
        </Typography>

        <div className={classes.list}>
          {props.undoHistoryOpened && (
            <>
              <div>
                <Typography>Undo</Typography>
                {undoOperations.map((op) => (
                  <Paper key={op.id} className={classes.item}>
                    <div>{op.id}</div>
                    <div>{op.type}</div>
                  </Paper>
                ))}
              </div>
              <div>
                <Typography>Redo</Typography>
                {redoOperations.map((op) => (
                  <Paper key={op.id} className={classes.item}>
                    <div>{op.id}</div>
                    <div>{op.type}</div>
                  </Paper>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default UndoHistory;
