import gql from 'graphql-tag';
import { Recording } from './recording';

export interface GetRecordingListQueryResult {
  recordings: Recording[];
}

export const GET_RECORDINGS = gql`
  query Recordings {
    recordings {
      recordingId
      status
      type
      startTime
      endTime
      uploadedDate
      patient {
        MR
      }
      deviceInfo {
        type
      }
    }
  }
`;
