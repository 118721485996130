import { Fade, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import _ from 'underscore';
import { Recording } from '../../queries/recording';
import Logger from '../../utils/logger';
import {
  MarkerDefinitionTools,
  MarkerGroups,
} from '../Chart/SignalChart/markerConstants';
import chartRangeTools from '../SignalSheet/chartRangeTools';
import OverviewSleepWidgetTable from './OverviewSleepWidgetTable';
import { ScoringInsights, SleepDurations } from '../../queries/scoringInsights';
import { Report } from '../../queries/report';
import reportTools from '../Report/reportTools';
import EventService from '../../services/eventService';
import { ScoringInsightsUpdatedResult } from '../../queries/subscriptions/scoringInsightsUpdated';
import ThreeLoadingDots from '../Shared/ThreeLoadingDots';
import Hidden from '../Shared/Hidden';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    display: 'flex',
  },
  graphContainer: {
    position: 'relative',
  },
  title: {
    left: 50,
    right: 50,
    bottom: 26,
    position: 'absolute',
    lineHeight: '12px',
    textAlign: 'center',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
  onlyTitle: {
    bottom: 12,
  },
  part: {
    left: 50,
    right: 50,
    bottom: 11,
    position: 'absolute',
    lineHeight: '12px',
    textAlign: 'center',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
  missing: {
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  missingText: {
    fontSize: 12,
    textAlign: 'center',
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
}));

interface OverviewSleepWidgetProps {
  recording?: Recording;
  withTable?: boolean;
  scoringInsights?: ScoringInsights;
  report?: Report;
  highlightPartId?: number;
}

export interface SleepStageChartDataItem {
  name: string;
  count: number;
  rawValue: number;
  color: string;
  shortName: string;
  percentage: number;
}

const OverviewSleepWidget = (props: OverviewSleepWidgetProps): JSX.Element => {
  // Logger.log('OverviewSleepWidget init', props.recording);

  const classes = useStyles();
  const [visible, setVisible] = React.useState(false);
  const [sleepData, setSleepData] = React.useState<SleepStageChartDataItem[]>(
    [],
  );
  const [dirty, setDirty] = React.useState(false);

  const [chartOptions, setChartOptions] = React.useState<Highcharts.Options>({
    chart: {
      plotBorderWidth: 0,
      plotShadow: false,
      margin: 0,
      width: 180,
      height: 110,
      spacingTop: 0,
      spacingBottom: 0,
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      style: {
        fontFamily: 'Roboto',
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      formatter() {
        const { point, y } = this;

        return [
          `<b>${point.name}</b>`,
          chartRangeTools.calculateDuration(0, y, { short: false }),
        ].join('<br />');
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: -20,
          style: {
            fontWeight: 'bold',
            color: 'white',
            textOverflow: 'initial',
          },
          overflow: 'allow',
        },
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '110%'],
        size: '200%',
        animation: {
          duration: 250,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: 'pie',
        innerSize: '50%',
        data: [],
      },
    ],
  });

  const getSleepDurations = (opts?: {
    withUpdatedInsights?: ScoringInsights;
  }): SleepDurations | undefined => {
    if (props.scoringInsights) {
      const parts = opts?.withUpdatedInsights
        ? opts.withUpdatedInsights.parts || []
        : props.scoringInsights.parts || [];

      const sortedParts = parts.sort((a, b) => {
        const dateA = new Date(a.beginning);
        const dateB = new Date(b.beginning);
        return dateA.valueOf() - dateB.valueOf();
      });

      Logger.log('OverviewSleepWidget getSleepDurations');
      Logger.log('OverviewSleepWidget highlightPartId', props.highlightPartId);
      Logger.log('OverviewSleepWidget parts', parts);
      Logger.log('OverviewSleepWidget sortedParts', sortedParts);
      Logger.log('OverviewSleepWidget insights', props.scoringInsights);

      if (props.highlightPartId) {
        const partId = props.highlightPartId - 1;
        const isSingleAnalysisPeriod =
          sortedParts[partId].analysisPeriods?.length === 1;
        const statsPeriod = isSingleAnalysisPeriod
          ? sortedParts[partId].analysisPeriods?.[0]
          : undefined;

        if (statsPeriod?.sleepDurations) {
          const originalPart = props.scoringInsights?.parts?.find(
            (part) =>
              part.beginning === sortedParts[partId].beginning &&
              part.end === sortedParts[partId].end,
          );
          if (originalPart) {
            const originalStats = isSingleAnalysisPeriod
              ? originalPart.analysisPeriods?.[0]
              : undefined;

            if (originalStats) {
              originalStats.sleepDurations = { ...statsPeriod.sleepDurations };
            }
          }

          return statsPeriod.sleepDurations;
        }
      } else {
        let wakeDuration = 0;
        let remDuration = 0;
        let nremDuration = 0;

        sortedParts.forEach((part) => {
          const isSingleAnalysisPeriod = part.analysisPeriods?.length === 1;
          const statsPeriod = isSingleAnalysisPeriod
            ? part.analysisPeriods?.[0]
            : undefined;
          if (statsPeriod?.sleepDurations) {
            wakeDuration += statsPeriod?.sleepDurations.totalSecondsInWake;
            remDuration += statsPeriod?.sleepDurations.totalSecondsInREM;
            nremDuration += statsPeriod?.sleepDurations.totalSecondsInNREM;
          }
        });

        return {
          totalSecondsInNREM: nremDuration,
          totalSecondsInREM: remDuration,
          totalSecondsInWake: wakeDuration,
        };
      }
    } else if (props.report) {
      Logger.log('OverviewSleepWidget props.report');
      const wakeDurationMinutes = reportTools.getParameterValueNumber(
        props.report.data,
        'AwakeDuration',
      );
      const remDurationMinutes = reportTools.getParameterValueNumber(
        props.report.data,
        'SleepTotalREMDuration',
      );
      const nremDurationMinutes = reportTools.getParameterValueNumber(
        props.report.data,
        'SleepTotalNREMDuration',
      );
      Logger.log('OverviewSleepWidget wakeDuration', wakeDurationMinutes);

      return {
        totalSecondsInNREM: nremDurationMinutes ? nremDurationMinutes * 60 : 0,
        totalSecondsInREM: remDurationMinutes ? remDurationMinutes * 60 : 0,
        totalSecondsInWake: wakeDurationMinutes ? wakeDurationMinutes * 60 : 0,
      };
    }

    return undefined;
  };

  const calculateData = (opts?: {
    withUpdatedInsights?: ScoringInsights;
  }): SleepStageChartDataItem[] => {
    const sleepStageTypes = MarkerGroups.getEventTypes(['Sleep Stage']);

    const data: SleepStageChartDataItem[] = [];

    const sleepDurations = getSleepDurations({
      withUpdatedInsights: opts?.withUpdatedInsights,
    });
    Logger.log('OverviewSleepWidget sleepDurations', sleepDurations);

    if (sleepDurations) {
      const totalDuration =
        sleepDurations.totalSecondsInNREM +
        sleepDurations.totalSecondsInREM +
        sleepDurations.totalSecondsInWake;

      const sleepStageTypeDefinitions =
        MarkerDefinitionTools.getFromMarkerTypes(sleepStageTypes);
      sleepStageTypeDefinitions.forEach((definition) => {
        if (
          definition.type === 'sleep-wake' ||
          definition.type === 'sleep-nrem' ||
          definition.type === 'sleep-rem'
        ) {
          let seconds = 0;
          let percentage = 0;
          if (definition.type === 'sleep-wake') {
            seconds = sleepDurations.totalSecondsInWake;
            if (props.report) {
              percentage =
                reportTools.getParameterValueNumber(
                  props.report.data,
                  'SleepTotalWakePercentage',
                ) || 0;
            }
          } else if (definition.type === 'sleep-rem') {
            seconds = sleepDurations.totalSecondsInREM;
            if (props.report) {
              percentage =
                reportTools.getParameterValueNumber(
                  props.report.data,
                  'SleepTotalREMPercentage',
                ) || 0;
            }
          } else if (definition.type === 'sleep-nrem') {
            seconds = sleepDurations.totalSecondsInNREM;
            if (props.report) {
              percentage =
                reportTools.getParameterValueNumber(
                  props.report.data,
                  'SleepTotalNREMPercentage',
                ) || 0;
            }
          }

          if (seconds > 0) {
            data.push({
              name: definition.name,
              shortName: definition.shortName,
              color: definition.color,
              count: Math.ceil(seconds) * 1000,
              rawValue: seconds,
              percentage: props.report
                ? percentage
                : (seconds * 100) / totalDuration,
            });
          }
        }
      });
    }

    if (data.length > 0) {
      setVisible(true);
    } else if (!opts?.withUpdatedInsights) {
      setVisible(() => false);
    }

    return data;
  };

  const onRedraw = _.throttle(
    (opts?: { withUpdatedInsights?: ScoringInsights }) => {
      Logger.log('OverviewSleepWidget ScoringChanged', props.recording);
      if (props.recording) {
        const data: SleepStageChartDataItem[] = calculateData({
          withUpdatedInsights: opts?.withUpdatedInsights,
        });
        setSleepData(data);

        if (data.length > 0) {
          setDirty(false);
          setChartOptions((old) => ({
            ...old,
            series: [
              {
                data: data.map((stage) => ({
                  name: stage.name,
                  y: stage.count,
                  color: stage.color,
                })),
                type: 'pie',
              },
            ],
          }));
        }

        Logger.log('OverviewSleepWidget', data);
      }
    },
    500,
  );

  React.useEffect(() => {
    Logger.log('OverviewSleepWidget useEffect');
    onRedraw();

    const cbId = [
      EventService.subscribe(
        'ScoringInsightsUpdated',
        (data: ScoringInsightsUpdatedResult) => {
          const scoringInsights = data.scoringInsightsUpdated;
          onRedraw({ withUpdatedInsights: scoringInsights });
        },
      ),
      EventService.subscribe(
        ['Scoring.AnalysisPeriodChanged', 'Scoring.ExclusionZoneChanged'],
        () => {
          setDirty(true);
        },
      ),
    ];

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, [
    props.recording,
    props.scoringInsights,
    props.report,
    props.highlightPartId,
  ]);

  return (
    <>
      <Fade in={visible}>
        <div data-cy="OverviewSleepWidget" className={classes.root}>
          <div
            className={clsx({
              [classes.container]: props.withTable,
            })}
          >
            {props.withTable && sleepData && (
              <OverviewSleepWidgetTable sleepData={sleepData} />
            )}

            <div className={classes.graphContainer}>
              <Typography
                className={clsx(classes.title, {
                  [classes.onlyTitle]: props.withTable,
                })}
              >
                Sleep
              </Typography>
              {props.scoringInsights && (
                <Typography className={classes.part}>
                  {(() => {
                    if (dirty) return <ThreeLoadingDots />;
                    if (props.highlightPartId !== undefined)
                      return `Part ${props.highlightPartId}`;
                    return 'Total';
                  })()}
                </Typography>
              )}
              <HighchartsReact
                key="SleepWidget"
                highcharts={Highcharts}
                allowChartUpdate
                options={chartOptions}
              />
              {sleepData.map((data) => (
                <Hidden
                  key={data.name}
                  data-cy={`SleepWidget-DurationMin${data.name}`}
                >
                  <>{parseFloat((data.rawValue / 60).toFixed(1))}</>
                </Hidden>
              ))}
            </div>
          </div>
        </div>
      </Fade>
      {!visible && !props.withTable && (
        <div className={classes.missing}>
          <Typography className={classes.missingText}>
            Missing sleep stages data
          </Typography>
        </div>
      )}
    </>
  );
};

export default OverviewSleepWidget;
