import { Theme } from '@material-ui/core';
import { OnLoadChartFunction } from '../../../interfaces/chart-props';
import chartRangeTools from '../../SignalSheet/chartRangeTools';
import chartTools from './chartTools';
import chartEvents from '../../SignalSheet/chartEvents';
import sheetTools from '../../SignalSheet/sheetTools';
import { SignalDefinition } from '../../../interfaces/sheet-definition';

export const ChartOptions = {
  signalNameWidth: 35,
};

export default function getChartOptions(
  signalInfo: SignalDefinition,
  theme: Theme,
  onSignalChartLoad: OnLoadChartFunction,
): Highcharts.Options {
  chartTools.registerSignalInfo(signalInfo);

  const chartOptions: Highcharts.Options = {
    chart: {
      marginLeft: ChartOptions.signalNameWidth, // Keep all charts left aligned
      marginTop: 0,
      width: sheetTools.getSheetContainerWidth(),
      height: sheetTools.getHeightForSignal(signalInfo.type),
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      plotBorderWidth: 0,
      animation: false,
      selectionMarkerFill: 'rgba(10.9%, 41.1%, 69.8%, 0.4)',
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      zoomType: 'x',
      events: {
        selection: (event) => chartEvents.onSelection(event, signalInfo.type),
        click: (event) => chartEvents.onClick(event, signalInfo.type),
        load() {
          chartEvents.onLoad(signalInfo.type, this, { isSignalChart: true });
          onSignalChartLoad(this);
        },
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    accessibility: {
      keyboardNavigation: {
        enabled: true,
      },
    },
    loading: {
      style: {
        background: theme.colors.chart.loading.background,
        color: theme.colors.chart.loading.color,
      },
    },
    xAxis: {
      opposite: true,
      visible: true,
      crosshair: false,
      gridLineWidth: 1,
      gridLineColor: theme.colors.chart.grid,
      lineWidth: 0,
      tickColor: theme.colors.chart.grid,
      showFirstLabel: true,
      showLastLabel: true,
      ordinal: false,
      tickPositioner: () => chartRangeTools.getTicks(),
      labels: {
        enabled: false,
      },
      min: chartRangeTools.getCurrentExtremes().min,
      max: chartRangeTools.getCurrentExtremes().max,
      plotBands: [],
    },
    yAxis: [
      {
        title: {
          text: '',
          style: {
            backgroundColor: theme.palette.background.paper,
            border: `1.5px solid ${theme.palette.background.paper}`,
            width: 90,
            padding: '10px 0',
          },
          useHTML: true,
          offset: 6,
        },
        alignTicks: false,
        ceiling: signalInfo.cropMax || signalInfo.yMax,
        floor: signalInfo.yMin,
        max: signalInfo.yMax,
        min: signalInfo.yMin,
        lineColor: theme.colors.chart.grid,
        opposite: false,
        gridLineColor: 'transparent',
        tickAmount: 0,
        labels: {
          enabled: false,
        },
      },
    ],
    tooltip: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    series: [
      {
        data: [0, 0],
        name: signalInfo.type,
        type: 'line',
        selected: false,
        enableMouseTracking: false,
        dataGrouping: { enabled: false },
        lineWidth: 1,
        yAxis: 0,
        cropThreshold: 1,
        color: (() => {
          if (signalInfo.respiratory)
            return theme.colors.chart.line.respiratory;
          if (signalInfo.heart) return theme.colors.chart.line.heart;
          return theme.colors.chart.line.default;
        })(),
        marker: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
        turboThreshold: 1,
        gapSize: signalInfo.showGaps ? 30000 : 1000,
        gapUnit: 'value',
      },
    ],
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
  };

  return chartOptions;
}
