import React from 'react';
import { Paper, makeStyles, fade } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Recording } from '../../queries/recording';
import AttributeListItem from '../Shared/AttributeListItem';
import themeProvider from '../../utils/theme';
import { generateTLocale, LanguageSupportedLocale } from '../../i18n';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.getContrastText(theme.palette.background.paper),
    padding: theme.spacing(1, 0, 1, 2),

    '&.MuiPaper-outlined': {
      border: 'none',
      borderLeft: `1px solid ${fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.15,
      )}`,
      borderRadius: 0,
      '@media print': {
        borderColor: fade(
          theme.palette.getContrastText(
            themeProvider.getType('LIGHT').palette.background.paper,
          ),
          0.15,
        ),
      },
    },

    '@media print': {
      background: `${
        themeProvider.getType('LIGHT').palette.background.paper
      } !important`,
      color: theme.palette.getContrastText(
        themeProvider.getType('LIGHT').palette.background.paper,
      ),
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'min-content max-content',
    alignItems: 'center',
  },
  missing: {
    fontStyle: 'italic',
  },
  patientIcon: {
    width: 60,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

interface OverviewPatientInfoProps {
  recording?: Recording;
  locale?: LanguageSupportedLocale;
}

const OverviewPatientInfo = (props: OverviewPatientInfoProps): JSX.Element => {
  const classes = useStyles();

  const { t } = useTranslation();
  const tLocale = generateTLocale(t, props.locale);

  return (
    <>
      {props.recording?.patient && (
        <Paper className={classes.root} variant="outlined">
          <div className={classes.container}>
            <div data-cy="OverviewPatientInfo">
              <AttributeListItem field={tLocale('Patient')}>
                {props.recording.patient.MR}
              </AttributeListItem>
              <AttributeListItem field={tLocale('Age')}>
                {props.recording.patient.age && props.recording.patient.age > 85
                  ? '85+'
                  : props.recording.patient.age}
              </AttributeListItem>
              <AttributeListItem field={tLocale('BMI')}>
                {props.recording.patient.BMI}
              </AttributeListItem>
              <AttributeListItem field={tLocale('Gender')}>
                {tLocale(props.recording.patient.gender)}
              </AttributeListItem>
            </div>
          </div>
        </Paper>
      )}
    </>
  );
};

export default OverviewPatientInfo;
