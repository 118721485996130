import React, { useState } from 'react';

import { PopperProps } from '@material-ui/core/Popper';
import SignalEventDetailPopup from './SignalEventDetailPopup';
import signalEventDetailService from './signalEventDetailService';
import { ScoringCanvasEvent } from '../Chart/ScoringCanvas/ScoringCanvas';

const SignalEventDetail = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<ScoringCanvasEvent | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl']>(null);

  const updateOpen = (isOpen: boolean) => setOpen(isOpen);
  const updateEventData = (newData?: ScoringCanvasEvent) => setData(newData);
  const updateAnchorEl = (newAnchorEl: PopperProps['anchorEl']) =>
    setAnchorEl(newAnchorEl);

  React.useEffect(() => {
    signalEventDetailService.initialize(
      updateOpen,
      updateEventData,
      updateAnchorEl,
    );
  }, []);

  return (
    <SignalEventDetailPopup
      isOpen={open}
      anchorEl={anchorEl}
      eventData={data}
    />
  );
};

export default SignalEventDetail;
