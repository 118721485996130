import { ApolloError } from '@apollo/client';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import sheetTools from '../components/SignalSheet/sheetTools';
import { QueryType } from '../interfaces/query-manager';
import {
  GetAccessTokenQueryParameters,
  GetAccessTokenQueryResult,
} from '../queries/accessToken';
import { GetIsAuthenticatedQueryResult } from '../queries/isAuthenticated';
import Logger from '../utils/logger';
import EventService from './eventService';
import queryManager from './queryManager';
import UserAttributesService from './userAttributesService';

type AccessToken = string;

export enum AuthErrorCode {
  NETWORK_ERROR = 'NETWORK_ERROR',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

interface AuthServiceInterface {
  redirectToLogin: (error: ApolloError) => void;
  ensureAuthenticated: () => Promise<boolean>;
  requestAccessToken: () => Promise<AccessToken>;
  requestAccessTokenIfNeeded: (opts: { query: QueryType }) => Promise<boolean>;
  setAccessToken: (accessToken: AccessToken) => void;
  getAccessToken: () => AccessToken | undefined;
  clearAccessToken: () => void;
  isAuthenticated: () => boolean;
  setAuthenticated: (newAuthenticated: boolean) => void;
  checkIfErrorIsAuthRelated: (error: ApolloError) => void;
  extractUserIdFromAccessToken: () => void;
  getAllowedQueryTypes: () => QueryType[];
}

let isAuthenticated = false;
let accessToken: AccessToken | undefined;
let authCheckTimeout: NodeJS.Timeout;

const AuthService: AuthServiceInterface = {
  redirectToLogin: (error: ApolloError) => {
    Logger.log('[AuthService][redirectToLogin] Init');
    if (!error.networkError) {
      const originUrl = document.location.href;
      const redirectUrl = `${
        document.location.origin
      }/callback?originUrl=${btoa(originUrl)}`;
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL}?redirectUrl=${redirectUrl}`,
      );
    } else {
      Logger.warn(
        '[AuthService][redirectToLogin] Cancelling due to network error',
      );
    }
  },
  ensureAuthenticated: () => {
    Logger.log('[AuthService][ensureAuthenticated] Init');
    return new Promise((resolve, reject) => {
      Logger.log('[AuthService][ensureAuthenticated] Requesting...');
      queryManager
        .query<GetIsAuthenticatedQueryResult>('IsAuthenticated')
        .then((data) => {
          Logger.log(
            '[AuthService][ensureAuthenticated] isAuthenticated?',
            data.isAuthenticated,
          );
          AuthService.setAuthenticated(data.isAuthenticated);
          resolve(AuthService.isAuthenticated());
        })
        .catch((error: ApolloError) => {
          if (!error.networkError) {
            Logger.error(
              '[AuthService][ensureAuthenticated] error:',
              JSON.stringify(error),
            );
            AuthService.setAuthenticated(false);

            AuthService.redirectToLogin(error);
            reject(AuthErrorCode.UNAUTHENTICATED);
          } else {
            reject(AuthErrorCode.NETWORK_ERROR);
          }
        });
    });
  },
  requestAccessToken: () => {
    Logger.log('[AuthService][requestAccessToken] Init');
    return new Promise((resolve, reject) => {
      const params: GetAccessTokenQueryParameters = {
        recordingId: sheetTools.getRecordingId() || 'NO_RECORDING_ID',
        scoringId: sheetTools.getScoringId() || 'NO_SCORING_ID',
      };

      Logger.log('[AuthService][requestAccessToken] Requesting...');
      queryManager
        .query<GetAccessTokenQueryResult>('AccessToken', params)
        .then((data) => {
          Logger.log('[AuthService][requestAccessToken] Done!', data);
          const { accessToken: newAccessToken } = data.accessToken;
          AuthService.setAccessToken(newAccessToken);
          resolve(newAccessToken);
        })
        .catch((error: ApolloError) => {
          Logger.error(
            '[AuthService][requestAccessToken] error:',
            JSON.stringify(error),
          );
          AuthService.redirectToLogin(error);

          if (error.networkError) {
            reject(AuthErrorCode.NETWORK_ERROR);
          }
        });
    });
  },
  requestAccessTokenIfNeeded: (opts: { query: QueryType }) => {
    Logger.log('[AuthService][requestAccessTokenIfNeeded] init:');

    const allowedQueryTypes = AuthService.getAllowedQueryTypes();

    return new Promise((resolve, reject) => {
      if (
        !allowedQueryTypes.includes(opts.query) &&
        AuthService.getAccessToken() === undefined
      ) {
        Logger.warn('[AuthService][requestAccessTokenIfNeeded] is needed!');
        return AuthService.requestAccessToken()
          .then((data) => {
            resolve(!!data);
          })
          .catch((error) => reject(error));
      }
      resolve(true);
      return undefined;
    });
  },
  setAccessToken: (newAccessToken: AccessToken) => {
    accessToken = newAccessToken;
    AuthService.extractUserIdFromAccessToken();
    EventService.dispatch('AccessTokenUpdated');
  },
  clearAccessToken: () => {
    accessToken = undefined;
  },
  getAccessToken: () => accessToken,
  isAuthenticated: () => {
    Logger.log('[AuthService][isAuthenticated]', isAuthenticated);
    return isAuthenticated;
  },
  setAuthenticated: (newAuthenticated: boolean) => {
    Logger.log('[AuthService][setAuthenticated]', newAuthenticated);
    clearInterval(authCheckTimeout);

    if (newAuthenticated) {
      authCheckTimeout = setInterval(AuthService.ensureAuthenticated, 60000);
    }

    isAuthenticated = newAuthenticated;
  },
  checkIfErrorIsAuthRelated: (error: ApolloError) => {
    if (error.graphQLErrors && error.graphQLErrors[0]) {
      const errorCode = error.graphQLErrors[0].extensions?.code;
      Logger.log(
        '[AuthService][checkIfErrorIsAuthRelated] errorCode:',
        errorCode,
      );
      Logger.log(
        '[AuthService][checkIfErrorIsAuthRelated] AuthErrorCode.UNAUTHENTICATED:',
        AuthErrorCode.UNAUTHENTICATED,
      );
      if (errorCode === AuthErrorCode.UNAUTHENTICATED) {
        AuthService.setAuthenticated(false);
        AuthService.redirectToLogin(error);
      }
    }
  },
  extractUserIdFromAccessToken: () => {
    if (accessToken) {
      const decoded = jwtDecode<JwtPayload>(accessToken);
      const userId = decoded.sub;
      if (userId) {
        Logger.log('[AuthService][extractUserId] userId:', userId);
        UserAttributesService.setUserId(userId);
      }
    }
  },
  getAllowedQueryTypes: () => ['AccessToken', 'IsAuthenticated'],
};

export default AuthService;
