import React from 'react';
import clsx from 'clsx';
import _ from 'underscore';
import {
  makeStyles,
  createStyles,
  Fade,
  Typography,
  fade,
  Button,
} from '@material-ui/core';
import EventService from '../../../services/eventService';
import { MarkerTypeDefinition } from '../../../interfaces/markers';
import eventChartTools from '../SignalChart/eventChartTools';
import { MarkerDefinitionTools } from '../SignalChart/markerConstants';
import { KeyCode } from '../../SignalSheet/interfaces/keyboard-manager';
import Logger from '../../../utils/logger';
import { ScoringCanvasEvent } from './ScoringCanvas';
import ScoringService from '../../../services/scoringService';

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      bottom: theme.spacing(-0.5),
      left: 0,
      right: 0,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      padding: 2,
    },
    shortcut: {
      height: 21,
      position: 'relative',
      margin: theme.spacing(0.5),
      padding: theme.spacing(0, 0.5),
      marginBottom: 0,
      display: 'flex',
      alignItems: 'center',
      gridGap: theme.spacing(0.5),
      fontWeight: 500,
      color: '#fff',
      zIndex: 1,
      boxShadow: 'none',
      border: `1px solid ${fade('#000', 0.1)}`,
      '&:hover': {
        boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 50%)',
      },
    },
    disabled: { opacity: '0.4 !important' },
    text: {
      fontWeight: 500,
      textTransform: 'none',
    },
    close: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    active: {
      color: '#fff',
      boxShadow: '0px 2px 4px 0px rgb(0 0 0 / 50%)',
      marginBottom: 4,
      top: -2,
      '&:hover': {
        boxShadow: '0px 2px 4px 0px rgb(0 0 0 / 50%)',
      },
    },
  });
});

const ScoringShortcuts = (): JSX.Element => {
  const classes = useStyles();
  const [show, setShow] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [activeMarker, setActiveMarker] = React.useState<ScoringCanvasEvent>();
  const [shortcuts, setShortcuts] = React.useState<MarkerTypeDefinition[]>([]);

  const cancelActiveMarker = () => {
    setShow(false);
    setActiveMarker(undefined);
    setShortcuts([]);
  };

  const handleChange = (definition: MarkerTypeDefinition) => {
    setActiveMarker((oldMarker) => {
      let newMarker = oldMarker;
      if (oldMarker && oldMarker.signalInfo?.markerSubscription) {
        const markerTypesForSignalType = oldMarker.signalInfo.markerSubscription
          .map((sub) => sub.markerTypes)
          .flat();

        const isValidTypeSwitch = markerTypesForSignalType.includes(
          definition.type,
        );
        if (isValidTypeSwitch) {
          newMarker = eventChartTools.switchEventType(
            oldMarker,
            'Shortcut',
            definition.type,
          );
        }
      }

      return newMarker;
    });
  };

  React.useEffect(() => {
    const cbId = [
      EventService.subscribe('Scoring.CancelActiveMarker', () => {
        Logger.debug('[ScoringShortcuts] CancelActiveMarker');
        cancelActiveMarker();
      }),
      EventService.subscribe(
        'Scoring.ActiveMarker',
        (marker: ScoringCanvasEvent) => {
          Logger.debug('[ScoringShortcuts] ActiveMarker', marker);

          const newActiveMarker = marker;
          if (!ScoringService.isReadOnly()) {
            const signalInfo = newActiveMarker?.signalInfo;

            if (newActiveMarker) {
              if (!newActiveMarker.extras) {
                newActiveMarker.extras = {
                  hasDrop: eventChartTools.shouldHaveDrop(
                    newActiveMarker.start,
                    newActiveMarker.end,
                    newActiveMarker.type,
                    signalInfo?.type,
                  ),
                };
              }
            }

            setActiveMarker({ ...newActiveMarker });

            if (signalInfo) {
              if (
                signalInfo?.markerSubscription &&
                signalInfo.markerSubscription.length > -1
              ) {
                const markerTypesForSignalType = _.uniq(
                  signalInfo.markerSubscription
                    .map((sub) => sub.markerTypes)
                    .flat(),
                );
                const definitions = MarkerDefinitionTools.getFromMarkerTypes(
                  markerTypesForSignalType,
                );
                const definitionsWithShortcuts = definitions.filter(
                  (def) => def.shortcutKey,
                );

                setShortcuts(definitionsWithShortcuts);
                setShow(true);
              }
            }
          }
        },
      ),
      EventService.subscribe('Keyboard.ActiveKeys', (activeKeys: string[]) => {
        Logger.debug('[ScoringShortcuts] shortcuts', shortcuts);
        if (!disabled) {
          if (activeKeys.includes(KeyCode.Escape)) {
            cancelActiveMarker();
          } else if (show && activeMarker && activeKeys.length > 0) {
            Logger.debug('[ScoringShortcuts] activeKeys', activeKeys);
            shortcuts.forEach((definition) => {
              Logger.debug('[ScoringShortcuts] evaluating', definition);
              if (
                !!definition.shortcutKey &&
                activeKeys.includes(definition.shortcutKey)
              ) {
                handleChange(definition);
              }
            });
          }
        }
      }),
      EventService.subscribe(
        'Scoring.DraggingOrMovingMarker',
        (changing: boolean) => {
          setDisabled(changing);
        },
      ),
    ];
    return () => {
      EventService.unsubscribe(cbId);
    };
  }, [activeMarker, disabled, shortcuts, show]);

  return (
    <div data-cy="EventTypeShortcutGuides" className={classes.root}>
      <Fade in={show} mountOnEnter unmountOnExit>
        <Button
          className={clsx(classes.shortcut, classes.close)}
          onClick={cancelActiveMarker}
        >
          <Typography variant="caption" className={classes.text}>
            Close (Esc)
          </Typography>
        </Button>
      </Fade>
      {shortcuts.map((definition, index) => (
        <Fade
          key={definition.type}
          in={show}
          mountOnEnter
          unmountOnExit
          style={{ transitionDelay: `${index * 25}ms` }}
        >
          <Button
            className={clsx('scoring-shortcut', classes.shortcut, {
              [classes.disabled]: disabled,
              [classes.active]: definition.type === activeMarker?.type,
            })}
            style={{
              backgroundColor:
                definition.type === activeMarker?.type
                  ? fade(
                      eventChartTools.getColorByEventType(definition.type),
                      1,
                    )
                  : eventChartTools.getColorByEventType(definition.type),
            }}
            onClick={() => {
              handleChange(definition);
            }}
          >
            <Typography
              variant="caption"
              className={clsx('scoring-shortcut', classes.text)}
            >
              {definition.name}{' '}
              {definition.shortcutKey && <>({definition.shortcutKey})</>}
            </Typography>
          </Button>
        </Fade>
      ))}
    </div>
  );
};

export default ScoringShortcuts;
