import { OnLoadChartFunction } from '../../../interfaces/chart-props';
import chartEvents from '../../SignalSheet/chartEvents';
import chartTools from '../SignalChart/chartTools';
import { SheetToolbarMode } from '../../../interfaces/sheet-toolbar-props';
import Logger from '../../../utils/logger';

export default function getNavigatorChartOptions(
  onNavigatorChartLoad: OnLoadChartFunction,
  toolbarMode: SheetToolbarMode,
): Highcharts.Options {
  const chartOptions: Highcharts.Options = {
    chart: {
      margin: 0,
      spacingTop: 0,
      animation: false,
      spacingBottom: 0,
      height: chartTools.getNavigatorChartHeight(toolbarMode),
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      events: {
        load() {
          Logger.log('[getNavigatorChartOptions] load');
          chartEvents.onLoad('NavigatorChart', this, { isSignalChart: false });
          onNavigatorChartLoad(this);
        },
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    accessibility: {
      keyboardNavigation: {
        enabled: true,
      },
    },
    plotOptions: {
      series: {
        showInNavigator: true,
        marker: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    navigator: {
      height: chartTools.getNavigatorHeight(toolbarMode),
      adaptToUpdatedData: true,
      outlineColor: 'transparent',
      maskFill: 'transparent',
      handles: {
        enabled: false,
      },
      series: {
        fillOpacity: 1,
        lineWidth: 0,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, '#d32f2f'], // start
            [0.8, '#f57c00'], // middle
            [1, '#0288d1'], // end
          ],
        },
        enableMouseTracking: false,
      },
      xAxis: {
        gridLineWidth: 0,
      },
      yAxis: {
        softMax: 10,
        softMin: 0,
      },
    },
    xAxis: {
      visible: false,
      /* crosshair: false, */
      events: {
        setExtremes: (e) => {
          Logger.log('[getNavigatorChartOptions] e', e);
          Logger.debug('[getNavigatorChartOptions] Diff is:', e.max - e.min);

          return chartEvents.onSetExtremes(e);
        },
      },
      maxRange: 10 * 60 * 1000, // 10 mins
      minRange: 30000, // 30 secs
      ordinal: true,
    },
    yAxis: {
      height: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    series: [
      {
        data: [],
        type: 'line',
      },
    ],
  };

  return chartOptions;
}
