import moment from 'moment-timezone';
import { ColDef } from '@ag-grid-community/all-modules';
import { Recording } from '../../queries/recording';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const columnsDefinition = (): ColDef[] => {
  return [
    {
      headerName: 'Recording ID',
      field: 'recordingId',
      filter: 'agTextColumnFilter',
    },
    /*     {
      headerName: 'Medical Record',
      field: 'MR',
      filter: 'agTextColumnFilter',
      valueGetter: ({ data }: { data: Recording }) => data.patient?.MR,
    }, */
    {
      headerName: 'Status',
      field: 'status',
      width: 150,
    },
    {
      colId: 'device',
      headerName: 'Device',
      valueGetter: ({ data }: { data: Recording }) =>
        data.deviceInfo ? data.deviceInfo.type : '',
      width: 150,
    },
    {
      headerName: 'Date of recording',
      field: 'date',
      filter: 'agDateColumnFilter',
      valueGetter: ({ data }: { data: Recording }) =>
        data.startTime ? moment(data.startTime).format(dateFormat) : '',
    },
    {
      headerName: 'Uploaded date',
      sort: 'desc',
      field: 'uploadedDate',
      filter: 'agDateColumnFilter',
      valueGetter: ({ data }: { data: Recording }) =>
        data.uploadedDate ? moment(data.uploadedDate).format(dateFormat) : '',
    },
    {
      colId: 'duration',
      headerName: 'Duration',
      field: 'duration',
      filter: 'agTextColumnFilter',
      valueGetter: ({ data }: { data: Recording }) => {
        if (data.endTime) {
          const diff =
            new Date(data.endTime).valueOf() -
            new Date(data.startTime).valueOf();
          const hours = diff / 1000 / 60 / 60;
          const hoursInt = parseInt(hours.toString(), 10);
          const paddedHours = hoursInt.toString().padStart(2, '0');
          return `${paddedHours}:${moment(diff).format('mm:ss')}`;
        }
        return '';
      },
      filterParams: {
        filterOptions: [
          'greaterThan',
          {
            displayKey: 'greaterThanHours',
            displayName: 'Greater than (hours)',
            test(filterValue: number, cellValue: string) {
              return (
                cellValue == null ||
                parseInt(cellValue.split(':')[0], 10) >= filterValue
              );
            },
          },
          {
            displayKey: 'greaterThanMinutes',
            displayName: 'Greater than (minutes)',
            test(filterValue: number, cellValue: string) {
              return (
                cellValue == null ||
                parseInt(cellValue.split(':')[1], 10) >= filterValue
              );
            },
          },
        ],
      },
    },
    {
      headerName: 'Submitted',
      field: 'isSubmitted',
      filter: false,
      sortable: false,
      suppressMenu: true,
      cellRenderer: 'submittedCellRenderer',
    },
    {
      headerName: '',
      field: 'actions',
      filter: false,
      sortable: false,
      suppressMenu: true,
      pinned: 'right',
      cellRenderer: 'actionsCellRenderer',
    },
  ];
};

export default columnsDefinition;
