import gql from 'graphql-tag';
import { RecordingId } from './recording';
import { ScoringInsightsPart } from './scoringInsights';

export interface Report {
  recordingId: RecordingId;
  scoringId: string;
  scoringVersion: string;
  data: ReportData[];
  insights: ScoringInsightsPart;
  comments: ReportCommentData[];
}

export interface ReportCommentsData {
  recordingId: RecordingId;
  scoringId: string;
  scoringVersion: string;
  comments: ReportCommentData[];
}

export interface ReportData {
  name: string;
  unit: string;
  value: string;
}

export interface ReportCommentData {
  author: string;
  date: Date;
  message: string;
}

export interface GetReportQueryParameters {
  recordingId: RecordingId;
  scoringId: string;
  scoringVersion: string;
}

export interface GetReportCommentsQueryParameters {
  recordingId: RecordingId;
  scoringId: string;
  scoringVersion: string;
}

export interface GetReportQueryResult {
  report: Report;
}

export interface GetReportCommentsQueryResult {
  reportComments: ReportCommentsData;
}

export const GET_REPORT = gql`
  query Report(
    $recordingId: String!
    $scoringId: String!
    $scoringVersion: String!
  ) {
    report(
      recordingId: $recordingId
      scoringId: $scoringId
      scoringVersion: $scoringVersion
    ) {
      recordingId
      scoringId
      scoringVersion
      data {
        name
        unit
        value
      }
      comments {
        author
        date
        message
      }
      insights {
        beginning
        end
        isSubmitted
        graphs {
          type
          signalType
          url
        }
        analysisPeriods {
          beginning
          end
          kpis {
            AHI
            ODI
            sleepEfficiency
          }
          sleepDurations {
            totalSecondsInWake
            totalSecondsInREM
            totalSecondsInNREM
          }
        }
      }
    }
  }
`;

export const GET_REPORT_COMMENTS = gql`
  query ReportComments(
    $recordingId: String!
    $scoringId: String!
    $scoringVersion: String!
  ) {
    reportComments(
      recordingId: $recordingId
      scoringId: $scoringId
      scoringVersion: $scoringVersion
    ) {
      recordingId
      scoringId
      scoringVersion
      comments {
        author
        date
        message
      }
    }
  }
`;

export interface AddReportCommentMutationParameters {
  recordingId: RecordingId;
  scoringId: string;
  scoringVersion: string;
  message: string;
}

export const ADD_REPORT_COMMENT = gql`
  mutation AddReportComment(
    $recordingId: String!
    $scoringId: String!
    $scoringVersion: String!
    $message: String!
  ) {
    addReportComment(
      recordingId: $recordingId
      scoringId: $scoringId
      scoringVersion: $scoringVersion
      message: $message
    )
  }
`;
