import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import React from 'react';
import { Fab } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { TouchNavigationProps } from './touch-navigation-props';
import Logger from '../../utils/logger';
/* import EventService from '../../services/eventService'; */
import { ZIndex } from '../../utils/theme';

const useStyles = makeStyles((theme) => ({
  button: { position: 'absolute', opacity: 0.8, zIndex: ZIndex.AboveBaseline },
  stageN1: {
    bottom: theme.spacing(14),
    left: theme.spacing(2),
  },
  stageN2: {
    bottom: theme.spacing(8),
    left: theme.spacing(8),
  },
  stageN3: {
    bottom: theme.spacing(2),
    left: theme.spacing(14),
  },
  stageREM: {
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  stageWake: {
    bottom: theme.spacing(14),
    left: theme.spacing(14),
  },
  moveLeft: {
    bottom: theme.spacing(2),
    right: theme.spacing(12),
  },
  moveRight: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function TouchNavigation(
  props: TouchNavigationProps,
): JSX.Element {
  const classes = useStyles();

  let intervalRef: NodeJS.Timeout;
  const moving = (fn: () => void) => {
    Logger.log('[TouchNavigation] Moving');
    props.setMovingStatus(true);
    clearInterval(intervalRef);
    intervalRef = setInterval(fn, 250);
  };
  const stopMoving = () => {
    Logger.log('[TouchNavigation] Stop Moving');
    clearInterval(intervalRef);
    setTimeout(() => {
      props.setMovingStatus(false);
    }, 200);
  };

  return (
    <>
      {/* <Fab
        className={clsx(classes.button, classes.stageN2)}
        onClick={() => EventService.dispatch('KeyboardShortcut.ScoreStageN2')}
      >
        N2
      </Fab>
      <Fab
        className={clsx(classes.button, classes.stageN1)}
        onClick={() => EventService.dispatch('KeyboardShortcut.ScoreStageN1')}
      >
        N1
      </Fab>
      <Fab
        className={clsx(classes.button, classes.stageN3)}
        onClick={() => EventService.dispatch('KeyboardShortcut.ScoreStageN3')}
      >
        N3
      </Fab>
      <Fab
        className={clsx(classes.button, classes.stageWake)}
        onClick={() => EventService.dispatch('KeyboardShortcut.ScoreStageWake')}
      >
        Wake
      </Fab>
      <Fab
        className={clsx(classes.button, classes.stageREM)}
        onClick={() => EventService.dispatch('KeyboardShortcut.ScoreStageREM')}
      >
        REM
      </Fab> */}
      <Fab
        className={clsx(classes.button, classes.moveLeft)}
        onClick={props.moveLeft}
        onTouchStart={() => moving(props.moveLeft)}
        onTouchEnd={stopMoving}
      >
        <ArrowBackIosIcon />
      </Fab>
      <Fab
        className={clsx(classes.button, classes.moveRight)}
        onClick={props.moveRight}
        onTouchStart={() => moving(props.moveRight)}
        onTouchEnd={stopMoving}
      >
        <ArrowForwardIosIcon />
      </Fab>
    </>
  );
}
