import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import EventService from '../../../services/eventService';
import ScoringRenderingService from '../../../services/scoringRenderingService';
import chartRangeTools from '../../SignalSheet/chartRangeTools';
import sheetTools from '../../SignalSheet/sheetTools';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      position: 'absolute',
      top: 0,
      background: 'rgba(115, 113, 115, 0.1)',
      opacity: 0.5,
    },
  });
});

const SelectedEpochHighlight = (): JSX.Element => {
  const classes = useStyles();

  const [left, setLeft] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState<'100%' | number>();

  const calculatePosition = (
    min: number,
    max: number,
  ): { left: number; width: number } => {
    const extremes = chartRangeTools.getCurrentExtremes();

    const diffWithExtremeMax = extremes.max - extremes.min;
    const diffWithEventStart = min - extremes.min;
    const diffWithEventEnd = max - extremes.min;
    const containerWidth = ScoringRenderingService.getScoringCanvasWidth();

    const posLeft = (diffWithEventStart * containerWidth) / diffWithExtremeMax;
    const posWidth =
      (diffWithEventEnd * containerWidth) / diffWithExtremeMax - posLeft;

    return {
      left: posLeft,
      width: posWidth,
    };
  };

  const highlightEpoch = (epoch: number) => {
    const highlightStartTime = chartRangeTools.convertToTimestamp(epoch);
    const highlightEndTime = highlightStartTime + 30000;
    const position = calculatePosition(highlightStartTime, highlightEndTime);
    // Logger.debug('[SelectedEpochHighlight] position', position);

    const currentExtremesInEpoch = chartRangeTools.getCurrentExtremesInEpoch();
    const epochsWithinExtremes =
      currentExtremesInEpoch.last - currentExtremesInEpoch.first;

    if (
      epochsWithinExtremes === 1 ||
      (epochsWithinExtremes > 1 && position.left !== 0)
    ) {
      setLeft(position.left);
      setWidth(position.width);
    }
    if (epochsWithinExtremes > 2) {
      setHeight('100%');
    } else {
      setHeight(20);
    }
  };

  React.useEffect(() => {
    const cbId = [
      EventService.subscribe(['CurrentEpochChanged', 'WindowResize'], () => {
        const selectedEpoch = sheetTools.getSelectedEpoch();
        highlightEpoch(selectedEpoch);
      }),
      EventService.subscribe(['Sheet.ExtremesUpdated'], () => {
        const selectedEpoch = sheetTools.getSelectedEpoch();

        const currentExtremesInEpoch =
          chartRangeTools.getCurrentExtremesInEpoch();
        const epochsWithinExtremes =
          currentExtremesInEpoch.last - currentExtremesInEpoch.first;
        const moreThan1Epoch = epochsWithinExtremes > 1;
        const notTheBestEpoch = selectedEpoch !== currentExtremesInEpoch.first;

        if (moreThan1Epoch && notTheBestEpoch) {
          highlightEpoch(currentExtremesInEpoch.first + 1);
        }
      }),
    ];

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, []);

  return (
    <div
      className={classes.root}
      style={{
        left,
        width,
        height,
      }}
    />
  );
};

export default SelectedEpochHighlight;
