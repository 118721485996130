import React, { useState } from 'react';
import { Paper, makeStyles, Typography, fade } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import {
  GridApi,
  GridReadyEvent,
  FirstDataRenderedEvent,
  RowNode,
} from '@ag-grid-community/all-modules';
import clsx from 'clsx';
import OverviewPartDetail from './OverviewPartDetail';
import { DeviceType, Recording, RecordingId } from '../../queries/recording';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';
import OverviewPartActions from './OverviewPartActions';
import OverviewPartStats from './OverviewPartStats';
import Logger from '../../utils/logger';
import { ScoringInsights } from '../../queries/scoringInsights';
import StyledGrid from '../Shared/StyledGrid';
import OverviewPartsReadOnly from './OverviewPartsReadOnly';
import ScoringService from '../../services/scoringService';
import studyTools from './studyTools';
import OverviewPartDetailSecondaryMenu, {
  OverviewPartMenuPos,
} from './OverviewPartDetailSecondaryMenu';
import { calculateOverviewChartsSize } from '../Chart/OverviewChart/overviewChartDefinitions';
import SavedStatus from '../StatusBar/SavedStatus';
import EventService from '../../services/eventService';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    margin: theme.spacing(1),
    padding: theme.spacing(3, 2),
  },
  status: {
    position: 'absolute',
    top: theme.spacing(3.5),
    right: theme.spacing(2),
    '& svg': {
      color: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.6,
      ),
    },
  },
  hidden: {
    visibility: 'hidden',
  },
}));

interface OverviewPartsProps {
  recording?: Recording;
  scoringInsights?: ScoringInsights;
  scoringInsightsError?: string;
  highlightPartId?: number;
  setHighlightPartId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export type PartId = number;

export interface RecordingPartData {
  recordingId: RecordingId;
  scoringId: string;
  deviceType?: DeviceType;
  readOnlyScoring: boolean;
  partId: PartId;
  startTime: string;
  endTime: string;
  availableSignals: string[];
  stats: {
    AHI?: number;
    ODI?: number;
    sleepEfficiency?: number;
    totalSleptTime?: number;
  };
  isSubmitted?: boolean;
}

const OverviewParts = (props: OverviewPartsProps): JSX.Element => {
  const classes = useStyles();

  const [gridApi, setGridApi] = useState<GridApi>();
  // const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const [rowData, setRowData] = useState<RecordingPartData[]>([]);
  const [loadingParts, setLoadingParts] = useState(true);
  const [isReadOnlyScoring, setIsReadOnlyScoring] = React.useState(
    ScoringService.isReadOnly(),
  );
  const [hasSubmittedPart, setHasSubmittedPart] = React.useState(
    ScoringService.getSubmittedPartId() !== undefined,
  );

  const initialMenuState: OverviewPartMenuPos = {
    mouseX: null,
    mouseY: null,
    timestampFrom: null,
    timestampTo: null,
    partId: null,
    origin: null,
    existingAreas: null,
  };
  const [secondaryMenuPos, setSecondaryMenuPos] =
    React.useState<OverviewPartMenuPos>(initialMenuState);
  const handleMenuClose = () => {
    setSecondaryMenuPos((old) => ({
      ...old,
      mouseX: initialMenuState.mouseX,
      mouseY: initialMenuState.mouseY,
    }));
  };
  const handleMenuOpen = (pos: OverviewPartMenuPos) => {
    Logger.debug('[OverviewParts] handleMenuOpen. pos:', pos);
    setSecondaryMenuPos((oldPos) => {
      Logger.debug('[OverviewParts] handleMenuOpen. oldPos:', oldPos);
      if (!oldPos.mouseX && !oldPos.mouseY) {
        return pos;
      }
      return oldPos;
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    if (rowData.length === 1 && params.api) {
      params.api.getDisplayedRowAtIndex(0)?.setExpanded(true);
    }
  };

  React.useEffect(() => {
    // Logger.debug('[OverviewParts] useEffect. gridApi:', gridApi);
    // Logger.debug('[OverviewParts] useEffect. props.highlightPartId:', props.highlightPartId);

    const cbId = [
      EventService.subscribe<boolean>('ScoringReadOnly', (isReadOnly) => {
        setIsReadOnlyScoring(isReadOnly);
      }),
      EventService.subscribe('ScoringSubmittedPart', (part) => {
        setHasSubmittedPart(() => part !== undefined);
      }),
    ];

    gridApi?.forEachNode((rowNode: RowNode) => {
      if (rowNode.data) {
        const data = rowNode.data as RecordingPartData;
        if (data.partId === props.highlightPartId) {
          // Logger.debug('[OverviewParts] Highlighting part:', data.partId);
          rowNode.setSelected(true);
        } else {
          // Logger.debug('[OverviewParts] Highlighting part:', data.partId);
          rowNode.setSelected(false);
        }
      }
    });

    if (props.scoringInsights && rowData.length === 0) {
      Logger.log(
        '[OverviewParts] useEffect. props.scoringInsights:',
        props.scoringInsights,
      );
      const newParts: RecordingPartData[] = [];
      const parts = props.scoringInsights.parts || [];

      const sortedParts = parts.sort((a, b) => {
        const dateA = new Date(a.beginning);
        const dateB = new Date(b.beginning);
        return dateA.valueOf() - dateB.valueOf();
      });

      const isAnyPartSubmitted = sortedParts.some((part) => part.isSubmitted);
      sortedParts.forEach((part, index) => {
        const isSingleAnalysisPeriod = part.analysisPeriods?.length === 1;
        const statsPeriod = isSingleAnalysisPeriod
          ? part.analysisPeriods?.[0]
          : undefined;

        if (props.scoringInsights?.recordingId) {
          const partId = index + 1;
          newParts.push({
            partId,
            startTime: part.beginning,
            endTime: part.end,
            deviceType: props.recording?.deviceInfo?.type || undefined,
            recordingId: props.scoringInsights.recordingId,
            scoringId: props.scoringInsights.scoringId,
            readOnlyScoring: isAnyPartSubmitted,
            isSubmitted: part.isSubmitted,
            availableSignals: part.signals.map((signal) => signal.signalType),
            stats: {
              AHI: statsPeriod?.kpis.AHI,
              ODI: statsPeriod?.kpis.ODI,
              sleepEfficiency: statsPeriod?.kpis.sleepEfficiency,
              totalSleptTime: statsPeriod?.sleepDurations
                ? statsPeriod.sleepDurations.totalSecondsInNREM +
                  statsPeriod.sleepDurations.totalSecondsInREM
                : undefined,
            },
          });

          if (part.isSubmitted) {
            ScoringService.setSubmittedPartId(partId);
          }
        }
      });

      studyTools.requestStudyGraphData(newParts);
      setRowData(newParts);
      setLoadingParts(false);
    }

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, [
    props.highlightPartId,
    props.scoringInsights,
    isReadOnlyScoring,
    hasSubmittedPart,
  ]);

  return (
    <Paper className={classes.root}>
      <Typography variant="h6" gutterBottom>
        <div
          className={clsx(classes.status, {
            [classes.hidden]:
              loadingParts || isReadOnlyScoring || hasSubmittedPart,
          })}
        >
          <SavedStatus />
        </div>
        Recording Parts
        {!loadingParts && <OverviewPartsReadOnly />}
      </Typography>

      {props.recording &&
        !loadingParts &&
        (rowData.length > 0 ? (
          <div
            data-cy="OverviewPartsTable"
            style={{
              height: '100%',
              width: '100%',
            }}
            className="ag-theme-material"
          >
            <StyledGrid>
              <AgGridReact
                modules={[
                  ClientSideRowModelModule,
                  MasterDetailModule,
                  MenuModule,
                  ColumnsToolPanelModule,
                ]}
                masterDetail
                detailRowHeight={
                  calculateOverviewChartsSize({
                    forDeviceType: props.recording?.deviceInfo?.type,
                  }) + 40
                }
                immutableData
                domLayout="autoHeight"
                rowHeight={90}
                headerHeight={0}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                getRowNodeId={(data: any) => data.partId}
                detailCellRenderer="partDetailCellRenderer"
                frameworkComponents={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  partDetailCellRenderer: (data: any) =>
                    OverviewPartDetail({
                      ...data,
                      handleMenuOpen,
                      setHighlightPartId: props.setHighlightPartId,
                    }),
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  actionsCellRenderer: (data: any) =>
                    OverviewPartActions({
                      ...data,
                    }),
                  statsCellRendered: OverviewPartStats,
                }}
                overlayNoRowsTemplate="No parts available for this recording."
                defaultColDef={{ flex: 1 }}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                rowData={rowData}
                suppressMovableColumns
                suppressCellSelection
                onCellMouseOver={(event: { data: RecordingPartData }) => {
                  props.setHighlightPartId(event.data.partId);
                }}
              >
                <AgGridColumn
                  field="partId"
                  headerName=""
                  cellRenderer="agGroupCellRenderer"
                  maxWidth={50}
                  valueFormatter={() => ' '}
                  suppressMenu
                />
                <AgGridColumn
                  field="stats"
                  headerName=""
                  cellRenderer="statsCellRendered"
                  cellRendererParams={{ handleMenuOpen }}
                  suppressMenu
                />
                <AgGridColumn
                  field="actions"
                  headerName=""
                  cellRenderer="actionsCellRenderer"
                  width={160}
                  maxWidth={160}
                  suppressMenu
                />
              </AgGridReact>
            </StyledGrid>

            <OverviewPartDetailSecondaryMenu
              position={secondaryMenuPos}
              handleClose={handleMenuClose}
            />
          </div>
        ) : (
          <Typography>No parts available for this recording.</Typography>
        ))}

      {loadingParts && (
        <>
          <Skeleton variant="text" animation="wave" width={400} />
        </>
      )}

      {props.scoringInsightsError && (
        <Typography>
          There seems to be an issue on our services. Please, try again later.
        </Typography>
      )}
    </Paper>
  );
};

export default OverviewParts;
