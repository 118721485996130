import _ from 'underscore';
import EventServiceInterface, {
  EventType,
  EventServiceHandler,
  CallbackCreator,
  EventServiceCallbackIdGenerator,
  CallbackId,
  CallbackInfo,
} from '../interfaces/event-service';
import Logger from '../utils/logger';

const callbacksMap = new Map<CallbackId, CallbackInfo[]>();

const generateCallbackId: EventServiceCallbackIdGenerator = () =>
  _.uniqueId(`${Date.now().toString()}_`);
const createCallback: CallbackCreator = (eventType, cb) => {
  return (e: unknown) => {
    const event = e as CustomEvent;
    cb(event.detail);
  };
};

const EventService: EventServiceInterface = {
  subscribe: <T>(
    eventType: EventType[] | EventType,
    handler: EventServiceHandler<T>,
  ) => {
    const eventTypes: EventType[] = Array.isArray(eventType)
      ? eventType
      : [eventType];
    const callbackInfos: CallbackInfo[] = [];
    eventTypes.forEach((type) => {
      // Logger.debug('[EventService] Subscribing to: ', type);
      const callback = createCallback(type, handler);
      document.addEventListener(type, callback);
      callbackInfos.push({
        eventType: type,
        handler: callback,
      });
    });
    const callbackId = generateCallbackId();
    callbacksMap.set(callbackId, callbackInfos);
    return callbackId;
  },
  unsubscribe: (callbackId: CallbackId[] | CallbackId) => {
    const callbackIds: CallbackId[] = Array.isArray(callbackId)
      ? callbackId
      : [callbackId];
    callbackIds.forEach((id) => {
      const callbackInfos = callbacksMap.get(id);
      callbacksMap.delete(id);
      if (callbackInfos) {
        callbackInfos.forEach((callbackInfo) => {
          // Logger.debug('[EventService] Unsubscribing to: ', callbackInfo.eventType);
          document.removeEventListener(
            callbackInfo.eventType,
            callbackInfo.handler,
          );
        });
      } else {
        Logger.warn('[EventService/remove] callbackId not found:', callbackId);
      }
    });
  },
  dispatch: (eventType: EventType, data: unknown) => {
    // Logger.debug('[EventService] Dispatching event: ', eventType);
    document.dispatchEvent(
      new CustomEvent(eventType, { detail: data, cancelable: false }),
    );
  },
};

export default EventService;
