import React from 'react';

import { Typography, makeStyles } from '@material-ui/core';
import cssClasses from './recordingNotFound.module.scss';
import FeatureToggle from '../Shared/FeatureToggle';
import Analytics from '../../services/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10%',
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
}));

interface RecordingNotFoundProps {
  message?: string;
}

const RecordingNotFound = (props: RecordingNotFoundProps): JSX.Element => {
  const classes = useStyles();

  React.useEffect(() => {
    Analytics.track.page('RecordingNotFound');
    Analytics.track.event('ERROR_RECORDING_NOT_FOUND');
  }, []);

  return (
    <div className={classes.root}>
      <div className={cssClasses.image} />
      <Typography variant="h5">Unable to show the recording</Typography>
      <Typography>
        Please make sure the recording URL is correct and that you have the
        appropriate permissions
      </Typography>
      {props.message && (
        <FeatureToggle enabledFor={['development', 'staging']}>
          <Typography variant="caption">{props.message}</Typography>
        </FeatureToggle>
      )}
    </div>
  );
};

export default RecordingNotFound;
