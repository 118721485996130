import { Fade } from '@material-ui/core';
import React from 'react';
import CanvasDraw from 'react-canvas-draw';
import FeatureToggle from '../../Shared/FeatureToggle';

interface ScoringAnnotationsProps {
  enabled: boolean;
}

const ScoringAnnotations = (props: ScoringAnnotationsProps): JSX.Element => {
  const isTouchDevice = 'ontouchstart' in document.documentElement;

  return (
    <FeatureToggle enabledFor="development">
      <Fade in={props.enabled} mountOnEnter unmountOnExit>
        <CanvasDraw
          canvasHeight="100%"
          canvasWidth="100%"
          brushRadius={5}
          lazyRadius={isTouchDevice ? 0 : 30}
          brushColor="#000000aa"
          backgroundColor="transparent"
        />
      </Fade>
    </FeatureToggle>
  );
};

export default ScoringAnnotations;
