import React from 'react';

import { makeStyles } from '@material-ui/core';
import SheetDefinitions from '../SignalSheet/sheetDefinitions';
import SignalOrderingList from './SignalOrderingList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: theme.variables.pageMaxWidth,
  },
}));

const SignalOrderingWorkspace = (): JSX.Element => {
  const classes = useStyles();

  const sheetDefinitions = SheetDefinitions.getAll();

  return (
    <>
      <div className={classes.root}>
        {sheetDefinitions
          .filter((definition) => definition.type === 'RespiratorySheet')
          .map((definition) => (
            <SignalOrderingList
              key={definition.type}
              definition={definition}
              onSaveHandler={SheetDefinitions.saveSignalOrder}
              onRestoreDefault={SheetDefinitions.restoreDefaultSignalOrder}
            />
          ))}
      </div>
    </>
  );
};

export default SignalOrderingWorkspace;
