import React from 'react';
import clsx from 'clsx';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
// import RestoreIcon from '@material-ui/icons/Restore';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import ReorderIcon from '@material-ui/icons/Reorder';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import GestureIcon from '@material-ui/icons/Gesture';
import { Button, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { SheetToolbarMode } from '../../interfaces/sheet-toolbar-props';
import FeatureToggle from '../Shared/FeatureToggle';
import EventViewer from '../EventViewer/EventViewer';
import sheetTools from '../SignalSheet/sheetTools';
import UndoService from '../../services/undoService';
import UndoHistory from '../UndoHistory/UndoHistory';
import KeyboardManager from '../SignalSheet/keyboardManager';
import EventService from '../../services/eventService';
import ScoringService from '../../services/scoringService';
import Analytics from '../../services/analytics';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 150,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // TODO: Enable when Safari supports it
    // gap: theme.spacing(0.5) + 'px',
    transition: '0.1s all linear',
  },
  minimal: {
    flexWrap: 'nowrap',
  },
  button: {
    width: 30,
    height: 23,
    minWidth: 0,
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.background.paper}`,
    transition: '0.1s all linear',
    padding: theme.spacing(0, 2),
    margin: theme.spacing(0, 0.2),
    marginBottom: theme.spacing(0.5),

    '&:first-child': {
      marginLeft: 0, // TODO: Replace with gap
    },

    '&:nth-child(5)': {
      marginLeft: 0, // TODO: Replace with gap
    },

    '&:hover': {
      background: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  buttonMinimal: {
    height: 28,

    '&:nth-child(5)': {
      marginLeft: theme.spacing(0.2), // TODO: Replace with gap
    },
  },
  selected: {
    background: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.main,
  },
  icon: {
    fontSize: '1rem',
    color: theme.colors.text.lighter,
    transition: '0.1s all linear',
  },
  iconSelected: {
    color: theme.colors.text.link,
  },
}));

interface ToolsGridProps {
  setSingleClickScoring: (is: boolean) => void;
  isSingleClickScoring: boolean;
  setInvalidDataMode: (is: boolean) => void;
  invalidDataMode: boolean;
  setAnnotationMode: (enabled: boolean) => void;
  annotationModeEnabled: boolean;
  toolbarMode: SheetToolbarMode;
}

const ToolsGrid = (props: ToolsGridProps): JSX.Element => {
  const classes = useStyles();

  const [eventViewerOpened, setEventViewerOpened] = React.useState(false);
  const [undoHistoryOpened, setUndoHistoryOpened] = React.useState(false);

  const [isReadOnlyScoring, setIsReadOnlyScoring] = React.useState(
    ScoringService.isReadOnly(),
  );

  React.useEffect(() => {
    const cbId = EventService.subscribe<boolean>(
      'ScoringReadOnly',
      (isReadOnly) => setIsReadOnlyScoring(isReadOnly),
    );

    return () => {
      EventService.unsubscribe(cbId);
    };
  });

  return (
    <div
      className={clsx(classes.root, {
        [classes.minimal]: props.toolbarMode === 'Minimal',
      })}
    >
      {!isReadOnlyScoring && (
        <>
          <Tooltip
            title={`Undo action (${KeyboardManager.shortcutToText(
              'KeyboardShortcut.UndoAction',
            )})`}
            arrow
          >
            <Button
              data-cy="UndoBtn"
              variant="contained"
              className={clsx(classes.button, {
                [classes.buttonMinimal]: props.toolbarMode === 'Minimal',
              })}
              onClick={() => UndoService.undo({ origin: 'Toolbar' })}
            >
              <UndoIcon fontSize="small" className={clsx(classes.icon)} />
            </Button>
          </Tooltip>
          <Tooltip
            title={`Redo action (${KeyboardManager.shortcutToText(
              'KeyboardShortcut.RedoAction',
            )})`}
            arrow
          >
            <Button
              data-cy="RedoBtn"
              variant="contained"
              className={clsx(classes.button, {
                [classes.buttonMinimal]: props.toolbarMode === 'Minimal',
              })}
              onClick={() => UndoService.redo({ origin: 'Toolbar' })}
            >
              <RedoIcon fontSize="small" className={clsx(classes.icon)} />
            </Button>
          </Tooltip>
          {/* <FeatureToggle enabledFor="development">
            <Tooltip title="Undo history" arrow>
              <Button
                variant="contained"
                className={clsx(classes.button, {
                  [classes.selected]: undoHistoryOpened,
                  [classes.buttonMinimal]: props.toolbarMode === 'Minimal',
                })}
                disableElevation={undoHistoryOpened}
                onClick={() => setUndoHistoryOpened(true)}
                data-cy="ToolsGrid-UndoHistory"
              >
                <RestoreIcon
                  fontSize="small"
                  className={clsx(classes.icon, {
                    [classes.iconSelected]: undoHistoryOpened,
                  })}
                />
              </Button>
            </Tooltip>
          </FeatureToggle> */}
          <Tooltip title="Single click scoring" arrow>
            <Button
              data-cy={
                props.isSingleClickScoring
                  ? 'SingleClickScoringOn'
                  : 'SingleClickScoringOff'
              }
              variant="contained"
              className={clsx(classes.button, {
                [classes.selected]: props.isSingleClickScoring,
                [classes.buttonMinimal]: props.toolbarMode === 'Minimal',
              })}
              disableElevation={props.isSingleClickScoring}
              onClick={() =>
                props.setSingleClickScoring(!props.isSingleClickScoring)
              }
            >
              <TouchAppIcon
                fontSize="small"
                className={clsx(classes.icon, {
                  [classes.iconSelected]: props.isSingleClickScoring,
                })}
              />
            </Button>
          </Tooltip>

          <Tooltip title="Mark invalid data" arrow>
            <Button
              data-cy={
                props.invalidDataMode
                  ? 'InvalidDataModeOn'
                  : 'InvalidDataModeOff'
              }
              variant="contained"
              className={clsx(classes.button, {
                [classes.selected]: props.invalidDataMode,
                [classes.buttonMinimal]: props.toolbarMode === 'Minimal',
              })}
              disableElevation={props.invalidDataMode}
              onClick={() => props.setInvalidDataMode(!props.invalidDataMode)}
            >
              <LayersClearIcon
                fontSize="small"
                className={clsx(classes.icon, {
                  [classes.iconSelected]: props.invalidDataMode,
                })}
              />
            </Button>
          </Tooltip>
        </>
      )}
      <Tooltip title="Scale all signals to fit" arrow>
        <Button
          data-cy="ToolsGrid-ScaleAll"
          variant="contained"
          className={clsx(classes.button, {
            [classes.buttonMinimal]: props.toolbarMode === 'Minimal',
          })}
          onClick={() => {
            Analytics.track.event('SIGNAL_SCALE_TO_FIT', {
              origin: 'Tools Grid',
            });

            EventService.dispatch('KeyboardShortcut.ScaleToFitAll');
          }}
        >
          <AspectRatioIcon fontSize="small" className={clsx(classes.icon)} />
        </Button>
      </Tooltip>
      <Tooltip title="Open Event Viewer" arrow>
        <Button
          data-cy="EventViewerBtn"
          variant="contained"
          className={clsx(classes.button, {
            [classes.selected]: eventViewerOpened,
            [classes.buttonMinimal]: props.toolbarMode === 'Minimal',
          })}
          disableElevation={eventViewerOpened}
          onClick={() => setEventViewerOpened(true)}
        >
          <ReorderIcon
            fontSize="small"
            className={clsx(classes.icon, {
              [classes.iconSelected]: eventViewerOpened,
            })}
          />
        </Button>
      </Tooltip>
      <FeatureToggle enabledFor="development">
        <Tooltip title="Scoring annotations" arrow>
          <Button
            variant="contained"
            className={clsx(classes.button, {
              [classes.selected]: props.annotationModeEnabled,
              [classes.buttonMinimal]: props.toolbarMode === 'Minimal',
            })}
            disableElevation={props.annotationModeEnabled}
            onClick={() =>
              props.setAnnotationMode(!props.annotationModeEnabled)
            }
            data-cy="ToolsGrid-ScoringAnnotations"
          >
            <GestureIcon
              fontSize="small"
              className={clsx(classes.icon, {
                [classes.iconSelected]: props.annotationModeEnabled,
              })}
            />
          </Button>
        </Tooltip>
      </FeatureToggle>
      <FeatureToggle enabledFor="development">
        <Tooltip title="Pre-fetch all data" arrow>
          <Button
            variant="contained"
            className={clsx(classes.button, {
              [classes.buttonMinimal]: props.toolbarMode === 'Minimal',
            })}
            onClick={() => sheetTools.downloadSheetData()}
            data-cy="ToolsGrid-Prefetch"
          >
            <CloudDownloadIcon
              fontSize="small"
              className={clsx(classes.icon)}
            />
          </Button>
        </Tooltip>
      </FeatureToggle>
      <EventViewer
        eventViewerOpened={eventViewerOpened}
        setEventViewerOpened={setEventViewerOpened}
      />
      <UndoHistory
        undoHistoryOpened={undoHistoryOpened}
        setUndoHistoryOpened={setUndoHistoryOpened}
      />
    </div>
  );
};

export default ToolsGrid;
