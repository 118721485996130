import { fade, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import packageJson from '../../../package.json';
import FeatureToggle from './FeatureToggle';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    color: fade(theme.colors.modal.color, 0.54),
  },
}));

function Version(): JSX.Element {
  const classes = useStyles();

  return (
    <FeatureToggle enabledFor={['development', 'staging']}>
      <Typography className={classes.root} variant="caption" component="div">
        v{packageJson.version}-{process.env.REACT_APP_BUILD_NUMBER || 0}
      </Typography>
    </FeatureToggle>
  );
}

export default Version;
