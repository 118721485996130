import { Theme } from '@material-ui/core';
import { OnLoadChartFunction } from '../../../interfaces/chart-props';
import chartRangeTools from '../../SignalSheet/chartRangeTools';
import chartEvents from '../../SignalSheet/chartEvents';
import SleepStageService from '../../../services/sleepStageService';
import { ChartOptions } from '../SignalChart/getChartOptions';

export const EpochChartOptions = {
  height: 50,
};

export default function getEpochChartOptions(
  theme: Theme,
  onEpochChartLoad: OnLoadChartFunction,
): Highcharts.Options {
  const chartOptions: Highcharts.Options = {
    chart: {
      marginLeft: ChartOptions.signalNameWidth, // Keep all charts left aligned
      marginTop: 30,
      height: EpochChartOptions.height,
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      animation: false,
      plotBorderWidth: 0,
      selectionMarkerFill: 'rgba(10.9%, 41.1%, 69.8%, 0.4)',
      panning: {
        enabled: false,
      },
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      events: {
        load() {
          chartEvents.onLoad('EpochChart', this, { isSignalChart: false });
          onEpochChartLoad(this);
        },
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    accessibility: {
      keyboardNavigation: {
        enabled: true,
      },
    },
    xAxis: {
      opposite: true,
      visible: true,
      crosshair: false,
      gridLineWidth: 1,
      gridLineColor: theme.colors.chart.grid,
      lineWidth: 0.3,
      tickColor: theme.colors.chart.grid,
      lineColor: theme.colors.chart.grid,
      showFirstLabel: true,
      showLastLabel: true,
      endOnTick: false,
      ordinal: false,
      tickPositioner: () => {
        const ticks = chartRangeTools.getTicks();
        ticks[ticks.length - 1] = ticks[ticks.length - 1] - 1;
        return ticks;
      },
      labels: {
        enabled: true,
        format: '{value:%H:%M:%S}',
      },
      min: chartRangeTools.getCurrentExtremes().min,
      max: chartRangeTools.getCurrentExtremes().max,
    },
    yAxis: [
      {
        min: 0,
        max: 1,
        opposite: false,
        gridLineColor: theme.colors.chart.grid,
        title: {
          rotation: 0,
          offset: 30,
          y: 5,
        },
        labels: {
          enabled: false,
        },
      },
    ],
    tooltip: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    series: [
      {
        type: 'line',
        lineWidth: 5,
        color: 'transparent',
        dataLabels: {
          enabled: true,
          y: 0,
          x: -2,
          style: {
            textOutline: 'none',
            color: theme.colors.chart.label,
            fontWeight: '500',
            fontSize: '9',
          },
          align: 'left',
          formatter() {
            const isPointDefined = this.point.x !== undefined;
            let label = '';

            if (isPointDefined) {
              const epoch = chartRangeTools.convertToEpoch(this.point.x);
              const sleepStage = SleepStageService.getStageNameForEpoch(epoch);

              label = `#${epoch + 1}`;
              if (sleepStage) {
                label += ` - ${sleepStage}`;
              }
            }

            return label;
          },
        },
        data: [],
        zoneAxis: 'x', // Sleep stage coloring
        enableMouseTracking: false,
        animation: false,
      },
    ],
  };

  return chartOptions;
}
