import { SignalOrderUserPreference } from '../interfaces/signal-ordering';
import { ThemeType } from '../utils/theme';

type UserPreferenceType = 'SignalOrder' | 'Theme';
type UserPreferenceValue = SignalOrderUserPreference | ThemeType | null;

interface UserAttributesServiceInterface {
  setUserId: (userId: string) => void;
  getUserId: () => string;
  getPreference: <T>(key: UserPreferenceType) => T;
  setPreference: (key: UserPreferenceType, value: UserPreferenceValue) => void;
}

const getFullKey = (key: string) =>
  `${key}.${localStorage.getItem('userId')}` || 'Anonymous';

const UserAttributesService: UserAttributesServiceInterface = {
  setUserId: (userId) => localStorage.setItem('userId', userId),
  getUserId: () => localStorage.getItem('userId') || 'Anonymous',
  getPreference: (key: UserPreferenceType) => {
    const value = localStorage.getItem(getFullKey(key));

    if (value) {
      return JSON.parse(value);
    }
    return null;
  },
  setPreference: (key: UserPreferenceType, value: UserPreferenceValue) =>
    localStorage.setItem(getFullKey(key), JSON.stringify(value)),
};

export default UserAttributesService;
