import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import clsx from 'clsx';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import {
  FormControl,
  FormHelperText,
  FormGroup,
  makeStyles,
  Paper,
} from '@material-ui/core';
import getNavigatorChartOptions from './getNavigatorChartOptions';
import Logger from '../../../utils/logger';
import { OnLoadChartFunction } from '../../../interfaces/chart-props';
import NavigatorTimeline from '../../NavigatorTimeline/NavigatorTimeline';
import chartRangeTools from '../../SignalSheet/chartRangeTools';
import PassiveEvents from '../../../utils/PassiveEvents';
import TabSyncService from '../../../services/tabSyncService';
import chartTools from '../SignalChart/chartTools';
import sheetTools from '../../SignalSheet/sheetTools';
import { SheetToolbarMode } from '../../../interfaces/sheet-toolbar-props';
import { Recording } from '../../../queries/recording';
import Analytics from '../../../services/analytics';
import HelpTooltip from '../../Shared/HelpTooltip';
import NavigatorOverlay from './NavigatorOverlay';
import studyTools from '../../StudyOverview/studyTools';

PassiveEvents(Highcharts);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0.5),
    position: 'relative',
    width: '100%',
    height: 50,
    transition: '0.1s all linear',
  },
  navigatorContainer: {
    width: '100%',
    position: 'relative',
  },
  control: {
    width: '100%',
    opacity: 1,
    transition: '0.1s all linear',
  },
  legend: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    opacity: 0.6,
    marginBottom: 3,
    height: 20,
    overflow: 'hidden',
    transition: '0.1s all linear',
  },
  minimized: {
    height: 0,
    opacity: 0,
    '& $legend': { height: 0, marginBottom: 0 },
  },
  minimal: {
    height: 40,
    '& $legend': { height: 0, marginBottom: 0 },
    '& $paper': { height: 28 },
  },
}));

interface NavigatorChartProps {
  toolbarMode: SheetToolbarMode;
  recording: Recording;
}

export default function NavigatorChart(
  props: NavigatorChartProps,
): JSX.Element {
  Logger.log('[Navigator] init');
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const onNavigatorChartLoad: OnLoadChartFunction = () => {
    Logger.log('[onNavigatorChartLoad] Initializing data');
    chartRangeTools.initializeRangeTools(props.recording.recordingId);
    const currentExtremes = chartRangeTools.getCurrentExtremes();
    const areExtremesUndefined =
      currentExtremes.min === 0 && currentExtremes.max === 0;

    let navigatorMin = chartRangeTools.getPartStartTime();
    let navigatorMax = navigatorMin + 30 * 1000;

    if (
      areExtremesUndefined ||
      props.recording.recordingId !== chartRangeTools.validForRecordingId()
    ) {
      const epoch = studyTools.getEpochFromUrl(location);
      studyTools.removeEpochFromUrl(location, history);
      if (epoch !== undefined) {
        navigatorMin = chartRangeTools.convertToTimestamp(epoch - 1);
        navigatorMax = navigatorMin + 30 * 1000;
      } else if (TabSyncService.isCurrentExtremesInfoValid()) {
        const extremesInfo = TabSyncService.getSheetExtremesInfo();
        if (extremesInfo) {
          navigatorMin = extremesInfo.min;
          navigatorMax = extremesInfo.max;
        }
      }
    } else {
      navigatorMin = currentExtremes.min;
      navigatorMax = currentExtremes.max;
    }

    sheetTools.setExtremes(
      { min: navigatorMin, max: navigatorMax },
      { redraw: false },
    );
    sheetTools.setSelectedEpoch(chartRangeTools.convertToEpoch(navigatorMin));
    chartTools.redrawNavigator();
  };

  const chartOptions: Highcharts.Options = getNavigatorChartOptions(
    onNavigatorChartLoad,
    props.toolbarMode,
  );

  return (
    <FormControl
      component="fieldset"
      className={clsx(classes.control, {
        [classes.minimized]: props.toolbarMode === 'Hidden',
        [classes.minimal]: props.toolbarMode === 'Minimal',
      })}
    >
      <FormHelperText component="legend" className={classes.legend}>
        Event Severity Map{' '}
        <HelpTooltip text="Calculated severity of events per epoch" />
      </FormHelperText>
      <FormGroup>
        <Paper data-cy="EventIntensityChartPaper" className={classes.paper}>
          <div
            className={classes.navigatorContainer}
            onClick={(e) => {
              const { tagName } = e.target as SVGAElement;
              if (tagName !== 'svg')
                Analytics.track.navigation({ action: 'event_chart' });
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              constructorType="stockChart"
              allowChartUpdate={false}
              options={chartOptions}
            />
            <NavigatorOverlay />
          </div>
          <NavigatorTimeline />
        </Paper>
      </FormGroup>
    </FormControl>
  );
}
