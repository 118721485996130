import React from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Tooltip } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  icon: {
    // marginLeft: theme.spacing(0.5),
    fontSize: 12,
    cursor: 'help',
    verticalAlign: 'top',
  },
}));

export interface HelpTooltipProps {
  text: string;
}

function HelpTooltip(props: HelpTooltipProps): JSX.Element {
  const classes = useStyles();

  return (
    <Tooltip title={props.text} placement="top" arrow>
      <HelpOutlineIcon className={classes.icon} />
    </Tooltip>
  );
}

export default HelpTooltip;
