import React from 'react';

import PrintIcon from '@material-ui/icons/Print';

import {
  Button,
  makeStyles,
  Theme,
  fade,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    position: 'relative',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    backgroundColor: theme.palette.background.paper,
    borderColor: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.23,
    ),
    '@media print': {
      display: 'none',
    },
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface PrintButtonProps {
  printing?: boolean;
  handlePrint: () => void;
}

const PrintButton = (props: PrintButtonProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      data-cy="PrintBtn"
      variant="outlined"
      className={classes.button}
      startIcon={<PrintIcon />}
      onClick={props.handlePrint}
    >
      {t('Print')}
      {props.printing && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
};

export default PrintButton;
