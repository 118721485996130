import moment from 'moment';
import {
  SleepParameter,
  SleepParameterType,
} from '../../../interfaces/sleep-parameter';

const SleepParameterDefinitions = new Map<SleepParameterType, SleepParameter>();
export default SleepParameterDefinitions;

SleepParameterDefinitions.set('AHI', {
  type: 'AHI',
  name: 'AHI',
  tooltipText: 'Number of Apnea or Hypopnea events per hour in Sleep',
  invalidateOnAnalysisPeriodChange: true,
  formatter: (value) => parseFloat(value.toFixed(1)).toString(),
  colorRules: {
    ok: (value) => value <= 5,
    mild: (value) => value > 5 && value <= 15,
    moderate: (value) => value > 15 && value <= 30,
    severe: (value) => value > 30,
  },
});

SleepParameterDefinitions.set('ODI', {
  type: 'ODI',
  name: 'ODI',
  tooltipText:
    'Number of significant oxygen drops per hour of estimated sleep time',
  invalidateOnAnalysisPeriodChange: true,
  formatter: (value) => parseFloat(value.toFixed(1)).toString(),
  colorRules: {
    ok: (value) => value <= 5,
    mild: (value) => value > 5 && value <= 15,
    moderate: (value) => value > 15 && value <= 30,
    severe: (value) => value > 30,
  },
});

SleepParameterDefinitions.set('SleepEfficiency', {
  type: 'SleepEfficiency',
  name: 'Sleep Efficiency',
  tooltipText: 'Percentage of total duration in Sleep',
  invalidateOnAnalysisPeriodChange: true,
  formatter: (value) => `${(value * 100).toFixed(0)}%`,
  colorRules: {
    ok: (value) => value >= 0.8,
    mild: (value) => value < 0.8 && value >= 0.5,
    moderate: (value) => value < 0.5 && value >= 0.2,
    severe: (value) => value < 0.2,
  },
});

SleepParameterDefinitions.set('TotalTimeInSleep', {
  type: 'TotalTimeInSleep',
  name: 'Hours in Sleep',
  tooltipText: 'Total hours in Sleep',
  invalidateOnAnalysisPeriodChange: true,
  formatter: (seconds) => parseFloat((seconds / 60 / 60).toFixed(2)).toString(),
  colorRules: {
    ok: () => false,
    severe: () => false,
    unknown: () => true,
  },
});

SleepParameterDefinitions.set('TotalHours', {
  type: 'TotalHours',
  name: 'Recorded Hours',
  tooltipText: 'Total recorded hours for this part',
  invalidateOnAnalysisPeriodChange: false,
  formatter: (milliseconds) =>
    parseFloat((milliseconds / 1000 / 60 / 60).toFixed(2)).toString(),
  colorRules: {
    ok: () => false,
    severe: (milliseconds) => milliseconds < 1 * 60 * 60 * 1000,
    unknown: () => true,
  },
});

SleepParameterDefinitions.set('PartStartDate', {
  type: 'PartStartDate',
  name: 'Date',
  tooltipText: 'Date when the part starts',
  invalidateOnAnalysisPeriodChange: false,
  formatter: (date) => moment(date).format('YYYY-MM-DD HH:mm'),
  colorRules: {
    ok: () => false,
    severe: () => false,
  },
});

SleepParameterDefinitions.set('Pulse-Min', {
  type: 'Pulse-Min',
  name: 'Pulse Min',
  tooltipText: 'Minimum Pulse value within the Analysis Period',
  invalidateOnAnalysisPeriodChange: true,
  formatter: (pulse) => pulse.toString(),
  colorRules: {
    ok: () => false,
    severe: (pulse: number) => pulse > 110,
    unknown: () => true,
  },
});

SleepParameterDefinitions.set('Pulse-Max', {
  type: 'Pulse-Max',
  name: 'Pulse Max',
  tooltipText: 'Maximum Pulse value within the Analysis Period',
  invalidateOnAnalysisPeriodChange: true,
  formatter: (pulse) => pulse.toString(),
  colorRules: {
    ok: () => false,
    severe: (pulse: number) => pulse > 110,
    unknown: () => true,
  },
});

SleepParameterDefinitions.set('Pulse-Average', {
  type: 'Pulse-Average',
  name: 'Pulse Average',
  tooltipText: 'Average Pulse value within the Analysis Period',
  invalidateOnAnalysisPeriodChange: true,
  formatter: (pulse) => pulse.toFixed(0).toString(),
  colorRules: {
    ok: () => false,
    severe: (pulse: number) => pulse > 110,
    unknown: () => true,
  },
});

SleepParameterDefinitions.set('SpO2-Min', {
  type: 'SpO2-Min',
  name: 'SpO2 Min',
  tooltipText: 'Minimum oxygen saturation value within the Analysis Period',
  invalidateOnAnalysisPeriodChange: true,
  formatter: (pulse) => `${pulse.toFixed(0).toString()}%`,
  colorRules: {
    ok: () => false,
    moderate: (spo2: number) => spo2 < 90,
    severe: (spo2: number) => spo2 < 80,
    unknown: () => true,
  },
});

SleepParameterDefinitions.set('SpO2-Average', {
  type: 'SpO2-Average',
  name: 'SpO2 Average',
  tooltipText: 'Average oxygen saturation value within the Analysis Period',
  invalidateOnAnalysisPeriodChange: true,
  formatter: (pulse) => `${pulse.toFixed(0).toString()}%`,
  colorRules: {
    ok: () => false,
    moderate: (spo2: number) => spo2 < 90,
    severe: (spo2: number) => spo2 < 80,
    unknown: () => true,
  },
});
