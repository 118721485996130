import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { SheetToolbarMode } from '../../interfaces/sheet-toolbar-props';
import EpochSelector from '../EpochSelector/EpochSelector';

interface EpochToolbarProps {
  classes: {
    control: string;
    legend: string;
  };
  toolbarMode: SheetToolbarMode;
}

const EpochToolbar = (props: EpochToolbarProps): JSX.Element => {
  return (
    <FormControl
      data-cy="epochPicker"
      className={props.classes.control}
      component="fieldset"
    >
      <FormHelperText component="legend" className={props.classes.legend}>
        Epoch
      </FormHelperText>
      <EpochSelector toolbarMode={props.toolbarMode} />
    </FormControl>
  );
};

export default EpochToolbar;
