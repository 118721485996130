import React from 'react';
import clsx from 'clsx';

import {
  makeStyles,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { LongRunningOperation } from '../../services/longRunningOperationService';
import TimeElapsedTimer from '../UploadRecording/TimeElapsedTimer';
import { ZIndex } from '../../utils/theme';

const useStyles = makeStyles((theme) => ({
  menu: {
    backgroundColor: theme.colors.modal.backgroundColor,
    color: theme.colors.modal.color,
    position: 'fixed',
    zIndex: ZIndex.Popup,
    bottom: 5,
    right: -350,
    width: 300,
    transition: '0.1s all linear',
  },
  open: {
    right: 5,
  },
  list: {
    width: '100%',
  },
  empty: {
    textAlign: 'center',
  },
  status: {
    display: 'inline',
    borderRadius: 5,
    padding: '1px 3px',
    fontSize: 13,
  },
  running: {
    backgroundColor: theme.colors.statuses.neutral,
    color: theme.palette.primary.contrastText,
  },
  failed: {
    backgroundColor: theme.colors.statuses.error,
    color: theme.palette.primary.contrastText,
  },
  finished: {
    backgroundColor: theme.colors.statuses.success,
    color: theme.palette.primary.contrastText,
  },
  timer: {
    textAlign: 'right',
    marginRight: 20,
  },
}));

interface LongRunningOperationsMenuProps {
  visible: boolean;
  operations: LongRunningOperation[];
}

const LongRunningOperationsMenu = (
  props: LongRunningOperationsMenuProps,
): JSX.Element => {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.menu, { [classes.open]: props.visible })}>
      <List dense className={classes.list}>
        {props.operations.map((op) => (
          <ListItem key={op.id}>
            <ListItemAvatar>
              <Avatar>
                {op.type === 'Import' && <CloudUploadIcon />}
                {op.type === 'Export' && <CloudDownloadIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={op.type}
              secondary={
                <div
                  className={clsx(classes.status, {
                    [classes.failed]: op.status === 'Failed',
                    [classes.finished]: op.status === 'Finished',
                    [classes.running]:
                      op.status === 'Created' || op.status === 'Running',
                  })}
                >
                  {op.status}
                </div>
              }
            />
            <ListItemText
              className={classes.timer}
              primary={
                <TimeElapsedTimer
                  from={op.addedTime}
                  current={op.finishedTime}
                  stop={op.status === 'Failed' || op.status === 'Finished'}
                />
              }
            />
            <ListItemSecondaryAction>
              {op.status === 'Finished' && op.successAction && (
                <IconButton
                  edge="end"
                  onClick={() =>
                    op.successAction ? op.successAction(op.data, op) : ''
                  }
                >
                  <LaunchIcon />
                </IconButton>
              )}

              {op.status === 'Failed' && op.errorAction && (
                <IconButton
                  edge="end"
                  onClick={() =>
                    op.errorAction ? op.errorAction(op.error, op) : ''
                  }
                >
                  <LaunchIcon />
                </IconButton>
              )}
              {op.status === 'Running' && (
                <IconButton edge="end">
                  <CircularProgress size={20} />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {props.operations.length === 0 && (
          <ListItem className={classes.empty}>
            <ListItemText primary="There are no running operations." />
          </ListItem>
        )}
      </List>
    </Paper>
  );
};

export default LongRunningOperationsMenu;
