import React from 'react';
import queryString from 'query-string';

import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { ApolloError, useApolloClient } from '@apollo/client';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import withNavigationBars from '../../hoc/withNavigationBars';
import {
  WithPageStatus,
  QueryParamsRecording,
} from '../../interfaces/page-status';
import withTheme, { WithTheme } from '../../hoc/withTheme';
import Analytics from '../../services/analytics';
import queryManager from '../../services/queryManager';
import { GetRecordingQueryResult, Recording } from '../../queries/recording';
import Logger from '../../utils/logger';
import RecordingNotFound from '../../components/RecordingNotFound/RecordingNotFound';
import NotificationService from '../../services/notificationService';
import sheetTools from '../../components/SignalSheet/sheetTools';
import ScoringService from '../../services/scoringService';
import chartRangeTools from '../../components/SignalSheet/chartRangeTools';
import studyTools from '../../components/StudyOverview/studyTools';
import AuthService from '../../services/authService';
import ReportHeader from '../../components/Report/ReportHeader';
import {
  AddReportCommentMutationParameters,
  GetReportQueryResult,
  Report,
  ReportCommentData,
} from '../../queries/report';
import ReportParameters from '../../components/Report/ReportParameters';
import { GetScoringQueryResult, Scoring } from '../../queries/scoring';
import { IgnoredMarkers } from '../../components/Chart/SignalChart/markerConstants';
import ReportCommentList from '../../components/Report/ReportCommentList';
import UserAttributesService from '../../services/userAttributesService';
import ReportScoring from '../../components/Report/ReportScoring';
import ReportPartGraphs from '../../components/Report/ReportPartGraphs';
import ToolsContainer from '../../components/Shared/ToolsContainer';
import ReportLanguageSelector from '../../components/Report/ReportLanguageSelector';
import PrintButton from '../../components/Shared/PrintButton';
import { LanguageDefault, LanguageSupportedLocale } from '../../i18n';
import AppTitle from '../../components/Shared/AppTitle';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    position: 'relative',
    gridTemplateRows:
      'min-content min-content min-content min-content minmax(60%, auto)',
    gap: theme.spacing(1),
    maxWidth: theme.variables.pageMaxWidth,
    margin: theme.spacing(1, 'auto'),
    height: '100%',
    '@media print': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  print: {},
  gap: { height: theme.spacing(0.5) },
}));

const ReportViewer = (props: WithTheme<WithPageStatus<unknown>>) => {
  NotificationService.useNotifications();

  const { i18n } = useTranslation();

  const componentRef = React.useRef<HTMLDivElement>(null);

  const [reportLocale, setReportLocale] =
    React.useState<LanguageSupportedLocale>(LanguageDefault);

  const [loading, setLoading] = React.useState(true);
  const [recording, setRecording] = React.useState<Recording>();
  const [scoring, setScoring] = React.useState<Scoring>();
  const [report, setReport] = React.useState<Report>();
  const [comments, setComments] = React.useState<ReportCommentData[]>();
  const [submittedComments, setSubmittedComments] =
    React.useState<ReportCommentData[]>();
  const [error, setError] = React.useState<string>();
  const [reportError, setReportError] = React.useState<string>();

  const [printing, setPrinting] = React.useState(false);

  const classes = useStyles();
  const client = useApolloClient();

  const location = useLocation();
  const params = queryString.parse(
    location.search,
  ) as unknown as QueryParamsRecording;

  const handleChangeReportLanguage = (newLocale: LanguageSupportedLocale) => {
    i18n.loadLanguages([newLocale]).then(() => setReportLocale(newLocale));
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: classes.print,
    documentTitle: `Study Report - ${sheetTools.getRecordingId()}`,
    onAfterPrint: () => setPrinting(() => false),
  });

  const handlePrint = () => {
    Analytics.track.event('REPORT_PRINT', {
      recordingId: sheetTools.getRecordingId(),
      scoringId: sheetTools.getScoringId(),
      scoringVersion: scoring?.version,
    });

    if (print) {
      setPrinting(true);
      window.requestAnimationFrame(print);
    }
  };

  const handleSubmitComment = (comment: string) => {
    return new Promise<boolean>((resolve, reject) => {
      const queryParams: AddReportCommentMutationParameters = {
        recordingId: sheetTools.getRecordingId(),
        scoringId: sheetTools.getScoringId(),
        scoringVersion: scoring?.version || '-1',
        message: comment,
      };
      queryManager
        .mutate('addReportComment', queryParams)
        .then(() => {
          setSubmittedComments((oldValue) => {
            const old = oldValue || [];

            return [
              ...old,
              {
                author: UserAttributesService.getUserId(),
                date: new Date(Date.now()),
                message: comment,
              },
            ];
          });

          resolve(true);
        })
        .catch((err) => reject(err));
    });
  };

  const waitForReportData = (
    recordindId: string,
    scoringId: string,
    version: string,
  ) => {
    Logger.log('[ReportViewer] waitForReportData');
    const timeout = 5 * 60 * 1000; // 5 mins
    const startTime = Date.now();

    const requestReport = (): Promise<Report> => {
      Logger.log('[ReportViewer] requestReport');
      return new Promise((resolve, reject) => {
        queryManager
          .query<GetReportQueryResult>('Report', {
            recordingId: recordindId,
            scoringId,
            scoringVersion: version,
          })
          .then((data) => {
            Logger.log('[ReportViewer] report data', data.report);
            if (data.report.insights) {
              ScoringService.setSubmittedPartId(999);
              setReport(data.report);
              setComments(data.report.comments);
              resolve(data.report);
            } else {
              setReportError('Missing insights');
              reject(new Error('Missing insights'));
            }
          })
          .catch((err) => {
            Logger.debug('[ReportViewer] Not found');
            Logger.debug(
              '[ReportViewer] Date.now() - startTime',
              Date.now() - startTime,
            );
            if (Date.now() - startTime < timeout) {
              setTimeout(
                () => requestReport().then(resolve).catch(reject),
                5000,
              );
            } else {
              setReportError(err);
              Logger.error(
                '[ReportViewer] Failed retrieving Report: %s',
                params.id,
                err,
              );
              reject(err);
            }
          });
      });
    };

    requestReport()
      .then((reportData: Report | void) => {
        if (!reportData) throw new Error('No report data');

        return queryManager.query('ReportComments', {
          recordingId: params.id,
          scoringId: sheetTools.getScoringId(),
          scoringVersion: reportData.scoringVersion,
        });
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    Analytics.track.page('Report');

    studyTools.initialize(params.id, props.pageStatus.setStatus);
    studyTools.clearSelectedPart();

    props.pageStatus.setStatus({
      ...props.pageStatus.status,
      currentPage: 'Report',
      recordingId: params.id,
      signalSheetTabs: studyTools.getSignalSheetTabs(),
    });

    if (!params.id) {
      setError('RecordingId was not specified');
    } else if (!params.scoringId) {
      setError('scoringId was not specified');
    } else {
      sheetTools.setRecordingId(params.id);
      sheetTools.setScoringId(params.scoringId);

      let recordingData: Recording | undefined;

      queryManager.initializeQueryManager(client);
      queryManager
        .query<GetRecordingQueryResult>('Recording', {
          recordingId: sheetTools.getRecordingId(),
        })
        .then((data) => {
          recordingData = data.recording;

          chartRangeTools.setRecordingStartTime(data.recording.startTime);
          chartRangeTools.setRecordingEndTime(data.recording.endTime);
        })
        .catch((err: ApolloError) => {
          setError(err.message);
          Logger.error(
            '[ReportViewer] Failed retrieving recording: %s',
            params.id,
            err,
          );
        })
        .then(() =>
          AuthService.requestAccessTokenIfNeeded({ query: 'Scoring' }),
        )
        .then(() =>
          queryManager.query<GetScoringQueryResult>('Scoring', {
            recordingId: params.id,
            scoringId: sheetTools.getScoringId(),
            ignoreMarkerTypes: IgnoredMarkers,
          }),
        )
        .then((data) => {
          setRecording(recordingData);
          setScoring(data.scoring);
          ScoringService.initialize(params.id);

          return data.scoring;
        })
        .catch((err: ApolloError) => {
          setError(err.message);
          Logger.error(
            '[ReportViewer] Failed retrieving Scoring: %s',
            params.id,
            err,
          );
        })
        .then((scoringData: Scoring | void) => {
          if (!scoringData) throw new Error('No scoring data');

          ScoringService.setReadOnly(scoringData.readOnly);
          waitForReportData(
            scoringData.recordingId,
            scoringData.scoringId,
            scoringData.version,
          );
        });
    }
  }, []);

  return (
    <>
      <AppTitle title="Report" />
      {!error && (
        <div className={classes.root} ref={componentRef}>
          <>
            <ReportHeader
              loading={loading}
              recording={recording}
              scoring={scoring}
              locale={reportLocale}
            />

            <>
              {!loading && report?.data && (
                <ToolsContainer>
                  <ReportLanguageSelector
                    locale={reportLocale}
                    onChangeReportLanguage={handleChangeReportLanguage}
                  />
                  <PrintButton handlePrint={handlePrint} printing={printing} />
                </ToolsContainer>
              )}
            </>

            {!reportError && (
              <>
                <ReportScoring
                  loading={loading}
                  recording={recording}
                  report={report}
                />
                <ReportPartGraphs
                  loading={loading}
                  recording={recording}
                  report={report}
                />
                <ReportCommentList
                  loading={loading}
                  comments={comments}
                  submittedComments={submittedComments}
                  error={reportError}
                  handleSubmitComment={handleSubmitComment}
                />
              </>
            )}
            <ReportParameters
              loading={loading}
              report={report}
              error={reportError}
              printing={printing}
            />
            <div className={classes.gap} />
          </>
        </div>
      )}
      {error && <RecordingNotFound message={error} />}
    </>
  );
};

export default withTheme(
  withNavigationBars(React.memo(ReportViewer, () => true)),
);
