import React from 'react';
import _ from 'underscore';
import { SignalDefinition } from '../../interfaces/sheet-definition';
import { Recording } from '../../queries/recording';
import SignalChart from '../Chart/SignalChart/SignalChart';
import chartTools from '../Chart/SignalChart/chartTools';
import chartRangeTools from './chartRangeTools';
import Logger from '../../utils/logger';
import sheetTools from './sheetTools';
import EpochChart from '../Chart/EpochChart/EpochChart';

interface SignalViewerProps {
  signals: SignalDefinition[];
  recording: Recording;
}

const SignalViewer = (props: SignalViewerProps): JSX.Element => {
  Logger.log('[SignalViewer]');
  const [signals, setSignals] =
    React.useState<SignalDefinition[] | undefined>();

  React.useEffect(() => {
    Logger.log('[SignalViewer] useEffect');
    chartTools.initializeChartTools();
    setSignals(props.signals);

    return () => {
      Logger.log('[SignalViewer] useEffect cleanup');
      chartRangeTools.initializeRangeTools('');
      chartTools.initializeChartTools();
      sheetTools.initializeSheetTools();
    };
  }, [props.signals]);

  return (
    <>
      {signals && <EpochChart recording={props.recording} />}
      {signals &&
        signals.map((signalInfo, index) => (
          <SignalChart
            key={`signal-chart${signalInfo.type}${_.uniqueId()}`}
            chartIndex={index}
            signalInfo={signalInfo}
            recordingId={props.recording.recordingId}
          />
        ))}
    </>
  );
};

export default SignalViewer;
