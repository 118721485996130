import _ from 'underscore';
import Logger from '../utils/logger';
import ScoringServiceInterface, {
  MarkerTypesToFetch,
  ScoringOperationResult,
  UpdateEventParams,
} from '../interfaces/scoring-service';
import queryManager from './queryManager';
import sheetTools from '../components/SignalSheet/sheetTools';
import {
  ScoringChanged,
  MarkerChangedMutationParameters,
} from '../queries/subscriptions/scoringChanged';
import eventChartTools from '../components/Chart/SignalChart/eventChartTools';
import {
  MarkerId,
  SignalEventDetailData,
} from '../interfaces/signal-event-detail-props';
import chartTools from '../components/Chart/SignalChart/chartTools';
import signalEventDetailService from '../components/SignalEventDetail/signalEventDetailService';
import EventService from './eventService';
import {
  SignalDefinition,
  SignalSubscription,
  SignalType,
} from '../interfaces/sheet-definition';
import SleepStageService from './sleepStageService';
import {
  GetScoringQueryResult,
  GetScoringQueryParameters,
} from '../queries/scoring';
import Analytics from './analytics';
import {
  MarkerDefinitions,
  IgnoredMarkers,
  MarkerGroups,
  ExtraOverlappingRules,
  DisableOverlappingRules,
} from '../components/Chart/SignalChart/markerConstants';
import AnalysisPeriodsService from './analysisPeriodsService';
import chartRangeTools from '../components/SignalSheet/chartRangeTools';
import { FeatureToggling } from '../utils/featureToggle';
import PositionLabelingService from './positionLabelingService';
import { MarkerType } from '../interfaces/markers';
import { ScoringCanvasEvent } from '../components/Chart/ScoringCanvas/ScoringCanvas';
import ScoringRenderingService from './scoringRenderingService';
import UndoService from './undoService';
import NotificationService from './notificationService';
import { MutationType } from '../interfaces/query-manager';
import { AnalysisPeriodsMutationParameters } from '../queries/analysisPeriods';
import TabSyncService from './tabSyncService';
import { ScoringInsightsUpdatedResult } from '../queries/subscriptions/scoringInsightsUpdated';

let retrievedInitialState = false;
let lastReceivedVersion: number | undefined;

let scoringConnection: ZenObservable.Subscription;
let insightsConnection: ZenObservable.Subscription;

let timeout: NodeJS.Timeout;

export type Events = SignalEventDetailData[];
export type Epoch = Events;
export type Epochs = Epoch[];
export type Scoring = Epochs;

export interface ScoringMutation {
  type: MutationType;
  timestamp: number;
  params: MarkerChangedMutationParameters | AnalysisPeriodsMutationParameters;
}

export interface MutationQueueStatus {
  queueLength: number;
  inProgress: boolean;
  failed: boolean;
}

/**
 * Object that stores all the markers with this format:
 * markerType -> epochs array -> array of markers
 */
const scoringStorage = new Map<MarkerType, Scoring>();

/**
 * Dictionary to quickly find the attributes of a marker given the markerId
 */
const markerIdLocator: Map<MarkerId, SignalEventDetailData> = new Map();

/**
 * List with all the pending scoring mutations to be sent to GQL
 */
const mutationsQueue: ScoringMutation[] = [];

/**
 * When we change to a new recording, first we get the initial state and then we
 * subscribe to changes. If we happen to receive new changes before the initial
 * state call has finished, we store all those changes on a queue. Once we receive
 * the initial state, we process it and then we process the reconciliationWaitingQueue.
 * After this happens, we continue with the subscription as always.
 */
const reconciliationWaitingQueue: ScoringChanged[] = [];

/**
 * A list generated after processing the Sheet Definition.
 * It includes all the markers the signals are interested on
 */
const markerTypesToFetch: MarkerTypesToFetch = [];

/**
 * A map that links the marker types with the signals that might
 * be interested on. Obtained after processing the Sheet Definition.
 */
const markerTypeToSignal: Map<MarkerType, SignalSubscription[]> = new Map();

/**
 * Attribute that controls many elements on the screen to be hidden
 * or disabled when a part has been submitted
 */
let isReadOnlyScoring = false;

/**
 * Used to let the system know that a change in isReadOnlyScoring attribute
 * is expected because the user has done it, and not because it's coming
 * from the subscription
 */
let submittingPart = false;

let restoreMutationQueueLength = 0;

let submittedPartId: number | undefined;

let recordingId = '';

let mutationInProgress = false;
let mutationErrorCount = 0;
const ERROR_LIMIT = 3;

const ScoringService: ScoringServiceInterface = {
  initialize: (currentRecordingId: string) => {
    Logger.log('[ScoringService] Initializing');

    if (recordingId !== currentRecordingId) {
      ScoringService.cleanup();

      recordingId = currentRecordingId;
      SleepStageService.initialize();
      PositionLabelingService.initialize();
      eventChartTools.initializeEventIntensity();

      ScoringService.getInitialState();
      AnalysisPeriodsService.getPeriods(
        recordingId,
        sheetTools.getScoringId(),
      ).catch((error) => Logger.error(error));
      ScoringService.subscribe();
    } else {
      Logger.log(
        '[ScoringService] Skipping clean because previous recordingId matches:',
      );
      Logger.log('[ScoringService] --> currentRecordingId', currentRecordingId);
      Logger.log('[ScoringService] --> recordingId', recordingId);
    }
  },
  cleanup: () => {
    Logger.log('[ScoringService] Cleaning state');

    ScoringService.unsubscribe();

    submittingPart = false;
    submittedPartId = undefined;
    ScoringService.setReadOnly(false);

    ScoringService.setInitialStateReceived(false);
    reconciliationWaitingQueue.splice(0, reconciliationWaitingQueue.length);
    ScoringService.setLastReceivedVersion();

    scoringStorage.clear();
    markerIdLocator.clear();

    recordingId = '';
  },
  getInitialState: () => {
    const params: GetScoringQueryParameters = {
      recordingId: sheetTools.getRecordingId(),
      scoringId: sheetTools.getScoringId(),
      ignoreMarkerTypes: IgnoredMarkers,
    };
    Logger.log('[ScoringService] Requesting initial state:', params);
    queryManager
      .query<GetScoringQueryResult>('Scoring', params)
      .then((data) => {
        Logger.log('[ScoringService] Got data!');
        EventService.dispatch('ScoringInitialStateReceived');

        ScoringService.setReadOnly(data.scoring.readOnly);

        const scoringChanged: ScoringChanged = {
          scoringChanged: {
            recordingId: params.recordingId,
            scoringId: params.scoringId,
            version: data.scoring.version,
            markers: data.scoring.markers,
            readOnly: data.scoring.readOnly,
            scoring: { name: data.scoring.name },
          },
        };
        ScoringService.onScoringChange(
          { data: scoringChanged },
          { isInitialState: true },
        );
        ScoringService.reconciliate();

        if (!FeatureToggling.isMode('development')) {
          chartTools.areasOfInterest.preload();
        }
      })
      .catch((error) => {
        ScoringService.setInitialStateReceived(true);
        Logger.error(
          '[ScoringService] Failed retrieving the initial state for:',
          params,
        );
        Logger.error('[ScoringService] --> error:', error);
      })
      .finally(() => {
        ScoringService.restoreMutationQueue();
      });
  },
  subscribe: () => {
    EventService.dispatch('WebsocketReconnect');
    Logger.log(
      '[ScoringService][Resubscribe] Subscribing with params:',
      ScoringService.generateSubscriptionParameters(),
    );
    scoringConnection = queryManager.subscribe(
      'scoringChanged',
      ScoringService.generateSubscriptionParameters(),
      ScoringService.onScoringChange,
      ScoringService.onConnectionError,
    );

    insightsConnection = queryManager.subscribe(
      'scoringInsightsUpdated',
      ScoringService.generateSubscriptionParameters(),
      ScoringService.onScoringInsightsUpdated,
      ScoringService.onConnectionError,
    );
  },
  reconciliate: () => {
    Logger.log(
      '[ScoringService] Reconciliating %d scoringChanges.',
      reconciliationWaitingQueue.length,
    );
    while (reconciliationWaitingQueue.length) {
      Logger.log('[ScoringService] Reconciliating...');
      ScoringService.onScoringChange(
        { data: reconciliationWaitingQueue.reverse().pop() },
        { isInitialState: true },
      );
    }
    ScoringService.setInitialStateReceived(true);
    Logger.log('[ScoringService] Reconciliation done.');
  },

  unsubscribe: () => {
    if (scoringConnection && insightsConnection) {
      Logger.log('[ScoringService] Unsubscribing');
      clearTimeout(timeout);
      scoringConnection.unsubscribe();
      insightsConnection.unsubscribe();
      EventService.dispatch('WebsocketReconnect');
    }
  },
  onConnectionError: (error: unknown) => {
    Logger.error('[ScoringService] Subscription connection error:', error);
  },
  generateSubscriptionParameters: () => ({
    recordingId: sheetTools.getRecordingId(),
    scoringId: sheetTools.getScoringId(),
  }),
  onScoringChange: (
    change: { data?: ScoringChanged },
    params?: { isInitialState?: boolean },
  ) => {
    Logger.log('[ScoringService] Change received:', change);
    EventService.dispatch('Subscription.ScoringChanged');

    if (change.data) {
      const { data } = change;
      if (data.scoringChanged.recordingId === sheetTools.getRecordingId()) {
        if (retrievedInitialState || params?.isInitialState) {
          if (
            lastReceivedVersion === undefined ||
            lastReceivedVersion < parseInt(data.scoringChanged.version, 10)
          ) {
            Logger.log(
              '[ScoringService] New lastReceivedVersion:',
              data.scoringChanged.version,
            );
            ScoringService.setLastReceivedVersion(data.scoringChanged.version);
          }

          if (data.scoringChanged.readOnly !== ScoringService.isReadOnly()) {
            ScoringService.setReadOnly(data.scoringChanged.readOnly);

            if (data.scoringChanged.readOnly && !submittingPart) {
              NotificationService.send(
                'Another user has submitted a part for this scoring. ' +
                  'No more changes will be allowed.',
                { variant: 'info', action: 'dismiss' },
              );
              submittingPart = false;
            }
          }

          if (
            !!data.scoringChanged.markers &&
            data.scoringChanged.markers.length > 0
          ) {
            data.scoringChanged.markers.forEach((marker) =>
              eventChartTools.processMarkerChange(marker),
            );
          }
          Logger.log('[ScoringService] Change processed.');
        } else {
          Logger.log(
            '[ScoringService] Initial state not present. Storing for reconciliation',
          );
          reconciliationWaitingQueue.push(data);
        }
      }
    } else {
      Logger.log(
        '[ScoringService] Received onScoringChange for a different recordingId. Skipping.',
      );
    }
  },
  onScoringInsightsUpdated: (change: {
    data?: ScoringInsightsUpdatedResult;
  }) => {
    Logger.log('[ScoringService] Scoring Insights updated:', change);
    if (change.data) {
      const { data } = change;
      if (
        data.scoringInsightsUpdated.recordingId === sheetTools.getRecordingId()
      ) {
        EventService.dispatch('ScoringInsightsUpdated', data);

        const analysisPeriods = AnalysisPeriodsService.scoringInsightsToPeriods(
          data.scoringInsightsUpdated,
        );

        if (AnalysisPeriodsService.isNewChange(analysisPeriods)) {
          Logger.log('[ScoringService] Periods have changed:', analysisPeriods);
          AnalysisPeriodsService.processPeriods(analysisPeriods);
        }
      } else {
        Logger.warn(
          '[ScoringService][onScoringInsightsUpdated] Received event for a different recordingId.',
        );
      }
    }
  },
  storeEvent: (event: SignalEventDetailData, parameters) => {
    // Logger.debug('[ScoringService][storeEvent][%s] Storing event:', event.type, event);
    const { send, local, updating, fromUndo, withUndoBulkId } = parameters;

    let newEvent = { ...event };

    if (send && !updating && !ScoringService.scoringIsAllowed()) {
      Logger.error(
        '[ScoringService][storeEvent] Change stopped because scoring is in read-only mode',
      );
      return false;
    }

    if (send) {
      const validatedEvent =
        ScoringService.validateMarkerInsideSelectedPart(newEvent);
      if (validatedEvent) {
        newEvent = validatedEvent;
      } else {
        Logger.error(
          '[ScoringService][storeEvent] Update was cancelled because out-of-bounds.',
        );
        EventService.dispatch('ScoringChanged', newEvent);
        return false;
      }
    }

    const storeResult: ScoringOperationResult = {
      operation: 'Add',
    };

    if (
      !local &&
      signalEventDetailService.popup.isOpenAndSameMarkerId(newEvent.id)
    ) {
      signalEventDetailService.popup.close();
    }

    if (!updating) {
      markerIdLocator.set(newEvent.id, newEvent);
    }

    ScoringService.updateMarkerHistory(newEvent, { wasLocalChange: !!local });

    if (!scoringStorage.has(newEvent.type)) {
      scoringStorage.set(newEvent.type, []);
    }
    const eventData = scoringStorage.get(newEvent.type);

    if (eventData) {
      ScoringService.iterateEpochsAndExecute(
        newEvent.start,
        newEvent.end,
        (epoch) => {
          /* Logger.debug(
          '[ScoringService][storeEvent][%s] Inserting event on epoch:',
          event.type,
          epoch
        ); */
          if (!eventData[epoch]) eventData[epoch] = [];
          eventData[epoch].push(newEvent);
          eventChartTools.incrementEventIntensity(epoch, newEvent.type);

          if (eventChartTools.isSleepStageMarker(newEvent)) {
            SleepStageService.addStage(newEvent);
          }
        },
      );

      chartTools.restyleAndRedrawNavigator();
      EventService.dispatch('ScoringChanged', newEvent);

      if (send) {
        const mutation = ScoringService.getMarkerMutationParameters(newEvent);

        if (newEvent.signalInfo) {
          eventChartTools.setDefaultMarkerTypeBySignalType(
            newEvent.signalInfo.type,
            newEvent.type,
          );
          eventChartTools.setDefaultMarkerDurationBySignalType(
            newEvent.signalInfo.type,
            mutation.durationMilliseconds,
          );
        }

        ScoringService.addScoringMutation(mutation);
        ScoringService.dispatchEvents(newEvent);

        if (!fromUndo) {
          EventService.dispatch('Scoring.ActiveMarker', newEvent);
        }

        if (!updating) {
          Analytics.track.scoringChange({
            type: newEvent.type,
            group:
              MarkerDefinitions.get(newEvent.type)?.markerGroup || 'Unknown',
            recordingId,
            action: 'Add',
            markerId: newEvent.id,
          });

          if (!fromUndo) {
            const undoRecipe = UndoService.addUndoRecipe({
              type: 'ScoreEvent',
              targetEpoch: sheetTools.getSelectedEpoch(),
              bulkId: withUndoBulkId,
              undo: {
                run: () =>
                  ScoringService.removeEvent(newEvent.id, {
                    send: true,
                    fromUndo: true,
                  }),
              },
              redo: {
                run: () =>
                  ScoringService.updateEvent(
                    { ...newEvent, deleted: false },
                    {
                      operation: 'Add',
                      send: true,
                      local: true,
                      fromUndo: true,
                    },
                  ),
              },
            });
            storeResult.undoRecipe = undoRecipe;

            ScoringService.preventIllegalOverlapping(newEvent, undoRecipe.id);
          }
        }
      }
    }

    return storeResult;
  },
  removeEvent: (markerId: string, parameters) => {
    Logger.log(
      '[ScoringService][removeEvent][%s] Removing event with id:',
      markerId,
    );
    const { send, updating, fromUndo, withUndoBulkId } = parameters;
    const marker = ScoringService.findEventById(markerId);

    const removeResult: ScoringOperationResult = {
      operation: 'Delete',
    };

    if (marker) {
      if (send && !updating && !ScoringService.scoringIsAllowed()) {
        Logger.error(
          '[ScoringService][removeEvent] Change stopped because scoring is in read-only mode',
        );
        return false;
      }

      if (
        !updating &&
        signalEventDetailService.popup.isOpenAndSameMarkerId(markerId)
      ) {
        signalEventDetailService.popup.close();
      }

      const eventData = scoringStorage.get(marker.type);

      if (eventData) {
        ScoringService.iterateEpochsAndExecute(
          marker.start,
          marker.end,
          (epoch) => {
            Logger.debug(
              '[ScoringService][removeEvent] Removing event from epoch:',
              epoch,
            );
            if (!eventData[epoch]) {
              eventData[epoch] = [];
              Logger.warn(
                '[ScoringService][removeEvent] Epoch did not exist. Is network slow?',
                epoch,
              );
            }
            eventData[epoch] = eventData[epoch].filter(
              (event) => event.id !== marker.id,
            );
            eventChartTools.decrementEventIntensity(epoch, marker.type);

            if (eventChartTools.isSleepStageMarker(marker)) {
              SleepStageService.deleteStage(marker);
            }
          },
        );

        if (!updating) {
          const deletedMarker = { ...marker, deleted: true };
          markerIdLocator.set(markerId, deletedMarker);
          ScoringService.updateMarkerHistory(deletedMarker, {
            wasLocalChange: true,
          });
          EventService.dispatch('ScoringChanged', deletedMarker);
          chartTools.restyleAndRedrawNavigator();
        }

        if (send) {
          const mutation = ScoringService.getMarkerMutationParameters(marker);

          ScoringService.addScoringMutation({ ...mutation, deleted: true });
          ScoringService.dispatchEvents(marker);

          if (!withUndoBulkId) {
            EventService.dispatch('Scoring.CancelActiveMarker');
          }

          Analytics.track.scoringChange({
            type: marker.type,
            group: MarkerDefinitions.get(marker.type)?.markerGroup || 'Unknown',
            recordingId,
            action: 'Delete',
            markerId: marker.id,
          });

          if (!fromUndo) {
            const undoRecipe = UndoService.addUndoRecipe({
              type: 'DeleteEvent',
              bulkId: withUndoBulkId,
              targetEpoch: sheetTools.getSelectedEpoch(),
              undo: {
                run: () =>
                  ScoringService.updateEvent(
                    { ...marker, deleted: false },
                    {
                      operation: 'Add',
                      send: true,
                      local: true,
                      fromUndo: true,
                    },
                  ),
              },
              redo: {
                run: () =>
                  ScoringService.removeEvent(marker.id, {
                    send: true,
                    fromUndo: true,
                  }),
              },
            });

            removeResult.undoRecipe = undoRecipe;
          }
        }
      }
    }

    return removeResult;
  },
  updateEvent: (event: SignalEventDetailData, parameters) => {
    let newEvent = event;
    Logger.log(
      '[ScoringService][updateEvent][%s] Updating event:',
      newEvent.type,
      newEvent,
    );
    const {
      send,
      local,
      ignoreOverrideFn,
      fromUndo,
      overrideMarkerInsideValidation,
    } = parameters;
    const originalMarker = markerIdLocator.get(newEvent.id);

    if (send && !ScoringService.scoringIsAllowed()) {
      Logger.error(
        '[ScoringService][updateEvent] Change stopped because scoring is in read-only mode',
      );
      return false;
    }

    const updateResult: ScoringOperationResult = {
      operation: parameters.operation,
    };

    if (send) {
      const validatedEvent = overrideMarkerInsideValidation
        ? newEvent
        : ScoringService.validateMarkerInsideSelectedPart(newEvent);

      if (validatedEvent) {
        newEvent = validatedEvent;
      } else {
        Logger.error(
          '[ScoringService][updateEvent][%s] Event is out-of-bounds',
          newEvent.type,
        );
        Logger.log(
          '[ScoringService][updateEvent][%s] Recovering original marker',
        );

        if (originalMarker) {
          newEvent = { ...originalMarker };
        } else {
          Logger.error(
            '[ScoringService][updateEvent][%s] Could not find original event. Exiting.',
          );
          return false;
        }
      }
    }

    newEvent.modifiedDate = Date.now();

    const overrideOnChangeFn = eventChartTools.getOnChangeFn(newEvent.type);
    if (ignoreOverrideFn || !overrideOnChangeFn) {
      if (local) {
        Analytics.track.scoringChange({
          type: newEvent.type,
          group: MarkerDefinitions.get(newEvent.type)?.markerGroup || 'Unknown',
          recordingId,
          action: parameters.operation,
          movementDelta: parameters.diff,
          markerId: newEvent.id,
        });
      }

      ScoringService.removeEvent(newEvent.id, {
        send: false,
        updating: true,
        fromUndo,
      });
      ScoringService.storeEvent(newEvent, {
        send,
        local,
        updating: true,
        fromUndo,
      });

      if (originalMarker && !fromUndo) {
        const updateParameters: UpdateEventParams = {
          operation: 'Add',
          send: true,
          local: true,
          fromUndo: true,
        };

        const undoRecipe = UndoService.addUndoRecipe({
          type: 'UpdateEvent',
          targetEpoch: sheetTools.getSelectedEpoch(),
          undo: {
            run: () =>
              ScoringService.updateEvent(
                { ...originalMarker },
                updateParameters,
              ),
          },
          redo: {
            run: () =>
              ScoringService.updateEvent({ ...newEvent }, updateParameters),
          },
        });

        ScoringService.preventIllegalOverlapping(newEvent, undoRecipe.id);

        updateResult.undoRecipe = undoRecipe;
      }
    } else {
      Logger.log(
        '[ScoringService][updateEvent][%s] Overriding function!',
        newEvent.type,
      );
      overrideOnChangeFn(
        recordingId,
        sheetTools.getScoringId(),
        newEvent,
        parameters,
      );
    }

    return updateResult;
  },
  preventIllegalOverlapping: (
    newMarker: SignalEventDetailData,
    undoId: string,
  ) => {
    Logger.log('[ScoringService][preventIllegalOverlapping] Init', newMarker);
    return new Promise((resolve) => {
      const newMarkerGroup = MarkerDefinitions.get(newMarker.type)?.markerGroup;

      if (newMarkerGroup) {
        if (!DisableOverlappingRules.has(newMarkerGroup)) {
          const illegalOverlappingEventTypes = MarkerGroups.getEventTypes([
            newMarkerGroup,
          ]);
          const extraIllegalMarkerGroup =
            ExtraOverlappingRules.get(newMarkerGroup);
          if (extraIllegalMarkerGroup) {
            illegalOverlappingEventTypes.push(
              ...MarkerGroups.getEventTypes(extraIllegalMarkerGroup),
            );
          }

          Logger.log(
            '[ScoringService][preventIllegalOverlapping] Illegal event types',
            illegalOverlappingEventTypes,
          );
          ScoringService.getAllMarkersForCurrentPart()
            .then((allMarkers) => {
              const illegalMarkers = allMarkers.filter((marker) => {
                let isIllegalMarker = false;
                const isSameMarker = marker.id === newMarker.id;
                const isSameSignal = marker.scoredFrom === newMarker.scoredFrom;
                if (!isSameMarker && isSameSignal) {
                  const isIllegalType = illegalOverlappingEventTypes.includes(
                    marker.type,
                  );
                  if (isIllegalType) {
                    const areOverlapping =
                      eventChartTools.areMarkersOverlapping(newMarker, marker);
                    if (areOverlapping) {
                      isIllegalMarker = true;
                    }
                  }
                }
                return isIllegalMarker;
              });

              Logger.log(
                '[ScoringService][preventIllegalOverlapping] illegalMarkers',
                illegalMarkers,
              );
              return illegalMarkers;
            })
            .then((illegalMarkers) =>
              illegalMarkers.forEach((marker) =>
                ScoringService.removeEvent(marker.id, {
                  send: true,
                  withUndoBulkId: undoId,
                }),
              ),
            )
            .then(() => resolve);
        }
      } else {
        Logger.log(
          '[ScoringService][preventIllegalOverlapping] Skipping: %s has the overlapping disabled',
          newMarkerGroup,
        );
        resolve();
      }
    });
  },
  dispatchEvents: (newMarker: SignalEventDetailData) => {
    const eventsForMarkerType = MarkerDefinitions.get(
      newMarker.type,
    )?.dispatchEventOnChange;

    eventsForMarkerType?.forEach((eventType) =>
      EventService.dispatch(eventType, newMarker),
    );
  },
  updateMarkerHistory: (
    marker: SignalEventDetailData,
    opts: { wasLocalChange: boolean },
  ) => {
    const changeHistory =
      ScoringService.findEventById(marker.id)?.history || [];

    if (opts.wasLocalChange) {
      changeHistory.push(eventChartTools.toMarkerChangeId(marker));
    }

    const newMarker: SignalEventDetailData = {
      ...marker,
      history: changeHistory,
    };
    // Logger.debug('[ScoringService][updateMarkerHistory] newMarker', newMarker);
    markerIdLocator.set(marker.id, newMarker);
  },
  addMutation: (
    type: MutationType,
    mutation:
      | MarkerChangedMutationParameters
      | AnalysisPeriodsMutationParameters,
  ) => {
    Logger.log('[ScoringService][addMutation] Mutation added:', mutation);
    mutationsQueue.push({ type, timestamp: Date.now(), params: mutation });
    TabSyncService.updateSavedMutationQueue(mutationsQueue);

    if (!mutationInProgress) {
      ScoringService.processMutationQueue();
    } else {
      Logger.log(
        '[ScoringService][addMutation] Waiting. Mutation in progress.',
      );
    }
  },
  addScoringMutation: (mutation: MarkerChangedMutationParameters) => {
    ScoringService.addMutation('marker', mutation);
  },
  sendMutationQueueStatus: () =>
    EventService.dispatch('ScoringMutationsStatus', {
      queueLength: mutationsQueue.length,
      inProgress: mutationInProgress,
      failed: mutationErrorCount > 0,
    } as MutationQueueStatus),
  processMutationQueue: () => {
    mutationInProgress = true;
    Logger.debug(
      '[ScoringService][processMutationQueue] mutationsQueue:',
      mutationsQueue,
    );
    const nextMutation = mutationsQueue
      .sort((a, b) => b.timestamp - a.timestamp)
      .pop();
    TabSyncService.updateSavedMutationQueue(mutationsQueue);

    Logger.debug(
      '[ScoringService][processMutationQueue] nextMutation:',
      nextMutation,
    );

    if (nextMutation) {
      queryManager
        .mutate(nextMutation.type, nextMutation.params)
        .then(() => {
          mutationErrorCount = 0;
          Logger.log(
            '[ScoringService][processMutationQueue] Mutation was sent successfully!',
          );
        })
        .catch((error: unknown) => {
          Logger.error(
            '[ScoringService][processMutationQueue] Error sending marker changed!',
            nextMutation,
          );
          Logger.error(
            '[ScoringService][processMutationQueue] --> error',
            error,
          );
          mutationErrorCount += 1;
          ScoringService.addMutation(nextMutation.type, nextMutation.params);
        })
        .finally(() => {
          ScoringService.sendMutationQueueStatus();
          if (mutationErrorCount < ERROR_LIMIT) {
            Logger.log(
              '[ScoringService][processMutationQueue] Calling to myself again.',
            );
            ScoringService.processMutationQueue();
          } else {
            Logger.log(
              '[ScoringService][processMutationQueue] Too many errors! Waiting...',
            );
            setTimeout(() => ScoringService.processMutationQueue(), 5000);
          }
        });
    } else {
      mutationInProgress = false;
      Logger.log(
        '[ScoringService][processMutationQueue] No more mutations in the queue.',
      );
    }
    ScoringService.sendMutationQueueStatus();
  },
  restoreMutationQueue: () => {
    if (mutationsQueue.length === 0) {
      const savedMutationQueue = TabSyncService.getSavedMutationQueue();
      if (savedMutationQueue.length > 0) {
        if (!isReadOnlyScoring) {
          ScoringService.setRestoreMutationQueueLength(
            savedMutationQueue.length,
          );
          Logger.log(
            '[ScoringService][restoreMutationQueue] Restoring, but giving time to subscription to connect...',
          );
          savedMutationQueue
            .sort((a, b) => b.timestamp - a.timestamp)
            .forEach((mutation) =>
              setTimeout(
                () =>
                  ScoringService.addMutation(mutation.type, mutation.params),
                3000,
              ),
            );
        } else {
          setTimeout(() =>
            EventService.dispatch('Scoring.MutationQueueClearedReadOnly', 3000),
          );
          TabSyncService.updateSavedMutationQueue([]);
          TabSyncService.getOtherMutationQueue();
        }
      } else {
        TabSyncService.getOtherMutationQueue();
      }
    } else {
      ScoringService.setRestoreMutationQueueLength(0);
      TabSyncService.getOtherMutationQueue();
    }
  },
  findEventById: (markerId: string) => markerIdLocator.get(markerId),
  getAllMarkers: () => Array.from(markerIdLocator.values()),
  getAllMarkersForCurrentPart: () => {
    return new Promise((resolve) => {
      Logger.log('[getAllMarkersForCurrentPart] init');
      const markersIterable = markerIdLocator.values();
      const markers = Array.from(markersIterable);
      const markersCurrentPart = markers.filter(
        (marker) =>
          !marker.deleted &&
          (chartRangeTools.isTimestampWithinPart(marker.start) ||
            chartRangeTools.isTimestampWithinPart(marker.end)),
      );
      const sortedMarkers = markersCurrentPart.sort(
        (a, b) => a.start - b.start,
      );
      resolve(sortedMarkers);
    });
  },
  getMarkerStorageSize: () => markerIdLocator.size,
  removeOlderMarkerChangeId: (markerId: string) => {
    const marker = markerIdLocator.get(markerId);
    if (marker) {
      markerIdLocator.set(markerId, {
        ...marker,
        history: marker.history.slice(1),
      });
    }
  },
  clearMarkerHistory: (markerId: string) => {
    const marker = markerIdLocator.get(markerId);
    if (marker) {
      markerIdLocator.set(markerId, { ...marker, history: [] });
    }
  },
  getMarkerMutationParameters: (event: SignalEventDetailData) => ({
    recordingId: sheetTools.getRecordingId(),
    scoringId: sheetTools.getScoringId(),
    markerId: event.id,
    eventType: event.type,
    signal: event.signalInfo ? event.signalInfo.type : undefined,
    timestamp: event.start,
    durationMilliseconds: Math.round(event.end - event.start),
    deleted: event.deleted,
  }),
  iterateEpochsAndExecute: (
    start: number,
    end: number,
    operation: (epoch: number) => void,
  ) => {
    const firstEpoch = chartRangeTools.convertToEpoch(start);
    let lastEpoch = chartRangeTools.convertToEpoch(end - 1); // [start, end)

    if (lastEpoch < firstEpoch) {
      lastEpoch = firstEpoch;
    }

    for (let epoch = firstEpoch; epoch <= lastEpoch; epoch += 1) {
      operation(epoch);
    }
  },
  retrieveMarkersByType: (markerTypes: MarkerType[]) => {
    Logger.log('[retrieveMarkersByType] markerTypes', markerTypes);
    const time = Date.now();

    const markers: SignalEventDetailData[] = [];

    markerTypes.forEach((markerType) => {
      const allMarkersOfThisType = scoringStorage.get(markerType);
      if (allMarkersOfThisType) {
        const flattenedMarkersOfThisType = allMarkersOfThisType.flat();
        const markersWithoutDuplicates = _.uniq(flattenedMarkersOfThisType);
        markers.push(...markersWithoutDuplicates);
      }
    });

    Logger.log('[retrieveMarkersByType] markers', markers);
    Logger.log(
      '[retrieveMarkersByType] Done! Took (in ms):',
      Date.now() - time,
    );
    return markers;
  },
  retrieveMarkersForExtremes: () => {
    /**
     * Used to prevent duplicated events (when looking at more than 1 epoch).
     */
    const markers: Map<SignalType, ScoringCanvasEvent[]> = new Map();

    /**
     * Object that will be returned with events (+ UI Positions) ready to be displayed.
     */
    const scoringCanvasEvents: ScoringCanvasEvent[] = [];

    const { min, max } = chartRangeTools.getCurrentExtremes();
    const markersTime = Date.now();
    // Get all needed markers for this period based to the marker list.
    //   --> Insert each marker on the corresponding Signal/s on the return object
    ScoringService.getMarkerTypesToFetch().forEach((markerType) => {
      if (scoringStorage.has(markerType)) {
        const eventData = scoringStorage.get(markerType);
        if (eventData) {
          ScoringService.iterateEpochsAndExecute(min, max, (epoch) => {
            if (eventData[epoch]) {
              eventData[epoch].forEach((marker) => {
                const signalSubscriptions =
                  ScoringService.getMarkerTypeToSignalMap().get(markerType);

                if (signalSubscriptions) {
                  signalSubscriptions.forEach((sub) => {
                    const shouldAdd =
                      sub.forAnySignal ||
                      marker.scoredFrom === sub.signalType ||
                      marker.scoredFrom === sub.scoredOnSignal;

                    const signalDistanceFromTop =
                      sheetTools.getDistanceFromTopForSignal(sub.signalType);
                    const signalHeight = sheetTools.getHeightForSignal(
                      sub.signalType,
                    );

                    if (shouldAdd) {
                      const markersForThisSignal =
                        markers.get(sub.signalType) ?? [];

                      const alreadyAdded = scoringCanvasEvents.some(
                        (storedMarker) => storedMarker.id === marker.id,
                      );

                      if (!alreadyAdded) {
                        const newMarker =
                          ScoringRenderingService.eventDetailDataToCanvasEvent(
                            marker,
                            min,
                            max,
                            markersTime,
                            sub.signalType,
                            signalDistanceFromTop,
                            signalHeight,
                          );

                        markersForThisSignal.push(newMarker);
                        scoringCanvasEvents.push(newMarker);
                        markers.set(sub.signalType, markersForThisSignal);
                      }
                    }
                  });
                } else {
                  // Event with no signal. Probably Analysis Period or Invalid Signal
                  const newMarker =
                    ScoringRenderingService.eventDetailDataToCanvasEvent(
                      marker,
                      min,
                      max,
                      markersTime,
                    );

                  const alreadyAdded = scoringCanvasEvents.some(
                    (storedMarker) => storedMarker.id === newMarker.id,
                  );

                  if (!alreadyAdded) {
                    scoringCanvasEvents.push(newMarker);
                  }
                }
              });
            }
          });
        }
      }
    });

    Logger.log(
      '[retrieveMarkersForExtremes] markersTotal',
      scoringCanvasEvents.length,
    );
    Logger.log('[retrieveMarkersForExtremes] markers', markers);
    Logger.log('[retrieveMarkersForExtremes] scoringStorage', scoringStorage);
    Logger.log(
      '[retrieveMarkersForExtremes] scoringCanvasEvents',
      scoringCanvasEvents,
    );
    Logger.log('[retrieveMarkersForExtremes] took:', Date.now() - markersTime);
    return scoringCanvasEvents;
  },
  processActiveSignalSubscriptions: () => {
    Logger.log('[processSignalSubscriptions] init');
    const time = Date.now();

    // Create object that associates eventTypes to Signals. Process the SheetDefinition.
    //   TODO move somewhere outside here
    markerTypeToSignal.clear();
    markerTypesToFetch.splice(0, markerTypesToFetch.length);

    // Create list of all the types I need to extract for this sheet.
    //   TODO move somewhere outside here
    const markerTypeList: MarkerType[] = [];
    const signals: SignalDefinition[] = sheetTools.getActiveSignals();
    signals.forEach((signal) =>
      signal.markerSubscription?.forEach((sub) => {
        markerTypeList.push(...sub.markerTypes);
        sub.markerTypes.forEach((markerType) => {
          const signalList: SignalSubscription[] =
            markerTypeToSignal.get(markerType) ?? [];
          signalList.push({
            signalType: signal.type,
            scoredOnSignal: sub.scoredOnSignal,
            forAnySignal: !!sub.forAnySignal,
          });
          markerTypeToSignal.set(markerType, signalList);
        });

        const signalList: SignalSubscription[] =
          markerTypeToSignal.get('web-custom') ?? [];
        signalList.push({
          signalType: signal.type,
        });
        markerTypeToSignal.set('web-custom', signalList);
      }),
    );
    markerTypeList.push('web-custom');
    markerTypeList.push('signal-invalid');
    markerTypeList.push(...MarkerGroups.getEventTypes(['Analysis Period']));
    const uniqueMarkerTypeList = _.uniq(markerTypeList);
    markerTypesToFetch.push(...uniqueMarkerTypeList);
    Logger.log(
      '[processSignalSubscriptions] uniqueMarkerTypesToFetch',
      markerTypesToFetch,
    );
    Logger.log(
      '[processSignalSubscriptions] markerTypeToSignal',
      markerTypeToSignal,
    );
    Logger.log(
      '[processSignalSubscriptions] Finishing... Took (ms):',
      Date.now() - time,
    );
  },
  retrieveMarkerTypesWithinTimePeriod: (
    markerTypes: MarkerType[],
    startTime: number,
    endTime: number,
  ) => {
    Logger.log('[retrieveMarkerTypesWithinTimePeriod] startTime', startTime);
    Logger.log('[retrieveMarkerTypesWithinTimePeriod] endTime', endTime);

    const markers = Array.from(markerIdLocator.values()).filter(
      (marker) =>
        marker.deleted !== true &&
        markerTypes.includes(marker.type) &&
        eventChartTools.isMarkerWithinTimePeriod(marker, startTime, endTime),
    );
    Logger.log('[retrieveMarkerTypesWithinTimePeriod] markers', markers);

    return markers;
  },
  getMarkerTypeToSignalMap: () => markerTypeToSignal,
  getMarkerTypesToFetch: () => markerTypesToFetch,
  validateMarkerInsideSelectedPart: (event: SignalEventDetailData) => {
    const validatedEvent = event;
    const startTime = chartRangeTools.getPartStartTime();
    const endTime = chartRangeTools.getPartEndTime() + 30000;

    if (startTime >= 0 && endTime >= 0) {
      const eventDuration = validatedEvent.end - validatedEvent.start;

      if (validatedEvent.start < startTime) {
        Logger.warn(
          '[ScoringService][validateMarkerInsideRecording] Adjusting marker start',
        );
        validatedEvent.start = startTime;

        if (!chartRangeTools.isTimestampWithinPart(validatedEvent.end)) {
          Logger.warn(
            '[ScoringService][validateMarkerInsideRecording] Adjusting with eventDuration:',
            eventDuration,
          );
          validatedEvent.end = startTime + eventDuration;
        }
      }

      if (validatedEvent.end > endTime) {
        Logger.warn(
          '[ScoringService][validateMarkerInsideRecording] Adjusting marker end',
        );
        validatedEvent.end = endTime;

        if (!chartRangeTools.isTimestampWithinPart(validatedEvent.start)) {
          Logger.warn(
            '[ScoringService][validateMarkerInsideRecording] Adjusting with eventDuration:',
            eventDuration,
          );
          validatedEvent.start = endTime - eventDuration;
        }
      }
    }

    return validatedEvent;
  },
  isReadOnly: () => isReadOnlyScoring,
  setReadOnly: (isReadOnly: boolean) => {
    // Logger.debug('[ScoringService][setReadOnly] Setting to:', isReadOnly);

    isReadOnlyScoring = isReadOnly;
    EventService.dispatch('ScoringReadOnly', isReadOnlyScoring);
  },
  scoringIsAllowed: () => {
    const scoringIsAllowed = !ScoringService.isReadOnly();

    if (!scoringIsAllowed) {
      NotificationService.send(
        'Change was blocked because the scoring is in read-only mode',
        {
          variant: 'error',
          action: 'dismiss',
        },
      );
    }

    return scoringIsAllowed;
  },
  setSubmittingPart: (isSubmitting) => {
    submittingPart = isSubmitting;
  },
  setSubmittedPartId: (partId: number) => {
    // Logger.debug('[ScoringService][setSubmittedPartId] Setting to:', partId);

    submittedPartId = partId;
    EventService.dispatch('ScoringSubmittedPart', partId);
  },
  getSubmittedPartId: () => submittedPartId,
  forceMarkerDelete: (markerId: string, opts: { force: boolean }) => {
    // Warning: This function should not be used unless very specific situations
    //          The normal way to delete an event is using removeEvent
    if (opts.force) {
      markerIdLocator.delete(markerId);
    }
  },
  getLastReceivedVersion: () => lastReceivedVersion,
  setLastReceivedVersion: (newVersion?: string) => {
    lastReceivedVersion = newVersion ? parseInt(newVersion, 10) : undefined;
    EventService.dispatch('Scoring.NewVersion', lastReceivedVersion);
  },
  isInitialStateReceived: () => {
    return retrievedInitialState;
  },
  setInitialStateReceived: (state: boolean) => {
    retrievedInitialState = state;
    EventService.dispatch('ScoringInitialStateProcessed', state);
  },
  restoreMutationQueueLength: () => {
    return restoreMutationQueueLength;
  },
  setRestoreMutationQueueLength: (length: number) => {
    restoreMutationQueueLength = length;
    EventService.dispatch('Scoring.RestoreMutationQueueLength', length);
  },
};

export default ScoringService;
