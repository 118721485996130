import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileSummaryLister from './FileSummaryLister';
import { AttachedFile, FileDefinition } from './interfaces/uploader-tools';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  icon: {
    marginBottom: -5,
  },
}));

interface FileSummaryProps {
  attachedFiles: AttachedFile[];
  fileDefinitions: FileDefinition[];
}

const FileSummary = (props: FileSummaryProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      {props.attachedFiles.length ? (
        <Typography variant="body2" className={classes.title}>
          <AttachFileIcon fontSize="small" className={classes.icon} /> Attached
          files:
        </Typography>
      ) : (
        ''
      )}
      <FileSummaryLister
        attachedFiles={props.attachedFiles}
        fileDefinitions={props.fileDefinitions}
        hidePlaceholdersWhenAttachedFiles
      />
    </>
  );
};

export default FileSummary;
