import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Fade, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0.5),
      marginLeft: theme.spacing(-1),
    },
    narrow: {
      position: 'absolute',
      margin: 0,
      top: theme.spacing(-1),

      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: 11,
      },
    },
    tooltip: { textAlign: 'center' },
  }),
);

interface MissingSignalIndicatorProps {
  narrow: boolean;
}

const MissingSignalIndicator = (
  props: MissingSignalIndicatorProps,
): JSX.Element => {
  const classes = useStyles();

  return (
    <Fade in appear mountOnEnter>
      <div
        className={clsx(classes.root, { [classes.narrow]: props.narrow })}
        data-cy="Signal-MissingIndicator"
      >
        <Tooltip
          title="The recording does not contain data for this signal"
          classes={{ tooltip: classes.tooltip }}
          arrow
        >
          <WarningIcon fontSize="small" />
        </Tooltip>
      </div>
    </Fade>
  );
};

export default MissingSignalIndicator;
