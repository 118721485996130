import React, { createContext, ReactElement, useState } from 'react';
import {
  SheetToolbarPageFlip,
  SheetToolbarZoomRange,
  SheetToolbarContextProps,
  SheetToolbarIntenseMode,
  SheetToolbarMode,
} from '../interfaces/sheet-toolbar-props';

const emptyModalContext: SheetToolbarContextProps = {
  zoomRange: '2min', // Default value not used
  setZoomRange: () => {
    /* To be replaced */
  },
  pageFlip: 'Epoch', // Default value not used
  setPageFlip: () => {
    /* To be replaced */
  },
  intenseMode: false, // Default value not used
  setIntenseMode: () => {
    /* To be replaced */
  },
  mode: 'Full', // Default value not used
  setMode: () => {
    /* To be replaced */
  },
  singleClickScoring: false, // Default value not used
  setSingleClickScoring: () => {
    /* To be replaced */
  },

  invalidDataMode: false, // Default value not used
  setInvalidDataMode: () => {
    /* To be replaced */
  },
  annotationModeEnabled: false, // Default value not used
  setAnnotationMode: () => {
    /* To be replaced */
  },
};

export const SheetToolbarContext = createContext(emptyModalContext);
export const SheetToolbarContextConsumer = SheetToolbarContext.Consumer;

export const SheetToolbarContextProvider = ({
  children,
}: {
  children: ReactElement;
}): JSX.Element => {
  const [zoomRange, setZoomRange] = useState<SheetToolbarZoomRange>('2min');
  const [pageFlip, setPageFlip] = useState<SheetToolbarPageFlip>('Epoch');
  const [intenseMode, setIntenseMode] =
    useState<SheetToolbarIntenseMode>(false);
  const [mode, setMode] = useState<SheetToolbarMode>('Full');
  const [singleClickScoring, setSingleClickScoring] = useState(false);
  const [invalidDataMode, setInvalidDataMode] = useState(false);
  const [annotationModeEnabled, setAnnotationMode] = useState(false);

  const toolbarContext: SheetToolbarContextProps = {
    zoomRange,
    setZoomRange,
    pageFlip,
    setPageFlip,
    intenseMode,
    setIntenseMode,
    mode,
    setMode,
    singleClickScoring,
    setSingleClickScoring,
    invalidDataMode,
    setInvalidDataMode,
    annotationModeEnabled,
    setAnnotationMode,
  };

  return (
    <SheetToolbarContext.Provider value={toolbarContext}>
      {children}
    </SheetToolbarContext.Provider>
  );
};
