import Logger from './logger';

const featureTogglingMode = process.env.REACT_APP_FEATURE_TOGGLING_MODE;

export type FeatureToggleMode = 'development' | 'staging' | 'production';
export const FeatureToggling = {
  getCurrentMode: (): FeatureToggleMode[] => {
    if (featureTogglingMode) {
      return [featureTogglingMode as FeatureToggleMode];
    }
    Logger.error('[FeatureToggling] Mode not set');
    return [];
  },
  isMode: (mode: FeatureToggleMode): boolean =>
    FeatureToggling.getCurrentMode().includes(mode),
};
