import React from 'react';
import queryString from 'query-string';

import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { ApolloError, useApolloClient } from '@apollo/client';
import withNavigationBars from '../../hoc/withNavigationBars';
import {
  WithPageStatus,
  QueryParamsRecording,
} from '../../interfaces/page-status';
import withTheme, { WithTheme } from '../../hoc/withTheme';
import Analytics from '../../services/analytics';
import queryManager from '../../services/queryManager';
import { GetRecordingQueryResult, Recording } from '../../queries/recording';
import Logger from '../../utils/logger';
import RecordingNotFound from '../../components/RecordingNotFound/RecordingNotFound';
import NotificationService from '../../services/notificationService';
import sheetTools from '../../components/SignalSheet/sheetTools';
import ScoringService from '../../services/scoringService';
import chartRangeTools from '../../components/SignalSheet/chartRangeTools';
import OverviewHeader from '../../components/StudyOverview/OverviewHeader';
import OverviewNavigator from '../../components/StudyOverview/OverviewNavigator';
import OverviewParts from '../../components/StudyOverview/OverviewParts';
import studyTools from '../../components/StudyOverview/studyTools';
import {
  GetScoringInsightsQueryResult,
  ScoringInsights,
} from '../../queries/scoringInsights';
import AuthService from '../../services/authService';
import AppTitle from '../../components/Shared/AppTitle';
import UndoService from '../../services/undoService';
import KeyboardManager from '../../components/SignalSheet/keyboardManager';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: theme.variables.pageMaxWidth,
    margin: '0 auto',
    marginTop: theme.spacing(1),
  },
}));

const StudyOverview = (props: WithTheme<WithPageStatus<unknown>>) => {
  NotificationService.useNotifications();

  const [loading, setLoading] = React.useState(true);
  const [recording, setRecording] = React.useState<Recording>();
  const [scoringInsights, setScoringInsights] =
    React.useState<ScoringInsights>();
  const [error, setError] = React.useState<string>();
  const [scoringInsightsError, setScoringInsightsError] =
    React.useState<string>();

  const [highlightPartId, setHighlightPartId] = React.useState<number>();

  const classes = useStyles();
  const client = useApolloClient();

  const location = useLocation();
  const params = queryString.parse(
    location.search,
  ) as unknown as QueryParamsRecording;

  React.useEffect(() => {
    Analytics.track.page('Overview');

    studyTools.initialize(params.id, props.pageStatus.setStatus);
    studyTools.clearSelectedPart();

    props.pageStatus.setStatus({
      ...props.pageStatus.status,
      currentPage: 'StudyOverview',
      recordingId: params.id,
      signalSheetTabs: studyTools.getSignalSheetTabs(),
    });

    if (!params.id) {
      setError('RecordingId was not specified');
    } else if (!params.scoringId) {
      setError('scoringId was not specified');
    } else {
      sheetTools.setRecordingId(params.id);
      sheetTools.setScoringId(params.scoringId);

      let newRecording: Recording | undefined;

      queryManager.initializeQueryManager(client);
      queryManager
        .query<GetRecordingQueryResult>('Recording', {
          recordingId: sheetTools.getRecordingId(),
        })
        .then((data) => {
          newRecording = data.recording;

          chartRangeTools.setRecordingStartTime(data.recording.startTime);
          chartRangeTools.setRecordingEndTime(data.recording.endTime);
        })
        .then(() =>
          AuthService.requestAccessTokenIfNeeded({ query: 'ScoringInsights' }),
        )
        .then(() => {
          ScoringService.initialize(sheetTools.getRecordingId());
        })
        .catch((err: ApolloError) => {
          setError(err.message);
          Logger.error(
            '[Sheet] Failed retrieving recording: %s',
            params.id,
            err,
          );
        })
        .then(() =>
          queryManager.query<GetScoringInsightsQueryResult>('ScoringInsights', {
            recordingId: params.id,
            scoringId: sheetTools.getScoringId(),
          }),
        )
        .then((data) => {
          KeyboardManager.init();
          UndoService.initialize();
          setRecording(newRecording);
          setScoringInsights(data.scoringInsights);
        })
        .catch((err) => {
          setScoringInsightsError(err);
          Logger.error(
            '[Sheet] Failed retrieving ScoringInsights: %s',
            params.id,
            err,
          );
        })

        .finally(() => setLoading(false));
    }

    return () => {
      KeyboardManager.destroy();
    };
  }, []);

  return (
    <>
      <AppTitle title="Overview" />
      <div className={classes.root}>
        {!error && (
          <>
            <OverviewHeader recording={recording} />

            <OverviewNavigator
              loading={loading}
              recording={recording}
              scoringInsights={scoringInsights}
              highlightPartId={highlightPartId}
              setHighlightPartId={setHighlightPartId}
            />
            <OverviewParts
              recording={recording}
              scoringInsights={scoringInsights}
              scoringInsightsError={scoringInsightsError}
              highlightPartId={highlightPartId}
              setHighlightPartId={setHighlightPartId}
            />
          </>
        )}
      </div>
      {error && <RecordingNotFound message={error} />}
    </>
  );
};

export default withTheme(
  withNavigationBars(React.memo(StudyOverview, () => true)),
);
