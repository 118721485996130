import React from 'react';

import {
  makeStyles,
  Typography,
  Fade,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  generating: {
    position: 'absolute',
    top: 0,
    right: theme.spacing(1),
    margin: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    gridTemplateRows: '30px 10px',
    alignItems: 'center',
  },
  loading: {
    gridArea: '1 / 1 / 3 / 2',
    margin: theme.spacing(0, 1),
  },
}));

interface ReportParameterLoadingProps {
  loading: boolean;
}

function ReportParameterLoading(
  props: ReportParameterLoadingProps,
): JSX.Element {
  const classes = useStyles();

  return (
    <Fade
      in={props.loading}
      style={{
        transitionDelay: props.loading ? '3000ms' : '0ms',
      }}
      mountOnEnter
      unmountOnExit
    >
      <div data-cy="ReportLoadingIndicator" className={classes.generating}>
        <CircularProgress className={classes.loading} />
        <Typography align="center">
          Generating report data. Please, wait.
        </Typography>
        <Fade
          in={props.loading}
          style={{
            transitionDelay: props.loading ? '6000ms' : '0ms',
          }}
          mountOnEnter
          unmountOnExit
        >
          <Typography align="center" variant="caption">
            This might take a few minutes.
          </Typography>
        </Fade>
      </div>
    </Fade>
  );
}

export default ReportParameterLoading;
