import gql from 'graphql-tag';

export interface UrlMultipartInfo {
  url: string;
  byteOffset: number;
  byteCount: number;
}

export interface NextFileInfo {
  name: string;
  parts: UrlMultipartInfo[];
}

export interface StartFileUploadMutationParameters {
  importId: string;
  fileName: string;
}

export interface StartFileUploadMutationResult {
  startFileUpload: NextFileInfo;
}

export const START_FILE_UPLOAD_MUTATION = gql`
  mutation StartFileUpload($importId: String!, $fileName: String!) {
    startFileUpload(importId: $importId, fileName: $fileName) {
      name
      parts {
        url
        byteOffset
        byteCount
      }
    }
  }
`;
