import gql from 'graphql-tag';

export interface GetImportStatusQueryParameters {
  importId: string;
}

export interface ImportStatus {
  status: string;
  id: string;
  recordingId?: string;
  recordingStart?: string;
  recordingEnd?: string;
}

export interface GetImportStatusQueryResult {
  recordingImport: ImportStatus;
}

export const GET_IMPORT_STATUS_QUERY = gql`
  query GetImportStatus($importId: String!) {
    recordingImport(importId: $importId) {
      id
      status
      recordingId
      recordingStart
      recordingEnd
    }
  }
`;
