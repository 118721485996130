import React from 'react';

import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline, fade, makeStyles, Theme } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import themeProvider, { ThemeType } from '../utils/theme';
import UserAttributesService from '../services/userAttributesService';

export declare type WithTheme<P> = P & {
  toggleTheme: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    html: {
      backgroundColor: theme.palette.background.default,
    },
  },
  snackBar: {
    top: 40,
  },
  success: {
    backgroundColor: `${fade('#5FA764', 0.7)} !important`,
    backdropFilter: 'blur(2px)',
    maxWidth: 500,
    '& .MuiButton-root': {
      color: '#fff !important',
    },
  },
  error: {
    backgroundColor: `${fade('#ef5350', 0.7)} !important`,
    backdropFilter: 'blur(2px)',
    color: '#fff !important',
    maxWidth: 500,
    '& .MuiButton-root': {
      color: '#fff !important',
    },
  },
  warning: {
    backgroundColor: `${fade('#ffd76b', 0.7)} !important`,
    backdropFilter: 'blur(2px)',
    color: '#000 !important',
    maxWidth: 500,
    '& .MuiButton-root': {
      color: '#000 !important',
    },
  },
  info: {
    backgroundColor: `${fade('#29b6f6', 0.7)} !important`,
    backdropFilter: 'blur(2px)',
    color: '#fff !important',
    maxWidth: 500,
    '& .MuiButton-root': {
      color: '#fff !important',
    },
  },
}));

const withTheme = <P extends Record<string, unknown>>(
  Content: React.ComponentType<P>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const Section = (props: P): React.ReactNode => {
    const getThemeTypeFromLocalStorage = () => {
      const themeLocalStorage = UserAttributesService.getPreference('Theme');
      let theme: ThemeType = 'LIGHT';
      if (!themeLocalStorage) {
        UserAttributesService.setPreference('Theme', theme);
      } else {
        theme = themeLocalStorage as ThemeType;
      }

      return theme;
    };

    const currentThemeType = getThemeTypeFromLocalStorage();
    const theme = themeProvider.getType(currentThemeType);

    const toggleTheme = () => {
      const newThemeType: ThemeType =
        currentThemeType === 'LIGHT' ? 'DARK' : 'LIGHT';
      UserAttributesService.setPreference('Theme', newThemeType);
      window.location.reload();
    };

    const newProps = {
      ...props,
      toggleTheme,
    };

    const classes = useStyles();

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          classes={{
            containerAnchorOriginTopRight: classes.snackBar,
            variantSuccess: classes.success,
            variantError: classes.error,
            variantInfo: classes.info,
            variantWarning: classes.warning,
          }}
        >
          <Content {...(newProps as P)} />
        </SnackbarProvider>
      </ThemeProvider>
    );
  };
  return Section;
};
export default withTheme;
