import React from 'react';
import { Report } from '../../queries/report';
import OverviewPartStat from '../StudyOverview/OverviewPartStat';
import FlexContainer from '../Shared/FlexContainer';
import reportTools from './reportTools';
import SleepParameterDefinitions from '../SignalSheet/definitions/SleepParameterDefinitions';

interface ReportScoringProps {
  report?: Report;
}

function ReportScoringStats(props: ReportScoringProps): JSX.Element {
  return (
    <FlexContainer data-cy="Report-Stats" styles={{ height: 'initial' }}>
      <OverviewPartStat
        data={{
          value: (() => {
            const hour = reportTools.getParameterValueNumber(
              props.report?.data,
              'RecordingDuration',
            );
            return hour ? hour * 60 * 1000 : undefined;
          })(),
        }}
        paramDefinition={SleepParameterDefinitions.get('TotalHours')}
      />
      <OverviewPartStat
        data={{
          value: (() => {
            const min = reportTools.getParameterValueNumber(
              props.report?.data,
              'TotalSleepTime(m)',
            );
            return min ? min * 60 : undefined;
          })(),
        }}
        paramDefinition={SleepParameterDefinitions.get('TotalTimeInSleep')}
      />
      <OverviewPartStat
        data={{
          value: (() => {
            const min = reportTools.getParameterValueNumber(
              props.report?.data,
              'SleepEfficiency',
            );
            return min ? min / 100 : undefined;
          })(),
        }}
        paramDefinition={SleepParameterDefinitions.get('SleepEfficiency')}
      />
      <OverviewPartStat
        data={{
          value: reportTools.getParameterValueNumber(props.report?.data, 'AHI'),
        }}
        paramDefinition={SleepParameterDefinitions.get('AHI')}
      />
      <OverviewPartStat
        data={{
          value: reportTools.getParameterValueNumber(
            props.report?.data,
            'OximetryODI',
          ),
        }}
        paramDefinition={SleepParameterDefinitions.get('ODI')}
      />
      <OverviewPartStat
        data={{
          value: reportTools.getParameterValueNumber(
            props.report?.data,
            'PulseMinimum',
          ),
        }}
        paramDefinition={SleepParameterDefinitions.get('Pulse-Min')}
      />
      <OverviewPartStat
        data={{
          value: reportTools.getParameterValueNumber(
            props.report?.data,
            'PulseMaximum',
          ),
        }}
        paramDefinition={SleepParameterDefinitions.get('Pulse-Max')}
      />
      {/* <OverviewPartStat
        data={{
          value: reportTools.getParameterValueNumber(props.report?.data, 'PulseAverage')}}
        paramDefinition={SleepParameterDefinitions.get('Pulse-Average')}
      /> */}
      <OverviewPartStat
        data={{
          value: reportTools.getParameterValueNumber(
            props.report?.data,
            'SpO2Minimum',
          ),
        }}
        paramDefinition={SleepParameterDefinitions.get('SpO2-Min')}
      />
      {/*  <OverviewPartStat
        data={{
          value: reportTools.getParameterValueNumber(props.report?.data, 'SpO2Average')}}
        paramDefinition={SleepParameterDefinitions.get('SpO2-Average')}
      /> */}
    </FlexContainer>
  );
}

export default ReportScoringStats;
