import React from 'react';

import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import withNavigationBars from '../../hoc/withNavigationBars';
import RecordingList from '../../components/RecordingList/RecordingList';

import withTheme, { WithTheme } from '../../hoc/withTheme';

import Analytics from '../../services/analytics';
import queryManager from '../../services/queryManager';
import { Recording } from '../../queries/recording';
import Logger from '../../utils/logger';
import { GetRecordingListQueryResult } from '../../queries/recordings';
import NotificationService from '../../services/notificationService';
import { WithPageStatus } from '../../interfaces/page-status';
import ScoringService from '../../services/scoringService';
import AppTitle from '../../components/Shared/AppTitle';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Recordings = (props: WithTheme<WithPageStatus<unknown>>) => {
  const [recordings, setRecordings] = React.useState<Recording[] | undefined>();
  const client = useApolloClient();
  const history = useHistory();
  NotificationService.useNotifications();

  const openUploadRecording = () => {
    history.push('/upload-recording');
  };

  React.useEffect(() => {
    Logger.debug('[Recordings] with props', props);
    Analytics.track.page('Recording List');
    ScoringService.cleanup();

    queryManager.initializeQueryManager(client);
    queryManager
      .query<GetRecordingListQueryResult>('RecordingList')
      .then((data) => setRecordings(data.recordings))
      .catch((error) => {
        Logger.error('[Sheet] Failed retrieving the recording list.', error);
      });
  }, []);

  return (
    <>
      <AppTitle title="Recordings" />
      <RecordingList
        openUploadRecording={openUploadRecording}
        recordings={recordings}
      />
    </>
  );
};

export default withTheme(withNavigationBars(Recordings));
