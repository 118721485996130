import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Logger from '../../../utils/logger';
import { ChartOptions } from './getChartOptions';
import SignalRenderingService, {
  SetIsLoadingFunction,
  UpdateDataLabelsFunction,
} from '../../../services/signalRenderingService';
import { SignalDefinition } from '../../../interfaces/sheet-definition';
import { ChartExtremes } from '../../SignalSheet/interfaces/chart-range-tools';
import EventService from '../../../services/eventService';
import chartTools from './chartTools';
import LoadingChart from './LoadingChart';

export const LABEL_FONT_SIZE = 11;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: ChartOptions.signalNameWidth,
      right: 0,
      overflow: 'hidden',
      pointerEvents: 'none',
    },
    label: {
      position: 'absolute',
      fontSize: LABEL_FONT_SIZE,
      marginLeft: -6.5,
      color: theme.colors.chart.label,
    },
  });
});

export interface DataLabelPosition {
  bottom: number;
  left: number;
  originalExtremeMin: number;
  originalExtremeMax: number;
}

export interface DataLabelData {
  timestamp: number;
  value: number | null;
  position: DataLabelPosition;
}

export interface DataLabelUpdate {
  extremes: ChartExtremes;
  containerSize: { height: number; width: number };
  labels: DataLabelData[];
}

export interface DataLabelContainerProps {
  signal: SignalDefinition;
}

const DataLabelContainer = (props: DataLabelContainerProps): JSX.Element => {
  const classes = useStyles();
  const [labelData, setLabelData] = useState<DataLabelUpdate>();
  const [showLoading, setShowLoading] = useState(true);
  const [signalReady, setSignalReady] = useState(false);

  const updateDataLabels: UpdateDataLabelsFunction = (
    newDataLabelUpdate: DataLabelUpdate,
  ) => {
    return new Promise(() => {
      Logger.debug('[DataLabelContainer] Drawing labels:', newDataLabelUpdate);
      setLabelData(newDataLabelUpdate);
    });
  };

  const setIsLoadingFunction: SetIsLoadingFunction = (isLoading: boolean) => {
    return new Promise(() => {
      setShowLoading(isLoading);
    });
  };

  React.useEffect(() => {
    SignalRenderingService.registerUpdateDataLabelsFunction(
      props.signal.type,
      updateDataLabels,
    );
    SignalRenderingService.registerSetIsLoadingFunction(
      props.signal.type,
      setIsLoadingFunction,
    );

    const cbId = [
      EventService.subscribe('WindowResize', () => {
        setSignalReady(true);
        const chart = chartTools.getChartFromRegistry(props.signal.type);
        if (chart) {
          Logger.debug(
            '[DataLabelContainer][%s] Detected window resize:',
            props.signal.name,
          );
          SignalRenderingService.redrawDataLabels(chart);
        } else {
          setLabelData(undefined);
        }
      }),
    ];

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, []);

  // TODO Use Breseham iteration to calculate the visible points
  // TODO Refactor huge function

  return (
    <>
      {showLoading && signalReady && <LoadingChart />}
      {labelData && (
        <div
          key={[
            labelData.extremes.min,
            labelData.extremes.min,
            labelData.containerSize.height,
            labelData.containerSize.width,
          ].join('|')}
          className={classes.root}
          data-cy="DataLabelContainer"
        >
          {labelData.labels.map((label) => (
            <div
              key={JSON.stringify(label)}
              className={classes.label}
              style={{
                bottom: label.position.bottom,
                left: label.position.left,
              }}
            >
              {label.value?.toFixed(0)}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DataLabelContainer;
