import React from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';

import { makeStyles } from '@material-ui/core/styles';
import {
  FirstDataRenderedEvent,
  GridReadyEvent,
  GridApi,
} from '@ag-grid-community/all-modules';
import { Paper } from '@material-ui/core';
import {
  MenuModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
} from '@ag-grid-enterprise/all-modules';
import columnDefs from './columnDefs';

import ActionsCellRenderer from './actionsCellRenderer';
import SubmittedCellRenderer from './submittedCellRenderer';
import RecordingListProps, {
  RecordingsListFilter,
} from '../../interfaces/recording-list-props';
import RecordingsToolbar from '../RecordingsToolbar/RecordingsToolbar';
import RecordingListLoading from './RecordingListLoading';
import recordingListTools from './recordingListTools';
import RecordingListEmpty from './RecordingListEmpty';
import StyledGrid from '../Shared/StyledGrid';
import ScoringService from '../../services/scoringService';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  sheet: {
    height: 'calc(100% - 104px)',
    padding: theme.spacing(1),
    margin: theme.spacing(1, 2),
  },
}));

const RecordingList = (props: RecordingListProps): JSX.Element => {
  const classes = useStyles();
  const [hasRendered, setHasRendered] = React.useState(false);
  const [gridApi, setGridApi] = React.useState<GridApi | undefined>();

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };
  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    params.api.sizeColumnsToFit();
    setHasRendered(true);
  };

  const setFilter = {
    longRecording: () => {
      if (gridApi) {
        const durationFilterComponent = gridApi.getFilterInstance('duration');
        if (durationFilterComponent) {
          durationFilterComponent.setModel({
            type: 'greaterThanHours',
            filter: '7',
          });
        }
      }
    },
    statusExploded: () => {
      if (gridApi) {
        const statusFilterComponent = gridApi.getFilterInstance('status');
        if (statusFilterComponent) {
          statusFilterComponent.setModel({
            type: 'equals',
            filter: 'Exploded',
          });
        }
      }
    },
  };

  const setGridFilters = (filters: RecordingsListFilter[]) => {
    if (gridApi) {
      gridApi.setFilterModel(null);
      if (filters.includes('statusExploded')) {
        setFilter.statusExploded();
      }
      if (filters.includes('longRecording')) {
        setFilter.longRecording();
      }
      gridApi.onFilterChanged();
    }
  };

  const autoFit = () => gridApi?.sizeColumnsToFit();

  React.useEffect(() => {
    ScoringService.unsubscribe();

    window.addEventListener('resize', autoFit);
    return () => {
      window.removeEventListener('resize', autoFit);
    };
  }, []);

  return (
    <>
      <RecordingsToolbar
        setGridFilters={setGridFilters}
        openUploadRecording={props.openUploadRecording}
        hasRendered={hasRendered}
      />

      <Paper className={classes.sheet}>
        <div
          className="ag-theme-material"
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <StyledGrid>
            <AgGridReact
              modules={[
                ClientSideRowModelModule,
                MenuModule,
                FiltersToolPanelModule,
                ColumnsToolPanelModule,
                SetFilterModule,
              ]}
              defaultColDef={{
                resizable: true,
                sortable: true,
                filter: true,
              }}
              columnDefs={columnDefs()}
              rowData={props.recordings}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              enableRangeSelection
              pagination
              paginationPageSize={recordingListTools.getPageSize()}
              frameworkComponents={{
                actionsCellRenderer: ActionsCellRenderer,
                submittedCellRenderer: SubmittedCellRenderer,
                customLoadingOverlay: RecordingListLoading,
                customNoRowsOverlay: RecordingListEmpty,
              }}
              loadingOverlayComponent="customLoadingOverlay"
              noRowsOverlayComponent="customNoRowsOverlay"
            />
          </StyledGrid>
        </div>
      </Paper>
    </>
  );
};

export default RecordingList;
