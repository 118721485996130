import gql from 'graphql-tag';
import { RecordingId } from './recording';

export interface GetGraphDataParameters {
  recordingId: RecordingId;
  signalType: string;
  beginning: Date;
  end: Date;
}

export interface GetGraphDataResult {
  graphData: GraphData;
}

export interface GraphData {
  samples: GraphDataSample[];
}

export interface GraphDataSample {
  timestamp: number;
  value: number;
}

export const GET_GRAPH_DATA = gql`
  query GraphData(
    $recordingId: String!
    $signalType: String!
    $beginning: DateTime!
    $end: DateTime!
  ) {
    graphData(
      recordingId: $recordingId
      signalType: $signalType
      beginning: $beginning
      end: $end
    ) {
      samples {
        timestamp
        value
      }
    }
  }
`;
