import React from 'react';

import {
  Button,
  makeStyles,
  Theme,
  fade,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import AddCommentIcon from '@material-ui/icons/AddComment';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.getContrastText(theme.palette.background.paper),
    backgroundColor: theme.palette.background.paper,
    borderColor: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.23,
    ),
    '@media print': {
      display: 'none',
    },
  },
  dialogTitle: {
    color: theme.colors.modal.color,
  },
  dialogContent: {
    color: theme.colors.modal.color,
  },
  dialogButton: {
    color: theme.colors.text.link,
    '&:hover': {
      backgroundColor: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.05,
      ),
    },
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorText: {
    textAlign: 'right',
    marginTop: theme.spacing(-1.5),
    padding: theme.spacing(2),
  },
  textArea: {
    width: '100%',
    minWidth: 450,
    '& fieldset': {
      borderColor: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.23,
      ),
    },
    '& textarea': {
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
    '&:hover': {
      '& fieldset': {
        borderColor: fade(
          theme.palette.getContrastText(theme.palette.background.paper),
          0.4,
        ),
      },
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.4,
      ),
    },
    '& .MuiInputBase-root.Mui-focused': {
      '& fieldset': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
  },
}));

interface ReportAddCommentProps {
  handleSubmit: (message: string) => Promise<boolean>;
}

const ReportAddComment = (props: ReportAddCommentProps): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [draftComment, setDraftComment] = React.useState('');

  const handleClose = () => setOpen(() => false);
  const handleSubmit = () => {
    props
      .handleSubmit(draftComment.trim())
      .then(() => {
        setDraftComment('');
        handleClose();
      })
      .catch((err) => setError(err))
      .finally(() => setSubmitting(() => false));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDraftComment(event.target.value);
  };

  return (
    <>
      <Button
        data-cy="Report-AddCommentBtn"
        variant="outlined"
        className={classes.button}
        startIcon={<AddCommentIcon />}
        onClick={() => setOpen(() => true)}
      >
        Add comment
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          data-cy="AddCommentDialogTitle"
          className={classes.dialogTitle}
        >
          Add Comment
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContent}>
            <TextField
              className={classes.textArea}
              multiline
              rows={4}
              placeholder="Comment"
              variant="outlined"
              onChange={handleChange}
              defaultValue={draftComment}
              autoFocus
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.dialogButton}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            className={classes.dialogButton}
            autoFocus
            disabled={draftComment.trim().length === 0}
          >
            Save
            {submitting && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
        {error && (
          <FormHelperText className={classes.errorText} error>
            There was an error during the submission
          </FormHelperText>
        )}
      </Dialog>
    </>
  );
};

export default ReportAddComment;
