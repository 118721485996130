import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 12,
    paddingBottom: 10,
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
}));

const UDI = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} align="center" gutterBottom>
      UDI: (01)15694311110255(8012)060200
    </Typography>
  );
};

export default UDI;
