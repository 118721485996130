import gql from 'graphql-tag';

export type RecordingId = string;
export type GenderType = 'M' | 'F' | 'U';

export interface Recording {
  recordingId: RecordingId;
  status: string;
  type: string;
  startTime: string;
  endTime: string;
  uploadedDate: string;
  patient?: PatientInfo;
  deviceInfo?: DeviceInfo;
}

export type DeviceType = 'T3' | 'T3S' | 'A1' | 'A1S';

export interface DeviceInfo {
  type?: DeviceType;
}

export interface PatientInfo {
  MR?: string;
  age?: number;
  gender?: GenderType;
  BMI?: number;
}

export interface GetRecordingQueryParameters {
  recordingId: RecordingId;
}

export interface GetRecordingQueryResult {
  recording: Recording;
}

export const GET_RECORDING = gql`
  query Recording($recordingId: String!) {
    recording(recordingId: $recordingId) {
      recordingId
      status
      type
      startTime
      endTime
      uploadedDate
      deviceInfo {
        type
      }
      patient {
        MR
        age
        gender
        BMI
      }
    }
  }
`;
