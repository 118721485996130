import React from 'react';
import { makeStyles } from '@material-ui/core';
import { AttachedFile, FileDefinition } from './interfaces/uploader-tools';
import FileItem from './FileItem';
import FilePlaceholder from './FilePlaceholder';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    width: 500,
    margin: '0 auto',
  },
  rootCondensed: {
    display: 'grid',
    maxWidth: 650,
    margin: '0 auto',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}));

interface FileSummaryListerProps {
  attachedFiles: AttachedFile[];
  fileDefinitions: FileDefinition[];
  hidePlaceholdersWhenAttachedFiles?: boolean;
}

const FileSummaryLister = (props: FileSummaryListerProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={
        props.attachedFiles.length > 1 ? classes.rootCondensed : classes.root
      }
    >
      {props.attachedFiles
        .sort((a: AttachedFile, b: AttachedFile) => {
          if (a.file.name < b.file.name) return -1;
          if (a.file.name > b.file.name) return 1;
          return 0;
        })
        .map((attachment) => (
          <FileItem
            key={attachment.file.name}
            attachment={attachment}
            condensed
          />
        ))}

      {!props.hidePlaceholdersWhenAttachedFiles ||
      (props.hidePlaceholdersWhenAttachedFiles &&
        props.attachedFiles.length === 0)
        ? props.fileDefinitions.map((def) => (
            <FilePlaceholder
              key={def.filename.toString()}
              isMultiple={def.multiple}
              text={def.text}
              filename={def.filename}
            />
          ))
        : ''}
    </div>
  );
};

export default FileSummaryLister;
