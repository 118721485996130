import React from 'react';
import { WithRouterProps } from '../../interfaces/with-router-props';

import RecordingListOptions from './RecordingOptions';
import Separator from '../Shared/Separator';
import RecordingOpen from './RecordingOpen';
import FlexContainer from '../Shared/FlexContainer';
import { Recording } from '../../queries/recording';

const ActionsCellRenderer = (
  props: WithRouterProps<{ data: Recording }>,
): JSX.Element => {
  return (
    <FlexContainer>
      {props.data.startTime ? (
        <>
          <RecordingOpen recordingId={props.data.recordingId} />
          <Separator />
          <RecordingListOptions recordingId={props.data.recordingId} />
        </>
      ) : (
        <Separator />
      )}
    </FlexContainer>
  );
};

export default ActionsCellRenderer;
