import React from 'react';
import { useFeatures } from '@paralleldrive/react-feature-toggles';
import { FeatureToggleMode } from '../../utils/featureToggle';

interface FeatureToggleProps {
  enabledFor: FeatureToggleMode[] | FeatureToggleMode;
  children: JSX.Element;
}

const FeatureToggle = (props: FeatureToggleProps): JSX.Element => {
  const features = useFeatures();
  const enabledFor = Array.isArray(props.enabledFor)
    ? props.enabledFor
    : [props.enabledFor];
  const shouldRender =
    enabledFor.some((mode) => features.includes(mode)) && props.children;

  return shouldRender ? props.children : <></>;
};

export default FeatureToggle;
