import React from 'react';
import SearchIcon from '@material-ui/icons/Search';

import { makeStyles, InputBase } from '@material-ui/core';
import { darkenOrLighten } from '../../utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '@media print': {
      display: 'none',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    background: darkenOrLighten(
      theme.id,
      theme.palette.background.paper,
      0.05,
      0.1,
    ),
    '&:hover': {
      background: darkenOrLighten(
        theme.id,
        theme.palette.background.paper,
        0.1,
        0.15,
      ),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

interface ReportParameterSearchProps {
  handleChange: (searchString: string) => void;
}

function ReportParameterSearch(props: ReportParameterSearchProps): JSX.Element {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleChange(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div data-cy="ParameterSearch" className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default ReportParameterSearch;
