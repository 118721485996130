import React from 'react';
import clsx from 'clsx';

import { Button, Fade, fade, makeStyles } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import EventService from '../../services/eventService';
import ScoringService from '../../services/scoringService';
import Logger from '../../utils/logger';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(1),
    fontSize: 12,
    textTransform: 'initial',
    textAlign: 'center',
    lineHeight: '12px',
    margin: theme.spacing(0.5, 1),
    width: 250,
    whiteSpace: 'normal',
    fontWeight: 400,
    boxShadow: 'none',
    pointerEvents: 'none',
    '&:after': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      content: '""',
      borderRadius: 4,
      border: `1px solid ${fade('#000', 0.1)}`,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

function OverviewPartsReadOnly(): JSX.Element {
  const classes = useStyles();

  const [isReadOnlyScoring, setIsReadOnlyScoring] = React.useState(
    ScoringService.isReadOnly(),
  );
  const [hasSubmittedPart, setHasSubmittedPart] = React.useState(
    ScoringService.getSubmittedPartId() !== undefined,
  );

  React.useEffect(() => {
    const cbId = [
      EventService.subscribe<boolean>('ScoringReadOnly', (isReadOnly) =>
        setIsReadOnlyScoring(isReadOnly),
      ),
      EventService.subscribe('ScoringSubmittedPart', (part) => {
        Logger.log('ScoringSubmittedPart');
        setHasSubmittedPart(() => part !== undefined);
      }),
    ];

    return () => {
      EventService.unsubscribe(cbId);
    };
  });

  return (
    <Fade in={isReadOnlyScoring || hasSubmittedPart}>
      <Button className={clsx(classes.button)} variant="contained">
        <LockIcon className={classes.icon} />
        This scoring
        {hasSubmittedPart && <> has been submitted and</>} is in read-only mode
      </Button>
    </Fade>
  );
}

export default OverviewPartsReadOnly;
