import React from 'react';
import { makeStyles, Tooltip, Grow } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import EventService from '../../services/eventService';
import ScoringService from '../../services/scoringService';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  tooltip: {
    maxWidth: 300,
  },
}));

const ScoringReadOnlyStatus = (): JSX.Element => {
  const classes = useStyles();
  const [show, setShow] = React.useState(ScoringService.isReadOnly());

  React.useEffect(() => {
    const cbId = EventService.subscribe<boolean>(
      'ScoringReadOnly',
      (isReadyOnly: boolean) => setShow(isReadyOnly),
    );

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, []);

  return (
    <Grow in={show} mountOnEnter unmountOnExit>
      <div className={classes.root}>
        <Tooltip
          title="This scoring has been submitted and is in read-only mode"
          classes={{ tooltip: classes.tooltip }}
          style={{ cursor: 'pointer' }}
          arrow
        >
          <LockIcon fontSize="small" />
        </Tooltip>
      </div>
    </Grow>
  );
};

export default ScoringReadOnlyStatus;
