import { MarkerType } from '../../../interfaces/markers';

import { ReactComponent as ApneasIcon } from '../../../assets/icons/overview/icon_so_apneas.svg';
import { ReactComponent as DesatIcon } from '../../../assets/icons/overview/icon_so_desaturation.svg';
import { ReactComponent as SnoringIcon } from '../../../assets/icons/overview/icon_so_snoring.svg';
import { ReactComponent as MovementIcon } from '../../../assets/icons/overview/icon_so_movement.svg';
import { ReactComponent as SleepStages } from '../../../assets/icons/overview/icon_so_sleeptime.svg';
import { ReactComponent as PulseIcon } from '../../../assets/icons/overview/icon_so_pulse.svg';
import { ReactComponent as PositionIcon } from '../../../assets/icons/overview/icon_so_position.svg';
import { ReactComponent as RIPPhaseIcon } from '../../../assets/icons/overview/icon_so_ripphase.svg';

import { PartId, RecordingPartData } from '../../StudyOverview/OverviewParts';
import OverviewHypnogram from './OverviewHypnogram';
import OverviewPositionChart from './OverviewPositionChart';
import OverviewApneaChart from './OverviewApneaChart';
import { DeviceType } from '../../../queries/recording';
import SleepStageService from '../../../services/sleepStageService';

export type OverviewGraphType =
  | 'Hypnogram'
  | 'Activity'
  | 'Snore'
  | 'Apneas'
  | 'Pulse'
  | 'Position'
  | 'RIP'
  | 'Desaturation';

export type OverviewGraphDataSamples = number[][];
export type OverviewGraphSignalType = string;
export interface GraphDataEvent {
  partId: PartId;
  signalType: OverviewGraphSignalType;
}

export interface OverviewGraphDefinition {
  type: OverviewGraphType;
  name: string;
  iconSvg: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  height: number;
  isSignalGraph?: boolean;
  signalType?: OverviewGraphSignalType;
  yAxisLabels?: boolean;
  yMin?: number;
  yMax?: number;
  filterMax?: number;
  displayEvents?: MarkerType[];
  getDeviceAvailability?: () => DeviceType[];
  customComponent?: (props: {
    graphDefinition: OverviewGraphDefinition;
    partData: RecordingPartData;
    chartWidth?: number;
  }) => JSX.Element;
}

export const overviewChartDefinitions = new Map<
  OverviewGraphType,
  OverviewGraphDefinition
>();

overviewChartDefinitions.set('Hypnogram', {
  type: 'Hypnogram',
  name: 'Sleep',
  height: 40,
  iconSvg: SleepStages,
  isSignalGraph: true,
  getDeviceAvailability: () => SleepStageService.getDeviceAvailability(),
  customComponent: OverviewHypnogram,
});

overviewChartDefinitions.set('Activity', {
  type: 'Activity',
  name: 'Activity',
  height: 50,
  iconSvg: MovementIcon,
  isSignalGraph: true,
  signalType: 'Activity-Gravity',
  displayEvents: ['activity-movement'],
});
overviewChartDefinitions.set('Position', {
  type: 'Position',
  name: 'Position',
  height: 30,
  iconSvg: PositionIcon,
  isSignalGraph: false,
  customComponent: OverviewPositionChart,
});
overviewChartDefinitions.set('Desaturation', {
  type: 'Desaturation',
  name: 'Desaturation',
  height: 50,
  yMin: 70,
  yMax: 100,
  iconSvg: DesatIcon,
  isSignalGraph: true,
  signalType: 'SpO2.Averaged-Probe',
  yAxisLabels: true,
  filterMax: 120,
  displayEvents: ['oxygensaturation-drop'],
});
overviewChartDefinitions.set('Apneas', {
  type: 'Apneas',
  name: 'Apneas',
  height: 50,
  iconSvg: ApneasIcon,
  isSignalGraph: false,
  signalType: 'SpO2.Averaged-Probe',
  customComponent: OverviewApneaChart,
});
overviewChartDefinitions.set('RIP', {
  type: 'RIP',
  name: 'RIP Phase',
  height: 50,
  iconSvg: RIPPhaseIcon,
  isSignalGraph: true,
  signalType: 'Resp.Phase-RIP',
});
overviewChartDefinitions.set('Pulse', {
  type: 'Pulse',
  name: 'Pulse',
  height: 50,
  iconSvg: PulseIcon,
  isSignalGraph: true,
  yAxisLabels: true,
  signalType: 'Pulse.Averaged-Probe',
  yMin: 40,
  yMax: 100,
  filterMax: 500,
});
overviewChartDefinitions.set('Snore', {
  type: 'Snore',
  name: 'Snoring dB',
  height: 50,
  iconSvg: SnoringIcon,
  isSignalGraph: true,
  signalType: 'Snore.Envelope-Audio.dB',
  yAxisLabels: true,
  yMin: 60,
  yMax: 100,
  displayEvents: ['snore-train'],
});

export const calculateOverviewChartsSize = (opts: {
  forDeviceType?: DeviceType;
}): number =>
  Array.from(overviewChartDefinitions.values())
    .filter(
      (def) =>
        !def.getDeviceAvailability ||
        (opts.forDeviceType &&
          def.getDeviceAvailability().includes(opts.forDeviceType)),
    )
    .reduce(
      (previous, value) => previous + value.height,
      0, // initial value
    );
