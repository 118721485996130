/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import { fade, makeStyles } from '@material-ui/core';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { darkenOrLighten } from '../../utils/theme';

// The license in plain text is apparently the only supported way
LicenseManager.setLicenseKey(
  'CompanyName=Nox Medical,' +
    'LicensedApplication=Noxturnal Web,' +
    'LicenseType=SingleApplication,' +
    'LicensedConcurrentDeveloperCount=1,' +
    'LicensedProductionInstancesCount=1,' +
    'AssetReference=AG-011830,' +
    'ExpiryDate=16_November_2021_[v2]_MTYzNzAyMDgwMDAwMA==7232843b2bf22d55fcb61abde1aa5e5f',
);

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#root': {
      height: '100%',
    },
    '.ag-theme-material': {
      '& .ag-root-wrapper': {
        color: theme.palette.getContrastText(theme.palette.background.paper),
        backgroundColor: theme.palette.background.paper,
      },

      '& .ag-overlay-wrapper': {
        color: theme.palette.getContrastText(theme.palette.background.paper),
        backgroundColor: theme.palette.background.paper,
      },

      '& .ag-cell-wrapper': {
        '& > *:not(.ag-cell-value):not(.ag-group-value)': {
          height: 100,
        },
      },

      '& .ag-layout-auto-height': {
        '& .ag-center-cols-clipper': {
          minHeight: 50,
        },
      },

      '& .ag-header': {
        border: 'none',
        borderColor: theme.colors.chart.grid,
        backgroundColor: theme.palette.background.paper,

        '& .ag-header-cell': {
          color: theme.palette.getContrastText(theme.palette.background.paper),
          backgroundColor: theme.palette.background.paper,
        },
      },
      '& .ag-paging-panel': {
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      '& .ag-row': {
        border: 'none',
        borderColor: theme.colors.chart.grid,
        backgroundColor: 'transparent',
        borderRadius: theme.spacing(0.5),
        color: theme.palette.getContrastText(theme.palette.background.paper),
        '&:nth-child(even)': {
          backgroundColor: fade(theme.palette.secondary.main, 0.2),
        },
        '&.ag-row-level-1': {
          borderBottom: 'none',
        },
      },
      '& .ag-tabs-header': {
        backgroundColor: darkenOrLighten(
          theme.id,
          theme.palette.background.paper,
          0.2,
          0.1,
        ),
        '& .ag-tab.ag-tab-selected': {
          borderColor: theme.palette.background.paper,
        },
      },
      '& .ag-menu': {
        color: theme.palette.getContrastText(theme.palette.background.paper),
        backgroundColor: darkenOrLighten(
          theme.id,
          theme.palette.background.paper,
          0,
          0.2,
        ),

        '& .ag-menu-option-active': {
          backgroundColor: darkenOrLighten(
            theme.id,
            theme.palette.background.paper,
            0.1,
            0.3,
          ),
        },
      },
      '& .ag-filter': {
        color: `${theme.palette.getContrastText(
          theme.palette.background.paper,
        )} !important`,
        '&:focus': {
          borderColor: `${theme.palette.background.paper} !important`,
        },

        '& .ag-picker-field-wrapper': {
          '& .ag-picker-field-display': {
            color: '#333',
          },
          '& .ag-icon': {
            color: '#333',
          },
        },

        '& .ag-input-field-input': {
          color: `${theme.palette.getContrastText(
            theme.palette.background.paper,
          )} !important`,
        },
      },
      '& .ag-row-hover, & .ag-row-selected': {
        backgroundColor: fade(
          theme.palette.getContrastText(theme.palette.background.paper),
          0.05,
        ),
        '&:nth-child(even)': {
          backgroundColor: `${fade(
            theme.palette.getContrastText(theme.palette.background.paper),
            0.05,
          )} !important`,
        },
      },

      '& .ag-column-hover': {
        // '&:hover': {
        //   backgroundColor: 'transparent !important',
        // },
        '&.ag-header-active': {
          backgroundColor: `${theme.palette.background.paper} !important`,
        },
      },
      '& .ag-header-cell': {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
      '& .ag-header-cell-moving': {
        backgroundColor: `${darkenOrLighten(
          theme.id,
          theme.palette.background.paper,
          0.2,
          0.2,
        )} !important`,
      },
      '& .ag-icon': {
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      '& .ag-paging-page-summary-panel .ag-icon.ag-disabled': {
        color: theme.palette.getContrastText(theme.palette.background.paper),
        opacity: 0.5,
      },
      '& .ag-overlay-wrapper .ag-react-container': {
        width: '100%',
        height: '100%',
        paddingTop: 65,
      },
      '& .ag-react-container': {
        height: '100%',
      },
      '& .ag-cell': {
        textOverflow: 'unset',
      },
    },
  },
}));

interface StyledGridProps {
  children: JSX.Element;
}

function StyledGrid(props: StyledGridProps): JSX.Element {
  const classes = useStyles();

  return <>{props.children}</>;
}

export default StyledGrid;
