import React from 'react';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { fade, makeStyles, Tooltip } from '@material-ui/core';
import _ from 'underscore';
import Logger from '../../../utils/logger';
import chartRangeTools from '../../SignalSheet/chartRangeTools';
import { ChartExtremes } from '../../SignalSheet/interfaces/chart-range-tools';
import EventService from '../../../services/eventService';
import sheetTools from '../../SignalSheet/sheetTools';
import { OverlayArea } from '../SignalChart/interfaces/event-chart-tools';
import ScoringService from '../../../services/scoringService';
import eventChartTools from '../SignalChart/eventChartTools';
import { SignalEventDetailData } from '../../../interfaces/signal-event-detail-props';
import { generateStripedGradient } from '../../../utils/theme';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  highlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    background: fade(theme.colors.chart.axisLabel, 0.5),
    opacity: 0.5,
    minWidth: 2,
  },
  hover: {
    background: fade(theme.colors.chart.axisLabel, 0.8),
  },
  tooltip: { maxWidth: 60, textAlign: 'center' },
  exclusionZone: {
    opacity: 1,
    background: generateStripedGradient('#9e9e9e', 0.05, 0.25),
  },
}));

export type CurrentExtremesUpdatedFunction = (data: ChartExtremes) => void;

export default function NavigatorOverlay(): JSX.Element {
  const classes = useStyles();

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [currentExtremes, setCurrentExtremes] = React.useState<ChartExtremes>();
  const [hovering, setHovering] = React.useState(false);
  const [hoverExtremes, setHoverExtremes] = React.useState<ChartExtremes>();
  const [overlayParts, setOverlayParts] = React.useState<OverlayArea[]>([]);

  const updateCurrentExtremes = (data: ChartExtremes) => {
    Logger.log('[NavigatorOverlay] updateCurrentExtremes', data);
    setCurrentExtremes(data);
  };

  const calculatePosition = (
    min: number,
    max: number,
  ): { left: number; width: number } => {
    const partStart = chartRangeTools.getPartStartTime();
    const partEnd = chartRangeTools.getPartEndTime() + 30000;

    const diffWithExtremeMax = partEnd - partStart;
    const diffWithEventStart = min - partStart;
    const diffWithEventEnd = max - partStart;
    const containerWidth = containerRef?.current?.clientWidth || 0;

    const left = (diffWithEventStart * containerWidth) / diffWithExtremeMax;
    const width =
      (diffWithEventEnd * containerWidth) / diffWithExtremeMax - left;

    return {
      left,
      width,
    };
  };

  const processInvalidSignals = (): OverlayArea[] => {
    const partStartTime = chartRangeTools.getPartStartTime();
    const partEndTime = chartRangeTools.getPartEndTime();

    const data: OverlayArea[] = [];
    const events = ScoringService.retrieveMarkersByType(['signal-invalid']);
    const containerWidth = containerRef?.current?.clientWidth || 0;

    Logger.log('[NavigatorOverlay] events', events);

    events.forEach((event) => {
      if (
        eventChartTools.isMarkerWithinTimePeriod(
          event,
          partStartTime,
          partEndTime,
        )
      ) {
        data.push({
          start: event.start,
          end: event.end,
          position: eventChartTools.calculateOverlayPosition(
            partStartTime,
            partEndTime,
            event.start,
            event.end,
            containerWidth,
          ),
          isExcluded: true,
        });
      }
    });
    Logger.log('[NavigatorOverlay] invalid signal events', data);
    return data;
  };

  const onRedraw = _.throttle(() => {
    Logger.log('NavigatorOverlay onRedraw');
    const newOverlayParts = [...processInvalidSignals()];
    setOverlayParts(newOverlayParts);
  }, 250);

  const pxToTimestamp = (px: number): number => {
    const containerWidth = containerRef?.current?.clientWidth || 0;

    const partStart = chartRangeTools.getPartStartTime();
    const partEnd = chartRangeTools.getPartEndTime() + 30000;

    const milliseconds = partEnd - partStart;
    const pxPerMillisecond = milliseconds / containerWidth;

    let timestamp = partStart + px * pxPerMillisecond;

    // Logger.log('[NavigatorOverlay] px', px);
    // Logger.log('[NavigatorOverlay] timestamp', timestamp);

    if (timestamp < partStart) {
      timestamp = partStart;
    } else if (timestamp > partEnd) {
      timestamp = partEnd;
    }

    return timestamp;
  };

  const calculateHoverExtremes = (
    timestamp: number,
  ): { min: number; max: number } => {
    const rawMin = chartRangeTools.adjustToEpoch(timestamp);
    const rawMax = rawMin + sheetTools.getZoomRange();
    return chartRangeTools.adjustToPartEdges(rawMin, rawMax);
  };

  const recalculatePosition = _.throttle(() => {
    setCurrentExtremes(chartRangeTools.getCurrentExtremes());
  }, 250);

  React.useEffect(() => {
    chartRangeTools.registerSetCurrentExtremesFunction(updateCurrentExtremes);

    const cbId = [
      EventService.subscribe(['WindowResize'], () => {
        recalculatePosition();
        onRedraw();
      }),
      EventService.subscribe(
        'ScoringChanged',
        (marker: SignalEventDetailData) => {
          if (marker.type === 'signal-invalid') {
            onRedraw();
          }
        },
      ),
    ];

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, []);

  const updateHoverExtremes = (offsetX: number) => {
    const hoverTimestamp = pxToTimestamp(offsetX);
    const newHoverExtremes = calculateHoverExtremes(hoverTimestamp);
    setHoverExtremes(newHoverExtremes);
  };

  return (
    <div
      ref={containerRef}
      className={classes.container}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onMouseMove={(e) => {
        // Logger.log('[NavigatorOverlay] event.target', e.nativeEvent);
        const currentTargetRect = e.currentTarget.getBoundingClientRect();
        const eventOffsetX = e.pageX - currentTargetRect.left;
        let cursorOffset = 2;
        const zoomRange = sheetTools.getZoomRange();
        if (zoomRange > 60000 * 2) cursorOffset = 5;
        updateHoverExtremes(eventOffsetX - cursorOffset);
      }}
      onClick={() => {
        if (hoverExtremes) {
          const newEpoch = chartRangeTools.convertToEpoch(hoverExtremes.min);
          Logger.log('[NavigatorOverlay] newEpoch', newEpoch);
          sheetTools.setExtremes(hoverExtremes, { redraw: true });
        }
      }}
    >
      {overlayParts.map((period, index) => (
        <div
          key={[period.start, period.end, period.partId, index].join()}
          className={clsx(classes.highlight, classes.exclusionZone)}
          style={{
            // 2px offset because of the border width
            left: period.position.left - 2,
            width: period.position.width + 2,
          }}
        />
      ))}

      {currentExtremes && (
        <div
          className={classes.highlight}
          style={calculatePosition(currentExtremes.min, currentExtremes.max)}
        />
      )}

      {hovering && hoverExtremes && (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={`#${chartRangeTools.convertToEpoch(hoverExtremes.min) + 1}\n
            ${moment(hoverExtremes.min).format('HH:mm:ss')}`}
          open
          arrow
        >
          <div
            key={JSON.stringify(hoverExtremes)}
            className={clsx(classes.highlight, classes.hover)}
            style={calculatePosition(hoverExtremes.min, hoverExtremes.max)}
          />
        </Tooltip>
      )}
    </div>
  );
}
