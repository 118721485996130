import {
  FormControl,
  FormGroup,
  FormHelperText,
  makeStyles,
  Toolbar,
  withStyles,
  createStyles,
  Theme,
  Grid,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { RecordingsListFilter } from '../../interfaces/recording-list-props';
import RecordingsToolbarProps from './interfaces/recording-toolbar';
import OpenUploadRecordingButton from './OpenUploadRecordingButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1.5, 2, 0.5, 2),
    },
    toolbar: {
      padding: 0,
      alignItems: 'normal',
      margin: theme.spacing(1, 2),
    },
    actions: {
      padding: 0,
      margin: theme.spacing(1, 2),
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    control: {
      margin: theme.spacing(0, 1),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    legend: {
      color: theme.palette.getContrastText(theme.palette.background.default),
      marginBottom: 3,
      opacity: 0.6,
    },
    button: {
      minWidth: 60,
    },
    placeholder: {
      width: 200,
      height: 48,
    },
  }),
);

const StyledToggleButtonGroup = withStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        '0px 1px 3px 0px rgba(0,0,0,0.2),' +
        '0px 1px 1px 0px rgba(0,0,0,0.14),' +
        '0px 2px 1px -1px rgba(0,0,0,0.12)',
    },
    grouped: {
      height: 40,
      margin: theme.spacing(0.5),
      border: 'none',
      padding: theme.spacing(0, 1),
      '&:not(:first-child)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-child': {
        borderRadius: theme.shape.borderRadius,
      },
      '& .MuiToggleButton-label': {
        color: theme.palette.getContrastText(theme.palette.background.paper),
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,

        '& .MuiToggleButton-label': {
          color: theme.palette.primary.contrastText,
        },
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,

        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },

        '& .MuiToggleButton-label': {
          color: theme.palette.primary.contrastText,
        },
      },
      '&.Mui-disabled': {
        opacity: 0.5,
      },
    },
  }),
)(ToggleButtonGroup);

export default function RecordingsToolbar(
  props: RecordingsToolbarProps,
): JSX.Element {
  const classes = useStyles();
  const [filters, setFilters] = useState<RecordingsListFilter[]>([]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Toolbar className={classes.toolbar}>
          <FormControl component="fieldset" className={classes.control}>
            <FormHelperText component="legend" className={classes.legend}>
              Quick filters
            </FormHelperText>
            <FormGroup>
              {props.hasRendered ? (
                <StyledToggleButtonGroup
                  size="small"
                  value={filters}
                  onChange={(e, changedFilters) => {
                    let newFilters = changedFilters;
                    if (changedFilters.includes('Clear')) {
                      newFilters = [];
                    }

                    setFilters(newFilters);
                    props.setGridFilters(newFilters);
                  }}
                >
                  <ToggleButton value="longRecording">
                    Long recording
                  </ToggleButton>
                  <ToggleButton value="statusExploded">Exploded</ToggleButton>
                  <ToggleButton value="Clear">Clear</ToggleButton>
                </StyledToggleButtonGroup>
              ) : (
                <Skeleton className={classes.placeholder} />
              )}
            </FormGroup>
          </FormControl>
        </Toolbar>
      </Grid>
      <Grid item xs={6}>
        <Toolbar className={classes.actions}>
          <FormControl component="fieldset" className={classes.control}>
            <FormGroup>
              <OpenUploadRecordingButton
                openUploadRecording={props.openUploadRecording}
              />
            </FormGroup>
          </FormControl>
        </Toolbar>
      </Grid>
    </Grid>
  );
}
