import { SleepStageType } from './sleep-stage-service';
import { AnalysisPeriodTypes } from '../queries/analysisPeriods';
import { SignalEventDetailData } from './signal-event-detail-props';
import { UpdateEventParams } from './scoring-service';
import { RecordingId } from '../queries/recording';
import { KeyCode } from '../components/SignalSheet/interfaces/keyboard-manager';
import { EventType } from './event-service';

export type PositionTypes =
  | 'position-upright'
  | 'position-supine'
  | 'position-left'
  | 'position-right'
  | 'position-prone'
  | 'position-unknown';

export type MarkerType =
  | 'apnea'
  | 'apnea-obstructive'
  | 'apnea-mixed'
  | 'apnea-central'
  | 'hypopnea'
  | 'oxygensaturation-drop'
  | 'activity-movement'
  | 'snorebreath'
  | 'snore-train'
  | 'arrhythmia-tachycardia'
  | 'arrhythmia-bradycardia'
  | 'pulsewave-drop'
  | 'web-custom'
  | SleepStageType
  | 'flow-diminished'
  | 'signal-artifact'
  | 'breath-normal'
  | 'breath-limitation'
  | 'breathing-paradoxical'
  | PositionTypes
  | 'limbmovement-twitch'
  | 'limbmovement-periodictwitch'
  | 'plm'
  | AnalysisPeriodTypes
  | 'signal-invalid';

export type MarkerGroup =
  | 'Apnea'
  | 'Hypopnea'
  | 'Activity'
  | 'Oxygen'
  | 'Snore'
  | 'Snore Breath'
  | 'Arrhythmia'
  | 'Sleep Stage'
  | 'Other'
  | 'Artifact'
  | 'Position'
  | 'Analysis Period'
  | 'Unknown';

export type MarkerOperation =
  | 'Add'
  | 'Delete'
  | 'Move'
  | 'ResizeStart'
  | 'ResizeEnd'
  | 'SwitchType'
  | 'ServerUpdate';

export type MarkerOverrideOnChange = (
  recordingId: RecordingId,
  scoringId: string,
  event: SignalEventDetailData,
  originalParams: UpdateEventParams,
) => Promise<boolean>;

export enum Severity {
  High = 5,
  Medium = 2,
  Low = 1,
  None = 0,
}

export interface MarkerTypeDefinition {
  type: MarkerType;
  name: string;
  color: string;
  shortName: string;
  markerGroup: MarkerGroup;
  severity: Severity;
  shortcutKey?: KeyCode;
  extras?: {
    hasDrop?: boolean;
    stripedBackground?: boolean;
  };
  isCrossChart?: boolean;
  onChange?: MarkerOverrideOnChange;
  dispatchEventOnChange?: EventType[];
}
