import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';

import './App.css';

import Routes from './routes/routes';
import { SheetToolbarContextProvider } from './context/sheet-toolbar-context';
import { FeatureToggling } from './utils/featureToggle';

const App: React.FunctionComponent = () => {
  return (
    <>
      <SheetToolbarContextProvider>
        <FeatureToggles features={FeatureToggling.getCurrentMode()}>
          <BrowserRouter basename="/">
            <Routes />
          </BrowserRouter>
        </FeatureToggles>
      </SheetToolbarContextProvider>
    </>
  );
};

export default App;
