import { Button } from '@material-ui/core';
import React from 'react';

interface ActionOrDismissProps {
  actionText?: string;
  actionOnClick?: () => void;
  dismissText: string;
  dismissOnClick: () => void;
}

export default function ActionOrDismiss(
  props: ActionOrDismissProps,
): JSX.Element {
  return (
    <>
      {props.actionText && (
        <Button
          onClick={props.actionOnClick}
          data-cy="SnackbarOpenRecordingBtn"
        >
          {props.actionText}
        </Button>
      )}

      <Button onClick={props.dismissOnClick} data-cy="SnackbarDismissBtn">
        {props.dismissText}
      </Button>
    </>
  );
}
