import {
  createStyles,
  fade,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { ChartOptions } from './getChartOptions';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    loading: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: ChartOptions.signalNameWidth,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      backgroundColor: '#ccd7eb29',
    },
    text: {
      color: fade(theme.colors.chart.loading.color, 0.5),
      fontWeight: 'bold',
    },
  });
});

const LoadingChart = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div data-cy="SignalLoadingText" className={classes.loading}>
      <Typography variant="caption" className={classes.text}>
        Downloading signal data...
      </Typography>
    </div>
  );
};

export default LoadingChart;
