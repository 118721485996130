import gql from 'graphql-tag';
import { ScoringInsights } from '../scoringInsights';

export type ScoringInsightsUpdatedType = 'scoringInsightsUpdated';

export interface ScoringInsightsUpdatedResult {
  scoringInsightsUpdated: ScoringInsights;
}

export interface ScoringInsightsSubscriptionParameters {
  recordingId: string;
  scoringId: string;
}

export const SCORING_INSIGHTS_UPDATED_SUBSCRIPTION = gql`
  subscription ScoringInsightsUpdated(
    $recordingId: String!
    $scoringId: String!
  ) {
    scoringInsightsUpdated(recordingId: $recordingId, scoringId: $scoringId) {
      recordingId
      scoringId
      parts {
        beginning
        end
        isSubmitted
        graphs {
          type
          signalType
          url
        }
        analysisPeriods {
          beginning
          end
          kpis {
            AHI
            ODI
            sleepEfficiency
          }
          sleepDurations {
            totalSecondsInWake
            totalSecondsInREM
            totalSecondsInNREM
          }
        }
      }
    }
  }
`;
