import React from 'react';
import clsx from 'clsx';
import { makeStyles, fade, Typography } from '@material-ui/core';

import KeyboardManager from '../SignalSheet/keyboardManager';
import KeyboardShortcut from '../Shared/KeyboardShortcut';
import themeProvider from '../../utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  compact: {
    display: 'initial',
  },
  categoryContainer: { margin: theme.spacing(0, 2) },
  shortcut: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 150px) min-content',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 250,
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(0.5),
    borderRadius: 4,
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, 0.35),
    },
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
  },
  condition: {
    color: fade(theme.colors.modal.color, 0.54),
    '@media print': {
      color: fade(
        theme.palette.getContrastText(
          themeProvider.getType('LIGHT').palette.background.paper,
        ),
        0.8,
      ),
    },
  },
}));

interface KeyboardShortcutsHelpProps {
  smallScreen?: boolean;
}

const KeyboardShortcutsHelp = (
  props: KeyboardShortcutsHelpProps,
): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div
        className={clsx(classes.root, { [classes.compact]: props.smallScreen })}
      >
        {KeyboardManager.getShortcutCategories().map((category) => (
          <div key={category.id} className={classes.categoryContainer}>
            <Typography variant="h6" gutterBottom>
              {category.name}
            </Typography>
            {KeyboardManager.getShortcutsByCategory(category.id).map(
              (shortcut) => (
                <div key={shortcut.id} className={classes.shortcut}>
                  <div className={classes.name}>
                    <Typography variant="body2">{shortcut.name}</Typography>
                    <Typography variant="caption" className={classes.condition}>
                      {shortcut.condition}
                    </Typography>
                  </div>
                  <div>
                    {shortcut.shortcutKeys.map((keys) => (
                      <div key={keys.map((key) => key).join()}>
                        <KeyboardShortcut keys={keys} />
                      </div>
                    ))}
                  </div>
                </div>
              ),
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default KeyboardShortcutsHelp;
