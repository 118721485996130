import React from 'react';
import _ from 'underscore';

import { Paper, makeStyles, Typography, fade } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Report } from '../../queries/report';
import FeatureToggle from '../Shared/FeatureToggle';
import ReportParameterList from './ReportParameterList';
import ReportParameterItem from './ReportParameterItem';
import themeProvider from '../../utils/theme';
import ReportParameterSearch from './ReportParameterSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    margin: theme.spacing(0, 1),
    padding: theme.spacing(2),
    height: '100%',

    '@media print': {
      margin: 'none !important',
      overflow: 'visible !important',
      display: 'block',
      height: 'auto !important',
      boxShadow: 'none',
      padding: 0,
      background: `${
        themeProvider.getType('LIGHT').palette.background.paper
      } !important`,
      color: theme.palette.getContrastText(
        themeProvider.getType('LIGHT').palette.background.paper,
      ),
      marginTop: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  fullParamContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(0, 1),
    position: 'relative',
    '@media print': {
      display: 'block',
    },
  },
  error: {
    margin: theme.spacing(2),
  },
  caption: {
    color: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.54,
    ),
  },
}));

interface ReportParametersProps {
  loading: boolean;
  report?: Report;
  error?: string;
  printing: boolean;
}

function ReportParameters(props: ReportParametersProps): JSX.Element {
  const classes = useStyles();
  const [reportData, setReportData] = React.useState(props.report?.data);
  const [searchString, setSearchString] = React.useState('');

  const handleSearch = (search: string) => {
    setSearchString(search);
    // Logger.log('[ReportParameters] handleSearch:', search);
    const filtered = props.report?.data.filter(
      (data) =>
        data.name.toLowerCase().search(search.toLowerCase()) !== -1 ||
        data.value.toLowerCase().search(search.toLowerCase()) !== -1,
    );
    // Logger.log('[ReportParameters] filtered:', filtered);
    setReportData(filtered);
  };

  React.useEffect(() => {
    handleSearch(searchString);
  }, [props.report?.data]);

  return (
    <>
      <Paper data-cy="ReportParametersPaper" className={classes.root}>
        <Typography variant="h6" className={classes.title}>
          Parameters{' '}
          {props.report?.data && (
            <ReportParameterSearch handleChange={handleSearch} />
          )}
        </Typography>

        {reportData && <ReportParameterList data={reportData} />}
        {props.report?.data &&
          props.report?.data.length > 0 &&
          reportData?.length === 0 && (
            <Typography align="center" className={classes.error}>
              No parameter matches the provided search text.
            </Typography>
          )}

        <div className={classes.fullParamContainer}>
          {props.loading && !props.report?.data && !props.error && (
            <>
              {Array(20)
                .fill(1)
                .map(() => (
                  <Skeleton
                    key={_.uniqueId()}
                    variant="text"
                    animation="wave"
                  />
                ))}
            </>
          )}

          {props.report?.data && props.printing && (
            <>
              {props.report.data.map((param) => (
                <>
                  <ReportParameterItem
                    key={param.name}
                    parameter={param}
                    onlyForPrinting
                  />
                </>
              ))}
            </>
          )}
        </div>

        {props.error && (
          <>
            <Typography align="center" className={classes.error}>
              There was an error trying to fetch the report data.
              <br />
              <FeatureToggle enabledFor={['development', 'staging']}>
                <Typography
                  variant="caption"
                  align="center"
                  className={classes.caption}
                >
                  {props.error.toString()}
                </Typography>
              </FeatureToggle>
            </Typography>
          </>
        )}
      </Paper>
    </>
  );
}

export default ReportParameters;
