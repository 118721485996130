import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { Button } from '@material-ui/core';
import { AttachedFile } from './interfaces/uploader-tools';
import { darkenOrLighten } from '../../utils/theme';
import { UploadStatus } from './interfaces/upload-manager';
import NotificationService from '../../services/notificationService';

const useStyles = makeStyles((theme: Theme) => ({
  open: {
    height: 25,
    margin: theme.spacing(0.5, 0, 1.5, 0),
    background: darkenOrLighten(
      theme.id,
      theme.palette.background.paper,
      0.1,
      0.1,
    ),
    color: theme.palette.getContrastText(
      darkenOrLighten(theme.id, theme.palette.background.paper, 0.1, 0.1),
    ),
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

interface ClearAttachedFilesProps {
  setAttachedFiles: (attachedFiles: AttachedFile[]) => void;
  setReadyToSubmit: (readyToSubmit: boolean) => void;
  setUploadStatus: (uploadStatus: UploadStatus | undefined) => void;
}

const ClearAttachedFiles = (props: ClearAttachedFilesProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.open}
        variant="contained"
        color="secondary"
        component="label"
        onClick={() => {
          NotificationService.closeAll();
          props.setAttachedFiles([]);
          props.setReadyToSubmit(false);
          props.setUploadStatus(undefined);
        }}
        data-cy="clearSelectionBtn"
      >
        <ClearIcon fontSize="small" className={classes.icon} /> Clear selection
      </Button>
    </>
  );
};

export default ClearAttachedFiles;
