import { ApolloError, useApolloClient } from '@apollo/client';
import queryString from 'query-string';
import React from 'react';

import { useLocation } from 'react-router-dom';
import AuthenticationError from '../../components/AuthenticationError/AuthenticationError';
import { GetIsAuthenticatedQueryResult } from '../../queries/isAuthenticated';
import AuthService from '../../services/authService';
import queryManager from '../../services/queryManager';

export interface CallbackQueryString {
  originUrl: string;
}

const Callback = (): JSX.Element => {
  const [authError, setAuthError] = React.useState<string | undefined>();

  const client = useApolloClient();
  const { search } = useLocation();

  const params = queryString.parse(search) as unknown as CallbackQueryString;

  React.useEffect(() => {
    if (!AuthService.isAuthenticated()) {
      if (params.originUrl) {
        queryManager.initializeQueryManager(client);
        queryManager
          .query<GetIsAuthenticatedQueryResult>('IsAuthenticated')
          .then((data) => {
            if (data.isAuthenticated) {
              window.location.replace(atob(params.originUrl));
            } else {
              setAuthError('Authentication failed.');
            }
          })
          .catch((error: ApolloError) => {
            setAuthError(`Authentication exception (${error})`);
          });
      } else {
        setAuthError('originUrl was not specified');
      }
    }
  });

  return <>{authError ? <AuthenticationError message={authError} /> : ''}</>;
};

export default Callback;
