import _ from 'underscore';
import {
  KeyboardManagerInterface,
  KeyboardShortcut,
  KeyboardShortcutCategory,
  KeyboardShortcutCategoryId,
  KeyboardShortcutId,
  KeyCode,
} from './interfaces/keyboard-manager';
import EventService from '../../services/eventService';
import Logger from '../../utils/logger';

const KeyboardShortcutsCategories = new Map<
  KeyboardShortcutCategoryId,
  KeyboardShortcutCategory
>();
KeyboardShortcutsCategories.set('Navigation', {
  id: 'Navigation',
  name: 'Navigation',
});
KeyboardShortcutsCategories.set('Tools', {
  id: 'Tools',
  name: 'Tools',
});
KeyboardShortcutsCategories.set('Scoring', {
  id: 'Scoring',
  name: 'Scoring',
});
KeyboardShortcutsCategories.set('Signal', {
  id: 'Signal',
  name: 'Signal',
});

const KeyboardShortcuts = new Map<KeyboardShortcutId, KeyboardShortcut>();
KeyboardShortcuts.set('KeyboardShortcut.UndoAction', {
  id: 'KeyboardShortcut.UndoAction',
  name: 'Undo action',
  category: 'Tools',
  shortcutKeys: [[KeyCode.Control, KeyCode.KeyZ]],
});
KeyboardShortcuts.set('KeyboardShortcut.RedoAction', {
  id: 'KeyboardShortcut.RedoAction',
  name: 'Redo action',
  category: 'Tools',
  shortcutKeys: [
    [KeyCode.Control, KeyCode.KeyY],
    [KeyCode.Control, KeyCode.Shift, KeyCode.KeyZ],
  ],
});
KeyboardShortcuts.set('KeyboardShortcut.DeleteEvent', {
  id: 'KeyboardShortcut.DeleteEvent',
  name: 'Delete event',
  condition: 'While the event detail popup is open',
  category: 'Scoring',
  shortcutKeys: [[KeyCode.Delete], [KeyCode.Backspace]],
});
// GL-958
/* KeyboardShortcuts.set('KeyboardShortcut.ScoreStageWake', {
  id: 'KeyboardShortcut.ScoreStageWake',
  name: 'Add Wake sleep stage',
  condition: 'On the selected epoch',
  category: 'Scoring',
  shortcutKeys: [[KeyCode.Num0]],
});
KeyboardShortcuts.set('KeyboardShortcut.ScoreStageN1', {
  id: 'KeyboardShortcut.ScoreStageN1',
  name: 'Add N1 sleep stage',
  condition: 'On the selected epoch',
  category: 'Scoring',
  shortcutKeys: [[KeyCode.Num1]],
});
KeyboardShortcuts.set('KeyboardShortcut.ScoreStageN2', {
  id: 'KeyboardShortcut.ScoreStageN2',
  name: 'Add N2 sleep stage',
  condition: 'On the selected epoch',
  category: 'Scoring',
  shortcutKeys: [[KeyCode.Num2]],
});
KeyboardShortcuts.set('KeyboardShortcut.ScoreStageN3', {
  id: 'KeyboardShortcut.ScoreStageN3',
  name: 'Add N3 sleep stage',
  condition: 'On the selected epoch',
  category: 'Scoring',
  shortcutKeys: [[KeyCode.Num3]],
});
KeyboardShortcuts.set('KeyboardShortcut.ScoreStageREM', {
  id: 'KeyboardShortcut.ScoreStageREM',
  name: 'Add REM sleep stage',
  condition: 'On the selected epoch',
  category: 'Scoring',
  shortcutKeys: [[KeyCode.Num5]],
}); */
KeyboardShortcuts.set('KeyboardShortcut.ZoomIn', {
  id: 'KeyboardShortcut.ZoomIn',
  name: 'Zoom in',
  category: 'Navigation',
  shortcutKeys: [[KeyCode.KeyPlus]],
});
KeyboardShortcuts.set('KeyboardShortcut.ZoomOut', {
  id: 'KeyboardShortcut.ZoomOut',
  name: 'Zoom out',
  category: 'Navigation',
  shortcutKeys: [[KeyCode.KeyMinus]],
});
KeyboardShortcuts.set('KeyboardShortcut.MoveBack', {
  id: 'KeyboardShortcut.MoveBack',
  name: 'Move back',
  category: 'Navigation',
  shortcutKeys: [[KeyCode.ArrowLeft]],
});
KeyboardShortcuts.set('KeyboardShortcut.MoveForward', {
  id: 'KeyboardShortcut.MoveForward',
  name: 'Move forward',
  category: 'Navigation',
  shortcutKeys: [[KeyCode.ArrowRight]],
});
KeyboardShortcuts.set('KeyboardShortcut.MoveBackFullPage', {
  id: 'KeyboardShortcut.MoveBackFullPage',
  name: 'Move a full page back',
  category: 'Navigation',
  shortcutKeys: [
    [KeyCode.Control, KeyCode.Shift, KeyCode.ArrowLeft],
    [KeyCode.PageUp],
  ],
});
KeyboardShortcuts.set('KeyboardShortcut.MoveForwardFullPage', {
  id: 'KeyboardShortcut.MoveForwardFullPage',
  name: 'Move a full page forward',
  category: 'Navigation',
  shortcutKeys: [
    [KeyCode.Control, KeyCode.Shift, KeyCode.ArrowRight],
    [KeyCode.PageDown],
  ],
});
KeyboardShortcuts.set('KeyboardShortcut.SelectPreviousEpoch', {
  id: 'KeyboardShortcut.SelectPreviousEpoch',
  name: 'Select previous epoch',
  category: 'Navigation',
  shortcutKeys: [[KeyCode.Shift, KeyCode.ArrowLeft]],
});
KeyboardShortcuts.set('KeyboardShortcut.SelectNextEpoch', {
  id: 'KeyboardShortcut.SelectNextEpoch',
  name: 'Select next epoch',
  category: 'Navigation',
  shortcutKeys: [[KeyCode.Shift, KeyCode.ArrowRight]],
});
KeyboardShortcuts.set('KeyboardShortcut.JumpToBeginning', {
  id: 'KeyboardShortcut.JumpToBeginning',
  name: 'Jump to the part start',
  category: 'Navigation',
  shortcutKeys: [[KeyCode.Home]],
});
KeyboardShortcuts.set('KeyboardShortcut.JumpToEnd', {
  id: 'KeyboardShortcut.JumpToEnd',
  name: 'Jump to the part end',
  category: 'Navigation',
  shortcutKeys: [[KeyCode.End]],
});
KeyboardShortcuts.set('KeyboardShortcut.ScaleToFitAll', {
  id: 'KeyboardShortcut.ScaleToFitAll',
  name: 'Scale to fit all signals',
  category: 'Signal',
  shortcutKeys: [[KeyCode.Alt, KeyCode.F9]],
});
KeyboardShortcuts.set('KeyboardShortcut.ScaleToFit', {
  id: 'KeyboardShortcut.ScaleToFit',
  name: 'Scale to fit',
  condition: 'Hovering the signal name',
  category: 'Signal',
  shortcutKeys: [[KeyCode.F9]],
});

// From https://unicode.org/charts/nameslist/n_2190.html
export const KeyCodeString = new Map<string, string>();
KeyCodeString.set(KeyCode.ArrowLeft, '←');
KeyCodeString.set(KeyCode.ArrowRight, '→');
KeyCodeString.set(KeyCode.Shift, '⇧');
KeyCodeString.set(KeyCode.Control, 'Ctrl');
KeyCodeString.set(KeyCode.PageUp, 'PageUp');
KeyCodeString.set(KeyCode.PageDown, 'PageDown');

const pressedKeys: string[] = [];
const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

const KeyPressedManager = {
  isShiftPressed: (event: KeyboardEvent) => event.shiftKey,
  isAltPressed: (event: KeyboardEvent) => event.altKey,
  isCtrlPressed: (event: KeyboardEvent) =>
    event.ctrlKey || (isMac && event.metaKey),
  isSpecialKey: (code: string) => {
    return [KeyCode.Shift, KeyCode.Meta, KeyCode.Control, KeyCode.Alt].some(
      (keyCode) => keyCode === code,
    );
  },
  addKey: (event: KeyboardEvent) => {
    const key = event.key.toUpperCase();
    const index = pressedKeys.indexOf(key);

    if (index === -1) {
      pressedKeys.push(key);
    }

    // Logger.debug('[KeyboardManager] pressedKeys', pressedKeys);
  },
  removeKey: (event: KeyboardEvent) => {
    const key = event.key.toUpperCase();
    const index = pressedKeys.indexOf(key);
    pressedKeys.splice(index, 1);

    if (isMac && key === KeyCode.Meta) {
      // MacOS: If the CMD key is released, we consider
      //        all keys have been released.
      pressedKeys.splice(0);
    }

    // Logger.debug('[KeyboardManager] pressedKeys', pressedKeys);
  },
};

const KeyboardManager: KeyboardManagerInterface = {
  init: () => {
    document.addEventListener('keydown', KeyboardManager.handleKeyPress);
    document.addEventListener('keyup', KeyboardManager.handleKeyReleased);
  },
  destroy: () => {
    document.removeEventListener('keydown', KeyboardManager.handleKeyPress);
    document.removeEventListener('keyup', KeyboardManager.handleKeyReleased);
  },
  handleKeyPress: (event: KeyboardEvent) => {
    // Logger.debug('[KeyboardManager] keyPress', event);
    EventService.dispatch('Keyboard.KeyPressed', event);

    if (!KeyPressedManager.isSpecialKey(event.key.toUpperCase())) {
      KeyPressedManager.addKey(event);
      KeyboardManager.triggerShortcuts(event);
    }
  },
  handleKeyReleased: (event: KeyboardEvent) => {
    // Logger.debug('[KeyboardManager] keyUp', event);
    KeyPressedManager.removeKey(event);
    EventService.dispatch('Keyboard.KeyReleased', event);
  },
  triggerShortcuts: (event: KeyboardEvent) => {
    const isShiftPressed = KeyPressedManager.isShiftPressed(event);
    const isCtrlPressed = KeyPressedManager.isCtrlPressed(event);
    const isAltPressed = KeyPressedManager.isAltPressed(event);

    const activeKeys = [...pressedKeys];
    if (isShiftPressed) {
      activeKeys.push(KeyCode.Shift);
    }
    if (isCtrlPressed) {
      activeKeys.push(KeyCode.Control);
    }
    if (isAltPressed) {
      activeKeys.push(KeyCode.Alt);
    }

    Logger.debug('[KeyboardManager][triggerShortcuts] activeKeys', activeKeys);
    EventService.dispatch('Keyboard.ActiveKeys', activeKeys);

    const activeShortcuts: KeyboardShortcutId[] = [];
    KeyboardShortcuts.forEach((shortcut, name) => {
      // Logger.debug('[KeyboardManager][triggerShortcuts] Evaluating:', name);
      shortcut.shortcutKeys.forEach((keyCombination) => {
        // Logger.debug('[KeyboardManager][triggerShortcuts] ---> keyCombination', keyCombination);
        // Logger.debug('[KeyboardManager][triggerShortcuts] ---> activeKeys', activeKeys);

        if (
          keyCombination.length === activeKeys.length &&
          _.difference(keyCombination, activeKeys).length === 0
        ) {
          activeShortcuts.push(name);
        }
      });
    });

    activeShortcuts.forEach((shortcutName) =>
      EventService.dispatch(shortcutName),
    );

    Logger.debug(
      '[KeyboardManager][triggerShortcuts] activeShortcuts',
      activeShortcuts,
    );
  },
  getShortcutCategories: () => Array.from(KeyboardShortcutsCategories.values()),
  getShortcutsByCategory: (category: KeyboardShortcutCategoryId) =>
    Array.from(KeyboardShortcuts.values()).filter(
      (shortcut) => shortcut.category === category,
    ),
  isEventThisKeyCode: (event: KeyboardEvent, keyCode: string) =>
    event.key.toUpperCase() === keyCode,
  shortcutToText: (shortcutId: KeyboardShortcutId) => {
    const text: string[] = [];
    KeyboardShortcuts.get(shortcutId)?.shortcutKeys[0].forEach((key) =>
      text.push(KeyboardManager.keyToText(key)),
    );
    return text.join(' + ');
  },
  keyToText: (keyCode: string) =>
    KeyCodeString.get(keyCode) ??
    keyCode.charAt(0).toUpperCase() + keyCode.toLowerCase().slice(1),
};

export default KeyboardManager;
