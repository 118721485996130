import gql from 'graphql-tag';

export interface ScoringChanged {
  scoringChanged: {
    recordingId: string;
    scoringId: string;
    version: string;
    scoring?: ScoringDiff;
    readOnly: boolean;
    markers?: ScoringMarkerDiff[];
  };
}

interface ScoringDiff {
  name?: string;
  deleted?: boolean;
}

export interface ScoringMarkerDiff {
  markerId: string;
  eventType?: string;
  signal?: string;
  startTime?: string;
  endTime?: string;
  deleted?: boolean;
  isAutomatic?: boolean;
}

export interface ScoringSubscriptionParameters {
  recordingId: string;
  scoringId: string;
}

export interface MarkerChangedMutationParameters {
  recordingId: string;
  scoringId: string;
  markerId: string;
  eventType: string;
  signal?: string;
  timestamp: number;
  durationMilliseconds: number;
  deleted?: boolean;
}

export const SCORING_CHANGED_SUBSCRIPTION = gql`
  subscription ScoringChanged($recordingId: String!, $scoringId: String!) {
    scoringChanged(recordingId: $recordingId, scoringId: $scoringId) {
      recordingId
      scoringId
      version
      scoring {
        name
        deleted
      }
      readOnly
      markers {
        markerId
        eventType
        signal
        startTime
        endTime
        deleted
      }
    }
  }
`;

export const MARKER_CHANGED_MUTATION = gql`
  mutation MarkerChanged(
    $recordingId: String!
    $scoringId: String!
    $markerId: String!
    $eventType: String
    $signal: String
    $timestamp: DateTime
    $durationMilliseconds: Int
    $deleted: Boolean
  ) {
    scoringMarker(
      recordingId: $recordingId
      scoringId: $scoringId
      markerId: $markerId
      eventType: $eventType
      signal: $signal
      timestamp: $timestamp
      durationMilliseconds: $durationMilliseconds
      deleted: $deleted
    )
  }
`;
