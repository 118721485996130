import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React, { useEffect } from 'react';
import { useTheme, makeStyles, createStyles } from '@material-ui/core';
import {
  ChartProps,
  OnLoadChartFunction,
} from '../../../interfaces/chart-props';
import Logger from '../../../utils/logger';
import PassiveEvents from '../../../utils/PassiveEvents';
import getChartOptions from './getChartOptions';
import SignalMenu from '../SignalMenu/SignalMenu';
import SignalRenderingService from '../../../services/signalRenderingService';
import DataLabelContainer from './DataLabelContainer';

PassiveEvents(Highcharts);

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
    },
  }),
);

const SignalChart = (props: ChartProps): JSX.Element => {
  Logger.log('[SignalChart] Created new instance for:', props.signalInfo.name);
  const classes = useStyles();

  const [options, setOptions] =
    React.useState<Highcharts.Options | undefined>();

  const [isSignalMenuOpen, setSignalMenuOpen] = React.useState(false);
  const [isSignalMenuDisabled, setSignalMenuDisabled] = React.useState(false);

  const theme = useTheme();

  const onSignalChartLoad: OnLoadChartFunction = (chart: Highcharts.Chart) => {
    Logger.log('[SignalChart] Initializing', props.signalInfo.name);
    Logger.log('[SignalChart] chart', chart);

    SignalRenderingService.showLoadingIfNeeded(chart).then(
      SignalRenderingService.addDataQueries,
    );
  };

  useEffect(() => {
    Logger.log('[SignalChart] useEffect', props.signalInfo.name);
    const chartOptions: Highcharts.Options = getChartOptions(
      props.signalInfo,
      theme,
      onSignalChartLoad,
    );
    setOptions(chartOptions);

    return () => {
      Logger.log('[SignalChart] useEffect cleanup', props.signalInfo.name);
    };
  }, [props.signalInfo]);

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!isSignalMenuDisabled) {
      setSignalMenuOpen(true);
    }
  };

  return (
    <div
      data-cy={`Signal-${props.signalInfo.type}`}
      className={classes.container}
      onContextMenu={handleRightClick}
    >
      {options && (
        <>
          <SignalMenu
            isOpen={isSignalMenuOpen}
            setOpen={setSignalMenuOpen}
            signalInfo={props.signalInfo}
            setDisabled={setSignalMenuDisabled}
          />
          <HighchartsReact
            highcharts={Highcharts}
            constructorType="stockChart"
            allowChartUpdate={false}
            options={options}
          />
          <DataLabelContainer signal={props.signalInfo} />
        </>
      )}
    </div>
  );
};

export default React.memo(SignalChart, () => true);
