import Logger from '../utils/logger';
import {
  AnalyticsInterface,
  AnalyticsKey,
  AnalyticsScoringChange,
  AnalyticsNavigation,
  AnalyticsSignalReordering,
  AnalyticsInternalInterface,
} from '../interfaces/analytics';
import UserAttributesService from './userAttributesService';

let isInitialized = false;
let isBadApiKey = false;
const isTouchDevice = 'ontouchstart' in document.documentElement;

const AnalyticsInternal: AnalyticsInternalInterface = {
  initialize: () => {
    if (!isInitialized && !isBadApiKey) {
      Logger.log('[Analytics/initialize]');
      const apiKey = process.env.REACT_APP_SEGMENT_API_KEY;
      if (apiKey) {
        analytics.load(apiKey);
        const userId = UserAttributesService.getUserId() || 'NO_USER';
        analytics.identify(userId);
        isInitialized = true;
      } else {
        isBadApiKey = true;
        Logger.error('[Analytics] Invalid API Key for Segment');
      }
    } else if (isBadApiKey) {
      Logger.debug('[Analytics] Ignoring tracking (bad API Key)');
    }
  },
  getAPI: () => {
    AnalyticsInternal.initialize();
    return analytics; // Defined in snippet in public/index.html
  },
};

const Analytics: AnalyticsInterface = {
  track: {
    page: (page: string, name?: string) => {
      Logger.log('[Analytics/track/page]', page, name);
      AnalyticsInternal.getAPI().page(page, name);
    },
    event: (event: AnalyticsKey, properties?: { [index: string]: unknown }) => {
      Logger.log('[Analytics/track/event] event:', event);
      AnalyticsInternal.getAPI().track(event, properties || {});
    },
    scoringChange: (change: AnalyticsScoringChange) => {
      Logger.log('[Analytics/track/event] scoringChange:', change);
      const eventKey: AnalyticsKey = 'SCORING_CHANGE';
      AnalyticsInternal.getAPI().track(eventKey, change);
    },
    navigation: (rawChange: AnalyticsNavigation) => {
      const eventKey: AnalyticsKey = 'NAVIGATION_INTERACTION';
      const change = rawChange;
      change.isTouchDevice = isTouchDevice;
      Logger.log('[Analytics/track/event] navigation:', change);
      AnalyticsInternal.getAPI().track(eventKey, rawChange);
    },
    signalReordering: (change: AnalyticsSignalReordering) => {
      const eventKey: AnalyticsKey = 'SIGNAL_REORDERING';
      Logger.log('[Analytics/track/event] signal:', change);
      AnalyticsInternal.getAPI().track(eventKey, change);
    },
  },
};

export default Analytics;
