import gql from 'graphql-tag';

export interface CompleteUploadMutationParameters {
  importId: string;
  files: UploadedFileInput[];
}

export interface UploadedFileInput {
  name: string;
  hash: string;
}

export interface CompleteUploadMutationResult {
  completeUpload: null;
}

export const COMPLETE_UPLOAD_MUTATION = gql`
  mutation CompleteUpload($importId: String!, $files: [UploadedFileInput!]!) {
    completeUpload(importId: $importId, files: $files)
  }
`;
