/* eslint-disable no-param-reassign */
import {
  Button,
  createStyles,
  fade,
  makeStyles,
  Typography,
} from '@material-ui/core';

import AlarmIcon from '@material-ui/icons/Alarm';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import React from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import clsx from 'clsx';
import ScoringRenderingService from '../../../services/scoringRenderingService';
import Logger from '../../../utils/logger';
import eventChartTools from '../SignalChart/eventChartTools';
import { ScoringCanvasEvent } from './ScoringCanvas';
import signalEventDetailService from '../../SignalEventDetail/signalEventDetailService';
import Hidden from '../../Shared/Hidden';
import { generateStripedGradient, ZIndex } from '../../../utils/theme';
import chartRangeTools from '../../SignalSheet/chartRangeTools';
import ScoringService from '../../../services/scoringService';

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      position: 'absolute',
      zIndex: ZIndex.Baseline,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      cursor: 'pointer',
      textTransform: 'initial',
      justifyContent: 'initial',
      alignItems: 'initial',
      color: '#fff',
      minWidth: 0,
      padding: 0,
      boxShadow: 'none',
      border: `1px solid ${fade('#000', 0.1)}`,
    },
    hidden: {
      display: 'none',
    },
    visibleContainer: {
      overflow: 'hidden',
      textAlign: 'left',
      margin: theme.spacing(0.5, 1.5),
      flexDirection: 'column',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    narrow: {
      marginLeft: 0,
      marginRight: 0,
      alignItems: 'center',
    },
    dense: {
      marginTop: 0,
      marginBottom: 0,
      justifyContent: 'center',
    },
    name: {
      fontSize: '11px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
    details: {
      alignItems: 'end',
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      fontStyle: 'italic',
    },
    detailsNarrow: {
      textAlign: 'center',
    },
    duration: {
      fontSize: '9px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
    icon: { fontSize: '12px', marginRight: 4, marginBottom: -2 },
    drop: {
      fontSize: '9px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
    dragHandle: {
      display: 'flex',
      position: 'absolute',
      top: '10%',
      bottom: '10%',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 10,
      zIndex: ZIndex.AboveBaseline,
    },
    resizeCursor: {
      cursor: 'ew-resize',
    },
    grabbingCursor: {
      cursor: 'ew-resize',
    },
    dragHandleRight: {
      right: 0,
    },
    dragHandleNarrowLeft: {
      left: -10,
    },
    dragHandleNarrowRight: {
      right: -10,
    },
    handleBar: {
      visibility: 'hidden',
      width: 2,
      height: '50%',
      boxShadow: '0px 0px 2px 1px #00000040',
      borderRadius: 20,
      backgroundColor: '#0000000d',
      transition: '0.1s opacity linear',
    },
    visible: {
      visibility: 'initial',
    },
    fadeIn: {
      opacity: 1,
      animationName: '$fadeIn',
      animationIterationCount: 1,
      animationTimingFunction: 'ease-in',
      animationDuration: '0.2s',
    },
    '@keyframes fadeIn': {
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
    blinking: {
      animationName: '$blinking',
      animationDuration: '0.4s',
      animationIterationCount: 2,
    },
    '@keyframes blinking': {
      '0%': { opacity: 1 },
      '49%': { opacity: 1 },
      '60%': { opacity: 0.7 },
      '99%': { opacity: 0.7 },
      '100%': { opacity: 1 },
    },
  });
});

interface ScoringEventProps {
  event: ScoringCanvasEvent;
}

export type MoveModes = ResizeModes | 'move';
export type ResizeModes = 'left' | 'right';
export type ResizeTimes = { start: number; end: number };

const ScoringEvent = (props: ScoringEventProps): JSX.Element => {
  const classes = useStyles();
  const marker = props.event;
  const { position } = props.event;

  const eventColor = eventChartTools.getColorByEventType(marker.type);

  // Logger.debug('[ScoringEvent] marker', marker);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const [isResizing, setIsResizing] = React.useState<boolean>(false);
  const [isDragging, setIsDragging] = React.useState<boolean>(false);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const [left, setLeft] = React.useState<number>(position.left);
  const [width, setWidth] = React.useState<number>(position.width);
  const [outsideLeftSide, setOutsideLeftSide] = React.useState<number>(
    position.outsideLeftSide,
  );
  const [outsideRightSide, setOutsideRightSide] = React.useState<number>(
    position.outsideRightSide,
  );
  const [visibleWidth, setVisibleWidth] = React.useState<number>(
    position.visibleWidth,
  );

  let initialDrop: number | undefined;
  if (marker.extras.hasDrop && marker.signalInfo) {
    initialDrop = eventChartTools.calculatePeakToPeak(
      marker.start,
      marker.end,
      marker.signalInfo,
    );
    if (signalEventDetailService.popup.isOpenAndSameMarkerId(marker.id)) {
      signalEventDetailService.setData(marker);
    }
  }
  const [drop, setDrop] = React.useState<number | undefined>(initialDrop);

  const initialDuration = chartRangeTools.calculateDuration(
    marker.start,
    marker.end,
    {
      short: true,
    },
  );
  const [duration, setDuration] = React.useState<string>(initialDuration);

  const recalculatePosition = (
    e: DraggableEvent,
    data: DraggableData,
    mode: MoveModes,
  ): number => {
    const containerWidth = ScoringRenderingService.getScoringCanvasWidth();
    let newLeft = position.left;
    let newWidth = position.width;
    let newDuration: string | undefined;

    if (mode === 'move') {
      newLeft = position.left + data.x;
    } else if (mode === 'left') {
      const { start, end } = eventChartTools.getTimesAfterResizeOrMove(
        marker,
        data.x,
        mode,
      );
      newDuration = chartRangeTools.calculateDuration(start, end, {
        short: true,
      });
      newLeft = position.left + data.x;
      newWidth = position.width - data.x;
      data.node.style.transform = 'translate(0px, 0px)';
    } else if (mode === 'right') {
      const { start, end } = eventChartTools.getTimesAfterResizeOrMove(
        marker,
        data.x,
        mode,
      );
      newDuration = chartRangeTools.calculateDuration(start, end, {
        short: true,
      });
      newWidth = position.width + data.x;
      data.node.style.transform = 'translate(0px, 0px)';
    }

    const newOutsideLeftSide = newLeft < 0 ? Math.abs(newLeft) : 0;
    const newOutsideRightSide =
      newLeft + newWidth > containerWidth
        ? newLeft + newWidth - containerWidth
        : 0;

    const newVisibleWidth = newWidth - newOutsideLeftSide - newOutsideRightSide;

    if (newVisibleWidth > 20) {
      if (mode !== 'move') {
        setLeft(newLeft);
      }
      setOutsideLeftSide(newOutsideLeftSide);
      setOutsideRightSide(newOutsideRightSide);
      setVisibleWidth(newVisibleWidth);
      setWidth(newWidth);
      if (newDuration !== undefined) {
        setDuration(newDuration);
      }
    }

    return newVisibleWidth;
  };

  const resizeHandle = (mode: ResizeModes) => (
    <>
      {!ScoringService.isReadOnly() && (
        <Draggable
          axis="x"
          offsetParent={
            ScoringRenderingService.getScoringCanvasContainer()
              .current as HTMLElement
          }
          onStart={(e) => {
            Logger.log('[ScoringEvent] Drag onStart');

            if (e.altKey) {
              ScoringService.removeEvent(marker.id, { send: true });
              return false;
            }
            signalEventDetailService.popup.close();
            ScoringRenderingService.setEventBeingResizedId(marker.id);
            setIsResizing(true);
            setIsHovering(false);
            return undefined;
          }}
          onDrag={(e, data) => {
            // Logger.log('[ScoringEvent] Drag onDrag,', data);
            const newVisibleWidth = recalculatePosition(e, data, mode);

            if (newVisibleWidth > 20) {
              eventChartTools.calculateDropOnMoveOrResize(
                marker,
                marker.signalInfo,
                data.x,
                mode,
                (newDrop) => {
                  setDrop(newDrop);
                },
              );
            }
          }}
          onStop={(e, data) => {
            Logger.log('[ScoringEvent] Drag onStop:', data);
            data.node.style.transform = 'translate(0px, 0px)';
            if (mode === 'left') {
              data.x = left - position.left;
            } else {
              data.x = width - position.width;
            }
            eventChartTools.onMarkerResize(marker, data, mode, anchorRef);
            setIsResizing(false);
            setIsHovering(true);
          }}
        >
          <div
            className={clsx(classes.dragHandle, classes.resizeCursor, {
              [classes.dragHandleRight]: mode === 'right' && visibleWidth >= 10,
              [classes.dragHandleNarrowRight]:
                mode === 'right' && visibleWidth < 10,
              [classes.dragHandleNarrowLeft]:
                mode === 'left' && visibleWidth < 10,
            })}
          >
            <div
              className={clsx(classes.handleBar, {
                [classes.visible]: isHovering,
              })}
            />
          </div>
        </Draggable>
      )}
    </>
  );

  return (
    <Draggable
      disabled={ScoringService.isReadOnly()}
      axis="x"
      cancel={`.${classes.dragHandle}`}
      onStart={(e) => {
        Logger.log('[ScoringEvent] Move onStart');

        if (!ScoringRenderingService.isEventBeingResized()) {
          if (e.altKey) {
            ScoringService.removeEvent(marker.id, { send: true });
            return false;
          }
          signalEventDetailService.popup.close();
          eventChartTools.onMarkerDragStart(marker);
          setIsDragging(true);
          return undefined;
        }
        return false;
      }}
      onDrag={(e: DraggableEvent, data: DraggableData) => {
        // Logger.log('[ScoringEvent] Move onDrag: ', data);
        if (!ScoringRenderingService.isEventBeingResized()) {
          recalculatePosition(e, data, 'move');
          eventChartTools.calculateDropOnMoveOrResize(
            marker,
            marker.signalInfo,
            data.x,
            'move',
            (newDrop) => {
              setDrop(newDrop);
            },
          );
          return undefined;
        }
        return false;
      }}
      onStop={(e, data) => {
        Logger.log('[ScoringEvent] Move onStop,', data);
        if (!ScoringRenderingService.isEventBeingResized()) {
          setIsDragging(false);
          eventChartTools.onMarkerDragStop(marker, data, anchorRef);
          return undefined;
        }
        return false;
      }}
    >
      <Button
        data-cy="Event"
        ref={anchorRef}
        className={clsx(classes.root, {
          [classes.hidden]: !position.visible,
          [classes.resizeCursor]: isResizing,
          [classes.grabbingCursor]: isDragging,
          [classes.fadeIn]: position.fadeIn,
          [classes.blinking]: position.blink,
        })}
        style={{
          left,
          width,
          top: position.top,
          height: position.height,
          background: marker.extras.stripedBackground
            ? generateStripedGradient(eventColor, 0.55, 0.7)
            : eventColor,
        }}
        onMouseOver={() => {
          if (!ScoringRenderingService.isEventBeingResized()) {
            setIsHovering(true);
          }
        }}
        onMouseLeave={() => {
          if (!ScoringRenderingService.isEventBeingResized()) {
            setIsHovering(false);
          }
        }}
        onClick={() => {
          if (ScoringService.isReadOnly()) {
            if (anchorRef?.current) {
              eventChartTools.onClick(marker, anchorRef.current);
            }
          }
        }}
      >
        {resizeHandle('left')}
        <div
          className={clsx(classes.visibleContainer, {
            [classes.dense]: position.height < 20,
            [classes.narrow]: visibleWidth < 75,
          })}
          style={{
            paddingLeft: outsideLeftSide,
            paddingRight: outsideRightSide,
            display: visibleWidth < 0 ? 'none' : 'flex',
          }}
        >
          <Typography data-cy="EventName" className={clsx(classes.name)}>
            {(() => {
              if (visibleWidth > 75) return marker.name;
              if (visibleWidth > 40) {
                return eventChartTools.getShortNameByEventType(marker.type);
              }
              return eventChartTools.getShortNameByEventType(marker.type)[0];
            })()}
          </Typography>

          <div
            className={clsx(classes.details, {
              [classes.hidden]: position.height < 30,
              [classes.detailsNarrow]: visibleWidth < 75,
            })}
            style={{
              paddingLeft: outsideLeftSide,
              paddingRight: outsideRightSide,
            }}
          >
            {drop !== undefined && drop > 0 && (
              <Typography className={classes.drop}>
                {visibleWidth > 60 && (
                  <TrendingDownIcon fontSize="small" className={classes.icon} />
                )}
                {`${drop.toFixed(0)}%`}
              </Typography>
            )}

            {duration && (
              <Typography
                data-cy="EventDuration"
                className={clsx(classes.duration, {
                  [classes.hidden]:
                    position.height < 50 && drop !== undefined && drop > 0,
                })}
              >
                {visibleWidth > 60 && (
                  <AlarmIcon fontSize="small" className={classes.icon} />
                )}
                {duration}
              </Typography>
            )}

            <Hidden data-cy="ScoringEvent-SignalType">
              {props.event.signalInfo?.type}
            </Hidden>
          </div>
        </div>
        {resizeHandle('right')}
      </Button>
    </Draggable>
  );
};

export default ScoringEvent;
