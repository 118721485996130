import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  useMediaQuery,
  Typography,
  FormHelperText,
  fade,
  lighten,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { SnackbarKey } from 'notistack';
import { RecordingPartData } from './OverviewParts';
import queryManager from '../../services/queryManager';
import { SubmitScoringPartMutationParameters } from '../../queries/scoringInsights';
import Analytics from '../../services/analytics';
import EventService from '../../services/eventService';
import ScoringService from '../../services/scoringService';
import NotificationService from '../../services/notificationService';
import ActionOrDismiss from '../ActionOrDismiss/ActionOrDismiss';

interface OverviewPartSubmitPartProps {
  recordingPart: RecordingPartData;
}

const useStyles = makeStyles((theme) => ({
  button: {
    background: theme.palette.primary.main,
    position: 'relative',
    fontSize: 12,
    textTransform: 'initial',
    textAlign: 'center',
    lineHeight: '12px',
    margin: theme.spacing(0.5, 1),
    maxWidth: 125,
    whiteSpace: 'normal',
    fontWeight: 400,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogTitle: {
    color: theme.colors.modal.color,
  },
  dialogContent: {
    color: theme.colors.modal.color,
  },
  dialogButton: {
    color: theme.colors.text.link,
    '&:hover': {
      backgroundColor: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.05,
      ),
    },
  },
  compact: {
    maxWidth: 70,
    minWidth: 0,
    '& $icon': {
      marginRight: 0,
    },
  },
  errorText: {
    textAlign: 'right',
    marginTop: theme.spacing(-1.5),
    padding: theme.spacing(2),
  },
  sent: {
    background: theme.palette.background.paper,
    pointerEvents: 'none',
    color:
      theme.id === 'LIGHT'
        ? theme.palette.primary.main
        : theme.palette.getContrastText(theme.palette.background.paper),
    borderColor:
      theme.id === 'LIGHT'
        ? lighten(theme.palette.primary.main, 0.2)
        : 'transparent',
  },
}));

const OverviewPartSubmitPart = (
  props: OverviewPartSubmitPartProps,
): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [sent, setSent] = React.useState(props.recordingPart.isSubmitted);

  const [hasSubmittedPart, setHasSubmittedPart] = React.useState(
    ScoringService.getSubmittedPartId() !== undefined,
  );

  React.useEffect(() => {
    const cbId = EventService.subscribe('ScoringSubmittedPart', (part) =>
      setHasSubmittedPart(part !== undefined),
    );

    return () => {
      EventService.unsubscribe(cbId);
    };
  });

  const smallScreen = useMediaQuery('(max-width:1000px)');

  const mutationParams: SubmitScoringPartMutationParameters = {
    part: {
      recordingId: props.recordingPart.recordingId,
      scoringId: props.recordingPart.scoringId,
      beginning: props.recordingPart.startTime,
      end: props.recordingPart.endTime,
    },
  };

  const handleClickOpen = () => {
    Analytics.track.event('OVERVIEW_SUBMIT_PART', {
      recordingId: props.recordingPart.recordingId,
      params: mutationParams,
    });

    setLoading(true);
    setError(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setError(false);
  };

  const handleSubmission = () => {
    setSubmitting(true);
    setError(false);

    ScoringService.setSubmittingPart(true);

    queryManager
      .mutate('submitScoringPart', mutationParams)
      .then(() => {
        Analytics.track.event('PART_SUBMITTED', {
          origin: 'Overview',
          params: mutationParams,
        });

        setSubmitting(false);
        handleClose();
        setSent(true);
        ScoringService.setReadOnly(true);
        ScoringService.setSubmittedPartId(props.recordingPart.partId);

        const dismissAction = (key: SnackbarKey) => (
          <ActionOrDismiss
            dismissText="Dismiss"
            dismissOnClick={() => NotificationService.close(key)}
          />
        );

        NotificationService.send(
          'The part has been submitted successfully. This tab can be closed now.',
          { variant: 'success', persist: true, customAction: dismissAction },
        );
      })
      .catch((err) => {
        Analytics.track.event('PART_SUBMITTED_FAILED', {
          origin: 'Overview',
          params: mutationParams,
          error: err,
        });

        ScoringService.setSubmittingPart(false);
        setSubmitting(false);
        setError(true);
      });
  };

  return (
    <>
      <Button
        className={clsx(classes.button, {
          [classes.compact]: smallScreen,
          [classes.sent]: sent,
        })}
        variant={sent ? 'outlined' : 'contained'}
        color="primary"
        onClick={handleClickOpen}
        disabled={loading || (!sent && hasSubmittedPart)}
      >
        {!sent ? (
          <>
            <SendIcon className={classes.icon} />
            {!smallScreen ? `Submit Part ${props.recordingPart.partId}` : ''}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </>
        ) : (
          <>
            <AssignmentTurnedInIcon className={classes.icon} />
            {!smallScreen ? `Part ${props.recordingPart.partId} submitted` : ''}
          </>
        )}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          data-cy="CompleteScoringDialogTitle"
          className={classes.dialogTitle}
        >
          Complete Scoring
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContent}>
            <Typography>
              Submit the scoring for <b>Part {props.recordingPart.partId}</b>{' '}
              and make it read-only.
            </Typography>
            <Typography>
              It will <b>not</b> be possible to submit other parts after this
              operation.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.dialogButton}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmission}
            color="primary"
            className={classes.dialogButton}
            autoFocus
          >
            Submit Part {props.recordingPart.partId}
            {submitting && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
        {error && (
          <FormHelperText className={classes.errorText} error>
            There was an error during the submission
          </FormHelperText>
        )}
      </Dialog>
    </>
  );
};

export default OverviewPartSubmitPart;
