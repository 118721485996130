import * as H from 'history';
import PageStatus, {
  PartId,
  SignalSheetTab,
} from '../../interfaces/page-status';

import SheetDefinition, {
  SheetId,
  SignalDefinition,
  SignalType,
} from '../../interfaces/sheet-definition';
import {
  GetGraphDataParameters,
  GetGraphDataResult,
} from '../../queries/graphData';
import { RecordingId } from '../../queries/recording';
import { ScoringInsightsSignalInfo } from '../../queries/scoringInsights';
import EventService from '../../services/eventService';
import queryManager from '../../services/queryManager';
import TabSyncService from '../../services/tabSyncService';
import Logger from '../../utils/logger';
import {
  overviewChartDefinitions,
  OverviewGraphDataSamples,
  OverviewGraphSignalType,
  OverviewGraphType,
} from '../Chart/OverviewChart/overviewChartDefinitions';
import { SignalDefinitionTools } from '../SignalSheet/definitions/SignalDefinitions';
import SheetDefinitions from '../SignalSheet/sheetDefinitions';
import { RecordingPartData } from './OverviewParts';
import Analytics from '../../services/analytics';
import { OpenSignalSheetOrigin } from './OverviewPartOpenSignalSheet';

export type PageStatusUpdateFunction = (
  value: React.SetStateAction<PageStatus>,
) => void;

interface StudyTools {
  initialize: (
    recordingId: RecordingId,
    pageStatus: PageStatusUpdateFunction,
  ) => void;
  addSignalSheetTab: (newTab: SignalSheetTab) => void;
  disableSignalSheetTabAnimation: (tab: SignalSheetTab) => void;
  getSignalSheetTabs: () => SignalSheetTab[];
  isSignalSheetTabPresent: (tab: SignalSheetTab) => boolean;
  getSelectedPart: () => number | undefined;
  setSelectedPart: (selectedPart: number) => void;
  clearSelectedPart: () => void;
  isPartSelected: () => boolean;
  requestStudyGraphData: (parts: RecordingPartData[]) => void;
  getGraphData: (
    partId: PartId,
    signalType: OverviewGraphSignalType,
  ) => OverviewGraphDataSamples;
  registerChart: (
    partId: PartId,
    type: OverviewGraphType,
    chart: Highcharts.Chart,
  ) => void;
  retrieveChart: (
    partId: PartId,
    type: OverviewGraphType,
  ) => Highcharts.Chart | undefined;
  getSignalDefinitionForCustomSheet: (
    customId: number,
  ) => SignalDefinition[] | undefined;
  isValidSheetDefinition: (sheetId?: SheetId, customId?: string) => boolean;
  pageStatus: {
    registerStatusUpdateFunction: (fn: PageStatusUpdateFunction) => void;
    update: PageStatusUpdateFunction;
  };
  setAvailableSignals: (signals: ScoringInsightsSignalInfo[]) => void;
  getAvailableSignals: () => SignalDefinition[];
  automaticAlternativeSignalSelector: (
    signals: SignalDefinition[],
  ) => SignalDefinition[];
  getEpochFromUrl: (location: H.Location) => number | undefined;
  addEpochToUrl: (url: string, epoch: number) => string;
  removeEpochFromUrl: (location: H.Location, history: H.History) => void;
  openSignalSheets: (opts: {
    recordingId: string;
    scoringId: string;
    partId: number;
    origin: OpenSignalSheetOrigin;
    epoch?: number;
    sheetDefinition: SheetDefinition;
    history: H.History;
    customId?: number;
  }) => void;
}

let currentRecordingId: RecordingId = '';
let selectedPart: number | undefined;
let pageStatusUpdate: PageStatusUpdateFunction = () => null;

export type SignalSheetTabId = string;

const signalSheetTabs = new Map<SignalSheetTabId, SignalSheetTab>();

const getSignalSheetTabId = (tab: SignalSheetTab): string =>
  [tab.partId, tab.sheetId, tab.customId].join();

export type OverviewGraphDataMap = Map<
  OverviewGraphSignalType,
  OverviewGraphDataSamples
>;
export type OverviewChartMap = Map<OverviewGraphType, Highcharts.Chart>;
const overviewPartGraphMap = new Map<PartId, OverviewGraphDataMap>();
const overviewPartChartMap = new Map<PartId, OverviewChartMap>();

const availableSignals = new Map<SignalType, SignalDefinition>();

const studyTools: StudyTools = {
  initialize: (
    recordingId: RecordingId,
    pageStatus: PageStatusUpdateFunction,
  ) => {
    Logger.log('[studyTools] Initialize');
    Logger.log('[studyTools] currentRecordingId', currentRecordingId);
    Logger.log('[studyTools] recordingId', recordingId);

    if (recordingId !== currentRecordingId) {
      Logger.log('[studyTools] Initialize -> Clearing');

      currentRecordingId = recordingId;
      signalSheetTabs.clear();
      availableSignals.clear();
      overviewPartGraphMap.clear();
      overviewPartChartMap.clear();
    }

    pageStatusUpdate = pageStatus;
  },
  setSelectedPart: (newSelectedPart: number) => {
    selectedPart = newSelectedPart;
  },
  clearSelectedPart: () => {
    selectedPart = undefined;
  },
  getSelectedPart: () => selectedPart,
  isPartSelected: () => selectedPart !== undefined,
  addSignalSheetTab: (newTab: SignalSheetTab) => {
    signalSheetTabs.set(getSignalSheetTabId(newTab), newTab);
  },
  disableSignalSheetTabAnimation: (tab: SignalSheetTab) => {
    const tabId = getSignalSheetTabId(tab);
    signalSheetTabs.set(tabId, { ...tab, animate: false });
  },
  isSignalSheetTabPresent: (tab: SignalSheetTab) => {
    const tabId = getSignalSheetTabId(tab);
    return signalSheetTabs.has(tabId);
  },
  getSignalSheetTabs: () =>
    Array.from(signalSheetTabs.values()).sort((a, b) => a.partId - b.partId),
  requestStudyGraphData: (parts) => {
    Logger.log('[requestStudyGraphData] Init');

    const definitions = Array.from(overviewChartDefinitions.values());

    parts.forEach((part) => {
      if (!overviewPartGraphMap.has(part.partId)) {
        Logger.log('[requestStudyGraphData] Requesting for part', part.partId);
        const graphDataMap: OverviewGraphDataMap = new Map();
        overviewPartGraphMap.set(part.partId, graphDataMap);
        definitions.forEach((definition) => {
          if (definition.signalType) {
            const params: GetGraphDataParameters = {
              beginning: new Date(part.startTime),
              end: new Date(part.endTime),
              recordingId: part.recordingId,
              signalType: definition.signalType as string,
            };

            queryManager
              .query<GetGraphDataResult>('GraphData', params)
              .then((data) => {
                Logger.log('[requestStudyGraphData] got graph data!', data);
                const samplesArray: number[][] = data.graphData.samples.map(
                  (sample) => [sample.timestamp, sample.value],
                );
                graphDataMap.set(params.signalType, samplesArray);
                EventService.dispatch('Overview.GraphDataReceived', {
                  partId: part.partId,
                  signalType: params.signalType,
                });
              });
          }
        });
      }
    });
  },
  getGraphData: (partId: PartId, signalType: OverviewGraphSignalType) => {
    Logger.log('[getGraphData] overviewPartGraphMap', overviewPartGraphMap);
    let graphData: OverviewGraphDataSamples = [];

    if (overviewPartGraphMap.has(partId)) {
      const part = overviewPartGraphMap.get(partId);
      if (part?.has(signalType)) {
        graphData = part.get(signalType) || [];
      }
    }

    return graphData;
  },
  registerChart: (
    partId: PartId,
    type: OverviewGraphType,
    chart: Highcharts.Chart,
  ) => {
    const chartMap: OverviewChartMap =
      overviewPartChartMap.get(partId) || new Map();
    chartMap.set(type, chart);
    overviewPartChartMap.set(partId, chartMap);
  },
  retrieveChart: (partId: PartId, type: OverviewGraphType) => {
    return overviewPartChartMap.get(partId)?.get(type);
  },
  getSignalDefinitionForCustomSheet: (customId: number) => {
    const customSheets = TabSyncService.getCustomSheets();
    const customSheet = customSheets.find((sheet) => sheet.id === customId);
    if (customSheet) {
      const definitions = SignalDefinitionTools.getBySignalTypes(
        customSheet.signalTypes,
      );
      return definitions;
    }
    return undefined;
  },
  isValidSheetDefinition: (sheetId?: SheetId, customId?: string) => {
    if (sheetId === 'CustomSheet') {
      if (customId) {
        return true;
      }
      return false;
    }
    return !!SheetDefinitions.get(sheetId as SheetId);
  },
  pageStatus: {
    registerStatusUpdateFunction: (fn: PageStatusUpdateFunction) => {
      pageStatusUpdate = fn;
    },
    update: pageStatusUpdate,
  },
  setAvailableSignals: (signals: ScoringInsightsSignalInfo[]) => {
    Logger.log('[setAvailableSignals] signals', signals);
    const signalTypes = signals.map((signal) => signal.signalType);

    const signalDefinition = SignalDefinitionTools.getBySignalTypes(
      signalTypes as SignalType[],
    );
    Logger.log('[setAvailableSignals] signalDefinition', signalDefinition);
    signalDefinition.forEach((signal) =>
      availableSignals.set(signal.type, signal),
    );
  },
  getAvailableSignals: () => Array.from(availableSignals.values()),
  automaticAlternativeSignalSelector: (signals: SignalDefinition[]) => {
    const newSignals = signals;
    const available = studyTools.getAvailableSignals();

    if (available.length) {
      newSignals.forEach((signal, index) => {
        const missingSignal = !available.some(
          (existing) => existing.type === signal.type,
        );
        if (
          signal.alternativeSignals &&
          signal.alternativeSignals.length > 0 &&
          missingSignal
        ) {
          const validSignalType = signal.alternativeSignals.find(
            (alternative) => available.some((s) => s.type === alternative),
          );
          const validSignal = available.find((s) => s.type === validSignalType);

          if (validSignal) {
            newSignals[index] = validSignal;
          }
        }
      });
    }

    return newSignals;
  },
  getEpochFromUrl: (location: H.Location) => {
    const queryParams = new URLSearchParams(location.search);
    const epoch = queryParams.get('epoch');

    if (epoch) {
      return parseInt(epoch, 10) - 1;
    }
    return undefined;
  },
  addEpochToUrl: (url: string, epoch: number) =>
    [url, '&epoch=', epoch].join(''),
  removeEpochFromUrl: (location: H.Location, history: H.History) => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('epoch')) {
      queryParams.delete('epoch');
      history.replace({
        search: queryParams.toString(),
      });
    }
  },
  openSignalSheets: (opts: {
    recordingId: string;
    scoringId: string;
    partId: number;
    origin: 'Actions' | 'Stats' | 'Hover';
    epoch?: number;
    sheetDefinition: SheetDefinition;
    history: H.History;
    customId?: number;
  }) => {
    Logger.log('[openSignalSheets] opts', opts);
    const newTab: SignalSheetTab = {
      partId: opts.partId,
      sheetId: opts.sheetDefinition.type,
      animate: true,
    };

    if (!studyTools.isSignalSheetTabPresent(newTab)) {
      studyTools.pageStatus.update((oldPageStatus) => {
        studyTools.addSignalSheetTab(newTab);
        return {
          ...oldPageStatus,
          signalSheetTabs: studyTools.getSignalSheetTabs(),
        };
      });
    }

    Analytics.track.event('OVERVIEW_OPEN_SIGNAL_SHEET', {
      part: opts.partId,
      origin: opts.origin,
    });

    let url = [
      '/recording',
      '/',
      opts.sheetDefinition.path,
      '?id=',
      opts.recordingId,
      '&part=',
      opts.partId,
      '&scoringId=',
      opts.scoringId,
    ].join('');

    if (opts.customId) {
      url += `&customId=${opts.customId}`;
    }

    if (opts.epoch !== undefined) {
      url = studyTools.addEpochToUrl(url, opts.epoch);
    }

    opts.history.push(url);
  },
};

export default studyTools;
