import React from 'react';
import moment from 'moment-timezone';
import Logger from '../../utils/logger';

export interface TimeElapsedTimerProps {
  current?: number;
  from?: number;
  stop: boolean;
}

const TimeElapsedTimer = (props: TimeElapsedTimerProps): JSX.Element => {
  const [timer, setTimer] = React.useState(0);
  const [, setTimeFrom] = React.useState(Date.now());
  const [timerId, setTimerId] = React.useState<NodeJS.Timeout>();

  const tick = () => {
    setTimeFrom((from) => {
      setTimer(Date.now() - from);
      return from;
    });
  };

  const startTimer = () => {
    Logger.log('[TimeElapsedTimer] startTimer');
    const id = setInterval(tick, 300);
    setTimerId(id);
    tick();
  };

  const stopTimer = () => {
    Logger.log('[TimeElapsedTimer] stopTimer');
    if (timerId) clearInterval(timerId);
  };

  const setClock = () => {
    const from = props.from || Date.now();
    const current = props.current ? props.current - from : 0;
    setTimer(current);
    setTimeFrom(from);
  };

  React.useEffect(() => {
    Logger.log('[TimeElapsedTimer] useEffect');

    if (props.stop) {
      if (props.from) setClock();
      stopTimer();
    } else {
      setClock();
      startTimer();
    }

    return () => {
      Logger.log('[TimeElapsedTimer] useEffect destroy');
      stopTimer();
    };
  }, [props.stop]);

  return <>{moment(timer).format('HH:mm:ss')}</>;
};

export default TimeElapsedTimer;
