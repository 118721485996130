import React from 'react';

import {
  Button,
  makeStyles,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { RecordingId } from '../../queries/recording';
import ExportRecording from '../ExportRecording/ExportRecording';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    minWidth: 20,
  },
  menu: {
    backgroundColor: theme.colors.modal.backgroundColor,
    color: theme.colors.modal.color,
    '&:focus': {
      outline: 'none',
    },
  },
}));

interface RecordingListOptionsProps {
  recordingId: RecordingId;
}

const RecordingListOptions = (
  props: RecordingListOptionsProps,
): JSX.Element => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event?: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      event &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef?.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        className={classes.menuButton}
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.menu}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem>
                    <ExportRecording
                      recordingId={props.recordingId}
                      isMenuItem
                      closeMenu={handleClose}
                    />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default RecordingListOptions;
