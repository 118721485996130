import React from 'react';
import FeatureToggle from './FeatureToggle';

interface HiddenProps {
  ['data-cy']: string;
  children?: JSX.Element | JSX.Element[] | string;
}

const Hidden = (props: HiddenProps): JSX.Element => {
  return (
    <FeatureToggle enabledFor={['development', 'staging']}>
      <div data-cy={props['data-cy']} style={{ display: 'none' }}>
        {props.children}
      </div>
    </FeatureToggle>
  );
};

export default Hidden;
