import React from 'react';
import clsx from 'clsx';

import { fade, Fade, Theme, Tooltip, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Skeleton } from '@material-ui/lab';
import {
  SleepParameter,
  SleepParameterData,
} from '../../interfaces/sleep-parameter';
import { OverviewPartMenuPos } from './OverviewPartDetailSecondaryMenu';
import Logger from '../../utils/logger';
import { RecordingPartData } from './OverviewParts';
import EventService from '../../services/eventService';
import Analytics from '../../services/analytics';
import { SignalEventDetailData } from '../../interfaces/signal-event-detail-props';
import ThreeLoadingDots from '../Shared/ThreeLoadingDots';
import eventChartTools from '../Chart/SignalChart/eventChartTools';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridTemplateColumns: 'min-content',
    lineHeight: '15px',
    alignItems: 'center',
    justifyItems: 'center',
    textAlign: 'center',
    marginRight: theme.spacing(1),
    gap: '8px',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  name: {
    alignSelf: 'flex-end',
    whiteSpace: 'normal',
    lineHeight: '11px',
    fontSize: 11,
    maxWidth: 55,
  },
  date: {
    justifySelf: 'flex-start',
    marginLeft: 8,
  },
  value: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    padding: theme.spacing(0, 1),
    color: theme.palette.getContrastText(theme.palette.background.paper),
    borderRadius: 4,
    minWidth: 42,
    minHeight: 19,
    fontSize: 13,
    transition: '0.1s all linear',
    boxShadow: 'none',
    '&:after': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      content: '""',
      borderRadius: 4,
      border: `1px solid ${fade('#000', 0.1)}`,
    },
  },
  statUnknown: {
    background: theme.colors.stats.unknown,
    color: theme.palette.getContrastText(theme.colors.stats.unknown),
  },
  statOk: {
    background: theme.colors.stats.ok,
    color: 'white',
  },
  statMild: {
    background: theme.colors.stats.mild,
    color: theme.palette.getContrastText(theme.colors.stats.moderate),
  },
  statModerate: {
    background: theme.colors.stats.moderate,
    color: 'white',
  },
  statSevere: {
    background: theme.colors.stats.severe,
    color: theme.palette.getContrastText(theme.colors.stats.severe),
  },
  tooltip: {
    maxWidth: 300,
    textAlign: 'center',
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(90%)',
    },
  },
  noStyle: {
    background: 'transparent',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    boxShadow: 'none',
    '&:after': {
      border: 'none',
    },
  },
}));

interface OverviewPartStatProps {
  data?: SleepParameterData;
  loading?: boolean;
  paramDefinition: SleepParameter | undefined;
  handleMenuOpen?: (pos: OverviewPartMenuPos) => void;
  recordingPart?: RecordingPartData;
}

const OverviewPartStat = (props: OverviewPartStatProps): JSX.Element => {
  const classes = useStyles();
  const [visible, setVisible] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);

  const colorRules = props.paramDefinition?.colorRules;

  React.useEffect(() => {
    setTimeout(() => setVisible(true));
    setTimeout(() => setDirty(false));
    const cbId: string[] = [];
    if (
      props.paramDefinition?.invalidateOnAnalysisPeriodChange &&
      props.recordingPart
    ) {
      const startTime = new Date(props.recordingPart.startTime).valueOf();
      const endTime = new Date(props.recordingPart.endTime).valueOf();

      cbId.push(
        EventService.subscribe(
          ['Scoring.AnalysisPeriodChanged', 'Scoring.ExclusionZoneChanged'],
          (marker: SignalEventDetailData) => {
            if (
              eventChartTools.isMarkerWithinTimePeriod(
                marker,
                startTime,
                endTime,
              )
            ) {
              setDirty(true);
            }
          },
        ),
      );
    }

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, [props.data?.updatedOn]);

  return (
    <>
      {props.paramDefinition && colorRules && (
        <Fade in={visible} mountOnEnter>
          <div
            className={classes.root}
            data-cy={`${props.paramDefinition.name}-Parameter`}
          >
            <Tooltip
              title={props.paramDefinition.tooltipText}
              classes={{ tooltip: classes.tooltip }}
              style={{ cursor: 'help' }}
              arrow
              PopperProps={{ placement: 'top' }}
            >
              <Typography
                variant="caption"
                className={clsx(classes.name, {
                  [classes.date]:
                    props.paramDefinition.type === 'PartStartDate',
                })}
              >
                {props.paramDefinition.name}
              </Typography>
            </Tooltip>
            {!props.loading &&
              (props.data !== undefined &&
              props.data.value !== undefined &&
              props.data.value !== null ? (
                <Typography
                  className={clsx(classes.value, {
                    [classes.statUnknown]: colorRules.unknown
                      ? colorRules.unknown(props.data.value)
                      : false,
                    [classes.statOk]: colorRules.ok(props.data.value),
                    [classes.statMild]: colorRules.mild
                      ? colorRules.mild(props.data.value)
                      : false,
                    [classes.statModerate]: colorRules.moderate
                      ? colorRules.moderate(props.data.value)
                      : false,
                    [classes.statSevere]: colorRules.severe(props.data.value),
                    [classes.clickable]: props.data.timestamp && !dirty,
                    [classes.noStyle]:
                      props.paramDefinition.type === 'PartStartDate',
                  })}
                  onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    Logger.log('[OverviewPartStat] onClick', props);

                    if (
                      props.data?.timestamp &&
                      !dirty &&
                      props.handleMenuOpen &&
                      props.recordingPart
                    ) {
                      Analytics.track.event('OVERVIEW_STAT_CLICK', {
                        stat: props.paramDefinition?.type,
                        value: props.data.value,
                      });

                      props.handleMenuOpen({
                        mouseX: event.clientX - 2,
                        mouseY: event.clientY + 10,
                        timestampFrom: props.data?.timestamp,
                        timestampTo: null,
                        partId: props.recordingPart?.partId,
                        origin: 'Stats',
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (props.data?.timestamp && !dirty) {
                      EventService.dispatch('Overview.HighlightTimestamp');
                    }
                  }}
                  onMouseMove={() => {
                    if (props.data?.timestamp && !dirty) {
                      EventService.dispatch(
                        'Overview.HighlightTimestamp',
                        props.data?.timestamp,
                      );
                    }
                  }}
                >
                  {dirty ? (
                    <ThreeLoadingDots />
                  ) : (
                    props.paramDefinition.formatter(props.data.value)
                  )}
                </Typography>
              ) : (
                <Typography
                  className={clsx(classes.value, classes.statUnknown)}
                >
                  -
                </Typography>
              ))}

            {props.loading && (
              <Skeleton variant="text" animation="wave" width={40} />
            )}
          </div>
        </Fade>
      )}
    </>
  );
};

export default OverviewPartStat;
