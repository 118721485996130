import React from 'react';
import clsx from 'clsx';

import { makeStyles, Theme, ButtonGroup, Button } from '@material-ui/core';

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SheetToolbarMode } from '../../interfaces/sheet-toolbar-props';
import sheetTools from '../SignalSheet/sheetTools';
import Logger from '../../utils/logger';
import chartTools from '../Chart/SignalChart/chartTools';
import { ZIndex } from '../../utils/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    marginBottom: -23,
    opacity: 0,
    transition: '0.1s all linear',
    zIndex: ZIndex.AboveBaseline,
  },
  minimal: {
    marginBottom: -20,
  },
  visible: {
    opacity: 1,
  },
  buttonGroup: {
    boxShadow: '0px -3px 3px -2px rgba(0,0,0,0.12)',
  },
  button: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    border: 'none',
    '&:not(:last-child)': {
      borderRight: 'none',
    },

    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },

    '& .MuiButton-label': {
      height: 10,
    },

    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1.00rem',
    },
  },
}));

interface SheetToolbarModeSwitcherProps {
  toolbarMode: SheetToolbarMode;
  setToolbarMode: (value: React.SetStateAction<SheetToolbarMode>) => void;
}

const SheetToolbarModeSwitcher = (
  props: SheetToolbarModeSwitcherProps,
): JSX.Element => {
  const [visible, setVisible] = React.useState(false);
  const [moving, setMoving] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const classes = useStyles();

  const checkHoverAndHide = () => {
    setHover((currentHover) => {
      Logger.log('[checkHoverAndHide] currentHover', hover);
      if (!currentHover) {
        setVisible(false);
      } else {
        setTimeout(() => checkHoverAndHide(), 1000);
      }

      return currentHover;
    });
  };

  const startMoving = () => {
    setMoving(true);
    setTimeout(() => sheetTools.autoFitAll(), 200);
    setTimeout(() => {
      setMoving(false);
      checkHoverAndHide();
    }, 1000);
  };

  const resizeNavigator = (mode: SheetToolbarMode) => {
    const navigatorChart = chartTools.getNavigatorChart();
    if (navigatorChart) {
      const { options } = navigatorChart;
      if (!!options && options.navigator) {
        const newChartHeight = chartTools.getNavigatorChartHeight(mode);
        const newNavigatorHeight = chartTools.getNavigatorHeight(mode);
        options.navigator.height = newNavigatorHeight;
        navigatorChart.update({ navigator: options.navigator }, false);
        navigatorChart.setSize(undefined, newChartHeight);
      }
    }
  };

  const expandLess = () =>
    props.setToolbarMode((mode) => {
      startMoving();
      let newMode: SheetToolbarMode = 'Hidden';
      if (mode === 'Full') newMode = 'Minimal';
      if (mode !== newMode) resizeNavigator(newMode);
      return newMode;
    });
  const expandMore = () =>
    props.setToolbarMode((mode) => {
      startMoving();
      let newMode: SheetToolbarMode = 'Full';
      if (mode === 'Hidden') newMode = 'Minimal';
      if (mode !== newMode) resizeNavigator(newMode);
      return newMode;
    });
  const fullscreen = () => {
    startMoving();
    props.setToolbarMode('Hidden');
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.visible]: visible,
        [classes.minimal]: props.toolbarMode === 'Minimal',
      })}
      onMouseEnter={() => {
        setHover(true);
        setVisible(true);
      }}
      onMouseLeave={() => {
        setHover(false);
        if (!moving) setVisible(false);
      }}
    >
      <ButtonGroup
        className={classes.buttonGroup}
        variant="contained"
        size="small"
      >
        <Button
          className={classes.button}
          onClick={expandLess}
          disabled={['Hidden'].includes(props.toolbarMode)}
        >
          <ExpandLessIcon fontSize="small" />
        </Button>
        <Button
          className={classes.button}
          onClick={fullscreen}
          disabled={['Hidden'].includes(props.toolbarMode)}
        >
          <FullscreenIcon fontSize="small" />
        </Button>
        <Button
          className={classes.button}
          onClick={expandMore}
          disabled={['Full'].includes(props.toolbarMode)}
        >
          <ExpandMoreIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default SheetToolbarModeSwitcher;
