import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, Tooltip, Grow } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import PortableWifiOffIcon from '@material-ui/icons/PortableWifiOff';
import EventService from '../../services/eventService';
import ScoringService from '../../services/scoringService';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing(0, 0.2),
  },
  tooltip: {
    maxWidth: 300,
  },
  loading: {
    animation: '$blinkingText 0.5s infinite',
  },
  receiving: {
    animation: '$blinkingText 1s infinite',
  },
  disconnectedIcon: {
    paddingTop: 1,
  },
  '@keyframes blinkingText': {
    '0%': { opacity: 1 },
    '49%': { opacity: 1 },
    '60%': { opacity: 0.7 },
    '99%': { opacity: 0.7 },
    '100%': { opacity: 1 },
  },
}));

const ConnectedStatus = (): JSX.Element => {
  const classes = useStyles();
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isReceiving, setIsReceiving] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [isReadOnlyScoring, setIsReadOnlyScoring] = React.useState(
    ScoringService.isReadOnly(),
  );

  React.useEffect(() => {
    let blinkingTimeout: NodeJS.Timeout;
    let disconnectedTimeout: NodeJS.Timeout;
    let initialStateTimeout: NodeJS.Timeout;

    if (ScoringService.getMarkerStorageSize() === 0) {
      initialStateTimeout = setTimeout(() => setHasTimedOut(true), 10000);
    } else {
      setIsLoading(false);
    }

    const callbackIds = [
      EventService.subscribe('Subscription.ScoringChanged', () => {
        setIsDisconnected(false);
        setIsReceiving(true);
        clearTimeout(blinkingTimeout);
        clearTimeout(initialStateTimeout);
        blinkingTimeout = setTimeout(() => setIsReceiving(false), 2000);
      }),
      EventService.subscribe('ScoringInitialStateReceived', () => {
        clearTimeout(initialStateTimeout);
        setIsLoading(false);
      }),
      EventService.subscribe('WebsocketDisconnected', () => {
        disconnectedTimeout = setTimeout(() => setIsDisconnected(true), 2000);
      }),
      EventService.subscribe('WebsocketConnected', () => {
        clearTimeout(disconnectedTimeout);
        setIsDisconnected(false);
      }),
      EventService.subscribe<boolean>('ScoringReadOnly', (isReadOnly) =>
        setIsReadOnlyScoring(isReadOnly),
      ),
    ];

    return () => {
      clearTimeout(blinkingTimeout);
      clearTimeout(initialStateTimeout);
      setHasTimedOut(false);
      setIsDisconnected(false);
      setIsLoading(false);
      setIsReceiving(false);
      EventService.unsubscribe(callbackIds);
    };
  }, []);

  const tooltipText = (() => {
    if (isLoading) return 'Trying to establish a connection...';
    if (isDisconnected)
      return 'You are offline. Changes will be received when the connection is restored.';
    return 'Connected to the Noxturnal Cloud Services.';
  })();

  return (
    <Grow in={!isReadOnlyScoring} mountOnEnter unmountOnExit>
      <div className={classes.root}>
        <Tooltip
          title={tooltipText}
          classes={{ tooltip: classes.tooltip }}
          style={{ cursor: 'pointer' }}
          arrow
        >
          <Typography>
            {isDisconnected || hasTimedOut ? (
              <PortableWifiOffIcon
                className={classes.disconnectedIcon}
                fontSize="small"
              />
            ) : (
              <WifiTetheringIcon
                fontSize="small"
                className={clsx({
                  [classes.receiving]: isReceiving,
                  [classes.loading]: isLoading,
                })}
              />
            )}
          </Typography>
        </Tooltip>

        {hasTimedOut && (
          <Tooltip
            title={
              'Something went wrong receiving the scoring.\n' +
              'Please, call support or your changes might get lost.'
            }
            classes={{ tooltip: classes.tooltip }}
            style={{ cursor: 'pointer' }}
            arrow
          >
            <WarningIcon fontSize="small" />
          </Tooltip>
        )}
      </div>
    </Grow>
  );
};

export default ConnectedStatus;
