import React from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import { Recording } from '../../queries/recording';
import OverviewRecordingInfo from './OverviewRecordingInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

interface OverviewHeaderProps {
  recording?: Recording;
}

const OverviewHeader = (props: OverviewHeaderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Sleep Study
      </Typography>
      <OverviewRecordingInfo recording={props.recording} />
    </Paper>
  );
};

export default OverviewHeader;
