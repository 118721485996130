import React, { useEffect } from 'react';

import { Paper, makeStyles, Typography } from '@material-ui/core';
import { useApolloClient } from '@apollo/client';
import withNavigationBars from '../../hoc/withNavigationBars';

import withTheme from '../../hoc/withTheme';

import UploadRecordingToolbar from '../../components/UploadRecording/UploadRecordingToolbar';
import SelectFiles from '../../components/UploadRecording/SelectFiles';
import FileUploader from '../../components/UploadRecording/FileUploader';
import FileSummary from '../../components/UploadRecording/FileSummary';
import {
  AttachedFile,
  FileDefinition,
} from '../../components/UploadRecording/interfaces/uploader-tools';
import uploaderTools from '../../components/UploadRecording/utils/uploaderTools';
import queryManager from '../../services/queryManager';
import { UploadStatus } from '../../components/UploadRecording/interfaces/upload-manager';
import ClearAttachedFiles from '../../components/UploadRecording/ClearAttachedFiles';
import Analytics from '../../services/analytics';
import NotificationService from '../../services/notificationService';
import AppTitle from '../../components/Shared/AppTitle';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
  },
  instructions: {
    color: theme.colors.text.lighter,
    marginBottom: theme.spacing(2),
  },
  container: {
    margin: '0 auto',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  sheet: {
    height: 'calc(100% - 89px)',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    margin: theme.spacing(2, 2),
  },
}));

const UploadRecording = () => {
  NotificationService.useNotifications();
  const classes = useStyles();
  const client = useApolloClient();
  const [attachedFiles, setAttachedFiles] = React.useState<AttachedFile[]>([]);
  const [readyToSubmit, setReadyToSubmit] = React.useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] =
    React.useState<boolean>(false);
  const [uploadStatus, setUploadStatus] =
    React.useState<UploadStatus | undefined>();
  const fileDefinitions: FileDefinition[] =
    uploaderTools.generateFileDefinitions();

  useEffect(() => {
    Analytics.track.page('Upload Recording');
    queryManager.initializeQueryManager(client);
  }, []);

  return (
    <>
      <AppTitle title="Upload Recording" />
      <UploadRecordingToolbar uploadInProgress={uploadInProgress} />
      <Paper className={classes.sheet}>
        <div className={classes.container}>
          <Typography variant="h3" className={classes.title}>
            Upload Recording
          </Typography>
          {attachedFiles.length === 0 && (
            <Typography className={classes.instructions} variant="body2">
              Navigate to the folder and select all the recording files
            </Typography>
          )}

          <FileSummary
            attachedFiles={attachedFiles}
            fileDefinitions={fileDefinitions}
          />
          {attachedFiles.length === 0 || uploadInProgress ? (
            <SelectFiles
              setAttachedFiles={setAttachedFiles}
              fileDefinitions={fileDefinitions}
              setReadyToSubmit={setReadyToSubmit}
              uploadInProgress={uploadInProgress}
            />
          ) : (
            <ClearAttachedFiles
              setAttachedFiles={setAttachedFiles}
              setReadyToSubmit={setReadyToSubmit}
              setUploadStatus={setUploadStatus}
            />
          )}
          <FileUploader
            setAttachedFiles={setAttachedFiles}
            setReadyToSubmit={setReadyToSubmit}
            setUploadInProgress={setUploadInProgress}
            setUploadStatus={setUploadStatus}
            attachedFiles={attachedFiles}
            readyToSubmit={readyToSubmit}
            uploadStatus={uploadStatus}
            uploadInProgress={uploadInProgress}
          />
        </div>
      </Paper>
    </>
  );
};

export default withTheme(
  withNavigationBars(React.memo(UploadRecording, () => true)),
);
