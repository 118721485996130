import React from 'react';
import queryString from 'query-string';

import { useLocation, useParams } from 'react-router-dom';
import { ApolloError, useApolloClient } from '@apollo/client';
import withNavigationBars from '../../hoc/withNavigationBars';
import SignalSheet from '../../components/SignalSheet/SignalSheet';

import {
  WithPageStatus,
  QueryParamsRecording,
  PartId,
  UrlParamsRecording,
  SignalSheetTab,
} from '../../interfaces/page-status';
import withTheme, { WithTheme } from '../../hoc/withTheme';
import Logger from '../../utils/logger';
import { Recording, GetRecordingQueryResult } from '../../queries/recording';
import queryManager from '../../services/queryManager';
import SheetDefinitions from '../../components/SignalSheet/sheetDefinitions';
import SheetDefinition, { SheetPath } from '../../interfaces/sheet-definition';
import SignalSheetLoading from '../../components/SignalSheetLoading/SignalSheetLoading';
import RecordingNotFound from '../../components/RecordingNotFound/RecordingNotFound';
import KeyboardManager from '../../components/SignalSheet/keyboardManager';
import NotificationService from '../../services/notificationService';
import studyTools from '../../components/StudyOverview/studyTools';
import {
  GetScoringInsightsQueryResult,
  ScoringInsightsPart,
} from '../../queries/scoringInsights';
import chartRangeTools from '../../components/SignalSheet/chartRangeTools';
import sheetTools from '../../components/SignalSheet/sheetTools';
import ScoringService from '../../services/scoringService';
import AuthService from '../../services/authService';
import EventService from '../../services/eventService';
import AppTitle from '../../components/Shared/AppTitle';

const SheetWorkspace = (props: WithTheme<WithPageStatus<unknown>>) => {
  NotificationService.useNotifications();

  const [loading, setLoading] = React.useState(true);
  const [recording, setRecording] = React.useState<Recording>();
  const [recordingPart, setRecordingPart] =
    React.useState<ScoringInsightsPart>();
  const [sheetDefinition, setSheetDefinition] =
    React.useState<SheetDefinition>();
  const [error, setError] = React.useState<string | undefined>();

  const client = useApolloClient();
  const { search } = useLocation();
  const { sheetPath } = useParams<UrlParamsRecording>();

  const params = queryString.parse(search) as unknown as QueryParamsRecording;
  const sheetId = SheetDefinitions.getTypeByPath(sheetPath as SheetPath);
  const partId: PartId = params.part ? parseInt(params.part, 10) : 1;
  const customSheetId = params.customId
    ? parseInt(params.customId, 10)
    : undefined;

  const isValidSheetDefinition = studyTools.isValidSheetDefinition(
    sheetId,
    params.customId,
  );

  if (!params.id) {
    Logger.error(
      '[SheetWorkspace] There is no recordingId parameter in the URL',
    );
  }

  if (!sheetId) {
    Logger.error(
      '[SheetWorkspace] Could not find a Sheet definition for the path:',
      sheetPath,
    );
  }

  React.useEffect(() => {
    Logger.log('[SheetWorkspace] useEffect');
    Logger.log('[SheetWorkspace] partId', partId);

    setLoading(true);
    studyTools.initialize(params.id, (status) => {
      props.pageStatus.setStatus(status);
      setLoading(true);
    });
    studyTools.setSelectedPart(partId);

    if (sheetId) {
      const currentTab: SignalSheetTab = {
        sheetId,
        partId,
        customId: customSheetId,
      };

      if (!studyTools.isSignalSheetTabPresent(currentTab)) {
        studyTools.addSignalSheetTab(currentTab);
      }

      props.pageStatus.setStatus({
        ...props.pageStatus.status,
        currentPage: sheetId,
        sheetInfo: {
          partId: currentTab.partId,
          customId: currentTab.customId,
        },
        recordingId: params.id,
        signalSheetTabs: studyTools.getSignalSheetTabs(),
      });

      if (!params.id) {
        setError('RecordingId was not specified');
      } else if (!params.scoringId) {
        setError('scoringId was not specified');
      } else {
        sheetTools.setRecordingId(params.id);
        sheetTools.setScoringId(params.scoringId);

        let newRecording: Recording | undefined;

        queryManager.initializeQueryManager(client);
        queryManager
          .query<GetRecordingQueryResult>('Recording', {
            recordingId: sheetTools.getRecordingId(),
          })
          .then((data) => {
            newRecording = data.recording;

            chartRangeTools.setRecordingStartTime(data.recording.startTime);
            chartRangeTools.setRecordingEndTime(data.recording.endTime);
          })
          .then(() =>
            AuthService.requestAccessTokenIfNeeded({
              query: 'ScoringInsights',
            }),
          )
          .then(() => {
            setRecording(newRecording);
            ScoringService.initialize(sheetTools.getRecordingId());
          })
          .then(() =>
            queryManager.query<GetScoringInsightsQueryResult>(
              'ScoringInsights',
              {
                recordingId: params.id,
                scoringId: sheetTools.getScoringId(),
              },
            ),
          )
          .then((data) => {
            const { parts } = data.scoringInsights;
            if (parts && parts.length > 0) {
              const sortedParts = parts.sort((a, b) => {
                const dateA = new Date(a.beginning);
                const dateB = new Date(b.beginning);
                return dateA.valueOf() - dateB.valueOf();
              });
              const submittedPartIndex = sortedParts.findIndex(
                (part) => part.isSubmitted,
              );

              if (
                submittedPartIndex !== -1 &&
                !ScoringService.getSubmittedPartId()
              ) {
                ScoringService.setSubmittedPartId(submittedPartIndex + 1);
              }

              const selectedPart = parts[partId - 1];

              if (selectedPart) {
                setRecordingPart(selectedPart);

                studyTools.setAvailableSignals(selectedPart.signals);

                setSheetDefinition(
                  SheetDefinitions.get(sheetId, customSheetId),
                );

                chartRangeTools.setPartStartTime(selectedPart.beginning);
                chartRangeTools.setPartEndTime(selectedPart.end);
              }
            } else {
              setError('No recording parts for this recording');
            }
            setLoading(false);
          })
          .catch((err: ApolloError) => {
            setError(err.message);
            setLoading(false);
            Logger.error(
              '[Sheet] Failed retrieving recording: %s',
              params.id,
              err,
            );
          });
      }
    }

    KeyboardManager.init();
    const cbId = EventService.subscribe('Sheet.Switching', () =>
      setLoading(true),
    );

    return () => {
      KeyboardManager.destroy();
      EventService.unsubscribe(cbId);
    };
  }, [partId, sheetId, customSheetId]);

  return (
    <>
      <AppTitle
        title={sheetDefinition ? sheetDefinition.name : 'Signal Sheet'}
      />
      {error && <RecordingNotFound message={error} />}

      {isValidSheetDefinition &&
        !error &&
        (!loading && recording && recordingPart && sheetDefinition ? (
          <SignalSheet
            key={partId}
            recording={recording}
            recordingPart={recordingPart}
            sheetDefinition={sheetDefinition}
          />
        ) : (
          <SignalSheetLoading />
        ))}

      {!isValidSheetDefinition && (
        <RecordingNotFound message={`Invalid SheetId: ${sheetPath}`} />
      )}
    </>
  );
};

export default withTheme(
  withNavigationBars(React.memo(SheetWorkspace, () => true)),
);
