import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import XRange from 'highcharts/modules/xrange';
import _ from 'underscore';
import React from 'react';
import {
  useTheme,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  fade,
  Tooltip,
} from '@material-ui/core';
import Logger from '../../../utils/logger';
import { RecordingPartData } from '../../StudyOverview/OverviewParts';
import { OverviewGraphDefinition } from './overviewChartDefinitions';
import ScoringService from '../../../services/scoringService';
import EventService from '../../../services/eventService';
import { SignalEventDetailData } from '../../../interfaces/signal-event-detail-props';
import eventChartTools from '../SignalChart/eventChartTools';
import { MarkerGroups } from '../SignalChart/markerConstants';
import chartTools from '../SignalChart/chartTools';
import studyTools from '../../StudyOverview/studyTools';

XRange(Highcharts);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    yAxisLabels: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      right: '100%',
      marginRight: theme.spacing(0.5),
      textAlign: 'right',
      justifyContent: 'space-between',
    },
    label: {
      color: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.54,
      ),
      fontSize: 9,
      cursor: 'help',
    },
    tooltip: { maxWidth: 120, textAlign: 'center' },
  }),
);

interface OverviewApneaChartProps {
  graphDefinition: OverviewGraphDefinition;
  partData: RecordingPartData;
  chartWidth?: number;
}

const OverviewApneaChart = (props: OverviewApneaChartProps): JSX.Element => {
  Logger.log(
    '[OverviewApneaChart] Created new instance for:',
    props.graphDefinition.type,
  );
  const classes = useStyles();
  const theme = useTheme();

  const redraw = _.throttle(() => {
    const chart = studyTools.retrieveChart(
      props.partData.partId,
      props.graphDefinition.type,
    );
    if (chart && chartTools.isSerieAvailable(chart)) {
      Logger.debug(
        '[OverviewApneaChart][redraw][%s] Redrawing',
        props.graphDefinition.type,
      );
      const time = Date.now();

      Logger.debug(
        '[OverviewApneaChart][redraw][%s] chartWidth',
        props.graphDefinition.type,
        chart.chartWidth,
      );
      if (chart.chartWidth > 1) {
        chart.redraw(false);
      } else {
        Logger.debug(
          '[OverviewApneaChart][redraw][%s] Skipping!',
          props.graphDefinition.type,
        );
      }

      Logger.debug(
        '[OverviewApneaChart][redraw][%s]  Redrawing (%d) points took (ms):',
        props.graphDefinition.type,
        chartTools.getDataFromChart(chart).length,
        Date.now() - time,
      );
    }
  }, 250);

  const processEvents = _.throttle(() => {
    Logger.log('[OverviewApneaChart] processSamples');
    const chart = studyTools.retrieveChart(
      props.partData.partId,
      props.graphDefinition.type,
    );

    if (chart) {
      const partStartTime = new Date(props.partData.startTime).valueOf();
      const partEndTime = new Date(props.partData.endTime).valueOf();

      const data: Highcharts.XrangePointOptionsObject[] = [];
      const events = ScoringService.retrieveMarkersByType(
        MarkerGroups.getEventTypes(['Apnea', 'Hypopnea', 'Artifact']),
      );
      Logger.log('[OverviewApneaChart] events', events);

      const artifactPoints: Highcharts.PointOptionsType[] = [];

      // Adding placeholders to prevent Highcharts issue
      [-1, 0, 1].forEach((y) =>
        data.push({
          x: partStartTime,
          x2: partStartTime,
          y,
          color: 'transparent',
        }),
      );

      events.forEach((event) => {
        if (
          eventChartTools.isMarkerWithinTimePeriod(
            event,
            partStartTime,
            partEndTime,
          )
        ) {
          if (event.type === 'signal-artifact') {
            if (event.scoredFrom === 'Resp.Flow-Cannula.Nasal') {
              artifactPoints.push({
                x: event.start,
                x2: event.end,
                y: 0,
                color: fade(
                  eventChartTools.getColorByEventType(event.type),
                  0.3,
                ),
              });
            }
          } else {
            data.push({
              x: event.start,
              x2: event.end,
              y: (() => {
                if (event.type === 'apnea-obstructive') return 1;
                if (event.type === 'hypopnea') return -1;
                return 0;
              })(),
              color: eventChartTools.getColorByEventType(event.type),
            });
          }
        }
      });
      Logger.log('[OverviewApneaChart] data', data);

      if (chartTools.isSerieAvailable(chart, { serieIndex: 1 })) {
        chart.series[1].setData(artifactPoints, false, false, true);
      }
      chartTools.setData(chart, data, { redraw: false });
      redraw();
    }
  }, 250);

  const handleEventChange = (marker: SignalEventDetailData) => {
    if (
      MarkerGroups.getEventTypes(['Apnea', 'Hypopnea']).includes(marker.type)
    ) {
      const partStartTime = new Date(props.partData.startTime).valueOf();
      const partEndTime = new Date(props.partData.endTime).valueOf();
      if (
        eventChartTools.isMarkerWithinTimePeriod(
          marker,
          partStartTime,
          partEndTime,
        )
      ) {
        processEvents();
      }
    }
  };

  React.useEffect(() => {
    const cbId = EventService.subscribe(
      'ScoringChanged',
      (marker: SignalEventDetailData) => {
        // Logger.debug('[OverviewApneaChart] ScoringChanged', marker);
        handleEventChange(marker);
      },
    );

    const chart = studyTools.retrieveChart(
      props.partData.partId,
      props.graphDefinition.type,
    );
    if (chart) {
      chart.setSize(props.chartWidth, undefined);
    }

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, [props.chartWidth]);

  const min = new Date(props.partData.startTime).valueOf();
  const max = new Date(props.partData.endTime).valueOf();

  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'xrange',
      width: 1,
      height: props.graphDefinition.height,
      marginLeft: 0,
      marginTop: 0,
      spacingTop: 0,
      spacingRight: 20,
      spacingBottom: 0,
      spacingLeft: 0,
      plotBorderWidth: 0,
      panning: {
        enabled: false,
      },
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
      animation: false,
      events: {
        load() {
          Logger.log('[OverviewChart] load');
          studyTools.registerChart(
            props.partData.partId,
            props.graphDefinition.type,
            this,
          );
          processEvents();
        },
      },
    },
    loading: {
      style: {
        background: theme.colors.chart.loading.background,
        color: theme.colors.chart.loading.color,
      },
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      visible: false,
      crosshair: false,
      gridLineWidth: 0,
      lineWidth: 0,
      showFirstLabel: true,
      showLastLabel: true,
      ordinal: false,
      min,
      max,
    },
    yAxis: [
      {
        startOnTick: true,
        endOnTick: true,
        tickPixelInterval: 100,
        alignTicks: false,
        lineWidth: 0,
        gridLineWidth: 0,
        showFirstLabel: true,
        showLastLabel: true,
        opposite: false,
        labels: {
          enabled: false,
        },
        categories: ['OA', 'H', 'CA+MA'],
      },
      {
        startOnTick: true,
        endOnTick: true,
        tickPixelInterval: 100,
        alignTicks: false,
        lineWidth: 0,
        gridLineWidth: 0,
        showFirstLabel: true,
        showLastLabel: true,
        opposite: false,
        labels: {
          enabled: false,
        },
        categories: ['Artifact'],
      },
    ],
    series: [
      {
        name: 'Apneas',
        type: 'xrange',
        borderColor: 'gray',
        pointWidth: 15,
        data: [],
        minPointLength: 1,
        dataLabels: {
          enabled: true,
        },
        turboThreshold: 0,
      },
      {
        name: 'Artifact',
        type: 'xrange',
        borderColor: 'gray',
        pointWidth: 49,
        groupPadding: 0.5,
        data: [],
        minPointLength: 1,
        dataLabels: {
          enabled: true,
        },
        opacity: 0.7,
        turboThreshold: 0,
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
  };

  return (
    <div
      data-cy={`OverviewGraph-${props.graphDefinition.type}`}
      className={classes.container}
    >
      <div className={classes.yAxisLabels}>
        <Tooltip
          title="Obstructive Apnea"
          classes={{ tooltip: classes.tooltip }}
          placement="right"
          arrow
        >
          <Typography variant="caption" className={classes.label}>
            OA
          </Typography>
        </Tooltip>
        <Tooltip
          title="Central and Mixed Apneas"
          classes={{ tooltip: classes.tooltip }}
          placement="right"
          arrow
        >
          <Typography variant="caption" className={classes.label}>
            CA+MA
          </Typography>
        </Tooltip>
        <Tooltip
          title="Hypopnea"
          classes={{ tooltip: classes.tooltip }}
          placement="right"
          arrow
        >
          <Typography variant="caption" className={classes.label}>
            H
          </Typography>
        </Tooltip>
      </div>

      <HighchartsReact
        highcharts={Highcharts}
        constructorType="stockChart"
        allowChartUpdate={false}
        options={chartOptions}
      />
    </div>
  );
};

export default OverviewApneaChart;
