import React from 'react';
import { makeStyles } from '@material-ui/core';
import KeyboardManager from '../SignalSheet/keyboardManager';
import KeyboardKey from './KeyboardKey';

interface KeyboardShortcutProps {
  keys: string[] | string;
}

const useStyles = makeStyles(() => ({
  shortcut: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const KeyboardShortcut = (props: KeyboardShortcutProps): JSX.Element => {
  const classes = useStyles();
  const keys = Array.isArray(props.keys) ? props.keys : [props.keys];

  return (
    <div className={classes.shortcut}>
      {keys.map((key) => {
        return (
          <>
            <KeyboardKey>{KeyboardManager.keyToText(key)}</KeyboardKey>
          </>
        );
      })}
    </div>
  );
};

export default KeyboardShortcut;
