import { fade, makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import moment from 'moment-timezone';
import { RecordingPartData } from './OverviewParts';
import chartRangeTools from '../SignalSheet/chartRangeTools';
import EventService from '../../services/eventService';
import Logger from '../../utils/logger';
import { OverviewPartMenuPos } from './OverviewPartDetailSecondaryMenu';
import { ZIndex } from '../../utils/theme';
import ScoringService from '../../services/scoringService';

const useStyles = makeStyles((theme) => ({
  highlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    background: fade(theme.colors.chart.axisLabel, 0.5),
    opacity: 0.5,
    minWidth: 2,
  },
  selectionHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    background: fade(theme.colors.chart.axisLabel, 0.2),
    opacity: 0.5,
  },
  tooltipContainer: {
    zIndex: ZIndex.AboveBaseline,
  },
  tooltipAbove: {
    zIndex: ZIndex.AboveBaseline + 1,
  },
  tooltip: {
    marginTop: theme.spacing(1),
    maxWidth: 60,
    textAlign: 'center',
  },
}));

interface OverviewPartHoverHighlightProps {
  part: RecordingPartData;
  hoverLeft?: number;
  containerRef?: React.RefObject<HTMLDivElement>;
  handleMenuOpen?: (pos: OverviewPartMenuPos) => void;
}

const OverviewPartHoverHighlight = (
  props: OverviewPartHoverHighlightProps,
): JSX.Element => {
  const classes = useStyles();

  const [highlightTimestamp, setHighlightTimestamp] = React.useState<number>();
  const [hoverLeft, setHoverLeft] = React.useState<number>();

  const [dragStart, setDragStart] = React.useState<number>();
  const [dragEnd, setDragEnd] = React.useState<number>();

  const [newSelectionArea, setNewSelectionArea] =
    React.useState<{ from: number; to: number }>();

  const pxToTimestamp = (px: number): number => {
    const containerWidth = props.containerRef?.current?.clientWidth || 0;

    const partStart = new Date(props.part.startTime).valueOf();
    const partEnd = new Date(props.part.endTime).valueOf();

    const milliseconds = partEnd - partStart;
    const millisecondPerPx = milliseconds / containerWidth;

    let timestamp = partStart + px * millisecondPerPx;

    // Logger.log('[NavigatorOverlay] px', px);
    // Logger.log('[NavigatorOverlay] timestamp', timestamp);

    if (timestamp < partStart) {
      timestamp = partStart;
    } else if (timestamp > partEnd) {
      timestamp = partEnd;
    }

    return timestamp;
  };

  const timestampToPx = (timestamp: number): number => {
    const containerWidth = props.containerRef?.current?.clientWidth || 0;

    const partStart = new Date(props.part.startTime).valueOf();
    const partEnd = new Date(props.part.endTime).valueOf();

    const milliseconds = partEnd - partStart;
    const millisecondPerPx = milliseconds / containerWidth;

    const timeDiff = timestamp - partStart;

    let px = timeDiff / millisecondPerPx;

    // Logger.log('[NavigatorOverlay] px', px);
    // Logger.log('[NavigatorOverlay] timestamp', timestamp);

    if (px < 0) {
      px = 0;
    } else if (px > containerWidth) {
      px = containerWidth;
    }

    return px;
  };

  const getTooltipText = (left: number): string => {
    const timestamp = pxToTimestamp(left);

    const title = `#${chartRangeTools.convertToEpoch(timestamp) + 1}\n${moment(
      timestamp,
    ).format('HH:mm:ss')}`;

    return title;
  };

  const handleClick = (event: MouseEvent) => {
    Logger.log('[OverviewPartHoverHighlight] handleClick', event);
    if ((hoverLeft || newSelectionArea) && props.handleMenuOpen) {
      event.preventDefault();

      const timestampFrom = newSelectionArea
        ? pxToTimestamp(newSelectionArea?.from)
        : pxToTimestamp(hoverLeft || 0);
      const timestampTo = newSelectionArea
        ? pxToTimestamp(newSelectionArea?.to)
        : null;

      const existingExcludedZonesIds =
        ScoringService.retrieveMarkerTypesWithinTimePeriod(
          ['signal-invalid'],
          timestampFrom,
          timestampTo !== null ? timestampTo : timestampFrom,
        ).map((marker) => marker.id);

      Logger.log(
        '[OverviewPartHoverHighlight] handleClick hoverLeft',
        hoverLeft,
      );
      props.handleMenuOpen({
        mouseX: event.clientX - 2,
        mouseY: event.clientY + 10,
        partId: props.part.partId,
        origin: 'Hover',
        timestampFrom,
        timestampTo,
        existingAreas: existingExcludedZonesIds,
      });
    }
  };

  const onMouseDown = () => {
    setDragStart(hoverLeft);
  };

  const getNewDragEnd = (oldDragEnd?: number): number | undefined => {
    const containerWidth = props.containerRef?.current?.clientWidth || 0;

    if (hoverLeft === undefined && dragStart) {
      if (oldDragEnd && oldDragEnd > dragStart) {
        return containerWidth;
      }
      return 0.1;
    }
    return hoverLeft;
  };

  const onMouseMove = () => {
    const newDragEnd = getNewDragEnd(dragEnd);
    setDragEnd(() => newDragEnd);

    if (dragStart && newDragEnd) {
      if (Math.abs(newDragEnd - dragStart) > 2) {
        if (newDragEnd < dragStart) {
          setNewSelectionArea({ from: newDragEnd, to: dragStart });
        } else {
          setNewSelectionArea({ from: dragStart, to: newDragEnd });
        }
      } else {
        Logger.log('[OverviewPartHoverHighlight] Clearing selection area');
        setNewSelectionArea(() => undefined);
      }
    }
  };

  const onMouseUp = (e: MouseEvent) => {
    onMouseMove();

    setDragStart(() => undefined);
    setDragEnd(() => undefined);

    Logger.log(`Movement: ${dragStart} -> ${hoverLeft}`);
    if (hoverLeft === undefined) {
      handleClick(e);
    }
  };

  React.useEffect(() => {
    const cbId = EventService.subscribe(
      'Overview.HighlightTimestamp',
      (timestamp: number) => {
        // Logger.log('[NavigatorOverlay] timestamp', timestamp);
        if (
          timestamp === null ||
          (new Date(props.part.startTime).valueOf() <= timestamp &&
            new Date(props.part.endTime).valueOf() >= timestamp)
        ) {
          setHighlightTimestamp(() => timestamp);
        }
      },
    );

    if (highlightTimestamp) {
      setHoverLeft(timestampToPx(highlightTimestamp));
    } else {
      setHoverLeft(props.hoverLeft);
    }

    if (!props.part.readOnlyScoring) {
      window.addEventListener('mousedown', onMouseDown);
      window.addEventListener('mouseup', onMouseUp);
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('click', handleClick);
      window.addEventListener('contextmenu', handleClick);
    }

    return () => {
      EventService.unsubscribe(cbId);
      window.removeEventListener('click', handleClick);
      window.removeEventListener('contextmenu', handleClick);
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    };
  });

  const timestampTooltip = (timestamp: number, isHigher?: boolean) => (
    <Tooltip
      title={getTooltipText(timestamp)}
      TransitionProps={{ appear: false }}
      PopperProps={{ hidden: false, open: true }}
      classes={{
        tooltip: classes.tooltip,
        popper: clsx(classes.tooltipContainer, {
          [classes.tooltipAbove]: isHigher,
        }),
      }}
      arrow
      open
    >
      <div
        className={classes.highlight}
        style={{
          left: timestamp - 1,
          width: 2,
        }}
      />
    </Tooltip>
  );

  return (
    <>
      {!!newSelectionArea &&
        ((!!dragStart && newSelectionArea.to > dragStart) ||
          (dragStart === undefined &&
            hoverLeft &&
            Math.abs(newSelectionArea.from - hoverLeft) > 2) ||
          !hoverLeft) &&
        timestampTooltip(newSelectionArea.from)}

      {!!newSelectionArea &&
        ((hoverLeft &&
          ((!!dragStart && dragStart > hoverLeft) ||
            (dragStart === undefined &&
              Math.abs(newSelectionArea.to - hoverLeft) > 2))) ||
          !hoverLeft) &&
        timestampTooltip(newSelectionArea.to, true)}

      {hoverLeft !== undefined &&
        hoverLeft >= 0 &&
        timestampTooltip(hoverLeft, true)}
      {newSelectionArea && (
        <div
          className={classes.selectionHighlight}
          style={{
            left: newSelectionArea.from,
            width: newSelectionArea.to - newSelectionArea.from,
          }}
        />
      )}
    </>
  );
};

export default OverviewPartHoverHighlight;
