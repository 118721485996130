import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UndoIcon from '@material-ui/icons/Undo';
import React from 'react';
import { Link } from 'react-router-dom';
import { WithPageStatus } from '../../interfaces/page-status';
import SheetDefinitions from '../SignalSheet/sheetDefinitions';
import FeatureToggle from '../Shared/FeatureToggle';
import NavigationBarButton from './RecordingNavigationBarButton/RecordingNavigationBarButton';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.getContrastText(theme.palette.secondary.main),
    },
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 7,
    margin: '2px 5px 0 5px',
    borderRadius: '4px 4px 0 0',
    border: `1px solid ${theme.colors.navBar.border}`,
    borderBottom: 'none',
    boxShadow: 'inset 0px -2px 4px -4px black',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.getContrastText(theme.palette.secondary.main),
    },
  },
  selected: {
    margin: '4px 5px 0 5px',
    borderRadius: '4px 4px 0 0',
    border: `1px solid ${theme.colors.navBar.border}`,
    borderBottom: 'none',
    paddingBottom: 9,
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  complete: {
    fontSize: 12,
    maxWidth: 100,
    textTransform: 'initial',
    textAlign: 'center',
    lineHeight: '12px',
    margin: theme.spacing(0, 1),
  },
}));

export default function RecordingNavigationBar(
  props: WithPageStatus<unknown>,
): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <FeatureToggle enabledFor="development">
        <Button
          component={Link}
          to="/recordings"
          className={classes.button}
          data-cy="NavBar-Recordings"
        >
          <UndoIcon fontSize="small" style={{ marginRight: 5 }} />{' '}
          <span>Recordings</span>
        </Button>
      </FeatureToggle>
      <NavigationBarButton
        pageStatus={props.pageStatus}
        direction="recording"
        pageId="StudyOverview"
      >
        Study Overview
      </NavigationBarButton>

      {props.pageStatus.status.signalSheetTabs?.map((tab) => {
        const sheetDefinition = SheetDefinitions.get(tab.sheetId, tab.customId);

        return (
          sheetDefinition && (
            <NavigationBarButton
              key={tab.sheetId}
              pageStatus={props.pageStatus}
              direction={`recording/${sheetDefinition.path}`}
              pageId={tab.sheetId}
              signalSheetTab={tab}
            >
              {sheetDefinition.name}
            </NavigationBarButton>
          )
        );
      })}

      <NavigationBarButton
        pageStatus={props.pageStatus}
        direction="report"
        pageId="Report"
        onlyForSubmittedScoring
      >
        Report
      </NavigationBarButton>
    </>
  );
}
