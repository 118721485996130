/* eslint-disable no-param-reassign */
import Highcharts from 'highcharts/highstock';
import React from 'react';
import {
  makeStyles,
  createStyles,
  Paper,
  Typography,
  MenuItem,
  fade,
  Popper,
  ClickAwayListener,
  ListItemIcon,
  Divider,
  Grow,
} from '@material-ui/core';
import clsx from 'clsx';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import { useHistory, useParams } from 'react-router-dom';
import { ChartOptions } from '../SignalChart/getChartOptions';
import { SignalDefinition } from '../../../interfaces/sheet-definition';
import Logger from '../../../utils/logger';
import sheetTools from '../../SignalSheet/sheetTools';
import ScoringRenderingService from '../../../services/scoringRenderingService';
import EventService from '../../../services/eventService';
import { ZIndex } from '../../../utils/theme';
import chartTools from '../SignalChart/chartTools';
import PassiveEvents from '../../../utils/PassiveEvents';
import Analytics from '../../../services/analytics';
import KeyboardManager from '../../SignalSheet/keyboardManager';
import CreateCustomSignalSheetModal from './CreateCustomSignalSheetModal';
import { UrlParamsRecording } from '../../../interfaces/page-status';
import studyTools from '../../StudyOverview/studyTools';
import MissingSignalIndicator from './MissingSignalIndicator';

PassiveEvents(Highcharts);

const useStyles = makeStyles((theme) =>
  createStyles({
    background: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: theme.spacing(-1),
      width: ChartOptions.signalNameWidth + theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      borderRight: `1px solid ${theme.colors.chart.grid}`,
      display: 'flex',
      alignItems: 'center',
      zIndex: ZIndex.Baseline,
    },
    nameContainer: {
      backgroundColor: 'transparent',
      zIndex: ZIndex.SignalName,
    },
    highlightSignal: {
      cursor: 'pointer',
      backgroundColor: fade('#000', 0.05),
    },
    toolbar: {
      transition: 'all 0.3s ease-out',
      opacity: 0,
      height: 0,
      width: 0,
      overflow: 'hidden',
      borderRadius: '0 50px 50px 0',
      backgroundColor: theme.palette.background.paper,

      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      alignItems: 'center',
      color: theme.colors.chart.axisLabel,
    },
    toolbarVisible: {
      marginLeft: 0,
      opacity: 1,
      height: 80,
      width: 30,
    },
    iconTop: {
      gridColumnStart: 1,
      gridColumnEnd: 2,
      gridRowStart: 1,
      gridRowEnd: 2,
      cursor: 'pointer',
    },
    iconBottom: {
      gridColumnStart: 1,
      gridColumnEnd: 2,
      gridRowStart: 2,
      gridRowEnd: 3,
      cursor: 'pointer',
    },
    iconRight: {
      gridColumnStart: 2,
      gridColumnEnd: 3,
      gridRowStart: 1,
      gridRowEnd: 3,
      justifySelf: 'left',
      marginLeft: -10,
      cursor: 'pointer',
    },
    iconDisabled: {
      cursor: 'disabled',
      pointerEvents: 'none',
      opacity: 0.2,
    },
    signalName: {
      position: 'absolute',
      width: ChartOptions.signalNameWidth + theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.chart.axisLabel,
      fontSize: 10,
      transform: 'rotate(-90deg)',
      textAlign: 'center',
      justifyContent: 'center',
    },
    signalNameSmall: {
      transform: 'rotate(-45deg)',
    },
    hide: {
      display: 'none',
    },
    menuContainer: {
      zIndex: ZIndex.Popup,
      willChange: 'auto !important',
    },
    menu: {
      backgroundColor: fade(theme.colors.modal.backgroundColor, 0.5),
      color: theme.colors.modal.color,
      backdropFilter: 'blur(2px)',
    },
    menuIcon: {
      color: theme.colors.modal.color,
      marginRight: theme.spacing(1.5),
      minWidth: 0,
    },
    openInNewIcon: {
      fontSize: 17,
      marginLeft: 6,
      position: 'absolute',
      marginTop: 1,
    },
  }),
);

interface SignalMenuProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  signalInfo: SignalDefinition;
}

const SignalMenu = (props: SignalMenuProps): JSX.Element => {
  Logger.debug('[SignalMenu] with props:', props);

  const classes = useStyles();
  const history = useHistory();
  const { sheetPath } = useParams<UrlParamsRecording>();

  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [isSignalReady, setIsSignalReady] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);
  const [chartSize, setChartSize] = React.useState(
    props.signalInfo.chartSize || 'normal',
  );
  const [isSmartScaling, setIsSmartScaling] = React.useState(true);
  const [enableClickAway, setEnableClickAway] = React.useState(false);

  const [chartHeight, setChartHeight] = React.useState(
    props.signalInfo?.screenPosition?.height ?? -1,
  );
  const [
    createCustomSignalSheetModalOpen,
    setCreateCustomSignalSheetModalOpen,
  ] = React.useState(false);

  const isMissing =
    studyTools.getAvailableSignals().length > 0 &&
    !studyTools
      .getAvailableSignals()
      .some((signal) => props.signalInfo.type === signal.type);

  const zoomIn = () => {
    Logger.log('[SignalMenu] zoomIn', props.signalInfo.name);
    Analytics.track.event('SIGNAL_EXPAND', {
      origin: 'Signal Menu',
      signalType: props.signalInfo.type,
    });

    switch (chartSize) {
      case 'normal':
        props.signalInfo.chartSize = 'larger';
        setChartSize('larger');
        break;
      case 'smaller':
        props.signalInfo.chartSize = 'normal';
        setChartSize('normal');
        break;
      default: // Do nothing
    }

    sheetTools.autoFitAll();
  };

  const zoomOut = () => {
    Logger.log('[SignalMenu] zoomOut', props.signalInfo.name);
    Analytics.track.event('SIGNAL_COLLAPSE', {
      origin: 'Signal Menu',
      signalType: props.signalInfo.type,
    });

    switch (chartSize) {
      case 'full':
        props.signalInfo.chartSize = 'larger';
        setChartSize('larger');
        break;
      case 'larger':
        props.signalInfo.chartSize = 'normal';
        setChartSize('normal');
        break;
      case 'normal':
        props.signalInfo.chartSize = 'smaller';
        setChartSize('smaller');
        break;
      default: // Do nothing
    }

    sheetTools.autoFitAll();
  };

  const zoomAll = () => {
    Logger.log('[SignalMenu] zoomAll', props.signalInfo.name);
    Analytics.track.event('SIGNAL_FULLSCREEN', {
      origin: 'Signal Menu',
      signalType: props.signalInfo.type,
    });

    props.signalInfo.chartSize = 'full';
    setChartSize('full');
    sheetTools.autoFitAll();
  };

  const scaleToFit = () => {
    Analytics.track.event('SIGNAL_SCALE_TO_FIT', {
      origin: 'Signal Menu',
      signalType: props.signalInfo.type,
    });

    chartTools.scaleToFit(props.signalInfo.type, { redraw: true });
    setIsSmartScaling(false);
  };

  const autoScale = () => {
    Analytics.track.event('SIGNAL_AUTO_SCALE', {
      origin: 'Signal Menu',
      signalType: props.signalInfo.type,
    });

    chartTools.scaleSmart(props.signalInfo.type);
    setIsSmartScaling(true);
  };

  React.useEffect(() => {
    Logger.debug('[SignalMenu] useEffect with props:', props);
    setIsSignalReady(props.signalInfo.screenPosition !== undefined);

    const cbId = [
      EventService.subscribe('WindowResize', () => {
        const newHeight = props.signalInfo?.screenPosition?.height || -1;
        setChartHeight(newHeight);
      }),
      EventService.subscribe('KeyboardShortcut.ScaleToFitAll', () => {
        scaleToFit();
      }),
      EventService.subscribe('KeyboardShortcut.ScaleToFit', () => {
        if (isHover || props.isOpen) {
          if (isSmartScaling) {
            scaleToFit();
          } else {
            autoScale();
          }
        }
      }),
    ];

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, [props.signalInfo.screenPosition, isHover, isSmartScaling, props.isOpen]);

  return (
    <>
      <div className={classes.background} />
      <div
        className={clsx(classes.background, classes.nameContainer, {
          [classes.highlightSignal]: isHover || props.isOpen,
        })}
        ref={anchorRef}
        onClick={() => {
          if (!createCustomSignalSheetModalOpen) {
            props.setOpen(true);
          }
        }}
        onMouseEnter={() => {
          if (
            !ScoringRenderingService.isEventBeingDragged() &&
            !ScoringRenderingService.isEventBeingResized()
          ) {
            setIsHover(true);
          }
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
      >
        {isSignalReady && (
          <>
            <Typography
              className={clsx(classes.signalName, {
                [classes.signalNameSmall]: chartHeight > 0 && chartHeight < 40,
                [classes.hide]: chartHeight === -1,
              })}
            >
              {isMissing && (
                <MissingSignalIndicator
                  narrow={chartHeight > 0 && chartHeight < 60}
                />
              )}
              {chartHeight > 0 && chartHeight < 40
                ? props.signalInfo.shortName
                : props.signalInfo.name}
            </Typography>
          </>
        )}
      </div>

      <ClickAwayListener
        mouseEvent="onMouseUp"
        touchEvent="onTouchStart"
        onClickAway={() => {
          if (enableClickAway) {
            Logger.log('[SignalMenu] ClickAwayListener');
            setEnableClickAway(false);
            props.setOpen(false);
          }
        }}
      >
        <Popper
          anchorEl={anchorRef.current}
          open={props.isOpen}
          placement="right"
          className={classes.menuContainer}
          modifiers={{
            flip: {
              enabled: true,
            },
            offset: {
              enabled: true,
              offset: '0,10',
            },
            preventOverflow: {
              enabled: true,
              boundariesElement:
                ScoringRenderingService.getScoringCanvasContainer()?.current,
            },
          }}
          transition
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              timeout={{
                enter: 250,
                exit: 100,
              }}
              style={{
                transformOrigin: 'center left',
              }}
              onEntered={() => {
                Analytics.track.event('SIGNAL_MENU_OPEN', {
                  signalType: props.signalInfo.type,
                });

                setEnableClickAway(true);
              }}
            >
              <Paper
                data-cy="SignalMenu"
                className={classes.menu}
                elevation={3}
              >
                {/* <MenuItem onClick={autoScale} selected={isSmartScaling}>
                  <ListItemIcon className={clsx(classes.menuIcon)}>
                    <PhotoFilterIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="body2">Smart Scaling</Typography>
                </MenuItem> */}
                <MenuItem data-cy="ScaleToFitLi" onClick={scaleToFit}>
                  <ListItemIcon className={classes.menuIcon}>
                    <AspectRatioIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="body2">
                    Scale to fit{' '}
                    <Typography variant="caption">
                      (
                      {KeyboardManager.shortcutToText(
                        'KeyboardShortcut.ScaleToFit',
                      )}
                      )
                    </Typography>
                  </Typography>
                </MenuItem>
                <Divider variant="middle" />
                <MenuItem
                  data-cy="EnlargeLi"
                  onClick={zoomIn}
                  disabled={['larger', 'full'].includes(chartSize)}
                >
                  <ListItemIcon className={classes.menuIcon}>
                    <ZoomInIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="body2">Enlarge</Typography>
                </MenuItem>
                <MenuItem
                  data-cy="ExpandLi"
                  onClick={zoomAll}
                  disabled={['full'].includes(chartSize)}
                >
                  <ListItemIcon className={classes.menuIcon}>
                    <ZoomOutMapIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="body2">Expand</Typography>
                </MenuItem>
                <MenuItem
                  data-cy="CompressLi"
                  onClick={zoomOut}
                  disabled={['smaller'].includes(chartSize)}
                >
                  <ListItemIcon className={classes.menuIcon}>
                    <ZoomOutIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="body2">Compress</Typography>
                </MenuItem>
                <Divider variant="middle" />
                {sheetPath !== 'custom-sheet' && (
                  <MenuItem
                    data-cy="MoveSignalsLi"
                    onClick={() => {
                      Analytics.track.event('SIGNAL_MENU_REORDER', {
                        signalType: props.signalInfo.type,
                      });

                      history.push('/settings/signal-ordering');
                    }}
                  >
                    <ListItemIcon className={classes.menuIcon}>
                      <DragIndicatorIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body2">
                      Move
                      <OpenInNewIcon
                        fontSize="small"
                        className={classes.openInNewIcon}
                      />
                    </Typography>
                  </MenuItem>
                )}
                {studyTools.getAvailableSignals().length > 0 && (
                  <MenuItem
                    data-cy="AllSignalsLi"
                    onClick={() => {
                      Analytics.track.event('SIGNAL_MENU_ALL_CHANNELS', {
                        signalType: props.signalInfo.type,
                      });

                      setCreateCustomSignalSheetModalOpen(true);
                      props.setDisabled(true);
                      props.setOpen(false);
                    }}
                  >
                    <ListItemIcon className={classes.menuIcon}>
                      <SettingsInputComponentIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body2">All Signals</Typography>
                  </MenuItem>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
      <CreateCustomSignalSheetModal
        open={createCustomSignalSheetModalOpen}
        handleClose={() => {
          setCreateCustomSignalSheetModalOpen(false);
          props.setDisabled(false);
        }}
      />
    </>
  );
};

export default SignalMenu;
