import Highcharts, { SVGElement } from 'highcharts/highstock';
import moment from 'moment-timezone';
import * as _ from 'underscore';
import Logger from '../../../utils/logger';
import chartRangeTools from '../../SignalSheet/chartRangeTools';
import { ChartRange } from '../../SignalSheet/interfaces/chart-range';
import sheetTools from '../../SignalSheet/sheetTools';
import eventChartTools from './eventChartTools';
import {
  ChartTools,
  EpochRange,
  ChartCustomDataKey,
  ChartCustomDataValue,
  HighchartsWithCustom,
  HighchartsAxisWithPlotbands,
} from './interfaces/chart-tools';
import queryManager from '../../../services/queryManager';
import {
  BufferedEpochInfo,
  BufferedStatusData,
  BufferedStatusUpdateFunction,
} from '../../NavigatorTimeline/navigator-timeline';
import EventService from '../../../services/eventService';
import PassiveEvents from '../../../utils/PassiveEvents';
import { SheetToolbarMode } from '../../../interfaces/sheet-toolbar-props';
import SignalRenderingService from '../../../services/signalRenderingService';
import chartEvents from '../../SignalSheet/chartEvents';
import ScoringRenderingService from '../../../services/scoringRenderingService';
import { SignalData } from '../../../queries/signal';
import {
  EpochContainer,
  EpochNumber,
  Sample,
  SignalContainer,
  SignalDatabase,
} from '../../SignalSheet/interfaces/data';
import {
  SignalDefinition,
  SignalType,
} from '../../../interfaces/sheet-definition';
import { ChartName } from '../../../interfaces/chart-props';
import studyTools from '../../StudyOverview/studyTools';

PassiveEvents(Highcharts);

const convertToSeconds = (milliseconds: number) => milliseconds / 1000;

const chartRegistry = new Map<ChartName, Highcharts.Chart>();
const signalInfoRegistry = new Map<SignalType, SignalDefinition>();

const signalDatabase: SignalDatabase = new Map<SignalType, SignalContainer>();

let bufferedStatusUpdate: BufferedStatusUpdateFunction = () => null;

const chartTools: ChartTools = {
  initializeChartTools: () => {
    Logger.debug('[initializeChartTools]');

    chartRegistry.forEach((chart, chartName) => {
      if (chartName !== 'EpochChart' && chartName !== 'NavigatorChart') {
        chartRegistry.delete(chartName);
      }
    });
    signalInfoRegistry.clear();
    signalDatabase.clear();

    setTimeout(() => bufferedStatusUpdate(new Map()), 0);
  },
  getCharts: (options) => {
    const {
      excludeNavigator,
      excludeEpochChart,
      withAvailableSeries,
      onlyVisible,
    } = options;

    const charts: Highcharts.Chart[] = [];
    chartRegistry.forEach((chart, chartName) => {
      let add = true;

      if (chart) {
        if (chart.series) {
          if (excludeEpochChart && chartName === 'EpochChart') {
            add = false;
          } else if (excludeNavigator && chartName === 'NavigatorChart') {
            add = false;
          }
          if (
            onlyVisible &&
            sheetTools.otherChartsInFullSize(chartName as SignalType)
          ) {
            add = false;
          }
        } else if (withAvailableSeries) {
          add = false;
        }

        if (add) charts.push(chart);
      }
    });
    // Logger.debug('[getCharts] charts:', charts);
    return charts;
  },
  getEpochChart: () => {
    const epochChart = chartTools.getChartFromRegistry('EpochChart');

    if (epochChart) {
      return epochChart;
    }
    // Logger.error('[getEpochChart] Epoch chart not found!');
    return undefined;
  },
  getNavigatorChart: () => {
    const navigatorChart = chartTools.getChartFromRegistry('NavigatorChart');

    if (navigatorChart) {
      return navigatorChart;
    }
    Logger.error('[getNavigatorChart] Navigator chart not found!');
    Logger.debug('[getNavigatorChart] chartRegistry', chartRegistry);
    return undefined;
  },
  getNavigatorChartHeight: (toolbarMode: SheetToolbarMode) =>
    toolbarMode === 'Full' ? 38 : 15,
  getNavigatorHeight: (toolbarMode: SheetToolbarMode) =>
    chartTools.getNavigatorChartHeight(toolbarMode) + 2,
  dataHasGaps: (signalInfo: SignalDefinition, epochRange: EpochRange) => {
    let hasGaps = false;

    const signalData = signalDatabase.get(signalInfo.type);
    if (signalData) {
      for (
        let epochNumber = epochRange.first;
        epochNumber < epochRange.last && !hasGaps;
        epochNumber += 1
      ) {
        if (!signalData.get(epochNumber)?.isReceived) {
          Logger.debug(
            '[dataHasGaps][%s] Found gap with no data on epoch:',
            signalInfo.name,
            epochNumber,
          );
          hasGaps = true;
        }
      }
    } else {
      Logger.debug('[dataHasGaps][%s] No data found.', signalInfo.name);
      hasGaps = true;
    }

    return hasGaps;
  },
  getDataFromChart: (chart: Highcharts.Chart) => {
    const serieOptions = chartTools.getSeriesOptions(chart);
    return (serieOptions?.data || []) as number[][];
  },
  storeData: (
    signalInfo: SignalDefinition,
    newData: Sample[],
    requestedRange: ChartRange,
  ) => {
    Logger.log(
      '[storeData] Storing data (%d lines) for:',
      newData.length,
      signalInfo.name,
    );

    const signalContainer = signalDatabase.get(signalInfo.type);

    if (signalContainer) {
      newData.forEach((sample: Sample) => {
        const epochNumber = chartRangeTools.convertToEpoch(sample[0]);
        const epochContainer = signalContainer.get(epochNumber);

        if (epochContainer && !epochContainer.isReceived) {
          epochContainer.samples.push(sample);
        }
      });

      for (
        let epochNumber = requestedRange.fromEpoch;
        epochNumber < requestedRange.toEpoch;
        epochNumber += 1
      ) {
        const epochContainer = signalContainer.get(epochNumber);

        if (epochContainer) {
          epochContainer.isReceived = true;
        }
      }
    }
  },
  getData: (signalInfo: SignalDefinition, epochRange: EpochRange) => {
    Logger.log(
      '[getData][%s] Starting for epochRange:',
      signalInfo.name,
      epochRange,
    );
    const data: Sample[] = [];

    const signalContainer = signalDatabase.get(signalInfo.type);
    if (signalContainer) {
      const time = Date.now();

      const previousEpoch = signalContainer.get(epochRange.first - 1);
      if (previousEpoch && previousEpoch.samples.length > 0) {
        const lastSampleFromPreviousEpoch =
          previousEpoch.samples[previousEpoch.samples.length - 1];
        data.push(lastSampleFromPreviousEpoch);
      }

      for (
        let epochNumber = epochRange.first;
        epochNumber < epochRange.last;
        epochNumber += 1
      ) {
        const epochContainer = signalContainer.get(epochNumber);
        if (epochContainer) {
          epochContainer.samples.map((sample) => data.push(sample));
        }
      }

      const nextEpoch = signalContainer.get(epochRange.last);
      if (nextEpoch && nextEpoch.samples.length > 0) {
        const firstSampleFromNextEpoch = nextEpoch.samples[0];
        data.push(firstSampleFromNextEpoch);
      }

      Logger.log(
        '[getData][%s] Extracted %d lines. Took (ms): ',
        signalInfo.name,
        data.length,
        Date.now() - time,
      );
    }

    return data;
  },
  addDataToChart: (
    chart: Highcharts.Chart,
    signalInfo: SignalDefinition,
    epochRange: EpochRange,
  ) => {
    let data: Sample[] = [];

    if (chartTools.isSerieAvailable(chart)) {
      Logger.log('[addDataToChart][%s] Starting', signalInfo.name);

      if (signalDatabase.get(signalInfo.type)) {
        data = chartTools.getData(signalInfo, epochRange);
        if (
          data.length > 4000 &&
          data.length > sheetTools.getBresenhamWidth() * 5
        ) {
          const epochRangeDiff = epochRange.last - epochRange.first;
          const currentExtremesInEpoch =
            chartRangeTools.getCurrentExtremesInEpoch();
          const extremesEpochDiff =
            currentExtremesInEpoch.last - currentExtremesInEpoch.first;
          const extremesInData = epochRangeDiff / extremesEpochDiff;
          Logger.log(
            '[addDataToChart][%s] applyBresenham with extremesInData',
            signalInfo.name,
            extremesInData,
          );
          data = chartTools.applyBresenham(
            data,
            sheetTools.getBresenhamWidth() * extremesInData,
          );
        }

        const time = Date.now();
        chartTools.setData(chart, data, { redraw: false });

        chartTools.setCustomData(chart, 'lastAddedSamples', data.length);
        chartTools.setCustomData(chart, 'lastDataRangeAdded', {
          ...epochRange,
        });
        chartTools.setCustomData(
          chart,
          'dataForZoomRange',
          sheetTools.getZoomRange(),
        );

        Logger.log(
          '[addDataToChart][%s] Added %d lines. Took (ms): ',
          signalInfo.name,
          data.length,
          Date.now() - time,
        );
        if (data.length > 0) {
          Logger.log(
            '[addDataToChart][%s] Samples dates range: %s - %s',
            signalInfo.name,
            moment(data[0][0]).toISOString(),
            moment(data[data.length - 1][0]).toISOString(),
          );
          Logger.log(
            '[addDataToChart][%s] Samples epochs range: %s - %s',
            signalInfo.name,
            chartRangeTools.convertToEpoch(data[0][0]),
            chartRangeTools.convertToEpoch(data[data.length - 1][0]),
          );
        }
      }
    }

    return data;
  },
  setCustomData: (
    chart: Highcharts.Chart,
    key: ChartCustomDataKey,
    value: ChartCustomDataValue,
  ) => {
    const customData = (chart as HighchartsWithCustom).custom || [];
    customData[key] = value;
    // eslint-disable-next-line no-param-reassign
    (chart as HighchartsWithCustom).custom = customData;
  },
  getCustomData: (chart: Highcharts.Chart, key: ChartCustomDataKey) => {
    const customData = (chart as HighchartsWithCustom).custom || [];
    return customData[key];
  },
  setChartName: (chart: Highcharts.Chart, chartName: ChartName) =>
    chartTools.setCustomData(chart, 'chartName', chartName),
  getChartName: (chart: Highcharts.Chart) =>
    chartTools.getCustomData(chart, 'chartName') as ChartName | undefined,
  setSignalType: (chart: Highcharts.Chart, signalType: SignalType) =>
    chartTools.setCustomData(chart, 'signalType', signalType),
  getSignalType: (chart: Highcharts.Chart) =>
    chartTools.getCustomData(chart, 'signalType') as SignalType | undefined,
  missingDataForCurrentExtremes: (chart: Highcharts.Chart) => {
    const custom = chartTools.getCustomData(
      chart,
      'lastDataRangeAdded',
    ) as EpochRange;
    const currentExtremes = chartRangeTools.getCurrentExtremesInEpoch();

    const hasData =
      !!custom &&
      custom.first <= currentExtremes.first &&
      custom.last >= currentExtremes.last;

    return !hasData;
  },
  dataStoredForDifferentZoomRange: (chart: Highcharts.Chart) => {
    const dataForZoomRange = chartTools.getCustomData(
      chart,
      'dataForZoomRange',
    ) as number | undefined;

    const dataStoredForDifferentZoomRange =
      dataForZoomRange !== sheetTools.getZoomRange();

    if (dataStoredForDifferentZoomRange) {
      Logger.warn(
        '[dataStoredForDifferentZoomRange] Found data stored for a different zoom range!',
      );
    }

    return dataStoredForDifferentZoomRange;
  },
  fadePlotband: (
    element: SVGElement,
    options: Highcharts.SVGAttributes,
    callback: () => void,
  ) => {
    const newOptions: Highcharts.SVGAttributes = Highcharts.extend(
      {
        duration: 500,
        prop: 'fill',
        to: 0,
      },
      options,
    );

    const color = new Highcharts.Color(newOptions.color);
    let opacity = parseInt(color.get('a').toString(), 10);

    const fps = 16;
    const frames = (newOptions.duration / 1000) * fps; // 16 steps per second
    const step = (newOptions.to - opacity) / frames;

    let currentFrame = 1;
    const animLoop = setInterval(() => {
      color.setOpacity((opacity += step));
      element.attr(newOptions.prop, color.get() as string);

      if (currentFrame >= frames) {
        clearInterval(animLoop);
        callback();
      } else {
        currentFrame += 1;
      }
    }, newOptions.duration / fps);
  },
  addPlotBandsToChart: (
    chart: Highcharts.Chart,
    plotBands: Highcharts.XAxisPlotBandsOptions[],
  ) => {
    return new Promise((resolve) => {
      const signalType = chartTools.getSignalType(chart);
      Logger.log('[addPlotBandsToChart][%s] Initializing', signalType);
      const time = Date.now();

      if (chartTools.isSerieAvailable(chart)) {
        plotBands.forEach((plotBand) => chart.xAxis[0].addPlotBand(plotBand));
      }

      Logger.log(
        '[addPlotBandsToChart][%s] Added %d PlotBands. Took (ms):',
        signalType,
        plotBands.length,
        Date.now() - time,
      );

      resolve(plotBands.length > 0);
    });
  },
  runBackgroundProcess: (fn: () => void) => {
    setTimeout(fn);
  },
  requestDataForRange: (
    signalInfo: SignalDefinition,
    chartRange: ChartRange | undefined,
    chart: Highcharts.Chart,
  ) => {
    if (chartRange) {
      Logger.log(
        '[requestDataForRange] Setting range to:',
        convertToSeconds(chartRange.toTime - chartRange.fromTime),
      );
      if (chartTools.isSerieAvailable(chart)) {
        Logger.log(
          '[requestDataForRange][%s] Requesting Query',
          signalInfo.name,
        );
        Logger.log(
          '[requestDataForRange][%s] Range %s-%s',
          signalInfo.name,
          moment(chartRange.fromTime).toISOString(),
          moment(chartRange.toTime).toISOString(),
        );
        Logger.log(
          '[requestDataForRange][%s] Epochs %d-%d',
          signalInfo.name,
          chartRangeTools.convertToEpoch(chartRange.fromTime),
          chartRangeTools.convertToEpoch(chartRange.toTime),
        );
      }

      const signalContainer = signalDatabase.get(signalInfo.type);
      if (signalContainer) {
        for (
          let epochNumber = chartRange.fromEpoch;
          epochNumber < chartRange.toEpoch;
          epochNumber += 1
        ) {
          const epochContainer: EpochContainer = signalContainer.get(
            epochNumber,
          ) || {
            isReceived: false,
            isRequested: true,
            samples: [],
          };

          signalContainer.set(epochNumber, epochContainer);
        }
      }

      queryManager.addSignalQuery(
        chartRange,
        {
          recordingId: sheetTools.getRecordingId(),
          signalType: signalInfo.type,
          beginning: moment(chartRange.fromTime).toISOString(),
          end: moment(chartRange.toTime).toISOString(),
        },
        (signalData: SignalData) => {
          Logger.log(
            '[requestDataForRange][%s] Asked for period: %s - %s',
            signalInfo.name,
            moment(chartRange.fromTime).toISOString(),
            moment(chartRange.toTime).toISOString(),
          );

          const samplesArray = chartTools.convertToArrayOfSamples(signalData);

          chartTools.storeData(signalInfo, samplesArray, chartRange);
          chartRangeTools.incrementLoadedEpoch({
            first: chartRange.fromEpoch,
            last: chartRange.toEpoch,
          });

          chartTools.redrawBufferedStatus();
          const currentEpochExtremes =
            chartRangeTools.getCurrentExtremesInEpoch();
          const shouldRedraw =
            chartRangeTools.isEpochRangeWithinCurrentExtremes({
              first: chartRange.fromEpoch,
              last: chartRange.toEpoch,
            });

          Logger.log(
            '[requestDataForRange][%s] needRedraw? ',
            signalInfo.name,
            shouldRedraw,
          );
          if (shouldRedraw) {
            Logger.log(
              '[requestDataForRange][%s] needRedraw is true. REDRAW NEEDED.',
              signalInfo.name,
            );
            if (chartTools.isSerieAvailable(chart)) {
              Logger.log(
                '[requestDataForRange][%s] Adding data for current range: ',
                signalInfo.name,
                currentEpochExtremes,
              );

              if (!chartEvents.isUserMoving()) {
                ScoringRenderingService.requestRedraw();
                SignalRenderingService.addDataToCurrentAndNextExtremes(chart)
                  .then(SignalRenderingService.autoScaleIfNeeded)
                  .then(SignalRenderingService.processCoreRendering);
              }
            }
          }
        },
      );
      Logger.log('[requestDataForRange][%s] Finishing', signalInfo.name);
    } else {
      Logger.log('[requestDataForRange] Skipping: chart range is undefined');
    }
  },
  convertToArrayOfSamples: (signalData: SignalData) => {
    const chartData: number[][] = [];

    let currentStartTime = 0;
    let currentGap = 0;

    signalData.sessions.forEach((session) => {
      /*  Logger.log('[processData][%s] Processing session', signalData.type);
      Logger.log('[processData][%s] Session beginning', signalData.type, session.beginning); */

      currentStartTime = new Date(session.beginning).getTime();
      currentGap = 1000 / session.samplingRate;
      // Logger.log('[processData] Gap between samples is: %fms', gap);

      const { samples } = session;
      samples.forEach((value, index) => {
        const timestamp = currentStartTime + currentGap * index;

        const sample = [timestamp, value];
        chartData.push(sample);
      });
    });

    // Logger.log('[processData][%s] Processed %d lines', signalData.type, chartData.length);
    return chartData;
  },
  applyBresenham: (rawData: number[][], chartWidth: number) => {
    Logger.log('[applyBresenham] Starting algorithm.');
    /* Logger.log('[applyBresenham] rawData:', rawData); */
    const time = Date.now();
    const samplesPerChunk = rawData.length / chartWidth;
    const downsampledChartData: number[][] = [];

    let previousPixel: number[] = [];
    let skippedPixel: number[] = [];
    let currentChunkSamples = 0;
    let first: number[] = [];
    let second: number[] = [];
    let third: number[] = [];
    let last: number[] = [];
    let max: number[] = [];
    let min: number[] = [];

    rawData.forEach((val) => {
      if (val) {
        const timestamp = val[0];
        const value = val[1];

        if (currentChunkSamples === 0) first = [timestamp, value];

        if (!min[1] || value < min[1]) {
          min = [timestamp, value];
        }

        if (!max[1] || value >= max[1]) {
          max = [timestamp, value];
        }

        if (currentChunkSamples + 1 > samplesPerChunk) {
          last = [timestamp, value];

          if (min[0] === max[0]) {
            second = min;
          } else if (min[0] < max[0]) {
            second = min;
            third = max;
          } else if (min[0] > max[0]) {
            second = max;
            third = min;
          }

          if (!previousPixel[1] || first[1] !== previousPixel[1]) {
            if (skippedPixel[1]) {
              downsampledChartData.push(skippedPixel);
              skippedPixel = [];
            }
            downsampledChartData.push(first);
            previousPixel = first;
          } else {
            skippedPixel = first;
          }

          if (!!second[1] && first[1] !== second[1] && second[1] !== last[1]) {
            downsampledChartData.push(second);
            previousPixel = second;
            skippedPixel = [];
          }

          if (!!third[1] && third[1] !== last[1] && third[1] !== second[1]) {
            downsampledChartData.push(third);
            previousPixel = third;
            skippedPixel = [];
          }

          if (!!last[1] && first[1] !== last[1]) {
            downsampledChartData.push(last);
            previousPixel = last;
            skippedPixel = [];
          }

          first = [];
          second = [];
          third = [];
          last = [];
          min = [];
          max = [];

          currentChunkSamples = 0;
        } else {
          currentChunkSamples += 1;
        }
      }
    });

    if (skippedPixel[1]) {
      downsampledChartData.push(skippedPixel);
      skippedPixel = [];
    }

    Logger.log(
      '[applyBresenham] Applied to %d -> %d. Took (ms):',
      rawData.length,
      downsampledChartData.length,
      Date.now() - time,
    );
    Logger.log('[applyBresenham] Ended algorithm.');
    /* Logger.log('[applyBresenham] downsampledChartData:', downsampledChartData); */
    return downsampledChartData;
  },
  setSize: (chart: Highcharts.Chart, width: number, height: number) =>
    new Promise((resolve) => {
      chart.setSize(width, height);
      resolve(chart);
    }),
  showLoading: (chart: Highcharts.Chart) => {
    if (chartTools.isSerieAvailable(chart)) {
      chartTools.setCustomData(chart, 'isLoading', true);
    }
  },
  hideLoading: (chart: Highcharts.Chart) => {
    if (chartTools.isSerieAvailable(chart)) {
      chartTools.setCustomData(chart, 'isLoading', false);
    }
  },
  isLoading: (chart: Highcharts.Chart) => {
    let isLoading = false;
    if (chartTools.isSerieAvailable(chart)) {
      isLoading =
        (chartTools.getCustomData(chart, 'isLoading') as boolean) ?? false;
    }
    return isLoading;
  },
  getMinMaxForCurrentExtremes: (signalType: SignalType) => {
    const signal = chartTools.getSignalInfo(signalType);
    if (signal) {
      const extremes = chartRangeTools.getCurrentExtremesInEpoch();
      const data = chartTools.getData(signal, extremes);
      let dataMin: number | undefined;
      let dataMax: number | undefined;

      data.forEach((sample) => {
        const value = sample[1];
        if (dataMin === undefined || value < dataMin) {
          dataMin = value;
        }

        if (dataMax === undefined || value > dataMax) {
          dataMax = value;
        }
      });

      if (dataMin !== undefined && dataMax !== undefined) {
        return { dataMin, dataMax };
      }
    }

    return undefined;
  },
  scaleToFit: (signalType: SignalType, opts?: { redraw: boolean }) => {
    const chart = chartTools.getChartFromRegistry(signalType);
    const signal = chartTools.getSignalInfo(signalType);

    if (!!chart && signal && chartTools.isSerieAvailable(chart)) {
      const dataMinMax = chartTools.getMinMaxForCurrentExtremes(signal.type);
      const offset = signal.labels ? 0.5 : 0;

      if (dataMinMax) {
        Logger.log('[scaleToFit] Scaling:', signalType);
        chart.update(
          {
            yAxis: {
              ceiling: dataMinMax.dataMax + offset,
              floor: dataMinMax.dataMin - offset,
              max: dataMinMax.dataMax + offset,
              min: dataMinMax.dataMin - offset,
            },
          },
          false,
        );

        chartTools.setCustomData(chart, 'yAxisAutoScaled', true);
        if (opts?.redraw) {
          SignalRenderingService.processCoreRendering(chart);
        }
      }
    }
  },
  scaleSmart: (signalType: SignalType) => {
    Logger.log('[scaleSmart] Scaling:', signalType);
    const chart = chartTools.getChartFromRegistry(signalType);
    const signalInfo = chartTools.getSignalInfo(signalType);

    if (!!chart && signalInfo && chartTools.isSerieAvailable(chart)) {
      Logger.log('[scaleSmart] Chart is available.');

      chart.update(
        {
          yAxis: {
            ceiling: signalInfo.cropMax || signalInfo.yMax,
            floor: signalInfo.yMin,
            max: signalInfo.yMax,
            min: signalInfo.yMin,
          },
        },
        false,
      );
      SignalRenderingService.processCoreRendering(chart);
    }
  },
  getChartFromRegistry: (chartName: ChartName) => {
    const chart = chartRegistry.get(chartName);
    if ((!chart || !chart.series) && chartName !== 'EpochChart')
      Logger.warn(
        '[getChartFromRegistry] Requested chart does not exist:',
        chartName,
      );

    return !!chart && !!chart.series ? chart : undefined;
  },
  generateBufferedStatusChartData: () => {
    Logger.log(
      '[generateBufferedStatusChartData] Generating Buffered Status Chart data',
    );
    const time = Date.now();

    const bufferedStatusData: BufferedStatusData = new Map<
      number,
      BufferedEpochInfo
    >();

    const firstEpoch = chartRangeTools.getFirstPartEpoch();
    const lastEpoch = chartRangeTools.getLastPartEpoch();

    for (let epoch = firstEpoch; epoch <= lastEpoch; epoch += 1) {
      bufferedStatusData.set(epoch, {
        epoch,
        bufferedSignals: chartRangeTools.getSignalsWithDataForEpoch(epoch),
      });
    }

    Logger.log(
      '[generateBufferedStatusChartData] Generated %d timestamps. Took (ms):',
      bufferedStatusData.size,
      Date.now() - time,
    );

    return bufferedStatusData;
  },
  calculateBufferedPercentage: (bufferedStatus: BufferedStatusData) => {
    Logger.log('[calculateBufferedPercentage] Calculating buffered percentage');
    const time = Date.now();

    const activeSignals = sheetTools.getActiveSignals().length;
    let signalsWithDataInEpochs = 0;

    bufferedStatus.forEach((epoch) => {
      signalsWithDataInEpochs += epoch.bufferedSignals;
    });

    const maxData = bufferedStatus.size * activeSignals;
    const percentage = (signalsWithDataInEpochs * 100) / maxData;

    Logger.log(
      '[calculateBufferedPercentage] Done: %d/%d = %f %. Took (ms)',
      signalsWithDataInEpochs,
      maxData,
      percentage,
      Date.now() - time,
    );
    return percentage;
  },
  redrawBufferedStatus: _.throttle(
    () => {
      const data = chartTools.generateBufferedStatusChartData();
      EventService.dispatch(
        'RecordingDownloadPercentage',
        chartTools.calculateBufferedPercentage(data).toFixed(0),
      );
      bufferedStatusUpdate(data);
    },
    1000,
    { leading: true },
  ),
  redrawNavigator: () => {
    Logger.log('[redrawNavigator] Redrawing');
    const time = Date.now();
    const navigatorChart = chartTools.getNavigatorChart();
    if (navigatorChart) {
      let data = eventChartTools.generateEventIntensityChartData();

      if (studyTools.isPartSelected()) {
        Logger.log('[redrawNavigator] Filtering');
        data = data.filter((intensityData) =>
          chartRangeTools.isTimestampWithinPart(intensityData[0]),
        );
      }

      // Logger.debug('[redrawNavigator] data:', data);
      chartTools.setData(navigatorChart, data, { redraw: true });

      Logger.log(
        '[redrawNavigator] Done! %d timestamps. Took (ms) :',
        data.length,
        Date.now() - time,
      );
    }
  },
  restyleAndRedrawNavigator: _.throttle(() => {
    if (eventChartTools.hasChangedEventIntensity()) {
      chartTools.redrawNavigator();
    } else {
      Logger.log(
        '[restyleAndRedrawNavigator] Event intensity has no changes. Skipping',
      );
    }
  }, 250),
  registerSignalInfo: (signalInfo: SignalDefinition) => {
    signalInfoRegistry.set(signalInfo.type, signalInfo);
    signalDatabase.set(signalInfo.type, new Map<EpochNumber, EpochContainer>());
  },
  getSignalInfo: (signalType: SignalType | undefined) => {
    if (signalType) {
      return signalInfoRegistry.get(signalType);
    }
    return undefined;
  },
  registerChart: (chartName: ChartName, chart: Highcharts.Chart) => {
    Logger.log('[registerChartRef] Registering chart:', chartName);
    chartRegistry.set(chartName, chart);
  },
  preloadMissingEpochs: () => {
    Logger.log('[preloadMissingEpochs] Initiating');
    const bufferedStatusNoData = Array.from(
      chartTools.generateBufferedStatusChartData().values(),
    ).filter((epoch) => epoch.bufferedSignals === 0);
    Logger.log(
      '[preloadMissingEpochs] bufferedStatusNoData:',
      bufferedStatusNoData,
    );
    const epochsWithNoData = bufferedStatusNoData.map((epoch) => epoch.epoch);
    Logger.log('[preloadMissingEpochs] epochsWithNoData:', epochsWithNoData);
    const rangesToPreload = chartTools.areasOfInterest
      .convertEpochsInConsecutiveRanges(epochsWithNoData)
      .reverse();
    Logger.log('[preloadMissingEpochs] rangesToPreload:', rangesToPreload);
    chartTools.areasOfInterest.addQueries(rangesToPreload);
    // const signalsWithDataForEpoch = chartRangeTools.getSignalsWithDataForEpoch(epoch);
    /* const missingEpochs = chartTools. */
  },
  areasOfInterest: {
    preload: _.debounce(() => {
      Logger.log('[AOI][preload] Starting...');
      const time = Date.now();
      const tools = chartTools.areasOfInterest;
      const EPOCH_LIMIT = 60;

      const intensityCount = tools.getEventsCountByIntensity();
      const interestThreshold = tools.findInterestThresholdIndex(
        intensityCount,
        EPOCH_LIMIT,
      );
      const eventIntensity =
        eventChartTools.getEventIntensityDataForCurrentPart();
      const epochsToPreload = tools.getEpochsByInterestThreshold(
        eventIntensity,
        interestThreshold,
        EPOCH_LIMIT,
        { fillUpToLimit: true },
      );
      if (epochsToPreload.length <= EPOCH_LIMIT) {
        const rangesToPreload = tools
          .convertEpochsInConsecutiveRanges(epochsToPreload)
          .reverse();
        tools.addQueries(rangesToPreload);
      } else {
        Logger.warn(
          '[AOI][preload] Skipping because epoch limit. %d > %d,',
          epochsToPreload.length,
          EPOCH_LIMIT,
        );
      }

      Logger.log('[AOI][preload] Took (ms):', Date.now() - time);
    }, 1000),
    getEventsCountByIntensity: () => {
      const time = Date.now();
      const intensityCount: number[] = [];
      const eventIntensity =
        eventChartTools.getEventIntensityDataForCurrentPart();
      Object.keys(eventIntensity).forEach((epoch) => {
        const currentEpoch = parseInt(epoch, 10);
        const epochEventIntensity = eventIntensity[currentEpoch];
        if (!intensityCount[epochEventIntensity])
          intensityCount[epochEventIntensity] = 0;
        intensityCount[epochEventIntensity] += 1;
      });
      Logger.log(
        '[AOI][generateIntensityCount] intensityCount: ',
        intensityCount,
      );
      Logger.log('[AOI][generateIntensityCount] Took (ms):', Date.now() - time);
      return intensityCount;
    },
    findInterestThresholdIndex: (
      intensityCount: number[],
      epochLimit: number,
    ) => {
      const time = Date.now();
      let interestThreshold = -1;
      let foundThreshold = false;
      let epochsCount = 0;

      for (let i = intensityCount.length - 1; i >= 0 && !foundThreshold; i--) {
        const currentIntensity = i;
        const epochsWithCurrentIntensity = intensityCount[currentIntensity];
        Logger.log(
          '[AOI][findInterestThresholdIndex] intensityCount[%d]=',
          currentIntensity,
          intensityCount[currentIntensity],
        );
        if (epochsWithCurrentIntensity) {
          if (epochsCount + epochsWithCurrentIntensity < epochLimit) {
            epochsCount += epochsWithCurrentIntensity;
            interestThreshold = currentIntensity;
          } else {
            Logger.log(
              '[AOI][findInterestThresholdIndex] EPOCH_LIMIT:',
              epochLimit,
            );
            Logger.log(
              '[AOI][findInterestThresholdIndex] epochsCount:',
              epochsCount,
            );
            Logger.log(
              '[AOI][findInterestThresholdIndex] epochsWithCurrentIntensity:',
              epochsWithCurrentIntensity,
            );
            const remainingEpochs = epochLimit - epochsCount;
            Logger.log(
              '[AOI][findInterestThresholdIndex] remainingEpochs:',
              remainingEpochs,
            );
            foundThreshold = true;
          }
        }
      }

      Logger.log(
        '[AOI][findInterestThresholdIndex] interestThreshold:',
        interestThreshold,
      );
      Logger.log('[AOI][findInterestThresholdIndex] epochsCount:', epochsCount);
      Logger.log(
        '[AOI][findInterestThresholdIndex] Took (ms):',
        Date.now() - time,
      );

      return interestThreshold;
    },
    getEpochsByInterestThreshold: (
      eventIntensity: number[],
      interestThreshold: number,
      epochLimit: number,
      opts: {
        fillUpToLimit: boolean;
      },
    ) => {
      const time = Date.now();

      const eventIntensityTimestamps = Object.keys(eventIntensity).sort();
      const epochsToPreload: number[] = _.uniq(
        eventIntensityTimestamps
          .filter(
            (timestamp) =>
              eventIntensity[parseInt(timestamp, 10)] > interestThreshold,
          )
          .map((timestamp) =>
            chartRangeTools.convertToEpoch(parseInt(timestamp, 10)),
          ),
        true, // isSorted
      );

      Logger.log(
        '[AOI][getEpochsByInterestThreshold] Collected needed epochs up to the threshold:',
        epochsToPreload,
      );

      for (
        let intensity = interestThreshold;
        epochsToPreload.length < epochLimit &&
        intensity >= 0 &&
        (intensity === interestThreshold || opts.fillUpToLimit);
        intensity--
      ) {
        const epochsForCurrentIntensity = _.uniq(
          eventIntensityTimestamps
            .filter(
              (timestamp) =>
                eventIntensity[parseInt(timestamp, 10)] === intensity,
            )
            .map((timestamp) =>
              chartRangeTools.convertToEpoch(parseInt(timestamp, 10)),
            ),
          true, // isSorted
        );

        if (epochsForCurrentIntensity.length) {
          const remainingEpochs = epochLimit - epochsToPreload.length;
          const amountToTake =
            epochsForCurrentIntensity.length > remainingEpochs
              ? remainingEpochs
              : epochsForCurrentIntensity.length;

          epochsToPreload.push(
            ...epochsForCurrentIntensity.slice(0, amountToTake),
          );
        }
      }

      Logger.log(
        '[AOI][getEpochsByInterestThreshold] epochsToPreload: %d -> %d: ',
        Object.keys(eventIntensity).length,
        Object.keys(epochsToPreload).length,
      );
      Logger.log(
        '[AOI][getEpochsByInterestThreshold] Calculated epochsToPreload: ',
        epochsToPreload,
      );
      Logger.log(
        '[AOI][getEpochsByInterestThreshold] epochsToPreload Took (ms):',
        Date.now() - time,
      );
      return epochsToPreload;
    },
    convertEpochsInConsecutiveRanges: (epochsToPreload: number[]) => {
      const EPOCH_RANGE_LIMIT = 20;
      const time = Date.now();

      let previousEpoch = 0;
      let currentRange: number[] = [];
      const rangesToPreload: number[][] = [];

      epochsToPreload.forEach((currentEpoch) => {
        if (currentEpoch - previousEpoch === 1) {
          currentRange.push(currentEpoch);
        } else {
          if (currentRange.length) rangesToPreload.push(currentRange);
          currentRange = [];
          currentRange.push(currentEpoch);
        }
        previousEpoch = currentEpoch;

        if (currentRange.length === EPOCH_RANGE_LIMIT) {
          rangesToPreload.push(currentRange);
          currentRange = [];
        }
      });

      if (currentRange.length) {
        rangesToPreload.push(currentRange);
      }

      Logger.log(
        '[AOI][convertEpochsInConsecutiveRanges] rangesToPreload: %d -> %d: ',
        Object.keys(epochsToPreload).length,
        Object.keys(rangesToPreload).length,
      );
      Logger.log(
        '[AOI][convertEpochsInConsecutiveRanges] Calculated rangesToPreload: ',
        rangesToPreload,
      );
      Logger.log(
        '[AOI][convertEpochsInConsecutiveRanges] rangesToPreload Took (ms):',
        Date.now() - time,
      );

      return rangesToPreload;
    },
    addQueries: (rangesToPreload: number[][]) => {
      const time = Date.now();
      let queryCount = 0;

      Logger.log('[AOI][addQueries] rangesToPreload:', rangesToPreload);

      rangesToPreload.forEach((range) => {
        const min = chartRangeTools.convertToTimestamp(range[0]);
        const max = chartRangeTools.convertToTimestamp(range[range.length - 1]);
        const isPreload = true;
        const rangeToLoad = chartRangeTools.calculateRangeToLoad(
          min,
          max,
          isPreload,
        );
        if (rangeToLoad) {
          Logger.log(
            '[AOI][addQueries] --> Request range for %s-%s is: %s-%s',
            range[0],
            range[range.length - 1],
            rangeToLoad.fromEpoch,
            rangeToLoad.toEpoch,
          );

          signalInfoRegistry.forEach((signalInfo) => {
            const chart = chartTools.getChartFromRegistry(signalInfo.type);
            if (chart) {
              chartTools.requestDataForRange(signalInfo, rangeToLoad, chart);
              queryCount += 1;
              Logger.log(
                '[AOI][addQueries] ----> For signal: %s (%s-%s)',
                signalInfo,
                rangeToLoad.fromEpoch,
                rangeToLoad.toEpoch,
              );
            }
          });
        }
      });
      Logger.log(
        '[AOI][addQueries] %d queries generated. Took (ms):',
        queryCount,
        Date.now() - time,
      );
    },
  },
  bufferedStatus: {
    registerStatusUpdateFunction: (fn: BufferedStatusUpdateFunction) => {
      bufferedStatusUpdate = fn;
    },
  },
  setData: (
    chart: Highcharts.Chart,
    data: Highcharts.PointOptionsType[],
    options: { redraw: boolean },
  ) => {
    const isAvailable = chartTools.isSerieAvailable(chart);
    if (isAvailable) {
      chart.series[0].setData(data, options.redraw, undefined, false);
    } else {
      Logger.warn('[setDataToSeries] Skipping because serie is not available.');
    }
  },
  getSeriesOptions: (chart: Highcharts.Chart) => {
    const isAvailable = chartTools.isSerieAvailable(chart);
    if (isAvailable) {
      return chart.series[0].options as Highcharts.SeriesLineOptions;
    }
    Logger.warn('[getSeriesOptions] Skipping because serie is not available.');
    return undefined;
  },
  getPlotBands: (chart: Highcharts.Chart) => {
    const isAvailable = chartTools.isSerieAvailable(chart);
    if (isAvailable) {
      return (chart.xAxis[0] as HighchartsAxisWithPlotbands).plotLinesAndBands;
    }
    Logger.warn('[getPlotBands] Skipping because serie is not available.');
    return [];
  },
  setSeriesOptions: (
    chart: Highcharts.Chart,
    options: Highcharts.SeriesLineOptions,
    parameters: { redraw: boolean },
  ) => {
    const isAvailable = chartTools.isSerieAvailable(chart);
    if (isAvailable) {
      chart.series[0].update(options, parameters.redraw);
    } else {
      Logger.warn(
        '[setSeriesOptions] Skipping because serie is not available.',
      );
    }
  },
  isSerieAvailable: (
    chart: Highcharts.Chart,
    opts?: { serieIndex?: number },
  ) => {
    const isAvailable =
      !!chart &&
      chart.series &&
      chart.series[opts?.serieIndex !== undefined ? opts.serieIndex : 0];
    if (!isAvailable) {
      Logger.warn(
        '[isSerieAvailable] Serie no longer available. User probably switched tabs',
      );
    }

    return !!isAvailable;
  },
};

export default chartTools;
