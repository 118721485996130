import { fade, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import _ from 'underscore';
import { Recording } from '../../queries/recording';
import AnalysisPeriodsService from '../../services/analysisPeriodsService';
import EventService from '../../services/eventService';
import ScoringService from '../../services/scoringService';
import Logger from '../../utils/logger';
import { generateStripedGradient } from '../../utils/theme';
import chartTools from '../Chart/SignalChart/chartTools';
import eventChartTools from '../Chart/SignalChart/eventChartTools';
import { OverlayArea } from '../Chart/SignalChart/interfaces/event-chart-tools';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  overlayPart: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    border: `2px solid ${fade(theme.palette.primary.main, 0.5)}`,
    borderTop: 'none',
    borderBottom: 'none',
    cursor: 'pointer',
    '&:hover': {
      '& $overlayText': {
        background: fade(theme.palette.primary.main, 0.5),
      },
    },
  },
  highlighted: {
    '& $overlayText': {
      background: fade(theme.palette.primary.main, 0.5),
    },
  },
  overlayText: {
    position: 'absolute',
    left: -2,
    right: -2,
    bottom: -18,
    textAlign: 'center',
    border: `2px solid ${fade(theme.palette.primary.main, 0.5)}`,
    borderTop: 'none',
    background: fade(theme.palette.primary.main, 0.15),
    color: theme.palette.getContrastText(theme.palette.primary.main),
    borderRadius: '0px 0px 4px 4px',
    fontSize: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    userSelect: 'none',
    '&:hover': {
      background: fade(theme.palette.primary.main, 0.5),
    },
  },
  outsidePeriod: {
    background: `linear-gradient(0deg, ${fade('#9e9e9e', 0.5)} 0%, ${fade(
      '#9e9e9e',
      0.15,
    )} 100%);`,
    border: 'none',
    cursor: 'default',
  },
  exclusionZone: {
    background: generateStripedGradient('#9e9e9e', 0.05, 0.25),
    pointerEvents: 'none',
  },
}));

interface OverviewNavigatorOverlayProps {
  recording?: Recording;
  highlightPartId?: number;
  setHighlightPartId: React.Dispatch<React.SetStateAction<number | undefined>>;
  containerWidth: number;
}

const OverviewNavigatorOverlay = (
  props: OverviewNavigatorOverlayProps,
): JSX.Element => {
  const classes = useStyles();
  const [overlayParts, setOverlayParts] = React.useState<OverlayArea[]>([]);
  const [, setSavedWidth] = React.useState(0);

  const processAnalysisPeriods = (): OverlayArea[] => {
    Logger.log('OverviewNavigatorOverlay onRedraw', props.recording);
    if (props.recording) {
      const periodEvents = AnalysisPeriodsService.retrievePeriodEvents();
      const analysisPeriods =
        AnalysisPeriodsService.convertEventsToPeriods(periodEvents);

      const newOverlayParts = analysisPeriods
        ? AnalysisPeriodsService.preparePeriodsForDisplay(
            analysisPeriods,
            props.recording.startTime,
            props.recording.endTime,
            props.containerWidth,
          )
        : [];

      return newOverlayParts;
    }

    return [];
  };

  const processInvalidSignals = (): OverlayArea[] => {
    const data: OverlayArea[] = [];

    const events = ScoringService.retrieveMarkersByType(['signal-invalid']);

    Logger.log('[OverviewNavigatorOverlay] events', events);

    events.forEach((event) => {
      if (props.recording) {
        data.push({
          start: event.start,
          end: event.end,
          position: eventChartTools.calculateOverlayPosition(
            new Date(props.recording.startTime).valueOf(),
            new Date(props.recording.endTime).valueOf(),
            event.start,
            event.end,
            props.containerWidth,
          ),
          isExcluded: true,
        });
      }
    });
    Logger.log('[OverviewNavigatorOverlay] invalid signal events', data);

    return data;
  };

  const onRedraw = () => {
    Logger.log('OverviewPartAnalysisPeriod onRedraw');

    const newOverlayParts = [
      ...processAnalysisPeriods(),
      ...processInvalidSignals(),
    ];
    setOverlayParts(newOverlayParts);
    chartTools.redrawNavigator();
    Logger.log('OverviewNavigatorOverlay', newOverlayParts);
  };

  const throttledRedraw = _.throttle(onRedraw, 250);

  React.useEffect(() => {
    Logger.log('OverviewNavigatorOverlay useEffect');
    const cbId = EventService.subscribe(['ScoringChanged'], throttledRedraw);

    if (overlayParts.length === 0) {
      throttledRedraw();
    }

    setSavedWidth((old) => {
      if (old !== props.containerWidth) {
        onRedraw();
      }
      return props.containerWidth;
    });

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, [props.recording, props.highlightPartId, props.containerWidth]);

  return (
    <div className={classes.root}>
      {overlayParts.map((part) => (
        <div
          key={JSON.stringify(part)}
          className={clsx(classes.overlayPart, {
            [classes.outsidePeriod]: part.partId === undefined,
            [classes.highlighted]: part.partId === props.highlightPartId,
            [classes.exclusionZone]: part.isExcluded,
          })}
          style={{
            left: part.position.left,
            width: part.position.width,
          }}
          onClick={() => {
            if (props.highlightPartId === part.partId) {
              props.setHighlightPartId(undefined);
            } else {
              props.setHighlightPartId(part.partId);
            }
          }}
          data-cy={
            part.partId === undefined
              ? 'OverviewNavigator-OutsidePeriod'
              : 'OverviewNavigator-AnalysisPeriod'
          }
        >
          {part.partId !== undefined ? (
            <Typography variant="caption" className={classes.overlayText}>
              Analysis Period
            </Typography>
          ) : (
            ''
          )}
        </div>
      ))}
    </div>
  );
};

export default OverviewNavigatorOverlay;
