import React from 'react';
import moment from 'moment-timezone';

import { Paper, makeStyles, Typography, fade, Fade } from '@material-ui/core';
import { ReportCommentData } from '../../queries/report';
import themeProvider, { darkenOrLighten } from '../../utils/theme';
import Logger from '../../utils/logger';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    background: darkenOrLighten(
      theme.id,
      theme.palette.background.paper,
      0.05,
      0.1,
    ),
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    gap: theme.spacing(1),
    alignItems: 'center',
    '@media print': {
      boxShadow: 'none',
      padding: 0,
      background: `${
        themeProvider.getType('LIGHT').palette.background.paper
      } !important`,
      color: theme.palette.getContrastText(
        themeProvider.getType('LIGHT').palette.background.paper,
      ),
      marginBottom: theme.spacing(2),
      pageBreakInside: 'avoid',
    },
  },
  author: {
    whiteSpace: 'nowrap',
  },
  date: {
    color: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.54,
    ),
    '@media print': {
      color: fade(
        theme.palette.getContrastText(
          themeProvider.getType('LIGHT').palette.background.paper,
        ),
        0.54,
      ),
    },
  },
  message: {
    gridColumn: '1/3',
    whiteSpace: 'break-spaces',
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
}));

interface ReportCommentProps {
  comment: ReportCommentData;
  scrollIntoView?: boolean;
}

function ReportComment(props: ReportCommentProps): JSX.Element {
  const classes = useStyles();
  const fieldRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    Logger.log('[ReportComment] useEffect', props);
    if (props.scrollIntoView && fieldRef.current) {
      Logger.log('[ReportComment] scrollIntoView ');
      fieldRef.current.scrollIntoView();
    }
  });

  return (
    <Fade in>
      <Paper elevation={2} className={classes.root} ref={fieldRef}>
        <Typography variant="body2" className={classes.author}>
          {props.comment.author}
        </Typography>
        <Typography variant="caption" className={classes.date}>
          {moment(props.comment.date).format('YYYY-MM-DD HH:mm:ss')}
        </Typography>
        <Typography variant="body1" className={classes.message}>
          {props.comment.message}
        </Typography>
      </Paper>
    </Fade>
  );
}

export default ReportComment;
