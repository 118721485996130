import React from 'react';
import clsx from 'clsx';

import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles, Button, Grow } from '@material-ui/core';
import Logger from '../../utils/logger';
import longRunningOperationService, {
  LongRunningOperation,
} from '../../services/longRunningOperationService';
import LongRunningOperationsMenu from '../LongRunningOperationsMenu/LongRunningOperationsMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  button: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    height: 36,
    marginLeft: 3,
    minWidth: 50,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  active: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  number: {
    position: 'absolute',
    top: 0,
    right: 0,
    lineHeight: '16px',
    borderRadius: 25,
    width: 16,
    height: 16,
    fontSize: 12,
    opacity: 1,
    transition: '0.5s all linear',
    letterSpacing: -1,
  },
  running: {
    backgroundColor: theme.colors.statuses.neutral,
    color: theme.palette.primary.contrastText,
  },
  failed: {
    backgroundColor: theme.colors.statuses.error,
    color: theme.palette.primary.contrastText,
  },
  finished: {
    backgroundColor: theme.colors.statuses.success,
    color: theme.palette.primary.contrastText,
  },
  hide: {
    opacity: 0,
  },
  gearFirst: {
    position: 'absolute',
    top: 3,
    left: 8,
  },
  gearSecond: {
    position: 'absolute',
    bottom: 3,
    right: 8,
  },
  gearAnimate: {
    animation: '$spin 1500ms linear infinite',
  },
  gearAnimateReverse: {
    animation: '$spin 1500ms linear infinite reverse',
  },
  arrowHide: {
    opacity: 0,
  },
  arrow: {
    position: 'absolute',
    bottom: -30,
    width: 15,
    height: 15,
    transition: '0.3s all linear',

    '&:before': {
      bottom: '100%',
      left: '50%',
      border: 'solid transparent',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: 'rgba(194, 225, 245, 0)',
      borderBottomColor: '#313131',
      borderWidth: 16,
      marginLeft: -16,
    },
    '&:after': {
      bottom: '100%',
      left: '50%',
      border: 'solid transparent',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: 'rgba(136, 183, 213, 0)',
      borderBottomColor: '#313131',
      borderWidth: 16,
      marginLeft: -16,
    },
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
}));

const LongRunningOperations = (): JSX.Element => {
  const [operations, setOperations] = React.useState<LongRunningOperation[]>(
    [],
  );
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const toggleMenu = () => {
    if (showMenu) {
      longRunningOperationService.markAllAsSeen();
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };
  const classes = useStyles();

  const updateOperations = (newOperations: LongRunningOperation[]) =>
    setOperations(newOperations);

  React.useEffect(() => {
    Logger.log('[LongRunningOperations] useEffect');
    longRunningOperationService.initialize(updateOperations);
  }, []);

  const areRunningOperations = operations.some(
    (op) => op.status === 'Running' || op.status === 'Created',
  );

  const hasAnyOperationFailed = operations
    .filter((lro) => !lro.seen)
    .some((op) => op.status === 'Failed');
  const areAllOperationSuccessful = operations
    .filter((lro) => !lro.seen)
    .every((op) => op.status === 'Finished');

  return (
    <Grow in={operations.length > 0} mountOnEnter unmountOnExit>
      <div className={classes.root}>
        <Button
          onClick={toggleMenu}
          className={clsx(classes.button, { [classes.active]: showMenu })}
        >
          <div
            className={clsx(classes.number, {
              [classes.failed]: hasAnyOperationFailed,
              [classes.finished]:
                !hasAnyOperationFailed && areAllOperationSuccessful,
              [classes.running]:
                !hasAnyOperationFailed &&
                !areAllOperationSuccessful &&
                areRunningOperations,
              [classes.hide]:
                operations.filter((lro) => !lro.seen).length === 0,
            })}
          >
            {operations.filter((lro) => !lro.seen).length}
          </div>
          <SettingsIcon
            fontSize="small"
            className={clsx(classes.gearFirst, {
              [classes.gearAnimate]: areRunningOperations,
            })}
          />
          <SettingsIcon
            fontSize="small"
            className={clsx(classes.gearSecond, {
              [classes.gearAnimateReverse]: areRunningOperations,
            })}
          />
        </Button>
        <LongRunningOperationsMenu operations={operations} visible={showMenu} />
      </div>
    </Grow>
  );
};

export default LongRunningOperations;
