import React from 'react';
import {
  Checkbox,
  createStyles,
  fade,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { WithRouterProps } from '../../interfaces/with-router-props';

import { Recording } from '../../queries/recording';
import queryManager from '../../services/queryManager';
import { GetScoringInsightsQueryResult } from '../../queries/scoringInsights';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      color: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.54,
      ),
      '&.Mui-checked': {
        color:
          theme.id === 'LIGHT'
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
      },
    },
  }),
);

const SubmittedCellRenderer = (
  props: WithRouterProps<{ data: Recording }>,
): JSX.Element => {
  const classes = useStyles();
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>();

  React.useEffect(() => {
    queryManager
      .query<GetScoringInsightsQueryResult>('ScoringInsights', {
        recordingId: props.data.recordingId,
        scoringId: 'default',
      })
      .then((data) =>
        setIsSubmitted(
          data.scoringInsights.parts?.some((part) => part.isSubmitted),
        ),
      )
      .catch(() => setIsSubmitted(() => undefined));
  }, []);

  return (
    <>
      <Checkbox
        checked={isSubmitted || false}
        disabled={isSubmitted === undefined}
        color="primary"
        className={classes.checkbox}
      />
    </>
  );
};

export default SubmittedCellRenderer;
