import SheetDefinition from '../../../../interfaces/sheet-definition';
import { SignalDefinitionTools } from '../SignalDefinitions';

const Respiratory: SheetDefinition = {
  name: 'Respiratory',
  type: 'RespiratorySheet',
  path: 'respiratory-sheet',
  signals: SignalDefinitionTools.getBySignalTypes([
    'Activity-Gravity',
    'Snore.Envelope-Audio.dB',
    'SpO2.Averaged-Probe',
    'Resp.Flow-Cannula.Nasal',
    'Resp.Movement-Inductive.Thorax',
    'Resp.Movement-Inductive.Abdomen',
    'Resp.FlowCal-RIP',
    'HeartRate-ECG',
  ]),
};

export default Respiratory;
