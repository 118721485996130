import gql from 'graphql-tag';
import { RecordingId } from './recording';

export interface Scoring {
  recordingId: RecordingId;
  scoringId: string;
  version: string;
  name?: string;
  readOnly: boolean;
  markers: ScoringMarker[];
}

export interface ScoringMarker {
  markerId: string;
  eventType: string;
  startTime: string;
  endTime: string;
  signal?: string;
  deleted?: boolean;
  isAutomatic: boolean;
}

export interface GetScoringQueryParameters {
  recordingId: RecordingId;
  scoringId: string;
  ignoreMarkerTypes: string[];
}

export interface GetScoringQueryResult {
  scoring: Scoring;
}

export const GET_SCORING = gql`
  query Scoring(
    $recordingId: String!
    $scoringId: String!
    $ignoreMarkerTypes: [String!]!
  ) {
    scoring(
      recordingId: $recordingId
      scoringId: $scoringId
      ignoreMarkerTypes: $ignoreMarkerTypes
    ) {
      recordingId
      scoringId
      version
      name
      readOnly
      markers {
        markerId
        eventType
        signal
        startTime
        endTime
        deleted
        isAutomatic
      }
    }
  }
`;
