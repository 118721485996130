import gql from 'graphql-tag';

export interface SignalData {
  type: string;
  unit: string;
  sessions: Session[];
}

export interface Session {
  beginning: Date;
  samplingRate: number;
  samples: number[];
}

export interface QuerySignal {
  signal: SignalData;
}

export const GET_SIGNAL = gql`
  query Signal(
    $recordingId: String!
    $signalType: String!
    $beginning: DateTime!
    $end: DateTime!
  ) {
    signal(
      recordingId: $recordingId
      signalType: $signalType
      beginning: $beginning
      end: $end
    ) {
      type
      unit
      sessions {
        beginning
        samplingRate
        samples
      }
    }
  }
`;
