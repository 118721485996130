import * as H from 'history';
import Logger from './logger';

const openRecording = (
  recordingId: string,
  history: H.History<Record<string, unknown>>,
): void => {
  Logger.log('[openRecording] Opening recordingId:', recordingId);
  history.push(
    ['/recording', '?id=', recordingId, '&scoringId=default'].join(''),
  );
};

export default openRecording;
