import React from 'react';
import _ from 'underscore';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  epoch: {
    margin: theme.spacing(0, 2),
  },
  signal: {
    flex: 1,
    margin: theme.spacing(1, 2),
    borderRadius: 5,
  },
}));

interface SignalViewerLoadingProps {
  signalsTotal: number;
}

const SignalViewerLoading = (props: SignalViewerLoadingProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Skeleton variant="text" height={20} className={classes.epoch} />
      {Array(props.signalsTotal)
        .fill('')
        .map(() => (
          <Skeleton
            key={_.uniqueId()}
            className={classes.signal}
            variant="rect"
          />
        ))}
    </>
  );
};

export default SignalViewerLoading;
