import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from './privateRoute';
import Recordings from '../containers/Recordings/Recordings';
import UploadRecording from '../containers/UploadRecording/UploadRecording';
import SheetWorkspace from '../containers/Recording/SheetWorkspace';
import SignalOrdering from '../containers/SignalOrdering/SignalOrdering';
import StudyOverview from '../containers/Recording/StudyOverview';
import Help from '../containers/Help/Help';
import Callback from './auth/callback';
import ReportViewer from '../containers/Recording/ReportViewer';

const Routes = (): JSX.Element => (
  <Switch>
    <Route path="/callback" exact component={Callback} />
    <PrivateRoute path="/recordings" component={Recordings} />
    <PrivateRoute path="/recording/:sheetPath" component={SheetWorkspace} />
    <PrivateRoute path="/recording" component={StudyOverview} />
    <PrivateRoute path="/upload-recording" component={UploadRecording} />
    <PrivateRoute path="/settings/signal-ordering" component={SignalOrdering} />
    <PrivateRoute path="/report" component={ReportViewer} />
    <PrivateRoute path="/help" component={Help} />
    <PrivateRoute path="/" exact component={Recordings} />
  </Switch>
);

export default Routes;
