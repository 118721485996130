import React from 'react';
import clsx from 'clsx';

import { Typography, makeStyles, Theme } from '@material-ui/core';
import cssClasses from './networkError.module.scss';
import FeatureToggle from '../Shared/FeatureToggle';
import { MarkerDefinitions } from '../Chart/SignalChart/markerConstants';
import Analytics from '../../services/analytics';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10%',
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  cross: {
    position: 'relative',

    '&:after': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      margin: '100px 0',
      height: 15,
      content: '""',
      background: MarkerDefinitions.get('sleep-nrem')?.color,
      borderRadius: 4,
      transform: 'rotate(-45deg)',
    },
  },
}));

interface NetworkErrorProps {
  message?: string;
}

const NetworkError = (props: NetworkErrorProps): JSX.Element => {
  const classes = useStyles();

  React.useEffect(() => {
    Analytics.track.page('NetworkError');
    Analytics.track.event('ERROR_NETWORK_DOWN');
  }, []);

  return (
    <div className={classes.root}>
      <div className={clsx(cssClasses.image, classes.cross)} />
      <Typography variant="h5">Our services cannot be reached</Typography>
      <Typography>
        We are working hard fixing it. Please, wait or contact an administrator.
      </Typography>
      <FeatureToggle enabledFor={['development', 'staging']}>
        <>
          {props.message && (
            <Typography variant="caption">
              {props.message.toString()}
            </Typography>
          )}
        </>
      </FeatureToggle>
    </div>
  );
};

export default NetworkError;
