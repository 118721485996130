import React from 'react';
import { Tooltip, IconButton, makeStyles } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { useHistory } from 'react-router-dom';
import { RecordingId } from '../../queries/recording';
import openRecording from '../../utils/openRecording';

interface RecordingOpenProps {
  recordingId: RecordingId;
}

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
}));

const RecordingOpen = (props: RecordingOpenProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Tooltip title="Open recording" arrow>
      <IconButton
        className={classes.button}
        onClick={() => openRecording(props.recordingId, history)}
      >
        <OpenInNewIcon />
      </IconButton>
    </Tooltip>
  );
};

export default RecordingOpen;
