import React from 'react';
import clsx from 'clsx';
import {
  FormControl,
  Paper,
  makeStyles,
  Theme,
  fade,
  ClickAwayListener,
  TextField,
  Fade,
} from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Skeleton from '@material-ui/lab/Skeleton';
import chartRangeTools from '../SignalSheet/chartRangeTools';
import sheetTools from '../SignalSheet/sheetTools';
import Analytics from '../../services/analytics';
import Logger from '../../utils/logger';
import EventService from '../../services/eventService';
import SleepStageService from '../../services/sleepStageService';
import { SheetToolbarMode } from '../../interfaces/sheet-toolbar-props';
import chartEvents from '../SignalSheet/chartEvents';
import { KeyCode } from '../SignalSheet/interfaces/keyboard-manager';
import KeyboardManager from '../SignalSheet/keyboardManager';

const useStyles = makeStyles((theme: Theme) => ({
  epoch: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
  },
  epochSkeleton: {
    width: 50,
    marginLeft: 10,
    marginRight: 25,
  },
  paper: {
    height: 50,
    transition: '0.1s all linear',
    backgroundColor: theme.palette.background.paper,
    color: theme.colors.text.lighter,
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2),' +
      '0px 1px 1px 0px rgba(0,0,0,0.14),' +
      '0px 2px 1px -1px rgba(0,0,0,0.12)',
    position: 'relative',
  },
  minimized: {},
  minimal: { height: 28 },
  textField: {
    flexDirection: 'initial',
  },
  inputContainer: {
    color: theme.colors.text.lighter,
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  input: {
    textAlign: 'center',
    paddingTop: 0,
    paddingBottom: 0,
  },
  loading: {
    display: 'initial',
  },
  arrow: {
    height: theme.spacing(1.5),
    position: 'absolute',
    right: 0,
    left: 0,
    cursor: 'pointer',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 1,
    margin: 2,
    borderRadius: 4,
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, 0.35),
    },
  },
  arrowUp: {
    top: 0,
  },
  arrowDown: {
    bottom: 0,
  },
  disabled: {
    pointerEvents: 'none',
  },
}));

interface EpochSelectorProps {
  toolbarMode: SheetToolbarMode;
}

const EpochSelector = (props: EpochSelectorProps): JSX.Element => {
  const classes = useStyles();

  const [currentEpoch, setCurrentEpoch] = React.useState(-1);
  const [inputText, setInputText] = React.useState<string>();
  const [isHover, setIsHover] = React.useState(false);
  const [isEpochInputFocused, setIsEpochInputFocused] = React.useState(false);
  const [hasEpochInitialized, setHasEpochInitialized] = React.useState(true);

  const isNewEpochInvalid = () => {
    const isInvalid =
      Number.isNaN(currentEpoch) ||
      !chartRangeTools.isTimestampWithinPart(
        chartRangeTools.convertToTimestamp(currentEpoch),
      );

    if (!isInvalid && !hasEpochInitialized) setHasEpochInitialized(true);
    return isInvalid;
  };

  const jumpToEpoch = (targetEpoch: number) => {
    setInputText(targetEpoch.toString());
    sheetTools.selectEpoch(targetEpoch - 1);
    Analytics.track.navigation({ action: 'epochJump', toEpoch: targetEpoch });
  };

  const updateEpoch = () => {
    setCurrentEpoch(sheetTools.getSelectedEpoch() + 1);
    setInputText((sheetTools.getSelectedEpoch() + 1).toString());
  };

  React.useEffect(() => {
    Logger.log('[EpochSelector] useEffect');

    if (currentEpoch === -1) {
      updateEpoch();
    }

    const callbackId = [
      EventService.subscribe('CurrentEpochChanged', () => {
        updateEpoch();
      }),
      EventService.subscribe('Keyboard.KeyPressed', (event: KeyboardEvent) => {
        if (KeyboardManager.isEventThisKeyCode(event, KeyCode.Enter)) {
          Logger.log('[EpochSelector] KeyboardShortcut.Enter');
          if (isEpochInputFocused) {
            Logger.log('[EpochSelector] isEpochInputFocused');
            if (isNewEpochInvalid()) {
              Logger.log('[EpochSelector] newEpoch is invalid!', currentEpoch);
              setCurrentEpoch(sheetTools.getSelectedEpoch() + 1);
              setInputText((sheetTools.getSelectedEpoch() + 1).toString());
            } else {
              Logger.log('[EpochSelector] selecting epoch:', currentEpoch);
              jumpToEpoch(currentEpoch);
            }
          }
        }
      }),
    ];

    return () => {
      Logger.log('[EpochSelector] useEffect cleanup');
      EventService.unsubscribe(callbackId);
    };
  });

  const onValueChange = (newValue: number) => {
    Logger.log('[EpochSelector] onValueChange. newValue:', newValue);
    setIsEpochInputFocused(false);
    SleepStageService.enableKeyBindings();
    chartEvents.enableNavigation();
    if (isNewEpochInvalid()) {
      setCurrentEpoch(sheetTools.getSelectedEpoch() + 1);
      setInputText((sheetTools.getSelectedEpoch() + 1).toString());
    } else {
      jumpToEpoch(newValue);
    }
  };

  const onValidateChange = (value: unknown): boolean => {
    Logger.log('[EpochSelector][TextField] onChange:', value);
    let valid = false;

    const isNumber = !Number.isNaN(value);
    if (isNumber) {
      const minEpoch = chartRangeTools.getFirstPartEpoch() + 1;
      const maxEpoch = chartRangeTools.getLastPartEpoch() + 1;

      const isEmpty = value === '';
      const number = parseInt(value as string, 10);
      const isPositive = number > 0;
      const noDecimals = Number.isInteger(number);

      Logger.log('[EpochSelector][TextField] isNumber:', isNumber);
      Logger.log('[EpochSelector][TextField] isPositive:', isPositive);
      Logger.log('[EpochSelector][TextField] noDecimals:', noDecimals);

      if (isEmpty) {
        setInputText('');
      } else if (isPositive && noDecimals) {
        if (number < minEpoch) {
          setCurrentEpoch(minEpoch);
          setInputText(number.toString());
        } else if (number >= minEpoch && number <= maxEpoch) {
          setCurrentEpoch(number);
          setInputText(number.toString());
          valid = true;
        } else {
          setCurrentEpoch(maxEpoch);
          setInputText(number.toString());
        }
      }
    }

    return valid;
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseUp"
      touchEvent="onTouchStart"
      onClickAway={() => {
        if (isEpochInputFocused) {
          Logger.log('[EpochSelector] ClickAwayListener');
          onValueChange(currentEpoch);
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
        }
      }}
    >
      <FormControl
        onBlur={() => onValueChange(currentEpoch)}
        onFocus={() => {
          Logger.log('[EpochSelector] onFocus');
          setIsEpochInputFocused(true);
          chartEvents.disableNavigation();
          SleepStageService.disableKeyBindings();
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Paper
          className={clsx([classes.paper, classes.epoch], {
            [classes.loading]: !hasEpochInitialized,
            [classes.minimized]: props.toolbarMode === 'Hidden',
            [classes.minimal]: props.toolbarMode === 'Minimal',
          })}
        >
          {isNewEpochInvalid() && !hasEpochInitialized ? (
            <Skeleton className={classes.epochSkeleton} />
          ) : (
            <>
              <TextField
                color="secondary"
                type="number"
                value={inputText}
                className={classes.textField}
                InputProps={{
                  className: classes.inputContainer,
                  disableUnderline: true,
                  style: {
                    width: `calc(${currentEpoch.toString().length}ch + 20px)`,
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  className: classes.input,
                }}
                onChange={(event) => {
                  onValidateChange(event.target.value);
                }}
                onFocus={() => {
                  Logger.log('[EpochSelector] onFocus');
                  setIsEpochInputFocused(true);
                  chartEvents.disableNavigation();
                  SleepStageService.disableKeyBindings();
                }}
              />
              <Fade
                in={
                  (isHover || isEpochInputFocused) &&
                  props.toolbarMode === 'Full'
                }
              >
                <div
                  data-cy="EpochPickerArrowUp"
                  className={clsx(classes.arrow, classes.arrowUp, {
                    [classes.disabled]:
                      currentEpoch === chartRangeTools.getLastPartEpoch() + 1,
                  })}
                  onClick={() => {
                    const newValue = currentEpoch + 1;
                    const valid = onValidateChange(newValue);
                    if (valid) {
                      onValueChange(newValue);
                    }
                  }}
                >
                  <ArrowDropUpIcon fontSize="small" />
                </div>
              </Fade>
              <Fade
                in={
                  (isHover || isEpochInputFocused) &&
                  props.toolbarMode === 'Full'
                }
              >
                <div
                  data-cy="EpochPickerArrowDown"
                  className={clsx(classes.arrow, classes.arrowDown, {
                    [classes.disabled]:
                      currentEpoch === chartRangeTools.getFirstPartEpoch() + 1,
                  })}
                  onClick={() => {
                    const newValue = currentEpoch - 1;
                    const valid = onValidateChange(newValue);
                    if (valid) {
                      onValueChange(newValue);
                    }
                  }}
                >
                  <ArrowDropDownIcon fontSize="small" />
                </div>
              </Fade>
            </>
          )}
        </Paper>
      </FormControl>
    </ClickAwayListener>
  );
};

export default EpochSelector;
