import React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import Draggable from 'react-draggable';
import _ from 'underscore';

import { fade, makeStyles, Tooltip } from '@material-ui/core';
import HeightIcon from '@material-ui/icons/Height';

import { SignalEventDetailData } from '../../interfaces/signal-event-detail-props';
import AnalysisPeriodsService from '../../services/analysisPeriodsService';
import EventService from '../../services/eventService';
import ScoringService from '../../services/scoringService';
import Logger from '../../utils/logger';
import { generateStripedGradient, ZIndex } from '../../utils/theme';
import eventChartTools from '../Chart/SignalChart/eventChartTools';
import { RecordingPartData } from './OverviewParts';
import OverviewPartHoverHighlight from './OverviewPartHoverHighlight';
import chartRangeTools from '../SignalSheet/chartRangeTools';
import { OverviewPartMenuPos } from './OverviewPartDetailSecondaryMenu';
import { OverlayArea } from '../Chart/SignalChart/interfaces/event-chart-tools';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 140, // width of the chart name sidebar
    right: 0,
    bottom: 40, // height of the xAxis chart
    borderLeft: `1px solid ${theme.colors.chart.grid}`,
  },
  overlayPart: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    border: `2px solid ${fade('#9e9e9e', 0.8)}`,
    borderTop: 'none',
    borderBottom: 'none',
  },
  overlayPartContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  outsidePeriod: {
    background: `linear-gradient(0deg, ${fade('#9e9e9e', 0.5)} 0%, ${fade(
      '#9e9e9e',
      0.15,
    )} 100%);`,
    border: 'none',
    cursor: 'default',
  },
  exclusionZone: {
    background: generateStripedGradient('#9e9e9e', 0.05, 0.25),
  },
  handleBarContainer: {
    height: '100%',
    cursor: 'grab',
    width: 10,
    position: 'absolute',
    top: 0,
    left: -6,
    zIndex: ZIndex.AboveBaseline,
    display: 'flex',
    justifyContent: 'center',

    '&:hover': {
      '& $handle': {
        opacity: 1,
      },
    },
  },
  handleBarContainerRight: {
    right: -6,
    left: 'initial',
  },
  periodTimestamp: {
    position: 'absolute',
    bottom: 5,
    left: -2,
  },
  bar: {
    background: fade(theme.palette.primary.main, 0.5),
    width: 2,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  handle: {
    marginTop: theme.spacing(-1),
    opacity: 0,
    transition: '0.1s all linear',
  },
  icon: {
    margin: theme.spacing(0.5),
    borderRadius: theme.spacing(2),
    backgroundColor: fade(theme.palette.primary.main, 0.5),
    transform: 'rotate(90deg)',
    color: theme.palette.primary.contrastText,
    fontSize: 16,
  },
  tooltip: {
    marginTop: theme.spacing(1),
    maxWidth: 60,
    textAlign: 'center',
  },
  readOnly: {
    cursor: 'default',

    '&:hover': {
      '& $handle': {
        opacity: 0,
      },
    },
  },
}));

interface OverviewPartAnalysisPeriodProps {
  part: RecordingPartData;
  handleMenuOpen?: (pos: OverviewPartMenuPos) => void;
}

const OverviewPartAnalysisPeriod = (
  props: OverviewPartAnalysisPeriodProps,
): JSX.Element => {
  const classes = useStyles();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [overlayParts, setOverlayParts] = React.useState<OverlayArea[]>([]);

  const [isReadOnlyScoring, setIsReadOnlyScoring] = React.useState(
    ScoringService.isReadOnly(),
  );
  const [currentTimeDiff, setCurrentTimeDiff] = React.useState(0);

  const [hoverLeft, setHoverLeft] = React.useState<number>();

  const processAnalysisPeriods = (): OverlayArea[] => {
    const periodEvents = AnalysisPeriodsService.retrievePeriodEvents();
    const analysisPeriods =
      AnalysisPeriodsService.convertEventsToPeriods(periodEvents);
    if (analysisPeriods) {
      const partStartTime = new Date(props.part.startTime).valueOf();
      const partEndTime = new Date(props.part.endTime).valueOf();
      const analysisPeriodsForThisPart = analysisPeriods.filter((period) => {
        const periodStart = new Date(period.beginning).valueOf();
        const periodEnd = new Date(period.end).valueOf();

        const isWithin =
          (periodStart >= partStartTime && periodStart <= partEndTime) ||
          (periodEnd >= partStartTime && periodEnd <= partEndTime);

        return isWithin;
      });

      const containerWidth = containerRef?.current?.clientWidth || 0;
      const newOverlayParts = analysisPeriodsForThisPart
        ? AnalysisPeriodsService.preparePeriodsForDisplay(
            analysisPeriodsForThisPart,
            props.part.startTime,
            props.part.endTime,
            containerWidth,
          )
        : [];

      Logger.log('OverviewPartAnalysisPeriod', newOverlayParts);
      return newOverlayParts;
    }
    return [];
  };

  const processInvalidSignals = (): OverlayArea[] => {
    const partStartTime = new Date(props.part.startTime).valueOf();
    const partEndTime = new Date(props.part.endTime).valueOf();

    const data: OverlayArea[] = [];
    const events = ScoringService.retrieveMarkersByType(['signal-invalid']);
    const containerWidth = containerRef?.current?.clientWidth || 0;

    Logger.log('[OverviewPartAnalysisPeriod] events', events);

    events.forEach((event) => {
      if (
        eventChartTools.isMarkerWithinTimePeriod(
          event,
          partStartTime,
          partEndTime,
        )
      ) {
        data.push({
          start: event.start,
          end: event.end,
          position: eventChartTools.calculateOverlayPosition(
            partStartTime,
            partEndTime,
            event.start,
            event.end,
            containerWidth,
          ),
          isExcluded: true,
        });
      }
    });
    Logger.log('[OverviewPartAnalysisPeriod] invalid signal events', data);
    return data;
  };

  const onRedraw = _.throttle(() => {
    Logger.log('OverviewPartAnalysisPeriod onRedraw', props.part);
    if (props.part) {
      const newOverlayParts = [
        ...processAnalysisPeriods(),
        ...processInvalidSignals(),
      ];
      setOverlayParts(newOverlayParts);
    }
  }, 50);

  const getTimeDiff = (
    marker: SignalEventDetailData,
    newLeft: number,
  ): number => {
    const containerWidth = containerRef?.current?.clientWidth || 0;
    const partStartTime = new Date(props.part.startTime).valueOf();
    const partEndTime = new Date(props.part.endTime).valueOf();

    const timePerPixel = (partEndTime - partStartTime) / containerWidth;
    const newTimestamp = timePerPixel * newLeft + partStartTime;

    let timeDiff = newTimestamp - marker.start;
    timeDiff = eventChartTools.removeTimestampPrecision(timeDiff);
    return timeDiff;
  };

  const onPeriodChange = (marker: SignalEventDetailData, newLeft: number) => {
    const timeDiff = getTimeDiff(marker, newLeft);
    setCurrentTimeDiff(0);

    const updatedEvent: SignalEventDetailData = {
      ...marker,
      start: marker.start + timeDiff,
      end: marker.end + timeDiff,
    };
    Logger.log('[OverviewPartAnalysisPeriod] updatedEvent:', updatedEvent);

    ScoringService.updateEvent(updatedEvent, {
      send: true,
      local: true,
      operation: 'Move',
      diff: timeDiff,
      overrideMarkerInsideValidation: true,
    });
  };

  const getTooltipText = (timestamp: number): string => {
    const title = `#${chartRangeTools.convertToEpoch(timestamp) + 1}\n${moment(
      timestamp,
    ).format('HH:mm:ss')}`;

    return title;
  };

  React.useEffect(() => {
    Logger.log('OverviewPartAnalysisPeriod useEffect');

    window.addEventListener('resize', onRedraw);
    const cbId = [
      EventService.subscribe(['ScoringChanged', 'WindowResize'], onRedraw),
      EventService.subscribe<boolean>(['ScoringReadOnly'], (isReadOnly) =>
        setIsReadOnlyScoring(isReadOnly),
      ),
    ];
    setTimeout(() => onRedraw(), 500);

    return () => {
      window.removeEventListener('resize', onRedraw);
      EventService.unsubscribe(cbId);
    };
  }, [props.part]);

  return (
    <div
      ref={containerRef}
      className={classes.root}
      onMouseLeave={() => setHoverLeft(() => undefined)}
      onMouseMove={(e) => {
        // Logger.log('[OverviewPartAnalysisPeriod] event.target', e.nativeEvent);
        const currentTargetRect = e.currentTarget.getBoundingClientRect();
        const eventOffsetX = e.pageX - currentTargetRect.left;
        setHoverLeft(eventOffsetX);
      }}
    >
      {overlayParts.map((period, index) => (
        <div
          data-cy={
            period.isExcluded === true ? 'InvalidPeriod' : 'OverviewPeriod'
          }
          key={[
            period.start,
            period.end,
            period.partId,
            index,
            isReadOnlyScoring,
          ].join()}
          className={clsx(classes.overlayPart, {
            [classes.outsidePeriod]: period.partId === undefined,
            [classes.exclusionZone]: period.isExcluded,
          })}
          style={{
            // 2px offset because of the border width
            left: period.position.left - 2,
            width: period.position.width + 2,
          }}
        >
          <div className={classes.overlayPartContainer}>
            {period.analysisPeriod && containerRef.current && (
              <>
                <Draggable
                  axis="x"
                  bounds={{
                    left:
                      containerRef.current.clientLeft - period.position.left,
                    right: period.position.width - 5,
                  }}
                  offsetParent={containerRef.current as HTMLElement}
                  onDrag={(e, data) => {
                    if (period.analysisPeriod && containerRef.current) {
                      const newLeft = period.position.left + data.x;
                      const timeDiff = getTimeDiff(
                        period.analysisPeriod.startMarker,
                        newLeft,
                      );
                      setCurrentTimeDiff(timeDiff);
                    }
                  }}
                  onStop={(e, data) => {
                    if (period.analysisPeriod && containerRef.current) {
                      const newLeft = period.position.left + data.x;
                      onPeriodChange(
                        period.analysisPeriod.startMarker,
                        newLeft,
                      );
                    }
                  }}
                  disabled={isReadOnlyScoring}
                >
                  <Tooltip
                    title={getTooltipText(period.start + currentTimeDiff)}
                    classes={{ tooltip: classes.tooltip }}
                    arrow
                  >
                    <div
                      data-cy={`Overview-AnalysisStartPart${props.part.partId}`}
                      className={clsx(classes.handleBarContainer, {
                        [classes.readOnly]: isReadOnlyScoring,
                      })}
                      onMouseMove={(e) => {
                        setHoverLeft(() => undefined);
                        e.stopPropagation();
                      }}
                    >
                      <div className={classes.bar}>
                        <div className={classes.handle}>
                          <HeightIcon
                            className={classes.icon}
                            fontSize="small"
                          />
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </Draggable>

                <Draggable
                  axis="x"
                  bounds={{
                    left: -period.position.width + 5,
                    right:
                      containerRef.current.clientWidth -
                      (period.position.left + period.position.width),
                  }}
                  onDrag={(e, data) => {
                    if (period.analysisPeriod) {
                      const newLeft =
                        period.position.left + period.position.width + data.x;
                      const timeDiff = getTimeDiff(
                        period.analysisPeriod.endMarker,
                        newLeft,
                      );
                      setCurrentTimeDiff(timeDiff);
                    }
                  }}
                  onStop={(e, data) => {
                    const newLeft =
                      period.position.left + period.position.width + data.x;
                    if (period.analysisPeriod) {
                      onPeriodChange(period.analysisPeriod.endMarker, newLeft);
                    }
                  }}
                  disabled={isReadOnlyScoring}
                >
                  <Tooltip
                    title={getTooltipText(period.end + currentTimeDiff)}
                    classes={{ tooltip: classes.tooltip }}
                    arrow
                  >
                    <div
                      data-cy={`Overview-AnalysisStopPart${props.part.partId}`}
                      className={clsx(
                        classes.handleBarContainer,
                        classes.handleBarContainerRight,
                        {
                          [classes.readOnly]: isReadOnlyScoring,
                        },
                      )}
                      onMouseMove={(e) => {
                        setHoverLeft(() => undefined);
                        e.stopPropagation();
                      }}
                    >
                      <div className={classes.bar}>
                        <div className={classes.handle}>
                          <HeightIcon
                            className={classes.icon}
                            fontSize="small"
                          />
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </Draggable>
              </>
            )}
          </div>
        </div>
      ))}

      <OverviewPartHoverHighlight
        part={props.part}
        hoverLeft={hoverLeft}
        containerRef={containerRef}
        handleMenuOpen={props.handleMenuOpen}
      />
    </div>
  );
};

export default OverviewPartAnalysisPeriod;
