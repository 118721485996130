import { makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import themeProvider from '../../utils/theme';
import { SleepStageChartDataItem } from './OverviewSleepWidget';

const useStyles = makeStyles((theme) => ({
  table: {
    border: 'none',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '15px',
    gridAutoRows: '20px',
    marginRight: theme.spacing(2),
    alignSelf: 'center',
    alignItems: 'center',
    '@media print': {
      color: theme.palette.getContrastText(
        themeProvider.getType('LIGHT').palette.background.paper,
      ),
    },
  },
  tableRow: { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' },
  tableCell: {
    padding: theme.spacing(0, 1),
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
  tableHeaderCell: {
    fontSize: 12,
  },
  oddCell: {
    background: theme.palette.secondary.main,
    '@media print': {
      background: themeProvider.getType('LIGHT').palette.background.paper,
    },
  },
  roundedLeft: {
    borderRadius: theme.spacing(0.5, 0, 0, 0.5),
  },
  roundedRight: {
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
  },
  hide: {
    display: 'none',
    '@media print': {
      display: 'grid',
    },
  },
}));

interface OverviewSleepWidgetTableProps {
  sleepData: SleepStageChartDataItem[];
}

const OverviewSleepWidgetTable = (
  props: OverviewSleepWidgetTableProps,
): JSX.Element => {
  const classes = useStyles();
  const smallScreen = useMediaQuery('(max-width:1000px)');

  return (
    <div className={clsx(classes.table, { [classes.hide]: smallScreen })}>
      <div className={clsx(classes.tableCell, classes.tableHeaderCell)} />
      <Typography
        variant="caption"
        align="right"
        className={clsx(classes.tableCell, classes.tableHeaderCell)}
      >
        Duration
      </Typography>
      <Typography
        variant="caption"
        align="right"
        className={clsx(classes.tableCell, classes.tableHeaderCell)}
      >
        Percentage
      </Typography>

      {props.sleepData.map((stage, index) => (
        <>
          <Typography
            className={clsx(classes.tableCell, {
              [classes.oddCell]: index % 2 === 0,
              [classes.roundedLeft]: index % 2 === 0,
            })}
          >
            {stage.name}
          </Typography>
          <Typography
            className={clsx(classes.tableCell, {
              [classes.oddCell]: index % 2 === 0,
            })}
            align="right"
          >
            {stage.count / 1000 / 60} m
          </Typography>
          <Typography
            className={clsx(classes.tableCell, {
              [classes.oddCell]: index % 2 === 0,
              [classes.roundedRight]: index % 2 === 0,
            })}
            align="right"
          >
            {stage.percentage.toFixed(1)}%
          </Typography>
        </>
      ))}
    </div>
  );
};

export default OverviewSleepWidgetTable;
