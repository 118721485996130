import moment from 'moment-timezone';
import { ChartRangeTools, ChartExtremes } from './interfaces/chart-range-tools';
import { ChartRange } from './interfaces/chart-range';
import Logger from '../../utils/logger';
import { EpochRange } from '../Chart/SignalChart/interfaces/chart-tools';
import sheetTools from './sheetTools';
import { RecordingId } from '../../queries/recording';
import {
  SheetToolbarZoomRange,
  ZoomRangeOption,
} from '../../interfaces/sheet-toolbar-props';
import { CurrentExtremesUpdatedFunction } from '../Chart/NavigatorChart/NavigatorOverlay';
import EventService from '../../services/eventService';

let currentRecordingId: RecordingId;
let recordingStartTime = -1;
let recordingEndTime = -1;
let selectedPartStartTime = -1;
let selectedPartEndTime = -1;
let requestedEpochs: boolean[] = []; // Array storing epochs of 30 seconds
let signalsWithDataPerEpoch: number[] = []; // Array storing epochs of 30 seconds
let currentRange: ChartRange | undefined;
let currentExtremes: ChartExtremes = {
  min: 0,
  max: 0,
};
let currentTicks: number[] = [];

let currentExtremesUpdated: CurrentExtremesUpdatedFunction = () => null;

const chartRangeTools: ChartRangeTools = {
  initializeRangeTools: (recordingId) => {
    Logger.log('[initializeRangeTools] Initializing');
    requestedEpochs = [];
    signalsWithDataPerEpoch = [];

    if (recordingId !== currentRecordingId) {
      currentRecordingId = recordingId;

      currentExtremes = {
        min: 0,
        max: 0,
      };
      setTimeout(() => currentExtremesUpdated(currentExtremes), 0);
      currentRange = undefined;
    }
  },
  validForRecordingId: () => currentRecordingId,
  setRecordingStartTime: (time: string) => {
    Logger.log('[setRecordingStartTime] Setting time to: %s', time);
    const adjustedTime = chartRangeTools.adjustToEpoch(moment(time).valueOf());
    Logger.log(
      '[setRecordingStartTime] But adjusting to epoch first: %s',
      adjustedTime,
    );
    recordingStartTime = adjustedTime;
  },
  getRecordingStartTime: (): number => {
    // Logger.log('[getRecordingStartTime] Requesting start time: %d', startTime);
    return recordingStartTime;
  },
  setRecordingEndTime: (time: string) => {
    Logger.log('[setRecordingEndTime] Setting time to: %s', time);
    const adjustedTime = chartRangeTools.adjustToEpoch(moment(time).valueOf());
    Logger.log(
      '[setRecordingEndTime] But adjusting to epoch first: %s',
      adjustedTime,
    );
    recordingEndTime = adjustedTime;
  },
  getRecordingEndTime: (): number => {
    // Logger.log('[getRecordingEndTime] Requesting end time: %d', startTime);
    return recordingEndTime;
  },

  setPartStartTime: (time: string) => {
    Logger.log('[setPartStartTime] Setting time to: %s', time);
    const adjustedTime = chartRangeTools.adjustToEpoch(moment(time).valueOf());
    Logger.log(
      '[setPartStartTime] But adjusting to epoch first: %s',
      adjustedTime,
    );
    selectedPartStartTime = adjustedTime;
  },
  getPartStartTime: (): number => {
    // Logger.log('[getStartTime] Requesting start time: %d', startTime);
    return selectedPartStartTime;
  },
  setPartEndTime: (time: string) => {
    Logger.log('[setPartEndTime] Setting time to: %s', time);
    const adjustedTime = chartRangeTools.adjustToEpoch(moment(time).valueOf());
    Logger.log(
      '[setPartEndTime] But adjusting to epoch first: %s',
      adjustedTime,
    );
    selectedPartEndTime = adjustedTime;
  },
  getPartEndTime: (): number => {
    // Logger.log('[getEndTime] Requesting end time: %d', startTime);
    return selectedPartEndTime;
  },
  getLastEpoch: () => chartRangeTools.convertToEpoch(recordingEndTime),
  getFirstPartEpoch: () =>
    chartRangeTools.convertToEpoch(selectedPartStartTime),
  getLastPartEpoch: () => chartRangeTools.convertToEpoch(selectedPartEndTime),
  getCurrentDataRange: (): ChartRange | undefined => {
    return currentRange;
  },
  setCurrentDataRange: (newRange: ChartRange | undefined) => {
    Logger.log('[setCurrentDataRange] Setting new data range: ', newRange);
    currentRange = newRange;
  },
  getCurrentExtremes: (): ChartExtremes => {
    if (!currentExtremes) {
      Logger.error('[getCurrentExtremes] No current extremes are defined!');
    }
    return { ...currentExtremes };
  },
  getCurrentExtremesInEpoch: (): EpochRange => {
    const epochRange: EpochRange = {
      first: chartRangeTools.convertToEpoch(currentExtremes.min),
      last: chartRangeTools.convertToEpoch(currentExtremes.max),
    };
    return epochRange;
  },
  isEpochRangeWithinCurrentExtremes: (epochRange: EpochRange) => {
    const extremes = chartRangeTools.getCurrentExtremesInEpoch();

    const isWithin =
      extremes.first <= epochRange.last && epochRange.first <= extremes.last;

    /* Logger.debug(
      '[isEpochRangeWithinCurrentExtremes] Is range %d-%d within current extremes %d-%d?:',
      epochRange.first,
      epochRange.last,
      currentExtremes.first,
      currentExtremes.last,
      isWithin
    ); */

    return isWithin;
  },
  isTimestampRangeWithinCurrentDataRange: (min: number, max: number) => {
    const currentDataRange = chartRangeTools.getCurrentDataRange();

    if (currentDataRange) {
      const isWithin = currentExtremes.min <= min && max <= currentExtremes.max;

      return isWithin;
    }
    return false;
  },
  setCurrentExtremes: (min: number, max: number): void => {
    Logger.log(
      '[setCurrentExtremes] Setting current extremes to %d-%d',
      min,
      max,
    );
    currentExtremes = { min, max };
    currentExtremesUpdated(currentExtremes);
    EventService.dispatch('Sheet.ExtremesUpdated');
  },
  convertToEpoch(time: number): number {
    const epoch = Math.floor((time - recordingStartTime) / 1000 / 30);
    // Logger.log('[convertToEpoch] %f -> %f', time, epoch);
    return epoch;
  },
  convertToTimestamp(epoch: number): number {
    const time = Math.floor(recordingStartTime + epoch * 1000 * 30);
    // Logger.log('[convertToEpoch] %f -> %f', time, epoch);
    return time;
  },
  // TODO Unit Test this
  calculateRangeToLoad: (
    min: number,
    max: number,
    isPreload?: boolean,
  ): ChartRange | undefined => {
    const BUFFER_FACTOR = 3;
    const MINIMUM_AFFECTED_SECTORS = 6;

    let newRangeToLoad: ChartRange | undefined;
    const ignoreMinimumLimit = isPreload || false;

    Logger.log(
      '[calculateRangeToLoad] Range requested %s - %s',
      new Date(min).toISOString(),
      new Date(max).toISOString(),
    );
    Logger.log(
      '[calculateRangeToLoad] Seconds requested %d (%d mins)',
      (max - min) / 1000,
      (max - min) / 1000 / 60,
    );

    let firstEpoch = chartRangeTools.convertToEpoch(min);
    if (firstEpoch > 0) firstEpoch -= 1;

    let lastEpoch = chartRangeTools.convertToEpoch(max);
    const epochsToLoad = lastEpoch - firstEpoch;
    Logger.log('[calculateRangeToLoad] Epochs to load: %d', epochsToLoad);
    let amountOfEpochs = epochsToLoad * BUFFER_FACTOR;
    amountOfEpochs = amountOfEpochs < 30 ? 30 : amountOfEpochs;

    Logger.log(
      '[calculateRangeToLoad] Original amount of epochs: %d',
      amountOfEpochs,
    );

    let firstEpochTime: number | undefined;
    let lastEpochTime: number | undefined;

    Logger.log(
      '[calculateRangeToLoad] getRangeToLoad (%d - %d). Total of %d epochs.',
      firstEpoch,
      lastEpoch,
      amountOfEpochs,
    );

    const affectedSectors: number[] = [];

    // Try to fetch epochs ahead of the requested one
    for (let i = 0; i < amountOfEpochs; i++) {
      const currentEpoch = firstEpoch + i;
      if (!requestedEpochs[currentEpoch]) {
        affectedSectors.push(currentEpoch);
      }
    }

    // If only one is affected, the user is probably going backwards.
    // Trying to fetch epochs before the requested one
    if (affectedSectors.length === 1 || isPreload) {
      for (let i = 1; i < amountOfEpochs; i++) {
        const currentEpoch = affectedSectors[0] - i;
        if (!requestedEpochs[currentEpoch]) {
          affectedSectors.push(currentEpoch);
        }
      }
    }

    Logger.debug(
      '[calculateRangeToLoad] List of affected sectors: ',
      affectedSectors,
    );

    const areSectorsWithinCurrentExtremes =
      chartRangeTools.isEpochRangeWithinCurrentExtremes({
        first: affectedSectors[0] + 1,
        last: affectedSectors[affectedSectors.length - 1] + 1,
      });

    if (
      affectedSectors.length > MINIMUM_AFFECTED_SECTORS ||
      ignoreMinimumLimit ||
      areSectorsWithinCurrentExtremes
    ) {
      Logger.log('[calculateRangeToLoad] We are GO for marking sectors');
      affectedSectors
        .sort((a, b) => a - b)
        .forEach((epoch) => {
          requestedEpochs[epoch] = true;
          // Logger.debug('[calculateRangeToLoad] Marking as loaded the sector: %d', epoch);
        });

      firstEpochTime = chartRangeTools.convertToTimestamp(affectedSectors[0]);
      lastEpochTime = chartRangeTools.convertToTimestamp(
        affectedSectors[affectedSectors.length - 1] + 1,
      );
    } else {
      Logger.log(
        '[calculateRangeToLoad] Not enough sectors to load. Skipping!',
      );
    }

    Logger.log('[calculateRangeToLoad] done!');
    Logger.log('[calculateRangeToLoad] Original range was %d-%d', min, max);
    if (!firstEpochTime || !lastEpochTime) {
      Logger.log(
        '[calculateRangeToLoad] Nothing to fetch! %d-%d',
        firstEpochTime,
        lastEpochTime,
      );
    } else {
      Logger.log(
        '[calculateRangeToLoad] Range to fetch is: %d-%d',
        firstEpochTime,
        lastEpochTime,
      );
      Logger.log(
        '[calculateRangeToLoad] Original date: %s - %s',
        new Date(min).toISOString(),
        new Date(max).toISOString(),
      );
      Logger.log(
        '[calculateRangeToLoad] Date range is: %s - %s',
        new Date(firstEpochTime).toISOString(),
        new Date(lastEpochTime).toISOString(),
      );
      Logger.log(
        '[calculateRangeToLoad] Seconds requested %d (%d mins)',
        (lastEpochTime - firstEpochTime) / 1000,
        (lastEpochTime - firstEpochTime) / 1000 / 60,
      );

      firstEpoch = chartRangeTools.convertToEpoch(firstEpochTime);
      lastEpoch = chartRangeTools.convertToEpoch(lastEpochTime);
      Logger.log(
        '[calculateRangeToLoad] Epoch range: %s - %s',
        firstEpoch,
        lastEpoch,
      );

      const epochs = lastEpoch - firstEpoch;
      Logger.log('[calculateRangeToLoad] Total epochs: ', epochs);

      newRangeToLoad = {
        fromTime: firstEpochTime,
        fromEpoch: firstEpoch,
        toTime: lastEpochTime,
        toEpoch: lastEpoch,
        epochs,
      };
    }

    return newRangeToLoad;
  },
  calculateTicks: (min: number, max: number) => {
    Logger.log(
      '[calculateTicks] Starting ticks calculation: %s - %s',
      min,
      max,
    );
    let tick = min;
    let increment = 5000;

    Logger.log('[calculateTicks] Difference is: ', max - min);
    const diff = max - min;
    if (diff <= 30000) {
      Logger.log('[calculateTicks] Detected 30s range');
      increment = 5000;
    } else if (diff <= 60000) {
      Logger.log('[calculateTicks] Detected 1m range');
      increment = 10000;
    } else if (diff <= 60000 * 2) {
      Logger.log('[calculateTicks] Detected 2m range');
      increment = 15000;
    } else if (diff >= 60000 * 5) {
      Logger.log('[calculateTicks] Detected >5m range');
      increment = 60000;
    }

    if (max !== null && min !== null) {
      currentTicks = [];
      for (tick; tick - increment <= max; tick += increment) {
        currentTicks.push(tick);
      }
    }

    Logger.log('[calculateTicks] Ticks are:', currentTicks);
  },
  adjustToEpoch: (time: number) => {
    const roundedDownTimeSec = Math.floor(moment(time).second() / 30) * 30;
    const roundedDownTime = moment(time)
      .second(roundedDownTimeSec)
      .millisecond(0);

    return roundedDownTime.valueOf();
  },
  adjustRangeToEpoch: (min: number, max: number) => {
    const diff = max - min;
    Logger.log(
      '[adjustRangeToEpoch] Adjusting to epoch: %s - %s (diff: %s)',
      min,
      max,
      diff,
    );

    const roundedMin = chartRangeTools.adjustToEpoch(min);
    const roundedMax = roundedMin + diff;

    Logger.log(
      '[adjustRangeToEpoch] Rounded date: %s - %s',
      new Date(roundedMin).toISOString(),
      new Date(roundedMax).toISOString(),
    );

    return {
      min: roundedMin,
      max: roundedMax,
    };
  },
  adjustToCurrentZoomRange: (min: number, max: number) => {
    let newMax = max;
    Logger.log(
      '[adjustToCurrentZoomRange] Adjusting to maximum: %s - %s (diff: %s)',
      min,
      newMax,
      newMax - min,
    );
    const currentZoomRange = sheetTools.getZoomRange();
    Logger.log(
      '[adjustToCurrentZoomRange] Current zoomRange is:',
      sheetTools.getZoomRange(),
    );

    if (newMax - min !== currentZoomRange) {
      newMax = min + currentZoomRange;
      Logger.log(
        '[adjustToCurrentZoomRange] New range needed! %s - %s (diff: %s)',
        min,
        newMax,
        newMax - min,
      );
    } else {
      Logger.log('[adjustToCurrentZoomRange] Nothing to do');
    }

    return newMax;
  },
  adjustToPartEdges: (min: number, max: number) => {
    // Logger.log('[adjustToPartEdges] Init');
    let newMin = min;
    let newMax = max;
    /* Logger.log('[adjustToPartEdges] Before: %d - %d', min, max);
    Logger.log(
      '[adjustToPartEdges] Before: %s - %s',
      new Date(min).toISOString(),
      new Date(max).toISOString()
    ); */

    const startTime = chartRangeTools.getPartStartTime();
    const endTime = chartRangeTools.getPartEndTime() + 30000;

    if (min < startTime) {
      // Logger.log('[adjustToPartEdges] min is before the recordingStart!');
      newMin = startTime;
      newMax = startTime + sheetTools.getZoomRange();
    }

    if (max > endTime) {
      // Logger.log('[adjustToPartEdges] max is after the recordingStart!');
      newMax = endTime;
      newMin = endTime - sheetTools.getZoomRange();
    }

    // Logger.log('[adjustToPartEdges]  After: %d - %d', newMin, newMax);
    // Logger.log(
    //   '[adjustToPartEdges]  After: %s - %s',
    //   new Date(newMin).toISOString(),
    //   new Date(newMax).toISOString()
    // );
    // Logger.log('[adjustToPartEdges] sheetTools.getZoomRange()', sheetTools.getZoomRange());

    return {
      min: newMin,
      max: newMax,
    };
  },
  getTicks: () => {
    return currentTicks;
  },
  getMsToMove: () => {
    const currentZoomRange = sheetTools.getZoomRange();
    const currentPageFlip = sheetTools.getPageFlip();

    switch (currentPageFlip) {
      case 'Epoch':
        return 30000;
      case 'Half':
        return currentZoomRange / 2;
      case 'Full':
        return currentZoomRange;
      default:
        return 0;
    }
  },
  getSignalsWithDataForEpoch: (epoch: number) => {
    if (!signalsWithDataPerEpoch[epoch]) signalsWithDataPerEpoch[epoch] = 0;
    return signalsWithDataPerEpoch[epoch];
  },
  incrementLoadedEpoch: (epochRange: EpochRange) => {
    for (let epoch = epochRange.first; epoch <= epochRange.last; epoch += 1) {
      if (!signalsWithDataPerEpoch[epoch]) signalsWithDataPerEpoch[epoch] = 0;
      signalsWithDataPerEpoch[epoch] += 1;
    }
  },
  getMaximumZoomRangeForPart: () => {
    const zoomRangeOptions = chartRangeTools.getZoomRangeOptions();
    const sortedOptions = zoomRangeOptions.sort((a, b) => a.ms - b.ms);
    let maximumZoomRange: ZoomRangeOption = sortedOptions[0];

    sortedOptions.forEach((option) => {
      if (chartRangeTools.isZoomRangeAvailable(option.value)) {
        maximumZoomRange = option;
      }
    });

    return maximumZoomRange;
  },
  // TODO Unit test this
  getMsByZoomRange: (newZoomRange: SheetToolbarZoomRange) =>
    chartRangeTools
      .getZoomRangeOptions()
      .find((option) => option.value === newZoomRange)?.ms || 30000,
  isZoomRangeAvailable: (zoomRange: SheetToolbarZoomRange) => {
    const selectedPartDuration = selectedPartEndTime - selectedPartStartTime;
    Logger.log(
      '[isZoomRangeAvailable] For %s and recording duration of %d',
      chartRangeTools.getMsByZoomRange(zoomRange),
      selectedPartDuration,
    );
    return selectedPartDuration >= chartRangeTools.getMsByZoomRange(zoomRange);
  },
  getZoomRangeOptions: () => [
    {
      valueText: '30',
      unitText: 'Secs',
      value: '30secs',
      ms: 30000,
    },
    {
      valueText: '1',
      unitText: 'Min',
      value: '1min',
      ms: 60000,
    },
    {
      valueText: '2',
      unitText: 'Min',
      value: '2min',
      ms: 2 * 60000,
    },
    {
      valueText: '5',
      unitText: 'Min',
      value: '5min',
      ms: 5 * 60000,
    },
    {
      valueText: '10',
      unitText: 'Min',
      value: '10min',
      ms: 10 * 60000,
    },
  ],
  getZoomRangeOptionByValue: (zoomRangeValue: SheetToolbarZoomRange) =>
    chartRangeTools
      .getZoomRangeOptions()
      .find((option) => option.value === zoomRangeValue),
  isTimestampWithinPart: (timestamp: number) => {
    const partStart = chartRangeTools.getPartStartTime();
    const partEnd = chartRangeTools.getPartEndTime() + 30000;

    return timestamp >= partStart && timestamp <= partEnd;
  },
  calculateDuration: (
    start: number,
    rawEnd: number,
    options: { short: boolean; ignoreSeconds?: boolean },
  ) => {
    const end = rawEnd === 0 ? start : rawEnd;
    const diff = end - start;
    const duration: string[] = [];

    let hours = Math.floor(diff / 1000 / 60 / 60);
    let minutes = Math.floor(diff / 1000 / 60) - hours * 60;
    let seconds = Math.round(((diff / 1000) % 60) * 10) / 10;

    if (seconds === 60) {
      minutes += 1;
      seconds = 0;
    }
    if (minutes === 60) {
      hours += 1;
      minutes = 0;
    }

    if (hours > 0) {
      const hoursText = options.short ? 'h' : ' h';
      duration.push(hours.toFixed(0) + hoursText);
    }

    if (minutes > 0) {
      const minutesText = options.short ? 'm' : ' min';
      duration.push(minutes.toFixed(0) + minutesText);
    }

    if (!options.ignoreSeconds && seconds > 0) {
      const secondsText = options.short ? 's' : ' sec';
      duration.push(seconds + secondsText);
    }

    const milliseconds = diff % 1000;
    if (milliseconds > 0) {
      if (duration.length === 0) {
        const msText = options.short ? 'ms' : ' ms';
        duration.push(milliseconds.toFixed(0) + msText);
      }
    } else if (duration.length === 0) {
      const secondsText = options.short ? 's' : ' sec';
      duration.push(`0${secondsText}`);
    }

    return duration.join(' ');
  },
  registerSetCurrentExtremesFunction: (fn: CurrentExtremesUpdatedFunction) => {
    currentExtremesUpdated = fn;
  },
};

export default chartRangeTools;
