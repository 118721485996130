import React from 'react';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { Grow, makeStyles } from '@material-ui/core';
import EventService from '../../../services/eventService';
import ScoringService from '../../../services/scoringService';
import Logger from '../../../utils/logger';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
    fontSize: 15,
    marginBottom: -3,
    color:
      theme.id === 'LIGHT'
        ? theme.palette.primary.main
        : theme.palette.primary.contrastText,
  },
}));

interface SubmittedPartIconProps {
  partId: number;
}

function SubmittedPartIcon(props: SubmittedPartIconProps): JSX.Element {
  const classes = useStyles();

  const [isSubmittedPart, setIsSubmittedPart] = React.useState(
    props.partId === ScoringService.getSubmittedPartId(),
  );

  Logger.log('[SubmittedPartIcon] partId', props.partId);
  Logger.log(
    '[SubmittedPartIcon]  ScoringService.getSubmittedPartId()',
    ScoringService.getSubmittedPartId(),
  );

  React.useEffect(() => {
    const cbId = EventService.subscribe('ScoringSubmittedPart', (partId) => {
      Logger.log('[SubmittedPartIcon] partId', partId);
      setIsSubmittedPart(partId === props.partId);
    });

    return () => {
      EventService.unsubscribe(cbId);
    };
  });

  return (
    <Grow appear={false} in={isSubmittedPart} mountOnEnter unmountOnExit>
      <AssignmentTurnedInIcon className={classes.icon} />
    </Grow>
  );
}

export default SubmittedPartIcon;
