import React, { useState } from 'react';
import { makeStyles, Typography, Tooltip, Grow } from '@material-ui/core';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import DoneIcon from '@material-ui/icons/Done';
import { SnackbarKey } from 'notistack';
import EventService from '../../services/eventService';
import ScoringService, {
  MutationQueueStatus,
} from '../../services/scoringService';
import Logger from '../../utils/logger';
import NotificationService from '../../services/notificationService';
import { NotificationKey } from '../../interfaces/notification-service';
import TabSyncService from '../../services/tabSyncService';
import { MutationQueue } from '../../interfaces/tab-sync-service';
import ActionOrDismiss from '../ActionOrDismiss/ActionOrDismiss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    margin: theme.spacing(0, 0.4),
  },
  icon: { position: 'relative' },
  text: {
    padding: theme.spacing(0.3, 1),
  },
  status: {
    position: 'absolute',
    fontSize: 10,
    top: 6,
    left: 5,
  },
  errorIcon: {
    position: 'absolute',
    fontSize: 10,
    top: 5,
    left: 5,
  },
  tooltip: {
    maxWidth: 250,
  },
}));

const SavedStatus = (): JSX.Element => {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [done, setDone] = useState(true);
  const [failed, setFailed] = useState(false);
  const [showSavedText, setShowSavedText] = useState(false);
  const [isReadOnlyScoring, setIsReadOnlyScoring] = React.useState(
    ScoringService.isReadOnly(),
  );

  const [, /* restoringNotificationKey */ setRestoringNotificationKey] =
    React.useState<NotificationKey>();

  const [, /* restoringOtherScoringDone */ setRestoringOtherScoringDone] =
    React.useState(false);

  const onRestoringMutationQueue = (length: number): void => {
    if (length > 0) {
      const key = NotificationService.send(
        `Found unsaved changes from a previous session. Saving...`,
        { variant: 'info', action: 'dismiss' },
      );
      setRestoringNotificationKey(key);
      ScoringService.setRestoreMutationQueueLength(0);
    }
  };

  const handleFindOtherMutationQueues = () => {
    setRestoringOtherScoringDone((isDone) => {
      if (!isDone) {
        TabSyncService.getOtherMutationQueue();
      }
      return true;
    });
  };

  const createOpenOrDiscardActions =
    (mutationQueue: MutationQueue) => (key: SnackbarKey) =>
      (
        <ActionOrDismiss
          actionText="Open"
          actionOnClick={() => {
            window.open(
              [
                '/recording',
                '?id=',
                mutationQueue.recordingId,
                `&scoringId=${mutationQueue.scoringId}`,
              ].join(''),
            );
            NotificationService.close(key);
          }}
          dismissText="Discard"
          dismissOnClick={() => {
            TabSyncService.discardOtherMutationQueue();
            NotificationService.close(key);
          }}
        />
      );

  React.useEffect(() => {
    const cbId = [
      EventService.subscribe(
        'ScoringMutationsStatus',
        (data: MutationQueueStatus) => {
          Logger.debug('[SavedStatus] data:', data);
          if (data) {
            setSaving(() => data.inProgress);
            setFailed(() => data.failed);

            if (data.queueLength === 0 && !data.inProgress) {
              setDone((wasDone) => {
                if (!wasDone) {
                  setShowSavedText(true);
                  setTimeout(() => setShowSavedText(false), 3000);
                }
                return true;
              });

              setRestoringNotificationKey((key) => {
                if (key !== undefined && key !== null) {
                  NotificationService.send(
                    `All restored changes have been saved successfully!`,
                    {
                      variant: 'success',
                      persist: true,
                      action: 'dismiss',
                    },
                  );
                  NotificationService.close(key);
                }
                handleFindOtherMutationQueues();

                return undefined;
              });
            } else {
              setShowSavedText(false);
              setDone(false);
            }
          }
        },
      ),
      EventService.subscribe<boolean>(
        'ScoringReadOnly',
        (isReadOnly: boolean) => setIsReadOnlyScoring(isReadOnly),
      ),
      EventService.subscribe<number>(
        'Scoring.RestoreMutationQueueLength',
        (length) => {
          onRestoringMutationQueue(length);
        },
      ),
      EventService.subscribe<MutationQueue>(
        'Scoring.OtherMutationQueueWaiting',
        (mutationQueue) => {
          NotificationService.send(
            `Found unsaved changes for another scoring. Do you want to open it or discard the changes?`,
            {
              variant: 'warning',
              persist: true,
              customAction: createOpenOrDiscardActions(mutationQueue),
            },
          );
          setRestoringOtherScoringDone(true);
        },
      ),
      EventService.subscribe('Scoring.MutationQueueClearedReadOnly', () => {
        NotificationService.send(
          `Unsaved changes were discarded because the scoring is in read-only mode`,
          {
            variant: 'warning',
            persist: true,
            action: 'dismiss',
          },
        );
      }),
    ];

    onRestoringMutationQueue(ScoringService.restoreMutationQueueLength());
    handleFindOtherMutationQueues();

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, []);

  return (
    <Grow in={!isReadOnlyScoring} mountOnEnter unmountOnExit>
      <div className={classes.root}>
        <Typography
          component="legend"
          variant="caption"
          className={classes.text}
        >
          {saving && !failed && 'Saving...'}
          {showSavedText && 'Saved!'}
          {failed && 'Retrying...'}
        </Typography>
        <Tooltip
          title={
            !failed
              ? 'Scoring changes are being saved automatically.\n There is no need to manually save.'
              : 'There was a problem saving the scoring. We will keep trying, but it is safe to close the tab. If so, the changes will be saved the next time a recording is opened.'
          }
          classes={{ tooltip: classes.tooltip }}
          style={{ cursor: 'pointer' }}
          arrow
        >
          <Typography className={classes.icon}>
            {done && (
              <>
                <CloudQueueIcon fontSize="small" />
                <DoneIcon
                  data-cy="StatusCloudSaved"
                  fontSize="small"
                  className={classes.status}
                />
              </>
            )}
            {saving && !failed && (
              <>
                <CloudUploadIcon data-cy="StatusCloudSaving" fontSize="small" />
              </>
            )}
            {failed && (
              <>
                <CloudQueueIcon fontSize="small" />
                <PriorityHighIcon
                  data-cy="StatusCloudSavingFailed"
                  fontSize="small"
                  className={classes.errorIcon}
                />
              </>
            )}
          </Typography>
        </Tooltip>
      </div>
    </Grow>
  );
};

export default SavedStatus;
