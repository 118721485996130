import React from 'react';
import { makeStyles } from '@material-ui/core';
import SavedStatus from './SavedStatus';
import ConnectedStatus from './ConnectedStatus';
import PositionEventsStatus from './PositionEventsStatus';
import SleepStageStatus from './SleepStageStatus';
import FeatureToggle from '../Shared/FeatureToggle';
import ScoringReadOnlyStatus from './ScoringReadOnlyStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    display: 'flex',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    opacity: 0.6,
  },
}));

const StatusBar = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SavedStatus />
      <FeatureToggle enabledFor="development">
        <PositionEventsStatus />
      </FeatureToggle>
      <FeatureToggle enabledFor="development">
        <SleepStageStatus />
      </FeatureToggle>
      <FeatureToggle enabledFor="development">
        <ConnectedStatus />
      </FeatureToggle>
      <ScoringReadOnlyStatus />
    </div>
  );
};

export default StatusBar;
