import React from 'react';
import _ from 'underscore';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Logger from '../../utils/logger';
import { AttachedFile, FileDefinition } from './interfaces/uploader-tools';
import uploaderTools from './utils/uploaderTools';
import NotificationService from '../../services/notificationService';
import Analytics from '../../services/analytics';

const useStyles = makeStyles((theme: Theme) => ({
  open: {
    margin: theme.spacing(1, 0),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  icon: {
    marginLeft: 4,
  },
}));

interface SelectFilesProps {
  fileDefinitions: FileDefinition[];
  setAttachedFiles: (attachedFiles: AttachedFile[]) => void;
  setReadyToSubmit: (readyToSubmit: boolean) => void;
  uploadInProgress: boolean;
}

const SelectFiles = (props: SelectFilesProps): JSX.Element => {
  const classes = useStyles();

  const onSelectFiles = _.throttle(
    () => {
      Analytics.track.event('SELECT_FILES_CLICK');
    },
    500,
    { trailing: false },
  );

  return (
    <>
      {!props.uploadInProgress ? (
        <Button
          className={classes.open}
          variant="contained"
          color="secondary"
          component="label"
          onClick={onSelectFiles}
          data-cy="selectFilesBtn"
        >
          Select files
          <input
            style={{ display: 'none' }}
            type="file"
            data-cy="selectFilesInput"
            onChange={(e) => {
              Analytics.track.event('SELECTED_FILES', {
                totalFiles: e.target.files?.length || 0,
              });

              const attachedFiles: AttachedFile[] = [];
              const uploadType = uploaderTools.getUploadType(e.target.files);
              const requiredFilesTotal = props.fileDefinitions.filter(
                (def) => def.requiredFor === uploadType,
              ).length;

              if (e.target.files) {
                if (uploadType === 'Exploded' || uploadType === 'Raw') {
                  for (let i = 0; i < e.target.files.length; i++) {
                    const file = e.target.files.item(i);
                    if (file) {
                      const definition = props.fileDefinitions.find(
                        (def) =>
                          (def.attachFor === uploadType ||
                            def.attachFor === 'Both') &&
                          uploaderTools.filenameContains(
                            file.name,
                            def.filename,
                          ),
                      );
                      if (definition) {
                        attachedFiles.push({
                          file,
                          definition,
                          remainingParts: 0,
                          totalParts: 0,
                          progress: 0,
                          uploading: false,
                          uploadComplete: false,
                        });
                      }
                    }
                  }
                } else if (uploadType === 'Both') {
                  NotificationService.closeAll();
                  NotificationService.send(
                    'Detected data files from both raw and exported recording. ' +
                      'Please, upload only .ndf or .nif files.',
                    { variant: 'error', persist: false },
                  );
                } else if (uploadType === 'Unknown') {
                  NotificationService.closeAll();
                  NotificationService.send(
                    'No data files were attached. Please, select .ndf or .nif files.',
                    { variant: 'error', persist: false },
                  );
                }
              }
              props.setAttachedFiles(attachedFiles);

              const requiredFilesAttached = new Set(
                attachedFiles
                  .filter((file) => file.definition.requiredFor === uploadType)
                  .map((file) => file.definition.type),
              ).size;

              if (requiredFilesAttached >= requiredFilesTotal) {
                Logger.log('[SelectFiles] Ready to submit!');
                props.setReadyToSubmit(true);
              } else {
                Logger.log('[SelectFiles] Missing files. Not ready to submit!');
                props.setReadyToSubmit(false);
              }
            }}
            multiple
          />
        </Button>
      ) : (
        ''
      )}
    </>
  );
};

export default SelectFiles;
