import React from 'react';
import { makeStyles, Typography, fade, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Recording } from '../../queries/recording';
import chartRangeTools from '../SignalSheet/chartRangeTools';
import OverviewPatientInfo from './OverviewPatientInfo';
import themeProvider from '../../utils/theme';
import { generateTLocale, LanguageSupportedLocale } from '../../i18n';

const useStyles = makeStyles((theme) => ({
  caption: {
    color: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.54,
    ),
    '@media print': {
      color: fade(
        theme.palette.getContrastText(
          themeProvider.getType('LIGHT').palette.background.paper,
        ),
        0.54,
      ),
    },
  },
}));

interface OverviewRecordingInfoProps {
  recording?: Recording;
  locale?: LanguageSupportedLocale;
}

const OverviewRecordingInfo = (
  props: OverviewRecordingInfoProps,
): JSX.Element => {
  const classes = useStyles();

  const { t } = useTranslation();
  const tLocale = generateTLocale(t, props.locale);

  return (
    <>
      <div>
        {props.recording ? (
          <>
            <Grid container spacing={3}>
              <Grid item data-cy="RecordingStart">
                <Typography variant="caption" className={classes.caption}>
                  {tLocale('Recording Start')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {moment(props.recording.startTime).format('DD. MMMM YYYY')}
                </Typography>
              </Grid>
              <Grid item data-cy="RecordingStop">
                <Typography variant="caption" className={classes.caption}>
                  {tLocale('Recording End')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {moment(props.recording.endTime).format('DD. MMMM YYYY')}
                </Typography>
              </Grid>
              <Grid item data-cy="RecordingInfoDuration">
                <Typography variant="caption" className={classes.caption}>
                  {tLocale('Duration')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {chartRangeTools.calculateDuration(
                    new Date(props.recording.startTime).valueOf(),
                    new Date(props.recording.endTime).valueOf(),
                    {
                      short: false,
                      ignoreSeconds: true,
                    },
                  )}
                </Typography>
              </Grid>
              <Grid item data-cy="UploadDate">
                <Typography variant="caption" className={classes.caption}>
                  {tLocale('Uploaded Date')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {moment(props.recording.uploadedDate).format('DD. MMMM YYYY')}
                </Typography>
              </Grid>
              <Grid item data-cy="DeviceType">
                <Typography variant="caption" className={classes.caption}>
                  {tLocale('Device')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {props.recording.deviceInfo?.type
                    ? props.recording.deviceInfo?.type
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Skeleton variant="text" animation="wave" width={400} />
          </>
        )}
      </div>
      {props.recording && (
        <OverviewPatientInfo
          recording={props.recording}
          locale={props.locale}
        />
      )}
    </>
  );
};

export default OverviewRecordingInfo;
