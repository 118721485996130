import {
  makeStyles,
  createStyles,
  fade,
  ClickAwayListener,
  Popper,
  Grow,
  Paper,
} from '@material-ui/core';
import React from 'react';
import Logger from '../../utils/logger';
import { ZIndex } from '../../utils/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    menuContainer: {
      zIndex: ZIndex.Popup,
      willChange: 'auto !important',
    },
    menu: {
      backgroundColor: fade(theme.colors.modal.backgroundColor, 0.5),
      color: theme.colors.modal.color,
      backdropFilter: 'blur(2px)',
    },
  }),
);

interface ToolbarMenuProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorRef: React.RefObject<HTMLDivElement | HTMLButtonElement>;
  children: JSX.Element | JSX.Element[];
}

const ToolbarMenu = (props: ToolbarMenuProps): JSX.Element => {
  const classes = useStyles();

  const [enableClickAway, setEnableClickAway] = React.useState(false);

  return (
    <ClickAwayListener
      mouseEvent="onMouseUp"
      touchEvent="onTouchStart"
      onClickAway={() => {
        if (enableClickAway) {
          Logger.log('[ToolbarMenu] ClickAwayListener');
          setEnableClickAway(false);
          props.setOpen(false);
        }
      }}
    >
      <Popper
        anchorEl={props.anchorRef.current}
        open={props.isOpen}
        placement="bottom-start"
        className={classes.menuContainer}
        modifiers={{
          offset: {
            enabled: true,
            offset: '0,5',
          },
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            timeout={{
              enter: 250,
              exit: 100,
            }}
            style={{
              transformOrigin: 'center top',
            }}
            onEntered={() => setEnableClickAway(true)}
          >
            <Paper
              className={classes.menu}
              elevation={3}
              style={{ minWidth: props.anchorRef.current?.clientWidth }}
            >
              {props.children}
            </Paper>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default ToolbarMenu;
