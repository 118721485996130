import { Recording } from '../../queries/recording';
import { Report, ReportData } from '../../queries/report';
import sheetTools from '../SignalSheet/sheetTools';
import { RecordingPartData } from '../StudyOverview/OverviewParts';

interface ReportTools {
  getParameter: (
    data: ReportData[] | undefined,
    name: ReportParameterName,
  ) => ReportData | undefined;
  getParameterValueNumber: (
    data: ReportData[] | undefined,
    name: ReportParameterName,
  ) => number | undefined;
  insightsPartToRecordingPartData: (
    recording: Recording,
    report: Report,
  ) => RecordingPartData;
}

type ReportParameterName =
  | 'AHI'
  | 'OximetryODI'
  | 'TotalSleepTime(m)'
  | 'SleepEfficiency'
  | 'RecordingDuration'
  | 'PulseMinimum'
  | 'PulseMaximum'
  | 'PulseAverage'
  | 'SpO2Minimum'
  | 'AwakeDuration'
  | 'SleepTotalREMDuration'
  | 'SleepTotalNREMDuration'
  | 'AnalysisDuration'
  | 'SleepTotalREMPercentage'
  | 'SleepTotalWakePercentage'
  | 'SleepTotalNREMPercentage';

const reportTools: ReportTools = {
  getParameter: (data, name) =>
    data ? data.find((param) => param.name === name) : undefined,
  getParameterValueNumber: (data, name) => {
    const param = reportTools.getParameter(data, name);
    if (param) {
      return parseFloat(param.value);
    }
    return undefined;
  },
  insightsPartToRecordingPartData: (recording: Recording, report: Report) => ({
    partId: 999,
    startTime: report.insights.beginning,
    endTime: report.insights.end,
    recordingId: report.recordingId,
    scoringId: sheetTools.getScoringId(),
    readOnlyScoring: true,
    isSubmitted: report.insights.isSubmitted,
    availableSignals: [],
    stats: {},
    deviceType: recording.deviceInfo?.type,
  }),
};

export default reportTools;
