import { Fade, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import WarningIcon from '@material-ui/icons/Warning';
import { OverviewGraphDefinition } from './overviewChartDefinitions';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },
  tooltip: { textAlign: 'center', maxWidth: 200 },
  missing: {
    marginLeft: theme.spacing(0.4),
    marginRight: theme.spacing(1.1),
    marginBottom: theme.spacing(-0.8),
    color:
      theme.id === 'LIGHT'
        ? theme.colors.chart.axisLabel
        : theme.palette.primary.contrastText,
  },
}));

interface OverviewChartNameProps {
  graphDefinition: OverviewGraphDefinition;
  availableSignals: string[];
}

const OverviewChartName = (props: OverviewChartNameProps): JSX.Element => {
  const classes = useStyles();

  const isMissing =
    props.graphDefinition.isSignalGraph &&
    props.graphDefinition.signalType &&
    props.availableSignals.length > 0 &&
    !props.availableSignals.includes(props.graphDefinition.signalType);

  const SVGIcon = props.graphDefinition.iconSvg;

  return (
    <Fade in mountOnEnter unmountOnExit>
      <div>
        <Typography className={classes.root} variant="caption">
          {isMissing ? (
            <Tooltip
              title={`The recording does not contain enough data to generate a ${props.graphDefinition.name} representation`}
              classes={{ tooltip: classes.tooltip }}
              arrow
            >
              <WarningIcon
                data-cy="OverviewGraph-Missing"
                className={clsx(classes.icon, classes.missing)}
                fontSize="small"
              />
            </Tooltip>
          ) : (
            <SVGIcon className={classes.icon} />
          )}
          {props.graphDefinition.name}
        </Typography>
      </div>
    </Fade>
  );
};

export default OverviewChartName;
