import React, { useState } from 'react';
import { makeStyles, Tooltip, Grow } from '@material-ui/core';
import SnoozeIcon from '@material-ui/icons/Snooze';
import EventService from '../../services/eventService';
import SleepStageService from '../../services/sleepStageService';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  tooltip: {
    maxWidth: 300,
  },
}));

const PositionEventsStatus = (): JSX.Element => {
  const classes = useStyles();
  const [show, setShow] = useState(SleepStageService.getTotalSleepStages() > 0);

  React.useEffect(() => {
    const cbId = EventService.subscribe('SleepStageReceived', (data: boolean) =>
      setShow(data),
    );

    return () => {
      EventService.unsubscribe(cbId);
    };
  }, []);

  return (
    <Grow in={show} mountOnEnter unmountOnExit>
      <div className={classes.root}>
        <Tooltip
          title="There are Sleep Stages for this scoring"
          classes={{ tooltip: classes.tooltip }}
          style={{ cursor: 'pointer' }}
          arrow
        >
          <SnoozeIcon fontSize="small" />
        </Tooltip>
      </div>
    </Grow>
  );
};

export default PositionEventsStatus;
