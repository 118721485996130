import Highcharts, {
  AxisSetExtremesEventObject,
  ChartSelectionContextObject,
  Chart,
  PointerEventObject,
} from 'highcharts/highstock';

import _ from 'underscore';
import { ChartEvents } from './interfaces/chart-events';
import chartRangeTools from './chartRangeTools';
import Logger from '../../utils/logger';
import sheetTools from './sheetTools';
import eventChartTools from '../Chart/SignalChart/eventChartTools';
import PassiveEvents from '../../utils/PassiveEvents';
import TabSyncService from '../../services/tabSyncService';
import chartTools from '../Chart/SignalChart/chartTools';
import signalEventDetailService from '../SignalEventDetail/signalEventDetailService';
import ScoringService from '../../services/scoringService';
import { TabSyncSheetExtremesInfo } from '../../interfaces/tab-sync-service';
import Analytics from '../../services/analytics';
import SignalRenderingService from '../../services/signalRenderingService';
import ScoringRenderingService from '../../services/scoringRenderingService';
import { ChartName } from '../../interfaces/chart-props';
import { SignalType } from '../../interfaces/sheet-definition';
import EventService from '../../services/eventService';
import { MarkerType } from '../../interfaces/markers';

PassiveEvents(Highcharts);

let xDown = 0;
let yDown = 0;

let userMoving = false;
let consecutiveMoves = 0;

let disableNavigation = false;
let scrollMovingInterval: NodeJS.Timeout;

const chartEvents: ChartEvents = {
  disableNavigation: () => {
    disableNavigation = true;
  },
  enableNavigation: () => {
    disableNavigation = false;
  },
  onSetExtremes: (e: AxisSetExtremesEventObject) => {
    Logger.debug('[onSetExtremes] Originated on', e);
    Logger.debug('[onSetExtremes] Diff is:', e.max - e.min);
    const navigatorChart = chartTools.getNavigatorChart();

    if (navigatorChart) {
      const range = chartRangeTools.adjustRangeToEpoch(e.min, e.max);
      const rawMin = range.min;
      let rawMax = range.max;
      rawMax = chartRangeTools.adjustToCurrentZoomRange(rawMin, rawMax);
      const { min, max } = chartRangeTools.adjustToPartEdges(rawMin, rawMax);

      const currentExtremes = chartRangeTools.getCurrentExtremes();
      Logger.log('[onSetExtremes] currentExtremes', currentExtremes);
      Logger.log('[onSetExtremes] min', min);
      Logger.log('[onSetExtremes] max', max);

      if (currentExtremes.min === min && currentExtremes.max === max) {
        Logger.log('[onSetExtremes] Same extremes. Skipping.', max);
        e.min = currentExtremes.min;
        e.max = currentExtremes.max;

        if (ScoringRenderingService.jumpingToEvent()) {
          ScoringRenderingService.requestRedraw();
        }

        return false;
      }
      if (!SignalRenderingService.isRenderInProgress()) {
        Logger.log('[onSetExtremes] Applying new extremes');
        SignalRenderingService.setRenderInProgress(true);
        e.min = min;
        e.max = max;
        TabSyncService.setCurrentExtremes({ min, max });
        chartRangeTools.setCurrentExtremes(min, max);
        chartRangeTools.calculateTicks(min, max);
        const newRangeToLoad = chartRangeTools.calculateRangeToLoad(min, max);
        chartRangeTools.setCurrentDataRange(newRangeToLoad);

        Logger.log('[onSetExtremes] redrawScoringCanvas init');
        ScoringRenderingService.requestRedraw();
        Logger.log('[onSetExtremes] reloadAllCharts init');
        SignalRenderingService.reloadAllCharts(consecutiveMoves);
      } else {
        Logger.log('[onSetExtremes] Sorry! Busy!');
      }
    }
    return undefined;
  },
  moveLeft: () => {
    if (!SignalRenderingService.isRenderInProgress()) {
      Logger.log('[moveLeft] Starting to move left');
      chartEvents.moveExtremes('LEFT');
      Analytics.track.navigation({ action: 'back' });
    } else {
      Logger.debug('[moveLeft] Sorry! busy!');
    }
  },
  moveRightFullPage: () => {
    if (!SignalRenderingService.isRenderInProgress()) {
      Logger.debug('[moveRightFullPage] Starting to move right');
      chartEvents.moveExtremes('RIGHT', { fullPage: true });
      Analytics.track.navigation({ action: 'forward' });
    } else {
      Logger.debug('[moveRight] Sorry! busy!');
    }
  },
  moveLeftFullPage: () => {
    if (!SignalRenderingService.isRenderInProgress()) {
      Logger.log('[moveRightFullPage] Starting to move left');
      chartEvents.moveExtremes('LEFT', { fullPage: true });
      Analytics.track.navigation({ action: 'back' });
    } else {
      Logger.debug('[moveLeft] Sorry! busy!');
    }
  },
  moveRight: () => {
    if (!SignalRenderingService.isRenderInProgress()) {
      Logger.debug('[moveRight] Starting to move right');
      chartEvents.moveExtremes('RIGHT');
      Analytics.track.navigation({ action: 'forward' });
    } else {
      Logger.debug('[moveRight] Sorry! busy!');
    }
  },
  isUserMoving: () => userMoving,
  setMovingStatus: (newMovingStatus: boolean) => {
    Logger.debug('[setMovingStatus] newMovingStatus:', newMovingStatus);
    const hasStopped = userMoving && newMovingStatus === false;
    userMoving = newMovingStatus;

    if (!userMoving) {
      Logger.debug('[setMovingStatus] userMoving', userMoving);
      consecutiveMoves = 0;

      if (hasStopped) {
        Logger.debug('[setMovingStatus] hasStopped', hasStopped);
        SignalRenderingService.waitForRenderFinished().then(() =>
          SignalRenderingService.onAfterUserMoving(),
        );
      }
    }
  },
  moveExtremes: (
    direction: 'LEFT' | 'RIGHT',
    opts?: { fullPage?: boolean },
  ) => {
    TabSyncService.setCurrentSheetAsLeader();
    const chart = chartTools.getNavigatorChart();
    if (
      !!chart &&
      !ScoringRenderingService.isEventBeingDragged() &&
      !disableNavigation
    ) {
      chartEvents.setMovingStatus(true);
      /*  scrollMovingInterval = setTimeout(() => {
        chartEvents.setMovingStatus(false);
      }, 200); */

      const extremes = chart.xAxis[0].getExtremes();
      Logger.debug(
        '[moveExtremes][%s] Current extremes are:',
        direction,
        extremes,
      );

      let msToMove = chartRangeTools.getMsToMove();
      const zoomRange = sheetTools.getZoomRange();
      if (opts?.fullPage) {
        Logger.debug('[moveExtremes][%s] Full page mode enabled!');
        msToMove = zoomRange;
      }

      Logger.debug(
        '[moveExtremes][%s] Diff is:',
        direction,
        extremes.max - extremes.min,
      );
      Logger.debug(
        '[moveExtremes][%s] User diff is:',
        direction,
        extremes.userMax - extremes.userMin,
      );
      Logger.debug('[moveExtremes][%s] msToMove: ', direction, msToMove);
      Logger.debug('[moveExtremes][%s] Zoom level is: ', direction, zoomRange);
      const moveDirection = direction === 'LEFT' ? -1 : 1;

      let newMin = extremes.userMin + msToMove * moveDirection;
      let newMax = extremes.userMax + msToMove * moveDirection;

      const startTime = chartRangeTools.getPartStartTime();
      const endTime = chartRangeTools.getPartEndTime() + 30000;

      if (direction === 'LEFT' && newMax < newMin + msToMove)
        newMax = newMin + msToMove;
      if (direction === 'RIGHT' && newMin > newMax - msToMove)
        newMin = newMax - msToMove;

      Logger.debug(
        '[moveExtremes][%s] newMin(%d) vs startTime(%s)',
        direction,
        newMin,
        startTime,
      );
      Logger.debug(
        '[moveExtremes][%s] newMax(%d) vs endTime(%s)',
        direction,
        newMax,
        endTime,
      );
      if (newMin < startTime) {
        newMin = startTime;
        newMax = newMin + zoomRange;
      }
      if (newMax > endTime) {
        newMax = endTime;
        newMin = endTime - zoomRange;
      }

      Logger.debug(
        '[moveExtremes][%s] Target zoom would be: %d - %d',
        direction,
        newMin,
        newMax,
      );
      Logger.debug(
        '[moveExtremes][%s] Target date would be: %s - %s',
        direction,
        new Date(newMin).toISOString(),
        new Date(newMax).toISOString(),
      );
      Logger.debug(
        '[moveExtremes][%s] New diff is:',
        direction,
        newMax - newMin,
      );

      consecutiveMoves += 1;
      sheetTools.setExtremes({ min: newMin, max: newMax }, { redraw: true });
    }

    if (ScoringRenderingService.isEventBeingDragged())
      Logger.debug('[moveExtremes][%s] Move cancelled due to event dragging');
  },
  moveScroll: (e: WheelEvent) => {
    Logger.debug('[moveScroll] Starting to move scroll');
    e.preventDefault();
    clearTimeout(scrollMovingInterval);
    TabSyncService.setCurrentSheetAsLeader();
    chartEvents.setMovingStatus(true);
    scrollMovingInterval = setTimeout(() => {
      chartEvents.setMovingStatus(false);
    }, 200);

    const scrollUp = e.deltaY > e.deltaX;
    const scrollDown = e.deltaY < e.deltaX;

    if (scrollUp) {
      Logger.debug(
        '[moveScroll] Direction is UP. DeltaY: %d. DeltaX: %d',
        e.deltaY,
        e.deltaX,
      );
      chartEvents.moveExtremes('RIGHT');
      Analytics.track.navigation({ action: 'scrollForward' });
    } else if (scrollDown) {
      Logger.debug(
        '[moveScroll] Direction is DOWN. DeltaY: %d. DeltaX: %d',
        e.deltaY,
        e.deltaX,
      );
      chartEvents.moveExtremes('LEFT');
      Analytics.track.navigation({ action: 'scrollBack' });
    } else {
      Logger.debug(
        '[moveScroll] Skipped scroll. DeltaY: %d. DeltaX: %d',
        e.deltaY,
        e.deltaX,
      );
    }
  },

  handleTouchStart: (evt: TouchEvent) => {
    Logger.debug('[handleTouchStart] Touch start event received:', evt);

    if (
      evt.srcElement &&
      (evt.srcElement as SVGSVGElement).className &&
      (evt.srcElement as SVGSVGElement).className.animVal &&
      (evt.srcElement as SVGSVGElement).className.animVal.indexOf(
        'highcharts-point',
      ) < 0
    ) {
      const firstTouch = evt.touches[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
      Logger.debug('[handleTouchStart] Setting xDown:', xDown);
      Logger.debug('[handleTouchStart] Setting yDown:', yDown);
    }
  },

  handleTouchMove: (evt: TouchEvent) => {
    Logger.debug('[handleTouchMove] Touch move event received. e:', evt);
    if (!xDown || !yDown) {
      return;
    }

    clearTimeout(scrollMovingInterval);
    TabSyncService.setCurrentSheetAsLeader();
    chartEvents.setMovingStatus(true);
    scrollMovingInterval = setTimeout(() => {
      chartEvents.setMovingStatus(false);
    }, 200);

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;

    Logger.debug('[handleTouchMove] xUp:', xUp);
    Logger.debug('[handleTouchMove] yUp:', yUp);
    Logger.debug(
      '[handleTouchMove] %f > %f?',
      Math.abs(xDiff),
      Math.abs(yDiff),
    );
    Logger.debug('[handleTouchMove] %f > %f?', xDiff, yDiff);
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /* most significant */
      if (xDiff > 0) {
        Logger.debug('[handleTouchMove] Swipe left. xDiff:', xDiff);
        chartEvents.moveRight();
        /* left swipe */
      } else {
        Logger.debug('[handleTouchMove] Swipe right. xDiff:', xDiff);
        chartEvents.moveLeft();
        /* right swipe */
      }
    } else if (yDiff > 0) {
      Logger.debug('[handleTouchMove] Swipe up. xDiff:', yDiff);
      /* up swipe */
    } else {
      Logger.debug('[handleTouchMove] Swipe down. xDiff:', yDiff);
      /* down swipe */
    }
  },
  onLoad: (
    chartName: ChartName,
    chart: Highcharts.Chart,
    opts: { isSignalChart: boolean },
  ) => {
    Logger.log('[getChartOptions] Loaded chart %s!', chartName, chart);
    chartTools.registerChart(chartName, chart);
    chartTools.setChartName(chart, chartName);
    if (opts.isSignalChart) {
      chartTools.setSignalType(chart, chartName as SignalType);
    }
  },
  onClick: (event: PointerEventObject, signalType: SignalType) => {
    Logger.log('[onClick] Received event: ', event);

    if (!ScoringRenderingService.isEventBeingDragged()) {
      if (sheetTools.isSingleClickScoring()) {
        if (!signalEventDetailService.popup.isOpen()) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const { chart } = (event as any).xAxis[0].axis;
          if (chart) {
            if (!chartTools.isLoading(chart)) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const clickedPoint = (event as any).xAxis[0];
              const eventType =
                eventChartTools.getDefaultMarkerTypeBySignalType(signalType);
              const duration =
                eventChartTools.getDefaultMarkerDurationBySignalType(
                  signalType,
                );
              const newMarker = eventChartTools.createSignalEventDetailData(
                eventType,
                clickedPoint.value - duration / 2,
                clickedPoint.value + duration / 2,
                signalType,
              );
              Logger.log('[onClick] newPoint:', newMarker);

              const startTime = chartRangeTools.getPartStartTime();
              const endTime = chartRangeTools.getPartEndTime() + 30000;

              if (newMarker.start < startTime && newMarker.end > endTime) {
                Logger.warn(
                  '[onClick][%s] Preventing out-of-bounds event',
                  signalType,
                  newMarker,
                );
                return;
              }
              if (newMarker.start < startTime) {
                newMarker.start = startTime;
              }

              if (newMarker.end > endTime) {
                newMarker.end = endTime;
              }

              Logger.debug(
                '[onClick][%s] Storing new event:',
                signalType,
                newMarker,
              );

              ScoringService.storeEvent(newMarker, { send: true, local: true });
            }
          } else {
            Logger.debug('[onClick] Skipping: chart is still loading');
          }
        } else {
          Logger.debug('[onClick] Event Detail popup is open');
        }
      } else {
        EventService.dispatch('Scoring.CancelActiveMarker');
        Logger.debug('[onClick] Single click scoring is disabled.');
      }
    } else {
      Logger.debug('[onClick] Skipping because event is being dragged');
    }
  },
  onSelection: (event: ChartSelectionContextObject, signalType: SignalType) => {
    Logger.log('[onSelection] Received event: ', event);
    const chart: Chart | null = event.target as unknown as Chart;
    if (chart) {
      if (!chartTools.isLoading(chart)) {
        const invalidDataMode = sheetTools.invalidDataMode();

        const eventType: MarkerType = !invalidDataMode
          ? eventChartTools.getDefaultMarkerTypeBySignalType(signalType)
          : 'signal-invalid';

        const eventToStore = eventChartTools.createSignalEventDetailData(
          eventType,
          event.xAxis[0].min,
          event.xAxis[0].max,
          !invalidDataMode ? signalType : undefined,
        );
        Logger.log(
          '[onSelection][%s] Storing new event:',
          signalType,
          eventToStore,
        );
        ScoringService.storeEvent(eventToStore, { send: true, local: true });
      } else {
        Logger.debug('[onSelection] Skipping: chart is still loading');
      }
    }

    return false;
  },
  onTabSyncExtremes: _.throttle(
    (extremes: TabSyncSheetExtremesInfo) => {
      Logger.debug('[onTabSyncExtremes] extremes:', extremes);
      if (extremes) {
        if (
          extremes.recordingId === sheetTools.getRecordingId() &&
          chartRangeTools.isTimestampWithinPart(extremes.min)
        ) {
          sheetTools.setExtremes(extremes, { redraw: true });
        }
      }
    },
    250,
    { trailing: true, leading: true },
  ),
};

export default chartEvents;
