import React from 'react';

import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  split,
  from,
  ApolloProvider,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';

import { WebSocketLink } from '@apollo/client/link/ws';
import {
  ConnectionParams,
  SubscriptionClient,
} from 'subscriptions-transport-ws';
import Children from '../interfaces/children';

import Logger from '../utils/logger';
import EventService from '../services/eventService';
import AuthService from '../services/authService';
import sheetTools from '../components/SignalSheet/sheetTools';
import { QueryType } from '../interfaces/query-manager';

// Create an http link:
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const checkAccessToken = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const newAccessToken = context.response.headers.get('x-new-access-token');

    if (newAccessToken && newAccessToken !== AuthService.getAccessToken()) {
      Logger.log('[ApolloClient] Replacing with new token!');
      AuthService.setAccessToken(newAccessToken);
    }

    return response;
  });
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_SUBSCRIPTIONS_ENDPOINT as string,
  options: {
    timeout: 30000,
    inactivityTimeout: 0,
    reconnect: true,
    connectionParams: async () => {
      const recordingId = sheetTools.getRecordingId();
      const scoringId = sheetTools.getScoringId();
      let params: ConnectionParams = {};

      if (recordingId && scoringId) {
        const accessToken = await AuthService.requestAccessToken();

        params = {
          token: accessToken ? `Bearer ${accessToken}` : '',
          recordingId,
          scoringId,
          credentials: 'include',
        };
      }

      return params;
    },
  },
});

const { subscriptionClient } = wsLink as unknown as {
  subscriptionClient: SubscriptionClient;
};
subscriptionClient.onConnected(() => {
  Logger.log('[subscriptionClient] onConnected');
  EventService.dispatch('WebsocketConnected');
});

subscriptionClient.onConnecting(() => {
  Logger.log('[subscriptionClient] onConnecting');
});

subscriptionClient.onDisconnected(() => {
  Logger.log('[subscriptionClient] onDisconnected');
  EventService.dispatch('WebsocketDisconnected');
});

subscriptionClient.onReconnecting(() => {
  Logger.log('[subscriptionClient] onReconnecting');
});

subscriptionClient.onReconnected(() => {
  Logger.log('[subscriptionClient] onReconnected');
  EventService.dispatch('WebsocketConnected');
});

subscriptionClient.onError((err: unknown) => {
  Logger.log('[subscriptionClient] onError', err);
});

EventService.subscribe(['WebsocketReconnect'], () => {
  Logger.log('[WebsocketReconnect]');
  subscriptionClient.close();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (subscriptionClient as any).connect();
});

const authLink = setContext((request) => {
  const accessToken = AuthService.getAccessToken();

  const includeAuthHeaders = AuthService.getAllowedQueryTypes().includes(
    request.operationName as QueryType,
  )
    ? {}
    : {
        recordingid: sheetTools.getRecordingId() || 'NO_RECORDING_ID',
        scoringid: sheetTools.getScoringId() || 'NO_SCORING_ID',
      };

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : '',
      ...includeAuthHeaders,
    },
  };
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  from([authLink, checkAccessToken, httpLink]),
);

const cache = new InMemoryCache({
  resultCaching: false,
});
const client = new ApolloClient({
  cache,
  link,
  assumeImmutableResults: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    },
  },
});

const ApolloProviderWrapper = ({ children }: Children): JSX.Element => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
