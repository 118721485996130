import _ from 'underscore';
import {
  ScoringCanvasEventPosition,
  ScoringCanvasEvent,
  ScoringCanvasEventExtras,
} from '../components/Chart/ScoringCanvas/ScoringCanvas';
import chartTools from '../components/Chart/SignalChart/chartTools';
import eventChartTools from '../components/Chart/SignalChart/eventChartTools';
import { MarkerDefinitions } from '../components/Chart/SignalChart/markerConstants';
import { SignalType } from '../interfaces/sheet-definition';
import {
  MarkerId,
  SignalEventDetailData,
} from '../interfaces/signal-event-detail-props';
import Logger from '../utils/logger';
import EventService from './eventService';
import ScoringService from './scoringService';

interface ScoringRenderingServiceInterface {
  wasResizeCancelled: () => boolean;
  setResizeWasCancelled: (was: boolean) => void;
  setEventBeingDraggedId: (markerId: MarkerId) => void;
  setEventJumpingTo: (markerId: MarkerId) => void;
  jumpingToEvent: () => boolean;
  cancelEventBeingDragged: () => void;
  isUserInteracting: () => boolean;
  isEventBeingDragged: () => boolean;
  getEventBeingDraggedId: () => string | undefined;
  getEventBeingDraggedDuration: () => number;
  setEventBeingResizedId: (markerId: MarkerId) => void;
  cancelEventBeingResized: () => void;
  isEventBeingResized: () => boolean;
  getEventBeingResizedId: () => string | undefined;
  getEventBeingResizedDuration: () => number;
  registerUpdateScoringCanvasEventsFunction: (
    fn: UpdateScoringCanvasEvents,
  ) => void;
  setScoringCanvasContainer: (
    container: React.RefObject<HTMLDivElement>,
  ) => void;
  getScoringCanvasContainer: () => React.RefObject<HTMLDivElement>;
  getScoringCanvasWidth: () => number;
  getTimestampFromLeftAttribute: (
    scoringEvent: ScoringCanvasEvent,
    newLeft: number,
  ) => number;
  eventDetailDataToCanvasEvent: (
    event: SignalEventDetailData,
    extremeMin: number,
    extremeMax: number,
    currentDateNow: number,
    signalType?: SignalType,
    distanceFromTop?: number,
    height?: number,
  ) => ScoringCanvasEvent;
  requestRedraw: () => Promise<boolean>;
  redrawScoringCanvas: () => Promise<boolean>;
}

export type UpdateScoringCanvasEvents = (
  newEvents: ScoringCanvasEvent[],
) => void;

let scoringCanvasContainer: React.RefObject<HTMLDivElement>;
let updateScoringCanvasEvents: UpdateScoringCanvasEvents = () => {
  /* Will be updated */
};

let eventBeingDraggedId: string | undefined;
let eventBeingDraggedFromTime: number | undefined;

let eventBeingResizedId: string | undefined;
let eventBeingResizedFromTime: number | undefined;

let resizeWasCancelled = false;

let eventJumpedToId: MarkerId | undefined;

const ScoringRenderingService: ScoringRenderingServiceInterface = {
  wasResizeCancelled: () => resizeWasCancelled,
  setResizeWasCancelled: (was: boolean) => {
    resizeWasCancelled = was;
  },
  setEventBeingDraggedId: (markerId: string) => {
    eventBeingDraggedId = markerId;
    eventBeingDraggedFromTime = Date.now();
    EventService.dispatch('Scoring.DraggingOrMovingMarker', true);
  },
  cancelEventBeingDragged: () => {
    eventBeingDraggedId = undefined;
    eventBeingDraggedFromTime = undefined;
    EventService.dispatch('Scoring.DraggingOrMovingMarker', false);
  },
  setEventJumpingTo: (markerId: MarkerId) => {
    eventJumpedToId = markerId;
  },
  jumpingToEvent: () => eventJumpedToId !== undefined,
  getEventBeingDraggedId: () => eventBeingDraggedId,
  getEventBeingDraggedDuration: () =>
    eventBeingDraggedFromTime ? Date.now() - eventBeingDraggedFromTime : 0,
  isUserInteracting: () =>
    ScoringRenderingService.isEventBeingDragged() ||
    ScoringRenderingService.isEventBeingResized(),
  isEventBeingDragged: () => eventBeingDraggedId !== undefined,
  setEventBeingResizedId: (markerId: string) => {
    eventBeingResizedId = markerId;
    eventBeingResizedFromTime = Date.now();
    EventService.dispatch('Scoring.DraggingOrMovingMarker', true);
  },
  cancelEventBeingResized: () => {
    eventBeingResizedId = undefined;
    eventBeingResizedFromTime = undefined;
    EventService.dispatch('Scoring.DraggingOrMovingMarker', false);
  },
  getEventBeingResizedId: () => eventBeingResizedId,
  getEventBeingResizedDuration: () =>
    eventBeingResizedFromTime ? Date.now() - eventBeingResizedFromTime : 0,
  isEventBeingResized: () => eventBeingResizedId !== undefined,
  registerUpdateScoringCanvasEventsFunction: (fn) => {
    updateScoringCanvasEvents = fn;
  },
  setScoringCanvasContainer: (container: React.RefObject<HTMLDivElement>) => {
    scoringCanvasContainer = container;
  },
  getScoringCanvasContainer: () => scoringCanvasContainer,
  getScoringCanvasWidth: () => {
    const clientWidth = scoringCanvasContainer?.current?.clientWidth;
    return clientWidth || 0;
  },
  getTimestampFromLeftAttribute: (
    scoringEvent: ScoringCanvasEvent,
    newLeft: number,
  ) => {
    const containerWidth = ScoringRenderingService.getScoringCanvasWidth();
    const timePerPixel =
      (scoringEvent.position.originalExtremesMax -
        scoringEvent.position.originalExtremesMin) /
      containerWidth;
    const timestamp =
      timePerPixel * newLeft + scoringEvent.position.originalExtremesMin;
    return timestamp;
  },
  eventDetailDataToCanvasEvent: (
    event: SignalEventDetailData,
    extremeMin: number,
    extremeMax: number,
    currentDateNow: number,
    signalType?: SignalType,
    distanceFromTop?: number,
    height?: number,
  ) => {
    const diffWithExtremeMax = extremeMax - extremeMin;
    const diffWithEventStart = event.start - extremeMin;
    const diffWithEventEnd = event.end - extremeMin;
    const containerWidth = ScoringRenderingService.getScoringCanvasWidth();

    const left = (diffWithEventStart * containerWidth) / diffWithExtremeMax;
    const width =
      (diffWithEventEnd * containerWidth) / diffWithExtremeMax - left;

    const outsideLeftSide = left < 0 ? Math.abs(left) : 0;
    const outsideRightSide =
      left + width > containerWidth ? left + width - containerWidth : 0;

    const validHeight = height !== undefined && height >= 0;
    const visible =
      (validHeight && distanceFromTop !== undefined) ||
      event.scoredFrom === undefined;
    const visibleWidth = width - outsideLeftSide - outsideRightSide;

    const scoringCanvasPosition: ScoringCanvasEventPosition = {
      position: {
        blink: eventJumpedToId === event.id,
        fadeIn:
          !!event.creationDate && currentDateNow - event.creationDate < 250,
        visible,
        isCrossChart: eventChartTools.isCrossChart(event.type),
        top: distanceFromTop ?? 0,
        left,
        width,
        height: validHeight && height ? height : '100%',
        originalExtremesMin: extremeMin,
        originalExtremesMax: extremeMax,
        outsideLeftSide,
        outsideRightSide,
        visibleWidth,
      },
    };

    if (scoringCanvasPosition.position.blink) {
      eventJumpedToId = undefined;
    }

    const scoringCanvasExtras: ScoringCanvasEventExtras = {
      extras: {
        hasDrop: eventChartTools.shouldHaveDrop(
          event.start,
          event.end,
          event.type,
          signalType,
        ),
        stripedBackground: MarkerDefinitions.get(event.type)?.extras
          ?.stripedBackground,
      },
    };

    // Logger.debug('[eventDetailDataToCanvasEvent] ----');
    // Logger.debug('[eventDetailDataToCanvasEvent] event', event);
    // Logger.debug('[eventDetailDataToCanvasEvent] scoringCanvasPosition', scoringCanvasPosition);
    // Logger.debug('[eventDetailDataToCanvasEvent] event.creationDate', event.creationDate);
    // Logger.debug('[eventDetailDataToCanvasEvent] currentDateNow', currentDateNow);
    /* Logger.debug(
      '[eventDetailDataToCanvasEvent] distanceFromTop',
      distanceFromTop,
    ); */
    // Logger.debug('[eventDetailDataToCanvasEvent] left', left);
    // Logger.debug('[eventDetailDataToCanvasEvent] height', height);
    // Logger.debug('[eventDetailDataToCanvasEvent] visible', visible);
    // Logger.debug('[eventDetailDataToCanvasEvent] visibleWidth', visibleWidth);
    // Logger.debug('[eventDetailDataToCanvasEvent] width', width);
    // Logger.debug('[eventDetailDataToCanvasEvent] containerWidth', containerWidth);
    /* Logger.log(
      '[eventDetailDataToCanvasEvent] scoringCanvasExtras',
      scoringCanvasExtras,
    ); */
    // Logger.debug('[eventDetailDataToCanvasEvent] ----');

    if (!event.signalInfo) {
      // eslint-disable-next-line no-param-reassign
      event.signalInfo = chartTools.getSignalInfo(signalType);
    }

    return {
      ...event,
      ...scoringCanvasPosition,
      ...scoringCanvasExtras,
    };
  },
  requestRedraw: () => {
    return new Promise((resolve) => {
      ScoringRenderingService.redrawScoringCanvas().then(resolve);
    });
  },
  redrawScoringCanvas: _.throttle(() => {
    Logger.debug('[redrawScoringCanvas] Init');
    return new Promise((resolve) => {
      const newEvents = ScoringService.retrieveMarkersForExtremes();
      // Logger.debug('[redrawScoringCanvas] Drawing events:', newEvents);
      updateScoringCanvasEvents(newEvents);
      resolve(true);
    });
  }, 10),
};

export default ScoringRenderingService;
