import React from 'react';
import {
  FixedSizeGrid as Grid,
  areEqual,
  GridChildComponentProps,
} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { makeStyles, useTheme } from '@material-ui/core';
import { ReportData } from '../../queries/report';
import ReportParameterItem from './ReportParameterItem';

const useStyles = makeStyles(() => ({
  root: {
    '@media print': {
      display: 'none',
    },
  },
}));

interface ReportParameterListProps {
  data: ReportData[];
}

function ReportParameterList(props: ReportParameterListProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const Cell = React.memo(function cell({
    data,
    rowIndex,
    style,
  }: GridChildComponentProps) {
    const paramList = data as ReportData[];
    const param = paramList.length > rowIndex ? paramList[rowIndex] : undefined;

    return (
      <>
        {param && (
          <ReportParameterItem
            style={{
              ...style,
              background:
                rowIndex % 2 === 0 ? 'initial' : theme.palette.secondary.main,
            }}
            parameter={param}
          />
        )}
      </>
    );
  },
  areEqual);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Grid
          columnCount={1}
          columnWidth={width}
          height={height - theme.spacing(5)}
          rowCount={props.data.length}
          rowHeight={20}
          width={width}
          itemData={props.data}
          className={classes.root}
          style={{ overflowX: 'hidden' }}
        >
          {Cell}
        </Grid>
      )}
    </AutoSizer>
  );
}

export default ReportParameterList;
