import _ from 'underscore';
import { SignalEventDetailData } from '../interfaces/signal-event-detail-props';
import SleepStageServiceInterface, {
  SleepStageType,
} from '../interfaces/sleep-stage-service';
import Logger from '../utils/logger';
import chartTools from '../components/Chart/SignalChart/chartTools';
import chartRangeTools from '../components/SignalSheet/chartRangeTools';
import EventService from './eventService';
import eventChartTools from '../components/Chart/SignalChart/eventChartTools';
import ScoringService from './scoringService';
import sheetTools from '../components/SignalSheet/sheetTools';
import { MarkerType } from '../interfaces/markers';
import SignalRenderingService from './signalRenderingService';

/**
 * Map that stores the sleep stages, one stage per epoch
 * signalType -> epochs array -> array of markers
 */
const sleepStages: Map<number, SignalEventDetailData> = new Map();

let disableKeyBindings = false;

const SleepStageService: SleepStageServiceInterface = {
  initialize: () => {
    Logger.log('[SleepStagesService] Initializing');
    EventService.dispatch('SleepStageReceived', false);
    sleepStages.clear();
  },
  registerKeyBindings: () => {
    const callbackIds = [
      EventService.subscribe('KeyboardShortcut.ScoreStageN1', () => {
        if (!disableKeyBindings)
          SleepStageService.scoreSelectedEpoch('sleep-n1');
      }),
      EventService.subscribe('KeyboardShortcut.ScoreStageN2', () => {
        if (!disableKeyBindings)
          SleepStageService.scoreSelectedEpoch('sleep-n2');
      }),
      EventService.subscribe('KeyboardShortcut.ScoreStageN3', () => {
        if (!disableKeyBindings)
          SleepStageService.scoreSelectedEpoch('sleep-n3');
      }),
      EventService.subscribe('KeyboardShortcut.ScoreStageWake', () => {
        if (!disableKeyBindings)
          SleepStageService.scoreSelectedEpoch('sleep-wake');
      }),
      EventService.subscribe('KeyboardShortcut.ScoreStageREM', () => {
        if (!disableKeyBindings)
          SleepStageService.scoreSelectedEpoch('sleep-rem');
      }),
    ];

    return callbackIds;
  },
  disableKeyBindings: () => {
    disableKeyBindings = true;
  },
  enableKeyBindings: () => {
    disableKeyBindings = false;
  },
  scoreSelectedEpoch: (type: SleepStageType) => {
    const selectedEpoch = sheetTools.getSelectedEpoch();
    Logger.log(
      '[scoreSelectedEpoch] Scoring %s on epoch #%d',
      type,
      selectedEpoch + 1,
    );
    const existingStage = sleepStages.get(selectedEpoch);

    if (existingStage) {
      ScoringService.removeEvent(existingStage.id, {
        send: true,
        updating: true,
      });
    }

    const fromTime = chartRangeTools.convertToTimestamp(selectedEpoch);
    const event = eventChartTools.createSignalEventDetailData(
      type,
      fromTime,
      fromTime + 30000,
    );
    ScoringService.storeEvent(event, { send: true, local: true });

    sheetTools.animateScoredEpoch(
      selectedEpoch,
      eventChartTools.getColorByEventType(type),
    );
    sheetTools.selectNextEpoch();
  },
  addStage: (marker: SignalEventDetailData) => {
    const epoch = chartRangeTools.convertToEpoch(marker.start);
    // Logger.debug('[addStage] Adding stage to #%d:', epoch, marker);
    const isSameStage = sleepStages.get(epoch)?.type === marker.type;

    if (sleepStages.size === 0) {
      EventService.dispatch('SleepStageReceived', true);
    }

    sleepStages.set(epoch, marker);
    if (!isSameStage) {
      const currentExtremesInEpoch =
        chartRangeTools.getCurrentExtremesInEpoch();
      if (
        epoch >= currentExtremesInEpoch.first &&
        epoch < currentExtremesInEpoch.last
      ) {
        Logger.log('[addStage] Requesting rerender.');
        SignalRenderingService.redrawEpochChart();
      }
      SleepStageService.updateStageColors();
    }
  },
  deleteStage: (marker: SignalEventDetailData) => {
    const epoch = chartRangeTools.convertToEpoch(marker.start);
    const existingStage = sleepStages.get(epoch);
    if (existingStage) {
      if (marker.id === existingStage.id) {
        Logger.log('[deleteStage] Deleting stage for #%d:', epoch);
        sleepStages.delete(epoch);
        if (
          chartRangeTools.isEpochRangeWithinCurrentExtremes({
            first: epoch,
            last: epoch,
          })
        ) {
          SignalRenderingService.redrawEpochChart();
        }
        SleepStageService.updateStageColors();
      } else {
        Logger.log(
          '[deleteStage] Skipping delete. Existing marker does not match. existingStage.id:',
          existingStage.id,
        );
      }
    } else {
      Logger.log('[deleteStage] Skipping delete. Epoch #%d is empty.', epoch);
    }
  },
  getMarkerForEpoch: (epoch: number) => sleepStages.get(epoch),
  getStageNameForEpoch: (epoch: number) => {
    const stageType = sleepStages.get(epoch)?.type;

    if (stageType) {
      return eventChartTools.getNameByEventType(stageType);
    }
    return undefined;
  },

  getStageForEpoch: (epoch: number) => sleepStages.get(epoch),
  // TODO: Unit test
  updateStageColors: _.throttle(() => {
    return new Promise((resolve) => {
      // Logger.debug('[updateStageColors] Init');

      const chart = chartTools.getEpochChart();
      if (chart) {
        let time = Date.now();

        /**
         * Array of zones.
         * Each zone contains a value attribute that represents up to where the zone extends
         */
        const zones: Highcharts.SeriesZonesOptionsObject[] = [];

        let previousEpoch: number | undefined;
        let currentStageType: MarkerType | undefined;
        let currentStageEnd: number | undefined;

        const sortedStagesPerEpoch = new Map(
          Array.from(sleepStages).sort((a, b) => {
            return a[0] - b[0];
          }),
        );

        sortedStagesPerEpoch.forEach((stageMarker, epoch) => {
          if (
            chartRangeTools.isEpochRangeWithinCurrentExtremes({
              first: epoch,
              last: epoch,
            })
          ) {
            const isConsecutiveEpoch = epoch - 1 === previousEpoch;
            const hasStageChanged = stageMarker.type !== currentStageType;

            if (
              !!currentStageType &&
              (hasStageChanged || (!hasStageChanged && !isConsecutiveEpoch))
            ) {
              const color =
                eventChartTools.getColorByEventType(currentStageType);
              zones.push({
                value: currentStageEnd,
                color,
              });
            }
            currentStageType = stageMarker.type;
            currentStageEnd = stageMarker.end;

            // Filling gap between stages
            if (!isConsecutiveEpoch) {
              zones.push({ value: stageMarker.start, color: 'transparent' });
            }

            previousEpoch = epoch;
          }
        });

        // Adding final stage
        if (currentStageEnd && currentStageType) {
          const color = eventChartTools.getColorByEventType(currentStageType);
          zones.push({
            value: currentStageEnd,
            color,
          });
          zones.push({ color: 'transparent' });
        }
        Logger.log(
          '[updateStageColors] Zones calculation took (ms):',
          Date.now() - time,
        );

        time = Date.now();

        Logger.log('[updateStageColors] Updating with new zones:', zones);
        chart.series[0].update({
          type: 'line',
          zones,
        });
        Logger.log('[updateStageColors] Update took (ms):', Date.now() - time);
      }

      resolve(true);
    });
  }, 250),
  getTotalSleepStages: () => sleepStages.size,
  getAllSleepStages: () => Array.from(sleepStages.values()),
  isNREMStage: (markerType: MarkerType) => {
    const NREMStages: MarkerType[] = [
      'sleep-nrem',
      'sleep-n1',
      'sleep-n2',
      'sleep-n3',
    ];
    return NREMStages.includes(markerType);
  },
  getDeviceAvailability: () => ['A1', 'A1S', 'T3S'],
};

export default SleepStageService;
