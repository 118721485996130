import React from 'react';
import clsx from 'clsx';
import { makeStyles, Button, useMediaQuery, fade } from '@material-ui/core';
import ShowChartIcon from '@material-ui/icons/ShowChart';

import { useHistory } from 'react-router-dom';
import { RecordingPartData } from './OverviewParts';
import { SheetId } from '../../interfaces/sheet-definition';
import SheetDefinitions from '../SignalSheet/sheetDefinitions';
import studyTools from './studyTools';
import sheetTools from '../SignalSheet/sheetTools';

interface OverviewPartOpenSignalSheetData {
  recordingPart: RecordingPartData;
  sheetId: SheetId;
}

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    borderColor: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.23,
    ),
    fontSize: 12,
    textTransform: 'initial',
    textAlign: 'center',
    lineHeight: '12px',
    margin: theme.spacing(0.5, 1),
    maxWidth: 125,
    whiteSpace: 'normal',
    fontWeight: 400,

    '&:hover': {
      backgroundColor: fade(
        theme.palette.getContrastText(theme.palette.background.paper),
        0.05,
      ),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  compact: {
    maxWidth: 70,
    minWidth: 0,
    '& $icon': {
      marginRight: 0,
    },
  },
}));

export type OpenSignalSheetOrigin = 'Actions' | 'Stats' | 'Hover';

const OverviewPartOpenSignalSheet = (
  props: OverviewPartOpenSignalSheetData,
): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const smallScreen = useMediaQuery('(max-width:1000px)');

  return (
    <Button
      data-cy="Action-OpenSignals"
      className={clsx(classes.button, { [classes.compact]: smallScreen })}
      variant="outlined"
      onClick={() => {
        const sheetDefinition = SheetDefinitions.get(props.sheetId);

        if (sheetDefinition) {
          studyTools.openSignalSheets({
            recordingId: props.recordingPart.recordingId,
            scoringId: sheetTools.getScoringId(),
            partId: props.recordingPart.partId,
            origin: 'Actions',
            history,
            sheetDefinition,
          });
        }
      }}
    >
      <ShowChartIcon className={classes.icon} />
      {!smallScreen ? 'View Signals' : ''}
    </Button>
  );
};

export default OverviewPartOpenSignalSheet;
