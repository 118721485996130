import React from 'react';
import { Paper, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Recording } from '../../queries/recording';
import { Report } from '../../queries/report';
import themeProvider from '../../utils/theme';
import OverviewSleepWidget from '../StudyOverview/OverviewSleepWidget';
import ReportScoringStats from './ReportScoringStats';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    color: theme.palette.getContrastText(theme.palette.background.paper),
    margin: theme.spacing(0, 1),
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    '@media print': {
      boxShadow: 'none',
      padding: 0,
      marginTop: theme.spacing(3),
      background: `${
        themeProvider.getType('LIGHT').palette.background.paper
      } !important`,
      color: theme.palette.getContrastText(
        themeProvider.getType('LIGHT').palette.background.paper,
      ),
    },
  },
  container: {
    position: 'relative',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

interface ReportScoringProps {
  loading: boolean;
  recording?: Recording;
  report?: Report;
}

function ReportScoring(props: ReportScoringProps): JSX.Element {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div>
        <Typography variant="h6" className={classes.title}>
          Scoring
        </Typography>

        {props.loading && (
          <Skeleton variant="text" animation="wave" width={400} />
        )}
        {!props.loading && props.report && (
          <ReportScoringStats report={props.report} />
        )}
      </div>

      {props.report && (
        <div>
          <OverviewSleepWidget
            recording={props.recording}
            report={props.report}
            withTable
          />
        </div>
      )}
    </Paper>
  );
}

export default ReportScoring;
